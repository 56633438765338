import React, { memo, useState, useCallback, useEffect } from 'react';
import { Box, Flex, Text, Heading, UnorderedList, ListItem, useTheme } from '@chakra-ui/react';

import ManageContentBox, { ContentHeader, Title } from 'components/ManageContentBox/manage-content-box';
import LabeledInput from 'components/labeled-input/labeled-input';
import LabeledSelect from 'components/labeled-select/labeled-select';
import Well from 'components/Well/well';

import asModal from '../../../features/Modal/as-modal';

import AudioFileUploader from '../audio-file-uploader';
import ENUMS from '../../../constants/ENUMS.gen.json';

import useUploadCoverArt from '../../../components/CoverArt/use-upload-cover-art';

const versionNames = [
  'Acapella',
  'Acoustic',
  'Alternative Version',
  'Ao Vivo',
  'Bonus Track',
  'Cover Version',
  'Deluxe',
  'Demo',
  'Edit',
  'Extended Version',
  'Freestyle',
  'Ghost Track',
  'Hidden Track',
  'Instrumental',
  'Karaoke Track',
  'Live',
  'Radio Edit',
  'Remastered',
  'Remix',
  'Silent Track',
];

const ERROR_MESSAGES = {
  title: 'A valid title is required',
  display_artist_name: 'A valid artist name is required',
  display_label_name: 'A valid label name is required',
};

function ClipSetup({
  title,
  sub_title,
  display_artist_name,
  display_label_name,
  copyright_year,
  cover_art,
  audio_source,
  track,
  asset_release_set_id,
  asset_release_id,
  doValidateField,
  saveState,
  tainted,
  hasBeenSubmitted,
}) {
  const [localData, setLocalData] = useState({
    title,
    sub_title,
    display_artist_name,
    display_label_name,
    copyright_year,
    cover_art,
    audio_source,
  });

  const [errorMessages, setErrorMessages] = useState({
    title: null,
    sub_title: null,
    display_artist_name: null,
    display_label_name: null,
  });

  function updateErrorMessages(name, value) {
    setErrorMessages(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  const [imageUploaded, setImageUploaded] = useState(null);

  function onFormInputChange({ target: { name, value } }) {
    setLocalData({ ...localData, [name]: value });
    const valid = doValidateField(name, value);
    if (!valid) {
      updateErrorMessages(name, ERROR_MESSAGES[name]);
    } else {
      updateErrorMessages(name, null);
    }
  }

  const onNullPreferredInputChange = useCallback(
    ({ target: { name, value } }) => {
      onFormInputChange({ target: { name, value: value || null } });
    },
    [onFormInputChange]
  );

  useEffect(() => {
    if (tainted) {
      Object.entries(localData).forEach(([name, value]) => {
        const valid = doValidateField(name, value);
        if (!valid) {
          if (!valid) {
            updateErrorMessages(name, ERROR_MESSAGES[name]);
          } else {
            updateErrorMessages(name, null);
          }
        }
      });
    }
  }, []);

  function saveUploadedImage(file) {
    setLocalData({ ...localData, cover_art: file });
    saveState({ ...localData, cover_art: file });
  }

  useEffect(() => {
    if (!imageUploaded) return;
    saveUploadedImage(imageUploaded);
  }, [imageUploaded]);

  const onBuildTrackCreationProperties = useCallback(() => ({ resource_type: ENUMS.RESOURCE_TYPE.CLIP_SOURCE }), []);

  function onAudioFileLoaded({ file, track }) {
    setLocalData({ ...localData, audio_source: file });
    saveState({ ...localData, audio_source: file, track });
  }

  function onImageLoaded(file) {
    setImageUploaded(file);
  }

  function setImageErrorMessage(msg) {
    updateErrorMessages('cover_art', msg);
  }

  const { UploadCoverArt, disclosure: invalidImageModal } = useUploadCoverArt({
    currentFile: localData.cover_art,
    onImageLoaded,
    setImageErrorMessage,
    release: { asset_release_id, asset_release_set_id },
  });

  const InvalidImageModal = asModal(function InvalidImageModal() {
    return (
      <Text margin={'1rem 0'} color="black">
        {errorMessages.cover_art}
      </Text>
    );
  });

  function submitForm(evt) {
    evt?.preventDefault();
    saveState(localData);
  }

  return (
    <Box>
      <form onSubmit={submitForm}>
        <ManageContentBox>
          <ContentHeader>
            <Title>Release Data</Title>
          </ContentHeader>
          <LabeledInput
            name="title"
            label="Release Title"
            value={localData.title || ''}
            onChange={onFormInputChange}
            onBlur={submitForm}
            invalidMessage={errorMessages.title}
          />
          <LabeledSelect
            width="14rem"
            name="sub_title"
            label="Version (optional)"
            value={localData.sub_title || ''}
            onChange={onNullPreferredInputChange}
            onBlur={submitForm}
            invalidMessage={null}
            options={versionNames}
          />
          {localData.title ? (
            <Well>
              <Text fontWeight="bold">RELEASE TITLE PREVIEW</Text>
              <Text>{`${localData.title} ${localData.sub_title ? `(${localData.sub_title})` : ''}`}</Text>
            </Well>
          ) : (
            ''
          )}
          <LabeledInput
            name="display_artist_name"
            label="Display Artist Name"
            value={localData.display_artist_name || ''}
            onChange={onFormInputChange}
            onBlur={submitForm}
            invalidMessage={errorMessages.display_artist_name}
            tooltip={'Performing artist names displayed on music platforms'}
          />
          <LabeledInput
            name="display_label_name"
            label="Display Label Name"
            value={localData.display_label_name || ''}
            onChange={onFormInputChange}
            onBlur={submitForm}
            invalidMessage={errorMessages.display_label_name}
            tooltip={
              'Identifies the owner (artist or label) of the rights to the master sound recording (aka "p line")'
            }
          />

          <LabeledSelect
            width="14rem"
            name="copyright_year"
            label="Copyright Year"
            value={localData.copyright_year || Number(new Date().getFullYear())}
            onChange={onFormInputChange}
            onBlur={submitForm}
            invalidMessage={null}
            options={getSelectionYears()}
            tooltip={'Select the year the sound recording rights were granted to the rights owner'}
          />
        </ManageContentBox>

        <ManageContentBox>
          <ContentHeader>
            <Title toolTip={'Upload the art that will display your release on streaming services'}>Cover Art</Title>
          </ContentHeader>
          <Flex marginTop={'1rem'}>
            <Box height="350px" width="350px">
              <UploadCoverArt />
            </Box>
            <Box paddingLeft={'20px'}>
              <Heading as={'h4'} fontSize="1.25rem">
                Cover Art Requirements
              </Heading>
              <UnorderedList>
                <RequirementsListItem>A perfect square</RequirementsListItem>
                <RequirementsListItem>3000 x 3000 px</RequirementsListItem>
                <RequirementsListItem>PNG and JPG files only</RequirementsListItem>
                <RequirementsListItem>No social media logos</RequirementsListItem>
                <RequirementsListItem>No references to brands</RequirementsListItem>
              </UnorderedList>
            </Box>
          </Flex>
        </ManageContentBox>

        <ManageContentBox>
          {hasBeenSubmitted ? (
            <>
              <ContentHeader>
                <Title toolTip={'With a published clips release, you cannot change the audio file'}>Audio File</Title>
              </ContentHeader>
              <Box>{localData.audio_source.name}</Box>
            </>
          ) : (
            <>
              <ContentHeader>
                <Title toolTip={'File must be a wav or mp3'}>Audio Upload</Title>
              </ContentHeader>
              <Box marginTop={'1rem'} height="140px">
                <AudioFileUploader
                  width="100%"
                  audio_source={localData.audio_source}
                  track={track}
                  onUploaded={onAudioFileLoaded}
                  onBuildTrackCreationProperties={onBuildTrackCreationProperties}
                />
              </Box>
            </>
          )}
        </ManageContentBox>
      </form>

      <InvalidImageModal
        variant="light"
        hideCancel={true}
        headerText="Invalid image"
        isOpen={invalidImageModal?.isOpen}
        onClose={invalidImageModal?.onClose}
        onSubmit={invalidImageModal?.onClose}
        submitText="OK"
      />
    </Box>
  );
}

export default memo(ClipSetup);

function RequirementsListItem({ children }) {
  const theme = useTheme();
  return (
    <ListItem paddingTop={'.25rem'} color={theme.colors.black100}>
      {children}
    </ListItem>
  );
}

function getSelectionYears() {
  return Array(4)
    .fill(Number(new Date().getFullYear()))
    .map((year, i) => Number(year) + 1 - i)
    .reverse();
}
