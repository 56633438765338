import * as React from 'react';
import { Skeleton } from '@chakra-ui/react';
import { ERROR_STATUS, LOADING_STATUS } from 'constants/request-statuses';

export default function SkeletonLoader({ skeletonProps, customError, status, children = null }) {
  if (status === LOADING_STATUS) return <Skeleton height="100%" width="100%" {...skeletonProps} />;
  if (status === ERROR_STATUS) return customError || null;

  return children;
}
