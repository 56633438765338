import { useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import AddContributorModal from '../../../components/AddArtistsForm/add-contributor-modal';
import asModal from '../../../features/Modal/as-modal';
import AddPayeeForm from '../../../settings/add-payee-form';
import * as API from '../../../utils/API/API';
import { useReleaseStatusTrackContext } from '../release-status-track-context';
import { formatPayees } from './splits-form-helpers';
import SplitsFormSimple from './splits-form-simple';

const AddPayeeModal = asModal(AddPayeeForm);

export default function SplitsFormContainer({ userGroupId }) {
  const {
    splitsData,
    setSplitsData,
    payees,
    contributors,
    splitsTotal,
    viewConfig,
    updateSplits,
    removeSplitByIndex,
    invalidateContributors,
    invalidatePayees,
  } = useReleaseStatusTrackContext();

  const addContributorModal = useDisclosure();
  const addPayeeModal = useDisclosure();

  const onChangeRowValue = (indexToUpdate, key, newData) => {
    setSplitsData(prevState => prevState.map(r => (r.idx !== indexToUpdate ? r : { ...r, [key]: newData })));
  };

  const onRemoveRow = indexToRemove => {
    removeSplitByIndex(indexToRemove);
  };

  const onAddNewRow = () =>
    updateSplits({
      payee: {},
      contributor: {},
      roles: [],
      percentage: undefined,
    });

  const [payeeModalInput, setPayeeModalInput] = useState({ name: '', email: '' });
  const [errors, setErrors] = useState([]);
  const Group = API.group();

  const handleSubmitPayeeModal = async () => {
    const existingPayee = payees.find(current => {
      const email = current?.payment_account?.user?.email;
      const inputEmail = payeeModalInput?.email;
      return !!email && !!inputEmail && email?.toLowerCase() === payeeModalInput?.email?.toLowerCase();
    });

    if (existingPayee) {
      setErrors([
        {
          detail:
            'This email is already associated with a current payee. You can close this dialog and select from the dropdown list instead.',
        },
      ]);
      return;
    }

    try {
      await Group.createPayeeIndividual(userGroupId, payeeModalInput.name, payeeModalInput.email);
      addPayeeModal.onClose();
    } catch (e) {
      setErrors([e]);
    } finally {
      await invalidatePayees();
    }
  };

  const { availablePayeeContributors } = viewConfig;
  const canAddSplit = splitsData.length <= payees.length * (contributors.length + 1);

  return (
    !viewConfig.isLoading && (
      <>
        <AddContributorModal
          userGroupId={userGroupId}
          variant="light"
          disclosure={addContributorModal}
          onSaved={invalidateContributors}
        />
        <AddPayeeModal
          variant="light"
          isOpen={addPayeeModal.isOpen}
          onClose={addPayeeModal.onClose}
          headerText="Add Payee"
          onSubmit={handleSubmitPayeeModal}
          errors={errors}
          payee={payeeModalInput}
          setPayee={setPayeeModalInput}
        />
        <SplitsFormSimple
          isEditing={viewConfig.isEditingSplits}
          splitsData={splitsData}
          payeesOptions={formatPayees(payees)}
          onChangeRowValue={onChangeRowValue}
          onAddNewRow={onAddNewRow}
          onRemoveRow={onRemoveRow}
          openContributorModal={addContributorModal.onOpen}
          openPayeeModal={addPayeeModal.onOpen}
          splitsTotal={splitsTotal}
          validate={viewConfig.validate}
          errors={viewConfig.errors}
          canAddSplit={canAddSplit}
          availablePayeeContributors={availablePayeeContributors}
        />
      </>
    )
  );
}
