import React from 'react';

import { Tooltip, Icon, Box } from '@chakra-ui/react';
const ToolTipIcon = React.forwardRef(({ icon, ...props }, ref) => (
  <Box display={'inline'} ref={ref}>
    <Icon as={icon} {...props} />
  </Box>
));

export default function IconedToolTip({ icon, iconProps, text, toolTipPlacement, ...props }) {
  return (
    <Tooltip hasArrow label={text} placement={toolTipPlacement || 'top'} {...props}>
      <ToolTipIcon icon={icon} {...iconProps} />
    </Tooltip>
  );
}

//The info tooltip already in the codebase ignores the color input. This is the chakra way of accomplishing the same task.
//https://chakra-ui.com/docs/components/tooltip#usage
