import React from 'react';

const { useTheme, Box, Heading, Text, Button } = require('@chakra-ui/react');

/**
 * @param {object} props
 * @param {string} props.text
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClose - use onClose method on chakra disclosure
 * @param {string} props.warningText
 * @param {object} props.confirmButton
 * @param {string} props.confirmButton.text
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.confirmButton.onClick
 * @param {boolean} props.showCancelButton
 */
function NoAccessModal({ text, onClose, warningText, confirmButton, showCancelButton }) {
  const theme = useTheme();

  return (
    <Box color={'black100'}>
      <Heading as="h4" color={theme.colors.black80} fontWeight="bold" fontSize="1.15rem" mb="1rem"></Heading>
      <Text marginBottom={'2rem'}>{text}</Text>
      {warningText ? (
        <Text color={theme.colors.pink} marginBottom={'2rem'}>
          {warningText}
        </Text>
      ) : null}
      <Box display="flex" justifyContent={'right'}>
        {showCancelButton ? (
          <Box mr="1rem">
            <Button variant="transparent" onClick={onClose}>
              {'Cancel'}
            </Button>
          </Box>
        ) : null}
        {confirmButton ? (
          <Button variant="smallDark" onClick={confirmButton?.onClick}>
            {confirmButton?.text}
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}

export default NoAccessModal;
