//The only way to know the actual size of an image is to load it and create an image out of it.
//That is why this validation function is pretty big.
import React, { useCallback } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useUploader } from '../../utils/uploader';

import DragAndDropImageUpload from 'components/DragAndDropUpload/drag-and-drop-image-upload';
import CardLoadingOverlay from 'components/loading-containers/card-loading-overlay';

export default function useUploadCoverArt({ currentFile, onImageLoaded, setImageErrorMessage, release }) {
  const { uploadFile } = useUploader();
  const { asset_release_id, asset_release_set_id } = release;
  let loading = false;
  async function uploadCoverArt(files) {
    loading = true;
    const file = files?.[0];
    const uploadPrimarySuccess = await uploadFile('coverArt', file, asset_release_id, asset_release_set_id);
    if (uploadPrimarySuccess) onImageLoaded(file);
    loading = false;
  }

  const disclosure = useDisclosure();

  const UploadCoverArt = useCallback(
    function UploadCoverArtCallback() {
      return (
        <>
          <DragAndDropImageUpload
            onChange={uploadCoverArt}
            currentFile={currentFile}
            name={'cover-art'}
            validatorFunction={imageValidatorFunction}
          />
          {loading ? (
            <CardLoadingOverlay text={'Uploading Image'} style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} />
          ) : (
            ''
          )}
        </>
      );
    },
    [currentFile, loading]
  );

  function imageValidatorFunction(files) {
    const file = files?.[0];
    const img = new Image();
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onload = function () {
        img.src = reader.result;
        img.onerror = e => {
          setImageErrorMessage(
            `An unkown error occured. Most likely the file is not a valid image or is corrupted. The file you uploaded was of type : ${file.type}`
          );
          disclosure.onOpen();
          return reject('invalid');
        };

        img.onload = () => {
          if (validate()) {
            return resolve(true);
          }
          return reject('invalid');
        };
      };
    });

    function validate() {
      if (!file) {
        setImageErrorMessage('No file was uploaded.');
        disclosure.onOpen();
        return false;
      }
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        setImageErrorMessage(
          `Invalid image type. Only jpg and png are supported. The file you uploaded was of type : ${file.type}`
        );
        disclosure.onOpen();

        return false;
      }

      if (Number(img.naturalWidth) !== 3000 || Number(img.naturalHeight) !== 3000) {
        setImageErrorMessage('Invalid image size. The image must be exactly 3000 x 3000 px');
        disclosure.onOpen();

        return false;
      }
      setImageErrorMessage(null);
      return true;
    }
  }

  return { UploadCoverArt, disclosure };
}
