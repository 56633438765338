//TECH DEBT this is deprecated. Do not use, and replace with Chakra Icon when possible
import React from 'react';

import styled from '@emotion/styled/macro';
import icons from '../../utils/icons';

const IconWrapper = styled.div`
  height: 1.063rem; // 17px
  width: 1.063rem;

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    path {
      color: ${props => props.theme.colors.black50};
    }
  `}

  &, * {
    display: inline-flex;
  }

  div,
  div > div,
  svg {
    height: 100%;
    width: 100%;
    path {
      color: ${props => props.color || 'inherit'};
    }
  }
`;

const Icon = React.memo(({ type, disabled, className, ...props }) => {
  const Icons = icons[type] || (() => null);
  return (
    <IconWrapper disabled={disabled} className={className} type={type} {...props}>
      <Icons />
    </IconWrapper>
  );
});

Icon.defaultProps = {
  type: 'exit',
  useSvgColor: false,
  color: null,
};

export default Icon;
