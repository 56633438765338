import React, { useEffect, useContext } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useGlobalData } from 'utils/global-data';
import useCardFlow from 'utils/use-card-flow';
import { useReleaseForm } from 'utils/use-release-form';
import { API_STATUS_KEYS } from 'constants/constants';
import ERRORS from 'constants/errors';

import { CardContext } from '../card-context';

import { CardContentWrapper, IconSpinner, InitialLoadContainer } from './card-style';

import { CARDS } from '../card';

const StartRelease = () => {
  const CARD_ID = CARDS.StartRelease;
  const { id, match, history, loadingAny } = useContext(CardContext);

  const { gotoNextCard, currentCardIndex, cardData, onExternalEvent, refreshLayoutState } = useCardFlow();

  const { release, selectedUserGroup, releaseStatus } = useGlobalData();

  const { createReleaseStatus } = useReleaseForm();

  const loadingCreateRelease =
    createReleaseStatus[API_STATUS_KEYS.IN_PROGRESS] && !createReleaseStatus[API_STATUS_KEYS.COMPLETE];

  const loadingGetRelease =
    releaseStatus.release[API_STATUS_KEYS.IN_PROGRESS] && !releaseStatus.release[API_STATUS_KEYS.COMPLETE];
  useEffect(() => {
    if (release) {
      refreshLayoutState();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release]);

  useEffect(() => {
    if (releaseStatus.release.complete && releaseStatus.release.error === ERRORS.RELEASE.NOT_FOUND) {
      history.replace('/');
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingGetRelease]);

  // ---------------------------------- StartRelease

  // if we're on the first card and there's a non-null group,
  //  no release is loading (or done), and no release is being created (or done),
  //  either create a release (if /new) or go to the next card (first data card)
  useEffect(() => {
    if (id === CARD_ID && selectedUserGroup && !loadingAny && !loadingCreateRelease) {
      if (match.url.indexOf('/new') !== -1) {
        handleCreateRelease();
      } else if (currentCardIndex === 0) {
        gotoNextCard();
      }
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, selectedUserGroup, loadingAny, loadingCreateRelease]);

  // trigger CREATE_RELEASE event that'll be picked up in ManageRelease
  const handleCreateRelease = () => {
    onExternalEvent({
      name: 'CREATE_RELEASE',
      data: { userGroupId: selectedUserGroup.id },
    });
  };

  return (
    <CardContentWrapper omnishareas>
      <Row>
        <Col xs={true}>
          <InitialLoadContainer>
            <h3>{`We're getting your release started`}</h3>
            <IconSpinner type="loader" style={{ height: '80px', width: '80px' }} />
          </InitialLoadContainer>
        </Col>
      </Row>
    </CardContentWrapper>
  );
};

export default StartRelease;
