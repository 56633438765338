import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Modal, ModalBody, ModalOverlay, ModalContent } from '@chakra-ui/react';

import TextField from 'features/TextField/text-field';
import Button from 'components/Button/button';
import { FlexBox, ButtonCol, Paragraph14 } from 'styles';

const TermsParagraph = styled(Paragraph14)`
  color: ${props => props.theme.colors.brand.textWhite};
  margin: 0 0 1rem;
`;

const EnterNameModal = ({ handleBackButton, disclosure, handleAccept }) => {
  const [name, setName] = useState();

  function updateName({ target: { value } }) {
    setName(value);
  }

  return (
    <Modal isOpen={disclosure.isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <TermsParagraph>Enter Your Name</TermsParagraph>
          <FlexBox>
            <TextField name="name" placeholder="My Name" id="name" required={true} onKeyUp={updateName} />
          </FlexBox>
          <ButtonCol xs={12}>
            <Button text="Back" tertiary onClick={handleBackButton} />
            <Button text="Continue" onClick={() => handleAccept({ name })} />
          </ButtonCol>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EnterNameModal;
