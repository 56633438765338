import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { ListItem } from '../../features/styles';
import Icon from 'components/Icon/icon';

const Wrapper = styled(ListItem)`
  align-items: center;
  background: ${props => props.theme.colors.background.multiCheckListItem[props.colorVariant].default};
  display: ${props => (props.visible ? 'flex' : 'none')};
  height: ${props => (props.short ? '2rem' : '3rem')};

  &:hover {
    background: ${props => props.theme.colors.background.multiCheckListItem[props.colorVariant].hover};
  }

  &:active {
    background: ${props => props.theme.colors.background.multiCheckListItem[props.colorVariant].active};
  }
`;

const CheckboxInput = styled.div`
  display: flex;
  margin-right: 0.75rem;
  padding: 0.85rem 0 0.85rem 1rem;

  ${props =>
    props.short &&
    `
		font-size: ${props.theme.fonts.sizes.xsm};
		padding: 0.7rem 0 0.7rem 1rem;
	`}
`;

const MultiCheckListItem = React.forwardRef(
  ({ className, dark, defaultChecked, isChecked, visible, disabled, light, name, label, short, ...props }, ref) => {
    const colorVariant = dark ? 'dark' : light ? 'light' : 'dark';

    return (
      <Wrapper className={className} colorVariant={colorVariant} visible={visible} {...props}>
        <CheckboxInput>
          <Icon
            color={
              disabled
                ? props.theme.colors.text.checkbox[colorVariant].disabled
                : props.theme.colors.text.checkbox[colorVariant].default
            }
            type={isChecked ? 'checkboxChecked' : 'checkboxEmpty'}
          />
        </CheckboxInput>
        {label}
        {/*
			<CheckboxInput
				defaultChecked={defaultChecked}
				label={label}
				name={name}
				ref={ref}
				short={short}
				dark={light}
				light={dark}
				colorVariant={colorVariant}
			/>*/}
      </Wrapper>
    );
  }
);

MultiCheckListItem.defaultProps = {
  defaultChecked: false,
  disabled: false,
  visible: true,
  error: null,
  name: 'MultiCheckList',
  options: [],
  short: false,
  label: 'CheckList Item',
  light: false,
  dark: false,
};
MultiCheckListItem.displayName = 'MultiCheckListItem';

export default withTheme(MultiCheckListItem);
