import React from 'react';
import { Tr, useTheme } from '@chakra-ui/react';

function RowWrapper({ RowComponent, onRowSelected, children, idx, row, domainData }) {
  const theme = useTheme();

  const rowHoverStyle = onRowSelected ? { cursor: 'pointer', backgroundColor: theme.colors['black05'] } : {};
  const handleRowClick = () => (onRowSelected ? onRowSelected(row.original, domainData) : true);

  const ComponentToRender = RowComponent || Tr;

  return (
    <ComponentToRender theme={theme} _hover={rowHoverStyle} idx={idx} onClick={handleRowClick}>
      {children}
    </ComponentToRender>
  );
}

export default RowWrapper;
