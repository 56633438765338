import * as API from '../utils/API/API';
import { useQuery } from 'react-query';

const DSP = API.dsp();

export const useAllDsps = () => {
  const queryKey = [`dsps`];
  const dspQuery = useQuery(queryKey, DSP.getAll, {
    staleTime: 1000 * 60 * 60 * 24,
  });
  return [dspQuery.data || [], dspQuery];
};
