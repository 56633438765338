import React from 'react';
import { Box, Heading, Table, Thead, Tbody, Td, Th, Tr, Image, useTheme } from '@chakra-ui/react';
import { useReleaseArtwork } from 'hooks/use-release-artwork';

const ThNoB = ({ children }) => <Th border="none">{children}</Th>;
const IMAGE_SIZE_PX = '50px';

function TrackTableRow(props) {
  const theme = useTheme();
  const { handleCreateClick, handleCopyToClipboard, buttonColumn } = props;
  const releaseId = props?.release.id;
  const imgUrl = useReleaseArtwork(releaseId);

  return (
    <Tr>
      <Td>
        <Image src={imgUrl} width={IMAGE_SIZE_PX} height={IMAGE_SIZE_PX} alt={`${props.title} cover`} />
      </Td>
      <Td>{props.title}</Td>
      <Td>{props.release.title}</Td>
      <Td>{props.release.type}</Td>
      <Td>{props.release.display_artist_name}</Td>
      <Td>
        {buttonColumn({
          marketingLinkTrack: props?.trackMarketingLinks?.[0],
          handleCreateClick,
          handleCopyToClipboard,
          imgUrl,
          title: props.title,
          artistName: props.release.display_artist_name,
          releaseId,
          trackId: props.asset_track_id,
        })}
      </Td>
    </Tr>
  );
}

function MarketingLinksTable({ tracks, handleCreateClick, handleCopyToClipboard, buttonColumn }) {
  return (
    <Table>
      <Thead background="none">
        <Tr _hover={{ backgroundColor: 'transparent' }} borderBottom={`1px solid #ddd`}>
          <ThNoB></ThNoB>
          <ThNoB>Trackname</ThNoB>
          <ThNoB>Release</ThNoB>
          <ThNoB>Release Type</ThNoB>
          <ThNoB>Artist</ThNoB>
          <ThNoB></ThNoB>
        </Tr>
      </Thead>
      <Tbody>
        {tracks.map(t => (
          <TrackTableRow
            {...t}
            key={t.id}
            handleCreateClick={handleCreateClick}
            handleCopyToClipboard={handleCopyToClipboard}
            buttonColumn={buttonColumn}
          />
        ))}
      </Tbody>
    </Table>
  );
}

export default function Tracks({ tracks, handleCreateClick, handleCopyToClipboard, buttonColumn }) {
  if (tracks?.length) {
    return (
      <Box>
        <Heading as="h2" fontSize="xl" mt="3rem">
          Tracks
        </Heading>
        <Box>
          <MarketingLinksTable
            tracks={tracks}
            handleCreateClick={handleCreateClick}
            handleCopyToClipboard={handleCopyToClipboard}
            buttonColumn={buttonColumn}
          />
        </Box>
      </Box>
    );
  }

  return null;
}
