import React from 'react';
import { Button, Icon } from '@chakra-ui/react';

const variants = {
  lightPink: {
    color: 'white',
    backgroundColor: 'pink',
  },
};

export default function IconButton({
  variant = 'lightPink',
  children = 'Click here',
  icon,
  iconSize = 5,
  borderRadius = '2rem',
  style,
  ...props
}) {
  const v = variants[variant];
  return (
    <Button
      color={v?.color}
      backgroundColor={v?.backgroundColor}
      _hover={{ filter: 'brightness(115%)' }}
      borderRadius={borderRadius}
      style={style}
      leftIcon={!icon ? null : <Icon boxSize={iconSize} as={icon} aria-label="sorted descending" />}
      {...props}>
      {children}
    </Button>
  );
}
