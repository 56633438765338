import React from 'react';
import { Input, FormControl, FormLabel, FormErrorMessage, useTheme, Tooltip } from '@chakra-ui/react';
import { RiInformationFill as InfoIcon } from 'react-icons/ri';
import IconedToolTip from 'components/IconedToolTip/IconedToolTip';

export default function LabeledInput({
  name,
  htmlId,
  label,
  value,
  onChange,
  onBlur,
  variant,
  invalidMessage,
  tooltip,
  hasToolTipIcon = true, //this only applies if there is a tooltip
  ...inputProps
}) {
  const theme = useTheme();

  //Since this insn't a real chakra component variants do not work as the other elements do.
  // An attempt to make this a terue chakra multipart compoent caused
  //the input to loose focus on typeing. So this way of doing it was landed on.
  const {
    div: divStyle,
    label: labelStyle,
    input: inputStyle,
  } = variant ? theme.components.LabeledInput.variants[variant] : theme.components.LabeledInput?.baseStyle || {};

  if (!name) console.error('LabeledInput requires a name property.');

  htmlId = htmlId || name;

  return (
    <FormControl {...divStyle} isInvalid={!!invalidMessage}>
      {!!tooltip && hasToolTipIcon ? (
        <FormLabel
          {...labelStyle}
          htmlFor={htmlId}
          display={'flex'}
          color={invalidMessage ? theme.colors.red100 : 'inherit'}>
          {label}
          <IconedToolTip
            iconProps={{ marginTop: '0.25rem', marginLeft: '0.25rem', size: 3 }}
            icon={InfoIcon}
            text={tooltip}
          />
        </FormLabel>
      ) : (
        <Tooltip label={tooltip} placement="top-start">
          <FormLabel {...labelStyle} htmlFor={htmlId} color={invalidMessage ? theme.colors.red100 : 'inherit'}>
            {label}
          </FormLabel>
        </Tooltip>
      )}
      <Input
        name={name}
        id={htmlId}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...inputStyle}
        {...inputProps}
      />
      {invalidMessage ? <FormErrorMessage>{invalidMessage}</FormErrorMessage> : ''}
    </FormControl>
  );
}
