import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled/macro';
import { useFormContext } from 'react-hook-form';

import Icon from 'components/Icon/icon';

import { renderFieldError, renderFieldWarning } from 'utils/form';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  background: ${props => props.theme.colors.background.textInput[props.colorVariant].default};
  border: 1px solid ${props => props.theme.colors.border.textInput[props.colorVariant].default};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.text.textInput[props.colorVariant].default};
  font-size: ${props => props.theme.fonts.sizes.reg};
  line-height: 18px;
  margin: 0;
  padding: 0.75rem 1rem;
  transition: all 250ms ease;
  width: 100%;
  ::placeholder {
    color: ${props => props.theme.colors.text.textInput[props.colorVariant].placeholder};
  }

  &:hover {
    ${props =>
    !props.disabled &&
      `
      background: ${props.theme.colors.background.textInput[props.colorVariant].hover};
      border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant].hover};
      color: ${props.theme.colors.text.textInput[props.colorVariant].hover};
    `}
  }

  ${props =>
    props.icon &&
    `
    padding-left: 2.25rem;
  `}

  ${props =>
    props.warning &&
    `
    background: ${props.theme.colors.background.textInput[props.colorVariant].warning};
    border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant].warning};
    color: ${props.theme.colors.text.textInput[props.colorVariant].warning};
  `}

  ${props =>
    props.invalid &&
    `
    background: ${props.theme.colors.background.textInput[props.colorVariant].error};
    border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant].error};
    color: ${props.theme.colors.text.textInput[props.colorVariant].error};
  `}

  ${props =>
    props.divInput &&
    `
    color: transparent;

    ::-moz-placeholder {
      color: ${props.theme.colors.brand.layer4};
      opacity: 1;
    }
  `}

  ${props =>
    props.disabled &&
    `
    background: ${props.theme.colors.background.textInput[props.colorVariant].disabled};
    border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant].disabled};
    color: ${props.theme.colors.text.textInput[props.colorVariant].disabled};

    ::-moz-placeholder {
      color: ${props.theme.colors.text.textInput[props.colorVariant].disabled};
      opacity: 1;
    }
  `}

  ${props =>
    props.phantom &&
    `
    background: ${props.theme.colors.background.textInput[props.colorVariant].phantom};
    border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant].phantom};
    color: ${props.theme.colors.text.textInput[props.colorVariant].phantom};
  `}
`;

const IconWrapper = styled(Icon)`
  margin-left: 1rem;
  margin-top: 14px;
  position: absolute;
  width: 0.8rem;
`;

const DivInput = styled.div`
  border: 1px solid transparent;
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.text.textInput[props.colorVariant].default};
  font-size: ${props => props.theme.fonts.sizes.reg};
  height: 100%;
  left: 0;
  line-height: 18px;
  overflow: hidden;
  padding: 0.75rem 1rem;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 100%;
  z-index: 10;

  * {
    display: inline;
    white-space: nowrap;
  }

  &:hover {
    ${props =>
    !props.disabled &&
      `
      ~ input {
        background: ${props.theme.colors.background.textInput[props.colorVariant].hover};
        border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant].hover};
      }
    `}
  }
`;

const TextInput = React.forwardRef(
  (
    {
      name,
      className,
      icon,
      error,
      autoFocus,
      autoComplete,
      placeholder,
      defaultValue,
      light,
      dark,
      short,
      password,
      divInput,
      disabled,
      onChange,
      onKeyUp,
      heapCode,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(null);

    const formContext = useFormContext();
    const refDivInput = useRef(null);

    const colorVariant = dark ? 'dark' : light ? 'light' : 'dark';

    const hasError = error && error?.type !== 'warning';
    const hasWarning = error && error?.type === 'warning';

    useEffect(() => {
      // set default value inside contenteditable div
      if (divInput && defaultValue !== null && refDivInput.current) {
        refDivInput.current.innerText = defaultValue;
      }
      // DEBT:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    const inputOverlayOnChangeHandler = e => {
      setInputValue(e.target.innerText);
      onChange({ ...e, target: { ...e.target, value: e.target.innerText } });
    };

    const updateHookForm = inputProps => {
      if (formContext) {
        const { setValue, watch } = formContext;

        // trim() to get around weird FF bug — infinite loop on space in watch value
        if (watch(name)?.trim() !== inputProps.value?.trim()) {
          setValue(name, inputProps.value, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
    };

    const inputProps = { ...props };

    if (divInput) {
      inputProps.value = inputValue !== null ? inputValue : defaultValue || '';
      inputProps.tabIndex = -1;

      delete inputProps.defaultValue;
      updateHookForm(inputProps);

      // set after-update value on contenteditable div
      if (refDivInput.current && refDivInput.current.innerText !== inputProps.value) {
        refDivInput.current.innerText = inputProps.value;
      }
    } else {
      inputProps.defaultValue = defaultValue;
    }

    // Listeners

    const handleKeyUp = e => {
      onKeyUp(e);
    };

    const handlePaste = e => {
      let pasted = (e.clipboardData || window.clipboardData).getData('text');
      pasted = pasted.substring(0, 100).replace(/\n/g, '');
      let newContent = pasted;

      try {
        const sel = window.getSelection();
        const selRange = sel.getRangeAt(0);
        const newRange = document.createRange();
        const text = refDivInput.current.innerText;
        const cursor = selRange.startOffset;

        newContent = `${text.substring(0, selRange.startOffset)}${pasted}${text.substring(
          selRange.endOffset,
          text.length
        )}`;

        // set value
        refDivInput.current.innerText = newContent;
        setInputValue(newContent);

        // set cursor
        newRange.setStart(refDivInput.current.childNodes[0], cursor + pasted.length);
        newRange.collapse(true);

        sel.removeAllRanges();
        sel.addRange(newRange);
      } catch (err) {
        // Log the following and ignore the error due to browser behavior on paste
        // eslint-disable-next-line no-console
        console.info(err);
      }

      onChange({ ...e, target: { ...e.target, value: newContent } });
      e.preventDefault();
    };

    return (
      <Wrapper>
        {divInput && !disabled && (
          <DivInput
            contentEditable="true"
            colorVariant={colorVariant}
            onInput={inputOverlayOnChangeHandler}
            onKeyUp={handleKeyUp}
            onKeyDown={e => e.keyCode === 13 && e.preventDefault()}
            onPaste={handlePaste}
            disabled={disabled}
            ref={refDivInput}
          />
        )}
        <Input
          className={className}
          icon={icon}
          name={name}
          invalid={hasError}
          warning={hasWarning}
          type={password ? 'password' : divInput ? 'text' : 'text'}
          autoFocus={autoFocus}
          colorVariant={colorVariant}
          autoComplete={autoComplete || 'new-password'}
          autocomplete={autoComplete || 'new-password'}
          placeholder={placeholder}
          short={short}
          disabled={disabled}
          divInput={divInput}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          ref={ref}
          data-heap={heapCode}
          {...inputProps}
        />
        {icon && <IconWrapper type={icon} />}
        {hasError && renderFieldError(error)}
        {hasWarning && renderFieldWarning(error)}
      </Wrapper>
    );
  }
);

TextInput.displayName = 'TextInput';

TextInput.defaultProps = {
  icon: null,
  placeholder: '',
  defaultValue: null,
  disabled: false,
  name: null,
  password: false,
  divInput: false,
  onChange: () => {},
  onKeyUp: () => {},
  heapCode: null,
};

export default TextInput;
