import React, { useState } from 'react';

import { Flex, useTheme, Button } from '@chakra-ui/react';
import { RiInformationLine as InfoIcon } from 'react-icons/ri';

import IconedToolTip from 'components/IconedToolTip/IconedToolTip';

const TabNav = ({ options = [], selected, disabled = [] }) => {
  const theme = useTheme();
  const [selectedIndex, setSelectedIndex] = useState(selected);

  const handleClick = index => {
    setSelectedIndex(index);
    options[index].onClick(index);
  };

  return (
    <Flex borderBottom={`3px solid ${theme.colors.black20} `}>
      {options.map((option, i) => (
        <Flex
          key={`${i}-${option.label}`}
          alignItems="center"
          marginRight="1rem"
          lineHeight="1rem"
          position="relative"
          top=".15rem"
          borderBottom={`3px solid ${selected === i ? theme.colors.black80 : theme.colors.black20}`}>
          <Button
            variant={'transparent'}
            onClick={() => handleClick(i)}
            disabled={disabled.includes(i)}
            color={theme.colors.black80}>
            {option.label}
          </Button>
          {option.tooltip && (
            <IconedToolTip
              text={option.tooltip}
              icon={InfoIcon}
              iconProps={{
                color: i === selectedIndex ? theme.colors.black100 : theme.colors.black60,
                marginLeft: '0.25rem',
              }}
            />
          )}
        </Flex>
      ))}
    </Flex>
  );
};

TabNav.displayName = 'TabNav';

export default TabNav;
