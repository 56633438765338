import React, { useEffect, useContext } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useForm } from 'react-hook-form';
import { useGlobalData } from 'utils/global-data';
import useCardFlow from 'utils/use-card-flow';
import Button from 'components/Button/button';
import CardButton from 'components/CardButton/card-button';

import { CardContext } from '../card-context';

import { ButtonCol, Paragraph14 } from 'styles';

import { CardLoadingWrapper, CardContentWrapper, CardHeaderBlock } from './card-style';

import { CARDS } from '../card';
import { SUPPORT_LINK } from 'constants/constants';
import HEAP from '../../../constants/HEAP.gen.json';

const IncludePreOrder = () => {
  const CARD_ID = CARDS.IncludePreOrder;
  const {
    id,
    getCardData,
    handleResetSaveAndPreview,
    NAV_DIRECTIONS,
    afterSaveActions,
    loadingAny,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { release } = useGlobalData();

  const {
    cardData,

    updateCard_Form,
    updateMultipleCards,
    isReviewMode,
    nullObjects,
    _passthrough,
  } = useCardFlow();

  const formMethods_IncludePreOrder = useForm();
  const formValues_IncludePreOrder = formMethods_IncludePreOrder.getValues({
    nest: true,
  });
  const isEndOfFlow_IncludePreOrder = formMethods_IncludePreOrder.watch('_meta.includePreOrder') === 'no';

  useEffect(() => {
    if (id === CARD_ID) {
      formMethods_IncludePreOrder.reset(getCardData('formData'));
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_IncludePreOrder.handleSubmit(data => save_IncludePreOrder(data, _passthrough.inducePreview))();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  const includePreOrderChangedHandler = includePreOrder => {
    updateCard_Form(id, {
      _meta: { includePreOrder: includePreOrder ? 'yes' : 'no' },
    });
    formMethods_IncludePreOrder.setValue('_meta.includePreOrder', includePreOrder ? 'yes' : 'no');
  };

  const save_IncludePreOrder = (data, forcePreview = false, navigation = null) => {
    const saveData = { ...formValues_IncludePreOrder, ...data };

    if (isEndOfFlow_IncludePreOrder) {
      updateMultipleCards(release ? nullObjects.preRelease : {});
    } else {
      updateCard_Form(id, saveData);
    }

    const showPreview =
      navigation !== NAV_DIRECTIONS.BACK && (isEndOfFlow_IncludePreOrder || isReviewMode || forcePreview);
    afterSaveActions(showPreview, navigation);
  };

  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <input
          name="_meta.includePreOrder"
          type="hidden"
          ref={formMethods_IncludePreOrder.register({ required: false })}
        />
        <CardHeaderBlock omnishareas>
          <Row>
            <Col xs={6}>
              <h3>Pre-Order</h3>
            </Col>
            <ButtonCol xs={6}>
              <ButtonCol xs={12}>
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={formMethods_IncludePreOrder.handleSubmit(data =>
                      save_IncludePreOrder(data, false, NAV_DIRECTIONS.BACK)
                    )}
                    disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_PRERELEASE_BUTTON_BACK}
                  />
                )}
                <Button
                  text={isEndOfFlow_IncludePreOrder || isReviewMode ? 'Save & Preview' : 'Next'}
                  rightIcon={isEndOfFlow_IncludePreOrder || isReviewMode ? null : 'chevronRight'}
                  loading={loadingSaveRelease || loadingAny}
                  onClick={formMethods_IncludePreOrder.handleSubmit(data =>
                    save_IncludePreOrder(data, false, NAV_DIRECTIONS.FORWARD)
                  )}
                  disabled={getCardData(CARD_ID) === null || isFlowAnimating}
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_PRERELEASE_BUTTON_NEXT}
                />
              </ButtonCol>
            </ButtonCol>
          </Row>
          <Row>
            <Col xs={12}>
              <Paragraph14>
                Pre-order information will be sent to Apple and Amazon only. Visit our{' '}
                <a href={SUPPORT_LINK} target="_blank" rel="noopener noreferrer">
                  FAQ
                </a>{' '}
                for more info.
              </Paragraph14>
            </Col>
          </Row>
        </CardHeaderBlock>
        <Row>
          <Col xs={12} md={6}>
            <div style={{ display: 'inline-flex', width: '100%' }}>
              <CardButton
                icon="circleCheck"
                title="Yes"
                selected={formMethods_IncludePreOrder.watch('_meta.includePreOrder') === 'yes'}
                disabled={loadingSaveRelease || loadingAny}
                onClick={() => includePreOrderChangedHandler(true)}
                heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_PREORDER_BUTTON_YES}
              />
              <CardButton
                icon="circleSlash"
                title="No"
                selected={formMethods_IncludePreOrder.watch('_meta.includePreOrder') === 'no'}
                disabled={loadingSaveRelease || loadingAny}
                onClick={() => includePreOrderChangedHandler(false)}
                heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_PREORDER_BUTTON_NO}
              />
            </div>
          </Col>
        </Row>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};

export default IncludePreOrder;
