import React from 'react';
import { Td } from '@chakra-ui/react';
import numeral from 'numeral';

// STATEMENT_TABLE_COLUMNS
const ARTIST = 'Artist';
const TRACK_NAME = 'Track Name';
const CONTRIBUTOR_NAME = 'Contributor Name';
const ROLE = 'Role';
const STREAMS = 'Streams';
const DOWNLOADS = 'Downloads';
const DISTRO_FEE = 'Distro Fee';
const RECOUPED = 'Recouped';
const REVENUE = 'Revenue';
const SPLIT_STREAMS = 'Streams Split';
const SPLITS = 'Split';
const TOTAL_NET_ROYALTY = ' Total Net Royalty';
const US_CA = 'US/CA';

export const HIDDEN_TABLE_COLUMN = 'venice/HIDDEN_TABLE_COLUMN'; // keep tables in alignment

const TextCell = ({ value }) => <Td>{value}</Td>;
const FormattedNumber = ({ value, numeralFormatString }) => (
  <Td textAlign="end">{numeral(value).format(numeralFormatString)}</Td>
);

// TODO: ask about text format
const DollarAmountCell = ({ value }) => (
  <FormattedNumber value={value} numeralFormatString={value < 0.01 && value > 0 ? '$0,0.000' : '$0,0.00'} />
);
const PercentageAmountCell = ({ value }) => <FormattedNumber value={value} numeralFormatString={'0.00%'} />;
const SplitPercentageAmount = ({ value }) => {
  const adjustedNumber = value / 100;
  return <PercentageAmountCell value={adjustedNumber} />;
};
const BooleanCell = ({ value }) => <Td textAlign="center">{value ? '✓' : ''}</Td>;

const EmptyCell = () => <Td />; // keep tables in alignment

const ARTIST_COLUMN_CONFIG = {
  Header: ARTIST,
  accessor: 'display_artist_name',
  CellComponent: TextCell,
  dynamicWidthConfig: {
    targetWidth: 35,
    minWidth: 32,
  },
};
const TRACK_NAME_COLUMN_CONFIG = {
  Header: TRACK_NAME,
  accessor: 'title',
  CellComponent: TextCell,
  dynamicWidthConfig: {
    targetWidth: 65,
    minWidth: 55,
  },
};
const IS_US_CA_COLUMN_CONFIG = {
  Header: US_CA,
  accessor: 'is_us_ca',
  CellComponent: BooleanCell,
  headerStyle: { width: '7rem' },
};
const STREAMS_COLUMN_CONFIG = {
  Header: STREAMS,
  accessor: 'streams_revenue',
  CellComponent: DollarAmountCell,
  headerStyle: { width: '7rem' },
};
const DOWNLOADS_COLUMN_CONFIG = {
  Header: DOWNLOADS,
  accessor: 'downloads_revenue',
  CellComponent: DollarAmountCell,
  headerStyle: { width: '7.5rem' },
};
const DISTRO_FEE_COLUMN_CONFIG = {
  Header: DISTRO_FEE,
  accessor: 'distro_fee_percentage',
  CellComponent: PercentageAmountCell,
  headerStyle: { width: '7rem' },
};
const RECOUPED_COLUMN_CONFIG = {
  Header: RECOUPED,
  accessor: 'recoup_amount',
  CellComponent: DollarAmountCell,
  headerStyle: { width: '7rem' },
};
const REVENUE_COLUMN_CONFIG = {
  Header: REVENUE,
  accessor: 'pre_split_royalty_usd',
  CellComponent: DollarAmountCell,
  headerStyle: { width: '7rem' },
};
const SPLIT_COLUMN_CONFIG = {
  Header: SPLIT_STREAMS,
  accessor: 'split_pct',
  CellComponent: SplitPercentageAmount,
  headerStyle: { width: '6rem' },
};
const SPLIT_DOWNLOADS_COLUMN_CONFIG = { ...SPLIT_COLUMN_CONFIG, Header: SPLITS };
const TOTAL_NET_ROYALTY_COLUMN_CONFIG = {
  Header: TOTAL_NET_ROYALTY,
  accessor: 'net_royalty_usd',
  CellComponent: DollarAmountCell,
  headerStyle: { width: '7rem' },
};

const CONTRIBUTOR_NAME_COLUMN_CONFIG = {
  Header: CONTRIBUTOR_NAME,
  accessor: 'contributor_name',
  CellComponent: TextCell,
  dynamicWidthConfig: {
    targetWidth: 33.3,
    minWidth: 30,
  },
};

const ROLE_COLUMN_CONFIG = {
  Header: ROLE,
  accessor: 'contributor_role',
  CellComponent: TextCell,
  headerStyle: { width: '6rem' },
};

export const labelView = {
  trackRevenueTable: {
    title: 'Track Revenue',
    columns: [
      ARTIST_COLUMN_CONFIG,
      TRACK_NAME_COLUMN_CONFIG,
      STREAMS_COLUMN_CONFIG,
      DOWNLOADS_COLUMN_CONFIG,
      DISTRO_FEE_COLUMN_CONFIG,
      RECOUPED_COLUMN_CONFIG,
      REVENUE_COLUMN_CONFIG,
      SPLIT_COLUMN_CONFIG,
      TOTAL_NET_ROYALTY_COLUMN_CONFIG,
    ],
    style: {
      tableLayout: 'fixed',
      minWidth: '65rem',
    },
  },
  albumDownloadTable: {
    title: 'Album Downloads',
    columns: [
      ARTIST_COLUMN_CONFIG,
      TRACK_NAME_COLUMN_CONFIG,
      //headerStyle needs to match STREAMS_COLUMN_CONFIG
      IS_US_CA_COLUMN_CONFIG,
      DOWNLOADS_COLUMN_CONFIG,
      DISTRO_FEE_COLUMN_CONFIG,
      RECOUPED_COLUMN_CONFIG,
      REVENUE_COLUMN_CONFIG,
      SPLIT_DOWNLOADS_COLUMN_CONFIG,
      TOTAL_NET_ROYALTY_COLUMN_CONFIG,
    ],
    style: {
      tableLayout: 'fixed',
      minWidth: '65rem',
    },
  },
};

const PAYEE_ARTIST_COLUMN_CONFIG = {
  ...ARTIST_COLUMN_CONFIG,
  dynamicWidthConfig: {
    targetWidth: 33.3,
    minWidth: 18,
  },
};

const PAYEE_TRACK_NAME_COLUMN_CONFIG = {
  ...TRACK_NAME_COLUMN_CONFIG,
  dynamicWidthConfig: {
    targetWidth: 33.3,
    minWidth: 30,
  },
};

export const payeeView = {
  trackRevenueTable: {
    title: 'Track Revenue',
    columns: [
      PAYEE_ARTIST_COLUMN_CONFIG,
      PAYEE_TRACK_NAME_COLUMN_CONFIG,
      CONTRIBUTOR_NAME_COLUMN_CONFIG,
      ROLE_COLUMN_CONFIG,
      STREAMS_COLUMN_CONFIG,
      DOWNLOADS_COLUMN_CONFIG,
      SPLIT_COLUMN_CONFIG,
      TOTAL_NET_ROYALTY_COLUMN_CONFIG,
    ],
    style: {
      tableLayout: 'fixed',
      minWidth: '65rem',
    },
  },
  albumDownloadTable: {
    title: 'Album Downloads',
    columns: [
      PAYEE_ARTIST_COLUMN_CONFIG,
      PAYEE_TRACK_NAME_COLUMN_CONFIG,
      CONTRIBUTOR_NAME_COLUMN_CONFIG,
      ROLE_COLUMN_CONFIG,
      //headerStyle needs to match STREAMS_COLUMN_CONFIG
      { Header: STREAMS, accessor: HIDDEN_TABLE_COLUMN, CellComponent: EmptyCell, headerStyle: { width: '7rem' } },
      DOWNLOADS_COLUMN_CONFIG,
      SPLIT_COLUMN_CONFIG,
      TOTAL_NET_ROYALTY_COLUMN_CONFIG,
    ],
    style: {
      tableLayout: 'fixed',
      minWidth: '65rem',
    },
  },
};
