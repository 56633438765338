import React, { memo, useState, useRef } from 'react';
import { Button, Checkbox, Text, useTheme } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import useFormState, { FormStates } from '../../state-management/use-form-state';
import asModal from '../Modal/as-modal';

const DspItem = ({ dspName, selectDsp, isSelected }) => {
  return (
    <div>
      <Checkbox isChecked={isSelected} onChange={selectDsp}>
        {dspName}
      </Checkbox>
    </div>
  );
};

const DspsSelector = ({ defaultSelections, onSelectDsps, dspOptions }) => {
  const hasDefaultSelections = Array.isArray(defaultSelections);
  const [formState, updateState] = useFormState(
    {
      dsp_platforms: hasDefaultSelections ? defaultSelections : [],
      allSelected: !hasDefaultSelections ? false : defaultSelections?.length === dspOptions.length,
    },
    {
      dsp_platforms: {
        [FormStates.DIRTY]: (value, state) => {
          state.allSelected = value.length === dspOptions.length;
        },
      },
      allSelected: {
        [FormStates.DIRTY]: (value, state) => {
          state.dsp_platforms = value ? dspOptions.map(dsp => dsp.value) : [];
        },
      },
      _after: {
        [FormStates.DIRTY]: (value, state) => {
          onSelectDsps(state.dsp_platforms);
        },
      },
    }
  );

  const toggleSelectAll = () => {
    updateState(({ allSelected: currentValue }) => {
      return {
        allSelected: !currentValue,
      };
    });
  };

  const selectDsp = dsp => {
    updateState(({ dsp_platforms: current }) => {
      const dsp_platforms = current.includes(dsp) ? current.filter(t => t !== dsp) : [...current, dsp];
      return {
        dsp_platforms,
      };
    });
  };

  return (
    <>
      <Checkbox isChecked={formState.allSelected} onChange={toggleSelectAll}>
        Select All
      </Checkbox>
      <div style={{ border: `1px solid #808080`, marginTop: '.25rem', marginBottom: '.25rem' }} />
      {dspOptions.map(({ name, value }) => {
        return (
          <DspItem
            key={value}
            dspName={name}
            selectDsp={() => selectDsp(value)}
            isSelected={formState.dsp_platforms.includes(value)}
          />
        );
      })}
    </>
  );
};

const MenuContainer = ({ defaultSelections, onSelectDsps, dspOptions = [], isValid }) => {
  const [isOpen, setIsOpen] = useState(false);
  const DspsModal = asModal(DspsSelector);

  const theme = useTheme();

  const rightIcon = isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;
  const selectionsRef = useRef(defaultSelections);

  const toggleOpen = () => {
    setIsOpen(open => !open);
  };

  const onSubmit = () => {
    setIsOpen(false);
    onSelectDsps(selectionsRef.current);
  };

  return (
    <>
      <Button
        onClick={toggleOpen}
        variant={isValid ? '' : 'invalid'}
        width="20rem"
        justifyContent="space-between"
        fontWeight="normal"
        rightIcon={rightIcon}
        background={'white'}>
        DSPs Selected ({defaultSelections?.length || 0})
      </Button>
      {!isValid ? <Text color={theme.colors.red100}>You must have at least 1 dsp</Text> : ''}
      <DspsModal
        headerProps={{ fontSize: '2xl' }}
        defaultSelections={defaultSelections}
        onSelectDsps={selections => (selectionsRef.current = selections)}
        dspOptions={dspOptions}
        scrollBehavior={'inside'}
        variant={'light'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        headerText={'Select DSPs'}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default memo(MenuContainer);
