const FEATURING_IN_TITLE_ERROR_MESSAGE = `Featured or collaborating artist's names cannot be referenced in your release or track titles directly. You will add featured or collaborating artists on the Release Artists page of the create release process.`;

const REQUIRED_FIELD_MSG = 'This field is required';

const doesStringContainFeaturingPermutation = /^.*[^\w](feat\.?|ft\.?|featuring)\s+.+/i;

/**
 * Tests title against regex to determine if it's valid
 * Also defined in: node/src/controllers/utils/determine-is-title-valid.js
 * @param {string} title - A Release or Track title
 * @returns {boolean} - is title valid
 */
function determineIsTitleValid(title) {
  return !doesStringContainFeaturingPermutation.test(title);
}

export const validateTitle = (title, options = {}) => {
  const { isRequired = false, isFeaturingBlocked = true } = options;

  if (isRequired && !title.trim()) {
    return { isValid: false, errorMessage: REQUIRED_FIELD_MSG };
  }

  if (isFeaturingBlocked && !determineIsTitleValid(title)) {
    return { isValid: false, errorMessage: FEATURING_IN_TITLE_ERROR_MESSAGE };
  }

  return { isValid: true };
};
