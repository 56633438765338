import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import styled from '@emotion/styled/macro';
import { Box, Text, Flex } from '@chakra-ui/react';

import GhostableRow from 'components/GhostableRow/ghostable-row';
import Dropdown from 'components/Controls/dropdown';
import FieldLabel from 'components/FieldLabel/field-label';
import StyledSelect from 'components/StyledSelect/styled-select';
import Button from 'components/Button/button';
import PlatformIds from 'components/PlatformIds/platform-ids';

import ENUMS from '../../constants/ENUMS.gen.json';
import HEAP from '../../constants/HEAP.gen.json';

const {
  SOUND_RECORDING_ROLE: { REMIXER, REMIXED_ARTIST, MAIN_ARTIST },
} = ENUMS;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArtistDropdown = styled(Dropdown)`
  height: 2.75rem;
`;

//Adds roles that are expected in con-junction with other roles in our ddex.
//IE main artist with remixer, or remixed artist whenever a none-remixer artist is present
function formatArtistRoles(roles = [], remixerExistsInPartyGroup) {
  const roleSet = new Set([...roles]);
  const isRemixer = roleSet.has(REMIXER);

  if (remixerExistsInPartyGroup) {
    if (isRemixer) {
      //Remixers should always be seen as main artists.
      roleSet.add(MAIN_ARTIST);
      roleSet.add(REMIXER);
    } else {
      //If a single remixer exists in a group of artists then everyone
      //not consided a remixer should be a remixed artist because their
      //work has been remixed.
      roleSet.add(REMIXED_ARTIST);
    }
  } else {
    roleSet.delete(REMIXER);
    roleSet.delete(REMIXED_ARTIST);
  }
  return [...roleSet];
}

const PartyArray = ({
  entityLabel,
  artistRoleOptions = [],
  errors,
  disabled,
  heapCodePrefix,
  artistsList = [],
  status,
  onArtistModalOpen,
  artists,
  setArtists,
  setActiveIndex,
  setError,
}) => {
  const gridTemplateColumns = '3fr 3fr 5fr 1fr';
  const [loadingStatus, setLoadingStatus] = React.useState(status);
  const [artistDropdownOptions, setArtistDropdownOptions] = useState([]);

  useEffect(() => {
    const dropdownOptions = artistsList
      ? artistsList.map(artist => ({
        label: artist?.full_name || '',
        value: artist.id,
      }))
      : [];

    setArtistDropdownOptions(dropdownOptions);
  }, [artistsList]);

  const artistRoleSet = useMemo(() => new Set(artistRoleOptions.map(x => x.value)), [artistRoleOptions]);

  useEffect(() => {
    setLoadingStatus(status);
  }, [status]);

  const updateArtist = useCallback(
    (updatedArtistIndex, artistId, artistRoles) =>
      setArtists(previousArtistArray => {
        const artistArrayCopy = [...previousArtistArray];

        //We need to overwrite the current artist with the expected artist then apply roles. Finally we alter all aritsts roles in case a remixer was added or removed.
        const matchingArtist = artistsList.find(a => a.id === artistId);
        if (matchingArtist) {
          artistArrayCopy[updatedArtistIndex] = { ...matchingArtist, roles: artistRoles };
        }

        const allRoles = artistArrayCopy.map(artist => artist.roles).flat();
        const rolesContainRemixer = allRoles.find(role => role === REMIXER);

        const newArtists = artistArrayCopy.map(artist => {
          const roles = (artist.roles || []).filter(x => x);
          artist.roles = formatArtistRoles(roles, rolesContainRemixer);
          return artist;
        });

        // Check if there are any artists with the role 'MainArtist'
        const includesMainArtist = newArtists.some(artist => artist.roles.includes('MainArtist'));

        if (!includesMainArtist && setError) {
          setError('no_primary_artist', {
            message: 'There must be at least one artist with the role "Primary Artist"',
          });
        }

        return newArtists;
      }),
    [artistsList, setArtists]
  );

  if (loadingStatus === 'loading') {
    return null;
  }

  const removeArtist = i => {
    setArtists(prev => prev.filter((a, artistIndex) => i !== artistIndex));
  };

  const appendArtist = () => {
    setArtists(prev => [
      ...prev,
      {
        keyName: prev.length,
        appleId: '',
        spotifyId: '',
        soundcloudId: '',
        full_name: 'Please select artist',
        role: '',
        party_id: undefined,
      },
    ]);
  };

  return (
    <Container>
      <Row>
        <Box display="grid" w="100%" gridTemplateColumns={gridTemplateColumns} gridGap="4" px="2">
          <FieldLabel text={entityLabel} required />
          <FieldLabel text={`Artist Role`} required />
          <FieldLabel text={`Artist ID`} />
        </Box>
      </Row>
      {artists.map((artist, i) => {
        const displaySpotifyId = artist?.platform_id?.spotify;
        const displayAppleId = artist?.platform_id?.apple;
        const displaySoundcloudId = artist?.platform_id?.soundcloud;
        const displayVevoChannelName = artist?.platform_id?.vevo;

        const roles = artist?.roles || [];
        const selectedArtistId = artist?.asset_party_id || artist?.id || '';
        const selectedArtistRole = roles.find(x => artistRoleSet.has(x)) || '';

        return (
          <GhostableRow key={`artist-row-${artist.id || artist.keyName || artist.asset_party_id}-${i}`}>
            <Box
              display="grid"
              w="100%"
              gridTemplateColumns={gridTemplateColumns}
              gridGap="4"
              px="2"
              alignItems="center">
              <ArtistDropdown
                defaultValue={selectedArtistId}
                placeholder="Select Artist"
                loading={status === 'loading'}
                value={selectedArtistId}
                options={artistDropdownOptions}
                onChange={artistIdChangeValue => updateArtist(i, artistIdChangeValue, [selectedArtistRole])}
                doShowErrow={true}
                error={errors[i] && errors[i]['party_id']}
                buttonText="Add new artist"
                onButtonClick={() => {
                  onArtistModalOpen();
                  setActiveIndex && setActiveIndex(i);
                }}
              />
              <StyledSelect
                defaultValue={selectedArtistRole}
                disabled={disabled}
                error={!selectedArtistRole && artist.id}
                heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.COMMON.TYPE.ROLE}`}
                variant={'dark'}
                onChange={e => {
                  const artistRoleChangeValue = e?.target?.value;
                  updateArtist(i, selectedArtistId, [artistRoleChangeValue]);
                }}>
                <option value="">Select...</option>
                {artistRoleOptions.map((option, i) => (
                  <option key={i} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </StyledSelect>
              <Row>
                <PlatformIds
                  apple={displayAppleId}
                  spotify={displaySpotifyId}
                  soundcloud={displaySoundcloudId}
                  vevo={displayVevoChannelName}
                />
              </Row>
              {!disabled && (
                <Button
                  onClick={() => removeArtist(i)}
                  tertiary
                  square
                  icon="trash"
                  heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.COMMON.TYPE.REMOVE}`}
                />
              )}
            </Box>

            <Flex direction="column">
              {Object.entries(errors)
                .filter(([key]) => key === i)
                .map(([_, err]) => (
                  <Text ml="0.5rem" mt="0.5rem" color="red">
                    {err?.message}
                  </Text>
                ))}
            </Flex>
          </GhostableRow>
        );
      })}
      <Row>
        <Col>
          <Button tertiary onClick={appendArtist} text="Add Artist" />
        </Col>
        <Col>
          {Object.entries(errors)
            .filter(([key]) => key === 'no_primary_artist')
            .map(([_, err]) => (
              <Text ml="0.5rem" mt="0.5rem" color="red">
                {err?.message}
              </Text>
            ))}
        </Col>
      </Row>
    </Container>
  );
};
PartyArray.defaultProps = {
  items: [],
  entityLabel: 'Artist Name',
  errors: {},
  disabled: false,
  artistsList: [],
};

export default PartyArray;
