import React, { useState, useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDisclosure, Stack, useToast, Button } from '@chakra-ui/react';
import Section from './section';
import SubSection from './sub-section';
import SectionHeaderBar from './section-header-bar';
import { Col, Row } from 'react-styled-flexboxgrid';
import FieldLabel from 'components/FieldLabel/field-label';
import { useDeleteStripeMutation } from '../data-client/use-delete-stripe';
import * as API from 'utils/API/API';

import { API_STATUS_KEYS } from 'constants/constants';
import { RightCol, CoverPadder, BankInfoDescription, FieldValue } from './settings-styles';
import ChangeCompanyNameModal from './change-company-name-modal';
const Group = API.group();
const User = API.user();

function CompanyProfile({ selectedUserGroup, stripeLinkStatus, loadStripeLoginLink, openStripeModalHandler, theme }) {
  const { refetch: refetchUser, data: user } = useQuery(['user'], () => User.getMe());
  const editCompanyNameModal = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { data: groupPaymentAccount } = useQuery(['groupAccount', selectedUserGroup.id], () =>
    Group.getPaymentAccount(selectedUserGroup.id)
  );
  const [groupPaymentLoginLink, setGroupPaymentLoginLink] = useState(null);

  const groupStripeId = groupPaymentAccount?.stripe_account_id;

  if (groupPaymentAccount && !groupPaymentLoginLink) {
    loadStripeLoginLink(groupPaymentAccount.id, setGroupPaymentLoginLink);
  }

  const { mutate: stripeResetMutation, isLoading: stripeResetIsLoading } = useDeleteStripeMutation({
    onSuccess: () => queryClient.invalidateQueries(['groupAccount']),
    onError: error => {
      const apiError = error?.response?.data || {};
      toast({
        title: apiError.detail || apiError.title || 'Your request could not processed. Please try again later.',
        status: 'error',
      });
    },
  });

  const stripeLabelButtonText = groupPaymentLoginLink?.stripe_account?.details_submitted
    ? 'View Label Stripe Dashboard'
    : 'Complete Stripe Onboarding';

  const onStripeResetClick = useCallback(
    () =>
      stripeResetMutation({
        accountId: groupPaymentAccount?.id,
      }),
    [stripeResetMutation, groupPaymentAccount]
  );

  const missingStripePaymentAccount = !groupPaymentAccount?.stripe_account_id;

  return (
    <>
      <Section>
        <SubSection>
          <SectionHeaderBar name="Label Profile"></SectionHeaderBar>
          <Row>
            <Col xs={4}>
              <FieldLabel light text="Company Name" />

              <FieldValue>{selectedUserGroup ? selectedUserGroup.name : 'No group found'}</FieldValue>
              {selectedUserGroup && user?.currentGroup?.role === 'Label Admin' ? (
                <Button variant="smallDark" onClick={editCompanyNameModal.onOpen} mt=".75rem">
                  Change Company Name
                </Button>
              ) : null}
            </Col>
          </Row>
        </SubSection>
        {/* Hide if no label payment account */}
        {groupPaymentAccount && (
          <SubSection>
            <SectionHeaderBar name="Label Banking Info" />
            <Row>
              <Col xs={6}>
                {!groupStripeId ? (
                  <BankInfoDescription>
                    Sign in or Create an Account on Stripe for automatic monthly payments.
                  </BankInfoDescription>
                ) : (
                  <BankInfoDescription>Stripe Account Connected</BankInfoDescription>
                )}
                <small>
                  Have questions? Submit a{' '}
                  <a
                    target="_blank"
                    href="https://venice-music.atlassian.net/servicedesk/customer/portal/1/group/-1"
                    rel="noreferrer"
                    style={{ color: '#FF335F' }}>
                    support request
                  </a>
                  .
                </small>
              </Col>
              <RightCol xs={6}>
                {!groupStripeId ? (
                  <Button
                    variant="smallDark"
                    onClick={() => openStripeModalHandler('label')}
                    disabled={stripeLinkStatus[API_STATUS_KEYS.IN_PROGRESS]}>
                    Connect Bank Info
                  </Button>
                ) : (
                  <Stack direction="row">
                    <Button
                      variant="smallDark"
                      as={'a'}
                      href={groupPaymentLoginLink?.url}
                      disabled={!groupPaymentLoginLink?.url}
                      pointerEvents={!groupPaymentLoginLink?.url ? 'none' : ''}
                      target="_blank">
                      {stripeLabelButtonText}
                    </Button>
                    <Button
                      variant="small"
                      disabled={missingStripePaymentAccount || stripeResetIsLoading}
                      onClick={onStripeResetClick}>
                      Reset Stripe
                    </Button>
                  </Stack>
                )}
              </RightCol>
            </Row>
          </SubSection>
        )}
        <CoverPadder />
      </Section>
      <ChangeCompanyNameModal
        isOpen={editCompanyNameModal.isOpen}
        onClose={editCompanyNameModal.onClose}
        group={selectedUserGroup}
        refetchUser={refetchUser}
        theme={theme}
      />
    </>
  );
}

export default CompanyProfile;
