import { useQuery } from 'react-query';
import Users from '../utils/API/user';
import useCurrentUser from './use-current-user';

const { getReferralCode } = Users();

export function useGetReferralCode() {
  const [user] = useCurrentUser();
  return useQuery(['get-referral-code', user?.id], () => {
    if (user && user.id) {
      return getReferralCode(user?.id);
    } else {
      return { data: null };
    }
  });
}
