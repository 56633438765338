import React from 'react';
import { Button, IconButton, useTheme } from '@chakra-ui/react';
import { RiArrowDownSLine as ChevronDown, RiArrowUpSLine as ChevronUp } from 'react-icons/ri';
import Box from '../Box/Box';

const EditButton = ({ canEditSplits, isNew, handleEditSplits, handleExpandCollapseClicked, isExpanded }) => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="flex-end">
      {canEditSplits && (
        <Button onClick={handleEditSplits} variant="small">
          {isNew ? 'Edit Splits' : 'Enter Splits'}
        </Button>
      )}
      <IconButton
        marginLeft="2rem"
        border="none"
        onClick={handleExpandCollapseClicked}
        variant={'transparent'}
        _hover={{ marginLeft: '2rem' }}
        icon={
          isExpanded ? (
            <ChevronUp size="1.875rem" color={theme.colors.black100} />
          ) : (
            <ChevronDown size="1.875rem" color={theme.colors.black100} />
          )
        }
      />
    </Box>
  );
};

export default EditButton;
