import React, { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useAuth0 } from 'utils/auth0';
import { useGlobalData } from 'utils/global-data';
import EnterNameModal from './enter-name-modal';
import AcceptTermsModal from './accept-terms-modal';
import URLS from 'constants/URLS.gen.json';

function AcceptTermsContainer({ needsAccepting }) {
  const { logout } = useAuth0();
  const { dbUser, getAuthedUser, updateUserData } = useGlobalData();
  const addTermsModal = useDisclosure();
  const addEnterNameModal = useDisclosure();

  useEffect(() => {
    addTermsModal.onOpen();
  }, []);

  async function handleAccept() {
    addTermsModal.onClose();
    if (dbUser.name) return await acceptedTerms({ name: dbUser.name });

    addEnterNameModal.onOpen();
  }

  function handleBackButton() {
    addTermsModal.onOpen();
    addEnterNameModal.onClose();
  }

  async function acceptedTerms({ name }) {
    if (dbUser) {
      const now = DateTime.now().toISO();
      const values = needsAccepting.reduce((saveObj, term) => {
        saveObj[term] = URLS.TOS[term];

        saveObj[term.replace('_url', '_at')] = now;
        return saveObj;
      }, {});

      if (name) values.name = name;

      await updateUserData(dbUser.id, values);

      getAuthedUser();
    }
  }

  return (
    <>
      <AcceptTermsModal
        disclosure={addTermsModal}
        handleAccept={handleAccept}
        logout={logout}
        needsAccepting={needsAccepting}
      />
      <EnterNameModal handleBackButton={handleBackButton} disclosure={addEnterNameModal} handleAccept={acceptedTerms} />
    </>
  );
}

export default AcceptTermsContainer;
