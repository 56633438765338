import React from 'react';
import styled from '@emotion/styled/macro';
import { Row } from 'react-styled-flexboxgrid';

const Wrapper = styled(Row)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.isGhosted &&
    `
    cursor: copy;
    display: flex;
    justify-content: space-between;
    opacity: 0.5;
    transition: opacity 250ms ease;

    &:hover {
      opacity: 0.9;
    }
  `}

  ${props =>
    props.disabled &&
    `
    cursor: default;
    pointer-events: none;

    &:hover {
      opacity: 0.5;
    }
  `}
`;

const GhostActivator = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 20;
`;

const GhostableRow = ({ onClickGhost, isGhosted, disabled, children, heapCode }) => {
  return (
    <Wrapper role="row" isGhosted={isGhosted} disabled={disabled} data-heap={heapCode}>
      {children}
      {isGhosted && <GhostActivator onClick={onClickGhost} />}
    </Wrapper>
  );
};

export default GhostableRow;
