import React, { useState, useEffect } from 'react';
import { RiPauseCircleLine, RiPlayCircleLine } from 'react-icons/ri';
import { IconButton } from '@chakra-ui/react';
const CLASS_NAME = 'venice-simple-audio-player';
function pauseAllAudio() {
  const audios = document.getElementsByClassName(CLASS_NAME);

  for (const audio of audios) {
    if (typeof audio?.pause === 'function') audio.pause();
  }
}

function useAudio(file, speed, start, end, pauseAllOthersOnPlay = true) {
  const [audio, setAudio] = useState(null);
  const [playing, setPlaying] = useState(false);

  let timeInterval;
  function timeIntervalFunc() {
    if (audio.currentTime >= end) {
      setPlaying(false);
      audio.currentTime = start || 0;
      clearInterval(timeInterval);
    }
  }

  const toggle = () => {
    if (end) timeInterval = setInterval(timeIntervalFunc, 100);
    if (playing && end) clearInterval(timeInterval);

    if (!playing && pauseAllOthersOnPlay) pauseAllAudio();

    setPlaying(!playing);
  };

  useEffect(() => {
    playing ? audio?.play() : audio?.pause();
  }, [playing]);

  const ended = () => setPlaying(false);

  useEffect(() => {
    if (!file) return;
    const audio = new Audio(URL.createObjectURL(file));
    //This allows all items to be queried for pausing.
    audio.className = CLASS_NAME;
    audio.playbackRate = speed;
    audio.currentTime = start;
    audio.preservesPitch = false;

    audio?.addEventListener('ended', ended);
    audio?.addEventListener('pause', ended);

    document.body.appendChild(audio);
    setAudio(audio);

    return () => {
      if (audio) {
        audio.removeEventListener('ended', ended);
        audio.removeEventListener('pause', ended);
        audio.pause();
        document.body.removeChild(audio);
      }
    };
  }, [file, speed, start]);

  return [playing, toggle];
}

//speed
/*
1 = normal, 2 = 2x, 0.5 = 1 / 2 speed
*/
export default function SimpleAudioPlayer({ file, speed = 1, start = 0, end = null }) {
  const [playing, toggle] = useAudio(file, speed, start, end);

  return (
    <IconButton
      w="2rem"
      marginRight=".5rem"
      fontSize="1.75rem"
      style={{ backgroundColor: 'transparent', border: '0' }}
      icon={playing ? <RiPauseCircleLine /> : <RiPlayCircleLine />}
      onClick={toggle}
      flexShrink="1"
      aria-label={'button'}
    />
  );
}
