import * as React from 'react';
import { Flex, Box, Heading } from '@chakra-ui/react';

export default function SectionHeaderBar({ children, name }) {
  return (
    <Flex justifyContent="space-between" alignItems="center" mb="4">
      <Box>
        <Heading fontSize={{ base: 'lg', sm: 'xxlg' }} fontWeight="bold">
          {name}
        </Heading>
      </Box>
      <Box>{children}</Box>
    </Flex>
  );
}
