import Schema from '../../../schema-gen/release';
import z from 'zod';
import ManageClipsStateManager from '../../manage-clips-state-manager';

class ClipSetupState extends ManageClipsStateManager {
  dependentFields = [
    'title',
    'sub_title',
    'display_artist_name',
    'display_label_name',
    'copyright_year',
    'cover_art',
    'audio_source',
    'track',
    'clips',
    'asset_release_id',
    'asset_release_set_id',
  ];

  title = 'Setup';
  path = '/manage/clip/setup';

  componentName = 'ClipSetup';

  constructor(args) {
    const { stateData, ...rest } = args;

    super(
      {
        ...rest,
        stateData: {
          ...stateData,
          copyright_year: stateData.copyright_year || `${new Date().getFullYear()}`,
        },
        mutableFields: [
          'title',
          'sub_title',
          'display_artist_name',
          'display_label_name',
          'cover_art',
          'audio_source',
          'copyright_year',
        ],
      },
      {
        title: Schema.Title,
        sub_title: Schema.SubTitle.nullable().optional(),
        display_artist_name: Schema.DisplayArtistName,
        display_label_name: Schema.DisplayLabelName,
        copyright_year: z.string().min(4).max(4),
        audio_source: z.object({}),
        cover_art: z.object({}),
      }
    );
  }

  toJSON() {
    // TODO: remap props/stateData to match the schema
  }
}

export default ClipSetupState;
