import { createBrowserHistory } from 'history';

const history = createBrowserHistory({ forceRefresh: true });

// Clear out any omnishare data
if (history.location && history.location.state && history.location.state.omnishare) {
  const state = { ...history.location.state };

  delete state.omnishare;
  history.replace({ ...history.location, state });
}

export default history;
