import { Flex, Text, Box, Spinner } from '@chakra-ui/react';
import React, { useState } from 'react';
import Pagination from '../features/Pagination/pagination';

const DummyDataComponent = ({ drawerContainerStyle, analyticsSinceStyle }) => {
  const blurStyle = {
    filter: 'blur(.15rem)',
    opacity: '.5',
  };
  const largeTextBlur = {
    filter: 'blur(.35rem)',
    opacity: '.5',
  };

  return (
    <Flex {...drawerContainerStyle}>
      <Flex width="100%">
        <HeaderCell addBlur={largeTextBlur} label="Link Visits" value={'1,204'} />
        <HeaderCell addBlur={largeTextBlur} label="Pre-Saves" value={'528'} />
        <HeaderCell addBlur={largeTextBlur} label="Streams" value={'2,992'} />
      </Flex>
      <Flex width="100%">
        <ColumnList
          header="Top Streaming Services"
          rowProps={blurStyle}
          rowData={[
            { label: 'spotify', value: 12449 },
            { label: 'apple music', value: 3889 },
            { label: 'deezer', value: 2467 },
            { label: 'other dsps', value: 949 },
          ]}
          showDspBarGraph={true}
        />
        <ColumnList
          header="Link visits by Country"
          rowProps={blurStyle}
          rowData={[
            { label: 'United States', value: 12449, flag: '🇺🇸' },
            { label: 'Japan', value: 3889, flag: '🇯🇵' },
            { label: 'Brazil', value: 2467, flag: '🇧🇷' },
            { label: 'Italy', value: 949, flag: '🇮🇹' },
            { label: 'Canada', value: 129, flag: '🇨🇦' },
          ]}
          showCountryFlag={true}
          borderRight="1px solid #aaa"
          borderLeft="1px solid #aaa"
        />
        <ColumnList
          header="Link visits by Cities"
          rowProps={blurStyle}
          rowData={[
            { label: 'Los Angeles', value: 2449 },
            { label: 'New York', value: 389 },
            { label: 'Atlanta', value: 248 },
            { label: 'Austin', value: 229 },
            { label: 'London', value: 147 },
          ]}
          showPagination={true}
          paginationProps={blurStyle}
        />
      </Flex>
      <Text {...analyticsSinceStyle}>Analytics since xx/xx/xx</Text>
    </Flex>
  );
};

const MarketingAnalyticsDropdown = ({
  isOpen,
  generalQuery,
  dspQuery,
  countryQuery,
  cityQuery,
  canAccessAnalytics,
}) => {
  const drawerContainerStyle = {
    direction: 'column',
    width: '100%',
    height: isOpen ? '450px' : 0,
    transition: 'height 0.25s ease-out',
    color: '#000',
    overflow: 'hidden',
  };

  const analyticsSinceStyle = {
    color: '#aaa',
    padding: '0rem 3rem',
    marginBottom: '1rem',
  };

  return (
    <>
      {canAccessAnalytics ? (
        <Flex {...drawerContainerStyle}>
          {/* Header */}
          <Flex width="100%">
            {generalQuery.isLoading ? (
              <HeaderCell isLoading />
            ) : (
              <>
                <HeaderCell label="Link Visits" value={generalQuery.data?.linkVisits?.toLocaleString()} />
                <HeaderCell label="Pre-Saves" value={generalQuery?.data?.preSaves?.toLocaleString()} />
                <HeaderCell label="Streams" value={generalQuery?.data?.streams?.toLocaleString()} />
              </>
            )}
          </Flex>
          {/* Columns */}
          <Flex width="100%">
            <Flex width="100%">
              <ColumnList
                header="Top Streaming Services"
                isLoading={dspQuery.isLoading}
                rowData={dspQuery?.data?.results}
                showDspBarGraph={true}
              />
              <ColumnList
                header="Link visits by Country"
                isLoading={countryQuery.isLoading}
                rowData={countryQuery?.data?.results}
                showCountryFlag={true}
                borderRight="1px solid #aaa"
                borderLeft="1px solid #aaa"
              />
              <ColumnList
                header="Link visits by Cities"
                isLoading={cityQuery.isLoading}
                rowData={cityQuery?.data?.results}
                showPagination={cityQuery?.data?.results?.length > 5}
              />
            </Flex>
          </Flex>
          <Text {...analyticsSinceStyle}>
            Analytics since {generalQuery.isLoading ? ' ... ' : generalQuery?.data?.lastAnalyticsDate}
          </Text>
        </Flex>
      ) : (
        <DummyDataComponent drawerContainerStyle={drawerContainerStyle} analyticsSinceStyle={analyticsSinceStyle} />
      )}
    </>
  );
};

const CountryFlag = ({ flag }) => (
  <Flex
    justify="center"
    align="center"
    alt={`flag-${flag}`}
    width="20px"
    height="20px"
    borderRadius="1rem"
    overflow="hidden"
    mr="0.5rem">
    <Text fontSize="3rem">{flag}</Text>
  </Flex>
);

const dspColors = {
  spotify: '#4ADF7E',
  'apple music': '#FA7F8D',
  youtube: '#E15C56',
  amazon: '#39ADDC',
  deezer: '#FCAB56',
  pandora: '#7190EC',
  default: '#AAA',
};

const TopStreamingServicesRowItem = ({ label, value, total, curveMultiplier, rowProps }) => (
  <Flex alignItems="center" width="100%" margin="0.25rem 0" justifyContent="space-between" {...rowProps}>
    <Flex alignItems="flex-start" direction="column" minWidth="5rem">
      <Text fontSize="0.75rem" marginBottom="0">
        {label.toUpperCase()}
      </Text>
      <Text fontWeight="bold" textAlign="right">
        {value.toLocaleString()}
      </Text>
    </Flex>
    <Box width="100%" paddingTop="1.125rem">
      <Box
        backgroundColor={dspColors?.[label] || dspColors.default}
        borderRadius="0.25rem"
        height="0.25rem"
        width={`${((100 * value) / total) * curveMultiplier}%`}
      />
    </Box>
  </Flex>
);

// Used for Country and City List
const RowItem = ({ label, value, sequence, flag, rowProps }) => (
  <Flex alignItems="center" margin="0.5rem 0" {...rowProps}>
    <Flex alignItems="center" width="100%">
      <Text fontSize="0.75rem" mr="0.75rem" maxW="1.5rem" textAlign="right">
        {`${sequence}`.padStart(2, '0')}
      </Text>
      {flag ? <CountryFlag flag={flag} /> : null}
      <Text>{label}</Text>
    </Flex>
    <Text fontWeight="bold" textAlign="right">
      {value.toLocaleString()}
    </Text>
  </Flex>
);

const ColumnList = ({
  header,
  rowData = [],
  showDspBarGraph = false,
  showPagination = false,
  isLoading,
  rowProps,
  paginationProps,
  ...props
}) => {
  const PAGE_SIZE = 5;

  const [pageIndex, setPageIndex] = useState(0);

  const containerStyle = {
    direction: 'column',
    width: '100%',
    height: '300px',
    minWidth: '300px',
    padding: '1rem 3rem',
  };

  const headerStyle = {
    fontSize: '1rem',
    marginBottom: '1rem',
  };

  const rowContainerStyle = {
    direction: 'column',
    minH: '13rem',
  };

  if (isLoading) {
    return (
      <Flex {...containerStyle} align="center" justify="center">
        <Spinner />
      </Flex>
    );
  }

  const rowDataValueTotal = rowData.length ? rowData.reduce((a, c) => a + c.value, 0) : 0;
  const largestRowDataValue = Math.max(...rowData.map(r => r.value));
  const curveMultiplier = 1 / (largestRowDataValue / rowDataValueTotal);

  const RowItemComponent = showDspBarGraph ? TopStreamingServicesRowItem : RowItem;

  const onPrevPageHandler = () => setPageIndex(pageIndex - 1);
  const onNextPageHandler = () => setPageIndex(pageIndex + 1);

  const formattedRowData = showPagination ? rowData.slice(pageIndex * PAGE_SIZE, PAGE_SIZE * (pageIndex + 1)) : rowData;

  const otherRowItemProps = showDspBarGraph ? { total: rowDataValueTotal, curveMultiplier } : {};

  return (
    <Flex {...containerStyle} {...props}>
      <Text {...headerStyle}>{header}</Text>
      <Flex {...rowContainerStyle}>
        {formattedRowData.map((row, i) => (
          <RowItemComponent
            rowProps={rowProps}
            {...row}
            {...otherRowItemProps}
            sequence={i + 1 + pageIndex * PAGE_SIZE}
          />
        ))}
      </Flex>
      {showPagination ? (
        <Pagination
          paginationProps={paginationProps}
          onPrevPageHandler={onPrevPageHandler}
          onNextPageHandler={onNextPageHandler}
          pageSize={PAGE_SIZE}
          pageIndex={pageIndex}
          totalLength={rowData.length}
          iconStyle={{ backgroundColor: 'white' }}
        />
      ) : null}
    </Flex>
  );
};

const HeaderCell = ({ label, value, isLoading, addBlur }) => {
  const containerStyle = {
    direction: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '6rem',
    width: '100%',
    minWidth: '300px',
    borderBottom: '1px solid #aaa',
  };

  const valueStyle = {
    fontSize: '2rem',
    fontWeight: '700',
    marginBottom: '-0.5rem',
  };

  if (isLoading) {
    <Flex {...containerStyle}>
      <Spinner />
    </Flex>;
  }

  return (
    <Flex {...containerStyle}>
      <Text {...valueStyle} {...addBlur}>
        {value}
      </Text>
      <Text>{label}</Text>
    </Flex>
  );
};

export default MarketingAnalyticsDropdown;
