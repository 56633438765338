import React from 'react';
import { Table, Thead, Tbody, Th as ChakraTh, Tr, Flex, Button, Box } from '@chakra-ui/react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import ReleasedTracksTableRow from './previous-releases-tracks-row';
const PAGE_SIZE = 8;

const Th = ({ children, ...props }) => (
  <ChakraTh border="none" {...props}>
    {children}
  </ChakraTh>
);

export default function ReleasedTracksTable({ releaseTracksQuery, onPage, setOnPage, selectReleaseAction }) {
  const { data, isLoading } = releaseTracksQuery;
  const releaseTracks = data?.results;
  const total = data?.total;
  const isPrevPageDisabled = onPage === 0;
  const isNextPageDisabled = (onPage + 1) * PAGE_SIZE >= total;

  return (
    <Table>
      <Thead background="none">
        <Tr style={{ backgroundColor: 'unset' }}>
          <Th>
            <Box width="50px"></Box>
          </Th>
          <Th>
            <Box>Track</Box>
          </Th>
          <Th>Release</Th>
          <Th>Artist</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {!isLoading &&
          !!total &&
          releaseTracks.map(releaseTrack => (
            <ReleasedTracksTableRow
              key={`track-${releaseTrack.asset_track_id}-on-release-${releaseTrack.asset_release_id}`}
              releaseTrack={releaseTrack}
              selectReleaseAction={selectReleaseAction}
            />
          ))}
        <Tr>
          <Th colSpan={6}>
            <Flex alignItems="center" justifyContent="center">
              {releaseTracks?.length > 0 && (
                <>
                  <Button variant="ghost" size="sm" onClick={() => setOnPage(onPage - 1)} disabled={isPrevPageDisabled}>
                    <RiArrowLeftSLine />
                  </Button>
                  <Box mx="2" color="black100" fontWeight="medium">
                    {onPage + 1} of {Math.ceil(total / PAGE_SIZE)}
                  </Box>

                  <Button variant="ghost" size="sm" onClick={() => setOnPage(onPage + 1)} disabled={isNextPageDisabled}>
                    <RiArrowRightSLine />
                  </Button>
                </>
              )}
            </Flex>
          </Th>
        </Tr>
      </Tbody>
    </Table>
  );
}
