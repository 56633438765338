import styled from '@emotion/styled/macro';
import { Col, Row } from 'react-styled-flexboxgrid';

import Icon from 'components/Icon/icon';

export const spinAnimation = `
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @keyframes spin {
    from {
      transform:rotate(0deg);
    }
    to {
      transform:rotate(360deg);
    }
  }
`;

export const ButtonLink = styled.button`
  background: transparent;
  border: 0;
  color: ${props => props.theme.colors.brand.accent};
  cursor: pointer;
  padding: 0;
  transition: all 250ms ease;

  &:hover {
    color: ${props => props.theme.colors.brand.accentHover};
  }
`;

export const IconButton = styled(Icon)`
  cursor: pointer;
`;

export const BoldLink = styled.a`
  font-weight: ${props => props.theme.fonts.weights.bold};
  text-decoration: none;
`;

export const Paragraph11 = styled.p`
  font-size: ${props => props.theme.fonts.sizes.xsm};

  ${props =>
    props.noPad &&
    `
    margin: 0;
  `}
`;

export const Paragraph12 = styled.p`
  font-size: ${props => props.theme.fonts.sizes.sm};

  ${props =>
    props.noPad &&
    `
    margin: 0;
  `}
`;

export const Paragraph14 = styled.p`
  font-size: ${props => props.theme.fonts.sizes.reg};
  min-height: 1rem;

  ${props =>
    props.noPad &&
    `
    margin: 0;
  `}
`;

export const CopyBlock = styled.div`
  color: ${props => props.theme.colors.brand.textWhite};

  h2 {
    font-family: ${props => props.theme.fonts.families.text};
    font-size: ${props => props.theme.fonts.sizes.lg};
    font-weight: ${props => props.theme.fonts.weights.bold};
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0 0 1rem;
  }

  a {
    font-weight: ${props => props.theme.fonts.weights.medium};
  }
`;

export const NumeralStyle = styled.span`
  font-family: ${props => props.theme.fonts.families.numerals};
  font-size: ${props => props.theme.fonts.sizes.sm};
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const FlexCol = styled(Col)`
  display: flex !important;

  ${props =>
    props.center &&
    `
    justify-content: center;
  `}

  ${props =>
    props.right &&
    `
    justify-content: flex-end;
  `}

  ${props =>
    props.bottom &&
    `
    align-items: flex-end;
  `}

  ${props =>
    props.column &&
    `
    flex-direction: column;
  `}
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const HeaderRow = styled(Row)`
  margin-bottom: 4rem;

  h1 {
    margin: 0;
  }
`;

export const VerticalCenter = styled(FlexBox)`
  align-items: center;
`;

export const FormGridRow = styled(Row)`
  margin-bottom: 2rem;
`;

export const MetadataRow = styled(Row)`
  position: relative;
  color: ${props => props.theme.colors.brand.textWhite};
  margin-bottom: 1.5rem;

  ${props =>
    props.short &&
    `
    margin-bottom: 0.5rem;
  `}
`;

export const MetadataLabel = styled.small`
  display: inline-block;
  font-weight: ${props => props.theme.fonts.weights.medium};
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const ButtonRow = styled(Row)`
  margin-top: 1.5rem;

  ${props =>
    props.nopad &&
    `
    margin-top: 0;
  `}
`;

export const CenterBlock = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const ButtonCol = styled(Col)`
  align-items: center;
  display: flex !important;
  justify-content: flex-end;
  flex-basis: 50%;

  > div {
    margin: 0 0.25rem;
    flex-grow: 1;
  }

  > span {
    align-items: center;
    display: inline-flex;
    min-height: 40px;
    max-width: 220px;
  }
`;

export const ErrorCol = styled(Col)`
  align-items: center;
  display: flex !important;

  ${props =>
    props.end === 'true' &&
    `
    justify-content: flex-end;
  `}

  ${props =>
    props.color === 'true' &&
    `
    color: ${props.theme.colors.negative};
  `}
`;

export const SpacedUppercaseText = styled.div`
  color: ${props => props.theme.colors.brand.layer3};
  font-size: ${props => props.theme.fonts.sizes.xxsm};
  font-weight: ${props => props.theme.fonts.weights.medium};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const FieldError = styled.div`
  color: ${props => props.theme.colors.negative};
  font-size: ${props => props.theme.fonts.sizes.xsm};
  margin: 0.25rem 0;
  position: absolute;
  top: 100%;
  white-space: nowrap;
`;

export const FieldWarning = styled(FieldError)`
  color: ${props => props.theme.colors.brand.alertYellow};
`;

export const InlineFlex = styled.div`
  display: inline-flex;
`;

export const ImageWrapper = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  height: 100%;
  width: 100%;
`;

export const Locator = styled.div`
  color: ${props => props.theme.colors.brand.layer3};
  font-size: ${props => props.theme.fonts.sizes.xxsm};
  font-weight: ${props => props.theme.fonts.weights.medium};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const RemoveButton = styled.button`
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ScrollFixedContainer = styled.div`
  position: fixed;
`;

export const PageSectionWrapper = styled.div`
  background-color: ${props => props.theme.colors.brand.layer0};
  border-radius: 1rem;
  margin-bottom: 2rem;
  padding: ${props => props.theme.space[8]};
  position: relative;
  z-index: 5;
`;

export const ButtonGroup = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-end;

  ${props =>
    props.alignVerticalCenter &&
    `
    align-items: center;
  `}

  > div:not(:last-child) {
    margin-right: ${props => props.marginRight || '0.5rem'};
  }
`;

export const LoaderSquare = styled.div`
  background-color: ${props => props.theme.colors.brand.layer1};
  height: 300px;
  position: relative;

  &:before {
    align-items: center;
    color: ${props => props.theme.colors.brand.layer0};
    content: 'loading';
    font-family: ${props => props.theme.fonts.families.cursive};
    font-size: ${props => props.theme.fonts.sizes.xlg};
    font-weight: ${props => props.theme.fonts.weights.bold};
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    width: 100%;

    -webkit-animation: color-change 3s infinite;
    -moz-animation: color-change 3s infinite;
    -o-animation: color-change 3s infinite;
    -ms-animation: color-change 3s infinite;
    animation: color-change 3s infinite;
  }

  @-webkit-keyframes color-change {
    0% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
    50% {
      color: ${props => props.theme.colors.brand.layer2};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer0};
    }
    100% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
  }
  @-moz-keyframes color-change {
    0% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
    50% {
      color: ${props => props.theme.colors.brand.layer2};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer0};
    }
    100% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
  }
  @-ms-keyframes color-change {
    0% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
    50% {
      color: ${props => props.theme.colors.brand.layer2};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer0};
    }
    100% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
  }
  @-o-keyframes color-change {
    0% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
    50% {
      color: ${props => props.theme.colors.brand.layer2};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer0};
    }
    100% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
  }
  @keyframes color-change {
    0% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
    50% {
      color: ${props => props.theme.colors.brand.layer2};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer0};
    }
    100% {
      color: ${props => props.theme.colors.brand.layer0};
      text-shadow: 1px 1px ${props => props.theme.colors.brand.layer2};
    }
  }
`;

export const SidebarLink = styled.div`
  color: ${props => props.theme.colors.brand.layer4};
  cursor: pointer;
  font-size: ${props => props.theme.fonts.sizes.xlg};
  margin-bottom: 1.5rem;

  ${props =>
    props.isSelected &&
    `
    color: ${props.theme.colors.brand.textWhite};
    cursor: default;
    font-weight: ${props.theme.fonts.weights.bold};
  `}
`;

export const SidebarTab = styled.div`
  align-items: center;
  box-sizing: border-box;
  border-radius: 2rem 0 0 2rem;
  box-sizing: border-box;
  color: ${props => props.theme.colors.brand.layer4};
  cursor: pointer;
  display: flex;
  font-weight: ${props => props.theme.fonts.weights.bold};
  height: 4.25rem;
  padding-left: 2rem;

  ${props =>
    props.isSelected &&
    `
    background-color: ${props.theme.colors.brand.layer0};
    color: ${props.theme.colors.brand.textWhite};
  `}

  ${props =>
    props.isInline &&
    `
    left: calc(-100% + 65%);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  `}
`;

export const SectionHeader = styled.h2`
  margin-bottom: 1.75rem;

  ${props =>
    props.disabled &&
    `
    color: ${props.theme.colors.brand.layer4};
  `}
`;

export const RowBreak = styled.div`
  flex-basis: 100%;
  height: 0;
`;

export const RichPageHeader = styled.h2`
  align-items: center;
  display: flex;
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin: 3.5rem 0;
  white-space: nowrap;
`;

export const StyledTextarea = styled.textarea`
  background: ${props => props.theme.colors.background.textInput[props.colorVariant || 'dark'].default};
  border: 1px solid ${props => props.theme.colors.border.textInput[props.colorVariant || 'dark'].default};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.text.textInput[props.colorVariant || 'dark'].default};
  font-size: ${props => props.theme.fonts.sizes.reg};
  line-height: 18px;
  padding: 0.75rem 1rem;
  resize: none;
  transition: all 250ms ease;
  width: 100%;

  &:hover {
    ${props =>
      !props.disabled &&
      `
      background: ${props.theme.colors.background.textInput[props.colorVariant || 'dark'].hover};
      border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant || 'dark'].hover};
      color: ${props.theme.colors.text.textInput[props.colorVariant || 'dark'].hover};
    `}
  }

  ${props =>
    props.invalid &&
    `
    background: ${props.theme.colors.background.textInput[props.colorVariant || 'dark'].error};
    border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant || 'dark'].error};
    color: ${props.theme.colors.text.textInput[props.colorVariant || 'dark'].error};
  `}

  ${props =>
    props.disabled &&
    `
    background: ${props.theme.colors.background.textInput[props.colorVariant || 'dark'].disabled};
    border: 1px solid ${props.theme.colors.border.textInput[props.colorVariant || 'dark'].disabled};
    color: ${props.theme.colors.text.textInput[props.colorVariant || 'dark'].disabled};
  `}
`;

export const TableRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 100%;

  color: ${props => props.theme.colors.grayLighter};
  border-top: 1px solid ${props => props.theme.colors.brand.layer2};
  font-size: ${props => props.theme.fonts.sizes.sm};

  margin: 0.2rem 0 0.1rem 0;
  background-color: ${props => (props.selected ? props.theme.colors.brand.layer2 : 'transparent')};
  ${props =>
    props.header &&
    `
    background-color: ${props.theme.colors.brand.layer2};
    text-transform: uppercase;
    border-radius: 0.25rem;
    height: 2rem;
    text-align: center;
    color: ${props.theme.colors.brand.textWhite};
    > *:not(:first-child) {
      display: flex;
      align-items: center;
      height: 2rem;
      border-left: 2px solid ${props.theme.colors.brand.layer3};
    }
  `}
`;
