import React, { useCallback } from 'react';
import styled from '@emotion/styled/macro';
import { useDropzone } from 'react-dropzone';
import { ClipLoader } from 'react-spinners';

import { validateFileExtensions } from 'utils/form';

const Container = styled.div`
  box-sizing: border-box;
  cursor: copy;
  display: flex;
  height: 100%;
  outline: none;
  position: relative;
  width: 100%;
  transition: height 250ms ease;

  ${props =>
    props._v === 2 &&
    `
    background-color: ${props.theme.colors.brand.layer1};
    border-radius: 0.5rem;
    padding: 0;
  `}

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    background-color: ${props.theme.colors.brand.layer2};
  `}
`;

const LoadingWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  min-height: 100px;
  position: absolute;
  top: 0;
  width: 100%;
`;

const DropZone = styled.div`
  border-radius: 1rem;
  width: 100%;
  border: 2px dashed ${props => props.theme.colors.brand.layer4};
  padding: 2rem 2rem 1rem 2rem;
  transition: all 250ms ease;

  ${props =>
    props.isLoading &&
    `
    opacity: 0.1;
  `}

  ${props =>
    props._v === 2 &&
    `
    background-color: ${props.theme.colors.brand.layer1};
    border-radius: 0.5rem;
    border: 2px dashed ${props.theme.colors.brand.layer4};
  `}

  ${props =>
    props.isDragActive &&
    `
    background-color: rgba(120, 203, 14, 0.1);
  `}

  ${props =>
    props.disabled &&
    `
    background-color: transparent;
  `}
`;

function DropFile({ onChange, loading, className, children, disabled, style, extensionWhitelist, _v, heapCode }) {
  const onDrop = useCallback(
    async acceptedFiles => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.readAsArrayBuffer(acceptedFiles[0]);
      if (!validateFileExtensions(acceptedFiles, extensionWhitelist)) {
        alert(`Sorry, one or more of your files are invalid. Allowed extensions are: ${extensionWhitelist.join(', ')}`);
        return;
      }

      onChange(acceptedFiles);
    },
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container _v={_v} disabled={disabled} {...getRootProps()} data-heap={heapCode}>
      <input {...getInputProps()} disabled={disabled || loading} />
      <DropZone
        isDragActive={isDragActive}
        className={className}
        style={style}
        isLoading={loading}
        disabled={disabled}
        _v={_v}>
        {children}
      </DropZone>
      {loading && (
        <LoadingWrapper>
          <ClipLoader />
        </LoadingWrapper>
      )}
    </Container>
  );
}

DropFile.defaultProps = {
  loading: false,
  onChange: () => console.warn('File dropped, no "onChange" prop'),
};

export default DropFile;
