import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import asModal from 'features/Modal/as-modal';

function DeleteClipModal({ onClose, onSubmit }) {
  return (
    <Box color="black100">
      <Text w="80%">Deleting is permanent, you will not have the ability to regain this clip.</Text>
      <Flex justifyContent={'flex-end'} mt="2.5rem">
        <Box pr="2rem">
          <Button onClick={onClose} border="none" variant="small" color="black100" w="5rem">
            Cancel
          </Button>
        </Box>
        <Button onClick={onSubmit} variant="smallDark">
          Delete
        </Button>
      </Flex>
    </Box>
  );
}

export default asModal(DeleteClipModal);
