import { FormControl, FormErrorMessage, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { soundRecordingRoles } from '../../../constants/roles';
import SearchSelect from '../../../features/SearchSelect/search-select';
import { Col } from 'react-styled-flexboxgrid';
const LABEL_PAYEE_ROW_TEXT = `The Label is automatically added to splits and cannot be removed.`;

export function RolesColumn({ disabled, invalid, onChange, value }) {
  return (
    <Col xs={3} disabled={disabled}>
      {!disabled && (
        <FormControl marginLeft={0} paddingLeft={0} isRequired isInvalid={invalid}>
          <SearchSelect
            isMulti
            placeholder="Select Roles"
            isDisabled={disabled}
            onChange={onChange}
            options={soundRecordingRoles}
            value={value}
            isSearchable={false}
          />
          <FormErrorMessage>* required</FormErrorMessage>
        </FormControl>
      )}
      {disabled && (
        <Tooltip hasArrow placement="top" label={LABEL_PAYEE_ROW_TEXT}>
          {value.map(v => v.value).join(', ')}
        </Tooltip>
      )}
    </Col>
  );
}
