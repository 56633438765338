import React from 'react';
import {
  Modal as ChakraModal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button as ChakraButton,
} from '@chakra-ui/react';

const ConfirmModal = ({
  isOpen,
  onCancel = () => {},
  onConfirm = () => {},
  headerContent,
  headerProps,
  children,
  cancelContent = 'Cancel',
  confirmContent = 'Yes',
}) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton marginTop="0.5rem" />

        <ModalHeader {...headerProps} paddingLeft="1.5rem" paddingTop="2rem" fontSize="l" marginTop="0">
          {headerContent}
        </ModalHeader>

        <ModalBody paddingLeft="1.5rem" color="black100" marginTop="1rem" marginBottom="1rem">
          {children}
        </ModalBody>

        <ModalFooter paddingRight="1.5rem" paddingBottom="1.5rem">
          <ChakraButton variant="transparent" fontWeight="700" onClick={onCancel}>
            {cancelContent}
          </ChakraButton>
          <ChakraButton marginLeft="2rem" variant="smallDark" submit onClick={onConfirm}>
            {confirmContent}
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default ConfirmModal;
