import React, { useState } from 'react';
import { Box, Input, InputLeftElement, InputGroup, useDisclosure, useTheme, Button } from '@chakra-ui/react';
import { RiSearchLine, RiAddLine } from 'react-icons/ri';
import { useQuery, useQueryClient } from 'react-query';
import * as API from '../utils/API/API';
import PayeesTable from './payees-table';
import Section from './section';
import SubSection from './sub-section';
import SectionHeaderBar from './section-header-bar';
import AddPayeeForm from './add-payee-form';
import asModal from 'features/Modal/as-modal';
import GroupsAPI from 'utils/API/group';
import HEAP from '../constants/HEAP.gen.json';
import { sanitizeFormatCharacters } from 'utils/format';

const Group = API.group();

const { createPayeeIndividual } = GroupsAPI();
const AddPayeeModal = asModal(AddPayeeForm);

export default function PayeesTabContainer({ groupId }) {
  const payeesQuery = useQuery(['payees'], () => Group.getPayees(groupId));

  const [filterTerm, setFilterTerm] = useState('');
  const handleChange = event => setFilterTerm(event.target.value);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [payee, setPayee] = useState({ name: '', email: '' });
  const [errors, setErrors] = useState([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const queryClient = useQueryClient();

  const theme = useTheme();

  const handleSubmit = async () => {
    clearErrors();
    const formattedEmail = sanitizeFormatCharacters(payee.email);
    const existingPayee = payeesQuery.data.find(current => {
      const email = current?.payment_account?.user?.email;
      return !!email && !!formattedEmail && email.toLowerCase() === formattedEmail.toLowerCase();
    });

    if (existingPayee) {
      setErrors([{ detail: 'This email is already associated with a current payee.' }]);
      return;
    }

    try {
      await createPayeeIndividual(groupId, payee.name, formattedEmail);
      onClose();
      setPayee({ name: '', email: '' });
    } catch (e) {
      setErrors([e]);
    } finally {
      await queryClient.invalidateQueries(['payees']);
    }
  };

  return (
    <>
      <Section>
        <SubSection>
          <SectionHeaderBar name="Payees">
            <Box display="flex" flexDirection="row">
              <InputGroup mr="2">
                <InputLeftElement pointerEvents="none">
                  <RiSearchLine color={theme.colors.black100} />
                </InputLeftElement>
                <Input
                  rounded="full"
                  borderColor="black100"
                  _placeholder={{ color: 'black60' }}
                  value={filterTerm}
                  placeholder="Filter payees"
                  onChange={handleChange}
                />
              </InputGroup>
              <Button
                outline
                variant="smallDark"
                style={{ padding: '0 1rem', width: '12rem' }}
                leftIcon={<RiAddLine />}
                onClick={onOpen}
                heapCode={HEAP.PUBLIC.SETTINGS.TYPE.SETTINGS_BUTTON_ADD_PAYEE}
                testCode="settings-button-add-payee">
                Add new payee
              </Button>
            </Box>
          </SectionHeaderBar>
          <AddPayeeModal
            isOpen={isOpen}
            onClose={onClose}
            headerText="Add Payee"
            onSubmit={handleSubmit}
            errors={errors}
            setPayee={setPayee}
            payee={payee}
            clearErrors={clearErrors}
            variant="light"
          />
          <PayeesTable
            groupId={groupId}
            filterTerm={filterTerm}
            payees={payeesQuery.data}
            loadingStatus={payeesQuery.status}
          />
        </SubSection>
      </Section>
    </>
  );
}
