import { useQuery, useMutation, useQueryClient } from 'react-query';
import ActionItemsAPI from '../utils/API/action-items';

const QUERY_KEY_PREFIX = '__action-item';
const { listActionItems, createActionItem, updateActionItem, deleteActionItem } = ActionItemsAPI();

/**
 * @export
 * @param {object} params
 * @param {number} params.page_index
 * @param {number} params.page_size
 */
export function useListActionItems(params) {
  return useQuery([`${QUERY_KEY_PREFIX}-list-page`, params.page_index], () => listActionItems(params));
}

export function useCreateActionItem() {
  const queryClient = useQueryClient();

  return useMutation(item => createActionItem(item), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERY_KEY_PREFIX}-list-page`]);
    },
  });
}

export function useUpdateActionItem() {
  const queryClient = useQueryClient();

  return useMutation(item => updateActionItem(item.action_item_id, item), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERY_KEY_PREFIX}-list-page`]);
    },
  });
}

export function useDeleteActionItem() {
  const queryClient = useQueryClient();

  return useMutation(id => deleteActionItem(id), {
    onSuccess: () => {
      queryClient.invalidateQueries([`${QUERY_KEY_PREFIX}-list-page`]);
    },
  });
}
