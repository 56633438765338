import React from 'react';
import { useTheme, Box, Button, Text } from '@chakra-ui/react';

import coManagerLogoWhite from '../assets/co-manager-logo.svg';

function ComanagerAIIcon(props) {
  return <img src={coManagerLogoWhite} alt="" {...props} />;
}

export default function OptimizeCard({ text, title, buttonLabel, onClick }) {
  const theme = useTheme();

  return (
    <Box
      padding={'1.2rem'}
      borderWidth="1px"
      borderColor={theme.colors.black20}
      borderRadius={'0.3rem'}
      maxWidth={'46rem'}>
      <Text fontWeight={theme.fonts.weights.medium} fontSize={theme.fonts.sizes.xxlg} color="black">
        {title}
      </Text>

      <Text fontWeight={theme.fonts.weights.medium} fontSize={theme.fonts.sizes.reg} color="gray.600">
        {text}
      </Text>

      <Button
        mt={'1rem'}
        onClick={onClick}
        fontSize="1rem"
        lineHeight="1rem"
        fontWeight="600"
        fontFamily={theme.fonts.body}
        variant="link"
        border="none"
        color={theme.colors.pink}
        __css={{ _hover: { textDecoration: 'none' } }}
        leftIcon={<ComanagerAIIcon color="white" style={{ height: '1rem', width: '1rem', marginBottom: '0.1rem' }} />}>
        {buttonLabel}
      </Button>
    </Box>
  );
}
