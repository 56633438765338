//TECH DEBT : react-hooks-form should be removed.
import React from 'react';
import { API_STATUS_KEYS } from 'constants/constants';
import { useForm } from 'react-hook-form';
import { buildStatus } from 'utils/helpers';
import DropdownField from 'components/DropdownField/dropdown-field';
import {
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import Input from 'components/Input/input';
import { capitalizeFirstLetter } from 'utils/format';
import { stripeCrossBorderTerritories } from 'constants/stripe-cross-border-territories';
import { CenterContent, ModalError } from './settings-styles';
import * as API from 'utils/API/API';
const Group = API.group();
const User = API.user();

function StripeModal({
  showStripeModal,
  selectedStripeType,
  stripeLinkStatus,
  setStripeLinkStatus,
  user,
  selectedUserGroup,
  closeStripeModalHandler,
}) {
  const formMethodsSettings = useForm({ mode: 'onChange' });

  const redirectToStripeOnboarding = async () => {
    const formData = formMethodsSettings.getValues({ nest: true });
    const { stripeAccountCountryCode } = formData;
    setStripeLinkStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
    try {
      const { origin } = window.location;
      const stripeConnectUrl =
        selectedStripeType === 'payee'
          ? await User.getStripeConnectUrl(user?.id, origin, stripeAccountCountryCode)
          : await Group.getStripeConnectUrl(selectedUserGroup.id, origin, stripeAccountCountryCode);

      if (stripeConnectUrl) {
        window.open(stripeConnectUrl, '_self');
      }
      // better to leave loader spinning until redirect
      // setStripeLinkStatus(buildStatus(API_STATUS_KEYS.COMPLETE))
    } catch (err) {
      setStripeLinkStatus(buildStatus(err.message));
    }
  };

  return (
    <Modal isOpen={showStripeModal} onClose={closeStripeModalHandler}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Connect {selectedStripeType ? capitalizeFirstLetter(selectedStripeType) : ''} Stripe Account
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form onSubmit={e => e.preventDefault()}>
            <Input variant="light" title="Country of Banking">
              <DropdownField
                name={'stripeAccountCountryCode'}
                options={stripeCrossBorderTerritories}
                defaultValue="US"
                themeVariant="lightMode"
                ref={formMethodsSettings.register({ required: true })}
              />
            </Input>
            <CenterContent>
              <ModalError>{stripeLinkStatus.error}</ModalError>
            </CenterContent>
            <ModalFooter paddingX="0">
              <Button
                variant="smallDark"
                loading={stripeLinkStatus.inProgress}
                onClick={formMethodsSettings.handleSubmit(redirectToStripeOnboarding)}>
                Go to Stripe Onboarding
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default StripeModal;
