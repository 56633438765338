import React from 'react';
import Section from './section';
import SubSection from './sub-section';
import SectionHeaderBar from './section-header-bar';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import TextField from 'components/TextField/text-field';
import Input from 'components/Input/input';
import RoleSelector from './role-selector';
import { sanitizeFormatCharacters } from '../utils/format';
import * as API from 'utils/API/API';
import { RiAddLine } from 'react-icons/ri';

import {
  Box,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
  Button,
} from '@chakra-ui/react';
import TeamTable from './team-table';
import HEAP from '../constants/HEAP.gen.json';
import getFormValues from 'utils/get-form-values';
const Group = API.group();

export default function Team({ isAdmin, groupId, theme }) {
  const { data: teamMembers } = useQuery(['teamMembers', 0, Number(groupId)], () => Group.users(groupId, { page: 0 }), {
    enabled: !!groupId,
  });

  const teamMembersCount = teamMembers?.total || 0;
  const addMemberModal = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();

  const inviteMemberMutation = useMutation(args => Group.inviteUser(groupId, args.email, args.role), {
    onError: error =>
      toast({
        title: error?.response?.data?.detail || error.message || `Something went wrong adding a new team member`,
        status: 'error',
      }),
  });

  return (
    <>
      <Section>
        <SubSection>
          <SectionHeaderBar name={`Team (${teamMembersCount})`}>
            {isAdmin && (
              <Button
                outline
                variant="smallDark"
                style={{ padding: '0 1rem', width: '10rem' }}
                leftIcon={<RiAddLine />}
                onClick={addMemberModal.onOpen}
                heapCode={HEAP.PUBLIC.SETTINGS.TYPE.SETTINGS_BUTTON_ADD_ARTIST}>
                Add team member
              </Button>
            )}
          </SectionHeaderBar>
          <TeamTable groupId={groupId} />
        </SubSection>
      </Section>
      <ChakraModal isOpen={addMemberModal.isOpen} onClose={addMemberModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add new team member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              as="form"
              id="newMember"
              onSubmit={async ev => {
                ev.preventDefault();
                const formData = getFormValues(ev);
                await inviteMemberMutation.mutateAsync({
                  email: sanitizeFormatCharacters(formData.email),
                  role: formData.role,
                });
                await queryClient.invalidateQueries(['teamMembers', Number(groupId)]);
                addMemberModal.onClose();
              }}
              mt="4">
              <Input variant="light" title="Email">
                <TextField name="email" placeholder="member@company.com" id="email" required={true} />
              </Input>
              <Input variant="light" title="Role">
                <RoleSelector variant="light" />
              </Input>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="small" onClick={addMemberModal.onClose} style={{ marginRight: theme.space[3] }}>
              Cancel
            </Button>
            <Button variant="smallDark" type="submit" form="newMember">
              Add team member
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}
