import React from 'react';
import { Td } from '@chakra-ui/react';

function CellWrapper({ CellComponent, row, idx, cell, children, context, rowIdx, ...props }) {
  return CellComponent ? (
    <CellComponent
      idx={idx}
      rowData={row.original}
      value={cell.value}
      background={'none'}
      context={context}
      key={rowIdx}
      {...props}>
      {children}
    </CellComponent>
  ) : (
    <Td {...props}>{children}</Td>
  );
}

export default CellWrapper;
