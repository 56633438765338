import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import InfoTooltip from 'components/InfoTooltip/info-tooltip';
//revisit this
const FieldLabelWrapper = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.text.textInput[props.colorVariant].default};
  cursor: default;
  display: flex;
  font-weight: ${props => props.theme.fonts.weights.regular};
  line-height: 0.75rem;
  margin-bottom: 0.5rem;
  position: relative;
  white-space: nowrap;
`;

const LinkText = styled.div`
  font-size: ${props =>
    props.fontSize ? props.fontSize : props.small ? props.theme.fonts.sizes.xxsm : props.theme.fonts.sizes.xxsm};
  font-weight: ${props => props.theme.fonts.weights.medium};
  letter-spacing: 2px;
  min-height: ${props => props.theme.fonts.sizes.sm};
  text-transform: uppercase;
`;

const FieldLabelTooltip = styled(InfoTooltip)`
  margin-left: 0.25rem;
  position: relative;

  i {
    width: 0.75rem;
  }
`;

const FieldLabel = React.forwardRef(
  (
    {
      alert,
      className,
      disabled,
      icon,
      onClick,
      text,
      tooltip,
      fontSize,
      dark,
      light,
      children,
      required,
      tooltipPosition,
      ...props
    },
    ref
  ) => {
    const colorVariant = dark ? 'dark' : light ? 'light' : 'dark';

    return (
      <FieldLabelWrapper className={className} icon={icon} colorVariant={colorVariant}>
        <LinkText fontSize={fontSize}>
          {text}
          {required && ' *'}
        </LinkText>
        {tooltip && (
          <FieldLabelTooltip position={tooltipPosition} color={props.theme.colors.black60} text={tooltip} type="info" />
        )}
        {children}
      </FieldLabelWrapper>
    );
  }
);

FieldLabel.displayName = 'FieldLabel';

FieldLabel.defaultProps = {
  text: 'Field Label',
  disabled: false,
  onClick: () => null,
  tooltip: null,
  required: false,
  tooltipPosition: 'top',
};

export default withTheme(FieldLabel);
