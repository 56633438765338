import React from 'react';
import { useQuery } from 'react-query';
import styled from '@emotion/styled';
import BigNumber from 'bignumber.js';
import ViewWrapper from '../ViewWrapper/view-wrapper';
import HeaderSection from './header-section';
import { Box, useTheme, Spinner } from '@chakra-ui/react';
import createTableType from './statements-table';
import { labelView, payeeView } from './view-configurations';
import { calculateDynamicColumns } from './dynamic-width-columns';
import * as API from 'utils/API';
import useCurrentUser from '../../data-client/use-current-user';

const Payment = API.payment();

const StyledSection = styled.section`
  padding: 0 ${props => props.theme.space[2]};
  color: ${props => props.theme.colors.cream100};
  margin-bottom: ${props => props.theme.space[16]};
`;

const LabelTrackRevenueTable = createTableType(labelView.trackRevenueTable);
const LabelAlbumDownloadsTable = createTableType(labelView.albumDownloadTable);
const PayeeTrackRevenueTable = createTableType(payeeView.trackRevenueTable);
const PayeeAlbumDownloadsTable = createTableType(payeeView.albumDownloadTable);

const StatementDetails = props => {
  const { statementQuery, title, TrackRevenueTable, AlbumDownloadsTable, id } = props;

  const csvUrlQuery = useQuery(['csv-url', id], () => Payment.getCsvUrlByRoyaltyTransfer(id), {
    enabled: false,
    onSuccess: data => {
      Payment.getCsvFromUrl(data);
    },
  });

  let statementNameColumnTransform;
  if (statementQuery.data) {
    const searchableData = statementQuery.data.albums.concat(statementQuery.data.tracks);
    statementNameColumnTransform = ({ columns }) => calculateDynamicColumns({ data: searchableData, columns });
  }
  const theme = useTheme();
  return (
    <ViewWrapper alignWithNav designLayer={0} disableHorizontalScroll={true} {...props}>
      <StyledSection>
        <Box>
          <HeaderSection title={title} date={statementQuery.data?.transferred_at} csvUrlQuery={csvUrlQuery} />
          <Box overflowX={'auto'} mt={theme.space[12]} mb={theme.space[20]}>
            {statementQuery.isLoading ? (
              <Spinner size="sm" marginX=".75rem" color={theme.colors.black100} />
            ) : (
              <>
                <Box>
                  <TrackRevenueTable
                    tableTotal={statementQuery.data?.trackTotal}
                    data={statementQuery.data?.tracks}
                    status={statementQuery.status}
                    withDataColumnTransformFunc={statementNameColumnTransform}
                  />
                </Box>
                <Box>
                  <AlbumDownloadsTable
                    grandTotal={statementQuery.data?.grandTotal}
                    transferredAmount={statementQuery.data?.transferred_amount}
                    deductedFeeAmount={statementQuery.data?.deducted_fee_amount}
                    tableTotal={statementQuery.data?.albumTotal}
                    data={statementQuery.data?.albums}
                    status={statementQuery.status}
                    withDataColumnTransformFunc={statementNameColumnTransform}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </StyledSection>
    </ViewWrapper>
  );
};

export function LabelView(props) {
  const id = props?.match?.params?.id;

  const [dbUser] = useCurrentUser();
  const labelName = dbUser?.currentGroup?.name ?? '';

  const labelStatementQuery = useQuery(['label-statement', id], () => Payment.getRoyaltyTransferLabelStatement(id), {
    select: data => {
      const { trackTotal, albumTotal, grandTotal } = selectTotals(data);

      const tracks = data.tracks.map(track => ({
        ...track,
        streams_revenue: track.stream_gross_revenue_usd,
        downloads_revenue: track.download_gross_revenue_usd,
      }));

      const albums = data.albums.map(album => ({
        ...album,
        downloads_revenue: album.download_gross_revenue_usd,
      }));

      return {
        ...data,
        tracks,
        albums,
        trackTotal,
        albumTotal,
        grandTotal,
      };
    },
  });

  return (
    <StatementDetails
      statementQuery={labelStatementQuery}
      title={labelName}
      TrackRevenueTable={LabelTrackRevenueTable}
      AlbumDownloadsTable={LabelAlbumDownloadsTable}
      id={id}
    />
  );
}

export const PayeeView = props => {
  const id = props?.match?.params?.id;

  const [dbUser] = useCurrentUser();
  const payeeName = dbUser?.name ?? '';

  const payeeStatementQuery = useQuery(['payee-statement', id], () => Payment.getRoyaltyTransferPayeeStatement(id), {
    select: data => {
      const { trackTotal, albumTotal, grandTotal } = selectTotals(data);

      const tracks = data.tracks.map(track => ({
        ...track,
        streams_revenue: track.stream_pre_split_royalty_usd,
        downloads_revenue: track.download_pre_split_royalty_usd,
      }));

      const albums = data.albums.map(album => ({
        ...album,
        downloads_revenue: album.download_pre_split_royalty_usd,
      }));

      return {
        ...data,
        tracks,
        albums,
        trackTotal,
        albumTotal,
        grandTotal,
      };
    },
  });

  return (
    <StatementDetails
      statementQuery={payeeStatementQuery}
      title={payeeName}
      TrackRevenueTable={PayeeTrackRevenueTable}
      AlbumDownloadsTable={PayeeAlbumDownloadsTable}
      id={id}
    />
  );
};

const calcTableTotal = tableData => BigNumber.sum(0, ...tableData.map(r => r.net_royalty_usd));

function selectTotals(data) {
  const trackTotal = calcTableTotal(data.tracks);
  const albumTotal = calcTableTotal(data.albums);
  const removeFractionalCents = n => n.decimalPlaces(2, BigNumber.ROUND_FLOOR).toString();

  return {
    trackTotal: removeFractionalCents(trackTotal),
    albumTotal: removeFractionalCents(albumTotal),
    grandTotal: removeFractionalCents(trackTotal.plus(albumTotal)),
  };
}
