import { useQuery } from 'react-query';
import { searchSpotify } from '../utils/API/dsp-uri';

export function useSearchSpotify(params) {
  return useQuery(['search-spotify', params], () => {
    if (params.query && params.types) {
      return searchSpotify(params.query, params.types);
    } else {
      return { data: null };
    }
  });
}
