import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/private-route';
import LabelDashboard from 'analytics/label/label-dashboard-container';
import TrackDashboard from 'analytics/track/track-dashboard-container';
import { hasPermission } from 'permissions/has-permission';
import { useGlobalData } from 'utils/global-data';
import AnalyticsContextProvider from './analytics-context';
import ArtistDashboardContainer from './artist/artist-dashboard-container';

export default function AnalyticsRouter({ isPrivate, userGroupId }) {
  const { dbUser } = useGlobalData();
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  return (
    <AnalyticsContextProvider>
      <RouteComponent
        path="/analytics"
        exact
        render={() => <Redirect to={`/analytics/label/${dbUser?.currentGroup?.id}`} />}
      />
      <RouteComponent
        path="/analytics/label/:groupId"
        exact
        render={props => {
          if (hasPermission({ featureNames: ['Data Dashboard - Label View'], permissions: dbUser?.permissions })) {
            return <LabelDashboard {...props} userGroupId={userGroupId} />;
          }
          return <Redirect to={`/permission-denied`} />;
        }}
      />
      <RouteComponent
        path="/analytics/track/:trackId"
        exact
        render={props => {
          if (hasPermission({ featureNames: ['Data Dashboard - Track View'], permissions: dbUser?.permissions })) {
            return <TrackDashboard {...props} userGroupId={userGroupId} />;
          }
          return <Redirect to={`/permission-denied`} />;
        }}
      />
      <RouteComponent
        path="/analytics/label/:groupId/artists/:artistId"
        exact
        render={props => {
          if (hasPermission({ featureNames: ['Data Dashboard - Artist View'], permissions: dbUser?.permissions })) {
            return <ArtistDashboardContainer {...props} userGroupId={userGroupId} />;
          }
          return <Redirect to={`/permission-denied`} />;
        }}
      />
    </AnalyticsContextProvider>
  );
}
