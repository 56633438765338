import React, { useState } from 'react';
import {
  Box,
  Button as ChakraButton,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  useDisclosure,
  useClipboard,
  useToast,
  chakra,
  FormLabel,
  FormControl,
  Input,
  useTheme,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from 'react-query';
import { useTable, useSortBy, usePagination } from 'react-table';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { MdContentCopy } from 'react-icons/md';
import { RiArrowUpLine, RiArrowDownLine } from 'react-icons/ri';

import TablePaginationFooter from 'components/Table/table-pagination-footer';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import ConfirmationModal from 'components/Modal/confirmation-modal';
import * as API from 'utils/API/API';

import { CheckIcon, WarningTwoIcon } from '@chakra-ui/icons';

const Payee = API.payee();

const EditPayee = ({ payeeName, payee, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);

  const theme = useTheme();

  const formLabelStyles = {
    mb: '0.15rem',
    fontSize: '.6rem',
    textTransform: 'uppercase',
    color: theme.colors.black60,
  };

  return (
    <>
      <Box key="edit-payee" as="form" id="edit-payee" mt="4">
        <FormControl>
          <FormLabel {...formLabelStyles}>Name</FormLabel>
          <Input
            name="name"
            placeholder="Name"
            isRequired
            isInvalid={false}
            errorBorderColor="crimson"
            onChange={onChange}
            onFocus={() => {
              setIsEditing(true);
            }}
            value={!isEditing && !payeeName ? payee.original.full_name : payeeName}></Input>
        </FormControl>
      </Box>
    </>
  );
};

const TableRow = ({ payee }) => {
  const toast = useToast();
  const removePayeeModal = useDisclosure();
  const editPayeeModal = useDisclosure();
  const copyEmail = useClipboard(payee.original?.payment_account?.user?.email);
  const [payeeName, setPayeeName] = useState('');
  const handlePayeeNameChange = event => {
    setPayeeName(event.target.value);
  };
  const clearPayeeName = () => {
    setPayeeName('');
  };

  const removePayeeMutation = useMutation(() => Payee.remove(payee.original.id), {
    onError: () => {
      toast({
        title: `Payee failed to be removed.`,
        status: 'error',
      });
    },
  });
  const editPayeeMutation = useMutation(data => Payee.update(payee.original.id, data), {
    onError: () => {
      toast({
        title: `Failed to edit payee.`,
        status: 'error',
      });
    },
  });
  const queryClient = useQueryClient();

  const onRemovePayee = async () => {
    await removePayeeMutation.mutateAsync();
    await queryClient.invalidateQueries(['payees']);
    removePayeeModal.onClose();
  };

  const onEditPayee = async _values => {
    await editPayeeMutation.mutateAsync({
      full_name: payeeName,
    });
    await queryClient.invalidateQueries(['payees']);
    editPayeeModal.onClose();
  };

  const onCopyPayeeEmail = () => {
    copyEmail.onCopy();
    toast({
      title: `Copied Payee Email to clipboard`,
    });
  };

  return (
    <>
      <Tr key={payee.original.name} {...payee.getRowProps()} borderColor={'black20'}>
        {payee.cells.map(cell => (
          <Td
            key={`${payee.original.id}-cell`}
            isNumeric={cell.column.isNumeric}
            {...cell.getCellProps({
              color: cell.column.id === 'name' ? 'black100' : 'inherit',
              textAlign: cell.column.id === 'menu' ? 'center' : 'inherit',
            })}>
            <Box minH="8" display="flex" alignItems="center" justifyContent={cell.column.id === 'menu' && 'center'}>
              {cell.render('Cell')}
              {cell.column.id === 'payment_account.user.email' && payee?.original?.payment_account?.user?.email && (
                <IconButton
                  marginLeft=".5rem"
                  color="inherit"
                  borderColor="black50"
                  size="xs"
                  variant="ghost"
                  aria-label="Copy Payment Email"
                  icon={<MdContentCopy />}
                  onClick={onCopyPayeeEmail}
                />
              )}
              {cell.column.id === 'menu' && !!payee.original.payment_account.user && (
                <Menu>
                  <MenuButton
                    as={ChakraButton}
                    variant="outline"
                    size="sm"
                    borderColor="black50"
                    _hover={{ backgroundColor: 'transparent' }}
                    width="fit-content"
                    alignSelf="center">
                    <HiOutlineDotsHorizontal />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={editPayeeModal.onOpen}>Edit Payee</MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Box>
          </Td>
        ))}
      </Tr>
      <ConfirmationModal
        headerText="Remove payee?"
        body={`Are you sure you want to remove ${
          payee?.original?.payment_account?.user?.full_name || 'Untitled payee'
        }? This action cannot be
            undone.`}
        confirmText="Remove payee"
        disclosure={removePayeeModal}
        onSubmit={onRemovePayee}
      />
      <ConfirmationModal
        headerText="Edit payee"
        body={
          <EditPayee
            payeeName={payeeName}
            payee={payee}
            onChange={handlePayeeNameChange}
            clearPayeeName={clearPayeeName}
          />
        }
        confirmText="Edit payee"
        testCode="edit-payee-confirm"
        disclosure={editPayeeModal}
        confirmButtonProps={{
          form: 'payee',
          style: {},
        }}
        onSubmit={onEditPayee}
      />
    </>
  );
};

const MIN_CHARS_FOR_SEARCH = 2;

const getBankingStatus = payment_account => {
  // Logged in, no stripe
  if (!payment_account?.stripe_account_id) return 'No stripe account';

  // All set!
  // - unhandled case: a payee can be paid "today" but will require "more info later"
  if (payment_account?.ready_for_payment) return 'Stripe account linked';

  // Stripe action required
  return 'Stripe requires more info';
};

export default function PayeesTable({ filterTerm, payees, loadingStatus }) {
  const data = React.useMemo(() => {
    const rawData = payees ?? [];
    let filteredData = [...rawData];

    if (filterTerm?.length >= MIN_CHARS_FOR_SEARCH) {
      const filterRegExString = filterTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const matcher = new RegExp(`\\b${filterRegExString}`, 'gi');

      filteredData = filteredData.filter(row => row?.full_name?.match(matcher) || false);
    }

    return filteredData.map(payee => ({
      ...payee,
      banking_status: getBankingStatus(payee?.payment_account),
    }));
  }, [payees, filterTerm]);

  const columns = React.useMemo(
    () => [
      {
        Header: `Name`,
        accessor: 'full_name',
        // eslint-disable-next-line react/display-name
        Cell: props => <Box color="black100">{props.value}</Box>,
      },
      {
        Header: 'Payment Email',
        accessor: 'payment_account.user.email',
        // eslint-disable-next-line react/display-name
        Cell: props => (
          <Box maxW="235px" whiteSpace="nowrap" overflowX="hidden" textOverflow="ellipsis">
            {props.value}
          </Box>
        ),
      },
      {
        Header: 'Payment Account',
        accessor: 'banking_status',
        // eslint-disable-next-line react/display-name
        Cell: props => {
          const linked = props.value === 'Stripe account linked';
          const color = linked ? '#3C923A' : 'destructiveRed';
          const RowIcon = linked ? CheckIcon : WarningTwoIcon;
          return (
            <Box color={color} style={{ display: 'flex', alignItems: 'center' }}>
              <RowIcon w={4} h={4} color={color} style={{ marginRight: '0.5rem' }} />
              {props.value}
            </Box>
          );
        },
      },
      {
        Header: '',
        accessor: 'menu',
        disableSortBy: true,
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [],
        pageSize: 25,
        sortBy: [
          {
            id: 'full_name',
            desc: false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  return (
    <SkeletonLoader status={loadingStatus} skeletonProps={{ height: '240px', width: '100%' }}>
      <Box overflowX="auto">
        <Table size="sm" variant="light" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, groupIndex) => (
              <Tr key={`${groupIndex}-headergroup`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, headerIndex) => (
                  <Th
                    key={`${groupIndex}-${headerIndex}-tableheader`}
                    isNumeric={column.isNumeric}
                    whiteSpace="nowrap"
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Box style={{ color: 'white' }} display="inline">
                      {column.isSorted ? (
                        <chakra.span pr="3" position="relative" top="1px">
                          {column.isSortedDesc ? (
                            <Icon as={RiArrowUpLine} aria-label="sorted descending" />
                          ) : (
                            <Icon as={RiArrowDownLine} aria-label="sorted ascending" />
                          )}
                        </chakra.span>
                      ) : null}
                      {column.render('Header')}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map(payee => {
              prepareRow(payee);
              return <TableRow key={`payee-${payee.original.id}`} payee={payee} />;
            })}
          </Tbody>
        </Table>
      </Box>
      <TablePaginationFooter
        onPrevPage={previousPage}
        isPrevPageDisabled={!canPreviousPage}
        page={pageIndex}
        totalPages={pageOptions.length}
        onNextPage={nextPage}
        isNextPageDisabled={!canNextPage}
      />
    </SkeletonLoader>
  );
}
