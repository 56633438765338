import numeral from 'numeral';

const formatCharacters = ['[\u2000-\u2011]', '[\u205f-\u206f]', '\t', '\r', '\n'];
const formatCharacterRegex = new RegExp(`${formatCharacters.join('|')}`, 'g');

const sanitizeFormatCharacters = str => {
  return str ? str.replace(formatCharacterRegex, '').trim() : str;
};

const grossIncome = (incomeValue, currency, exchangeRate, percent) => {
  const income = incomeValue * exchangeRate * percent;
  return formatUsd(income, currency);
};

const formatUsd = (number, currency = 'usd', decimals = 2) => {
  if (isNaN(number)) {
    return '$0';
  } else
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
};

const getFormattedTime = s => {
  const seconds = s % 60;
  const minutes = `${parseInt(s / 60) % 60}`;
  const hours = `${parseInt(s / 3600) % 60}`;
  return `${hours > 0 ? `${numeral(hours).format('00')}:` : ''}${numeral(minutes).format('00')}:${numeral(
    seconds
  ).format('00')}`;
};

const capitalizeFirstLetter = str => (!str.length ? '' : str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());
const titleCase = str =>
  !str.length
    ? ''
    : str
      .split(' ')
      .map(str => capitalizeFirstLetter(str))
      .join(' ');

export { grossIncome, formatUsd, capitalizeFirstLetter, titleCase, getFormattedTime, sanitizeFormatCharacters };
