import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const ARTIST_LIST_PAGE_SIZE = 50;

const Parties = () => {
  const search = params =>
    AuthAxios.get(`${DataApiUrl}/parties`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const create = ({ full_name, platform_id, type, user_group_id }) =>
    AuthAxios.post(`${DataApiUrl}/parties`, { full_name, platform_id, type, user_group_id }).then(res => res.data.data);

  const patch = (id, data) => AuthAxios.patch(`${DataApiUrl}/parties/${id}`, data).then(res => res.data.data);

  const destroy = id =>
    AuthAxios.delete(`${DataApiUrl}/parties/${id}`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const list = async (type, params) => {
    try {
      let results = [];
      let totalPages = 1;

      for (let page = 0; page < totalPages; page++) {
        // There is potential to receive duped results if the pages shift while
        // querying. Need to dedupe.  We could still miss an item but this is
        // the same with querying/rebuilding data anyways.
        const queryData = await AuthAxios.get(`${DataApiUrl}/parties`, { params: { ...params, type, page } }).then(
          res => res.data
        );
        const { data, total } = queryData;

        totalPages = Math.ceil(total / ARTIST_LIST_PAGE_SIZE);
        results = results.concat(data);
      }

      const idSetTracker = new Set();
      const dedupedResults = results.filter(x => {
        if (idSetTracker.has(x.asset_party_id) === false) {
          idSetTracker.add(x.asset_party_id);
          return true;
        }

        return false;
      });

      return { total: dedupedResults.length, data: dedupedResults };
    } catch (error) {
      console.error(error.message);
    }

    return {};
  };

  const listGroupParties = async user_group_id => {
    return list(null, { user_group_id });
  };

  return {
    search,
    create,
    patch,
    destroy,
    list,
    listGroupParties,
  };
};

export default Parties;
