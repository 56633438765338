import React, { useState } from 'react';
import { Box, useDisclosure, useToast } from '@chakra-ui/react';
import MarketingDiscoveryModeTable from './marketing-discovery-mode-table';
import * as API from 'utils/API/API';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import OptimizeCard from './optimize-card';
import useDebounce from 'utils/debounce';
import ConfirmationModal from './confirmation-modal';
import CustomStrategyModal from './custom-strategy-modal';

const MarketingDiscoveryModeTracks = API.marketingDiscoveryModeTracks();
const PAGE_SIZE = 8;

export default function MarketingDiscoveryModeDraft({ userGroupId }) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const successModal = useDisclosure();
  const customStrategyModal = useDisclosure();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const query = useQuery(
    ['discoveryModeDraftTable', page, userGroupId, debouncedSearchTerm],
    () =>
      MarketingDiscoveryModeTracks.getDraftCampaignTracks({
        page_size: PAGE_SIZE,
        page_index: page,
        user_group_id: userGroupId,
        search_term: debouncedSearchTerm,
      }),
    { enabled: !!userGroupId }
  );

  const { data, totalPages } = React.useMemo(() => {
    const { data: queryData = { total: 1, results: [] } } = query;
    const { total = 1, results } = queryData;
    const totalPages = Math.max(Math.ceil(total / PAGE_SIZE), 1);

    return { data: results, totalPages };
  }, [query.data]);

  const optimizeFanGrowthMutation = useMutation(
    userGroupId => MarketingDiscoveryModeTracks.optimizeFanGrowth(userGroupId),
    {
      onError: () => {
        toast({
          title: 'Failed to optimize fan growth.',
          status: 'error',
        });
      },
    }
  );

  const optimizeRevenueGrowthMutation = useMutation(
    userGroupId => MarketingDiscoveryModeTracks.optimizeRevenueGrowth(userGroupId),
    {
      onError: () => {
        toast({
          title: 'Failed to optimize revenue growth.',
          status: 'error',
        });
      },
    }
  );

  const handleOptimizeFanGrowthClick = async () => {
    await optimizeFanGrowthMutation.mutateAsync(userGroupId);
    await queryClient.invalidateQueries(['discoveryModeDraftTable']);
    setSuccessMessage(
      'I have selected the tracks that I recommend you opt-in for growing your fan base most effectively.'
    );
    successModal.onOpen();
  };

  const handleOptimizeRevenueGrowthClick = async () => {
    await optimizeRevenueGrowthMutation.mutateAsync(userGroupId);
    await queryClient.invalidateQueries(['discoveryModeDraftTable']);
    setSuccessMessage('I have selected tracks that I recommend you opt-in for growing your revenue most effectively.');
    successModal.onOpen();
  };

  return (
    <>
      <CustomStrategyModal isOpen={customStrategyModal.isOpen} onClose={customStrategyModal.onClose} />

      <Box pb={5} display={'flex'} gap={5}>
        {data?.length > 0 && (
          <>
            <OptimizeCard
              title="Optimize Fan Growth"
              text="Use recommended co-manager tracks for growing your fan base most effectively."
              buttonLabel="Apply Co-Manager Recommendations"
              onClick={() => handleOptimizeFanGrowthClick()}
            />

            <OptimizeCard
              title="Optimize Revenue Growth"
              text="Use recommended co-manager tracks for growing your revenue most effectively."
              buttonLabel="Apply Co-Manager Recommendations"
              onClick={() => handleOptimizeRevenueGrowthClick()}
            />
          </>
        )}
        <OptimizeCard
          title="Custom Strategy"
          text="Use co-manager to walk through a strategy on what to opt into Spotify discovery mode."
          buttonLabel="Start Planning with Co-Manager"
          onClick={customStrategyModal.onOpen}
        />
      </Box>

      <MarketingDiscoveryModeTable
        data={data}
        query={query}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        userGroupId={userGroupId}
        emptyStateMsg="No eligible tracks for Spotify discovery mode. Eligible tracks typically show up on the 11th of each month."
      />

      <ConfirmationModal
        isOpen={successModal.isOpen}
        onClose={successModal.onClose}
        variant="light"
        text={successMessage}
        headerText="Success"
        hideCancel
      />
    </>
  );
}
