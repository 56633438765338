import React, { useState, useContext } from 'react';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';
import Cookies from 'js-cookie';
import { APP_VIEWS } from './routes';

const generateDefaultPerformanceFilter = () => ({
  category: 'income',
  sort: 'BY_DSP',
  startRange: '2019-04',
  endRange: '2020-01',
  interval: 'MONTHLY',
});

export const PERFORMANCE_FILTERS = {
  SETTINGS: 'SETTINGS',
};

export const SEARCH_RESULT_FILTERS = {
  TEXT: 'TEXT',
};

const generateInitialState = () => ({
  [APP_VIEWS.HOME]: {
    [SEARCH_RESULT_FILTERS.TEXT]: '',
    meta: {
      shouldDoQuerySearch: true,
    },
  },
  [APP_VIEWS.SEARCH]: {
    [SEARCH_RESULT_FILTERS.TEXT]: '',
    meta: {
      shouldDoQuerySearch: true,
    },
  },
  [APP_VIEWS.SPLITS_AND_PAYMENTS]: {
    meta: {
      shouldDoQuerySearch: true,
    },
  },
  [APP_VIEWS.STATEMENTS]: {
    meta: {
      shouldDoQuerySearch: false,
    },
  },
  [APP_VIEWS.PERFORMANCE]: {
    [PERFORMANCE_FILTERS.SETTINGS]: generateDefaultPerformanceFilter(),
    meta: {
      shouldDoQuerySearch: false,
    },
  },
  [APP_VIEWS.SETTINGS]: {
    meta: {
      shouldDoQuerySearch: false,
    },
  },
});

export const FiltersContext = React.createContext();
export const useFilters = () => useContext(FiltersContext);
export const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState(generateInitialState());

  const [subFilters, setSubFilters] = useState({
    [APP_VIEWS.PERFORMANCE]: {
      leftSubFilter: null,
      subFilterType: null,
      rightSubFilter: null,
    },
  });

  const getCookieValue = (view, key) => {
    return Cookies.get(`vce_filters_${view.toLowerCase()}_${key.toLowerCase()}`);
  };

  const setCookieValue = (view, key, value) => {
    Cookies.set(
      `vce_filters_${view.toLowerCase()}_${key.toLowerCase()}`,
      isObject(value) || isArray(value) ? JSON.stringify(value) : value
    );
  };

  const resetFilters = view => {
    const initialState = generateInitialState()[view];

    Object.keys(initialState).forEach(key => setCookieValue(view, key, initialState[key]));

    setFilters({ ...filters, [view]: initialState });
  };

  const updateFilters = (view, key, value, cookieOnly = false) => {
    setCookieValue(view, key, value);

    if (!cookieOnly) {
      setFilters({ ...filters, [view]: { ...filters[view], [key]: value } });
    }
  };

  // not saved in cache
  const updateSubFilters = (view, key, value) => {
    setSubFilters(prevState => ({
      ...subFilters,
      [view]: { ...prevState[view], [key]: value },
    }));
  };

  return (
    <FiltersContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        updateFilters,
        subFilters,
        updateSubFilters,
        getCookieValue,
      }}>
      {children}
    </FiltersContext.Provider>
  );
};
