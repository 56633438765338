import React from 'react';
import styled from '@emotion/styled/macro';
import Icon from '../Icon/icon';
import Tooltip from '../Tooltip/tooltip';

const TooltipIcon = styled(Icon)`
  opacity: 0.5;
  transition: opacity 250ms ease;
  height: 0.7rem;
  width: 0.7rem;

  &:hover {
    opacity: 1;
  }
`;

function InfoTooltip({ text, color, type, className, iconStyle, ...props }) {
  return (
    <Tooltip className={className} text={text} {...props}>
      <TooltipIcon style={iconStyle} type={type} color={color} />
    </Tooltip>
  );
}

InfoTooltip.defaultProps = {
  type: 'info',
};

export default InfoTooltip;
