import * as React from 'react';
import AnalyticsLayout from 'analytics/analytics-layout';
import useCachedMetrics from '../useCachedMetrics';
import * as API from '../../utils/API/API';
import { useAnalyticsContext } from '../analytics-context';
import { countStreamsByDsp } from '../count-streams-by-dsp';
import { splitDataIntoSubsets } from '../split-data-into-subsets';
import { calculateDspDataStatus } from '../calculate-dsp-data-status';
import { SUPPORTED_DSPS } from '../supported-dsps';
import { SUCCESS_STATUS } from '../../constants/request-statuses';
import history from '../../utils/history';
import { ArtistsCell, GotoArtistCell, StreamsCell, TrendCell } from '../table/artists-table-cells';
import TotalStreams from '../total-streams';
import TopTracksTable from '../top-tracks-table';
import DspPieChart from '../dsp-pie-chart';
import SearchAndFilter from '../search-and-filter';
import PerformanceChart from '../performance-chart';

const Metrics = API.metrics();

function tableSortAlphabetical(a, b, columnId) {
  // react-table not great at sorting strings for some reason
  const aColumnLowerCase = a?.values?.[columnId]?.toLowerCase() ?? '';
  const bolumnLowerCase = b?.values?.[columnId]?.toLowerCase() ?? '';
  if (aColumnLowerCase < bolumnLowerCase) return -1;
  if (aColumnLowerCase > bolumnLowerCase) return 1;

  return 0;
}

const config = {
  table: {
    title: 'All Artists',
    rowsPerPage: 10,
    dataProvider: ({ domainData }) => {
      return domainData?.data?.data?.series?.artists || [];
    },
    onRowSelected: (rowData, domainData) => {
      const groupId = domainData.data.data.resource.user_group_id;
      return history.push(`/analytics/label/${groupId}/artists/${rowData.asset_party_id}`);
    },
    queryKey: ({ context }) => {
      return ['label-artists', context.currentMetricsInterval.interval, context.dateRangeString];
    },
    columns: [
      {
        Header: 'Artist',
        accessor: 'full_name',
        CellComponent: ArtistsCell,
        sortType: tableSortAlphabetical,
      },
      {
        Header: 'Streams',
        accessor: 'streams',
        CellComponent: StreamsCell,
      },
      {
        Header: '', //Header: 'Trends' TODO add trends back once wow/mom data is back,
        accessor: 'time_over_time',
        CellComponent: TrendCell,
      },
      {
        id: 'blank',
        disableSortBy: true,
        Header: '',
        CellComponent: GotoArtistCell,
      },
    ],
  },
  cards: [TotalStreams, TopTracksTable, DspPieChart],
  masthead: {
    viewTypeText: 'Label',
    getTitle: data => `${data?.data?.data?.resource?.name || ''}`,
  },
  SearchComponent: SearchAndFilter,
  PerformanceChart,
};

export default function LabelDashboard({ ...props }) {
  const {
    params: { groupId },
  } = props.match;

  const { dailyData, updateAnalyticsState, currentMetricsInterval } = useAnalyticsContext();

  function aggregateStats({ data }) {
    if (Object.keys(data?.series || {}).length === 0) return {};

    const streamCounts = countStreamsByDsp(data.series.dsps);
    const { subsetOfData: dataSubset } = splitDataIntoSubsets(streamCounts, currentMetricsInterval.interval);
    const dspMetadata = calculateDspDataStatus(streamCounts);

    const dspTotalMap = SUPPORTED_DSPS.reduce((dspTotals, dsp) => {
      dspTotals[dsp] = 0;
      return dspTotals;
    }, {});

    data.rollups.dsps.forEach(d => {
      if (d.dsp_name in dspTotalMap) dspTotalMap[d.dsp_name] += d.streams;
    });

    const dsps = Object.keys(dspTotalMap)
      .map(dspName => ({
        streams: dspTotalMap[dspName],
        dspName,
      }))
      .sort((a, b) => b.streams - a.streams);

    const rollups = {
      artists: {
        status: SUCCESS_STATUS,
        data: data.rollups.artists,
      },
      dsps,
    };

    return {
      dspMetadata,
      totalStreams: data?.streams,
      dataSubset,
      prevPeriodDataSubset: [],
      streamPercentageChange: data?.time_over_time,
      rollups,
    };
  }

  const labelData = useCachedMetrics(
    ['label-metrics', groupId, currentMetricsInterval.interval],
    () =>
      Metrics.getLabelMetrics(groupId, {
        metrics_interval: currentMetricsInterval,
      }),
    {
      enabled: !!groupId,
      onSuccess: response => {
        const aggregate = aggregateStats(response);
        updateAnalyticsState(aggregate);
      },
    }
  );

  return (
    <AnalyticsLayout key={props.match.url} domainData={labelData} dailyData={dailyData} config={config} {...props} />
  );
}
