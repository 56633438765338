import StateManagerCollection from '../../state-management/state-manager-collection';
import { MANAGE_CLIPS_VIEWS } from './clip-route-container';

class LeftRailStateCollection extends StateManagerCollection {
  #props;
  StateManagerItemClass = StateManagerCollection;

  constructor(...managers) {
    super(...managers);
    this.activeManager = managers.find(manager => manager?.pathMatches);
  }

  //LAZY CACHING
  get props() {
    if (!this.#props) {
      this.#props = this.reduce((props, manager) => {
        return {
          ...props,
          [manager.name]: this.#toRailProps(manager),
        };
      }, {});
    }
    return this.#props;
  }

  get nextManager() {
    const managerGraph = {
      [MANAGE_CLIPS_VIEWS.SETUP]: MANAGE_CLIPS_VIEWS.RELEASE_INFO,
      [MANAGE_CLIPS_VIEWS.RELEASE_INFO]: MANAGE_CLIPS_VIEWS.DISTRIBUTION,
      [MANAGE_CLIPS_VIEWS.DISTRIBUTION]: MANAGE_CLIPS_VIEWS.TRACK_DETAILS,
      [MANAGE_CLIPS_VIEWS.TRACK_DETAILS]: MANAGE_CLIPS_VIEWS.CREATE,
      [MANAGE_CLIPS_VIEWS.CREATE]: MANAGE_CLIPS_VIEWS.REVIEW,
    };

    return this[managerGraph[this.activeManager?.name]];
  }

  #toRailProps(manager) {
    return {
      name: manager.name,
      allValid: manager.allValid,
      active: manager.pathMatches,
      path: manager.path,
      title: manager.title,
      linkTitle: manager.linkTitle,
      disabled: manager.disabled,
      disabledHoverText: manager.disabledHoverText,
      tainted: manager.tainted,
    };
  }
}

export default LeftRailStateCollection;
