import { useMutation, useQuery } from 'react-query';
import * as API from 'utils/API/API';

const Membership = API.membership();

export const useGetStripeTiers = params => {
  return useQuery(['membership-tiers', params], () => {
    return Membership.listTiers(params);
  });
};

export const useCancelStripeSubscription = () => useMutation(body => Membership.cancelSubscription(body));

export const useUpdateStripeDefaultPaymentMethod = () => useMutation(body => Membership.updatePaymentDefault(body));

export const useGetStripeClientSecret = () =>
  useQuery(['client-secret'], () => {
    return Membership.getClientSecret();
  });

export const useGetPreviewProratedUpgradeAmount = ({ subscription_proration_date, upgrade_to, discount_id, enabled }) =>
  useQuery(
    ['preview-prorated-amount', upgrade_to, discount_id],
    () => {
      return Membership.getPreviewProratedUpgradeAmount(subscription_proration_date, upgrade_to, discount_id);
    },
    {
      enabled,
      retry: false,
    }
  );

export const useUpdateUpgradeStripeSubscription = () => useMutation(body => Membership.upgradeSubscription(body));

export const useAccelerateTrialSubscription = () => useMutation(body => Membership.accelerateTrialSubscription(body));
