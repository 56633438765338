import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/private-route';
import useUser from 'data-client/use-current-user.jsx';
import MarketingDiscoveryMode from './marketing-discovery-mode';

export default function MarketingDiscoveryModeRouter({ isPrivate, userGroupId }) {
  const [user] = useUser();
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  if (!user?.currentGroup?.id) return <></>;

  return (
    <>
      <RouteComponent
        path="/marketing-discovery-mode"
        exact
        render={() => <Redirect to={`/marketing-discovery-mode/${user?.currentGroup?.id}`} />}
      />
      <RouteComponent
        path="/marketing-discovery-mode/:groupId"
        exact
        render={props => <MarketingDiscoveryMode {...props} userGroupId={userGroupId} />}
      />
    </>
  );
}
