import React from 'react';
import Box from '../Box/Box';
import { Text, Icon, Flex } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import icons from '../../utils/icons';
import ReactMarkdown from 'react-markdown';

const AiAgentMessage = ({ unfurled_text, createdAt, hasTime }) => {
  return (
    <Flex lineHeight="1.5rem" fontSize="1rem" gap="0.25rem" maxWidth="48rem" direction="row">
      <Box w="1.25rem" flexShrink="0">
        <Icon
          as={icons.comanager}
          display="inline"
          color="pink"
          pr="0.25rem"
          w="1.25rem"
          h="1.25rem"
          title="Co-Manager"></Icon>
      </Box>
      <Box>
        <Text verticalAlign="middle" lineHeight="1.25rem" fontSize="1rem" color="black80" fontWeight="600">
          Co-Manager
        </Text>
        <Box paddingTop="0.25rem" className="markdown-styles">
          <ReactMarkdown>{unfurled_text}</ReactMarkdown>
        </Box>
        <Box variant="messageUnderBubble">
          {hasTime === true ? `${DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATETIME_SHORT)}` : undefined}
        </Box>
      </Box>
    </Flex>
  );
};

export default AiAgentMessage;
