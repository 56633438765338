import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';

import { renderFieldError } from 'utils/form';

import { Label, disableSelection } from '../../features/styles';

const Wrapper = styled.div`
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;

  ${props =>
    props.invalid &&
    `
    border: 1px solid ${props.theme.colors.border.buttonToggle[props.colorVariant].error};
	`}
`;

const SubWrapper = styled.div`
  display: flex;
`;

export const Activator = styled.button`
  ${disableSelection}
  background: ${props => props.theme.colors.background.buttonToggle[props.colorVariant].default};
  border: 1px solid transparent;
  border-right: 1px solid ${props => props.theme.colors.border.buttonToggle[props.colorVariant].default};
  color: ${props => props.theme.colors.text.buttonToggle[props.colorVariant].default};
  cursor: pointer;
  display: block;
  font-weight: ${props => props.theme.fonts.weights.regular};
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  transition: background 250ms ease, border-radius 250ms ease, border 250ms ease, color 250ms ease;

  &:active {
    background: ${props => props.theme.colors.background.buttonToggle[props.colorVariant].active};
    border-right: 1px solid ${props => props.theme.colors.border.buttonToggle[props.colorVariant].active};
    color: ${props => props.theme.colors.text.buttonToggle[props.colorVariant].active};
  }

  ${props =>
    !props.selected &&
    `
		&:hover {
			background: ${props.theme.colors.background.buttonToggle[props.colorVariant].hover};
			border-right: 1px solid ${props.theme.colors.border.buttonToggle[props.colorVariant].hover};
			color: ${props.theme.colors.text.buttonToggle[props.colorVariant].hover};
		}
	`}

  ${props =>
    props.selected &&
    `
		background: ${props.theme.colors.background.buttonToggle[props.colorVariant].selected};
		border-right: 1px solid ${props.theme.colors.border.buttonToggle[props.colorVariant].selected};
		color: ${props.theme.colors.text.buttonToggle[props.colorVariant].selected};
		font-weight: ${props.theme.fonts.weights.bold};
	`}

	${props =>
    props.disabled &&
    `
		pointer-events: none;
		background: ${props.theme.colors.background.buttonToggle[props.colorVariant].disabled};
		border-right: 1px solid ${props.theme.colors.border.buttonToggle[props.colorVariant].disabled};
		color: ${props.theme.colors.text.buttonToggle[props.colorVariant].disabled};
	`}

	&:first-child {
    border-radius: 4px 0px 0px 4px;
  }

  &:last-child {
    border-radius: 0px 4px 4px 0px;
    border: 0;
  }

  &:only-child {
    border-radius: 4px;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const ClickShrowd = styled.span`
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ButtonToggle = React.forwardRef(
  ({ className, style, name, options, disabled, error, setValue, defaultValue, onClick, heapCode }, ref) => {
    const { watch } = useFormContext();
    const selectedValue = watch(name);

    const colorVariant = 'default';

    const handleToggleChange = value => {
      setValue(name, value, { shouldDirty: true });
    };

    // default Value -- optional prop
    // major rendering issues makes a useEffect() not work as expected.
    // "only set the defaultValue if there isn't yet a selected value"
    if (defaultValue && !selectedValue) {
      setValue(name, defaultValue, { shouldDirty: true });
    }

    return (
      <Wrapper className={className} style={style} colorVariant={colorVariant} invalid={!!error}>
        <SubWrapper>
          {options.map((option, i) => (
            <React.Fragment key={i}>
              <Activator
                type="button"
                colorVariant={colorVariant}
                disabled={disabled}
                // eslint-disable-next-line
                selected={option.value == selectedValue}
                onClick={() => handleToggleChange(option.value)}>
                <Label toggleButton>{option.label}</Label>
                <ClickShrowd onClick={() => onClick(option.value)} data-heap={heapCode} />
                <HiddenInput name={name} type="radio" value={option.value} disabled={disabled} ref={ref} />
              </Activator>
            </React.Fragment>
          ))}
        </SubWrapper>
        {error && renderFieldError(error)}
      </Wrapper>
    );
  }
);

ButtonToggle.displayName = 'ButtonToggle';

ButtonToggle.defaultProps = {
  options: [],
  disabled: false,
  onClick: () => {},
};

export default withTheme(ButtonToggle);
