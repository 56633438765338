import React from 'react';
import SkeletonLoader from '../components/loading-containers/skeleton-loader';
import { Box, Heading, Flex, HStack, Avatar, useTheme } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import Artwork from '../components/Artwork/artwork';
import MastheadSubtitle from './masthead-subtitle';
import DspDataStatus from './dsp-data-status';

const HeaderSection = styled.section`
  padding: 0 8px;
  margin-bottom: 16px;
  font-size: 32px;
  color: #eee6db;
  width: 100%;
`;

const defaultSkeletonProps = {
  width: '100%',
  height: '36px',
};

const Masthead = ({
  skeletonProps = defaultSkeletonProps,
  getTitle,
  getImageAndAltText = () => ({}),
  Decoration,
  domainData,
  viewTypeText,
  subtitle,
}) => {
  const theme = useTheme();
  const title = viewTypeText ? `${viewTypeText}: ${getTitle(domainData)}` : getTitle(domainData);
  const { imageUrl, altText } = getImageAndAltText(domainData);
  return (
    <HeaderSection>
      <Flex align="flex-start" justify="space-between" w={'100%'}>
        {domainData?.data?.releases && (
          <Box p="0 16px 0 0">
            <Artwork maxW="4rem" releaseId={domainData?.data?.series?.releases?.[0]?.id} />
          </Box>
        )}
        {Decoration && (
          <Box>
            <Decoration data={domainData} />
          </Box>
        )}
        {imageUrl ? (
          <Box p="0 16px 0 0">
            <Avatar src={imageUrl} name={altText} />
          </Box>
        ) : null}

        <HStack display="block" borderBottom="1px" borderColor={theme.colors.black20} w={'100%'}>
          <Box isTruncated display="inline-block" w="calc(100% - 168px)" mr="0.5rem">
            <SkeletonLoader status={domainData.status} skeletonProps={skeletonProps}>
              <Heading
                as="h1"
                margin={0}
                lineHeight="1"
                isTruncated
                data-cy={global.APP.RUNTIME === 'production' ? undefined : 'analytics-view-heading'}>
                {title}
              </Heading>
              {subtitle && <MastheadSubtitle subtitle={subtitle} data={domainData} />}
            </SkeletonLoader>
          </Box>
          <Box float="right" w="112px">
            <DspDataStatus fontSize="12px" my={2} />
          </Box>
        </HStack>
      </Flex>
    </HeaderSection>
  );
};

export default Masthead;
