import React from 'react';
import { Box, Stack, Td } from '@chakra-ui/react';

export function TrackTitleCell({ rowData, ...props }) {
  const trackTitle = rowData?.track_title;

  return (
    <Td {...props}>
      <Stack display="inline-flex" direction="row" align={'center'}>
        <Box isTruncated maxW={500}>
          {trackTitle}
        </Box>
      </Stack>
    </Td>
  );
}
