import React, { useState } from 'react';
import { Image } from '@chakra-ui/react';
import FallbackCoverArt from 'assets/fallback-cover-art.png';

export default function CoverArt({ file, src, width = '40px', height = '40px', cornerRadius = 1 }) {
  const reader = new FileReader();

  const [coverArtSrc, setCoverArtSrc] = useState(FallbackCoverArt);

  reader.onload = function () {
    setCoverArtSrc(reader.result);
  };

  if (file instanceof Blob) {
    reader.readAsDataURL(file);
  } else if (src) {
    setCoverArtSrc(src);
  }

  return <Image width={width} height={height} src={coverArtSrc} borderRadius={cornerRadius} alt="cover art" />;
}
