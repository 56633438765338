import React from 'react';
import { Box as ChakraBox, useStyleConfig } from '@chakra-ui/react';

export default function Box(props) {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('Box', { variant });

  // Pass the computed styles into the `__css` prop
  return <ChakraBox __css={styles} {...rest} />;
}
