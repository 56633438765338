import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';

const SliderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 2rem;
  width: 100%;
`;

const SliderBg = styled.div`
  background: ${props =>
    props.isDragging
      ? props.theme.colors.background.playerBarBg.selected
      : props.theme.colors.background.playerBarBg.default};
  border-radius: 1rem;
  height: 0.25rem;
  position: relative;
  width: 100%;
`;

const SliderProgressBar = styled.div`
  background: ${props =>
    props.error
      ? props.theme.colors.background.progressBar.error
      : props.percent < 100
        ? props.theme.colors.background.progressBar.uploading
        : props.theme.colors.background.progressBar.complete};
  border-radius: 1rem;
  height: 100%;
  pointer-events: none;
  width: ${props => (props.error ? 100 : props.percent < 0 ? 0 : props.percent > 100 ? 100 : props.percent)}%;
`;

const ProgressBar = React.forwardRef(({ className, percent, error, ...props }, ref) => {
  return (
    <SliderWrapper className={className} {...props}>
      <SliderBg>
        <SliderProgressBar percent={percent} error={error} />
      </SliderBg>
    </SliderWrapper>
  );
});

ProgressBar.displayName = 'ProgressBar';

ProgressBar.defaultProps = {
  error: false,
  percent: 0,
};

export default withTheme(ProgressBar);
