import React, { useState } from 'react';
import { Box, Flex, Text, useDisclosure, IconButton } from '@chakra-ui/react';

import { useMutation } from 'react-query';
import { useUploader } from '../../utils/uploader';
import CardLoadingOverlay from 'components/loading-containers/card-loading-overlay';
import DragAndDropUpload from 'components/DragAndDropUpload/drag-and-drop-upload';
import asModal from '../../features/Modal/as-modal';

import useCurrentUser from '../../data-client/use-current-user';
import { useAddTrackToRelease } from 'data-client/use-releases';

import track from 'utils/API/track';

import { getMediaMetadata } from 'utils/metadata';
import { validateAudio } from 'utils/validate-metadata';
import { RiDeleteBin7Line } from 'react-icons/ri';
import ProgressBar from 'components/ProgressBar/progress-bar';
const { create: createTrack } = track();

function UploadedAudioDragAndDropComponent({
  onAudioFileSelected,
  fileToUpload,
  uploadPercent,
  setFileToUpload,
  uploadStatusText,
}) {
  const fileName = fileToUpload?.name;

  return (
    <Flex alignItems={'flex-start'}>
      <Box width="220px" height="125px">
        <DragAndDropUpload
          allowedExts={['wav', 'mp3']}
          onChange={onAudioFileSelected}
          name={'audio-source'}
          currentFile={fileToUpload}
          maxNumberOfFiles={1}
          confirmModal={{
            bodyText: 'Replacing the file will overwrite all existing clips. Are you sure you want to proceed?',
            headerText: 'Reset all clips?',
          }}
        />
      </Box>
      <Flex flexDirection="column" width="70%">
        <Flex
          border={'1px solid black'}
          marginX="2rem"
          borderRadius="1rem"
          height="80px"
          alignItems={'center'}
          justifyContent={'center'}>
          <Flex flexDirection={'column'} w="15rem">
            <Flex justifyContent={'space-between'}>
              <Text>{`${fileName.substr(0, 18)}... ${fileName.substr(fileName.length - 4)}`}</Text>
              <Text>{uploadPercent !== null && uploadPercent !== 100 ? `${uploadPercent}%` : ''}</Text>
            </Flex>
            <ProgressBar
              percent={uploadPercent}
              style={{ display: uploadPercent !== null && uploadPercent !== 100 ? 'flex' : 'none' }}
            />
          </Flex>
          <IconButton
            icon={<RiDeleteBin7Line size={'1.5rem'} />}
            variant={'transparent'}
            onClick={() => {
              setFileToUpload(undefined);
            }}
          />
        </Flex>
        <Text margin="0.15rem 0 0 2rem">{uploadStatusText}</Text>
      </Flex>
    </Flex>
  );
}

//TODO I don't think this uploader should be concerned with track creation or even the upload process. Instead it should just be a dummy component that looks a certain way. -PAUL Greco (Notes for later for when we have time to change)
export default function AudioFileUploader({
  audio_source,
  onUploaded,
  track,
  asset_release_id,
  onBuildTrackCreationProperties = () => ({}),
}) {
  const { uploadFile, uploadStatus, uploadPercent, uploadStatusText } = useUploader();
  const [fileToUpload, setFileToUpload] = useState(audio_source);
  const [localTrack, setLocalTrack] = useState(track?.asset_track_id ? track : null);
  const [isReadingMetaData, setIsReadingMetaData] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(null);

  const [user] = useCurrentUser();

  const { mutate: addTrackToRelease, isLoading: isAddingTrackToRelease } = useAddTrackToRelease();

  const { mutateAsync: doCreateTrack } = useMutation({
    mutationFn: () => createTrack({ user_group_id: user?.currentGroup?.id, ...onBuildTrackCreationProperties() }),
    mutationKey: `create-track-${user?.currentGroup?.id}-${fileToUpload?.name}`,
    onSuccess: async data => {
      //Don't add the track to a release if we have no release id. This is done
      //for the single "source" track for clips since we do not want it to appear
      //on the set for the release. Instead we want the clips to become the tracks.
      if (asset_release_id) {
        await addTrackToRelease({ asset_release_id, asset_track_id: data.asset_track_id });
      }
      setLocalTrack(data);
    },
    onError: error => {
      console.error(error);
      throw error;
    },
  });

  const { mutate: doUploadAudio } = useMutation({
    mutationFn: ({ file, metadata }) => uploadAudio(file, localTrack?.asset_track_id, metadata),
    mutationKey: `upload-audio-${localTrack?.asset_track_id}-${fileToUpload?.name}`,
    onError: error => {
      console.error(error);
    },
  });

  //x-wav seems to be returned as the type for wav files in safari
  const acceptedFileTypeSet = new Set(['audio/mpeg', 'audio/wav', 'audio/x-wav']);

  async function onAudioFileSelected(files) {
    const file = files?.[0];
    if (!file) return;

    if (acceptedFileTypeSet.has(file.type) === false) {
      invalidAudioSourceModal.onOpen();
      return 'invalid';
    }

    const metadata = await getMediaMetadata(file, {
      onStart: () => setIsReadingMetaData(true),
      onEnd: () => setIsReadingMetaData(false),
    });

    const durationInSeconds = metadata.general?.Duration ? Number.parseFloat(metadata.general.Duration) : 10000.0; //10000.0 seconds is a default value

    if (durationInSeconds < 60) {
      setInvalidMessage('Tracks must be at least 60 seconds in duration to be accepted by streaming services');
      invalidAudioSourceModal.onOpen();
      return 'invalid';
    }

    const validateAsAdmin = user?.system_admin;
    const validationResult = validateAudio({ metadata, file, validateAsAdmin });

    if (!validationResult.isFileValid) {
      setInvalidMessage(validationResult.messages);
      invalidAudioSourceModal.onOpen();
      return 'invalid';
    }

    if (!localTrack) {
      doCreateTrack().then(() => {
        doUploadAudio({ file, metadata });
      });
    } else {
      doUploadAudio({ file, metadata });
    }

    setFileToUpload(file);
  }

  async function uploadAudio(file, trackId, metadata) {
    const uploadPrimarySuccess = await uploadFile('audio_source', file, trackId, file.name, metadata);
    onUploaded({ file, track });
  }

  const invalidAudioSourceModal = useDisclosure();

  const InvalidAudioSourceModal = asModal(function InvalidAudioSource() {
    return <Text margin={'1rem 0'}>{invalidMessage}</Text>;
  });

  return (
    <>
      {fileToUpload === undefined ? (
        <DragAndDropUpload
          allowedExts={['wav', 'mp3']}
          onChange={onAudioFileSelected}
          name={'audio-source'}
          currentFile={fileToUpload}
          maxNumberOfFiles={1}
          confirmModal={{
            bodyText: 'Replacing the file will overwrite all existing clips. Are you sure you want to proceed?',
            headerText: 'Reset all clips?',
          }}
        />
      ) : (
        <UploadedAudioDragAndDropComponent
          onAudioFileSelected={onAudioFileSelected}
          fileToUpload={fileToUpload}
          uploadPercent={uploadPercent}
          setFileToUpload={setFileToUpload}
          uploadStatusText={uploadStatusText}
        />
      )}
      <InvalidAudioSourceModal
        variant="light"
        hideCancel
        headerText={'Invalid file type'}
        isOpen={invalidAudioSourceModal.isOpen}
        onClose={invalidAudioSourceModal.onClose}
        onSubmit={invalidAudioSourceModal.onClose}
        submitText="OK"
      />
      {isAddingTrackToRelease ||
      isReadingMetaData ||
      (uploadStatus?.audio_source && Object.values(uploadStatus?.audio_source).find(e => e.inProgress)) ? (
        <CardLoadingOverlay
          percent={uploadPercent}
          text={uploadStatusText}
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        />
      ) : (
        ''
      )}
    </>
  );
}
