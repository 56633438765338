import React from 'react';
import styled from '@emotion/styled/macro';

const List = styled.ul`
  list-style-type: none;
  display: flex;
`;

const StyledItem = styled.li`
  list-style-type: none;
  padding-right: ${props => props.theme.space[4]};
  display: flex;
`;

export default function HorizontalList({ Items = [] }) {
  return (
    <List>
      {Items.map(Item => (
        <StyledItem key={Item.key}>{Item}</StyledItem>
      ))}
    </List>
  );
}
