import { useQueries } from 'react-query';
import * as API from '../utils/API/API';

const Group = API.group();
const Split = API.split();
const Party = API.party();
const Track = API.track();

const STALE_TIME = 1000 * 60 * 60;
const CACHE_TIME = 1000 * 60 * 60;

export const useTrackSplitsQueries = trackData => {
  const keys = {
    payees: ['payees', trackData.user_group_id],
    contributors: ['contributors', trackData.user_group_id],
    currentGroup: [`currentGroup`, trackData.user_group_id],
    trackSplits: ['track-splits', trackData.asset_track_id],
    trackData: ['trackData', trackData.asset_track_id],
  };

  const configs = [
    {
      queryKey: keys.payees,
      queryFn: () => Group.getPayees(trackData.user_group_id),
    },
    {
      queryKey: keys.contributors,
      queryFn: () =>
        Party.list('contributor', {
          user_group_id: trackData.user_group_id,
          only_group_parties_if_admin: true,
        }),
    },
    {
      queryKey: keys.currentGroup,
      queryFn: () => Group.get(trackData.user_group_id),
    },
    {
      queryKey: keys.trackSplits,
      queryFn: () => Split.byIsrc(trackData.isrc_code),
      retry: 3,
      placeholderData: [],
    },
    {
      queryKey: keys.trackData,
      queryFn: () => Track.get(trackData.asset_track_id),
      cacheTime: 10 * 60 * 1000,
      placeholderData: trackData,
    },
  ];
  return {
    configs,
    keys,
  };
};

const useTrackSplits = ({ trackData, options = {} }) => {
  const { configs } = useTrackSplitsQueries(trackData);
  return useQueries(
    configs.map(q => {
      const opts = options[q.queryKey] || {};
      return {
        ...q,
        ...opts,
        STALE_TIME,
        CACHE_TIME,
      };
    })
  );
};

export default useTrackSplits;
