import React, { useState } from 'react';
import { Link, Box, Heading, Text, Button, useTheme, useDisclosure } from '@chakra-ui/react';
import { AiFillCheckCircle as CheckCircle } from 'react-icons/ai';
import { FiArrowLeft as ArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import asModal from '../../../../features/Modal/as-modal';
import { useQuery } from 'react-query';
import party from '../../../../utils/API/party';
import { useUserTierChecks } from 'hooks/authorization-hooks';

//Sections
import ReviewAndSubmitSetup from './clip-review-and-submit-setup';
import ReviewAndSubmitDistribution from './clip-review-and-submit-distribution';
import ReviewAndSubmitCreateClips from './clip-review-and-submit-create-clips';
import ReviewAndSubmitReleaseInfo from './clip-review-and-submit-release-info';
import ReviewAndSubmitTrackDetails from './clip-review-and-submit-track-details';

const Party = party();

const SubmitModal = asModal(function SubmitConfirmation({ submitForDistribution, onClose }) {
  const theme = useTheme();
  const [disableButtons, setDisableButtons] = useState(false);

  return (
    <Box>
      <Heading as="h3" fontSize={'1rem'} fontWeight={'bold'}>
        Confirm before submission
      </Heading>
      <Box fontSize="0.75rem" color={theme.colors.black90} as={'ul'} ml="1rem" mt="1rem">
        <Text as={'li'} mt="1rem">
          I understand that my release may be rejected from stores if I do not correctly label tracks as explicit that
          contain swear words.
        </Text>
        <Text mt="1rem" as={'li'}>
          I am authorized to distribute this music to stores and territories that I selected.
        </Text>
        <Text mt="1rem" as={'li'}>
          I am not using any other artist's name in my name, song titles, or album titles without their approval.
        </Text>
      </Box>
      <Box mt="1rem" display={'flex'} justifyContent={'flex-end'}>
        <Box mr="1rem">
          <Button color={theme.colors.black90} variant={'transparent'} onClick={onClose} isDisabled={disableButtons}>
            No, Exit
          </Button>
        </Box>
        <Button
          border={'none'}
          fontSize={'0.75rem'}
          color={theme.colors.white}
          background={theme.colors.pink}
          isDisabled={disableButtons}
          loading={disableButtons}
          onClick={async () => {
            try {
              setDisableButtons(true);
              await submitForDistribution();
            } finally {
              setDisableButtons(false);
            }
          }}>
          Yes, Submit
        </Button>
      </Box>
    </Box>
  );
});

function ClipReviewAndSubmit({ reviewStateCollection, submitForDistribution, stateManager, releaseGroupId }) {
  const theme = useTheme();
  const submitModal = useDisclosure();

  const history = useHistory();
  const { isInTrial } = useUserTierChecks();

  const artistsQuery = useQuery(
    ['artists', releaseGroupId],
    () => Party.list('artist', { user_group_id: releaseGroupId }),
    {
      enabled: releaseGroupId !== undefined && releaseGroupId !== null,
      select: results => results?.data.map(c => ({ full_name: c.full_name, id: c.id })) || [],
    }
  );

  //get contributors options
  const contributorsQuery = useQuery(
    ['contributors', releaseGroupId],
    () => Party.list('contributor', { user_group_id: releaseGroupId }),
    {
      enabled: releaseGroupId !== undefined && releaseGroupId !== null,
      select: results => results?.data.map(c => ({ full_name: c.full_name, id: c.id })) || [],
    }
  );

  const {
    ClipSetup: setupManager,
    ClipTrackDetails: trackDetailsManager,
    ClipCreate: createClipManager,
    ClipDistribution: distributionManager,
    ClipReleaseInfo: releaseInfoManager,
  } = reviewStateCollection;

  const { stateData } = stateManager;

  return (
    <>
      <Box width={'100%'} display={'flex'} justifyContent={'center'}>
        <Box maxWidth={'37.5rem'}>
          <Button variant="transparent" leftIcon={<ArrowLeft />} onClick={() => history.goBack()}>
            <Text as="span">Back</Text>
          </Button>
          <Heading as="h2" fontSize={'1.5rem'} mb={'1rem'}>
            Review and Submit
          </Heading>
          <Box
            mb={'1.5rem'}
            display={'flex'}
            justifyContent={'flex-start'}
            fontSize={'0.75rem'}
            fontWeight={'bold'}
            color={'green'}>
            <CheckCircle size={'1.25rem'} />
            <Text pl="0.5rem">
              You're good to go. Click submit or scroll below to review all sections before submitting your clips to
              platforms.
            </Text>
          </Box>

          {isInTrial && (
            <Text
              color="white"
              width="100%"
              backgroundColor="#da7e3c"
              borderRadius="0.3rem"
              p="1rem"
              mt="1rem"
              mb="0.3rem">
              <>
                During your trial, you may create releases but you will need to upgrade to a full membership to
                distribute them.{' '}
                <Link href="/settings#membership" textDecoration="underline">
                  Upgrade Now
                </Link>
              </>
            </Text>
          )}

          <ReviewAndSubmitSetup stateManager={setupManager} stateData={stateData} />

          {/* Release Information */}
          <ReviewAndSubmitReleaseInfo
            stateManager={releaseInfoManager}
            stateData={stateData}
            artistsQuery={artistsQuery}
            contributorsQuery={contributorsQuery}
          />
          {/* Distribution */}
          <ReviewAndSubmitDistribution stateManager={distributionManager} stateData={stateData} />

          {/* Track details */}
          <ReviewAndSubmitTrackDetails
            stateManager={trackDetailsManager}
            stateData={stateData}
            artistsQuery={artistsQuery}
            contributorsQuery={contributorsQuery}
          />

          {/* Create Clips */}
          <ReviewAndSubmitCreateClips stateManager={createClipManager} stateData={stateData} />
        </Box>
      </Box>
      <Box height={'4.5rem'}></Box>

      <Box
        backgroundColor={theme.colors.black90}
        display={'flex'}
        justifyContent={'center'}
        width="100%"
        position={'fixed'}
        bottom={0}
        left={0}
        py={'1rem'}>
        <Box display={'flex'} justifyContent={'space-between'} width="100%" maxWidth={'44rem'}>
          <Heading as="h2" color={theme.colors.white}>
            Create Clips
          </Heading>
          <Button
            lineHeight={'0.75rem'}
            px={'3rem'}
            background={theme.colors.pink}
            color={theme.colors.white}
            disabled={isInTrial}
            onClick={submitModal.onOpen}>
            Submit
          </Button>
        </Box>
      </Box>
      <SubmitModal
        variant="light"
        noFooter={true}
        hideClose={true}
        isOpen={submitModal.isOpen}
        onClose={submitModal.onClose}
        submitForDistribution={submitForDistribution}
      />
    </>
  );
}

export default ClipReviewAndSubmit;
