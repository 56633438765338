import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import numeral from 'numeral';
import Icon from 'components/Icon/icon';
import { buildStatus } from 'utils/helpers';
import { DateTime } from 'luxon';

import { disableSelection } from '../styles';

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  color: ${props => (props.uiTheme === 'dark' ? props.theme.colors.white : props.theme.colors.black100)};

  ${props =>
    props.disabled &&
    `
		opacity: 0.5;
	`}
`;

const MonthHeader = styled.div`
  ${disableSelection}
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const CurrentMonthName = styled.div``;

const DaysWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Week = styled.div`
  display: flex;
`;

const WeekHeader = styled(Week)`
  border-top: 1px solid ${props => (props.uiTheme === 'dark' ? props.theme.colors.white : props.theme.colors.black100)};
  padding-top: 0.25rem;
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin-bottom: 0.25rem;
`;

const ToggleMonthIcon = styled(Icon)`
  cursor: pointer;
`;

const LeftIcon = styled(ToggleMonthIcon)`
  margin-left: 0.25rem;

  ${props =>
    props.disabled &&
    `
		opacity: 0;
		pointer-events: none;
	`}
`;

const RightIcon = styled(ToggleMonthIcon)`
  margin-right: 0.25rem;

  ${props =>
    props.disabled &&
    `
		opacity: 0;
		pointer-events: none;
	`}
`;

const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  color: ${props => (props.uiTheme === 'dark' ? props.theme.colors.white : props.theme.colors.black100)};
  font-weight: ${props => props.theme.fonts.weights.medium};
`;

const Day = styled(Cell)`
  user-select: none;
  border-radius: 1rem;
  font-size: ${props => props.theme.fonts.sizes.sm};
  ${props =>
    !props.status.inProgress &&
    `
		transition:
			background 250ms ease,
			border 250ms ease,
			color 250ms ease,
			width 250ms ease,
			margin 250ms ease,
			height 250ms ease;
	`}

  ${props =>
    props.isPhantom &&
    `
		visibility: hidden;
	`}

${props =>
    !props.isPhantom &&
    !props.isDisabled &&
    `
		cursor: pointer;
	`}

${props =>
    props.isDisabled &&
    `
		color: ${props.theme.colors.gray};
    cursor: not-allowed;
	`}

	${props =>
    !props.status.inProgress &&
    props.isSelected &&
    !props.isPhantom &&
    `
		background-color: ${props.theme.colors.brand.accent};
		border-radius: 1rem;
	`}

	${props =>
    !props.status.inProgress &&
    props.isToday &&
    `
		border: 1px solid ${props.theme.colors.gray};
		width: 1.5rem;
		height: 1.5rem;
		margin: 0rem;
		box-sizing: border-box;
	`}

	&:hover {
    ${props =>
    !props.isPhantom &&
      !props.isDisabled &&
      `
			background-color: ${props.theme.colors.brand.accent}${!props.isSelected ? 50 : 90};
		`}
  }
`;

const ClickShield = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const HiddenInput = styled.input`
  display: none;
`;

const DayName = styled(Cell)``;

const getFirstDay = (year, month) => new Date(year, month - 1).getDay();

const getDaysInMonth = (year, month) => 32 - new Date(year, month - 1, 32).getDate();

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getCalendarDays = (year, month) => {
  const rows = [];
  const daysInMonth = getDaysInMonth(year, month);
  const firstDay = getFirstDay(year, month);
  let dateNumber = 1;
  let postDateNumber = 1;
  let preDateNumber = getDaysInMonth(month === 1 ? year - 1 : year, month === 1 ? 12 : month - 1) - firstDay + 1;

  for (let i = 0; i < 6; i++) {
    let row = [];
    for (let j = 0; j < 7; j++) {
      if (i === 0 && j < firstDay) {
        row.push(`-${preDateNumber}`);
        preDateNumber++;
      } else if (dateNumber > daysInMonth) {
        row.push(`+${postDateNumber}`);
        postDateNumber++;
      } else {
        row.push(`${dateNumber}`);
        dateNumber++;
      }
    }
    rows.push(row);
  }
  return rows;
};

const Calendar = React.forwardRef(
  (
    {
      className,
      name,
      setValue,
      clearErrors,
      disabled,
      defaultSelectedDate,
      onChange,
      minDate,
      maxDate,
      uiTheme = 'dark',
    },
    ref
  ) => {
    const todayDate = DateTime.local().toISODate();

    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(7);

    const [calendarDays, setCalendarDays] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');

    const [status, setStatus] = useState(buildStatus(null));

    useEffect(() => {
      if (!defaultSelectedDate) {
        const higherOfTodayAndMinDate = minDate && minDate > todayDate ? minDate : todayDate;
        setYear(parseInt(higherOfTodayAndMinDate.split('-')[0]));
        setMonth(parseInt(higherOfTodayAndMinDate.split('-')[1]));
        return;
      }
      setSelectedDate(defaultSelectedDate);
      setYear(parseInt(defaultSelectedDate.split('-')[0]));
      setMonth(parseInt(defaultSelectedDate.split('-')[1]));
      setValue(name, defaultSelectedDate);
      // DEBT:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultSelectedDate]);

    useEffect(() => {
      setStatus({ inProgress: true, complete: false, error: null });
      const newCalendarDays = getCalendarDays(year, month);
      setCalendarDays(newCalendarDays);
      setStatus({ inProgress: false, complete: true, error: null });
      // DEBT:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, month]);

    const handleGoToPreviousMonth = () => {
      setStatus({ inProgress: true, complete: false, error: null });
      if (month === 1) {
        setMonth(12);
        setYear(year - 1);
      } else {
        setMonth(month - 1);
      }
    };

    const handleGoToNextMonth = () => {
      setStatus({ inProgress: true, complete: false, error: null });
      if (month === 12) {
        setMonth(1);
        setYear(year + 1);
      } else {
        setMonth(month + 1);
      }
    };

    const handleSelectDate = day => {
      const newSelectedDate = `${year}-${numeral(month).format('00')}-${numeral(day).format('00')}`;
      setSelectedDate(newSelectedDate);
      setValue(name, newSelectedDate, { shouldDirty: true });
      clearErrors(name);
      if (onChange) {
        onChange(newSelectedDate);
      }
    };

    const [selectedYear, selectedMonth, selectedDay] = selectedDate.split('-');
    const [todayYear, todayMonth, todayDay] = todayDate.split('-');

    const thisMonthDate = `${numeral(year).format('00')}-${numeral(month).format('00')}-01`;
    const nextMonthDate = `${numeral(year).format('00')}-${numeral(month + 1).format('00')}-01`;

    return (
      <Wrapper className={className} disabled={disabled} uiTheme={uiTheme}>
        <MonthHeader uiTheme={uiTheme}>
          <LeftIcon type={'chevronLeft'} disabled={thisMonthDate < minDate} onClick={handleGoToPreviousMonth} />
          <CurrentMonthName>
            {monthNames[month - 1]} {year}
          </CurrentMonthName>
          <RightIcon type={'chevronRight'} disabled={nextMonthDate >= maxDate} onClick={handleGoToNextMonth} />
        </MonthHeader>
        <DaysWrapper>
          <WeekHeader uiTheme={uiTheme}>
            {['Sun', 'M', 'T', 'W', 'T', 'F', 'Sat'].map((dayName, i) => (
              <DayName key={`${name}-dayName-${dayName}-${i}`} uiTheme={uiTheme}>
                {dayName}
              </DayName>
            ))}
          </WeekHeader>
          {calendarDays &&
            calendarDays.map((week, i) => (
              <Week key={`${name}-week-${i}`}>
                {week.map(day => {
                  const isSelected =
                    selectedYear === `${year}` &&
                    selectedMonth === numeral(month).format('00') &&
                    selectedDay === numeral(day).format('00');
                  const isToday =
                    todayYear === `${year}` &&
                    todayMonth === numeral(month).format('00') &&
                    todayDay === numeral(day).format('00');
                  const isPhantom = ['+', '-'].some(el => day.includes(el));
                  const date =
                    !isPhantom &&
                    `${numeral(year).format('00')}-${numeral(month).format('00')}-${numeral(day).format('00')}`;
                  let isDisabled = false;

                  if (minDate && date < minDate) {
                    isDisabled = true;
                    // setShowLeftArrow(false)
                  }
                  if (maxDate && date > maxDate) {
                    isDisabled = true;
                    // setShowRightArrow(false)
                  }

                  return (
                    <Day
                      key={`${name}-${day}`}
                      onClick={isDisabled || isPhantom ? null : () => handleSelectDate(day)}
                      isSelected={isSelected}
                      isPhantom={isPhantom}
                      isDisabled={isDisabled}
                      isToday={isToday}
                      status={status}
                      uiTheme={uiTheme}>
                      {day.replace(/\+|-/g, '')}
                    </Day>
                  );
                })}
              </Week>
            ))}
        </DaysWrapper>
        <HiddenInput disabled={disabled} name={name} ref={ref} type="date" />
        {disabled && <ClickShield />}
      </Wrapper>
    );
  }
);

Calendar.defaultProps = {
  clearErrors: () => {},
};

Calendar.displayName = 'Calendar';

export default withTheme(Calendar);
