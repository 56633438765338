import React from 'react';
import {
  Grid,
  GridItem,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
  Text,
  Tooltip,
  Image,
  Box,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { FiArrowRight } from 'react-icons/fi';
import { useReleaseArtwork } from 'hooks/use-release-artwork';
import FallbackCoverArt from 'assets/fallback-cover-art.png';
import { RiMailFill, RiLineChartFill } from 'react-icons/ri';
import {
  useGetTopCities,
  useGetTopCountries,
  useGetTopDsps,
  useGetGeneralAnalytics,
  useUpdateMarketingLink,
} from 'data-client/use-marketing-links';
import MarketingAnalyticsDropdown from './marketing-links-analytics-dropdown';
import { CSVLink } from 'react-csv';

import URLS from 'constants/URLS.gen.json';

// based on RiMailUnreadFill
const NewEmailsAvailableIcon = ({ style }) => (
  <Icon viewBox="0 0 24 24" {...style}>
    <path d="M18.8032 8.4928C19.4663 8.81764 20.2118 9 21 9C21.3425 9 21.6769 8.96557 22 8.89998V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H16.1C16.0344 3.32311 16 3.65753 16 4C16 5.23672 16.449 6.36857 17.1929 7.24142L12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L18.8032 8.4928Z" />
    <path
      d="M21 7C19.3431 7 18 5.65685 18 4C18 2.34315 19.3431 1 21 1C22.6569 1 24 2.34315 24 4C24 5.65685 22.6569 7 21 7Z"
      style={{ fill: `rgb(198, 50, 49)` }}
    />
  </Icon>
);
const MEMBERSHIP_URL = URLS.MEMBERSHIP[process.env.REACT_APP_RUNTIME];

function AnalyticsUpgradeModal({ display }) {
  const upgradePathStyles = {
    backgroundColor: 'black10',
    borderRadius: '.5rem',
    display: `${display}`,
    width: '30rem',
    flexWrap: 'wrap',
    padding: '1.5rem',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: '5',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -100%)',
  };

  return (
    <Box {...upgradePathStyles}>
      <Text marginBottom={'1.5rem'} fontWeight="500" color="black100" fontSize="1rem">
        Want to see how your marketing links are performing?
      </Text>
      <Button variant="dark" fontSize=".875rem" onClick={() => (location.href = `/settings#membership`)}>
        Upgrade Now
        <Icon as={FiArrowRight} boxSize={5} marginLeft={'1rem'} />
      </Button>
    </Box>
  );
}

function createArtistName(track) {
  return track?.trackParties
    .reduce((names, tp) => {
      //Do I need more than the main artist?
      if (tp.roles.includes('MainArtist')) return [tp.party.full_name, ...names];
      if (tp.roles.includes('FeaturedArtist')) return [...names, tp.party.full_name];
      return names;
    }, [])
    .join(', ');
}

export default function MarketingLinksTableRow({
  linkData,
  handleCopyToClipboard,
  handleDeleteClick,
  gridConfig,
  showAnalytics = false,
  refetchMarketingLinks,
  canAccessAnalytics = false,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const handleOnClickDrawerToggle = () => setIsDrawerOpen(prev => !prev);

  const id = linkData.id;
  const queryOptions = { enabled: canAccessAnalytics && isDrawerOpen };

  const cityAnalyticsQuery = useGetTopCities(id, queryOptions);
  const countryAnalyticsQuery = useGetTopCountries(id, queryOptions);
  const dspAnalyticsQuery = useGetTopDsps(id, queryOptions);
  const generalAnalyticsQuery = useGetGeneralAnalytics(id, queryOptions);

  const track = linkData.trackMarketingLink?.track;
  const release = track ? linkData.trackMarketingLink?.release : linkData.releaseMarketingLink?.release;
  const artistName = track ? createArtistName(track) : release?.display_artist_name;

  const imgUrl = useReleaseArtwork(release?.id, '50x50');

  const fanEmailCount = linkData.fanEmails.length;
  const fanEmailRead = linkData.countSinceLastDownload > 0;

  const gridItemConfig = {
    display: 'flex',
    alignItems: 'center',
    color: '#000',
  };

  const coverArtCell = (
    <GridItem {...gridItemConfig}>
      <Image src={imgUrl || FallbackCoverArt} alt="cover art" width="50" height="50" borderRadius="0.375rem" />
    </GridItem>
  );

  const releaseTitleCell = (
    <GridItem {...gridItemConfig}>
      <Text>{shorten(release?.title)}</Text>
    </GridItem>
  );

  const trackTitleCell = <GridItem {...gridItemConfig}>{shorten(track?.title || '-')}</GridItem>;

  const artistNameCell = <GridItem {...gridItemConfig}>{shorten(artistName)}</GridItem>;

  const { mutate: updateMarketingLinkMutation } = useUpdateMarketingLink();

  const isAtleastOneFanEmail = linkData?.fanEmails?.length > 0;

  const onClickCsvDownload = async () => {
    await updateMarketingLinkMutation(
      {
        id: linkData.id,
        body: { last_fan_email_count: linkData?.fanEmails?.length },
      },
      {
        onSuccess: () => {
          refetchMarketingLinks();
        },
      }
    );
    if (!isAtleastOneFanEmail) {
      // returning false prevents the CSV from downloading
      return false;
    }
  };

  // mm/dd/yy
  const todaysDate = new Date().toLocaleString().split(',')[0].replaceAll('/', '-');

  const fanEmailsCell = (
    <GridItem {...gridItemConfig} marginLeft="1.25rem">
      <Tooltip isDisabled={!isAtleastOneFanEmail} label="Click to download a CSV file with emails." shouldWrapChildren>
        {fanEmailCount > 0 ? (
          <CSVLink
            data={linkData.fanEmails}
            style={{ display: 'flex', alignItems: 'center', color: '#000' }}
            headers={[{ label: 'Email', key: 'email' }]}
            filename={`${todaysDate}-${linkData.shortcode}-fan-emails.csv`}
            onClick={onClickCsvDownload}>
            {fanEmailRead ? (
              <NewEmailsAvailableIcon style={{ fontSize: '1.125rem', marginRight: '0.125rem' }} />
            ) : (
              <RiMailFill style={{ fontSize: '1.125rem', marginRight: '0.125rem' }} />
            )}
            {fanEmailCount > 999 ? '999+' : fanEmailCount}
          </CSVLink>
        ) : (
          '--'
        )}
      </Tooltip>
    </GridItem>
  );

  const toggleAnalyticsCell = (
    <GridItem {...gridItemConfig} marginLeft="0.5rem">
      {isDrawerOpen ? (
        <Text onClick={handleOnClickDrawerToggle} cursor="pointer" fontWeight="bold">
          Close X
        </Text>
      ) : (
        <IconButton onClick={handleOnClickDrawerToggle} variant="ghost" border="none" icon={<RiLineChartFill />} />
      )}
    </GridItem>
  );

  const overflowMenuCell = (
    <GridItem {...gridItemConfig} display={isDrawerOpen ? 'none' : 'flex'}>
      <Menu>
        <MenuButton as={Button} variant="smallLighter" size="sm" width="fit-content" alignSelf="center">
          <HiOutlineDotsHorizontal />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => handleCopyToClipboard(linkData.linkfire_url)}>Copy Link</MenuItem>
          <MenuItem onClick={() => handleDeleteClick(linkData.id)}>Delete Link</MenuItem>
        </MenuList>
      </Menu>
    </GridItem>
  );

  return (
    <>
      <Grid {...gridConfig} borderBottom={isDrawerOpen ? 'none' : '1px solid #ddd'} padding="1rem 0" minWidth="55rem">
        {coverArtCell}
        {releaseTitleCell}
        {trackTitleCell}
        {artistNameCell}
        {fanEmailsCell}
        {toggleAnalyticsCell}
        {overflowMenuCell}
      </Grid>
      <Flex position={'relative'}>
        <AnalyticsUpgradeModal display={isDrawerOpen && !canAccessAnalytics ? 'flex' : 'none'} />
        <MarketingAnalyticsDropdown
          canAccessAnalytics={canAccessAnalytics}
          generalQuery={generalAnalyticsQuery}
          dspQuery={dspAnalyticsQuery}
          countryQuery={countryAnalyticsQuery}
          cityQuery={cityAnalyticsQuery}
          isOpen={isDrawerOpen}
        />
      </Flex>
    </>
  );
}

function shorten(string) {
  if (string?.length < 37) return string;
  return string?.substring(0, 37) + '...';
}
