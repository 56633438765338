import * as React from 'react';
import {
  Box,
  Text,
  IconButton,
  Grid,
  Portal,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  useTheme,
  Icon as ChakraIcon,
} from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { RiErrorWarningLine, RiArrowDownSLine } from 'react-icons/ri';
import { DateTime } from 'luxon';

import Icon from 'components/Icon/icon';
import { useAnalyticsContext } from './analytics-context';

export default function DspDataStatus({ ...props }) {
  const {
    dsps: { dspMetadata },
  } = useAnalyticsContext();

  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  if (isEmpty(dspMetadata)) return null;

  let hasMissingData = [];
  let hasUpToDateData = [];
  // the rule for missing data is >=3 days old, ie today and yesterday with missing data are still not marked as stale
  Object.entries(dspMetadata).forEach(([key, value]) => {
    // in plain english: if 2 days ago is BEFORE the last piece of data we have from this DSP, data is missing
    if (!value.lastUpdated || DateTime.now().startOf('day').minus({ days: 2 }) > DateTime.fromISO(value?.lastUpdated)) {
      hasMissingData.push({ key, value });
    } else {
      hasUpToDateData.push({ key, value });
    }
  });

  return (
    <Box
      sx={{
        color: 'black.100',
        display: 'grid',
        gridColumnGap: '2',
        alignItems: 'center',
        gridTemplateAreas: `
          "icon status"
        `,
        gridTemplateColumns: 'auto 1fr',
      }}
      {...props}>
      <Box display="flex" alignItems="baseline">
        {hasMissingData.length === 0 ? (
          <Icon type="circleCheck" color={theme.colors.green100} />
        ) : (
          <RiErrorWarningLine size="1.5em" />
        )}
      </Box>
      <Box>
        <Text>
          Data status{' '}
          <Popover isOpen={isOpen} onClose={close}>
            <PopoverTrigger>
              <IconButton
                size="lg"
                icon={<RiArrowDownSLine />}
                border="none"
                minW="inherit"
                w="1.25rem"
                h="1.25rem"
                onClick={open}
                transform={isOpen ? 'rotate(180deg)' : ''}
              />
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                ml={5}
                borderColor="black.20"
                borderRadius="3px"
                borderWidth="1px"
                borderStyle="solid"
                color="black.100">
                <PopoverBody backgroundColor="white">
                  {hasMissingData.length > 0 && (
                    <Box mb={3}>
                      <Text fontWeight="medium" textTransform="uppercase" letterSpacing="1px" mb={1}>
                        Missing data
                      </Text>
                      {hasMissingData.map(dsp => (
                        <Grid key={dsp.key} gap={2} templateColumns="auto 1fr 1fr" alignItems="center">
                          <ChakraIcon w="1.5em" h="1.5em" as={RiErrorWarningLine} color="orange.300" />
                          <Text>{dsp.key}</Text>
                          <Text justifySelf="flex-end" color="orange.300">
                            Updated{' '}
                            {dsp.value?.lastUpdated
                              ? DateTime.fromISO(dsp.value?.lastUpdated).toFormat('LL/dd')
                              : '--/--'}
                          </Text>
                        </Grid>
                      ))}
                    </Box>
                  )}

                  {hasUpToDateData.length > 0 && (
                    <Box>
                      <Text fontWeight="medium" textTransform="uppercase" letterSpacing="1px" mb={1}>
                        Data up-to-date
                      </Text>
                      {hasUpToDateData.map(dsp => (
                        <Grid key={dsp.key} gap={2} templateColumns="auto 1fr 1fr" alignItems="center">
                          <Icon type="circleCheck" color={theme.colors.green100} />
                          <Text>{dsp.key}</Text>
                          <Text justifySelf="flex-end" color="black.60">
                            Updated {DateTime.fromISO(dsp.value?.lastUpdated).toFormat('LL/dd')}
                          </Text>
                        </Grid>
                      ))}
                    </Box>
                  )}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Text>
      </Box>
    </Box>
  );
}
