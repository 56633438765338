//TECH DEBT: Chakrafy this MOFO
import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import Icon from 'components/Icon/icon';
import { spinAnimation } from 'styles';
import ProgressBar from 'components/ProgressBar/progress-bar';
import { Flex, Text, Box } from '@chakra-ui/react';
import { Duration } from 'luxon';

const Overlay = styled.div`
  align-items: center;
  display: flex;
  ${props => props.vertical && 'flex-direction: column;'}
  height: 100vh;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
`;

const IconSpinner = styled(Icon)`
  ${spinAnimation};
  width: 2rem;
  height: 2rem;
`;

const CardLoadingOverlay = ({ text, percent = undefined, secondsRemaining = 0, error = false, ...props }) => {
  if (typeof percent === 'number') {
    const duration = Duration.fromMillis(secondsRemaining * 1000).toISOTime({ suppressMilliseconds: true });
    return (
      <Overlay vertical={true} {...props}>
        <Box width="20rem">
          <Flex align="center">
            {text && (
              <h2>
                {text} {percent}%
              </h2>
            )}
          </Flex>
          <ProgressBar percent={percent} error={error}></ProgressBar>
          {!!secondsRemaining && (
            <Flex align="right">{secondsRemaining && <Text>Estimated {duration} remaining</Text>}</Flex>
          )}
        </Box>
      </Overlay>
    );
  } else {
    return (
      <Overlay {...props}>
        {text && <h2>{text}</h2>}
        <IconSpinner type="loader" />
      </Overlay>
    );
  }
};

export default withTheme(CardLoadingOverlay);
