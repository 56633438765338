import { dataApiUrl } from './url';
import axios from 'axios';
import { getTokenSilently } from './auth0';
import jwt_decode from 'jwt-decode';

const DataApiUrl = dataApiUrl();
const Axios = axios.create({
  baseURL: DataApiUrl,
});

export const NoAuthAxios = axios.create({
  baseURL: DataApiUrl,
});

Axios.defaults.headers.common = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

Axios.interceptors.request.use(
  async config => {
    let token = localStorage.getItem('scale_music_auth0_token');
    const decodedToken = token ? jwt_decode(token) : null;
    const current_time = Date.now().valueOf() / 1000;

    if (!decodedToken || current_time > decodedToken.exp) {
      token = await getTokenSilently();
    }

    config.headers['Authorization'] = `Bearer ${token}`;
    config.headers['x-scale-omni-token'] = localStorage.getItem('scale_music_omni_token') || '';

    return config;
  },
  error => Promise.reject(error)
);

export default Axios;
