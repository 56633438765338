// count up streams by dsp from the flat array of streaming data
export function countStreamsByDsp(data) {
  let streamCounts = [];
  data.forEach(day => {
    // have to check for the existence of the DSP already in the array, potential optimization here
    const curDayIndex = streamCounts.findIndex(streamCount => streamCount.date === day.date);
    if (curDayIndex !== -1) {
      streamCounts[curDayIndex] = {
        ...streamCounts[curDayIndex],
        [day.dsp_name]: day.streams,
        date: day.date,
        totalStreamCount: (streamCounts[curDayIndex]?.totalStreamCount || 0) + day.streams,
      };
    } else {
      streamCounts.push({
        [day.dsp_name]: day.streams,
        date: day.date,
        totalStreamCount: day.streams,
      });
    }
  });
  return streamCounts;
}
