import React, { useEffect, useContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { Col, Row } from 'react-styled-flexboxgrid';
import useCardFlow from 'utils/use-card-flow';

import FieldLabel from 'components/FieldLabel/field-label';
import TextInput from 'features/TextInput/text-input';
import Button from 'components/Button/button';

import { CardContext } from '../card-context';

import { CardContentWrapper, CardLoadingWrapper, CardHeaderBlock } from './card-style';

import { ButtonCol } from 'styles';

import { CARDS } from '../card';
import HEAP from '../../../constants/HEAP.gen.json';
import { validateTitle } from '../manage-release-functions';

const InputReleaseTitle = ({ disabled, register }) => {
  const toast = useToast();
  const [error, setError] = useState(null);

  const handleOnBlur = () => {
    toast.closeAll();
  };

  const handleOnFocus = () => {
    if (error && !toast.isActive(error)) {
      toast({
        status: 'error',
        title: 'Release Title Error',
        id: error,
        description: error,
        duration: null,
      });
    }
  };

  return (
    <>
      <FieldLabel text="Release Title" />
      <TextInput
        invalid={!!error}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        name="title"
        disabled={disabled}
        ref={register({
          validate: title => {
            const { isValid, errorMessage } = validateTitle(title, { isRequired: true });
            setError(errorMessage);

            if (!isValid && !toast.isActive(errorMessage)) {
              toast({
                status: 'error',
                title: 'Release Title Error',
                id: errorMessage,
                description: errorMessage,
                duration: null,
              });
            } else if (isValid) {
              setError(null);
              toast.closeAll();
            }

            return isValid;
          },
        })}
        heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TITLE_INPUT_TITLE}
      />
    </>
  );
};

const CoreDetails = () => {
  const CARD_ID = CARDS.CoreDetails;

  const {
    id,
    getCardData,
    handleSubmitError,
    handleResetSaveAndPreview,
    afterSaveActions,
    NAV_DIRECTIONS,
    loadingAny,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { cardData, _passthrough, updateCard_Form, isReviewMode } = useCardFlow();
  // ---------------------------------- CoreDetails

  const formMethods_CoreDetails = useForm();
  const formValues_CoreDetails = formMethods_CoreDetails.getValues({
    nest: true,
  });
  const errors_CoreDetails = formMethods_CoreDetails.errors;

  useEffect(() => {
    if (id === CARD_ID) {
      formMethods_CoreDetails.reset(getCardData('formData'));
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_CoreDetails.handleSubmit(
        data => save_CoreDetails(data, _passthrough.inducePreview),
        _data => handleSubmitError(_passthrough.inducePreview)
      )();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  const save_CoreDetails = (data, forcePreview = false, navigation = null) => {
    const saveData = {
      ...formValues_CoreDetails,
      ...data,
      title: data?.title?.trim() || formValues_CoreDetails.title,
      sub_title: data?.sub_title === '' ? '' : data?.sub_title?.trim() || formValues_CoreDetails.sub_title,
    };

    updateCard_Form(id, saveData);

    const showPreview = forcePreview || isReviewMode;
    afterSaveActions(showPreview, navigation);
  };

  const submitHandler_CoreDetails = formMethods_CoreDetails.handleSubmit(data =>
    save_CoreDetails(data, false, NAV_DIRECTIONS.FORWARD)
  );

  const isDisabled = loadingSaveRelease || loadingAny;

  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <FormProvider {...formMethods_CoreDetails}>
          <form onSubmit={submitHandler_CoreDetails} autoComplete="off">
            <CardHeaderBlock omnishareas>
              <Row>
                <Col xs={8}>
                  <h3>Name Your Release</h3>
                </Col>
                <ButtonCol xs={4}>
                  <Button
                    text={isReviewMode ? 'Save & Preview' : 'Next'}
                    rightIcon={isReviewMode ? null : 'chevronRight'}
                    onClick={submitHandler_CoreDetails}
                    loading={loadingSaveRelease || loadingAny}
                    disabled={isFlowAnimating}
                    submit
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TITLE_BUTTON_NEXT}
                  />
                </ButtonCol>
              </Row>
            </CardHeaderBlock>
            <Row>
              <Col xs={12} md={6}>
                <InputReleaseTitle disabled={isDisabled} register={formMethods_CoreDetails.register} />
                <br />
                <br />
                <FieldLabel text="Release Version" />
                <TextInput
                  name="sub_title"
                  placeholder="If original, leave blank. (ex: Live, Remix, etc.)"
                  error={errors_CoreDetails['sub_title']}
                  disabled={isDisabled}
                  ref={formMethods_CoreDetails.register()}
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_SUB_TITLE_INPUT_SUB_TITLE}
                />
              </Col>
            </Row>
          </form>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};

export default CoreDetails;
