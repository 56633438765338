import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { useFormContext } from 'react-hook-form';
import Icon from 'components/Icon/icon';

const LabelWrapper = styled.label`
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  margin-right: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${props =>
    props.disabled &&
    `
		cursor: default;
		pointer-events: none;
	`}
`;

const Label = styled.span`
  align-items: center;
  color: ${props => props.theme.colors.text.checkbox[props.colorVariant].default};
  display: inline-flex;
  letter-spacing: normal;
  margin-left: 0.5rem;

  ${props =>
    props.disabled &&
    `
		color: ${props.theme.colors.text.checkbox[props.colorVariant].disabled};
	`}
`;

const Input = styled.input`
  position: absolute;
  visibility: hidden;
`;

const Checkbox = React.forwardRef(
  (
    {
      children,
      className,
      dark,
      defaultChecked,
      disabled,
      doShowError,
      error,
      label,
      light,
      name,
      style,
      theme,
      heapCode,
      ...props
    },
    ref
  ) => {
    const { watch } = useFormContext();
    const watchIsChecked = watch(name);
    const [isChecked, setIsChecked] = useState(defaultChecked);
    const variant = props?.variant || 'dark';

    useEffect(() => setIsChecked(watchIsChecked), [watchIsChecked]);

    return (
      <LabelWrapper style={style} htmlFor={name} className={className} disabled={disabled}>
        <Icon
          color={disabled ? theme.colors.text.checkbox[variant].disabled : theme.colors.text.checkbox[variant].default}
          type={isChecked ? 'checkboxChecked' : 'checkboxEmpty'}
        />
        {label && (
          <Label colorVariant={variant} disabled={disabled}>
            {label}
          </Label>
        )}
        <Input
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={name}
          invalid={error}
          key={name}
          label={label}
          name={name}
          ref={ref}
          type="checkbox"
          data-heap={heapCode}
          {...props}
        />
      </LabelWrapper>
    );
  }
);
Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  disabled: false,
  dark: false,
  light: false,
  defaultChecked: false,
  doShowError: false,
  error: null,
  label: '',
  heapCode: null,
};

export default withTheme(Checkbox);
