import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Center,
  Heading,
  Icon,
  Spinner,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useTheme,
  chakra,
  Td,
  Flex,
} from '@chakra-ui/react';
import Box from 'components/Box/Box';
import * as API from 'utils/API/API';
import { useQuery } from 'react-query';
import useDebounce from 'utils/debounce';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import TablePaginationFooter from 'components/Table/table-pagination-footer';
import { useSortBy, useTable } from 'react-table';
import { RiArrowDownLine, RiArrowUpLine } from 'react-icons/ri';
import SearchBar from 'components/search-bar';
import IconComponent from 'components/Icon/icon';

const MarketingDiscoveryModeTracks = API.marketingDiscoveryModeTracks();

const PAGE_SIZE = 8;

const TableRow = ({ track }) => {
  return (
    <>
      <Tr borderColor={'black20'}>
        {track.cells.map(cell => (
          <Td key={`${cell.column.id}-${track.original.artist_name}-${track.original.isrc_code}`}>
            {['artist_name', 'track_name', 'isrc_code'].includes(cell.column.id) && (
              <Box>{track.original[cell.column.id]}</Box>
            )}
            {['analytics'].includes(cell.column.id) && (
              <Link to={`/analytics/track/${track.original.asset_track_id}`}>
                <IconComponent type="magnifyingGlass" color="black" />
              </Link>
            )}
          </Td>
        ))}
      </Tr>
    </>
  );
};

export default function MarketingDiscoveryModeLive({ userGroupId }) {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const query = useQuery(
    ['liveCampaignStats', userGroupId],
    () =>
      MarketingDiscoveryModeTracks.getLiveCampaignStats({
        user_group_id: userGroupId,
      }),
    { enabled: !!userGroupId }
  );

  const { year = '', month_name = '' } = React.useMemo(() => query.data || {}, [query.data]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Artist',
        accessor: 'artist_name',
      },
      {
        Header: 'Track',
        accessor: 'track_name',
      },
      {
        Header: 'ISRC',
        accessor: 'isrc_code',
      },
      {
        Header: 'Analytics',
        accessor: 'analytics',
      },
    ];
  }, []);

  const tableQuery = useQuery(
    ['discoveryModeLiveTable', page, userGroupId, debouncedSearchTerm],
    () =>
      MarketingDiscoveryModeTracks.getLiveCampaignTracks({
        page_size: PAGE_SIZE,
        page_index: page,
        user_group_id: userGroupId,
        search_term: debouncedSearchTerm,
      }),
    { enabled: !!userGroupId }
  );

  const { data, totalPages } = React.useMemo(() => {
    const { data: queryData = { total: 1, results: [] } } = tableQuery;
    const { total = 1, results } = queryData;
    const totalPages = Math.max(Math.ceil(total / PAGE_SIZE), 1);

    return { data: results, totalPages };
  }, [tableQuery.data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'artist_name',
            desc: false,
          },
        ],
      },
    },
    useSortBy
  );

  const onNextPage = React.useMemo(() => () => setPage(p => p + 1), []);
  const onPrevPage = React.useMemo(() => () => setPage(p => p - 1), []);

  const isNextPageDisabled = React.useMemo(
    () => page + 1 >= totalPages || tableQuery.isLoading || tableQuery.isRefetching,
    [page, totalPages, tableQuery.isLoading, tableQuery.isRefetching]
  );

  const isPrevPageDisabled = React.useMemo(
    () => page <= 0 || tableQuery.isLoading || tableQuery.isRefetching,
    [page, tableQuery.isLoading, tableQuery.isRefetching]
  );

  return !month_name && query.isFetched ? (
    <Box color="black70" p={6} textAlign="center" fontWeight="bold">
      You don't have a live campaign right now.
    </Box>
  ) : (
    <>
      <Flex justifyContent="space-between" pb={3}>
        <Heading
          mt={3}
          mb={3}
          color="gray.800"
          as={'h2'}
          fontSize={theme.fonts.sizes.xlg}
          fontFamily={theme.fonts.families.text}>
          {month_name} {year}
        </Heading>
        <SearchBar
          searchTerm={searchTerm}
          onSearchTermChange={setSearchTerm}
          width="25rem"
          placeholder="Search by artist, title or isrc"
        />
      </Flex>
      <>
        <SkeletonLoader status={tableQuery.status} skeletonProps={{ height: '240px', width: '100%' }}>
          <Box overflowX="auto">
            <Table size="sm" variant="light" {...getTableProps()}>
              <Thead>
                {headerGroups.map((headerGroup, groupIndex) => (
                  <Tr key={`${groupIndex}-headergroup`} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, headerIndex) => (
                      <Th
                        key={`${groupIndex}-${headerIndex}-tableheader`}
                        isNumeric={column.isNumeric}
                        {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <Box style={{ color: 'white' }} display="inline">
                          {column.isSorted ? (
                            <chakra.span pr="3" position="relative" top="1px">
                              {column.isSortedDesc ? (
                                <Icon as={RiArrowUpLine} aria-label="sorted descending" />
                              ) : (
                                <Icon as={RiArrowDownLine} aria-label="sorted ascending" />
                              )}
                            </chakra.span>
                          ) : null}
                          {column.render('Header')}
                        </Box>
                      </Th>
                    ))}
                  </Tr>
                ))}
              </Thead>
              <Tbody {...getTableBodyProps()}>
                {rows.map(track => {
                  prepareRow(track);
                  return (
                    <TableRow key={`track-${track.original.artist_name}-${track.original.isrc_code}`} track={track} />
                  );
                })}
              </Tbody>
            </Table>
            {query.isRefetching && (
              <Box
                style={{
                  position: 'absolute',
                  left: 0,
                  bottom: 0,
                  right: 0,
                  top: 0,
                  backgroundColor: theme.colors.brand.layer4,
                  opacity: '25%',
                }}>
                <Center h="full">
                  <Spinner color="black" size="lg" />
                </Center>
              </Box>
            )}
          </Box>
          <TablePaginationFooter
            onPrevPage={onPrevPage}
            isPrevPageDisabled={isPrevPageDisabled}
            page={page}
            totalPages={totalPages}
            onNextPage={onNextPage}
            isNextPageDisabled={isNextPageDisabled}
          />
        </SkeletonLoader>
      </>
    </>
  );
}
