import React, { useCallback, useEffect, useState } from 'react';

import {
  Box,
  Flex,
  FormControl,
  IconButton,
  NumberInput,
  NumberInputField,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
  Tooltip,
  useDisclosure,
  useTheme,
  useToast,
} from '@chakra-ui/react';

import LabeledInput from '../labeled-input/labeled-input';
import ClipWaveForm from './clip-waveform';
import Well from '../Well/well';
import { RiDeleteBin7Line, RiPauseCircleLine, RiPlayCircleLine, RiPencilFill } from 'react-icons/ri';
import DeleteClipModal from './delete-clip-modal';
import { WaveSurfer } from 'wavesurfer-react';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions';
import useWaveFormProps from './use-wave-form-props';

const GenerateNewWaveForm = ({
  clipKey,
  playBackSeconds,
  file,
  clip,
  onDeleteClip,
  canDelete,
  isActivePlayer,
  setIsActivePlayer,
  hasBeenSubmitted,
  clipNamePlaceholder = 'My Clip',
}) => {
  const theme = useTheme();
  const [wavesurfer, setWavesurfer] = useState();
  const [regionEnd, setRegionEnd] = useState();
  const [loading, setLoading] = useState(true);
  const [clipName, setClipName] = useState(clip.props.clipName);
  const [startTime, setStartTime] = useState(clip.props.startTimeSeconds);
  const [sliderValue, setSliderValue] = useState(clip.props.speedPercent);
  const [inputValue, setInputValue] = useState(clip.props.speedPercent);
  const [isEditing, setIsEditing] = useState(false);

  const deleteClipModal = useDisclosure();
  const [showTooltip, setShowTooltip] = useState(false);
  const toast = useToast();
  const waveFormProps = useWaveFormProps({
    progressColor: [theme.colors.transparent],
  });

  // generic value update to synchronize input and slider values
  const updateValue = ({ newInputValue, newSliderValue }) => {
    let value = newInputValue || newSliderValue;

    if (value === inputValue && value === sliderValue) {
      return;
    }

    if (wavesurfer.isPlaying()) {
      wavesurfer.pause();
    }
    clip.updateState({ speedPercent: value });

    // input value is always being updated
    setInputValue(value);
    if (newSliderValue) {
      setSliderValue(value);
    }
    wavesurfer.setPlaybackRate(value / 100, true);
    if (isActivePlayer) {
      wavesurfer.play();
    }
  };

  const invalidInputNumber = inputValue < 25 || inputValue > 300;

  // update the slider onBlur, rather than onChange
  const handleInputBlur = () => {
    let newValue = inputValue;
    if (newValue < 25) {
      newValue = 25;
    }
    if (newValue > 300) {
      newValue = 300;
    }

    if (newValue !== inputValue || newValue !== sliderValue) {
      setSliderValue(newValue);
      setInputValue(newValue);
    }
    setIsEditing(false);
  };

  const [regionConfig, setRegionConfig] = useState({
    id: `${clipKey}-region`,
    start: clip.props.startTimeSeconds,
    end: clip.props.endTimeSeconds,
    minLength: (clip.props.speedPercent / 100) * 60,
    maxLength: (clip.props.speedPercent / 100) * 60,
    color: theme.colors.pinkHalfOpacity,
    resize: false,
    loop: true,
    drag: hasBeenSubmitted ? false : true,
  });

  const inputStyles = {
    borderRadius: '0',
    borderColor: 'black100',
    border: '1px solid',
  };

  const waveFormId = `generate-new-wave-form-${clipKey}`;
  const plugins = [
    {
      plugin: RegionsPlugin,
    },
  ];

  const handleRegionUpdate = region => {
    if (!region.isDragging) {
      if (wavesurfer.isPlaying()) {
        wavesurfer.stop();
      }

      const newStart = region.start;
      setStartTime(newStart);
      clip.updateState({
        startTimeSeconds: newStart,
        endTimeSeconds: region.end,
      });
      wavesurfer.setCurrentTime(newStart);
      if (isActivePlayer) {
        wavesurfer.play();
      }
      setRegionConfig(config => ({
        ...config,
        start: newStart,
        end: region.end,
      }));
    }
  };

  const onClipNameChange = e => {
    const clipName = e.target.value;
    setClipName(clipName);
    clip.updateState({ clipName });
  };

  const onClipNameOut = e => {
    if (!e.target.value) {
      toast({
        title: 'Please provide a title for your clip',
        description: `A title is required for your clip`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onStartTimeChange = value => {
    setStartTime(value);
    const maxValue = regionEnd - regionConfig.minLength;

    if (value && value < maxValue) {
      setRegionConfig(config => ({
        ...config,
        start: value,
      }));

      clip.setStartTimeSeconds(value);
    } else if (value > maxValue) {
      toast({
        title: 'Invalid Start Time',
        description: `For a complete clip, the start time must be a number from 0 and ${maxValue}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const onStartTimeOut = e => {
    const maxValue = regionEnd - regionConfig.minLength;
    if (!e.target.value || e.target.value > maxValue) {
      toast({
        title: 'Invalid Start Time',
        description: `For a complete clip, the start time must be a number from 0 and ${maxValue}`,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const formatSecondsToTime = () => {
    const givenSeconds = Math.floor(startTime);
    const minutes = Math.floor(givenSeconds / 60);
    const seconds = Math.floor(givenSeconds - minutes * 60);
    const timeString = minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
    return timeString;
  };

  const handlePlayPause = useCallback(() => {
    if (isActivePlayer) {
      const currentRegion = wavesurfer?.regions?.list[regionConfig.id];
      currentRegion.playLoop();
    }
  }, [isActivePlayer, wavesurfer, regionConfig.id, wavesurfer?.currentTime]);

  useEffect(() => {
    // if pause is defined wavesurfer is ready
    if (typeof wavesurfer?.stop === 'function') {
      handlePlayPause();
      return () => wavesurfer.stop();
    }
  }, [handlePlayPause, wavesurfer?.stop]);

  const playPause = () => {
    setIsActivePlayer(!isActivePlayer);
  };

  const onMount = wavesurfer => {
    if (wavesurfer.markers) {
      wavesurfer.clearMarkers();
    }
    setWavesurfer(wavesurfer);
    if (file) {
      wavesurfer.loadBlob(file);
    }
    wavesurfer.on('ready', () => {
      wavesurfer.setPlaybackRate(clip.props.speedPercent / 100, true);
      const duration = wavesurfer.getDuration();
      setRegionEnd(duration * (clip.props.speedPercent / 100));
      setLoading(false);
    });
  };

  return (
    <WaveSurfer plugins={plugins} onMount={onMount} container={`#${waveFormId}`} {...waveFormProps}>
      <FormControl>
        <Flex
          flexDirection="column"
          border={`1px solid ${theme.colors.cream100}`}
          borderRadius=".875rem"
          padding="1.5rem"
          marginTop={'1rem'}
          width="100%">
          <Flex alignItems="center" justifyContent="space-between" w="100%" mb="1.5rem" color="black100">
            <LabeledInput
              placeholder={clipNamePlaceholder}
              {...inputStyles}
              onBlur={onClipNameOut}
              w="15rem"
              onChange={onClipNameChange}
              value={clipName}
              label={'Clip Name'}
              name={'clipname'}
              tooltip={'This is the song title that will display for this clip'}
            />
            <Flex alignItems="center" w="8rem">
              <Text mr=".75rem">Start</Text>
              {/* the `1 === 1` makes unreachable code so this is a temp fix. We may need to handle actually inputing later 08/31/2023 */}
              {hasBeenSubmitted || 1 === 1 ? (
                <Text>{formatSecondsToTime()}</Text>
              ) : (
                <NumberInput
                  {...inputStyles}
                  defaultValue={startTime}
                  min={0}
                  onChange={onStartTimeChange}
                  value={formatSecondsToTime()}
                  onBlur={onStartTimeOut}>
                  <NumberInputField
                    borderStyle={'none'}
                    _focus={{ borderStyle: 'none' }}
                    padding="0.75rem"
                    w="4rem"
                    fontSize={'.875rem'}
                  />
                </NumberInput>
              )}
            </Flex>
          </Flex>
          {/* Audio */}
          <ClipWaveForm
            handleRegionUpdate={handleRegionUpdate}
            regionConfig={regionConfig}
            waveFormId={waveFormId}
            playBackSeconds={playBackSeconds}
            loading={loading}
          />
          {/* Begin Footer */}
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Flex flexGrow="2" alignItems="center" justifyContent="flex-start">
              <IconButton
                w="2rem"
                marginRight=".5rem"
                fontSize="1.75rem"
                style={{ backgroundColor: 'transparent', border: '0' }}
                icon={isActivePlayer ? <RiPauseCircleLine /> : <RiPlayCircleLine />}
                onClick={playPause}
                flexShrink="1"
                aria-label={'button'}
              />
              {/* Begin Slider */}
              <Text>25%</Text>
              <Slider
                disabled={hasBeenSubmitted}
                min={25}
                max={300}
                defaultValue={clip.props.speedPercent}
                value={sliderValue}
                step={25}
                mx="0.5rem"
                onChange={v => setSliderValue(v)}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => {
                  setShowTooltip(false);
                  updateValue({ newSliderValue: sliderValue });
                }}>
                <SliderTrack h=".35rem" borderRadius="0">
                  <SliderFilledTrack />
                </SliderTrack>
                <Tooltip
                  hasArrow
                  bg="teal.500"
                  color="white"
                  placement="top"
                  isOpen={showTooltip}
                  label={`${sliderValue}%`}>
                  <SliderThumb>
                    <Box bg="white" w="1rem" h=".75rem" border="solid black 1px" borderRadius="50%" />
                  </SliderThumb>
                </Tooltip>
              </Slider>
              <Text>300%</Text>
              <Flex
                ml="1.5rem"
                alignItems="center"
                fontSize=".825rem"
                w="100%"
                flexWrap="wrap"
                maxH="40px"
                position="relative">
                <Text color={'black100'}>Speed</Text>
                <NumberInput
                  min={25}
                  max={300}
                  ml=".25rem"
                  size="xs"
                  maxH="40px"
                  disabled={hasBeenSubmitted}
                  borderColor={isEditing ? 'black100' : 'transparent'}
                  focusBorderColor={invalidInputNumber ? 'error' : 'black100'}
                  color="black100"
                  value={inputValue ? ` ${inputValue}%` : ' %'}
                  onChange={v => updateValue({ newInputValue: v })}
                  onBlur={e => handleInputBlur(e)}
                  precision={0}
                  isInvalid={invalidInputNumber}>
                  <NumberInputField
                    disabled={!isEditing ? true : false}
                    _disabled={{ pointerEvents: 'none', color: 'black100' }}
                    padding="0"
                    w="2.5rem"
                  />
                </NumberInput>
                {!isEditing && !hasBeenSubmitted ? (
                  <IconButton
                    marginLeft=".15rem"
                    fontSize="1rem"
                    justifyContent="flex-start"
                    alignContent="center"
                    style={{ backgroundColor: 'transparent', border: '0' }}
                    icon={<RiPencilFill />}
                    onClick={() => setIsEditing(true)}
                  />
                ) : null}
                {invalidInputNumber ? (
                  <Text
                    top="1.75rem"
                    position="absolute"
                    justifySelf={'flex-start'}
                    alignSelf={'flex-end'}
                    color="error"
                    fontSize="sm"
                    w="10rem">
                    Please input a number between 25% and 300%.
                  </Text>
                ) : null}
              </Flex>
            </Flex>
            {canDelete && (
              <Flex justifyContent="flex-end" alignItems="center" color="black100" w="auto">
                <Text>Delete Clip</Text>
                <IconButton
                  fontSize="1rem"
                  style={{ backgroundColor: 'transparent', border: '0' }}
                  icon={<RiDeleteBin7Line />}
                  onClick={deleteClipModal.onOpen}
                />
                <DeleteClipModal
                  isOpen={deleteClipModal.isOpen}
                  onClose={deleteClipModal.onClose}
                  onSubmit={onDeleteClip}
                  noFooter={true}
                  variant="short"
                  headerText="Sure you want to delete this clip?"
                />
              </Flex>
            )}
          </Flex>
          <Well variant="collapsible" mt="1rem">
            Change the speed % to speed up or slow down your track. The standard version of your track is at 100%.
            Anything below 100% will slow it down and anything above 100% will speed it up. You can use the play button
            to see how it sounds at various speeds.
          </Well>
          <div id={`anchor-${clip.name}`} />
        </Flex>
      </FormControl>
    </WaveSurfer>
  );
};

export default GenerateNewWaveForm;
