import React from 'react';
import { Box, Text, useTheme } from '@chakra-ui/react';
import { ContentHeader, Title } from 'components/ManageContentBox/manage-content-box';

import HeaderAndContentBox from './clip-review-header-and-content';
import SimpleAudioPlayer from 'components/MediaPlayer/simple-audio-player';

export default function ReviewAndSubmitCreateClips({ stateManager, stateData }) {
  return (
    <HeaderAndContentBox title={`Create Clips (${stateData?.clips?.length || 0})`} stateManager={stateManager}>
      <ContentHeader>
        <Title>Your Clips</Title>
      </ContentHeader>
      {stateData.clips?.map(clip => (
        <ClipPlayer clip={clip} key={clip.name} />
      ))}
      <Box height="2rem"></Box>
    </HeaderAndContentBox>
  );
}

function ClipPlayer({ clip }) {
  const { stateData } = clip;
  const theme = useTheme();
  return (
    <Box
      my={'1rem'}
      px={'1rem'}
      py={'1rem'}
      display={'flex'}
      border={'1px solid'}
      borderRadius={'1rem'}
      borderColor={theme.colors.black50}
      alignItems={'center'}>
      <Text width={'14rem'}>{stateData.clipName || 'Clip 1'}</Text>
      <SimpleAudioPlayer
        file={stateData.file}
        speed={stateData.speedPercent / 100}
        start={stateData.startTimeSeconds}
        end={stateData.endTimeSeconds}
      />
      <Text width={'6rem'}>Start {fmtMSS(stateData.startTimeSeconds)}</Text>
      <Text width={'6rem'}>Speed {stateData.speedPercent}%</Text>
    </Box>
  );
}

/**
 * Converts seconds into minutes and seconds in the format of "MM:SS.ss"
 * @param {*} seconds The number value to convert to the above format
 * @returns formated value
 * @example
 * // returns 0:00.00
 * fmtMSS(0)
 * @example
 * //return 2:03.40
 * fmtMSS(123.4)
 */
function fmtMSS(seconds) {
  let s = seconds;
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + parseFloat(s).toFixed(2);
}
