import * as React from 'react';
import { useQueries } from 'react-query';
import { Avatar, SkeletonCircle, Stat, StatArrow, Table, Tbody, Tr, Td, Text } from '@chakra-ui/react';
import numeral from 'numeral';

import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import GlanceCard from './glance-card';
import { useAnalyticsContext } from './analytics-context';
import HEAP from '../constants/HEAP.gen.json';
import { determineShouldShowTotFromDomainData } from './determine-should-show-tot-for-track';

export default function TopTerritoriesTableContainer({ domainData }) {
  const { dateRangeString, decorativeText, percentChangeStartDate, currentMetricsInterval, rollups } =
    useAnalyticsContext();
  const topTerritories = rollups?.territories ?? {};

  return (
    <GlanceCard
      status={domainData.status}
      dateRangeString={dateRangeString}
      decorativeText={decorativeText}
      title={'Top Countries'}
      data-heap={HEAP.PUBLIC.ANALYTICS.TYPE.ANALYTICS_CARD_TOP_TERRITORIES_TABLE_CONTAINER}>
      <SkeletonLoader status={domainData.status} customError={'Error Loading Territory Data'}>
        <TopTerritoriesTable
          topTerritories={topTerritories.data ?? []}
          timeRangeInDays={currentMetricsInterval.interval + 1}
          percentChangeStartDate={percentChangeStartDate}
          shouldShowTotStat={determineShouldShowTotFromDomainData(domainData)}
        />
      </SkeletonLoader>
    </GlanceCard>
  );
}

function TopTerritoriesTable({ shouldShowTotStat, timeRangeInDays, topTerritories }) {
  const top3Territories = topTerritories.slice(0, 3);

  const territoryQueries = useQueries(
    top3Territories.map(trtry => {
      return {
        queryKey: ['territory', trtry.territory],
        queryFn: async () => {
          const response = await fetch(`https://restcountries.com/v3.1/alpha/${trtry.territory}`);
          if (!response.ok) {
            throw new Error('Network response for country metadata failed');
          }
          return response.json();
        },
      };
    })
  );

  return (
    <Table size="sm" variant="simple">
      <Tbody>
        {top3Territories.map((territoryObj, index) => {
          let territoryPercentageChange = territoryObj?.time_over_time;

          return (
            <Tr key={`${index}-${territoryObj?.territory}`}>
              <Td pl={0} pr={2} py={4} color="black.60">
                {String(index + 1).padStart(2, '0')}
              </Td>
              <Td px={0}>
                {territoryQueries[index].isLoading && <SkeletonCircle height={6} width={6} />}
                {territoryQueries[index].isSuccess && (
                  <Avatar
                    name={territoryObj?.territory}
                    src={territoryQueries[index]?.data[0]?.flags?.png}
                    height={6}
                    width={6}
                  />
                )}
              </Td>
              <Td pl={2}>
                <Text as="div" color="black.100" fontSize="reg" fontWeight="bold">
                  {(territoryQueries[index].isSuccess && territoryQueries[index].data.name) || territoryObj?.territory}
                  {territoryQueries[index].isError && 'Country'}
                </Text>
              </Td>
              <Td pr={0} isNumeric>
                <Text color="black.100" fontSize="lg" fontWeight="bold">
                  {numeral(territoryObj.streams).format('0,0')}
                </Text>
                <SkeletonLoader skeletonProps={{ w: '50px', h: '17px', float: 'right' }}>
                  {territoryPercentageChange !== null && !!shouldShowTotStat && (
                    <Stat
                      visibility="hidden"
                      fontSize="sm"
                      fontWeight="medium"
                      color={territoryPercentageChange > 0 ? 'green.400' : 'red.400'}
                      whiteSpace="nowrap">
                      <StatArrow width={2} height={2} type={territoryPercentageChange > 0 ? 'increase' : 'decrease'} />
                      {numeral(Math.abs(territoryPercentageChange)).format('0.00%')}
                      <Text key={timeRangeInDays} display="inline" ml={1} fontSize="xsm" color="gray.500">
                        {Number(timeRangeInDays) === 7 && 'WoW'}
                        {Number(timeRangeInDays) === 30 && 'MoM'}
                      </Text>
                    </Stat>
                  )}
                </SkeletonLoader>
              </Td>
            </Tr>
          );
        })}
        {top3Territories?.length === 0 && (
          <Tr>
            <Td borderBottom="none" pl={0}>
              <Text color="black.100" fontSize="reg" fontWeight="bold">
                No Territories to display
              </Text>
            </Td>
          </Tr>
        )}
      </Tbody>
    </Table>
  );
}
