import z from 'zod';

const clipName = z.string().min(3);
const startTimeSeconds = z.number().min(0);
const endTimeSeconds = z.number().min(60);
const speedPercent = z.number().min(0).max(300);

const Model = z.object({
  Clip: z.object({
    clipName: clipName,
    startTimeSeconds: startTimeSeconds,
    endTimeSeconds: endTimeSeconds,
    speedPercent: speedPercent,
  }),
});

const clips = z.array(z.any()).min(1);

const validators = {
  Model,
  clipName,
  startTimeSeconds,
  endTimeSeconds,
  speedPercent,
  clips,
};

export { validators as default };
