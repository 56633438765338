import React from 'react';
import { ContentHeader, Title } from 'components/ManageContentBox/manage-content-box';
import PartyRow from './clip-review-and-submit-party-row';
import HeaderAndContentBox from './clip-review-header-and-content';
import ListItem from './clip-review-list-item';
import { LANGUAGES2 } from '../../../../constants/dictionaries';
const languagesOptions = LANGUAGES2.map(lang => ({ value: lang.value, title: lang.label }));

export default function ReviewAndSubmitReleaseInfo({ stateManager, stateData, artistsQuery }) {
  const { isLoading: artistsLoading, data: artists } = artistsQuery;
  return (
    <HeaderAndContentBox stateManager={stateManager}>
      <ContentHeader>
        <Title>Artists</Title>
      </ContentHeader>
      {!artistsLoading ? (
        stateData.artists.map(artist => (
          <PartyRow
            key={`artist-${artist.id}`}
            party={{ ...artists?.find(a => a.id === artist.asset_party_id), ...artist }}
          />
        ))
      ) : (
        <div>loading...</div>
      )}
      <ListItem title={'Genre'} value={stateData.genre} />
      <ListItem title={'Language'} value={languagesOptions.find(la => la.value === stateData.lang)?.title || ''} />
      <ListItem title={'Do you have your own UPC'} value={stateData?.asset_release_upc ? 'Yes' : 'No'} />
      {stateData?.asset_release_upc ? <ListItem title={'UPC'} value={stateData?.asset_release_upc} /> : ''}
      <ListItem title={'Is this a re-release'} value={stateData?.original_release_date ? 'Yes' : 'No'} />
      {stateData?.original_release_date ? (
        <ListItem title={'Original Release Date'} value={stateData?.original_release_date} />
      ) : (
        ''
      )}
    </HeaderAndContentBox>
  );
}
