import { FormControl, FormErrorMessage, Tooltip } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import React from 'react';
import SearchSelect from '../../../features/SearchSelect/search-select';
import { Col } from 'react-styled-flexboxgrid';
const LABEL_PAYEE_ROW_TEXT = `The Label is automatically added to splits and cannot be removed.`;

export function PayeeColumn(props) {
  return (
    <Col xs={3} disabled={props.disabled}>
      <FormControl isRequired isInvalid={props.invalid}>
        {!props.disabled && (
          <>
            <SearchSelect
              placeholder="Select Payee"
              isDisabled={props.disabled}
              onChange={props.onChange}
              options={props.payeesOptions || []}
              value={props.value}
              rowActionButtonText={'Add Payee'}
              onRowActionButtonClick={props.onRowActionButtonClick}
            />
            <FormErrorMessage>* required</FormErrorMessage>
          </>
        )}
        {props.disabled && props?.value?.label && (
          <Tooltip hasArrow placement="top" label={LABEL_PAYEE_ROW_TEXT}>
            {props?.value?.label}
          </Tooltip>
        )}
      </FormControl>
    </Col>
  );
}

PayeeColumn.propTypes = {
  invalid: PropTypes.any,
  disabled: PropTypes.any,
  onChange: PropTypes.func,
  payeesOptions: PropTypes.any,
  value: PropTypes.any,
  onRowActionButtonClick: PropTypes.any,
};
