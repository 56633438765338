import React from 'react';
import { Tr, Td, Flex, Box, IconButton } from '@chakra-ui/react';
import { useReleaseArtwork } from 'hooks/use-release-artwork';
import FallbackCoverArt from 'assets/fallback-cover-art.png';
import { FiArrowRight as ArrowRight } from 'react-icons/fi';

export default function ReleasedTracksTableRow({ releaseTrack, selectReleaseAction }) {
  const imgUrl = useReleaseArtwork(releaseTrack?.asset_release_id, '50x50');

  return (
    <Tr cursor={'pointer'} onClick={() => selectReleaseAction(releaseTrack)}>
      <Td>
        <Box marginRight="48px" width="50px">
          <img src={imgUrl || FallbackCoverArt} />
        </Box>
      </Td>
      <Td>
        <Flex alignItems="center">
          <Box>{releaseTrack?.track_title}</Box>
        </Flex>
      </Td>
      <Td>{releaseTrack?.release_title}</Td>
      <Td>{releaseTrack?.track_display_artist_name}</Td>
      <Td textAlign="end" paddingRight="0">
        <IconButton
          variant="transparent"
          icon={<ArrowRight size={'1.75rem'} />}
          onClick={() => selectReleaseAction(releaseTrack)}
        />
      </Td>
    </Tr>
  );
}
