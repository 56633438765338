import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';
import { isISRC, isUpc } from 'utils/helpers';

const DataApiUrl = `${dataApiUrl()}/marketing-links`;

export async function getMarketingLinks(params) {
  return AuthAxios.get(DataApiUrl, { params }).then(res => {
    return res.data.data;
  });
}

export async function getMarketingLinksCreatedThisMonth() {
  return AuthAxios.get(`${DataApiUrl}/links-created-this-month`).then(res => res.data.data);
}

export async function createMarketingLink(body) {
  return AuthAxios.post(DataApiUrl, body);
}

export async function deleteMarketingLink(id) {
  return AuthAxios.delete(`${DataApiUrl}/${id}`);
}

export async function updateMarketingLink(id, body) {
  return AuthAxios.patch(`${DataApiUrl}/${id}`, body);
}

export async function getPresignedUrl(key) {
  return AuthAxios.post(`${DataApiUrl}/get-presigned-url`, { key }).then(res => res.data.data);
}

export async function getGeneralAnalytics(id) {
  return AuthAxios.get(`${DataApiUrl}/${id}/analytics`).then(res => res.data.data);
}

export async function getTopAnalytics(id, type) {
  return AuthAxios.get(`${DataApiUrl}/${id}/analytics/${type}`).then(res => res.data.data);
}

export async function searchTracksAndReleases(searchInput, dsps = ['spotify', 'apple', 'amazon', 'deezer']) {
  const params = {
    search: !isUpc(searchInput) && !isISRC(searchInput) ? searchInput : undefined,
    upc: isUpc(searchInput) ? searchInput : undefined,
    isrc_code: isISRC(searchInput) ? searchInput : undefined,
    dsps,
  };
  return AuthAxios.get(`${DataApiUrl}/search`, { params }).then(res => res.data.data);
}
