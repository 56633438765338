import { Flex, FormControl, FormErrorMessage, Progress, Button } from '@chakra-ui/react';
import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { HeaderCol } from './header-column';
import Box from '../../Box/Box';
import SplitsRowSimple from './splits-row-simple';

const PAYEE_TOOL_TIP_COPY =
  'A Payee is anyone who gets paid for a Track Sound Recording Split. This person can be the same or different from the contributor';
const CONTRIBUTOR_TOOLTIP_COPY =
  'A contributor earns the royalties for their involvement in the sound recording. They can be paid directly or via another person by selecting a different payee.';
const ROLES_TOOLTIP_COPY = `Select all applicable roles`;
const SPLIT_PERCENT_TOOLTIP_COPY = `For odd numbers of splits, add .001 to one of the percentages — e.g. 33.334`;

const MetadataRow = ({ children, ...props }) => {
  return (
    <Flex marginBottom="1.5rem" {...props}>
      {children}
    </Flex>
  );
};

const ControlsRow = ({ onAddNewRow, disabled = {} }) => {
  return (
    <Row>
      <Col xs={3}>
        <Button fontSize=".75rem" disabled={disabled.addSplit} variant="dark" onClick={onAddNewRow}>
          + Add Split
        </Button>
      </Col>
      <Col xs={4} />
      <Col xs={3} />
      <Col xs={2} />
    </Row>
  );
};

const SplitsRequirementsFeedback = ({ children }) => (
  <MetadataRow>
    <Col xs={12}>{children}</Col>
  </MetadataRow>
);

function FormFeedbackRow({ value, splitsTotalError, splitsTotal }) {
  return (
    <>
      <Progress height={'1px'} value={value} />
      <MetadataRow>
        <Col xs={10}>
          <FormErrorMessage>{splitsTotalError && <Box>Splits must total 100%</Box>}</FormErrorMessage>
        </Col>
        <Col xs={2}>
          <Flex justify={'flex-end'} fontWeight={700}>
            {value !== 100 ? `${splitsTotal.toFixed()}%` : `${value}%`}{' '}
          </Flex>
        </Col>
      </MetadataRow>
    </>
  );
}

const SplitsForm = ({
  isEditing,
  onChangeRowValue,
  onRemoveRow,
  splitsData,
  payeesOptions,
  onAddNewRow,
  splitsTotal,
  isValid,
  errors,
  openPayeeModal,
  openContributorModal,
  canAddSplit,
  availablePayeeContributors,
}) => {
  const { splitsTotalError, rowErrors } = errors;

  const headerCols = [
    { xs: 3, label: 'Payee', tooltipText: PAYEE_TOOL_TIP_COPY, style: { marginLeft: 0 } },
    { xs: 4, label: 'Contributor', tooltipText: CONTRIBUTOR_TOOLTIP_COPY },
    { xs: 3, label: 'Roles', tooltipText: ROLES_TOOLTIP_COPY },
    { xs: 2, label: 'Split %', tooltipText: SPLIT_PERCENT_TOOLTIP_COPY },
  ].map(col => ({
    ...col,
    key: `head-${col.label.split(' ')[0]}`,
  }));

  // display label payee split only
  // 1 - when editing always
  // 2- not editing but has id

  const sortedAndFilteredSplits = splitsData
    .filter(split => {
      if (isEditing) return true;
      if (split.stakeholder_track_split_id) return true;
      return false;
    })
    .sort(s => {
      return s.isLabelPayee ? -1 : 0;
    });

  return (
    <FormControl isInvalid={!isValid} label={'Splits'}>
      <Box variant="darkText" width={'100%'} padding="1rem">
        <MetadataRow
          style={{
            marginBottom: '0.25rem',
          }}>
          {headerCols.map(props => (
            <HeaderCol {...props} key={props.key} />
          ))}
        </MetadataRow>
        {sortedAndFilteredSplits.map((split, i) => (
          <SplitsRowSimple
            isDisabled={!isEditing}
            key={`split-row-${split.idx}`}
            index={split.idx}
            split={split}
            payeesOptions={payeesOptions}
            onChangeRowValue={onChangeRowValue}
            handleRemoveRowClick={onRemoveRow}
            handleAddPayeeClick={openPayeeModal}
            handleAddContributorClick={openContributorModal}
            errors={rowErrors[i]}
            splitsTotal={splitsTotal}
            availablePayeeContributors={availablePayeeContributors}
          />
        ))}
        {isEditing && (
          <>
            <FormFeedbackRow value={splitsTotal} splitsTotalError={splitsTotalError} splitsTotal={splitsTotal} />
            <ControlsRow
              onAddNewRow={onAddNewRow}
              openContributorModal={openContributorModal}
              openPayeeModal={openPayeeModal}
              disabled={{
                addSplit: !canAddSplit,
              }}
            />
          </>
        )}
        {!sortedAndFilteredSplits.length && (
          <SplitsRequirementsFeedback>There are no splits entered</SplitsRequirementsFeedback>
        )}
        <Box variant="darkText" marginTop={'1rem'}>
          Editing Splits is available{' '}
          <strong>upon submission, only until first payment for the ISRC across any releases is sent</strong>. Please
          reach out to YourFriends@Venicemusic.co for any edits outside of this window
        </Box>
      </Box>
    </FormControl>
  );
};

export default SplitsForm;
