import { API_STATUS_KEYS, USER_ROLES } from 'constants/constants';

import ERRORS from 'constants/errors';
import sortBy from 'lodash/sortBy';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import get from 'lodash/get';
import React, { useContext, useEffect, useState } from 'react';
import { useAuth0 } from 'utils/auth0';
import { APP_VIEWS } from 'utils/routes';
import defaultLogo from '../assets/default/logo.png';
import * as API from './API/API';
import { buildStatus } from './helpers';
import useCurrentUser from '../data-client/use-current-user';

export const DATA_CACHE_KEYS = {
  HOME: 'HOME',
  HOME_RECENT: 'HOME_RECENT',
  SPLITS_AND_PAYMENTS: 'SPLITS_AND_PAYMENTS',
  SETTINGS: 'SETTINGS',
  STATEMENTS: 'STATEMENTS',
};

const generateInitialStateReleaseStatus = () => {
  const initialState = {};
  const initialStates = ['all', 'release', 'sets', 'set_tracks', 'track'];
  initialStates.forEach(key => (initialState[key] = buildStatus(null)));
  return initialState;
};

const userAttributes = ['isPayee'];

export const GlobalDataContext = React.createContext();
export const useGlobalData = () => useContext(GlobalDataContext);

export const GlobalDataProvider = ({ children }) => {
  const { user, loading: auth0Loading, logout } = useAuth0();

  const [view, setView] = useState(null);
  const [staleViews, setStaleViews] = useState([]);

  const [dbUser, setDbUser, currentUserQuery] = useCurrentUser({
    retry: false,
    onError: async error => {
      //If after fetching the currentUser we still don't have a user
      //(potentially due to an api error or any error) we need to log
      //the user out so they can start fresh.
      await logout();
    },
  });

  const [dbUserStatus, setDbUserStatus] = useState(buildStatus(null));
  const [userPermissions, setUserPermissions] = useState({
    role: null,
    attributes: {},
  });
  const [allUserAttributesLoaded, setAllUserAttributesLoaded] = useState(false);

  const [selectedUserGroup, setSelectedUserGroup] = useState(null);

  const [userGroups, setUserGroups] = useState([]);
  const [userGroupStatus, setUserGroupStatus] = useState(buildStatus(null));

  const [release, setRelease] = useState(null);
  const [releaseStatus, setReleaseStatus] = useState(generateInitialStateReleaseStatus());

  const [releaseId, setReleaseId] = useState(null);

  const [userPaymentAccounts, setUserPaymentAccounts] = useState({});
  const [userPaymentAccountStatus, setUserPaymentAccountsStatus] = useState({});

  const [dsps, setDsps] = useState([]);
  const [dspsStatus, setDspsStatus] = useState(buildStatus(null));

  const [fileUrls, setFileUrls] = useState({});
  const [fileUrlStatus, setFileUrlStatus] = useState({});

  const Release = API.release();
  const Track = API.track();
  const DSP = API.dsp();
  const User = API.user();

  // Get user profile
  useEffect(() => {
    if (user && dbUser && !auth0Loading) {
      getAuthedUser();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, dbUser, auth0Loading]);

  // View-specific data refresh

  useEffect(() => {
    const alwaysUpdateWhen = {
      [APP_VIEWS.RELEASE_CREATE]:
        !releaseStatus[API_STATUS_KEYS.IN_PROGRESS] && parseInt(releaseId) !== parseInt(release?.id),
      [APP_VIEWS.RELEASE_EDIT]:
        !releaseStatus[API_STATUS_KEYS.IN_PROGRESS] && parseInt(releaseId) !== parseInt(release?.id),
      [APP_VIEWS.RELEASE_DETAIL]:
        !releaseStatus[API_STATUS_KEYS.IN_PROGRESS] && parseInt(releaseId) !== parseInt(release?.id),
    };

    if (alwaysUpdateWhen[view] || staleViews.includes(view)) {
      switch (view) {
        case APP_VIEWS.RELEASE_CREATE:
          if (releaseId && parseInt(releaseId) !== release?.id) {
            // TODO: set to null first?
            getReleaseMetadata(releaseId);
          }
          break;
        case APP_VIEWS.RELEASE_DETAIL:
          if (releaseId) {
            setRelease(null);
          }
          break;
        default:
          break;
      }

      if (includes(staleViews, view)) {
        setViewAsFresh(view);
      }
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, staleViews, releaseId]);

  useEffect(() => {
    if (!releaseId) {
      setRelease(null);
    }
  }, [releaseId]);

  useEffect(() => {
    if (dbUser) {
      getPaymentAccountByUser(dbUser.id);

      window.heap && window.heap.identify(dbUser.email);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser]);

  useEffect(() => {
    if (dbUser && !userGroupStatus[API_STATUS_KEYS.IN_PROGRESS] && userGroupStatus[API_STATUS_KEYS.COMPLETE]) {
      if (dbUser.system_admin) {
        updateUserPermissions({ role: USER_ROLES.SYSTEM_ADMIN });
      } else if (selectedUserGroup) {
        updateUserPermissions({ role: USER_ROLES.LABEL_MEMBER });
      } else {
        updateUserPermissions({ role: USER_ROLES.BASIC_USER });
      }
    }
  }, [dbUser, selectedUserGroup, userGroupStatus, dbUserStatus]);

  useEffect(() => {
    if (
      dbUser?.id &&
      !isEmpty(userPaymentAccounts) &&
      !Object.prototype.hasOwnProperty.call(userPermissions.attributes, 'isPayee')
    ) {
      updateUserPermissions({
        ...userPermissions,
        attributes: {
          ...userPermissions.attributes,
          isPayee: !isEmpty(userPaymentAccounts[dbUser.id]),
        },
      });
    }

    const loaded = userAttributes.reduce(
      (a, v) => a && Object.prototype.hasOwnProperty.call(userPermissions.attributes, v),
      true
    );

    if (loaded && !allUserAttributesLoaded) {
      setAllUserAttributesLoaded(true);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser, userPaymentAccounts, userPaymentAccountStatus, userPermissions]);

  const updateUserPermissions = data => setUserPermissions(prevState => ({ ...prevState, ...data }));

  // View Methods

  const setViewAsFresh = view => setStaleViews(prevState => without(prevState, view));

  // Data Methods

  const getAuthedUser = async () => {
    try {
      const res = dbUser;

      setUserGroupStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
      const userGroups = [res.currentGroup];
      setUserGroups(userGroups);
      setSelectedUserGroup(get(userGroups, 0, null));

      setDbUserStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
      setUserGroupStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    } catch (err) {
      console.error(err);
      setUserGroupStatus(buildStatus(err.message));
    }
  };

  const getDSPs = async () => {
    setDspsStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

    let results = await DSP.getAll();

    if (!results) {
      setDspsStatus(buildStatus(ERRORS.DSPS.DATA_PROBLEM));
    } else {
      results = sortBy(results.slice(), 'name');

      setDsps(results);
      setDspsStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    }
  };

  const startReleaseSpinner = () => {
    updateStatusRelease('all', null, API_STATUS_KEYS.IN_PROGRESS);
  };
  const stopReleaseSpinner = () => {
    updateStatusRelease('all', null, API_STATUS_KEYS.COMPLETE);
  };

  const getReleaseMetadata = async (releaseId, allData = true) => {
    updateStatusRelease('all', null, API_STATUS_KEYS.IN_PROGRESS);
    updateStatusRelease('release', null, API_STATUS_KEYS.IN_PROGRESS);
    let releaseData = await Release.get(releaseId);

    if (!releaseData) {
      updateStatusRelease('all', null, API_STATUS_KEYS.COMPLETE);
      updateStatusRelease('release', null, ERRORS.RELEASE.NOT_FOUND);
      return;
    }
    updateStatusRelease('release', null, API_STATUS_KEYS.COMPLETE);

    if (releaseData && allData) {
      updateStatusRelease('sets', null, API_STATUS_KEYS.IN_PROGRESS);
      const sets = await Release.sets(releaseId);
      updateStatusRelease('sets', null, API_STATUS_KEYS.COMPLETE);
      if (!sets) {
        updateStatusRelease('sets', null, ERRORS.SETS.NOT_FOUND);
      }
      if (sets) {
        releaseData.sets = sets;
        for (let i = 0; i < sets.length; i++) {
          updateStatusRelease('set_tracks', null, API_STATUS_KEYS.IN_PROGRESS);
          let setTracks = await Release.tracksBySet(releaseId, sets[i].id);
          if (!setTracks) {
            updateStatusRelease('set_tracks', null, ERRORS.SET_TRACKS.NOT_FOUND);
          } else {
            updateStatusRelease('set_tracks', null, API_STATUS_KEYS.COMPLETE);

            // Intentionally load tracks in parallel to avoid a very slow create-release experience
            // where each track is downloaded one after another.
            await Promise.allSettled(
              setTracks.map(async (t, index) => {
                const trackId = t.asset_track_id;

                updateStatusRelease('track', trackId, API_STATUS_KEYS.IN_PROGRESS);

                let newTrackData = await Track.get(trackId);

                if (!newTrackData) {
                  updateStatusRelease('track', trackId, ERRORS.TRACK.NOT_FOUND);
                } else {
                  updateStatusRelease('track', trackId, API_STATUS_KEYS.COMPLETE);
                  setTracks[index] = { ...t, ...newTrackData };
                }
              })
            );

            setTracks = setTracks.sort((a, b) => (a.sequence > b.sequence ? 1 : -1));
          }
          releaseData.sets[i].tracks = setTracks || [];
        }
      }
    }
    setRelease(releaseData);
    updateStatusRelease('all', null, API_STATUS_KEYS.COMPLETE);
  };

  // - DEPRECATE THIS WHOLE FILE PLEASE

  const getPaymentAccountByUser = async userId => {
    try {
      setUserPaymentAccountsStatus(prevState => ({
        ...prevState,
        [userId]: buildStatus(API_STATUS_KEYS.IN_PROGRESS),
      }));
      const res = await User.getPaymentAccount(userId);

      setUserPaymentAccounts(prevState => ({
        ...prevState,
        [userId]: res || {},
      }));
      setUserPaymentAccountsStatus(prevState => ({
        ...prevState,
        [userId]: buildStatus(API_STATUS_KEYS.COMPLETE),
      }));
    } catch (err) {
      console.error(err);
      setUserPaymentAccountsStatus(prevState => ({
        ...prevState,
        [userId]: buildStatus(err.message),
      }));
    }
  };

  const addGroupImageUrl = (id, size, url, type) =>
    setFileUrls(prevState => {
      let newState = { ...prevState };
      if (!newState.group) newState.group = {};
      if (!newState.group[id]) newState.group[id] = {};
      if (!newState.group[id][type]) newState.group[id][type] = {};
      newState.group[id][type][size] = url;
      return newState;
    });

  const addReleaseImageUrl = (releaseId, setId, artworkIndex, size, url) => {
    setFileUrls(prevState => {
      let newState = { ...prevState };
      if (!newState['release']) newState['release'] = {};
      if (!newState['release'][releaseId]) newState['release'][releaseId] = {};
      if (!newState['release'][releaseId][setId]) newState['release'][releaseId][setId] = {};
      if (!newState['release'][releaseId][setId][artworkIndex])
        newState['release'][releaseId][setId][artworkIndex] = {};
      newState['release'][releaseId][setId][artworkIndex][size] = url;
      return newState;
    });

    setReleaseUrlStatusHandler('release', releaseId, setId, 0, size, buildStatus(API_STATUS_KEYS.COMPLETE));
  };

  const setGroupUrlStatusHandler = (entity, id, size, status, type) =>
    setFileUrlStatus(prevState => {
      const newState = { ...prevState };
      if (!newState[entity]) newState[entity] = {};
      if (!newState[entity][id]) newState[entity][id] = {};
      if (!newState[entity][id][type]) newState[entity][id][type] = {};
      newState[entity][id][type][size] = status;
      return newState;
    });

  // Art Files

  // e.g. getFileUrlStatus('release', 123, 'defaultArtwork', 0, '720x720')
  const getFileUrlStatus = (...args) => {
    let value = fileUrlStatus;

    for (let i = 0; i < 5; i++) {
      value = value[args[i]];
      if (!value) return null;
    }

    return value;
  };

  const setReleaseUrlStatusHandler = (entity, id, id2, id3, size, status) =>
    setFileUrlStatus(prevState => {
      return {
        ...prevState,
        [entity]: {
          ...prevState?.[entity],
          [id]: {
            ...prevState?.[entity]?.[id],
            [id2]: {
              ...prevState?.[entity]?.[id]?.[id2],
              [id3]: {
                ...prevState[entity]?.[id]?.[id2]?.[id3],
                [size]: status,
              },
            },
          },
        },
      };
    });

  const loadFile = async (entity, type, size, { releaseId, setId, groupId, trackId, artworkIndex = 0 }) => {
    let res, url;
    const errorMsg = `${type} not found`;

    switch (type) {
      case 'logo':
        setGroupUrlStatusHandler(entity, groupId, size, buildStatus(API_STATUS_KEYS.IN_PROGRESS), type);

        url = defaultLogo;

        addGroupImageUrl(groupId, size, url, type);
        setGroupUrlStatusHandler(entity, groupId, size, buildStatus(API_STATUS_KEYS.COMPLETE), type);
        break;

      case 'coverArt':
        setReleaseUrlStatusHandler(
          entity,
          releaseId,
          setId,
          artworkIndex,
          size,
          buildStatus(API_STATUS_KEYS.IN_PROGRESS)
        );
        res = await Release.artwork(releaseId, setId, 0, size);

        if (!res) {
          setReleaseUrlStatusHandler(entity, releaseId, setId, artworkIndex, size, buildStatus(errorMsg));
          return;
        } else {
          const blob = new Blob([res]);
          url = window.URL.createObjectURL(blob);
          addReleaseImageUrl(releaseId, setId, artworkIndex, size, url);
        }
        break;

      case 'defaultArtwork':
        setReleaseUrlStatusHandler(
          entity,
          releaseId,
          'defaultArtwork',
          artworkIndex,
          size,
          buildStatus(API_STATUS_KEYS.IN_PROGRESS)
        );
        res = await Release.defaultArtwork(releaseId, size);

        if (!res) {
          setReleaseUrlStatusHandler(entity, releaseId, 'defaultArtwork', artworkIndex, size, buildStatus(errorMsg));
          return;
        } else {
          const blob = new Blob([res]);
          url = window.URL.createObjectURL(blob);
          addReleaseImageUrl(releaseId, 'defaultArtwork', artworkIndex, size, url);
        }
        break;

      default:
        break;
    }
  };

  // Data Update Methods

  const updateUserData = async (userId, values) => {
    try {
      setDbUserStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
      const res = await User.updateUser(userId, values);

      setDbUser(res);
      setDbUserStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    } catch (err) {
      console.error(err);
      setDbUserStatus(buildStatus(err.message));
    }
  };

  const updateStatusRelease = (node, id, newStatus) => {
    const newStatusObj = buildStatus(newStatus);

    switch (node) {
      case 'all':
        setReleaseStatus(prevState => ({ ...prevState, all: newStatusObj }));
        break;
      case 'release':
        setReleaseStatus(prevState => ({ ...prevState, release: newStatusObj }));
        break;
      case 'sets':
        setReleaseStatus(prevState => ({
          ...prevState,
          sets: { ...prevState.sets, ...newStatusObj },
        }));
        break;
      case 'set_tracks':
        setReleaseStatus(prevState => ({
          ...prevState,
          sets: {
            ...prevState.sets,
            tracks: { ...prevState.sets.tracks, ...newStatusObj },
          },
        }));
        break;
      case 'track':
        setReleaseStatus(prevState => ({
          ...prevState,
          sets: {
            ...prevState.sets,
            tracks: { ...prevState.sets.tracks, [id]: newStatusObj },
          },
        }));
        break;
      default:
        break;
    }
  };

  const patchReleaseMetadata = metadata => setRelease(prevState => ({ ...prevState, ...metadata }));

  return (
    <GlobalDataContext.Provider
      value={{
        dbUser,
        dbUserStatus,
        userPermissions,
        allUserAttributesLoaded,
        getAuthedUser,
        startReleaseSpinner,
        stopReleaseSpinner,
        userGroups,
        userGroupStatus,
        dsps,
        getDSPs,
        dspsStatus,
        addReleaseImageUrl,
        selectedUserGroup,
        release,
        setRelease,
        setReleaseId,
        patchReleaseMetadata,
        releaseStatus,
        view,
        setView,
        loadFile,
        fileUrls,
        fileUrlStatus,
        getFileUrlStatus,
        updateUserData,
      }}>
      {children}
    </GlobalDataContext.Provider>
  );
};
