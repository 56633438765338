import React from 'react';
import styled from '@emotion/styled/macro';
import Microtip from 'microtip-react';

const Wrapper = styled.div`
  display: inline-flex;
  font-family: ${props => props.theme.fonts.families.text};
  font-size: ${props => props.theme.fonts.sizes.reg};
  letter-spacing: normal;
  margin-left: 0.5rem;

  data {
    display: flex;
  }

  [role~='tooltip'] {
    z-index: 100;
  }

  // the :before element is the triangle ▼
  [role~='tooltip']:before {
    // in order to override styles from the package without providing multiple different unique selectors we need to use !important
    margin-bottom: 2.5px !important;
    z-index: 100;
  }
  [role~='tooltip'][data-microtip-position='top'] {
    :before {
      transform: translate3d(-55%, 5px, 0);
    }

    :hover:before {
      transform: translate3d(-55%, 1px, 0);
    }
  }

  [role~='tooltip'][data-microtip-position='left'],
  [role~='tooltip'][data-microtip-position='right'] {
  }

  // after element is the rectangle text box
  [role~='tooltip']:after {
    margin-bottom: 2.5px !important;
    z-index: 100;
  }
  [role~='tooltip'][data-microtip-position='top'] {
    :after {
      transform: translate3d(-50%, 0, 0);
      width: max-content;
      max-width: 320px;
      white-space: pre-wrap;
      line-height: 1rem;
      text-align: center;
    }

    :hover:after {
      transform: translate3d(-50%, -5px, 0);
    }
  }

  ${props =>
    props.width &&
    `
    [aria-label][role~="tooltip"]::after {
      white-space: normal;
      width: ${props.width};
    }
  `}// Uncomment to debug microtip-react
  //[aria-label][role~="tooltip"]::before,
  //[aria-label][role~="tooltip"]::after {
  //  opacity: 1 !important;
  //}
`;

export default function Tooltip({ children, className, text, width, ...props }) {
  return text ? (
    <Wrapper className={className} width={width}>
      <Microtip label={text} {...props}>
        {children}
      </Microtip>
    </Wrapper>
  ) : (
    children
  );
}

Tooltip.defaultProps = {
  width: null,
};
