import { useQuery, useMutation } from 'react-query';
import {
  getMarketingLinks,
  getMarketingLinksCreatedThisMonth,
  deleteMarketingLink,
  createMarketingLink,
  updateMarketingLink,
  searchTracksAndReleases,
  getPresignedUrl,
  getTopAnalytics,
  getGeneralAnalytics,
} from '../utils/API/marketing-links';

export function useGetMarketingLinks(params) {
  return useQuery([params.page_index], () => getMarketingLinks(params));
}

export function useGetLinksCreatedThisMonth() {
  return useQuery(['links-created-this-month'], () => getMarketingLinksCreatedThisMonth());
}

//options should include onSuccess
export function useDeleteMarketingLink(options) {
  return useMutation(id => deleteMarketingLink(id), options);
}

export function useCreateMarketingLink(options) {
  return useMutation(body => createMarketingLink(body), options);
}

export function useUpdateMarketingLink(options) {
  return useMutation(({ id, body }) => updateMarketingLink(id, body), options);
}

export function useSearchTracksAndReleases(debouncedSearchTerm, dsps) {
  return useQuery(['search-tracks-releases', debouncedSearchTerm], () =>
    searchTracksAndReleases(debouncedSearchTerm, dsps)
  );
}

export function useGetPresignedUrl(key) {
  return useQuery([key], () => getPresignedUrl(key));
}

export function useGetGeneralAnalytics(id, options) {
  return useQuery(['general-analytics', id], () => getGeneralAnalytics(id), options);
}

export function useGetTopCities(id, options) {
  return useQuery(['top-cities', id], () => getTopAnalytics(id, 'city'), options);
}

export function useGetTopCountries(id, options) {
  return useQuery(['top-countries', id], () => getTopAnalytics(id, 'country'), options);
}

export function useGetTopDsps(id, options) {
  return useQuery(['top-dsps', id], () => getTopAnalytics(id, 'dsp'), options);
}
