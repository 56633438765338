import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text, useTheme } from '@chakra-ui/react';
import { RiArrowLeftLine as GoBackIcon } from 'react-icons/ri';
import ManagePageLayout from './manage-page-layout';

export default memo(function Container({ hideLeftRail, children }) {
  const theme = useTheme();

  const LeftRailComponent = children.find(child => child.type?.name === 'LeftRailContainer');
  const BodyComponent = children.filter(child => child.type?.name === 'BodyContainer');

  if (!LeftRailComponent || !BodyComponent) {
    const message = 'The component LeftRailContainer and BodyContainer are required as children.';
    if (window.APP.RUNTIME === 'local') {
      throw Error(message);
    } else {
      console.warn(message);
    }
  }

  return (
    <Flex height={'100vh'} width={'100%'}>
      {!hideLeftRail ? (
        <Box
          height={'100vh'}
          width={'20rem'}
          backgroundColor={theme.colors.black90}
          paddingLeft={'6rem'}
          paddingRight={'2rem'}>
          {LeftRailComponent}
        </Box>
      ) : (
        ''
      )}
      <Box flexGrow={'1'} paddingLeft={'2rem'} paddingTop={'2rem'} overflowY={'scroll'}>
        <Box maxWidth={hideLeftRail ? '100%' : '64rem'}>{BodyComponent}</Box>
      </Box>
    </Flex>
  );
});

export const BodyContainer = memo(function BodyContainer({ stateManagerCollection, fullWidth = false, children }) {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');

  const canSubmit = stateManagerCollection.filter(stateManager => !stateManager.allValid).length === 0; //all states are valid

  const { title, description, showTooltip, stateData = {} } = stateManagerCollection?.activeManager || {};
  const nextManager = stateManagerCollection?.nextManager || {};

  let canContinue = !!nextManager.name;
  if (nextManager.name === 'ClipCreate') {
    //We can't continue to clipCreate if we have no audio file to make clips from!
    canContinue = stateData.audio_source?.name !== undefined;
  } else if (nextManager.name === 'ClipReview') {
    canContinue = canSubmit;
  }

  const continueTo = nextManager.path ? `${nextManager.path}?id=${id}` : undefined;

  return (
    <ManagePageLayout
      fullWidth={fullWidth}
      title={title}
      description={description}
      showTooltip={showTooltip}
      continueTo={continueTo}
      canContinue={canContinue}>
      {children}
    </ManagePageLayout>
  );
});

//This is because minification destroys the names otherwise
BodyContainer.name = 'BodyContainer';

export const LeftRailContainer = memo(function LeftRailContainer({ title, onExit, children }) {
  const theme = useTheme();

  if (!onExit) throw Error('onExit prop is required.');

  return (
    <>
      <Button leftIcon={<GoBackIcon />} variant={'transparent'} color={theme.colors.white} onClick={onExit}>
        Exit
      </Button>
      <Heading
        as="h2"
        color={theme.colors.white}
        fontFamily="Neue Machina"
        fontSize={'1.5rem'}
        marginTop={'1.2rem'}
        fontWeight="700">
        {title}
      </Heading>
      <Box>{children}</Box>
    </>
  );
});

//This is because minification destroys the names otherwise
LeftRailContainer.name = 'LeftRailContainer';

export const SaveController = memo(function SaveController({
  onSaveDraft,
  canSaveDrafts,
  isDraftSaved,
  canSubmit,
  lastSaved,
  onReview,
}) {
  const [lastSavedDisplay, setLastSavedDisplay] = useState(displayTimeSinceLastSave(lastSaved));

  useEffect(() => {
    const interval = setInterval(() => setLastSavedDisplay(displayTimeSinceLastSave(lastSaved)), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastSaved]);

  return (
    <Box position={'relative'} bottom={'-8rem'} width={'100%'}>
      <Flex width={'100%'} justifyContent={'space-between'}>
        <Button variant={'smallestDarkWhiteText'} onClick={onSaveDraft} display={canSaveDrafts ? '' : 'none'}>
          Save Draft
        </Button>
        <Button
          variant={'smallestDarkWhiteText'}
          onClick={onReview}
          disabled={!canSubmit}
          flexGrow={canSaveDrafts ? 0 : 1}>
          Review + Submit
        </Button>
      </Flex>
      <Text visibility={canSaveDrafts ? 'visible' : 'hidden'} fontSize={'0.75rem'} paddingTop={'0.5rem'}>
        {isDraftSaved ? 'Current progress saved' : lastSavedDisplay}
      </Text>
    </Box>
  );
});

function displayTimeSinceLastSave(lastSaved) {
  if (!lastSaved) return 'Current progress not saved';

  const minutesSinceLastSave = Math.floor((new Date().getTime() - lastSaved.getTime()) / 1000 / 60);

  if (minutesSinceLastSave < 60)
    return `saved ${minutesSinceLastSave} minute${minutesSinceLastSave !== 1 ? 's' : ''} ago`;

  const hoursSinceLastSave = Math.floor(minutesSinceLastSave / 60);

  if (minutesSinceLastSave < 1440)
    return `saved ${hoursSinceLastSave} hour${hoursSinceLastSave !== 1 ? 's' : ''} ${Math.floor(
      minutesSinceLastSave % 60
    )} minutes ago`;

  return 'saved more than a day ago';
}
