import { MEDIA_TYPES } from '../../constants/constants';
import Tooltip from '../../components/Tooltip/tooltip';
import Icon from '../../components/Icon/icon';
import React from 'react';
import styled from '@emotion/styled/macro';
import MediaPlayer from '../../components/MediaPlayer/media-player';
import { MetadataLabel, VerticalCenter } from '../../styles';
import { useTheme } from '@chakra-ui/react';

const MediaPlayerWrapper = styled(MediaPlayer)`
  align-items: center;
  display: flex;
`;

const KeyInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem 0 1rem;
  width: 100%;

  ${props =>
    !props.editable &&
    `
    padding-right: 1rem;
  `}
`;

const TrackHeaderInfo = styled.div`
  flex-grow: 1;
`;

const TopLine = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`;
const TitleLine = styled.strong`
  color: ${props => props.theme.colors.brand.textWhite};
  font-size: ${props => props.theme.fonts.sizes.reg};
  margin-right: 1.5rem;
`;

const TitleInfo = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.brand.textWhite};
  display: flex;

  span {
    font-size: ${props => props.theme.fonts.sizes.xsm};
  }
`;

const InstantGratWrapper = styled.div``;
const SubLine = styled.div``;

const TrackNonEditableContent = styled(VerticalCenter)`
  flex-grow: 1;
  justify-content: flex-end;
`;

const ISRCLabel = styled(MetadataLabel)`
  color: ${props => props.theme.colors.brand.layer4};
  margin-right: 1.5rem;
`;

function TrackHeader({ trackId, trackFormData, editable, SplitControls = false, formMethods }) {
  const formType = formMethods.watch('resource_type');
  const resourceType = trackFormData.resource_type || formType;
  const theme = useTheme();

  return (
    <>
      <MediaPlayerWrapper
        buttonPropsOverwite={{ color: theme.colors.cream100 }}
        trackId={trackId}
        type={resourceType === 'Video' ? MEDIA_TYPES.VIDEO : MEDIA_TYPES.AUDIO}
      />
      <KeyInfo editable={editable}>
        <TrackHeaderInfo>
          <TopLine>
            <TitleLine>{trackFormData?.title || '[No title added]'}</TitleLine>
            {!editable && (
              <TitleInfo>
                <span>{trackFormData.duration || ''}</span>
                {!!trackFormData.instant_grat_date && (
                  <InstantGratWrapper>
                    <Tooltip text="Instant grat track" position="right">
                      <Icon type="instantGrat" />
                    </Tooltip>
                  </InstantGratWrapper>
                )}
              </TitleInfo>
            )}
          </TopLine>
          <SubLine>{trackFormData?.artists?.length ? trackFormData.artists[0].full_name : '[No artist added]'}</SubLine>
        </TrackHeaderInfo>
        {!editable && !!SplitControls && (
          <TrackNonEditableContent>
            <ISRCLabel>{trackFormData.isrc_code}</ISRCLabel>
            {SplitControls}
          </TrackNonEditableContent>
        )}
      </KeyInfo>
      <br />
    </>
  );
}

export default TrackHeader;
