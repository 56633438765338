import React from 'react';
import { FiArrowRight as ArrowRight } from 'react-icons/fi';
import { Box, Text, IconButton, useTheme, Flex } from '@chakra-ui/react';
import icons from '../../utils/icons';

const LockedChip = ({ text }) => {
  return (
    <Flex
      bgColor={'black10'}
      color={'black50'}
      height={'1.25rem'}
      padding={'0 0.5rem'}
      fontSize={'0.75rem'}
      borderRadius={'1rem'}
      align={'center'}
      fontWeight={700}
      marginBottom="0.25rem"
      marginLeft={'0.5rem'}>
      <icons.unlock />
      <Text ml={'0.25rem'}>{text}</Text>
    </Flex>
  );
};

/**
 * @param {object} props
 * @param {string} props.title
 * @param {React.MouseEventHandler<HTMLButtonElement>} props.onClick
 * @param {JSX.Element} props.RightComponent - optional JSX component to render on the right side of the box
 * @param {string} [props.isLockedText] - acts as a boolean, but also provides the text to display
 */
export default function NextActionBox({ title, text, onClick, RightComponent, isLockedText, iconColor }) {
  const theme = useTheme();
  RightComponent =
    RightComponent ||
    function ArrowComponent() {
      return (
        <IconButton
          variant="transparent"
          icon={<ArrowRight size={'2rem'} color={iconColor ? iconColor : 'red'} />}
          onClick={onClick}
        />
      );
    };
  return (
    <Box
      cursor={'pointer'}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'space-between'}
      border={'1px solid'}
      borderRadius={'0.35rem'}
      padding={'1rem'}
      mt={'1rem'}
      backgroundColor={'#fff'}
      width={'100%'}
      borderColor="black20"
      onClick={onClick}>
      <Box>
        <Flex align="center">
          <Text as="h4" color={theme.colors.black80} fontWeight="bold" fontSize="1.15rem">
            {title}
          </Text>
          {isLockedText ? <LockedChip text={isLockedText} /> : null}
        </Flex>
        <Text fontWeight={500}>{text}</Text>
      </Box>
      <Flex width={'3.5rem'} justify="flex-end">
        <RightComponent />
      </Flex>
    </Box>
  );
}
