import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const ReleaseSets = () => {
  const updateTracks = (id, setId, tracks) =>
    AuthAxios.patch(`${DataApiUrl}/releases/${id}/sets/${setId}/tracks`, {
      tracks: tracks,
    })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  return {
    updateTracks,
  };
};

export default ReleaseSets;
