import { useState, useCallback, useRef } from 'react';
import { getUserThreads, getThreadMessages, deleteThread } from '../utils/API/co-manager';
import { useQuery, useMutation } from 'react-query';

//TODO move into generic data client file. Created because react-query useInfiniteQuery do not
//have the additive functionality that I require. They instead limit results per page in a way
//such that you have a window view into the result set.
const useModelAdditivePageQuery = (options = {}) => {
  const {
    reactQueryArgs = {},
    pageSize,
    queryKey,
    queryFunc,
    getModelIdFunc = a => a.id,
    modelSortFunc = (a, b) => getModelIdFunc(b) - getModelIdFunc(a),
  } = options;

  //Tracks the max page that has been fetched since the latest reset. loadNextPage always refers to this to keep it always adding onto the current pages.
  const maxPageFetchedRef = useRef(0);
  const [pageArgs, setPageArgs] = useState({ pageSize, page: 0, maxPage: 0, hasMoreData: false });
  const [modelData, setModelData] = useState({ models: [], total: 0 });

  const onQuerySuccess = useCallback(
    ({ results, total }) => {
      //Merge result models with the current models in such a way that there are no dupes
      //and favor the result models so that data is updated if dupe data happened to be retrieved.
      const modelsToSet = [...modelData.models, ...results].reduce((dedupeAcc, currentModel) => {
        dedupeAcc[getModelIdFunc(currentModel)] = currentModel;
        return dedupeAcc;
      }, {});
      const sortedModels = Object.values(modelsToSet).sort(modelSortFunc);
      setModelData({ models: sortedModels, total });

      const maxPageFromQuery = Math.floor(total / pageArgs.pageSize);
      setPageArgs({
        ...pageArgs,
        maxPage: maxPageFromQuery,
        hasMoreData: maxPageFetchedRef.current < maxPageFromQuery,
      });
    },
    [getModelIdFunc, setModelData, setPageArgs]
  );

  const { isFetching, error, isError, refetch } = useQuery(
    [...queryKey, pageArgs.page],
    () => {
      return queryFunc(pageArgs.page, pageArgs.pageSize);
    },
    {
      ...reactQueryArgs,
      onSuccess: onQuerySuccess,
    }
  );

  const loadNextPage = useCallback(() => {
    maxPageFetchedRef.current++;
    setPageArgs(args => ({ ...args, page: maxPageFetchedRef.current }));
    refetch();
  }, [setPageArgs]);

  const removeLocalModel = useCallback(
    modelId => {
      setModelData(data => {
        const remainingModels = data.models.filter(m => getModelIdFunc(m) !== modelId);
        return { models: remainingModels, total: data.total - 1 };
      });
    },
    [setModelData, getModelIdFunc]
  );

  const fetchPage = useCallback(
    page => {
      setPageArgs(args => ({ ...args, page }));
      refetch();
    },
    [refetch, setPageArgs]
  );

  const reset = useCallback(() => {
    maxPageFetchedRef.current = 0;
    setModelData({ models: [], total: 0 });
    setPageArgs({ pageSize, page: 0, maxPage: 0, hasMoreData: false });
    refetch();
  }, [setModelData, setPageArgs, pageSize]);

  return {
    fetchPage,
    modelData,
    isProcessing: isFetching,
    error,
    isError,
    pageArgs,
    removeLocalModel,
    loadNextPage,
    reset,
  };
};

export const useCoManagerThreadAdditivePageQuery = (options = {}) => {
  const { pageSize = 20, userId, reactQueryArgs } = options;

  return useModelAdditivePageQuery({
    reactQueryArgs,
    pageSize,
    queryKey: ['co-manager-user-threads', userId],
    queryFunc: (page, pageSize) => getUserThreads({ page, pageSize }),
    getModelIdFunc: m => m.chat_bot_thread_id,
    modelSortFunc: (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
  });
};

export const useCoManagerMessageAdditivePageQuery = (options = {}) => {
  const { pageSize = 20, threadId, reactQueryArgs } = options;

  return useModelAdditivePageQuery({
    reactQueryArgs,
    pageSize,
    queryKey: ['co-manager-thread-messages', threadId],
    queryFunc: (page, pageSize) =>
      threadId ? getThreadMessages({ threadId, page, pageSize }) : { results: [], total: 0 },
    modelSortFunc: (a, b) => a.chat_bot_thread_message_id - b.chat_bot_thread_message_id,
    getModelIdFunc: m => m.chat_bot_thread_message_id,
  });
};

export const useCoManagerDeleteThreadMutation = mutateArgs => {
  return useMutation(threadId => deleteThread(threadId), mutateArgs);
};
