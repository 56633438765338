import React, { useState } from 'react';
import { Box, Text, Flex, List, ListItem } from '@chakra-ui/react';
import Button from '../../components/Button/button';
import asModal from 'features/Modal/as-modal';

function VideoQualityConfirmation({ onClose, emptyTrackForm, ...props }) {
  const [doesNotConform, setDoesNotConform] = useState(false);
  const variant = props?.variant || 'dark';

  function handleNoClicked() {
    setDoesNotConform(true);
    if (typeof emptyTrackForm === 'function') emptyTrackForm();
  }

  if (doesNotConform)
    return (
      <Box pb={'2rem'} variant={variant}>
        If your video does not meet the standards for video distribution, you can still upload your video directly to
        your Youtube Channel to ensure it is seen by your fans!
      </Box>
    );

  return (
    <Box pb={'2rem'} variant={variant}>
      <Text>
        Some of the DSPs that we distribute our videos to require what would be categorized as “high quality” music
        videos. This means:
        <List px={'1rem'} listStyleType={'disc'}>
          <ListItem>No lyrics videos </ListItem>
          <ListItem>No visualizer videos</ListItem>
          <ListItem>No stationary image videos</ListItem>
          <ListItem>No advertisements or promos</ListItem>
          <ListItem>No partial videos</ListItem>
          <ListItem>No medley videos</ListItem>
        </List>
        <br />
        Do you confirm your video is not one of the video types listed above?
      </Text>
      <Flex justifyContent={'end'} pt={'1.5rem'}>
        <Box px={'1rem'}>
          <Button naked onClick={handleNoClicked} text="No" />
        </Box>
        <Button primary onClick={onClose} text="Yes" />
      </Flex>
    </Box>
  );
}

export default asModal(VideoQualityConfirmation);
