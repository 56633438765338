import React, { useCallback } from 'react';
import { Route } from 'react-router-dom';
import { useAuth0 } from 'utils/auth0';
import AcceptTermsContainer from 'components/AcceptTerms/accept-terms-container';
import URLS from 'constants/URLS.gen.json';
import useCurrentUser from '../../data-client/use-current-user';

const PrivateRoute = ({ component: Component, render: Render, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  const [, , currentUserQuery] = useCurrentUser();
  const { data: dbUser, isFetching } = currentUserQuery;

  if (!loading && !isAuthenticated) loginWithRedirect({ appState: { targetUrl: path } });

  const render = useCallback(
    props =>
      isAuthenticated === false ? null : Render ? (
        <Render isPrivate={true} {...props} />
      ) : (
        <Component isPrivate={true} {...props} />
      ),
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path]
  );

  const needsAccepting = dbUser
    ? Object.keys(URLS.TOS).filter(term => term !== 'privacy' && dbUser[term] !== URLS.TOS[term])
    : [];

  return (
    <>
      {dbUser && needsAccepting.length && !isFetching ? <AcceptTermsContainer needsAccepting={needsAccepting} /> : null}
      <Route path={path} render={render} {...rest} />
    </>
  );
};

export default PrivateRoute;
