import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/private-route';
import TypeSelector from './manage-type-selector';
import ClipRouter from './clip/clip-route-container';
import ManageNewClipTypeSelector from './manage-new-clip-type-selector';
import ManageNewClipFromPreviousRelease from './manage-new-clip-from-previous-release/manage-new-clip-from-previous-release';
export default function ManageRouter({ isPrivate }) {
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  return (
    <>
      <RouteComponent path="/manage/" exact>
        <TypeSelector />
      </RouteComponent>

      <Switch>
        <RouteComponent path="/manage/clip/new" exact>
          <ManageNewClipTypeSelector />
        </RouteComponent>
        <RouteComponent path="/manage/clip/new-from-released" exact>
          <ManageNewClipFromPreviousRelease />
        </RouteComponent>
        <RouteComponent path="/manage/clip/">
          <ClipRouter />
        </RouteComponent>
      </Switch>

      <RouteComponent path="/manage/release/" render={() => <Redirect to="/releases/new" />} />
      <RouteComponent path="/manage/video/" render={() => <Redirect to="/releases/new" />} />
    </>
  );
}
