import React from 'react';
import { Box, Flex, Stat, StatArrow, Text } from '@chakra-ui/react';
import numeral from 'numeral';
import GlanceCard from './glance-card';
import { useAnalyticsContext } from './analytics-context';
import HEAP from '../constants/HEAP.gen.json';

function TotalStreams({ domainData }) {
  const {
    streams: { totalStreams },
    streamPercentageChange,
    dateRangeString,
    decorativeText,
    currentMetricsInterval,
  } = useAnalyticsContext();

  const timeRangeInDays = currentMetricsInterval.interval + 1;

  const getTimeRangePrefix = timeRangeInDays => {
    return { 7: 'WoW', 30: 'MoM' }[Number(timeRangeInDays)] || null;
  };

  return (
    <GlanceCard
      status={domainData.status}
      dateRangeString={dateRangeString}
      decorativeText={decorativeText}
      title="Total Streams"
      data-heap={HEAP.PUBLIC.ANALYTICS.TYPE.ANALYTICS_CARD_TOTAL_STREAMS}>
      <Flex height="100%" width="100%" direction="column" justify="flex-end">
        <Box color="black.100" fontSize="xxxlg" fontFamily="display" fontWeight="bold">
          {numeral(totalStreams).format('0,0')}
        </Box>
        {streamPercentageChange ? (
          <Box visibility="hidden">
            <Box>
              <Stat
                fontSize="xlg"
                fontWeight="bold"
                color={streamPercentageChange > 0 ? 'green.400' : 'red.400'}
                whiteSpace="nowrap">
                <StatArrow type={streamPercentageChange > 0 ? 'increase' : 'decrease'} />
                {numeral(Math.abs(streamPercentageChange)).format('0.00%')}
                <Text key={currentMetricsInterval.interval} display="inline" ml={1} fontSize="sm" color="gray.500">
                  {getTimeRangePrefix(timeRangeInDays)}
                </Text>
              </Stat>
            </Box>
          </Box>
        ) : null}
      </Flex>
    </GlanceCard>
  );
}

export default TotalStreams;
