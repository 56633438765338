import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import numeral from 'numeral';
import Icon from 'components/Icon/icon';
import { HoverIconWrapper, disableSelection } from '../styles';
import { DateTime } from 'luxon';

const Wrapper = styled.div`
  ${disableSelection}
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.colors.white};

  ${props =>
    props.disabled &&
    `
		opacity: 0.5;
	`}
`;
const CurrentValue = styled.div`
  padding: 0.5rem 0;
  font-size: ${props => props.theme.fonts.sizes.reg};
`;

const ArrowIcon = styled(Icon)`
  cursor: pointer;
  ${props =>
    props.disabled &&
    `
		opacity: 0;
		pointer-events: none;
	`}
`;

const ClickShield = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const HiddenInput = styled.input`
  display: none;
`;

const ColumnSeparator = styled.span`
  font-weight: 700;
  width: 0.25rem;
`;

const ValuePickerWrapper = styled.div`
  ${disableSelection}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0.5rem;
  font-weight: ${props => props.theme.fonts.weights.medium};
`;

const ValuePicker = ({ handleGoToPrevious, handleGoToNext, currentValue, style }) => (
  <ValuePickerWrapper style={style}>
    <HoverIconWrapper>
      <ArrowIcon type={'chevronUp'} onClick={handleGoToNext} />
    </HoverIconWrapper>
    <CurrentValue>{currentValue}</CurrentValue>
    <HoverIconWrapper>
      <ArrowIcon type={'chevronDown'} onClick={handleGoToPrevious} />
    </HoverIconWrapper>
  </ValuePickerWrapper>
);

const TimePicker = React.forwardRef(({ className, name, setValue, disabled, defaultValue, theme, ...props }, ref) => {
  const [time, setTime] = useState(null);

  const dateTimeString = time => time.toISOTime().split('.')[0].substring(0, 5);

  useEffect(() => {
    if (defaultValue === null || defaultValue === undefined || defaultValue === '') {
      return;
    }
    const value = DateTime.fromISO(defaultValue);
    setTime(value);
  }, [defaultValue]);

  useEffect(() => {
    if (time) {
      setValue(name, dateTimeString(time), { shouldDirty: true });
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const handleGoToPreviousHour = () => {
    const newTime = time ? time.minus({ hours: 1 }) : DateTime.fromObject({ hours: 23, minutes: 0 });
    setTime(newTime);
    dateTimeString(newTime);
  };

  const handleGoToNextHour = () => {
    const newTime = time ? time.plus({ hours: 1 }) : DateTime.fromObject({ hours: 1, minutes: 0 });
    setTime(newTime);
  };

  const handleGoToPreviousMinute = () => {
    const newTime = time ? time.minus({ minutes: 5 }) : DateTime.fromObject({ hours: 23, minutes: 55 });
    setTime(newTime);
  };
  const handleGoToNextMinute = () => {
    const newTime = time ? time.plus({ minutes: 5 }) : DateTime.fromObject({ hours: 0, minutes: 5 });
    setTime(newTime);
  };

  const handleToggleMeridian = () => {
    const newTime = time ? time.plus({ hours: 12 }) : DateTime.fromObject({ hours: 12, minutes: 0 });
    setTime(newTime);
  };

  return (
    <Wrapper className={className} disabled={disabled}>
      <ValuePicker
        disabled={disabled}
        handleGoToPrevious={handleGoToPreviousHour}
        handleGoToNext={handleGoToNextHour}
        currentValue={!time ? '--' : numeral(time.hour % 12 || 12).format('00')}
      />
      <ColumnSeparator>:</ColumnSeparator>
      <ValuePicker
        disabled={disabled}
        handleGoToPrevious={handleGoToPreviousMinute}
        handleGoToNext={handleGoToNextMinute}
        currentValue={!time ? '--' : numeral(time.minute).format('00')}
      />
      <ColumnSeparator />
      <ValuePicker
        disabled={disabled}
        handleGoToPrevious={handleToggleMeridian}
        handleGoToNext={handleToggleMeridian}
        currentValue={!time ? '--' : time.hour >= 12 ? 'PM' : 'AM'}
        style={{ paddingLeft: '1rem' }}
      />
      <HiddenInput name={name} ref={ref} type="time" {...props} />
      {disabled && <ClickShield />}
    </Wrapper>
  );
});

TimePicker.displayName = 'TimePicker';

TimePicker.defaultProps = {
  disabled: false,
  // defaultValue: '00:00'
};

export default withTheme(TimePicker);
