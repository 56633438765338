import React from 'react';
import Button from 'components/Button/button';
import TrackTable from 'components/TrackTable/track-table';

function ButtonColumn({ handleCopyToClipboard, title, artistName }) {
  function handleCopyToClipboardInternal() {
    if (handleCopyToClipboard && title && artistName) {
      handleCopyToClipboard({
        query: `artist:${artistName} upc:${title}`,
        types: ['track', 'artist'],
      });
    }
  }

  return <Button tertiary short icon="copy" text="Copy Link" onClick={handleCopyToClipboardInternal} />;
}

export default function Tracks({ tracks, handleCopyToClipboard }) {
  return <TrackTable tracks={tracks} handleCopyToClipboard={handleCopyToClipboard} buttonColumn={ButtonColumn} />;
}
