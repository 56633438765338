import { useToast } from '@chakra-ui/react';
import React from 'react';
import { useModals } from '../../utils/useModals';
import ReleaseStatusTrack from './release-status-track';
import { useReleaseStatusTrackContext } from './release-status-track-context';

const ReleaseStatusTrackContainer = ({ ...props }) => {
  const { parentRelease, trackData, saveSplits } = useReleaseStatusTrackContext();
  const { updateModalState, showModal, hideModal } = useModals();

  const toast = useToast();

  const confirmMultipleIsrcsWillBeEdited = async () => {
    // if forking away from existing splits into no splits, display no splits
    // if merging into existing splits from existing OR no splits, display existing splits
    const otherReleases = trackData?.all_releases_with_isrc.filter(r => r.id !== parentRelease.id);
    if (!otherReleases.length) {
      await handleSaveSplits();
      return;
    }

    showModal('confirmSplitChanges');
    updateModalState('confirmSplitChanges', {
      releases: otherReleases,
      onConfirm: async () => {
        try {
          hideModal('confirmSplitChanges');
          await handleSaveSplits();
        } catch (err) {
          console.error(err);
        }
      },
    });
  };

  const handleSaveSplits = () =>
    saveSplits({
      onSuccess: () => {
        toast({
          title: 'Splits Locked In',
          description: "We've locked in your splits for you.",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      },
      onError: error => {
        console.error(error);
        toast({
          title: 'Error Locking Splits',
          description: 'Something went wrong. Please try again later.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      },
    });

  return <ReleaseStatusTrack saveSplits={confirmMultipleIsrcsWillBeEdited} props={props} />;
};

export default ReleaseStatusTrackContainer;
