import React from 'react';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import Box from '../Box/Box';
import { Image, Heading, Icon, Text, Flex, useTheme } from '@chakra-ui/react';
import Tooltip from 'components/Tooltip/tooltip';
import {
  RiVidiconFill as VideoFilledIcon,
  RiScissorsLine as ClipIcon,
  RiMusic2Fill as MusicNoteIcon,
} from 'react-icons/ri';
import { EMPTY_RELEASE_TITLE } from 'constants/constants';
import FallbackCoverArt from '../../assets/light-theme-fallback-cover-art.png';
import { pluralize } from '../../utils/helpers';

const COVER_ART_SIZE = `10rem`;

export const Activator = ({ children, ...props }) => {
  return (
    <Box
      as={Link}
      alignItems="center"
      cursor="pointer"
      display="grid"
      gridGap="1rem"
      gridTemplateColumns={`${COVER_ART_SIZE} auto`}
      margin="calc(-1 * var(--negativespace))"
      overflow="hidden"
      {...props}>
      {children}
    </Box>
  );
};

const Artist = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      color={theme.colors.black60}
      fontSize={theme.fonts.sizes.sm}
      fontWeight={theme.fonts.weights.medium}
      marginBottom="0.5rem"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap">
      {children}
    </Box>
  );
};

const TrackStatus = ({ children }) => {
  const theme = useTheme();

  return (
    <Flex
      alignItem="center"
      color={theme.colors.black60}
      fontSize={theme.fonts.sizes.xxsm}
      fontWeight={theme.fonts.weights.medium}
      letterSpacing="2px"
      whiteSpace="nowrap"
      textTransform="uppercase">
      {children}
    </Flex>
  );
};

const TrackCount = ({ children }) => {
  const theme = useTheme();
  return (
    <Flex
      alignItems="center"
      color={theme.colors.black60}
      display="flex"
      fontWeight={theme.fonts.weights.medium}
      letterSpacing="1px">
      {children}
    </Flex>
  );
};

const TileCount = ({ children }) => {
  const theme = useTheme();
  return (
    <Flex marginRight="8px" alignItems="center" fontSize={theme.fonts.sizes.xxsm}>
      {children}
    </Flex>
  );
};

const ReleaseDetails = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between" minWidth="6rem">
      {children}
    </Box>
  );
};

const CatalogTile = ({
  coverArt,
  trackTitle,
  artistNames,
  releaseDate,
  dspPlatforms,
  to,
  audioTrackCount,
  videoTrackCount,
  clipTrackCount,
}) => {
  const formattedReleaseDate = releaseDate ? DateTime.fromISO(releaseDate).toFormat('D') : 'Invalid DateTime';
  const displayReleaseDate = formattedReleaseDate !== 'Invalid DateTime' ? formattedReleaseDate : '--/--/----';
  const normalizedDspPlatforms = dspPlatforms?.map(platform => platform.toUpperCase()) ?? [];

  const tracksAreExclusivelyClips = () => {
    //if there are any clipTrackCount, it is by nature of how we make clips exclusivly Clips
    if (Number(clipTrackCount) > 0) return true;
    !!normalizedDspPlatforms.length &&
      normalizedDspPlatforms.every(dspPlatform => {
        return dspPlatform === `TRILLER` || dspPlatform === `BYTEDANCE`;
      });
  };

  // count number of tracks of each type in the release
  const trackCounts = {
    audio: parseInt(audioTrackCount, 10),
    video: parseInt(videoTrackCount, 10),
    clip: parseInt(clipTrackCount || 0, 10),
  };

  // figure out which resource icons to show
  let showAudioIcon = !!trackCounts.audio && !tracksAreExclusivelyClips;
  const showClipIcon =
    Number(trackCounts.clip) > 0 ||
    normalizedDspPlatforms?.includes(`TRILLER`) ||
    normalizedDspPlatforms?.includes(`BYTEDANCE`); //this is keeping the old legacy clip releases as a clip release
  const showVideoIcon = !!trackCounts.video;

  // toggle the visibility of the music note icon for null states where nothing else would show
  if (!showClipIcon && !showVideoIcon) {
    showAudioIcon = true;
  }

  const constructTooltipCountMessage = () => {
    if (tracksAreExclusivelyClips()) {
      return `${trackCounts.audio || trackCounts.clip} Clip${trackCounts.audio || trackCounts.clip === 1 ? `` : `s`}`;
    } else if (showAudioIcon && showClipIcon && showVideoIcon) {
      return `${trackCounts.audio} Total Audio ${pluralize('Track', trackCounts.audio)} and ${pluralize(
        'Clip',
        trackCounts.audio
      )}, ${trackCounts.video} Video ${pluralize('Track', trackCounts.video)}`;
    } else if (showAudioIcon && showClipIcon) {
      return `${trackCounts.audio} Total Audio ${pluralize('Track', trackCounts.audio)} and ${pluralize(
        'Clip',
        trackCounts.audio
      )}`;
    } else if (showAudioIcon && showVideoIcon) {
      return `${trackCounts.audio} Audio ${pluralize('Track', trackCounts.audio)}, ${
        trackCounts.video
      } Video ${pluralize('Track', trackCounts.video)}`;
    } else if (showAudioIcon) {
      return `${trackCounts.audio} Audio ${pluralize('Track', trackCounts.audio)}`;
    } else if (showVideoIcon) {
      return `${trackCounts.video} Video ${pluralize('Track', trackCounts.video)}`;
    }
  };

  const tooltipCountMessage = constructTooltipCountMessage();

  const Counts = (
    <Tooltip text={tooltipCountMessage}>
      <Box display="flex" flexDirection="row">
        {(showAudioIcon || showClipIcon) && (
          <TileCount>
            {showAudioIcon && <Icon as={MusicNoteIcon} h="1rem" w="1rem" />}
            {showClipIcon && <Icon as={ClipIcon} h="1rem" w="1rem" />}
            <Text paddingLeft=".25rem">{trackCounts.audio || trackCounts.clip}</Text>
          </TileCount>
        )}
        {showVideoIcon && (
          <TileCount>
            <Icon as={VideoFilledIcon} h="1rem" w="1rem" />
            <Text paddingLeft=".25rem">{trackCounts.video}</Text>
          </TileCount>
        )}
      </Box>
    </Tooltip>
  );

  return (
    <Activator to={to}>
      {coverArt === 'NOT_FOUND' || !coverArt ? (
        <Image alt={`Placeholder art`} src={FallbackCoverArt} />
      ) : (
        <Image alt={`Cover art for ${trackTitle}`} src={coverArt} />
      )}
      <ReleaseDetails>
        <Box>
          <Heading as="h3" variant="h3" textOverflow="ellipsis" zIndex="5" lineClamp="2" position="relative">
            {[EMPTY_RELEASE_TITLE, null, undefined].includes(trackTitle) ? `Untitled` : trackTitle}
          </Heading>
          <Artist>{artistNames || `—`}</Artist>
        </Box>
        <Box>
          <TrackStatus>{`Release Date: ${displayReleaseDate}`}</TrackStatus>
          <TrackCount>{Counts}</TrackCount>
        </Box>
      </ReleaseDetails>
    </Activator>
  );
};

CatalogTile.displayName = 'CatalogTile';

export default CatalogTile;
