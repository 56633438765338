import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import Icon from '../Icon/icon';
import { renderFieldError } from '../../utils/form';

const Container = styled.div`
  background-color: ${props => props.theme.colors.inputBackground};
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  width: 100%;
  height: 3rem;

  ${props =>
    props.small &&
    `
    padding: 0.5rem 0;
    height: 2.25rem;
    font-size: ${props => props.theme.fonts.sizes.xsm};
    width: 8rem;
  `}

  ${props =>
    props.disabled &&
    `
    background-color: transparent;
  `}

  ${props =>
    props.themeVariant === 'light' &&
    `
    background-color: ${props.theme.colors.brand.layer3};

    ${
  props.disabled &&
      `
      background-color: transparent;
    `
    }
  `}

  ${props =>
    props.themeVariant === 'lightMode' &&
    `
    background-color: #fff;
    color: ${props.theme.colors.black100};
    border: 1px solid ${props.theme.colors.black20};

    ${
  props.disabled &&
      `
      background-color: transparent;
    `
    }
  `}

  ${props =>
    props.naked &&
    `
    background-color: transparent;
    color: ${props.theme.colors.brand.accent};
    font-size: ${props.theme.fonts.sizes.xlg};
  `}
`;

const Select = styled.select`
  background-color: transparent;
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 4px;
  color: ${props => (props.disabled ? '#757575' : 'inherit')};
  font-size: ${props => props.theme.fonts.sizes.reg};
  margin-bottom: 1px;
  padding: 0 1.5rem 0 1rem;
  appearance: none;
  width: 100%;
  z-index: 10;

  ${props =>
    props.naked &&
    `
		padding: 0 1.5rem 0 0rem;
  `}

  ${props =>
    props.invalid &&
    `
    border-color: ${props.theme.colors.negative};
    outline-color: ${props.theme.colors.negative};
  `}

  ${props =>
    props.disabled &&
    `
    border-color: ${props.theme.colors.brand.layer2};
    color: ${props.theme.colors.brand.layer4};
  `}

  ${props =>
    props.themeVariant === 'light' &&
    `
    color: ${props.theme.colors.brand.textWhite};

    ${
  props.disabled &&
      `
      border-color: ${props.theme.colors.brand.layer3};
      color: ${props.theme.colors.brand.layer3};
    `
    }
  `}
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: 'center';
  justify-content: center;
  position: absolute;
  height: 3rem;
  right: 1rem;

  ${props =>
    props.small &&
    `
    height: 2.25rem;
  `}

  ${props =>
    props.naked &&
    `
    right: 0;
  `}

  ${props =>
    props.disabled &&
    `
    i {
      opacity: 0.25;
    }
  `}
`;

const DropdownField = React.forwardRef(
  ({ name, className, style, placeholder, options, small, key, error, doShowError, ...props }, ref) => {
    return (
      <React.Fragment>
        <Container
          className={className}
          style={{ ...style }}
          small={small}
          disabled={!!props.disabled}
          themeVariant={props.themeVariant}
          naked={props.naked}>
          <IconContainer small={small} naked={props.naked} disabled={!!props.disabled}>
            <Icon
              type="chevronDown"
              alt="Choose"
              color={props.iconColor ? props.iconColor : props.naked ? props.theme.colors.brand.accent : null}
            />
          </IconContainer>
          <Select name={name} invalid={error} themeVariant={props.themeVariant} ref={ref} {...props}>
            {placeholder && <option value="">{placeholder}</option>}
            {options.map((option, index) => (
              <option key={index} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </Select>
        </Container>
        {doShowError && renderFieldError(error)}
      </React.Fragment>
    );
  }
);

DropdownField.defaultProps = {
  name: '',
  disabled: false,
  isValid: true,
  options: [
    { label: 'Example', value: 'example1' },
    { label: 'Example2', value: 'example2' },
  ],
  placeholder: 'Select...',
  naked: false,
  doShowError: true,
};

export default withTheme(DropdownField);
