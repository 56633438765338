import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as API from '../../utils/API/API';
import { getPayeeName } from '../../utils/helpers';
import useTrackSplits, { useTrackSplitsQueries } from '../../data-client/use-track-splits';
import ENUMS from '../../constants/ENUMS.gen.json';

const ReleaseStatusTrackContext = React.createContext();

function* newIdxGenerator() {
  let x = 0;
  while (true) {
    x = x + 1;
    yield x;
  }
}

const formatSplitsFormData = (splitsFormData, isrc_code) => {
  return splitsFormData.map(({ stakeholder_track_split_id, payee, contributor, roles, percentage }) => {
    return {
      stakeholder_track_split_id,
      user_group_payee_id: payee.value,
      asset_party_id: contributor.value || null,
      roles: roles.map(r => r.value),
      percentage,
      isrc_code,
    };
  });
};

const generateSplitsIdx = newIdxGenerator();

function ReleaseStatusTrackContextProvider({ trackFormData, trackId, parentRelease, trackIndex, children }) {
  const Split = API.split();

  //SERVER STATE
  const [labelPayee, setLabelPayee] = useState(null);
  const [payees, setPayees] = useState([]);
  const [contributors, setContributors] = useState([]);
  const [currentGroup, setCurrentGroup] = useState({});
  const [trackData, setTrackData] = useState(trackFormData);

  //DERIVED STATE
  const [splitsData, setSplitsData] = useState([]);
  const [splitsTotal, setSplitsTotal] = useState(0);
  const [deletedSplits, setDeletedSplits] = useState([]);

  const userGroupId = trackData.user_group_id;

  const [viewConfigState, setViewConfig] = useState({
    isEditingSplits: false,
    isExpanded: false,
    canEditSplits: trackData.resource_type !== ENUMS.RESOURCE_TYPE.VIDEO && !!trackData.isrc_code,
    activeTab: 0,
    disabledTabs: [],
    isLoading: true,
    showContent: false,
    selectedPayees: [],
  });

  const queryClient = useQueryClient();

  const allMutations = useMutation(() => {
    return Promise.all([
      splitsData.length && Split.putSplits(formatSplitsFormData(splitsData, trackData.isrc_code)),
      deletedSplits.length && Split.deleteSplits(formatSplitsFormData(deletedSplits, trackData.isrc_code)),
    ]);
  });

  const saveSplits = async ({ onSuccess, onError, onSettled }) => {
    const handleSuccess = async data => {
      await queryClient.refetchQueries(queryKeys.trackSplits);
      await hydrateSplitsData();
      setViewConfig(prev => ({
        ...prev,
        isExpanded: true,
        isEditingSplits: false,
        disabledTabs: [],
      }));
      if (typeof onSuccess === 'function') {
        onSuccess(data);
      }
    };
    await allMutations.mutateAsync(null, { onSuccess: handleSuccess, onError, onSettled });
  };

  const updateSplits = newData => {
    setSplitsData(prevState => {
      if (!newData.idx) {
        newData.idx = generateSplitsIdx.next().value;
      }
      return [...prevState, newData];
    });
  };

  const removeSplitByIndex = split => {
    if (split.stakeholder_track_split_id) {
      setDeletedSplits(prevState => [...prevState, split]);
    }
    setSplitsData(prevState => prevState.filter(s => s.idx !== split.idx));
  };

  const transformAndSetSplits = (data = []) => {
    let hasLabelPayee = false;

    const splits = data.map(split => {
      const matchingContributor = contributors.find(c => c.id === split.asset_party_id);
      const isLabelPayee = split.user_group_payee_id === labelPayee.user_group_payee_id && !split.asset_party_id;
      if (!hasLabelPayee) hasLabelPayee = isLabelPayee;

      return {
        ...split,
        isLabelPayee,
        idx: generateSplitsIdx.next().value,
        payee: {
          label: getPayeeName(split.payee),
          value: split.user_group_payee_id,
        },
        contributor: {
          label: matchingContributor?.full_name,
          value: matchingContributor?.id,
        },
        roles: split.roles.map(r => ({ label: r, value: r })),
        percentage: split.percentage,
      };
    });

    if (hasLabelPayee) return setSplitsData(splits);

    const payeeSplit = {
      idx: generateSplitsIdx.next().value,
      isLabelPayee: true,
      payee: {
        label: getPayeeName(labelPayee),
        value: labelPayee.user_group_payee_id,
      },
      contributor: {
        label: false,
        value: false,
      },
      isrc_code: trackData.isrc_code,
      roles: [{ label: 'Label', value: 'Label' }],
      percentage: undefined,
    };
    setSplitsData([payeeSplit, ...splits]);
  };

  const hydrateSplitsData = async () => {
    const data = queryClient.getQueryData(queryKeys.trackSplits);
    transformAndSetSplits(data);
  };

  const { keys: queryKeys } = useTrackSplitsQueries(trackData);

  const queryOptions = {
    [queryKeys.payees]: {
      onSuccess: data => {
        const labelPayee = data?.find(payee => payee.payment_account.user_group_id === userGroupId);
        setLabelPayee(labelPayee);
        setPayees(data);
      },
    },
    [queryKeys.contributors]: {
      onSuccess: res => setContributors(res.data),
    },
    [queryKeys.currentGroup]: {
      onSuccess: resp => {
        viewConfig.update({
          canEditSplits: !!(!!trackData.isrc_code && resp.splits_payout_enabled && !trackData.has_royalties),
        });
      },
    },
    [queryKeys.trackSplits]: {
      enabled: !!trackData.isrc_code,
      placeholderData: [],
    },
    [queryKeys.trackData]: {
      onSuccess: res => {
        setTrackData({ ...trackData, ...res });
      },
      placeholderData: trackData,
    },
  };

  const allQueries = useTrackSplits({ trackData, options: queryOptions });

  const invalidateContributors = async () => {
    await queryClient.invalidateQueries(queryKeys.contributors);
  };

  const invalidatePayees = async () => {
    await queryClient.invalidateQueries(queryKeys.payees);
  };

  useEffect(() => {
    if (splitsData.length) {
      setSplitsTotal(splitsData?.reduce((a, v) => a + parseFloat(v.percentage || 0), 0) || 0);
    }
  }, [splitsData]);

  function missingRequiredField(field) {
    return Object.keys(field).length < 1;
  }

  const updateViewConfig = newConfig => {
    setViewConfig(prevState => ({ ...prevState, ...newConfig }));
  };

  const viewConfig = {
    ...viewConfigState,
    isLoading: allQueries.some(q => q.isFetching || q.isLoading),
    hasFetchError: allQueries.some(q => q.isError),
    showContent: viewConfigState.isExpanded || viewConfigState.isEditingSplits,
    availablePayeeContributors: payees.reduce(
      (a, payee) => {
        const usedContributors = splitsData
          .filter(split => split.payee.value === payee.id)
          .map(split => split.contributor.value);

        const selectableContributors = contributors.filter(c => !usedContributors.includes(c.id));
        return {
          ...a,
          [payee.id]: selectableContributors,
        };
      },
      { [undefined]: contributors }
    ),
    update: obj => {
      updateViewConfig({
        ...obj,
      });
    },
    setActiveTab: idx => {
      const [activeTab, disabledTabs] = idx === 2 && viewConfigState.isEditingSplits ? [2, [0, 1]] : [idx, []]; //blame prettier
      updateViewConfig({ activeTab, disabledTabs });
    },
    editSplits: async () => {
      await hydrateSplitsData();

      updateViewConfig({
        isEditingSplits: true,
        activeTab: 2,
        disabledTabs: [0, 1],
      });
    },
    stopEditingSplits: async () => {
      setDeletedSplits([]);
      if (!labelPayee) {
        return refetchData();
      }
      // prevent stopEditingSplits from constantly refetching
      await hydrateSplitsData().then(() => {
        updateViewConfig({
          isEditingSplits: false,
          disabledTabs: [],
        });
      });
    },
    toggleExpanded: async () => {
      const currentlyExpanded = viewConfig.isExpanded;
      if (!currentlyExpanded) {
        await hydrateSplitsData();
      }
      updateViewConfig({
        isExpanded: !currentlyExpanded,
      });
    },
    isValid: useMemo(() => {
      return (
        splitsTotal === 100 &&
        splitsData.every(split => {
          return !isEmpty(split.payee) && !isEmpty(split.roles);
        })
      );
    }, [splitsData, splitsTotal]),
    errors: useMemo(() => {
      const splitsTotalError = splitsData.length && splitsTotal !== 100;
      const rowErrors = splitsData?.map(({ payee, roles }) => ({
        payee: missingRequiredField(payee),
        roles: missingRequiredField(roles),
      }));

      return {
        splitsTotalError,
        rowErrors,
      };
    }, [splitsTotal, splitsData]),
  };

  const refetchData = () => queryClient.invalidateQueries(...Object.values(queryKeys));

  return (
    <ReleaseStatusTrackContext.Provider
      value={{
        setContributors,
        contributors,
        invalidateContributors,
        setPayees,
        payees,
        invalidatePayees,
        setSplitsData,
        updateSplits,
        removeSplitByIndex,
        splitsData,
        splitsTotal,
        trackData,
        trackId,
        parentRelease,
        trackIndex,
        currentGroup,
        setCurrentGroup,
        viewConfig,
        labelPayee,
        saveSplits,
        refetchData,
      }}>
      {children}
    </ReleaseStatusTrackContext.Provider>
  );
}

export default ReleaseStatusTrackContextProvider;

export const useReleaseStatusTrackContext = () => useContext(ReleaseStatusTrackContext);
