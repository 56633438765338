import React, { useState, useEffect } from 'react';
import {
  Box,
  Center,
  Button as ChakraButton,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  useDisclosure,
  useClipboard,
  useToast,
  chakra,
  Avatar,
  Spinner,
  useTheme,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTable } from 'react-table';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { RiArrowUpLine, RiArrowDownLine, RiAlertLine } from 'react-icons/ri';

import TablePaginationFooter from 'components/Table/table-pagination-footer';
import ArtistForm from './artist-form';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import ConfirmationModal from 'components/Modal/confirmation-modal';
import PlatformIds from 'components/PlatformIds/platform-ids';
import * as API from 'utils/API/API';
import styled from '@emotion/styled/macro';
import { CheckIcon } from '@chakra-ui/icons';
import { useUserTierChecks } from '../hooks/authorization-hooks';

const User = API.user();
const Party = API.party();

const ArtistAvatar = styled(Avatar)`
  background-color: ${props => props.theme.colors.black50};
  margin-right: 1.5rem;
`;

// handles fallbacks. size is expected to be: ['small', 'medium', 'large']
const artistImageUrlWithFallback = (image_url, size) => {
  if (size === 'large') {
    return image_url?.large;
  }
  if (size === 'medium') {
    return image_url?.medium || image_url?.large;
  }
  if (size === 'small') {
    return image_url?.small || image_url?.medium || image_url?.large;
  }
};

const TableRow = ({ artist }) => {
  const toast = useToast();
  const removeArtistModal = useDisclosure();
  const requiredArtistModal = useDisclosure();
  const editArtistModal = useDisclosure();
  const copySpotify = useClipboard(artist.original?.platform_id?.spotify);
  const copyApple = useClipboard(artist.original?.platform_id?.apple);
  const removeArtistMutation = useMutation(() => Party.destroy(artist.original.id), {
    onError: () => {
      toast({
        title: `Artist failed to be removed.`,
        status: 'error',
      });
    },
  });

  const editMemberMutation = useMutation(data => Party.patch(artist.original.id, data), {
    onError: error => {
      toast({
        title: error?.response?.data?.detail || error?.message || `Failed to edit artist.`,
        status: 'error',
      });
    },
  });

  const queryClient = useQueryClient();
  const canDelete = [
    artist?.original?.trackParties,
    artist?.original?.stakeholderTrackSplits,
    artist?.original?.releaseParties,
  ].every(a => !a?.length);

  const hasDeliveredRelease = !!artist?.original?.releaseParties?.find(party => party.release.status === 'Delivered');

  const onRemoveArtist = async () => {
    await removeArtistMutation.mutateAsync();
    removeArtistModal.onClose();
    await queryClient.invalidateQueries(['artists']);
  };

  const onEditArtist = async values => {
    await editMemberMutation.mutateAsync({
      full_name: values.artistName,
      platform_id: {
        spotify: values.spotifyId || undefined,
        apple: values.appleId || undefined,
        soundcloud: values.soundcloudId || undefined,
        vevo: values.vevoChannelName || undefined,
      },
      is_required_release_artist: values.isRequiredReleaseArtist,
    });
    editArtistModal.onClose();
    await queryClient.invalidateQueries(['artists']);
  };

  const onCopySpotifyId = () => {
    copySpotify.onCopy();
    toast({
      title: `Copied Spotify URI to clipboard`,
    });
  };
  const onCopyAppleId = () => {
    copyApple.onCopy();
    toast({
      title: `Copied Apple Music ID to clipboard`,
    });
  };

  const onSetRequiredReleaseArtist = async () => {
    await editMemberMutation.mutateAsync({
      is_required_release_artist: true,
    });
    requiredArtistModal.onClose();
    await queryClient.invalidateQueries(['artists']);
  };

  return (
    <>
      <Tr key={artist.original.name} {...artist.getRowProps()} borderColor={'black20'}>
        {artist.cells.map((cell, index) => (
          <Td
            key={`${index}-cell`}
            isNumeric={cell.column.isNumeric}
            {...cell.getCellProps({
              color: cell.column.id === 'name' ? 'black100' : 'inherit',
              textAlign: cell.column.id === 'menu' ? 'center' : 'inherit',
            })}>
            <Box minH="8" display="flex" alignItems="center" justifyContent={cell.column.id === 'menu' && 'center'}>
              {cell.render('Cell')}

              {cell.column.id === 'is_required_release_artist' && (
                <Flex width="100%" justifyContent="left">
                  <IconButton
                    size="xs"
                    borderColor="black60"
                    borderWidth="1px"
                    borderStartStyle="solid"
                    backgroundColor="white"
                    aria-label="Required Release Artist"
                    icon={artist.original?.is_required_release_artist ? <CheckIcon /> : null}
                    onClick={requiredArtistModal.onOpen}
                    pointerEvents={artist.original?.is_required_release_artist ? 'none' : 'default'}
                    disabled={artist.original?.is_required_release_artist}
                  />
                </Flex>
              )}

              {cell.column.id === 'menu' && (
                <Menu strategy="fixed">
                  <MenuButton
                    as={ChakraButton}
                    variant="outline"
                    size="sm"
                    borderColor="black50"
                    width="fit-content"
                    alignSelf="center"
                    testCode="artist-menu">
                    <HiOutlineDotsHorizontal />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={editArtistModal.onOpen}>Edit Artist</MenuItem>
                    <MenuItem isDisabled={!canDelete} testCode="remove-artist-menu" onClick={removeArtistModal.onOpen}>
                      Remove Artist{' '}
                      {!canDelete && (
                        <Tooltip
                          hasArrow
                          label="A release has been associated with this artist.
                  Please contact yourfriends@venicemusic.co."
                          placement="top"
                          bg="cream.100"
                          color="gray.800">
                          <Box ml="1rem" lineHeight=".5rem">
                            <Icon as={RiAlertLine} alt="Info" boxSize="4" color="black" />
                          </Box>
                        </Tooltip>
                      )}
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Box>
          </Td>
        ))}
      </Tr>
      <ConfirmationModal
        headerText="Remove artist?"
        body={`Are you sure you want to remove ${artist.original?.full_name || 'Untitled artist'}? This action cannot be
            undone.`}
        confirmText="Remove artist"
        disclosure={removeArtistModal}
        onSubmit={onRemoveArtist}
        isLoading={removeArtistMutation.isLoading}
        testCodetext="remove-artist-confirmation"
      />
      <ConfirmationModal
        headerText="Set new required artist?"
        body={`Are you sure you want to set your required release artist to ${
          artist.original?.full_name || 'Untitled artist'
        }?`}
        confirmText="Set required artist"
        disclosure={requiredArtistModal}
        onSubmit={onSetRequiredReleaseArtist}
        isLoading={editMemberMutation.isLoading}
        testCodetext="required-artist-confirmation"
      />
      <ConfirmationModal
        headerText="Edit artist"
        body={
          <ArtistForm
            variant="light"
            isEditing={true}
            hasDeliveredRelease={hasDeliveredRelease}
            defaultValues={{
              artistName: artist.original?.full_name,
              spotifyId: artist.original?.platform_id?.spotify,
              appleId: artist.original?.platform_id?.apple,
              soundcloudId: artist.original?.platform_id?.soundcloud,
              vevoChannelName: artist.original?.platform_id?.vevo,
              isRequiredReleaseArtist: artist.original?.is_required_release_artist,
            }}
            onSubmit={onEditArtist}
          />
        }
        isLoading={editMemberMutation.isLoading}
        confirmText="Edit artist"
        disclosure={editArtistModal}
        confirmButtonProps={{
          form: 'artist',
          type: 'submit',
        }}
      />
    </>
  );
};

const ARTISTS_PAGE_SIZE = 50;
const DEFAULT_HIDDEN_COLUMNS = ['menu'];

export default function ArtistsTable({ searchTerm }) {
  const theme = useTheme();
  const [sortConfig, setSortConfig] = useState({ id: 'name', orderAsc: true });
  const [page, setPage] = useState(0);
  const userQuery = useQuery(['user'], () => User.getMe());

  useEffect(() => {
    setPage(() => 0);
  }, [searchTerm]);

  const artistsQuery = useQuery(
    ['artists', page, sortConfig, searchTerm, userQuery],
    () =>
      Party.list('artist', {
        user_group_id: userQuery?.data?.currentGroup?.id,
        page,
        search: searchTerm,
        orderBy: sortConfig.id,
        orderAsc: sortConfig.orderAsc,
        with_status: true,
      }),
    { keepPreviousData: true }
  );

  //needs default [] arg so that useTable below does not crash when a query gets cancelled (happens on logout sometimes)
  const { data: artists = [], totalPages } = React.useMemo(() => {
    const { data: queryData = { total: 1, data: [] } } = artistsQuery;
    const { total, data } = queryData;
    const totalPages = Math.max(Math.ceil(total / ARTISTS_PAGE_SIZE), 1);
    return { data, totalPages };
  }, [artistsQuery.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'full_name',
        // eslint-disable-next-line react/display-name
        Cell: props => (
          <Box color="black100" display="flex" alignItems="center">
            <ArtistAvatar
              size="sm"
              icon={<div />} // override default profile icon
              src={artistImageUrlWithFallback(props.cell?.row?.original?.image_url, 'small')}
            />
            {props.value}
          </Box>
        ),
        sortId: 'name',
      },
      {
        Header: 'Platform IDs',
        accessor: 'platform_id',
        Cell: props => {
          return <PlatformIds {...props.value} />;
        },
      },
      {
        Header: 'Required',
        accessor: 'is_required_release_artist',
        sortId: 'requiredReleaseArtist',
      },
      {
        Header: '',
        accessor: 'menu',
      },
    ],
    []
  );

  const { needsRequiredReleaseArtist, unknownTier } = useUserTierChecks();
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, toggleHideColumn } = useTable({
    columns,
    data: artists.slice(page * 50, (page + 1) * 50),
    initialState: {
      hiddenColumns: [...DEFAULT_HIDDEN_COLUMNS, 'is_required_release_artist'],
    },
  });

  //There is a race condition where we don't know that we need the required release artists
  //but the table sets its initial state and cannot be reset easily due to the library.
  // We need to toggle the required release artist column to be hidden or not once we know.
  React.useEffect(() => {
    toggleHideColumn('is_required_release_artist', needsRequiredReleaseArtist === false);
  }, [needsRequiredReleaseArtist]);

  const isNextPageDisabled = React.useMemo(
    () => page + 1 >= totalPages || artistsQuery.isLoading || artistsQuery.isRefetching,
    [page, totalPages, artistsQuery.isLoading, artistsQuery.isRefetching]
  );
  const isPrevPageDisabled = React.useMemo(
    () => page <= 0 || artistsQuery.isLoading || artistsQuery.isRefetching,
    [page, artistsQuery.isLoading, artistsQuery.isRefetching]
  );
  const onNextPage = React.useMemo(() => () => setPage(p => p + 1), []);
  const onPrevPage = React.useMemo(() => () => setPage(p => p - 1), []);

  const onColumnHeaderClick = React.useMemo(
    () => column =>
      column.sortId
        ? setSortConfig(({ id, orderAsc }) => ({
            id: column.sortId,
            orderAsc: id === column.sortId ? !orderAsc : orderAsc,
          }))
        : undefined,
    []
  );

  // wait until the role can be checked as an admin before showing the menu
  const userRole = userQuery?.data?.currentGroup?.role;
  React.useEffect(() => {
    if (userRole === 'Label Admin') {
      toggleHideColumn('menu', false);
    }
  }, [userRole, toggleHideColumn]);

  return (
    <SkeletonLoader status={artistsQuery.status || unknownTier} skeletonProps={{ height: '240px', width: '100%' }}>
      <Box overflowX="auto" style={{ position: 'relative' }}>
        <Table size="sm" variant="light" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, groupIndex) => (
              <Tr key={`${groupIndex}-headergroup`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, headerIndex) => (
                  <Th key={`${groupIndex}-${headerIndex}-tableheader`} isNumeric={column.isNumeric} whiteSpace="nowrap">
                    <Box
                      style={{ color: 'white' }}
                      display="inline"
                      onClick={() => {
                        onColumnHeaderClick(column);
                      }}>
                      {column.sortId === sortConfig.id ? (
                        <chakra.span pr="3" position="relative" top="1px">
                          {!sortConfig.orderAsc ? (
                            <Icon as={RiArrowUpLine} aria-label="sorted descending" />
                          ) : (
                            <Icon as={RiArrowDownLine} aria-label="sorted ascending" />
                          )}
                        </chakra.span>
                      ) : null}
                      {column.render('Header')}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(artist => {
              prepareRow(artist);
              return <TableRow key={`artist-${artist.original.id}`} artist={artist} />;
            })}
          </Tbody>
        </Table>
        {artistsQuery.isRefetching && (
          <Box
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              right: 0,
              top: 0,
              backgroundColor: theme.colors.brand.layer4,
              opacity: '25%',
            }}>
            <Center h="full">
              <Spinner color="black" size="lg" />
            </Center>
          </Box>
        )}
      </Box>
      <TablePaginationFooter
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        page={page}
        totalPages={totalPages}
        onNextPage={onNextPage}
        isNextPageDisabled={isNextPageDisabled}
      />
    </SkeletonLoader>
  );
}
