import z from 'zod';
import TrackSchema from './track.js';
const { Track } = TrackSchema;

const SubTitle = z
  .enum([
    'Acapella',
    'Acoustic',
    'Alternative Version',
    'Ao Vivo',
    'Bonus Track',
    'Cover Version',
    'Deluxe',
    'Demo',
    'Edit',
    'Extended Version',
    'Freestyle',
    'Ghost Track',
    'Hidden Track',
    'Instrumental',
    'Karaoke Track',
    'Live',
    'Radio Edit',
    'Remastered',
    'Remix',
    'Silent Track',
  ])
  .optional();

const Title = z.string().min(1);
const DisplayArtistName = z.string().min(2);
const DisplayLabelName = z.string().min(2);
const CopyRightYear = z.number().min(1900).max(new Date().getFullYear());
const UPC = z.string().min(12).max(12).optional().nullable();
const OriginalReleaseDate = z.string().nullable();

const Artist = z.object({
  asset_party_id: z.number(),
  roles: z.array(z.enum(['MainArtist', 'FeaturedArtist', 'Remixer', 'RemixedArtist'])).min(1),
});

const Genre = z.enum([
  'African',
  'Afrikaans',
  'Afro House',
  'Afro Soul',
  'Afro-Beat',
  'Afro-Pop',
  'Afro-fusion',
  'Alternative',
  'Amapiano',
  'Ambient',
  'Americana',
  'Arabic',
  'Audiobooks',
  'Bluegrass',
  'Blues',
  'Bollywood',
  'Chanukah',
  "Children's Music",
  'Chinese',
  'Christian & Gospel',
  'Christmas',
  'Classical',
  'Comedy',
  'Country',
  'Dance',
  'Devotional & Spiritual',
  'Electronic',
  'Folk',
  'Funk',
  'Garage',
  'Grunge',
  'Hardcore',
  'Hip Hop/Rap',
  'Holiday',
  'House',
  'Indian',
  'Indie Pop',
  'Indie Rock',
  'Inspirational',
  'Instrumental',
  'Jazz',
  'K-Pop',
  'Karaoke',
  'Korean',
  'Latin',
  'Mambo',
  'Neo-Soul',
  'New Age',
  'New Wave',
  'Opera',
  'Original Score',
  'Pop',
  'Punjabi',
  'R&B/Soul',
  'Reggae',
  'Rock',
  'Sing-Along',
  'Soul',
  'Soundtrack',
  'Spoken Word',
  'Techno',
  'Trance',
  'Vocal',
  'World',
]);

const Language = z.enum([
  'en',
  'pt',
  'es',
  'fr',
  'ar',
  'bn',
  'ru',
  'ro',
  'zh-Hans',
  'zh-Hant',
  'yue-Hant',
  'hi',
  'id',
  'ja',
]);

const Model = z.object({
  Release: z.object({
    tracks: z.array(Track).optional(),
    title: Title,
    sub_title: SubTitle,
    upc: UPC,
    genre: Genre,
    secondary_genre: Genre.optional(),
    lang: Language,
    artists: z.array(Artist),
    original_release_date: OriginalReleaseDate.optional(),
    display_artist_name: DisplayArtistName,
    display_label_name: DisplayLabelName,
    copyright_year: CopyRightYear,
  }),
});

const validators = {
  Model,
  Title,
  SubTitle,
  DisplayArtistName,
  DisplayLabelName,
  CopyRightYear,
  UPC,
  Genre,
  OriginalReleaseDate,
  Language,
  Artist,
  Artists: z.array(Artist),
};

export { validators as default };
