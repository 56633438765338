import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const MarketingDiscoveryModeTracks = () => {
  const getDraftCampaignTracks = params =>
    AuthAxios.get(`${DataApiUrl}/marketing-discovery-mode-tracks/draft`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getLiveCampaignTracks = params =>
    AuthAxios.get(`${DataApiUrl}/marketing-discovery-mode-tracks/live`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getLiveCampaignStats = params =>
    AuthAxios.get(`${DataApiUrl}/marketing-discovery-mode-tracks/live-stats`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getHistoryCampaignTracks = params =>
    AuthAxios.get(`${DataApiUrl}/marketing-discovery-mode-tracks/history`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getHistoryStats = params =>
    AuthAxios.get(`${DataApiUrl}/marketing-discovery-mode-tracks/history-stats`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const updateOptIn = data =>
    AuthAxios.patch(`${DataApiUrl}/marketing-discovery-mode-tracks/opt-in`, data)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const optimizeFanGrowth = user_group_id =>
    AuthAxios.post(`${DataApiUrl}/marketing-discovery-mode-tracks/optimize-fan-growth`, { user_group_id })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const optimizeRevenueGrowth = user_group_id =>
    AuthAxios.post(`${DataApiUrl}/marketing-discovery-mode-tracks/optimize-revenue-growth`, { user_group_id })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  return {
    getDraftCampaignTracks,
    getLiveCampaignTracks,
    getLiveCampaignStats,
    getHistoryStats,
    updateOptIn,
    getHistoryCampaignTracks,
    optimizeFanGrowth,
    optimizeRevenueGrowth,
  };
};

export default MarketingDiscoveryModeTracks;
