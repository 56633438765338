import * as React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Box, useToast, useTheme, Select } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import ViewWrapper from 'views/ViewWrapper/view-wrapper';
import Masthead from './masthead';
import { useAnalyticsContext } from './analytics-context';

import AnalyticsTable from './table/analytics-table';
import SkeletonLoader from '../components/loading-containers/skeleton-loader';

import METRICS_PERIOD_INTERVALS from '../constants/METRICS_PERIOD_INTERVALS.gen.json';

const SearchAndFilterSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  padding: 0 8px;
  width: 100%;
  margin: 16px 0;
  ${props => props.theme.breakpoint('max-width', 'sm')`
    grid-template-columns: 1fr;
    gap: 16px 0px;
	`};
`;

const TableSection = styled.section`
  margin-top: 1.75rem;
  width: 100%;
  background-color: ${props => (props.nobg ? '' : props.theme.colors['black80'])};
  border-radius: 8px;
`;

export default function AnalyticsLayout({ history, config, domainData, loadingStatus, ...props }) {
  const { updateAnalyticsState, currentMetricsInterval } = useAnalyticsContext();

  const toast = useToast();
  const theme = useTheme();

  const { masthead, SearchComponent, cards, table, PerformanceChart } = config;

  /**
   * ============================================================================
   * report errors in data
   * ============================================================================
   */
  const loadingStatusHasError = loadingStatus?.isError;
  const toastIsActive = toast.isActive('analytics-error');
  const handleTimeRangeChange = event => {
    updateAnalyticsState({
      currentMetricsInterval: Object.values(METRICS_PERIOD_INTERVALS).find(
        i => i.interval === parseInt(event.target.value, 10)
      ),
    });
  };

  React.useEffect(() => {
    if (loadingStatusHasError && !toastIsActive) {
      if ([401, 403].includes(loadingStatus?.error?.response?.status)) {
        toast({
          id: 'analytics-error',
          title: `You are not permitted to view this data.`,
          status: 'error',
        });
      } else {
        toast({
          id: 'analytics-error',
          title: `There was an error retrieving analytics data.`,
          status: 'error',
        });
      }
    }
  }, [loadingStatusHasError, toastIsActive, toast, loadingStatus]);

  /**
   * ============================================================================
   * transform and aggregate data
   * ============================================================================
   */

  return (
    <ViewWrapper designLayer={0} disableHorizontalScroll={true} {...props}>
      <style>{`body { background-color: ${theme.colors.white} color: ${theme.colors.black100} }`}</style>
      <Row>
        <Masthead {...masthead} domainData={domainData} />
        <SearchAndFilterSection>
          <Box width="100%" gridColumn="1 / 3">
            <SkeletonLoader status={domainData.status} skeletonProps={{ borderRadius: 30 }}>
              {SearchComponent && <SearchComponent history={history} />}
            </SkeletonLoader>
          </Box>
          <Box
            minWidth="150px"
            justifySelf="flex-end"
            width={{
              base: '100%',
              sm: 'auto',
            }}>
            <Select
              bg="white"
              borderColor="black.20"
              color="black.100"
              value={currentMetricsInterval?.interval}
              onChange={handleTimeRangeChange}>
              {Object.values(METRICS_PERIOD_INTERVALS).map(interval => {
                return (
                  <option key={`option-${interval.interval}`} value={interval.interval}>
                    {interval.friendly}
                  </option>
                );
              })}
            </Select>
          </Box>
        </SearchAndFilterSection>
        <Col xs={12}>
          <section
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
              gridGap: '16px',
              marginBottom: theme.space[4],
            }}>
            {cards.map(Card => {
              return (
                <div key={Card.name}>
                  <Card domainData={domainData} loadingStatus={loadingStatus} params={props.match.params} />
                </div>
              );
            })}
          </section>
          {table?.title && (
            <TableSection nobg="true" id={'analytics-table'}>
              <AnalyticsTable domainData={domainData} {...table} />
            </TableSection>
          )}
          {PerformanceChart ? (
            <SkeletonLoader status={domainData.status}>
              <PerformanceChart domainData={domainData} loadingStatus={loadingStatus} params={props.match.params} />
            </SkeletonLoader>
          ) : null}
        </Col>
      </Row>
    </ViewWrapper>
  );
}
