import ManageClipsStateManager from '../../../manage-clips-state-manager';

class ClipReviewAndSubmitState extends ManageClipsStateManager {
  dependentFields = [];
  path = '/manage/clip/review';

  static mutableFields = [];

  constructor({ stateData, ...args }) {
    const schema = {};
    super(
      {
        stateData,
        ...args,
        mutableFields: ClipReviewAndSubmitState.mutableFields,
      },
      schema
    );
  }
}

export default ClipReviewAndSubmitState;
