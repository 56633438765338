import React, { useState } from 'react';
import { Button, Center, Flex, Heading, Spinner, Table, Tbody, Td, Th, Thead, Tr, useTheme } from '@chakra-ui/react';
import * as API from 'utils/API/API';
import { useQuery } from 'react-query';
import Box from 'components/Box/Box';
import { useTable } from 'react-table';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import numeral from 'numeral';
import Icon from 'components/Icon/icon';
import TablePaginationFooter from 'components/Table/table-pagination-footer';
import MarketingDiscoveryModeHistoryTable from './marketing-discovery-mode-history-table';

const MarketingDiscoveryModeTracks = API.marketingDiscoveryModeTracks();

const TableRow = ({ stats, onDetailsClick }) => {
  return (
    <>
      <Tr borderColor={'black20'}>
        {stats.cells.map(cell => (
          <Td key={`${cell.column.id}-${stats.original.month_name}${stats.original.year}`}>
            {cell.column.id === 'month_name' && (
              <Flex width="100%" justifyContent="left">
                <strong>
                  {stats.original?.month_name} {stats.original?.year}
                </strong>
              </Flex>
            )}
            {['intent_rate', 'stream_lift', 'listener_lift'].includes(cell.column.id) && (
              <Box>{numeral(stats.original[cell.column.id]).format('0.00')}%</Box>
            )}
            {['streams', 'listeners', 'saves', 'playlist_adds'].includes(cell.column.id) && (
              <Box>{numeral(stats.original[cell.column.id]).format('0,0')}</Box>
            )}
            {cell.column.id === 'details' && (
              <Flex width="100%" justifyContent="left" cursor="pointer" onClick={() => onDetailsClick(stats.original)}>
                <Icon type="magnifyingGlass" />
              </Flex>
            )}
          </Td>
        ))}
      </Tr>
    </>
  );
};

export default function MarketingDiscoveryModeHistory({ userGroupId }) {
  const theme = useTheme();
  const [page, setPage] = useState(0);

  const query = useQuery(
    ['historyCampaignStats', userGroupId, page],
    () =>
      MarketingDiscoveryModeTracks.getHistoryStats({
        user_group_id: userGroupId,
        page_size: PAGE_SIZE,
        page_index: page,
      }),
    { enabled: !!userGroupId }
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'month_name',
      },
      {
        Header: 'Promoted Streams',
        accessor: 'streams',
      },
      {
        Header: 'Promoted Stream Lift',
        accessor: 'stream_lift',
      },
      {
        Header: 'Listeners',
        accessor: 'listeners',
      },
      {
        Header: 'Listeners Lift',
        accessor: 'listener_lift',
      },
      {
        Header: 'Saves',
        accessor: 'saves',
      },
      {
        Header: 'Playlist adds',
        accessor: 'playlist_adds',
      },
      {
        Header: 'Intent Rate',
        accessor: 'intent_rate',
      },
      {
        Header: 'Details',
        accessor: 'details',
      },
    ],
    []
  );

  const PAGE_SIZE = 10;

  const { data, totalPages } = React.useMemo(() => {
    const { data: queryData = { total: 1, results: [] } } = query;
    const { total = 1, results } = queryData;
    const totalPages = Math.max(Math.ceil(total / PAGE_SIZE), 1);

    return { data: results, totalPages };
  }, [query.data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const onNextPage = React.useMemo(() => () => setPage(p => p + 1), []);
  const onPrevPage = React.useMemo(() => () => setPage(p => p - 1), []);

  const isNextPageDisabled = React.useMemo(
    () => page + 1 >= totalPages || query.isLoading || query.isRefetching,
    [page, totalPages, query.isLoading, query.isRefetching]
  );

  const isPrevPageDisabled = React.useMemo(
    () => page <= 0 || query.isLoading || query.isRefetching,
    [page, query.isLoading, query.isRefetching]
  );

  const [selectedDate, setSelectedDate] = useState(null);

  const handleSelectDate = ({ month_name, month, year, tracks }) => {
    setSelectedDate({
      month_name,
      month,
      year,
      tracks,
    });
  };

  return selectedDate ? (
    <>
      <Flex alignItems={'center'} justifyContent={'space-between'} width="100%">
        <Flex alignItems={'center'} gap="20px">
          <Heading as="h3" fontSize={'1.3rem'}>
            {selectedDate?.month_name} {selectedDate?.year}
          </Heading>
        </Flex>

        <Button variant={'smallDark'} onClick={() => setSelectedDate(null)} mt={2} mb={3}>
          Back to History
        </Button>
      </Flex>

      <MarketingDiscoveryModeHistoryTable
        userGroupId={userGroupId}
        month={selectedDate?.month}
        year={selectedDate?.year}
      />
    </>
  ) : query.isFetched && data.length === 0 ? (
    <Box color="black70" p={6} textAlign="center" fontWeight="bold">
      Looks like there is no historical campaign stats yet. You'll see info here 2 weeks after a monthly campaign wraps
      up.
    </Box>
  ) : (
    <SkeletonLoader status={query.status} skeletonProps={{ height: '240px', width: '100%' }}>
      <Box overflowX="auto">
        <Table size="sm" variant="light" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, groupIndex) => (
              <Tr key={`${groupIndex}-headergroup`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, headerIndex) => (
                  <Th key={`${groupIndex}-${headerIndex}-tableheader`} isNumeric={column.isNumeric}>
                    <Box style={{ color: 'white' }} display="inline">
                      {column.render('Header')}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(stats => {
              prepareRow(stats);
              return (
                <TableRow key={`track-${stats.original.month_name}`} stats={stats} onDetailsClick={handleSelectDate} />
              );
            })}
          </Tbody>
        </Table>
        {query.isRefetching && (
          <Box
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              right: 0,
              top: 0,
              backgroundColor: theme.colors.brand.layer4,
              opacity: '25%',
            }}>
            <Center h="full">
              <Spinner color="black" size="lg" />
            </Center>
          </Box>
        )}
      </Box>
      <TablePaginationFooter
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        page={page}
        totalPages={totalPages}
        onNextPage={onNextPage}
        isNextPageDisabled={isNextPageDisabled}
      />
    </SkeletonLoader>
  );
}
