import React, { useState, useCallback, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { ClipLoader } from 'react-spinners';

const ImageWrapper = styled.div`
  align-items: center;
  background: url('${props => props.imageUrl}') center center;
  background-color: ${props => props.theme.colors.brand.layer2};
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${props => (props.catalog ? props.size - 1.5 : props.size)}rem;
  justify-content: center;
  width: ${props => (props.catalog ? props.size - 1.5 : props.size)}rem;

  ${props =>
    props.wide &&
    props.standard &&
    `
		height: ${0.5625 * props.size}rem;
	`}

  ${props =>
    props.catalog &&
    `
		border: 1rem solid ${props.theme.colors.brand.layer1}
		border-radius: 100%;
	`}

	${props =>
    props.searchResult &&
    `
		border-radius: 100%;
	`}

	${props =>
    props.square &&
    `
		border-radius: 0;
	`}

	${props =>
    props.logo &&
    `
		width: ${props.size * 2}rem;
	`}

	${props =>
    props.fullWidth &&
    `
		width: 100%;
	`}
`;

const ImageBlock = React.forwardRef(
  (
    { catalog, className, logo, searchResult, size, square, src, standard, fullWidth, loading, wide, ...props },
    ref
  ) => {
    const [height, setHeight] = useState(null);
    const [refImageContainer, setRefImageContainer] = useState(null);

    let mergedProps = { ...props };

    useEffect(() => {
      window.addEventListener('resize', () => handleWindowResize());
      handleWindowResize();

      return () => window.removeEventListener('resize', () => handleWindowResize());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refImageContainer]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRefImageContainer = useCallback(node => {
      if (node && !node.isEqualNode(refImageContainer)) {
        setRefImageContainer(node);
        handleWindowResize();
      }
    });

    const handleWindowResize = () => {
      const rect = refImageContainer && refImageContainer.getBoundingClientRect();
      const newHeight = rect ? rect.width : 0;

      if (newHeight > 0) {
        setHeight(newHeight);
      }
    };

    if (square && height !== null) {
      mergedProps = { style: { height }, ...props };
    }

    return (
      <ImageWrapper
        catalog={catalog}
        imageUrl={src}
        logo={logo}
        searchResult={searchResult}
        size={size}
        square={square}
        standard={standard}
        wide={wide}
        fullWidth={fullWidth}
        ref={onRefImageContainer}
        {...mergedProps}>
        {loading && <ClipLoader />}
      </ImageWrapper>
    );
  }
);

ImageBlock.displayName = 'ImageBlock';

ImageBlock.defaultProps = {
  catalog: false,
  logo: false,
  searchResult: false,
  size: 10,
  square: false,
  src: null,
  standard: true,
  wide: false,
  fullWidth: false,
  loading: false,
};

export default withTheme(ImageBlock);
