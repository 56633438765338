import React from 'react';
import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
} from '@chakra-ui/react';

import { useDeleteMarketingLink } from 'data-client/use-marketing-links';
import { useErrorResponseToast } from 'hooks/use-error-response-toast';
export default function MarketingLinkConfirmDeleteModal({ isOpen, onClose, deleteId, refetchMarketingLinks }) {
  const errorResponseToast = useErrorResponseToast();
  const { mutate: deleteMarketingLink, isLoading: waitingOnDelete } = useDeleteMarketingLink({
    onSuccess: () => {
      refetchMarketingLinks();
      onClose();
    },
    onError: err => {
      errorResponseToast(err);
      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={waitingOnDelete === false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Link Removal </ModalHeader>
        <ModalCloseButton disabled={waitingOnDelete} />
        <ModalBody>
          <Box>
            Are you sure you want to remove this link? Be sure you are not using this link on any of your socials before
            removing!
          </Box>
          <Box my="16px">Removing a link does not bring back a link credit.</Box>
        </ModalBody>
        <ModalFooter>
          <Button color="black100" borderColor="black100" disabled={waitingOnDelete} onClick={onClose}>
            Cancel
          </Button>
          <Button
            color="#C53232"
            borderWidth="0"
            isLoading={waitingOnDelete}
            onClick={() => deleteMarketingLink(deleteId)}>
            Remove Link
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
