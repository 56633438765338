import { DateTime } from 'luxon';
import React from 'react';
import { Td } from '@chakra-ui/react';
import DATE_TIME from '../../constants/DATE_TIME.gen.json';
import HEAP from '../../constants/HEAP.gen.json';

function DateCell({ value }) {
  const displayDate = value ? DateTime.fromISO(value).toFormat(DATE_TIME.SLASHED_8601) : 'N/A';
  return <Td data-heap={HEAP.PUBLIC.ANALYTICS.TYPE.ANALYTICS_TABLE_DATE_CELL}>{displayDate}</Td>;
}

export default DateCell;
