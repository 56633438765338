class Text extends String {
  get asKey() {
    return this.toLowerCase().split(' ').join('-');
  }

  get asTitle() {
    return this.toUpperCase();
  }

  get asString() {
    return String(this);
  }
}

const STRINGS = {
  NEW_TRACK: 'Add new track',
  EXISTING_TRACK: 'Add existing track',
  NEW_TRACK_VIDEO: 'Add new track/video',
  EXISTING_TRACK_VIDEO: 'Add existing track/video',
  NO_TRACKS: 'No tracks added to this release',
  UPLOAD_FILE: 'Upload file',
  UPLOADING_FILE: 'Uploading file ...',
  UPLOAD_COMPLETE: 'Upload complete',
  AUDIO_VIDEO_FILE: 'AUDIO/VIDEO FILE',
  DOLBY_ATMOS_FILE: 'DOLBY ATMOS FILE (OPTIONAL)',
  REPLACE_FILE: 'Replace file',
  ALREADY_DELIVERED_ISRC: 'ISRCs cannot be changed after a track has been delivered to DSPs',
};

export default Object.keys(STRINGS).reduce((obj, key) => {
  return {
    ...obj,
    [key]: new Text(STRINGS[key]),
  };
}, {});
