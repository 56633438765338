import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ChakraLink, Text, useTheme } from '@chakra-ui/react';
import Box from '../Box/Box';

const NewTag = ({ children }) => <Box variant="newTag">{children}</Box>;

export const NavMenuItem = ({ hasVisited, to, heapCode, isLoaded, isActive, body }) => {
  const theme = useTheme();
  return (
    <ChakraLink variant="headerNavLink" as={Link} to={to} data-heap={heapCode} isLoaded={isLoaded}>
      <Text color={isActive ? theme.colors.black50 : 'inherit'}>{body}</Text>
      {hasVisited ? <NewTag>NEW</NewTag> : null}
    </ChakraLink>
  );
};
