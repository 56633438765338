import { dataApiUrl } from '../url';
import AuthAxios, { NoAuthAxios } from '../auth-axios';

const DataApiUrl = dataApiUrl();

const Payment = () => {
  //TECH DEBT There is something odd where re-fetching this without a browser refresh return the origin instead of the desired url
  const getStripeLoginLink = (stripeAccountId, origin) =>
    AuthAxios.get(`${DataApiUrl}/payments/accounts/${stripeAccountId}/stripeLoginLink`, { params: { origin } }).then(
      res => res.data.data
    );

  const getRoyaltyTransfersByAccount = (accountId, startDate, endDate, limit, offset) =>
    AuthAxios.get(`${DataApiUrl}/payments/accounts/${accountId}/royaltyTransfers`, {
      params: { start_date: startDate, end_date: endDate, limit, offset },
    }).then(res => res.data.data.results);

  const getRoyaltyTransferSummariesByAccount = (accountId, startDate, endDate) =>
    AuthAxios.get(`${DataApiUrl}/payments/accounts/${accountId}/royaltyTransfers/summary`, {
      params: { start_date: startDate, end_date: endDate },
    }).then(res => res.data.data);

  const getCsvUrlByRoyaltyTransfer = transferId =>
    AuthAxios.get(`${DataApiUrl}/royaltyTransfers/${transferId}/statement`).then(res => res.data.data.signedUrl);

  const getRoyaltyTransferLabelStatement = transferId =>
    AuthAxios.get(`${DataApiUrl}/royaltyTransfers/${transferId}/label`).then(res => res.data.data);

  const getRoyaltyTransferPayeeStatement = transferId =>
    AuthAxios.get(`${DataApiUrl}/royaltyTransfers/${transferId}/payee`).then(res => res.data.data);

  const getCsvFromUrl = (url, filenamePrefix = '') =>
    NoAuthAxios.get(url, { responseType: 'blob' }).then(res => {
      const blob = new Blob([res.data], { type: 'text/csv' });
      const path = res.config.url.split('?')[0];
      const filename = `${filenamePrefix}${path.split('/')[path.split('/').length - 1]}`;

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const a = window.document.createElement('a');

        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    });

  const deleteStripeAccount = accountId =>
    AuthAxios.delete(`${DataApiUrl}/payments/accounts/${accountId}/stripeAccount`).then(res => res.data.data);

  return {
    getStripeLoginLink,
    getRoyaltyTransferSummariesByAccount,
    getRoyaltyTransfersByAccount,
    getCsvUrlByRoyaltyTransfer,
    getCsvFromUrl,
    getRoyaltyTransferLabelStatement,
    getRoyaltyTransferPayeeStatement,
    deleteStripeAccount,
  };
};

export default Payment;
