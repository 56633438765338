import * as React from 'react';
import { DateTime } from 'luxon';
import AnalyticsLayout from 'analytics/analytics-layout';
import useCachedMetrics from '../useCachedMetrics';
import * as API from '../../utils/API/API';
import { Text } from '@chakra-ui/react';
import { useAnalyticsContext } from '../analytics-context';
import { SLASHED_8601 } from '../LUXON_CONSTANTS';
import { countStreamsByDsp } from '../count-streams-by-dsp';
import { splitDataIntoSubsets } from '../split-data-into-subsets';
import { calculateDspDataStatus } from '../calculate-dsp-data-status';
import { SUPPORTED_DSPS } from '../supported-dsps';
import { SUCCESS_STATUS } from '../../constants/request-statuses';
import ImageCell from '../table/image-cell';
import DateCell from '../table/date-cell';
import TotalStreams from '../total-streams';
import TopTerritoriesTable from '../top-territories-table';
import DspPieChart from '../dsp-pie-chart';
import PerformanceChart from '../performance-chart';
import { release } from '../../utils/API/API';

const Metrics = API.metrics();
const formatDate = date => date.toFormat(SLASHED_8601);

const MastheadArtist = ({ data }) => (
  <Text position="absolute" top="-24px" fontSize="sm" color="cream.200">
    {data?.isSuccess && `${data.data?.display_artist_name || ''}`}
  </Text>
);

const { getArtworkForIds } = release();

async function trackToReleasesProvider({ domainData }) {
  const releases = domainData.data.data.series.releases;
  if (!releases || !releases.length) return null;
  const ids = releases?.map(r => r.asset_release_id);

  const urlMap = await getArtworkForIds(ids);
  return releases.map(r => ({
    ...r,
    img_url: urlMap.data[r.asset_release_id].url,
  }));
}

const filterUnavailableDates = ({ data, earliestViableDate }) => {
  if (!earliestViableDate) return data;

  return data.filter(d => {
    return DateTime.fromISO(d.date) >= earliestViableDate;
  });
};

const config = {
  table: {
    dataProvider: trackToReleasesProvider,
    title: 'Featured On',
    columns: [
      {
        Header: 'Release Title',
        accessor: 'title',
        CellComponent: ImageCell,
      },
      {
        Header: 'Release Date',
        accessor: 'release_date',
        CellComponent: DateCell,
      },
    ],
  },
  cards: [TotalStreams, TopTerritoriesTable, DspPieChart],
  masthead: {
    viewTypeText: 'Track',
    getTitle: trackData => {
      return `${trackData?.data?.data?.resource?.title || ''}`;
    },
    subtitle: {
      transformData: data => {
        const artists = data?.data?.artists || [];
        return artists.map(a => {
          const o = {};

          if (a.asset_party_id) {
            o.path = `/analytics/label/${a.user_group_id}/artists/${a.asset_party_id}`;
          }

          o.text = a.full_name;
          return o;
        });
      },
      Container: ({ children }) => <>by {children}</>,
      ArtistComponent: MastheadArtist,
    },
  },
  SearchComponent: false,
  PerformanceChart,
};

export default function TrackDashboard({ ...props }) {
  const {
    params: { trackId },
  } = props.match;

  const { updateAnalyticsState, currentMetricsInterval } = useAnalyticsContext();

  const domainKey = { trackId };

  function aggregateStats({ data }, { earliestViableDate }) {
    const streamCounts = data?.series?.dsps ? countStreamsByDsp(data.series.dsps) : [];
    const { subsetOfData } = splitDataIntoSubsets(streamCounts, currentMetricsInterval.interval);

    // filter out data that was from prior to the track's earliest release date
    // otherwise, the chart will display dates from prior to the track's release
    const dataSubset = filterUnavailableDates({ data: subsetOfData, earliestViableDate });

    const dspMetadata = calculateDspDataStatus(streamCounts);

    const dspTotalMap = SUPPORTED_DSPS.reduce((dspTotals, dsp) => {
      dspTotals[dsp] = 0;
      return dspTotals;
    }, {});

    data.rollups.dsps.forEach(d => {
      if (d.dsp_name in dspTotalMap) dspTotalMap[d.dsp_name] += d.streams;
    });

    const dsps = Object.keys(dspTotalMap)
      .map(dspName => ({
        streams: dspTotalMap[dspName],
        dspName,
      }))
      .sort((a, b) => b.streams - a.streams);

    const rollups = {
      territories: {
        status: SUCCESS_STATUS,
        data: data.rollups.territories,
      },
      dsps,
    };

    return {
      dspMetadata,
      totalStreams: data?.streams,
      dataSubset,
      prevPeriodDataSubset: [],
      streamPercentageChange: data?.time_over_time,
      rollups,
    };
  }

  const trackData = useCachedMetrics(
    ['track-metrics', ...Object.values(domainKey), currentMetricsInterval.interval],
    () =>
      Metrics.getTrackMetrics(trackId, {
        metrics_interval: currentMetricsInterval,
      }),
    {
      enabled: !!trackId,
      //If we can't get the track for some reason we should not try to get daily data because we will not have an isrc_code.
      onSuccess: response => {
        if (response) {
          const releases = response.data.series.releases;

          // determine the earliest date the track was released
          const earliestViableDate = releases.reduce((acc, cur) => {
            const earlierReleaseDateEpoch = Math.min(DateTime.fromISO(acc), DateTime.fromISO(cur.release_date));

            return DateTime.fromMillis(earlierReleaseDateEpoch);
          }, releases[0]?.release_date);

          updateAnalyticsState(aggregateStats(response, { earliestViableDate }));
        }
      },
    }
  );

  return (
    <AnalyticsLayout key={props.match.url} dashboardDomain="track" domainData={trackData} config={config} {...props} />
  );
}
