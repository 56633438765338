import React from 'react';
import { Flex, Box, Button, useToast, useTheme } from '@chakra-ui/react';
import { useReleaseArtwork } from 'hooks/use-release-artwork';
import Release from 'components/ReleaseList/release';
import { RiFileCopyLine } from 'react-icons/ri';

const COVER_ART_SIZE = `250`;

export default function ReleaseList({ releases = [], handleCreateClick }) {
  return (
    <Box>
      <Flex flexFlow="row wrap" justifyContent="flex-start">
        {releases.map(release => (
          <ReleaseItem key={release.id} release={release} handleCreateClick={handleCreateClick} />
        ))}
      </Flex>
    </Box>
  );
}

function ReleaseItem({ release, handleCreateClick }) {
  const toast = useToast();
  const theme = useTheme();
  const imgUrl = useReleaseArtwork(release?.id, `${COVER_ART_SIZE}x${COVER_ART_SIZE}`);

  const linkfireUrl = release.releaseMarketingLinks?.[0]?.marketingLink?.linkfire_url;

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(linkfireUrl);
    toast({ title: `Copied ${linkfireUrl} to clipboard.` });
  }

  const trackCount = release.releaseSets?.[0].setTracks.length || 0;

  return (
    <Box
      padding="1rem"
      position="relative"
      // mr="4.375rem"
      // mb="3.5rem"
      borderRadius="0.25rem"
      _hover={{ backgroundColor: theme.colors.black10 }}>
      <Release release={release}></Release>
      <Box mt="1.5rem">
        {linkfireUrl ? (
          <Button variant={'smallDark'} iconLeft={<RiFileCopyLine />} onClick={handleCopyToClipboard}>
            Copy Link
          </Button>
        ) : (
          <Button
            variant={'smallDark'}
            onClick={() =>
              handleCreateClick({
                type: 'release',
                imgUrl,
                title: release.title,
                artistName: release.display_artist_name,
                releaseId: release.id,
                trackCount,
              })
            }>
            Create Link
          </Button>
        )}
      </Box>
    </Box>
  );
}
