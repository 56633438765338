import React from 'react';
import { HStack, Flex, useTheme, Link } from '@chakra-ui/react';

import { SUPPORT_LINK } from 'constants/constants';
import HEAP from '../../constants/HEAP.gen.json';

const Footer = ({ withStickyFooter, solidFooter }) => {
  const theme = useTheme();

  const hoverStyle = { textDecoration: 'none', color: theme.colors.black60 };

  return (
    <Flex
      justifyContent={'center'}
      backgroundColor={solidFooter || withStickyFooter ? theme.colors.black10 : 'transparent'}
      position={withStickyFooter ? 'fixed' : 'relative'}
      width={'100%'}
      zIndex={withStickyFooter ? '101' : '10'}
      bottom={withStickyFooter ? 0 : null}
      height={'4.5rem'}>
      <Flex alignItems={'center'} height={'100%'} maxWidth={'80rem'} width="100%" justifyContent="flex-end">
        <HStack
          marginX={2}
          display={'flex'}
          alignItems={'center'}
          padding={'0 4rem'}
          height={'100%'}
          width="100%"
          justifyContent={{ base: 'center', sm: 'flex-end' }}
          spacing={8}>
          <Link
            color={theme.colors.black100}
            _hover={hoverStyle}
            href="/privacy.html"
            target="_blank"
            data-heap={HEAP.PUBLIC.FOOTER.TYPE.FOOTER_LINK_PRIVACY}>
            Privacy Policy
          </Link>

          <Link
            color={theme.colors.black100}
            _hover={hoverStyle}
            href="/terms.html"
            target="_blank"
            data-heap={HEAP.PUBLIC.FOOTER.TYPE.FOOTER_LINK_TOS}>
            Terms of Service
          </Link>

          <Link
            color={theme.colors.black100}
            _hover={hoverStyle}
            href={SUPPORT_LINK}
            target="_blank"
            data-heap={HEAP.PUBLIC.FOOTER.TYPE.FOOTER_LINK_SUPPORT}>
            Support & FAQ
          </Link>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default Footer;
