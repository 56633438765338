import React from 'react';
import Modal, { ModalHeaderWrapper } from 'features/Modal/modal';
import { Box } from '@chakra-ui/react';
import Button from 'components/Button/button';

const VideoReleaseWarningModal = ({ hideModal, isShowing, selectedModalId }) => {
  return (
    <>
      <Modal
        header={
          <ModalHeaderWrapper headerPadding={'1rem'}>
            <h4>Warning!</h4>
          </ModalHeaderWrapper>
        }
        isShowing={isShowing}
        config={{
          style: { height: 'fit-content', overflowY: 'hidden' },
          contentPadding: '1rem',
        }}
        onClose={() => hideModal('WARNING_VIDEO_RELEASE_DATE_TOO_SOON', selectedModalId)}>
        <Box>
          Selections within 2 weeks of the release live date will be delivered but cannot be guaranteed. Each streaming
          platform has different processing times. Please proceed with caution.
        </Box>
        <Box display="flex" justifyContent="flex-end" mt="4">
          <Button
            onClick={() => {
              hideModal('WARNING_VIDEO_RELEASE_DATE_TOO_SOON', selectedModalId);
            }}
            text="OK"
          />
        </Box>
      </Modal>
    </>
  );
};

export default VideoReleaseWarningModal;
