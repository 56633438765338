import styled from '@emotion/styled/macro';

const SubSection = styled.div`
  border-bottom: 2px solid ${props => props.theme.colors.white};
  padding: ${props => props.theme.space[8]};
  ${props => props.theme.breakpoint('max-width', 'sm')`
    padding: 1rem;
  `};

  &:last-child {
    border-bottom: 0;
  }
`;

export default SubSection;
