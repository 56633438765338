import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { useGetMembershipEvents } from '../../data-client/use-membership-events';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Grid, GridItem, Heading, Text, Button, SkeletonText } from '@chakra-ui/react';
import Box from '../../components/Box/Box';
import icons from '../../utils/icons';
import eventsBg from '../../assets/photos/events-bg.jpg';

const DISPLAY_AT_A_TIME = 4;
const INITIAL_DISPLAY = 4;

const UPGRADE_URL =
  '/settings?utm_content=events&utm_source=membership_portal&utm_campaign=in_product_upgrade&utm_term=interested+in+events&utm_medium=web_app#membership';

const EventCTA = ({ grayedOut, startsAt, link, attendUrl, ...props }) => {
  if (grayedOut) {
    return (
      <Button as="a" href={UPGRADE_URL} rightIcon={<icons.external />} {...props}>
        Upgrade to RSVP
      </Button>
    );
  } else if (startsAt > DateTime.now().plus({ minutes: 15 })) {
    return (
      <Button as="a" href={link} rightIcon={<icons.external />} disabled={grayedOut} {...props}>
        RSVP
      </Button>
    );
  } else {
    return (
      <Button as="a" href={attendUrl} rightIcon={<icons.external />} disabled={grayedOut} {...props}>
        Join Event
      </Button>
    );
  }
};

const EventCard = ({ title, summary, starts_at, link, attend_url, grayedOut, fullSize, totalEvents, ...props }) => {
  const startsAtDate = DateTime.fromISO(starts_at).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY);
  const time = DateTime.fromISO(starts_at).toLocaleString(DateTime.TIME_SIMPLE);

  if (fullSize) {
    return (
      <GridItem rowSpan={{ base: 1, md: totalEvents - 1 }} {...props}>
        <Box
          variant="darkText"
          backgroundImage={`url(${eventsBg})`}
          backgroundSize="cover"
          backgroundPosition="bottom"
          borderRadius=".5rem"
          minHeight="45rem"
          color="white"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          p="1.5rem">
          <Heading as="h3" size="md" noOfLines={2} title={summary.replace('\n', '')} color="white">
            {title}
          </Heading>
          <Text fontWeight="bold">{startsAtDate + ' / ' + time}</Text>
          <Text mt="1.5rem" noOfLines={5} fontSize="1rem">
            {summary}
          </Text>
          <Box>
            <EventCTA
              mt="1.5rem"
              grayedOut={grayedOut}
              startsAt={DateTime.fromISO(starts_at)}
              link={link}
              background="pink"
              color="white"
              attendUrl={attend_url}></EventCTA>
          </Box>
        </Box>
      </GridItem>
    );
  }

  return (
    <GridItem rowSpan={1} border="1px solid" borderColor="cream200" borderRadius=".5rem" p="1.5rem" {...props}>
      <Box variant="darkText">
        <Heading as="h3" size="md" noOfLines={2} title={summary.replace('\n', '')} fontWeight="500">
          {title}
        </Heading>
        <Text>{startsAtDate + ' / ' + time}</Text>
        <Text mt="1.5rem" noOfLines={4} fontSize="1rem">
          {summary}
        </Text>
      </Box>
      <EventCTA
        grayedOut={grayedOut}
        startsAt={DateTime.fromISO(starts_at)}
        link={link}
        attendUrl={attend_url}
        variant="naked"
        border="none"
        p="0"
        color="pink"
        mt="1rem"></EventCTA>
    </GridItem>
  );
};

const SkeletonCard = ({ fullSize }) => {
  return (
    <GridItem rowSpan={fullSize ? 2 : 1} colSpan={fullSize ? 2 : 1}>
      <Box borderLeft="1px solid" pl="1rem" variant="darkText">
        <SkeletonText noOfLines={2} height="2.4rem" />
        <SkeletonText noOfLines={1} width="200px" />
        {fullSize ? <SkeletonText mt="1.5rem" noOfLines={5} /> : null}
      </Box>
    </GridItem>
  );
};

export default function EventSection({ canAccessEvents }) {
  const [onPage, setOnPage] = useState(-1);
  const nextPageNumber = onPage + 1;

  const membershipEventsQuery = useGetMembershipEvents();
  const membershipEvents = membershipEventsQuery?.data || [];

  const events = membershipEvents?.sort((a, b) => a.starts_at - b.starts_at);

  const onClickHandler = () => setOnPage(nextPageNumber);

  return membershipEventsQuery.isLoading ? (
    <Grid templateColumns="repeat(4, 1fr)" gap={6} width={'100vw'} mt="2rem" mb="5rem">
      <SkeletonCard fullSize={true}></SkeletonCard>
      <SkeletonCard></SkeletonCard>
      <SkeletonCard></SkeletonCard>
    </Grid>
  ) : (
    <>
      <Grid templateColumns={{ base: '100%', md: 'repeat(2, minmax(0, 1fr))' }} gap={6} width={'100%'} mt="2rem">
        {events.slice(0, INITIAL_DISPLAY + DISPLAY_AT_A_TIME * nextPageNumber).map((event, i) => {
          return (
            <EventCard
              {...event}
              key={`events-section-item-${i}`}
              grayedOut={!canAccessEvents}
              fullSize={i === 0}
              totalEvents={events.length}
            />
          );
        })}
      </Grid>
      {canAccessEvents && events.length > INITIAL_DISPLAY + DISPLAY_AT_A_TIME * nextPageNumber ? (
        <Button onClick={onClickHandler}>More Events</Button>
      ) : null}
      {!canAccessEvents ? (
        <Box mt="1rem">
          Interested in Events?{' '}
          <Link
            to={
              'settings?utm_content=events&utm_source=membership_portal&utm_campaign=in_product_upgrade&utm_term=interested+in+events&utm_medium=web_app#membership'
            }
            as={RouterLink}
            fontWeight={'bold'}>
            Upgrade your subscription tier
          </Link>
        </Box>
      ) : null}
      <Text mb="5rem"></Text>
    </>
  );
}
