import { useDisclosure, useToast } from '@chakra-ui/react';
import { withTheme } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { API_STATUS_KEYS } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import AddArtistModal from 'settings/add-artist-modal';
import * as API from 'utils/API/API';
import { useAudioPlayer } from 'utils/audio-player';
import { generatePartySubmitObject } from 'utils/form';
import { buildStatus } from 'utils/helpers';
import { useUploader } from 'utils/uploader';
import { useReleaseForm } from 'utils/use-release-form';

import { useModals } from 'utils/useModals';
import TrackForm from '../../views/ManageRelease/Card/Tracks/track-form';
import SequenceControls from './sequence-controls';
import TrackHeader from './track-header';
import TrackPersistanceControls from './track-persistance-controls';
import TrackWrapper from './track-wrapper';
import * as validation from './validation';
import { sortArtists } from '../../utils/form';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
`;

const CollapsedContent = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0;
  position: relative;
  width: 100%;
  z-index: 5;
`;

const ExpandedContent = styled.div`
  box-sizing: border-box;
  margin-top: 1rem;
  padding: 1rem 0 5.75rem 2.5rem;
  position: relative;
  width: calc(100% - 4rem);
  z-index: 10;
`;

const initialManualDirtyState = { frameTime: false, contributors: false, artists: false };

const DraggableTrack = React.memo(
  ({
    updateDataStore,
    trackFormData: trackData,
    trackId,
    trackIndex,
    style,
    onTrackReorder,
    heapCodePrefix,
    defaultExpanded = false,
    handleRemoveNewTrackStub,
    contributorsList,
    onContributorModalOpen,
    hasBeenReleased,
    parentRelease,
    isVideoType,
    setFileType,
    ...props
  }) => {
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);
    const [framePickerTime, setFramePickerTime] = useState(0);
    const [manualIsDirty, setManualIsDirty] = useState(initialManualDirtyState);

    const toast = useToast();

    const { updateModalState, showModal, hideModal } = useModals();

    const {
      release,
      removeReleaseTrack,
      saveTracksStatus,
      setSaveTracksStatus,
      trackSequenceStatus,
      removeTrackStatus,
      createTrackStatus,
      setExpandedTracks,
    } = useReleaseForm();

    const { uploadStatus } = useUploader();

    const { fileLinks, tryGetTrackFiles, fileLinkStatus } = useAudioPlayer();

    const Track = API.track();
    const Split = API.split();

    const queryClient = useQueryClient();
    const [artists, setArtists] = React.useState(trackData.artists || []);
    const [activeIndex, setActiveIndex] = React.useState(null);

    const setArtistsHandler = state => {
      if (!formMethods_Track.formState.isDirty) {
        setManualIsDirty(prevState => ({
          ...prevState,
          artists: true,
        }));
      }
      setArtists(state);
    };

    const [contributors, setContributors] = React.useState(trackData.contributors || []);

    const setContributorsHandler = state => {
      if (!formMethods_Track.formState.isDirty) {
        setManualIsDirty(prevState => {
          return {
            ...prevState,
            contributors: true,
          };
        });
      }
      setContributors(state);
    };

    const [resourceType, setResourceType] = React.useState(trackData.resource_type);

    const [error, setError] = React.useState(null);

    // Modal form

    const modalId = `${parentRelease?.id}.${trackId}`;

    const formMethods_Track = useForm({ defaultValues: trackData, mode: 'onChange' });
    // Track form
    const hasManualDirtyFields = () => Object.values(manualIsDirty).reduce((a, v) => a || v, false);

    useEffect(() => {
      const fileError = fileLinkStatus[trackId]
        ? Object.keys(fileLinkStatus[trackId]).reduce(
            (a, encoding) => !!fileLinkStatus[trackId][encoding][API_STATUS_KEYS.ERROR] && a,
            true
          )
        : false;

      const fileUrl = trackId && fileLinks[trackId] ? fileLinks[trackId].url : null;

      updateModalState('framePicker', modalId, {
        formMethods: formMethods_Track,
        url: fileUrl,
        selectedTime: framePickerTime,
        isLoading: !fileError && !fileUrl && fileLinkStatus[trackId],
        sideEffect: time => {
          if (time !== framePickerTime) {
            setFramePickerTime(parseFloat(time));
            setManualIsDirty(prevState => ({
              ...prevState,
              frameTime: true,
            }));
          }
        },
      });
      // DEBT:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackId, fileLinks, fileLinkStatus, framePickerTime]);

    useEffect(() => {
      setExpandedTracks(prevState => ({
        ...prevState,
        [trackId]: isExpanded,
      }));
    }, [isExpanded, setExpandedTracks, trackId]);

    const expandCollapseForm = expand => {
      setIsExpanded(expand);
    };

    // Spring elements mount and unmount

    const handleDeleteClicked = () => {
      if (window.confirm('Delete this track. Are you sure?')) {
        setIsExpanded(false);
        removeReleaseTrack(trackIndex);
      }
    };

    // Data Handlers
    useEffect(() => {
      setManualIsDirty(prev => ({
        ...prev,
        frameTime: false,
      }));
      setFramePickerTime(parseFloat(trackData?.video_thumbnail_timestamp || 0));
      // DEBT:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trackData?.video_thumbnail_timestamp]);

    const isMetadataComplete = () => {
      return !(
        !trackData?.title ||
        !trackData?.artists?.length ||
        !trackData?.contributors.length ||
        !trackData?.parental_warning ||
        !trackData?.genre ||
        !trackData?.lang
      );
    };

    const handleSaveTrackMetadata = async data => {
      const nextSaveStep = !!trackId && data.isrc_code ? confirmIsrc : saveTrack;

      if (trackData.has_released) {
        if (
          window.confirm(
            'This release has already been delivered to streaming platforms. ' +
              'To ensure your updates are delivered, please re-submit this release after making changes. ' +
              'It may take a few business days for the updates to appear. Continue with save?'
          )
        ) {
          await nextSaveStep(data);
        } else {
          return;
        }
      }
      await nextSaveStep(data);
    };

    const confirmIsrc = async data => {
      const oldSplits = await Track.getSplits(trackId);
      const splitsWithNewIsrc = await Split.byIsrc(data.isrc_code);

      // skip confirmation if neither old/new ISRC has splits
      if (!oldSplits?.length && !splitsWithNewIsrc?.length) {
        await saveTrack(data);
        return;
      }

      // if forking away from existing splits into no splits, display no splits
      // if merging into existing splits from existing OR no splits, display existing splits
      showModal('confirmIsrc');
      updateModalState('confirmIsrc', {
        splits: splitsWithNewIsrc.length ? splitsWithNewIsrc : [],
        isrc_code: data.isrc_code,
        onConfirm: async () => {
          try {
            hideModal('confirmIsrc');
            await saveTrack(data);
          } catch (err) {
            console.error(err);
          }
        },
      });
    };

    const saveTrack = async submitObject => {
      setSaveTracksStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

      // Remap and clean up fields

      if (!trackId && !submitObject.primary_audio_file) {
        const errorMsg = 'Please add the AUDIO/VIDEO file';
        toast({
          title: errorMsg,
          status: 'error',
        });
        setSaveTracksStatus(buildStatus(errorMsg));
        return;
      }

      if (!artists?.length) {
        const errorMsg = 'Please add at least one artist';
        setError(errorMsg);
        setSaveTracksStatus(buildStatus(errorMsg));
        return;
      }

      if (artists) {
        if (artists.some(a => !a.roles?.length)) {
          const errorMsg = 'Please add at least one artist';
          setError(errorMsg);
          setSaveTracksStatus(buildStatus(errorMsg));

          return;
        }

        submitObject.artists = sortArtists(artists).map((party, idx) => generatePartySubmitObject(party, idx + 1));
      }
      setError(null);

      const contributorErrors = validation.validateContributors(contributors, resourceType) || [];

      if (contributorErrors.length) {
        const errorMsg = 'Error validating contributors';
        contributorErrors.map(title =>
          toast({
            title,
            status: 'error',
          })
        );
        setSaveTracksStatus(buildStatus(errorMsg));
        return;
      }

      submitObject.contributors = contributors.map((party, idx) => {
        // don't send blank platform_id object
        const contributorParty = generatePartySubmitObject(party, idx + 1);
        delete contributorParty.platform_id;
        return contributorParty;
      });

      if (resourceType) {
        submitObject.resource_type = resourceType;
      }

      //We compare includeExistingISRC/AtmosISRC to false
      //because the control may not appear on the gui which means
      //it becomes undefined. If it is not false then we should
      //keep the current value unless it is an empty string.
      if (submitObject?._meta?.includeExistingISRC === false) {
        submitObject.isrc_code = null;
      } else {
        submitObject.isrc_code = submitObject.isrc_code || null;
      }

      if (submitObject?._meta?.includeExistingAtmosISRC === false) {
        submitObject.atmos_isrc_code = null;
      } else {
        submitObject.atmos_isrc_code = submitObject.atmos_isrc_code || null;
      }

      // Video thumbnail

      if (resourceType !== 'Video' || isNaN(parseFloat(submitObject.video_thumbnail_timestamp))) {
        submitObject.video_thumbnail_timestamp = null;
      }

      // YT Shorts Preview
      if (resourceType !== 'Video' && submitObject._meta?.includeYTShortsPreview) {
        submitObject.preview = {
          ...submitObject.preview,
          start_point: submitObject.yt_shorts_preview_start_seconds,
          end_point: submitObject.yt_shorts_preview_end_seconds,
          release_date: submitObject.yt_shorts_preview_release_date,
        };
        delete submitObject.yt_shorts_preview_start_seconds;
        delete submitObject.yt_shorts_preview_end_seconds;
        delete submitObject.yt_shorts_preview_release_date;
      } else {
        submitObject.preview = {
          ...submitObject.preview,
          start_point: null,
          end_point: null,
          release_date: null,
        };
      }

      const update = await updateDataStore(submitObject);
      if (!update) {
        formMethods_Track.setError('OnSave', 'Saving Track Failed');
        setIsExpanded(true);
      } else {
        setIsExpanded(false);
        setManualIsDirty(initialManualDirtyState);
        formMethods_Track.reset();
      }
    };

    const disableForm =
      createTrackStatus[API_STATUS_KEYS.IN_PROGRESS] ||
      saveTracksStatus[API_STATUS_KEYS.IN_PROGRESS] ||
      trackSequenceStatus[API_STATUS_KEYS.IN_PROGRESS] ||
      removeTrackStatus[API_STATUS_KEYS.IN_PROGRESS];

    const onOpenClose = () => {
      if (!trackId) {
        handleRemoveNewTrackStub();
      }
      expandCollapseForm(!isExpanded);
    };

    const submitForm = async () => {
      const formData = formMethods_Track.watch();
      const validationResult = await formMethods_Track.trigger();
      if (validationResult) {
        await handleSaveTrackMetadata(formData);
      }
    };

    const onCreateArtist = async data => {
      await queryClient.invalidateQueries(['artists']);
      setArtists(prev => prev.map((a, artistIndex) => (artistIndex === activeIndex ? data : a)));
    };

    const addArtistModal = useDisclosure();

    return (
      <>
        <AddArtistModal disclosure={addArtistModal} useReleaseGroupId={true} onCreateArtist={onCreateArtist} />
        <Wrapper style={style}>
          <SequenceControls
            onTrackReorder={onTrackReorder}
            disabled={disableForm}
            sequence={trackData.sequence}
            trackId={trackId}
            editable={!hasBeenReleased}
          />
          <TrackWrapper hashover={(!isExpanded).toString()}>
            <CollapsedContent>
              <TrackHeader
                trackId={trackId}
                trackFormData={trackData}
                editable={true}
                formMethods={formMethods_Track}
              />
              <FormProvider {...formMethods_Track}>
                <TrackPersistanceControls
                  disabled={
                    (disableForm || uploadStatus === API_STATUS_KEYS.IN_PROGRESS) &&
                    uploadStatus !== API_STATUS_KEYS.ERROR
                  }
                  isExpanded={isExpanded}
                  handleDeleteClicked={handleDeleteClicked}
                  incomplete={isMetadataComplete() ? 0 : -1}
                  isDirty={formMethods_Track.formState.isDirty || hasManualDirtyFields() || !trackId}
                  addDetailsClickedHandler={onOpenClose}
                  onSubmit={submitForm}
                  canDelete={!!trackId}
                  errorText={error}
                  hasBeenReleased={hasBeenReleased}
                  trackId={trackId}
                />
              </FormProvider>
            </CollapsedContent>
            {isExpanded && (
              <ExpandedContent>
                <FormProvider {...formMethods_Track}>
                  {error && <span>{error}</span>}
                  <TrackForm
                    isVideoType={isVideoType}
                    setFileType={setFileType}
                    handleSubmit={submitForm}
                    formMethods={formMethods_Track}
                    trackId={trackId}
                    trackFormData={trackData}
                    heapCodePrefix={heapCodePrefix}
                    disableForm={disableForm}
                    disabled={disableForm || uploadStatus === API_STATUS_KEYS.IN_PROGRESS}
                    submitDisabled={!formMethods_Track.formState.isDirty && !hasManualDirtyFields()}
                    contributorsList={contributorsList}
                    onArtistModalOpen={addArtistModal.onOpen}
                    onContributorModalOpen={onContributorModalOpen}
                    artists={artists}
                    setArtists={setArtistsHandler}
                    setActiveIndex={setActiveIndex}
                    contributors={contributors}
                    setContributors={setContributorsHandler}
                    resourceType={resourceType}
                    setResourceType={setResourceType}
                    error={error}
                    {...props}
                    hasBeenReleased={hasBeenReleased}
                    release={release}
                  />
                </FormProvider>
              </ExpandedContent>
            )}
          </TrackWrapper>
        </Wrapper>
      </>
    );
  }
);

DraggableTrack.displayName = 'DraggableTrack';

DraggableTrack.defaultProps = {
  disabled: false,
  trackFormData: {},
  onClick: () => {},
};

export default withTheme(DraggableTrack);
