import React from 'react';
import { Flex, Icon, Select, Spinner, Text } from '@chakra-ui/react';
import { chakraTheme } from '../../theme';
import { FiXCircle as circleX } from 'react-icons/fi';
const SelectArtistSection = ({ artistResults, isLoading, onChange, ctaEnabled }) => {
  const onlyOneArtist = artistResults?.length === 1;

  return (
    <Flex direction="column" m="0 1rem 0 2rem">
      <Select
        mt={'2rem'}
        minWidth="14rem"
        variant="outline"
        color={chakraTheme.colors.black100}
        borderColor={chakraTheme.colors.black60}
        backgroundColor="white"
        icon={isLoading ? <Spinner /> : undefined}
        onChange={onChange}
        // if there is only one artist, we want to display their name instead of the option to select more than one
        placeholder={onlyOneArtist ? artistResults?.name : 'Select an artist'}
        disabled={onlyOneArtist}>
        {artistResults?.map(a => (
          <option key={`s4a-artist-${a.asset_party_id}`} value={a.asset_party_id}>
            {a.full_name}
          </option>
        ))}
      </Select>
      {/* The default value of "selectedArtist" is null, so it will not show this message on default */}
      {ctaEnabled ? null : (
        <Flex alignItems={'flex-start'} marginTop={'.75rem'}>
          <Icon mr={'.5em'} w={6} h={6} as={circleX} />
          <Text fontSize={'.75rem'}>
            This artist cannot get access as they have not yet released through Venice or are missing a spotify artist
            profile.
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default SelectArtistSection;
