import { useMutation } from 'react-query';
import { dataApiUrl } from '../utils/url';
import AuthAxios from '../utils/auth-axios';

const DataApiUrl = dataApiUrl();

function buildBasicEventRequest(eventUrl) {
  const url = `${DataApiUrl}/${eventUrl}`;
  return () => {
    return AuthAxios.get(url);
  };
}

const makeReferralCtaClickRequest = buildBasicEventRequest('member-events/ctaClickEvent');
const makeReferralLinkCopyRequest = buildBasicEventRequest('member-events/linkCopyEvent');

export function useReferralCtaClickEventMutation() {
  return useMutation(() => makeReferralCtaClickRequest());
}

export function useReferralLinkCopyEventMutation() {
  return useMutation(() => makeReferralLinkCopyRequest());
}
