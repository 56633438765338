import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import Icon from 'components/Icon/icon';
import { strokeNotFill } from '../../utils/icons';
import { disableSelection } from '../styles';

const LinkButtonWrapper = styled.div`
  ${disableSelection}
  align-items: center;
  color: ${props => props.theme.colors.text.linkButton[props.colorVariant].default};
  cursor: pointer;
  display: inline-flex;
  font-weight: ${props => props.theme.fonts.weights.bold};
  height: 1rem;
  overflow: hidden;
  position: relative;
  transition: color 250ms ease;

  i path {
    ${props =>
    strokeNotFill.includes(props.icon)
      ? `
			fill: transparent;
			stroke: ${props.theme.colors.text.linkButton[props.colorVariant].default};
		`
      : `
			fill: ${props.theme.colors.text.linkButton[props.colorVariant].default};
		`}
  }

  &:hover {
    color: ${props => props.theme.colors.text.linkButton[props.colorVariant].hover};

    i path {
      ${props =>
    strokeNotFill.includes(props.icon)
      ? `
				fill: transparent;
				stroke: ${props.theme.colors.text.linkButton[props.colorVariant].hover};
			`
      : `
				fill: ${props.theme.colors.text.linkButton[props.colorVariant].hover};
			`}
    }
  }

  &:active {
    color: ${props => props.theme.colors.text.linkButton[props.colorVariant].active};

    i path {
      ${props =>
    strokeNotFill.includes(props.icon)
      ? `
				fill: transparent;
				stroke: ${props.theme.colors.text.linkButton[props.colorVariant].active};
			`
      : `
				fill: ${props.theme.colors.text.linkButton[props.colorVariant].active};
			`}
    }
  }

  ${props =>
    props.disabled &&
    `
		color: ${props.theme.colors.text.linkButton[props.colorVariant].disabled};
		pointer-events: none;

		i path {
			${
  strokeNotFill.includes(props.icon)
    ? `
				fill: transparent;
				stroke: ${props.theme.colors.text.linkButton[props.colorVariant].disabled};
			`
          : `
				fill: ${props.theme.colors.text.linkButton[props.colorVariant].disabled};
			`
}
		}
	`}
`;

const LinkIcon = styled(Icon)`
  height: ${props => (props.small ? '0.75rem' : '1rem')};
  width: ${props => (props.small ? '0.75rem' : '1rem')};
`;

const LinkText = styled.div`
  font-size: ${props => (props.small ? props.theme.fonts.sizes.xxsm : props.theme.fonts.sizes.xsm)};
  font-weight: ${props => props.theme.fonts.weights.medium};

  ${props =>
    props.hasIcon &&
    props.hasText &&
    `
		margin-left: 0.5rem;
	`}
`;

const ClickShrowdDiv = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ClickShrowdAnchor = styled.a`
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;

const LinkButton = ({
  className,
  href,
  target,
  icon,
  text,
  primary,
  secondary,
  small,
  disabled,
  onClick,
  heapCode,
  ...props
}) => {
  const colorVariant = primary ? 'primary' : secondary ? 'secondary' : 'primary';

  const ClickShrowd = href ? (
    <ClickShrowdAnchor href={href} target={target} data-heap={heapCode} />
  ) : (
    <ClickShrowdDiv onClick={onClick} data-heap={heapCode} />
  );

  return (
    <LinkButtonWrapper className={className} colorVariant={colorVariant} disabled={disabled} icon={icon}>
      {icon && <LinkIcon type={icon} small={small} />}
      <LinkText small={small} hasIcon={!!icon} hasText={!!text}>
        {text}
      </LinkText>
      {props.children}
      {ClickShrowd}
    </LinkButtonWrapper>
  );
};

LinkButton.displayName = 'LinkButton';

LinkButton.defaultProps = {
  disabled: false,
  href: null,
  target: '_top',
  icon: null,
  onClick: () => null,
  primary: false,
  secondary: false,
  small: false,
  text: 'LinkButton',
};

export default withTheme(LinkButton);
