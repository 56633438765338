import GhostableRow from '../GhostableRow/ghostable-row';
import { Col } from 'react-styled-flexboxgrid';
import Dropdown from '../Controls/dropdown';
import { VerticalCenter } from '../../styles';
import StyledSelect from '../StyledSelect/styled-select';
import React from 'react';
import styled from '@emotion/styled/macro';
import Button from '../Button/button';
import HEAP from '../../constants/HEAP.gen.json';
import { deactivatedRoles } from 'constants/roles';

const RoleLabel = styled.span`
  color: ${props => props.theme.colors.brand.layer4};
  font-weight: ${props => props.theme.fonts.weights.medium};
`;

const RemoveButton = styled(Button)`
  position: absolute;
  right: -45px;
  top: 2px;
`;

const HiddenInput = styled.input`
  display: none;
`;

export default function FormRow({
  contributor,
  isRequired,
  options,
  onContributorModalOpen,
  updateContributors,
  contributorsList,
  errors,
  updateContributorRole,
  disabled,
  heapCodePrefix,
  removeContributor,
  name,
}) {
  const keyName = 'asset_party_id';
  const id = contributor?.[keyName];
  const contributorRoleValue = contributor?.roles[0];

  // When editing a deactivated role, add it back to options
  const deactivateRole = contributorRoleValue && deactivatedRoles.find(role => role.value === contributorRoleValue);
  const dropdownOptions = deactivateRole ? [...options, deactivateRole] : options;

  return (
    <GhostableRow>
      <HiddenInput type="text" name={`${name}[${id}].party_id`} defaultValue={contributor} />
      <Col xs={6}>
        <Dropdown
          name={`${id}-option`}
          placeholder="Select Contributor"
          buttonText="Add new contributor"
          onButtonClick={onContributorModalOpen}
          onChange={(v, selectedContributor) => updateContributors(contributor, selectedContributor)}
          options={contributorsList}
          defaultValue={id}
          doShowErrow={true}
          value={id}
          error={errors?.[`${id}_${contributorRoleValue}`] || errors?.[id]?.['party_id']}
        />
      </Col>
      <Col xs={6}>
        {isRequired ? (
          <VerticalCenter style={{ height: '42px' }}>
            <HiddenInput name={`${name}[${id}].role`} defaultValue={contributorRoleValue} />
            <RoleLabel>{contributorRoleValue}</RoleLabel>&nbsp;*
          </VerticalCenter>
        ) : (
          <StyledSelect
            name={`${name}[${id}].role`}
            defaultValue={contributorRoleValue}
            onChange={e => updateContributorRole(contributor, e.target.value)}
            error={errors[id] && errors[id]['role']}
            disabled={disabled}
            variant={'dark'}
            heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.COMMON.TYPE.ROLE}`}>
            <option value="">Select...</option>
            {dropdownOptions.map((option, i) => (
              <option defaultValue={contributorRoleValue} key={`name-roles-${i}-${option.value}`} value={option.value}>
                {option.label}
              </option>
            ))}
          </StyledSelect>
        )}
      </Col>
      {!disabled && !isRequired && (
        <RemoveButton
          onClick={() => removeContributor(contributor)}
          tertiary
          square
          icon="trash"
          heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.COMMON.TYPE.DELETE_ARTIST}`}
        />
      )}
    </GhostableRow>
  );
}
