import * as React from 'react';
import { Global, css } from '@emotion/react';

import NeueMachinaBlack from './assets/fonts/NeueMachina/NeueMachina-Black.otf';
import NeueMachinaBold from './assets/fonts/NeueMachina/NeueMachina-Bold.otf';
import NeueMachinaLight from './assets/fonts/NeueMachina/NeueMachina-light.otf';
import NeueMachinaMedium from './assets/fonts/NeueMachina/NeueMachina-Medium.otf';
import NeueMachinaRegular from './assets/fonts/NeueMachina/NeueMachina-Regular.otf';
import NeueMachinaUltraBold from './assets/fonts/NeueMachina/NeueMachina-Ultrabold.otf';
import NeueMachinaUltraLight from './assets/fonts/NeueMachina/NeueMachina-Ultralight.otf';

import KarlaBold from './assets/fonts/Karla/static/Karla-Bold.ttf';
import KarlaLight from './assets/fonts/Karla/static/Karla-Light.ttf';
import KarlaMedium from './assets/fonts/Karla/static/Karla-Medium.ttf';
import KarlaRegular from './assets/fonts/Karla/static/Karla-Regular.ttf';
import KarlaUltraBold from './assets/fonts/Karla/static/Karla-ExtraBold.ttf';
import KarlaUltraLight from './assets/fonts/Karla/static/Karla-ExtraLight.ttf';

export const Fonts = () => (
  <Global
    styles={css`
      // Karla uses static fonts
      @font-face {
        font-family: 'Karla';
        font-weight: 200;
        src: url(${KarlaUltraLight});
      }
      @font-face {
        font-family: 'Karla';
        font-weight: 300;
        src: url(${KarlaLight});
      }
      @font-face {
        font-family: 'Karla';
        font-weight: 400;
        src: url(${KarlaRegular});
      }
      @font-face {
        font-family: 'Karla';
        font-weight: 500;
        src: url(${KarlaMedium});
      }
      @font-face {
        font-family: 'Karla';
        font-weight: 700;
        src: url(${KarlaBold});
      }
      @font-face {
        font-family: 'Karla';
        font-weight: 800;
        src: url(${KarlaUltraBold});
      }

      // Neue Machina uses static fonts
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 200;
        src: url(${NeueMachinaUltraLight});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 300;
        src: url(${NeueMachinaLight});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 400;
        src: url(${NeueMachinaRegular});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 500;
        src: url(${NeueMachinaMedium});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 700;
        src: url(${NeueMachinaBold});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 800;
        src: url(${NeueMachinaUltraBold});
      }
      @font-face {
        font-family: 'Neue Machina';
        font-weight: 900;
        src: url(${NeueMachinaBlack});
      }
    `}
  />
);
