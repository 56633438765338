import React from 'react';
import {
  Modal as ChakraModal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button as ChakraButton,
} from '@chakra-ui/react';

/**
 * @param {object} props
 * @param {boolean} props.isShowing
 * @param {function} props.onCancel
 * @param {function} props.onConfirm
 */
const SubmittedReleaseConfirmExit = ({ isShowing, onCancel, onConfirm }) => {
  return (
    <ChakraModal isOpen={isShowing} onClose={() => onCancel()}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton marginTop="0.5rem" />

        <ModalHeader paddingLeft="1.5rem" paddingTop="2rem" fontSize="l" marginTop="0">
          Your changes have not been submitted to DSPs.
        </ModalHeader>

        <ModalBody paddingLeft="1.5rem" color="black100" marginTop="1rem" marginBottom="1rem">
          The changes you made have not been sent to DSPs and will be lost if you exit. If you would like to submit your
          updates, click "continue editing" and finish submitting your updates.
        </ModalBody>

        <ModalFooter paddingRight="1.5rem" paddingBottom="1.5rem">
          <ChakraButton variant="transparent" fontWeight="700" onClick={() => onCancel()}>
            Continue Editing
          </ChakraButton>
          <ChakraButton marginLeft="2rem" variant="smallDark" submit onClick={onConfirm}>
            Discard Changes
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default SubmittedReleaseConfirmExit;
