import React, { useState, useCallback } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { SORT_DIRECTIONS } from 'constants/constants';
import SearchBar from 'components/search-bar';
import { IconButton, Select, useTheme, Heading, Box, Flex } from '@chakra-ui/react';
import { RiSortAsc, RiArrowDownSLine, RiSortDesc } from 'react-icons/ri';
import HEAP from 'constants/HEAP.gen.json';
import _debounce from 'lodash/debounce';

const SearchResultSection = ({
  title,
  button,
  children,
  onSortChange,
  sortBy = [],
  onSearchTermChange,
  currentSearchTerm,
}) => {
  const theme = useTheme();
  const [localSearch, setLocalSearch] = useState(currentSearchTerm);

  const updateParentTerm = useCallback(
    _debounce(newSearchTerm => onSearchTermChange(newSearchTerm), 2000, {
      trailing: true,
    }),
    [_debounce, onSearchTermChange]
  );

  const handleSearchChange = newSearchTerm => {
    setLocalSearch(newSearchTerm);
    if (newSearchTerm.length > 2) {
      updateParentTerm(newSearchTerm);
      return;
    }

    if (!newSearchTerm) {
      updateParentTerm.cancel();
      onSearchTermChange(false);
      return;
    }
    updateParentTerm.cancel();
  };

  const handleSortChange = newFriendly => {
    const newOpts = sortBy.setSelectedByFriendly(newFriendly);
    onSortChange(newOpts);
  };

  const handleSortDirectionChange = () => {
    onSortChange(sortBy.toggleDirection());
  };

  return (
    <Box width="100%">
      <Flex
        paddingBottom="1rem"
        borderBottom={`1px solid ${theme.colors.black20}`}
        alignItems="center"
        justifyContent="space-between">
        <Heading as="h1" alignSelf="center" marginTop="0.5rem">
          {title}
        </Heading>
        {button}
      </Flex>
      <Box display="flex" margin="1rem 2rem" justifyContent="space-between" textAlign="right" rowGap="1rem">
        <Col xs={12} sm={6} md={4}>
          <SearchBar searchTerm={localSearch} onSearchTermChange={handleSearchChange} />
        </Col>
        <Flex
          xsOffset={6}
          xs={6}
          smOffset={0}
          sm={4}
          md={2}
          display="flex"
          alignItems="center"
          justifyContent="space-around">
          <Select
            variant="outline"
            icon={<RiArrowDownSLine />}
            onChange={e => handleSortChange(e.target.value)}
            disabled={sortBy.disabled}
            defaultValue={sortBy.find(sb => sb.selected)}
            style={{
              cursor: 'pointer',
              border: `1px solid ${theme.colors.black100}`, //selects require a large amount of work to enable variants
            }}>
            {sortBy.map((option, idx) => {
              return (
                <option heap={`${HEAP.PUBLIC.CATALOG.PREFIX.SORT_BY}-${option.param}`} key={option.param}>
                  {option.friendly}
                </option>
              );
            })}
          </Select>

          <IconButton
            square
            variant="outline"
            style={{ border: '1px solid', marginLeft: '0.6rem', color: theme.colors.black100 }}
            disabled={sortBy.friendly === 'Relevance'}
            icon={sortBy.direction === SORT_DIRECTIONS.ASC ? <RiSortAsc /> : <RiSortDesc />}
            onClick={handleSortDirectionChange}
          />
        </Flex>
      </Box>

      {children}
    </Box>
  );
};

SearchResultSection.defaultProps = {
  brightTitle: false,
};

export default SearchResultSection;
