import React from 'react';
import { RiBookmarkLine, RiArrowGoBackLine } from 'react-icons/ri';
import { VStack, Flex, Button, Box, Text, Icon, Center, Spinner } from '@chakra-ui/react';
import ThreadPreviewList from './thread-preview-list';

function SavedThreadsTab({
  isLoading,
  hasMoreData,
  threadModelCategories,
  savedThreadCount,
  onGoBackClick,
  onOpenThread,
  onDeleteThread,
  onLoadMoreThreads,
}) {
  return (
    <Box h="100%">
      <Flex justifyContent="space-between" alignItems="center" h="3rem" bg="cream.50" p=".5rem 1rem .5rem .5rem">
        <Flex alignItems="center">
          <Icon as={RiBookmarkLine} boxSize="1.25rem" />
          <Text ml=".5rem">
            You have saved{' '}
            <Text as="b">
              {savedThreadCount} message{savedThreadCount === 1 ? '' : 's'}
            </Text>{' '}
            from Co-Manager.
          </Text>
        </Flex>
        <Flex>
          <Button variant="transparent" onClick={onGoBackClick}>
            <Icon as={RiArrowGoBackLine} boxSize="1.25rem" />
            <Text fontSize="0.875rem" ml=".5rem">
              Back to Chat
            </Text>
          </Button>
        </Flex>
      </Flex>
      <VStack h="calc(100% - 3rem)" w="100%" pl="10rem" pr="10rem" pt="1.5rem" gap="1rem" overflow="auto">
        <ThreadPreviewList
          threadModelCategories={threadModelCategories}
          onOpenThread={onOpenThread}
          onDeleteThread={onDeleteThread}
        />
        {hasMoreData && (
          <Center>
            {isLoading ? (
              <Spinner />
            ) : (
              <Button variant="transparent" color="red" onClick={onLoadMoreThreads}>
                Load More Conversations
              </Button>
            )}
          </Center>
        )}
      </VStack>
    </Box>
  );
}

export default SavedThreadsTab;
