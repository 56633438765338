import React from 'react';
import { Link } from '@chakra-ui/react';
import ToggableBanner from 'features/ToggableBanner/toggable-banner';
import MEMBERSHIP from 'constants/MEMBERSHIP.gen.json';

const { GRACE_PERIOD_DAYS } = MEMBERSHIP;

export default function IsInGracePeriodBanner({ meData }) {
  const isInGracePeriod = meData?.subscription?.isInGracePeriod;
  if (!isInGracePeriod) return null;

  const meTier = meData?.subscription?.tier;
  const cancelDateObject = new Date(meData?.subscription?.currentPeriodEnd * 1000);
  const daysOfGraceForMe = GRACE_PERIOD_DAYS[meTier];

  cancelDateObject.setDate(cancelDateObject.getDate() + daysOfGraceForMe);
  const formatedDate = cancelDateObject.toLocaleDateString('en-us', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <ToggableBanner showBanner={isInGracePeriod}>
      <Link href="/settings#membership">
        {`Your subscription has expired and your music will be taken down on ${formatedDate}. Please update your payment method in settings to continue using the Venice platform.`}
      </Link>
    </ToggableBanner>
  );
}
