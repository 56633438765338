import React from 'react';
import { Box, IconButton, useClipboard, Flex, useToast, Tooltip } from '@chakra-ui/react';
import { FaApple, FaSoundcloud, FaSpotify } from 'react-icons/fa';
import { ReactComponent as VevoLogo } from '../../assets/vevo_logo_icon.svg';

export default function PlatformIds({ apple, spotify, soundcloud, vevo }) {
  const iconStyle = {
    color: 'black70',
    width: '1rem',
    height: '1rem',
    backgroundColor: 'transparent',
    mr: '0.2rem',
    size: 's',
    cursor: 'pointer',
    border: 'none',
  };

  const vevoStyle = {
    bg: 'black',
    width: '1rem',
    height: '1rem',
    cursor: 'pointer',
    border: 'none',
    _hover: { bg: 'black' },
  };

  const toast = useToast();

  const { onCopy: onAppleCopy } = useClipboard(apple);
  const { onCopy: onSpotifyCopy } = useClipboard(spotify);
  const { onCopy: onSoundcloudCopy } = useClipboard(soundcloud);
  const { onCopy: onVevoCopy } = useClipboard(vevo);

  const onAppleClick = () => {
    onAppleCopy();
    toast({
      title: `Copied Apple ID to clipboard`,
    });
  };
  const onSpotifyClick = () => {
    onSpotifyCopy();
    toast({
      title: `Copied Spotify ID to clipboard`,
    });
  };
  const onSoundcloudClick = () => {
    onSoundcloudCopy();
    toast({
      title: `Copied SoundCloud ID to clipboard`,
    });
  };

  const onVevoClick = () => {
    onVevoCopy();
    toast({
      title: `Copied Vevo Channel Name to clipboard`,
    });
  };

  return (
    <Flex gap=".3rem" alignItems="center">
      {!!apple && (
        <Tooltip label={apple} placement="top">
          <Box>
            <IconButton as={FaApple} {...iconStyle} onClick={onAppleClick} />
          </Box>
        </Tooltip>
      )}
      {!!spotify && (
        <Tooltip label={spotify} placement="top">
          <Box>
            <IconButton as={FaSpotify} {...iconStyle} onClick={onSpotifyClick} />
          </Box>
        </Tooltip>
      )}
      {!!soundcloud && (
        <Tooltip label={soundcloud} placement="top">
          <Box>
            <IconButton as={FaSoundcloud} {...iconStyle} onClick={onSoundcloudClick} />
          </Box>
        </Tooltip>
      )}
      {!!vevo && (
        <Tooltip label={vevo} placement="top">
          <Box>
            <IconButton as={VevoLogo} {...vevoStyle} onClick={onVevoClick} />
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
}
