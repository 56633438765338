const FLAGS = require('../constants/FLAGS.gen.json');

const isFlagEnabled = (flag, runtime = window.APP.RUNTIME) => flag[runtime];

const withFlag = (flag, func, shim = false, runtime = window.APP.RUNTIME) => {
  if (isFlagEnabled(flag, runtime)) {
    return func();
  }
  if (shim) return shim();
};

module.exports = {
  isFlagEnabled,
  withFlag,
  flags: FLAGS,
};
