import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Col, Row } from 'react-styled-flexboxgrid';
import { withRouter } from 'react-router-dom';
import { withTheme } from '@emotion/react/macro';
import { useQuery } from 'react-query';

import CatalogTile from 'components/CatalogTile/catalog-tile';

import * as API from '../../utils/API/API';
const Release = API.release();

const Container = styled.div``;

const ColWrapper = styled(Col)`
  ${props => props.theme.breakpoint('max-width', 'md')`
		// This works
	`}
`;

const TileWrapper = styled.div`
  margin-bottom: 2rem;
  flex-basis: calc(33.333% - 2rem);
  margin-right: 2rem;
  position: relative;

  ${props =>
    props.lgNopad === 'true' &&
    `
		flex-basis: 33.333%;
  	margin-right: 0;
	`}

  ${props => props.theme.breakpoint('max-width', 'md')`
 		flex-basis: calc(50% - 2rem);
  	margin-right: 2rem;
	`}

	${props =>
    props.mdNopad === 'true' &&
    props.theme.breakpoint('max-width', 'md')`
		flex-basis: 50%;
  	margin-right: 0;
	`}

	${props => props.theme.breakpoint('max-width', 'sm')`
 		flex-basis: 100%;
  	margin-right: 0;
	`}
`;

const GridWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const GridItem = ({ release, i }) => {
  const size = '720x720';

  const [imageUrl, setImageUrl] = useState();

  useQuery([release.id, size], () => Release.defaultArtwork(release.id, size), {
    onSuccess(data) {
      if (!data) return setImageUrl(null);
      const blob = new Blob([data]);
      setImageUrl(window.URL.createObjectURL(blob));
    },
  });

  return (
    <TileWrapper key={release.id} mdNopad={((i + 1) % 2 === 0).toString()} lgNopad={((i + 1) % 3 === 0).toString()}>
      <CatalogTile
        to={`/releases/${release.id}`}
        coverArt={imageUrl}
        trackTitle={release.title}
        artistNames={release.display_artist_name}
        releaseDate={release.release_date}
        releaseSets={release.releaseSets}
        dspPlatforms={release.dsp_platforms}
        audioTrackCount={release.audioTrackCount}
        videoTrackCount={release.videoTrackCount}
        clipTrackCount={release.clipTrackCount}
        data-cy={global.APP.RUNTIME === 'production' ? undefined : release.id}
      />
    </TileWrapper>
  );
};

const ResultsGrid = ({ results, pageIdx }) => {
  return (
    <Container>
      <Row>
        <ColWrapper xs={true}>
          <GridWrapper>
            {results.map((release, i) => (
              /*
              A SIMPLE IDX FOR A KEY IS PROBLEMATIC SINCE THERE ARE
              POTENTIALLY MULTIPLE ResultsGrid COMPONENTS PER CATALOG
              */
              <GridItem release={release} i={i} key={`grid-item-${release.id}-${pageIdx}-${i}`} />
            ))}
          </GridWrapper>
        </ColWrapper>
      </Row>
    </Container>
  );
};
ResultsGrid.defaultProps = {
  filters: {},
};

export default withRouter(withTheme(ResultsGrid));
