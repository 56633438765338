import styled from '@emotion/styled/macro';

const TrackWrapper = styled.div`
  background-color: ${props => props.theme.colors.brand.layer1};
  border-radius: 0.5rem;
  min-height: 80px;
  padding: 0 2rem 0 1.5rem;
  position: relative;
  transition: background-color 250ms ease;
  width: 100%;

  ${props =>
    props.hashover === 'true' &&
    `
    &:hover {
      background-color: ${props.theme.colors.brand.layer2};
    }`}
`;

export default TrackWrapper;
