import * as React from 'react';
import AnalyticsLayout from 'analytics/analytics-layout';
import useCachedMetrics from '../useCachedMetrics';
import * as API from '../../utils/API/API';
import { useAnalyticsContext } from 'analytics/analytics-context';
import { countStreamsByDsp } from '../count-streams-by-dsp';
import { splitDataIntoSubsets } from '../split-data-into-subsets';
import { calculateDspDataStatus } from '../calculate-dsp-data-status';
import { SUPPORTED_DSPS } from '../supported-dsps';
import { SUCCESS_STATUS } from '../../constants/request-statuses';
import { TrackTitleCell } from '../table/tracks-table-cells';
import { GotoArtistCell, StreamsCell, TrendCell } from '../table/artists-table-cells';
import TotalStreams from '../total-streams';
import TopTerritoriesTable from '../top-territories-table';
import DspPieChart from '../dsp-pie-chart';
import getImageUrlWithFallback from '../get-image-url-with-fallback';
import PerformanceChart from '../performance-chart';
import { useHistory } from 'react-router-dom';

const Metrics = API.metrics();

function tableSortAlphabetical(a, b, columnId) {
  // react-table not great at sorting strings for some reason
  const aColumnLowerCase = a?.values?.[columnId]?.toLowerCase() ?? '';
  const bolumnLowerCase = b?.values?.[columnId]?.toLowerCase() ?? '';
  if (aColumnLowerCase < bolumnLowerCase) return -1;
  if (aColumnLowerCase > bolumnLowerCase) return 1;

  return 0;
}

export default function ArtistDashboardContainer({ ...props }) {
  const {
    params: { groupId, artistId },
  } = props.match;

  const domainKey = { artistId, groupId };
  const history = useHistory();

  const config = {
    table: {
      title: 'All Tracks',
      dataProvider: ({ domainData }) =>
        domainData.data.data.series.isrcs.sort((a, b) => {
          const aTrackTitle = a?.track_title?.toLowerCase() ?? '';
          const bTrackTitle = b?.track_title?.toLowerCase() ?? '';
          if (aTrackTitle < bTrackTitle) return -1;
          if (aTrackTitle > bTrackTitle) return 1;

          return 0;
        }),
      onRowSelected: rowData => {
        history.push(`/analytics/track/${rowData.asset_track_id}`);
      },
      columns: [
        {
          Header: 'Tracks',
          accessor: 'tracks',
          CellComponent: TrackTitleCell,
          sortType: tableSortAlphabetical,
        },
        {
          Header: 'Streams',
          accessor: 'streams',
          CellComponent: StreamsCell,
        },
        {
          Header: '', //Header: 'Trends' TODO add trends back once wow/mom data is back,
          accessor: 'time_over_time',
          CellComponent: TrendCell,
        },
        {
          id: 'blank',
          disableSortBy: true,
          Header: '',
          CellComponent: GotoArtistCell,
        },
      ],
    },
    cards: [TotalStreams, TopTerritoriesTable, DspPieChart],
    masthead: {
      viewTypeText: 'Artist',
      getTitle: artistData => `${artistData?.data?.data?.resource?.full_name || ''}`,
      getImageAndAltText: artistData => {
        const allImageUrls = artistData?.data?.data?.resource?.image_url;
        const imageUrl = getImageUrlWithFallback(allImageUrls, 'small');
        const artistName = artistData?.data?.data?.resource?.full_name;

        return { imageUrl, altText: artistName };
      },
      subtitle: {
        transformData: data => {
          const artists = data?.data?.artists || [];
          return artists.map(a => {
            const o = {};

            if (a.asset_party_id) {
              o.path = `/analytics/label/${a.user_group_id}/artists/${a.asset_party_id}`;
            }

            o.text = a.full_name;
            return o;
          });
        },
        Container: ({ children }) => <>{children}</>,
      },
    },
    SearchComponent: false,
    PerformanceChart,
  };

  const { dailyData, updateAnalyticsState, currentMetricsInterval } = useAnalyticsContext();

  function aggregateStats({ data }) {
    const streamCounts = countStreamsByDsp(data.series.dsps);
    const { subsetOfData: dataSubset } = splitDataIntoSubsets(streamCounts, currentMetricsInterval.interval);
    const dspMetadata = calculateDspDataStatus(streamCounts);

    const dspTotalMap = SUPPORTED_DSPS.reduce((dspTotals, dsp) => {
      dspTotals[dsp] = 0;
      return dspTotals;
    }, {});

    data.rollups.dsps.forEach(d => {
      if (d.dsp_name in dspTotalMap) dspTotalMap[d.dsp_name] += d.streams;
    });

    const dsps = Object.keys(dspTotalMap)
      .map(dspName => ({
        streams: dspTotalMap[dspName],
        dspName,
      }))
      .sort((a, b) => b.streams - a.streams);

    const rollups = {
      territories: {
        status: SUCCESS_STATUS,
        data: data.rollups.territories,
      },
      dsps,
    };

    return {
      dspMetadata,
      totalStreams: data?.streams,
      dataSubset,
      prevPeriodDataSubset: [],
      streamPercentageChange: data?.time_over_time,
      rollups,
    };
  }

  const artistData = useCachedMetrics(
    ['artist-metrics', ...Object.values(domainKey), currentMetricsInterval.interval],
    () =>
      Metrics.getArtistMetrics(groupId, artistId, {
        metrics_interval: currentMetricsInterval,
      }),
    {
      enabled: !!artistId && !!groupId,
      onSuccess: data => {
        updateAnalyticsState(aggregateStats(data));
      },
      select: data => {
        if (data && data.series && data.series.isrcs) {
          data.series.isrcs.sort((a, b) => b.streams - a.streams);
        }
        return data;
      },
    }
  );

  return (
    <AnalyticsLayout
      key={props.match.url}
      dashboardDomain="artist"
      domainData={artistData}
      dailyData={dailyData}
      config={config}
      {...props}
    />
  );
}
