import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { PaymentHeader, SubscriptionInfo } from './simple-components';
import ManagePaymentInfo from './manage-payment-info';
import UpgradeSubscriptionModal from './upgrade-subscription-modal';
import { useGetStripeTiers } from 'data-client/use-membership';
import { UpdateExistingPayment } from './update-existing-payment';
import { useQuery } from 'react-query';
import Section from '../../settings/section';
import SubSection from '../../settings/sub-section';
import SectionHeaderBar from 'settings/section-header-bar';
import { useAccelerateTrialSubscription } from 'data-client/use-membership';

import * as API from 'utils/API/API';

const User = API.user();

export function SettingsPayment({ user }) {
  const { refetch: refetchUser } = useQuery(['user'], () => User.getMe());
  const [upgradeSubscriptionModalOpen, setUpgradeSubscriptionModalOpen] = useState(false);
  const [upgradeToTier, setUpgradeToTier] = useState(null);

  const discountId = null; //searchParams.get('discount');
  const { data: tiers } = useGetStripeTiers({ discountId });

  const accelerateTrialSubscription = useAccelerateTrialSubscription({
    onSuccess: () => {
      refetchUser();
    },
  });

  function handleUpgradeRequest(tierToUpgradeTo, interval, fromTrial) {
    setUpgradeToTier({ tierName: tierToUpgradeTo, interval, discountId });

    if (fromTrial) {
      accelerateTrialSubscription.mutate({ discountId });
    } else {
      setUpgradeSubscriptionModalOpen(true);
    }
  }

  const subscription = user?.subscription;
  const hasStripeSubscription = !!subscription;
  const periodEnd = subscription?.currentPeriodEnd;
  const isBannedSubscription = !!subscription?.isBanned;

  if (!periodEnd) {
    return (
      <Box mt="10rem" mb="10rem" ml="5rem">
        <Box>Please reach out to a Venice Music representative with any questions about your payment terms.</Box>
      </Box>
    );
  } else if (isBannedSubscription) {
    return (
      <Box mt="10rem" mb="10rem">
        <Box>Your account has been banned.</Box>
      </Box>
    );
  }

  const currentPeriodEnd = DateTime.fromSeconds(periodEnd).toLocaleString();
  const stripeSubscription = { cancelAtPeriodEnd: true, ...user.subscription, currentPeriodEnd };

  return (
    <Section>
      <SubSection>
        <SectionHeaderBar name="Membership"></SectionHeaderBar>
        <PaymentHeader {...stripeSubscription} />
      </SubSection>
      {hasStripeSubscription ? (
        <>
          {tiers && (
            <SubscriptionInfo
              stripeSubscription={stripeSubscription}
              handleUpgradeRequest={handleUpgradeRequest}
              tiers={tiers}
              allowTierUpgrades={isBannedSubscription === false && !stripeSubscription.onStripeEmailBlockList}
            />
          )}
          {!stripeSubscription.onStripeEmailBlockList && (
            <>
              <SubSection>
                <SectionHeaderBar name="Subscription Payment Method"></SectionHeaderBar>
                <ManagePaymentInfo stripeSubscription={stripeSubscription} />
              </SubSection>
              {isBannedSubscription === false && (
                <SubSection>
                  <SectionHeaderBar name="Renewal Options"></SectionHeaderBar>
                  <UpdateExistingPayment
                    stripeSubscription={stripeSubscription}
                    refetchUser={refetchUser}
                    userName={user.name}
                    userEmail={user.email}
                  />
                </SubSection>
              )}
              {upgradeToTier && (
                <UpgradeSubscriptionModal
                  modalOpen={upgradeSubscriptionModalOpen}
                  setModalOpen={setUpgradeSubscriptionModalOpen}
                  stripeSubscription={stripeSubscription}
                  upgradeToTier={upgradeToTier}
                  tiers={tiers}
                  refetchUser={refetchUser}
                />
              )}
            </>
          )}
        </>
      ) : (
        <Box mt="2.5rem">
          <Box>A Venice Music representative will reach out to you about a renewal.</Box>
        </Box>
      )}
    </Section>
  );
}
