import * as React from 'react';
import PrivateRoute from 'components/PrivateRoute/private-route';
import { Route } from 'react-router-dom';
import Statements from './statements';
import { PayeeView, LabelView } from './statement-details';

export default function StatementsRouter({ isPrivate }) {
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  return (
    <>
      <RouteComponent key={0} path="/statements" exact render={props => <Statements {...props} />} />
      <RouteComponent path="/statements/:id/label" exact render={props => <LabelView {...props} />} />
      <RouteComponent path="/statements/:id/payee" exact render={props => <PayeeView {...props} />} />
    </>
  );
}
