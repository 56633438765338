import React, { useContext, useEffect } from 'react';
import { CardContext } from '../card-context';

import { PRICE_TIERS } from 'constants/constants';
import useCardFlow from 'utils/use-card-flow';
import { useReleaseForm } from 'utils/use-release-form';
import { ButtonCol } from 'styles';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'components/Button/button';
import { Col, Row } from 'react-styled-flexboxgrid';
import numeral from 'numeral';
import FieldLabel from 'components/FieldLabel/field-label';
import ButtonToggle from 'components/ButtonToggle/button-toggle';

import { TrackRowContainer, TrackRowBody, CardLoadingWrapper, CardContentWrapper, CardHeaderBlock } from './card-style';

import { CARDS } from '../card';
import HEAP from '../../../constants/HEAP.gen.json';

const PriceTierTrack = () => {
  const CARD_ID = CARDS.PriceTierTrack;
  const {
    id,
    loadingAny,
    getCardData,
    getCardDataById,
    handleResetSaveAndPreview,
    afterSaveActions,
    NAV_DIRECTIONS,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { cardData, updateCard_Form, isReviewMode, _passthrough } = useCardFlow();

  const { releaseTracks } = useReleaseForm();

  const formMethods_PriceTierTrack = useForm();
  const formValues_PriceTierTrack = formMethods_PriceTierTrack.getValues({
    nest: true,
  });
  const errors_PriceTierTrack = formMethods_PriceTierTrack.errors;
  const otherCardData_PriceTierTrack = {
    ReleaseDetails: getCardDataById('ReleaseDetails', 'formData'),
    Tracklist: getCardDataById('Tracklist', 'tracklistData'),
  };

  const isEndOfFlow_PriceTierTrack =
    otherCardData_PriceTierTrack.ReleaseDetails?._meta?.releaseType === 'VideoSingle' &&
    otherCardData_PriceTierTrack.Tracklist?.length > 1;

  useEffect(() => {
    if (id === CARD_ID) {
      formMethods_PriceTierTrack.reset(getCardData('formData'));
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_PriceTierTrack.handleSubmit(data => save_PriceTierTrack(data, _passthrough.inducePreview))();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  const save_PriceTierTrack = (data, forcePreview = false, navigation = null) => {
    const saveData = { ...formValues_PriceTierTrack, ...data };

    updateCard_Form(id, saveData);

    const showPreview =
      navigation !== NAV_DIRECTIONS.BACK && (isEndOfFlow_PriceTierTrack || isReviewMode || forcePreview);
    afterSaveActions(showPreview, navigation);
  };

  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <FormProvider {...formMethods_PriceTierTrack}>
          <CardHeaderBlock omnishareas>
            <Row>
              <Col xs={6}>
                <h3>Track Price Tiers for Downloads</h3>
              </Col>
              <ButtonCol xs={6}>
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={formMethods_PriceTierTrack.handleSubmit(data =>
                      save_PriceTierTrack(data, false, NAV_DIRECTIONS.BACK)
                    )}
                    disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TIERTRACK_BUTTON_BACK}
                  />
                )}
                <Button
                  text={isEndOfFlow_PriceTierTrack || isReviewMode ? 'Save & Preview' : 'Next'}
                  rightIcon={isEndOfFlow_PriceTierTrack || isReviewMode ? null : 'chevronRight'}
                  loading={loadingSaveRelease || loadingAny}
                  onClick={formMethods_PriceTierTrack.handleSubmit(data =>
                    save_PriceTierTrack(data, false, NAV_DIRECTIONS.FORWARD)
                  )}
                  disabled={isFlowAnimating}
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TIERTRACK_BUTTON_NEXT}
                />
              </ButtonCol>
            </Row>
            <Row>
              <Col xs={4}>
                <p>Please note that the pricing below may vary or be adjusted by retail stores at their discretion.</p>
                <Row>
                  <Col xs={4}>Front: {numeral(PRICE_TIERS.FRONT).format('$0.00')}</Col>
                  <Col xs={4}>Mid: {numeral(PRICE_TIERS.MID).format('$0.00')}</Col>
                  <Col xs={4}>Back: {numeral(PRICE_TIERS.BACK).format('$0.00')}</Col>
                </Row>
              </Col>
            </Row>
          </CardHeaderBlock>
          <Row>
            <Col xs={12} md={6}>
              <FieldLabel text="Tracks" />
              {releaseTracks &&
                releaseTracks.map((track, i) => (
                  <TrackRowContainer key={i}>
                    <span>{numeral(track.data.sequence).format('00')}</span>
                    <TrackRowBody>
                      <span>{track.data.title}</span>
                      <ButtonToggle
                        name={`track_price_tiers[${i}]`}
                        options={[
                          { label: 'Front', value: 'Front' },
                          { label: 'Mid', value: 'Mid' },
                          { label: 'Back', value: 'Back' },
                        ]}
                        error={errors_PriceTierTrack['track_price_tiers']?.[i]}
                        setValue={formMethods_PriceTierTrack.setValue}
                        disabled={loadingSaveRelease || loadingAny}
                        ref={formMethods_PriceTierTrack.register({
                          required: true,
                        })}
                        heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TIERTRACK_BUTTON_TOGGLE}
                      />
                    </TrackRowBody>
                  </TrackRowContainer>
                ))}
            </Col>
          </Row>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};

export default PriceTierTrack;
