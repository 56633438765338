import React, { useState } from 'react';
import { Image } from '@chakra-ui/react';
import DragAndDropUpload from './drag-and-drop-upload';

function UploadedImageHandler({ files }) {
  //this depends on only having a single file. If the AC changes this will need to deal with multiple files
  const file = files?.[0];
  const reader = new FileReader();
  const [src, setSrc] = useState(null);

  reader.onload = function () {
    setSrc(reader.result);
  };

  reader.readAsDataURL(file);

  return <Image src={src} width="100%" height="100%" position={'relative'} top={'4px'} left={'4px'} />;
}

async function handleChange(files, validatorFunction, onChange) {
  const status = await validatorFunction(files).catch(err => err);
  if (status === 'invalid') return status;

  return onChange(files);
}

/**
 *
 *
 *  @param {Object} options
 *  @param {String}  options.allowedExts = ['jpg', 'png'] - array of allowed extensions
 *  @param {Function} options.onChange
 *  @param {String} options.errorMessage
 *  @param {Function} options.validationFunction - expects a function that returns a promise that can resolve or reject with the string 'invalid'
 *  @param {Blob} currentFile - The current file in memory
 *  @param {String} name - The html-valid name for the form field
 *  @param {*} props - any additional chakra props.
 *
 * @return {*}
 *
 * validationFunction expects a promise that resolves true if it is valid or rejects('invalid')
 */
function DragAndDropImageUpload({
  allowedExts = ['jpg', 'png'],
  onChange,
  validatorFunction = new Promise(resolve => resolve(true)),
  errorMessage,
  currentFile,
  name,
  ...props
}) {
  return (
    <DragAndDropUpload
      FileHandlerComponent={UploadedImageHandler}
      allowedExts={allowedExts}
      onChange={files => handleChange(files, validatorFunction, onChange)}
      errorMessage={errorMessage}
      currentFile={currentFile}
      name={name}
      maxNumberOfFiles={1} //no ac says we need more than one image ATM. Until that changes this works.
      {...props}
    />
  );
}

export default DragAndDropImageUpload;
