import React from 'react';
import amex from '../../assets/credit-cards/amex.svg';
import diners from '../../assets/credit-cards/diners.svg';
import discover from '../../assets/credit-cards/discover.svg';
import jcb from '../../assets/credit-cards/jcb.svg';
import mastercard from '../../assets/credit-cards/mastercard.svg';
import unionpay from '../../assets/credit-cards/unionpay.svg';
import visa from '../../assets/credit-cards/visa.svg';
import generic from '../../assets/credit-cards/generic.svg';

//Names supplied by stripe
const CC_IMAGES = {
  amex,
  diners,
  discover,
  jcb,
  mastercard,
  unionpay,
  visa,
  unknown: generic,
};

function CreditCardIndicator({ cardBrand }) {
  return <img src={CC_IMAGES[cardBrand] || CC_IMAGES['unknown']} width="30px" alt={`${cardBrand} Icon`} />;
}

export default CreditCardIndicator;
