import FallbackCoverArt from 'assets/light-theme-fallback-cover-art.png';
import { RiFileCopyLine as CopyIcon, RiPencilFill as EditIcon, RiDeleteBin7Line as DeleteIcom } from 'react-icons/ri';
import ReleaseStatusChip from 'components/ReleaseStatusChip/release-status-chip';

import ReleaseStatusTrackContainer from 'components/ReleaseStatusTrack/release-status-track-container';

import { API_STATUS_KEYS, EMPTY_RELEASE_TITLE } from 'constants/constants';
import MODEL_TYPES from '../../constants/MODEL_TYPES.gen.json';
import { LANGUAGES2 } from 'constants/dictionaries';
import { territories as dictTerritories } from 'constants/territories';
import _get from 'lodash/get';

import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { ClipLoader } from 'react-spinners';
import { Col, Row } from 'react-styled-flexboxgrid';

import * as API from 'utils/API/API';

import { convertBufferIntoImgUrl, pluralize } from 'utils/helpers';
import { useReleaseForm } from 'utils/use-release-form';
import { useModals } from 'utils/useModals';
import ReleaseStatusTrackContextProvider from '../../components/ReleaseStatusTrack/release-status-track-context';
import { formatTrackData } from '../../features/DraggableTrack/format-track-data';

import ViewWrapper from '../ViewWrapper/view-wrapper';
import { useAllDsps } from '../../data-client/use-dsps';
import useCurrentUser from '../../data-client/use-current-user';

import HEAP from '../../constants/HEAP.gen.json';
import { useDisclosure, Button, Image, Heading, Text, Flex, useTheme } from '@chakra-ui/react';
import Box from '../../components/Box/Box';
import TakedownModal from './takedown-modal';
import { useErrorResponseToast } from '../../hooks/use-error-response-toast';
import Small from 'components/Small/Small';

// hardcode selected set since we don't support selecting a different one for now
const SELECTED_SET = 0;

const DspPlatformGrid = ({ children, ...props }) => {
  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(auto-fill, minmax(140px, 1fr))"
      gridGap="8px"
      marginBottom="32px"
      {...props}>
      {children}
    </Box>
  );
};

const DspCell = ({ children, ...props }) => {
  return (
    <Flex flexDirection="row" alignItems="center" gap="4px" {...props}>
      {children}
    </Flex>
  );
};

const DspName = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Box
      width="100%"
      overflowX="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      fontWeight="bold"
      color={theme.colors.black60}
      {...props}>
      {children}
    </Box>
  );
};

const SectionHeading = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Flex alignItems={'center'} marginBottom={'1rem'} {...props}>
      {children}
    </Flex>
  );
};

const MetadataLabel = ({ children, ...props }) => (
  <Small variant="metaDataLabel" {...props}>
    {children}
  </Small>
);

const MetadataRow = ({ children, ...props }) => {
  return (
    <Flex marginBottom="1.5rem" {...props}>
      {children}
    </Flex>
  );
};

export default function ReleaseStatus({ match, ...props }) {
  const { release_id } = match.params;
  const { onClose, onOpen: takedownModalOnOpen, isOpen: takedownModalIsOpen } = useDisclosure();

  function takedownModalOnClose() {
    setChecked([]);
    setRequestSent(false);
    onClose();
  }

  const Release = API.release();

  const [currentUser] = useCurrentUser();

  const [dsps] = useAllDsps();
  const releaseQuery = useQuery([`release`, release_id], () => Release.get(release_id), {
    retry: 1,
  });

  const releaseFieldStatusQuery = useQuery([`releaseFieldStatus`, release_id], () => Release.getStatus(release_id));
  const releaseFieldDeliveredToQuery = useQuery([`releaseFieldDeliveredTo`, release_id], () =>
    Release.getDeliveredTo(release_id)
  );
  const errorResponseToast = useErrorResponseToast();
  const releaseTakedownMutation = useMutation(({ release_id, checked }) => Release.takedown(release_id, checked), {
    onSuccess: () => {
      setIsTakingDown(false);
      setRequestSent(true);
    },
    onError: errorResponseToast,
  });

  const setId = _get(releaseQuery, `data.sets[${SELECTED_SET}].id`, null);
  const releaseArtworkQuery = useQuery(
    [`releaseArtwork`, release_id],
    () => Release.artwork(release_id, setId, 0, '720x720'),
    { enabled: !!setId, refetchOnMount: 'always' }
  );

  const imgUrl = useMemo(() => {
    if (releaseArtworkQuery.isSuccess) {
      return convertBufferIntoImgUrl(releaseArtworkQuery.data);
    }
    return null;
  }, [releaseArtworkQuery.isSuccess, releaseArtworkQuery.data]);

  const { updateModalState, showModal, hideModal } = useModals();

  const { deleteRelease, deleteReleaseStatus } = useReleaseForm();

  const selectedReleaseTracks = _get(releaseQuery, `data.sets[${SELECTED_SET}].tracks`, []);
  const formattedReleaseTracks = selectedReleaseTracks.map((track, index) => formatTrackData(track, index + 1));

  const [checked, setChecked] = useState([]);
  const [isTakingDown, setIsTakingDown] = useState(false);
  const [requestSent, setRequestSent] = useState(false);

  const loadingRelease = releaseQuery.isLoading;

  useEffect(() => {
    if (!loadingRelease && releaseQuery.isError) {
      props.history.replace('/search/releases');
    }
  }, [loadingRelease, releaseQuery.isError, props.history]);

  const issueTakedown = async evt => {
    setIsTakingDown(true);
    evt.preventDefault();

    releaseTakedownMutation.mutate({ release_id, checked });
  };

  // Event Handlers

  const duplicateClickedHandler = async () => {
    showModal('duplicateRelease');
    updateModalState('duplicateRelease', {
      onConfirm: async () => {
        try {
          const newRelease = await Release.duplicate(release_id);
          props.history.push(`/releases/${newRelease.id}/edit/0/1?preview=true`);
        } catch (err) {
          console.error(err);
        }
        hideModal('duplicateRelease');
      },
    });
  };

  const editClickedHandler = () => {
    const type = releaseQuery?.data?.type;

    const url = type === 'Clip' ? `/manage/clip?id=${release_id}` : `/releases/${release_id}/edit/0/1`;
    props.history.push(url);
  };

  const deleteClickedHandler = async () => {
    if (window.confirm('Delete this draft?')) {
      const response = await deleteRelease(release_id);

      if (response) {
        props.history.replace(`/search/releases`);
      }
    }
  };

  const concatArtistName = release => {
    const mainArtistNames = release.artists?.filter(item => item.roles.includes('MainArtist')) ?? [];
    const featArtistNames = release.artists?.filter(item => item.roles.includes('FeaturedArtist')) ?? [];
    const mainArtists = (
      <React.Fragment>
        {mainArtistNames.map((artist, i) => (
          <React.Fragment key={i}>
            {/* eslint-disable-next-line */}
            {i > 0 && i == mainArtistNames.length - 1 ? ' and ' : ''}
            <span>
              {artist.full_name}
              {i < mainArtistNames.length - 2 ? ', ' : ''}
            </span>
          </React.Fragment>
        ))}
      </React.Fragment>
    );

    const featArtists = (
      <React.Fragment>
        {featArtistNames.length > 0 && 'featuring '}
        {featArtistNames.map((artist, i) => (
          <React.Fragment key={i}>
            {/* eslint-disable-next-line */}
            {i > 0 && i == featArtistNames.length - 1 ? ' and ' : ''}
            <span>
              {artist.full_name}
              {i < featArtistNames.length - 2 ? ', ' : ''}
            </span>
          </React.Fragment>
        ))}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {mainArtists} {featArtists}
      </React.Fragment>
    );
  };

  const territories = releaseQuery?.data?.territories || [];

  let completedDsps = new Set();
  let takenDownDsps = new Set();
  let canTakeDownDsps = new Set();
  if (releaseFieldDeliveredToQuery.data && dsps.length) {
    releaseFieldDeliveredToQuery.data.forEach(job => {
      const dspFromBackend = dsps.find(dsp => dsp.identifier === job.dsp);
      if (job.ern_type === MODEL_TYPES.ERN_TYPE.TAKEDOWN) {
        if (dspFromBackend) takenDownDsps.add(dspFromBackend.name);
      } else if (job.ern_type === MODEL_TYPES.ERN_TYPE.NEW || job.ern_type === MODEL_TYPES.ERN_TYPE.UPDATE) {
        if (dspFromBackend) {
          completedDsps.add(dspFromBackend.name);
          canTakeDownDsps.add({ label: dspFromBackend.name, value: dspFromBackend.identifier });
        }
      } else {
        console.warn('Distribution job has an unconfigured ERN type of ' + job.ern_type);
      }
    });
  }

  const releaseFromQuery = releaseQuery.data;
  return (
    <ViewWrapper designLayer={0} disableHorizontalScroll={true} {...props}>
      <Box padding="3.5rem 0 0" marginBottom="4rem">
        <Row>
          <Col xs={12} sm={3}>
            <Image src={imgUrl || FallbackCoverArt} alt="Release album art" borderRadius="8px" width="100%" />
            {releaseFromQuery && (
              <Box margin="2rem 0" variant="darkText">
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>UPC</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>{releaseFromQuery.asset_release_upc}</Text>
                  </Col>
                </MetadataRow>
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>Genre</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>{releaseFromQuery.genre}</Text>
                  </Col>
                </MetadataRow>
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>Language</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>{LANGUAGES2.find(l => l.value === releaseFromQuery.lang)?.label}</Text>
                  </Col>
                </MetadataRow>
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>Copyright</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>{releaseFromQuery.copyright_year}</Text>
                  </Col>
                </MetadataRow>
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>Label Line</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>{releaseFromQuery.display_label_name}</Text>
                  </Col>
                </MetadataRow>
                {releaseFromQuery.original_release_date && (
                  <MetadataRow>
                    <Col xs={5}>
                      <MetadataLabel>Original Release Date</MetadataLabel>
                    </Col>
                    <Col xs={7}>
                      <Text>{releaseFromQuery.original_release_date}</Text>
                    </Col>
                  </MetadataRow>
                )}
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>Territories</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>
                      {territories?.length === 1 && territories[0] === 'Worldwide'
                        ? 'Worldwide'
                        : territories?.length < 25
                        ? territories
                            .map(territory => (dictTerritories.find(t => t.value === territory) || {}).label)
                            .join(', ')
                        : territories?.length + pluralize(' Territory', territories?.length)}
                    </Text>
                  </Col>
                </MetadataRow>
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>Live Release Date</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>
                      {releaseFromQuery?.release_date &&
                        DateTime.fromISO(releaseFromQuery.release_date, {
                          setZone: true,
                        }).toFormat('D @ h:mma')}
                    </Text>
                  </Col>
                </MetadataRow>
                <MetadataRow>
                  <Col xs={5}>
                    <MetadataLabel>Pre-Order Date</MetadataLabel>
                  </Col>
                  <Col xs={7}>
                    <Text>
                      {releaseFromQuery.pre_order_release_date
                        ? DateTime.fromISO(releaseFromQuery.pre_order_release_date).toFormat('D')
                        : 'n/a'}
                    </Text>
                  </Col>
                </MetadataRow>
              </Box>
            )}
          </Col>
          <Col xs={12} sm={9}>
            <Row>
              <Col xs={12} sm={11} smOffset={1}>
                {releaseFromQuery && (
                  <>
                    <Flex alignItems="center" justifyContent="space-between">
                      <Box>
                        {releaseFieldStatusQuery.status === 'success' && (
                          <ReleaseStatusChip
                            status={releaseFieldStatusQuery.data}
                            releaseDate={releaseFromQuery.release_date}
                            updatedAt={releaseFromQuery.updated_at}
                          />
                        )}
                        {releaseFieldStatusQuery.status === 'loading' && <ClipLoader />}
                      </Box>
                      <Box variant="buttonGroup">
                        {releaseQuery?.data?.type === 'Clip' ? (
                          ''
                        ) : (
                          <Button
                            variant="small"
                            onClick={duplicateClickedHandler}
                            leftIcon={<CopyIcon />}
                            disabled={loadingRelease || !currentUser?.subscription?.isActive}
                            heapCode={HEAP.PUBLIC.RELEASE_STATUS.TYPE.RELEASE_STATUS_BUTTON_DUPLICATE}>
                            Duplicate
                          </Button>
                        )}
                        <Button
                          variant="small"
                          marginLeft="1.5rem"
                          onClick={editClickedHandler}
                          leftIcon={<EditIcon />}
                          disabled={loadingRelease || !currentUser?.subscription?.isActive}
                          heapCode={HEAP.PUBLIC.RELEASE_STATUS.TYPE.RELEASE_STATUS_BUTTON_EDIT}>
                          Edit
                        </Button>
                        {[null, 'Draft'].includes(releaseFieldStatusQuery.data) ? (
                          <Button
                            variant="small"
                            marginLeft="1.5rem"
                            key="delete-button"
                            onClick={deleteClickedHandler}
                            disabled={
                              loadingRelease ||
                              !currentUser?.subscription?.isActive ||
                              releaseFromQuery?.hasBeenSubmitted
                            }
                            loading={deleteReleaseStatus[API_STATUS_KEYS.IN_PROGRESS]}
                            leftIcon={<DeleteIcom />}>
                            Delete
                          </Button>
                        ) : (
                          <Button
                            variant="small"
                            marginLeft="1.5rem"
                            key="takedown-button"
                            onClick={takedownModalOnOpen}
                            disabled={
                              loadingRelease || !currentUser?.subscription?.isActive || completedDsps.size === 0
                            }>
                            Takedown
                          </Button>
                        )}
                      </Box>
                    </Flex>
                    <Box marginBottom="4rem" marginTop="2rem">
                      <Col xs={12}>
                        <Heading as="h1" variant="h1">
                          {[EMPTY_RELEASE_TITLE, null, undefined].includes(releaseFromQuery.title)
                            ? `Untitled`
                            : releaseFromQuery.title}
                        </Heading>
                        <Heading as="h2" variant="h2" fontSize={'1.5rem'}>
                          {concatArtistName(releaseFromQuery)}
                        </Heading>
                      </Col>
                    </Box>
                    <SectionHeading>
                      <Heading as="h4" variant="h4">
                        Tracks
                      </Heading>
                      &nbsp;&nbsp;(
                      {formattedReleaseTracks?.length})
                    </SectionHeading>
                  </>
                )}
                <Row>
                  {loadingRelease ? (
                    <ClipLoader />
                  ) : (
                    formattedReleaseTracks?.map((track, i) => (
                      <Col xs={12} key={track.id}>
                        <ReleaseStatusTrackContextProvider
                          trackFormData={track.data}
                          trackId={track.id}
                          trackIndex={i}
                          trackResourceType={track.resource}
                          heapCodePrefix={HEAP.PUBLIC.RELEASE_STATUS.PREFIX.RELEASE_STATUS_TRACKLIST_TRACK}
                          parentRelease={releaseFromQuery}>
                          <ReleaseStatusTrackContainer heapCodePrefix="release-status-tracklist-track" />
                        </ReleaseStatusTrackContextProvider>
                      </Col>
                    ))
                  )}
                </Row>
                <Row style={{ marginTop: 16 }}>
                  {releaseFieldDeliveredToQuery.status === 'success' && (
                    <>
                      {completedDsps.size > 0 ? (
                        <Col xs={12}>
                          <SectionHeading>
                            <Heading as="h4" variant="h4">
                              Distributed to the following platforms
                            </Heading>
                          </SectionHeading>
                          <DspPlatformGrid>
                            {Array.from(completedDsps).map(completedDsp => (
                              <DspCell key={completedDsp}>
                                <DspName title={completedDsp}>{completedDsp}</DspName>
                              </DspCell>
                            ))}
                          </DspPlatformGrid>
                        </Col>
                      ) : (
                        <Col xs={12}>
                          <SectionHeading>
                            <Heading as="h4" variant="h4">
                              {['Pre-delivery review', 'Reviewing updates'].includes(releaseFieldStatusQuery.data)
                                ? 'Distributing to the following platforms'
                                : 'Selected platforms'}
                            </Heading>
                          </SectionHeading>
                          <DspPlatformGrid>
                            {releaseFromQuery?.dsp_platforms?.length > 0
                              ? releaseFromQuery?.dsp_platforms?.map(dsp => {
                                  const dspFromBackend = dsps.find(dbDsp => dbDsp.identifier === dsp);
                                  if (!dspFromBackend) return null;
                                  return <DspName key={dsp}>{dspFromBackend?.name}</DspName>;
                                })
                              : 'No platforms selected'}
                          </DspPlatformGrid>
                        </Col>
                      )}
                      {takenDownDsps.size > 0 && (
                        <Col xs={12}>
                          <SectionHeading>
                            <Heading as="h4" variant="h4">
                              Taken down from the following platforms
                            </Heading>
                          </SectionHeading>
                          <DspPlatformGrid>
                            {Array.from(takenDownDsps).map(takeDownDsp => (
                              <DspCell key={takeDownDsp}>
                                <DspName title={takeDownDsp}>{takeDownDsp}</DspName>
                              </DspCell>
                            ))}
                          </DspPlatformGrid>
                        </Col>
                      )}
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Box>
      <TakedownModal
        onClose={takedownModalOnClose}
        isOpen={takedownModalIsOpen}
        issueTakedown={issueTakedown}
        isTakingDown={isTakingDown}
        dsps={[...canTakeDownDsps]}
        checked={checked}
        setChecked={setChecked}
        requestSent={requestSent}
      />
    </ViewWrapper>
  );
}
