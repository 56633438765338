import { Col, Row } from 'react-styled-flexboxgrid';
import { useGlobalData } from 'utils/global-data';
import React, { useState, useEffect, useMemo } from 'react';
import styled from '@emotion/styled/macro';
import { artistRoles, creditRoles } from '../../../../constants/roles';
import validator from 'validator';
import { LANGUAGES2, PARENTAL_WARNINGS } from '../../../../constants/dictionaries';
import ENUMS from '../../../../constants/ENUMS.gen.json';
import STRINGS from './strings';
import URLS from '../../../../constants/URLS.gen.json';
import { withTheme } from '@emotion/react/macro';
import HEAP from '../../../../constants/HEAP.gen.json';
import { useToast, Text, Link } from '@chakra-ui/react';
import InfoTooltip from 'components/InfoTooltip/info-tooltip';

import FieldLabel from '../../../../components/FieldLabel/field-label';
import TextInput from '../../../../features/TextInput/text-input';
import PartyArray from '../../../../features/PartyArray/party-array';
import AddArtistsForm from '../../../../components/AddArtistsForm/add-artists-form';
import Checkbox from '../../../../features/Checkbox/checkbox';
import StyledSelect from '../../../../components/StyledSelect/styled-select';
import UploadTrackButton from '../../../../features/DraggableTrack/upload-track-button';
import VideoQualityConfirmationModal from '../../../../features/VideoQualityConfirmationModal/video-quality-confirmation-modal';

import Tooltip from 'components/Tooltip/tooltip';
import { FlexCol, VerticalCenter } from '../../../../styles';
import Button from '../../../../components/Button/button';
import { useUserTierChecks } from '../../../../hooks/authorization-hooks';
import useGetPresignedTrackUrl from '../../../../hooks/use-get-presigned-track-url';
import { useGetArtistsList } from '../../../../data-client/track-parties';
import useCurrentUser from '../../../../data-client/use-current-user';
import { InputTrackTitle } from './input-track-title';
import { validateImmersiveAudio, validateAudio, validateVideo } from '../../../../utils/validate-metadata';
import { TrackFormBlockInput } from './track-form-block-input';
import { getMediaMetadata } from 'utils/metadata';

const BlockWrapper = styled.div`
  margin-bottom: 1rem;
`;

const FormCol = styled(Col)`
  margin-bottom: 1.5rem;
`;

const MAX_VIDEO_DESCRIPTION_LENGTH = 5000;
const MEMBERSHIP_URL = URLS.MEMBERSHIP[process.env.REACT_APP_RUNTIME];

function TrackForm({
  trackId,
  trackFormData,
  heapCodePrefix,
  disabled = false,
  submitDisabled = false,
  handleSubmit,
  formMethods,
  contributorsList,
  onArtistModalOpen,
  onContributorModalOpen,
  artists,
  setArtists,
  setActiveIndex,
  contributors,
  setContributors,
  resourceType,
  setResourceType,
  hasBeenReleased,
  release,
  isVideoType,
  setFileType,
}) {
  const { has_immersive_audio, duration, assets } = trackFormData;
  const toast = useToast();
  const { errors, register, setError, getValues } = formMethods;
  const { startReleaseSpinner, stopReleaseSpinner } = useGlobalData();

  const [trackFile, setTrackFile] = useState();
  const [atmosFile, setAtmosFile] = useState();
  const [atmosFileMetadata, setAtmosFileMetadata] = useState();

  const [isAtmosFileValid, setIsAtmosFileValid] = useState(false);
  const [isConfirmVidoeQualityOpen, setIsConfirmVidoeQualityOpen] = useState(false);

  // "has" flag states are only used for UI
  // trackFormData will be sent to the API.
  const [hasTrack, setHasTrack] = useState(!!trackId);
  const [hasImmersiveAudio, setHasImmersiveAudio] = useState(has_immersive_audio);
  const [assetDuration, setAssetDuration] = useState(duration || 10000.0);
  const [previousAssetName, setPreviousAssetName] = useState('');

  const { canReleaseAtmos, canReleaseVideo, canCreateYTShortsPreviews } = useUserTierChecks();

  const showValidationMessages = messageArr => {
    messageArr.forEach(message => {
      toast({
        title: message,
        status: 'error',
        duration: 20000,
        isClosable: true,
      });
    });
  };

  useEffect(() => {
    const assetMatcher = isVideoType ? 'video' : 'audio';
    const matchedAsset = assets.findLast(asset => asset.type === assetMatcher);
    const assetName = decodeURI(matchedAsset?.fileName || '');

    setPreviousAssetName(assetName);
  }, [assets, isVideoType]);

  useEffect(() => {
    if (!atmosFile) return;
    if (!atmosFileMetadata) return;

    if (!isAtmosFileValid) {
      formMethods.setError('immersive_audio_file', 'File Encoding is not valid');
      setAtmosFile(null);
    } else {
      formMethods.setValue('has_immersive_audio', true, { shouldDirty: true });
      formMethods.setValue('immersive_audio_file_metadata', atmosFileMetadata);
      formMethods.setValue('immersive_audio_file', atmosFile, { shouldDirty: true });
      setHasImmersiveAudio(true);
    }
    //Do not put formMethods in this dependency array. Is causes the ui to loop updates forever.
  }, [atmosFile, isAtmosFileValid, atmosFileMetadata]);

  const { data: artistsList, status: artistsStatus } = useGetArtistsList(release);

  const { data: presignedTrackUrl, status: preSignedTrackUrlStatus } = useGetPresignedTrackUrl({
    track_id: trackId,
    file_name: previousAssetName,
  });
  const InputTrackSubTitle = (
    <>
      <FieldLabel text="Version" />
      <TextInput
        colorVariant="dark"
        name="sub_title"
        disabled={disabled}
        error={errors['sub_title']}
        placeholder="If original, leave blank. (ex: Live, Remix, etc.)"
        ref={register({ required: false })}
        heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.SUB_TITLE}`}
      />
    </>
  );

  const InputArtists = (
    <BlockWrapper>
      <PartyArray
        name="artists"
        entityLabel="Artist(s)"
        artistRoleOptions={artistRoles}
        disabled={disabled}
        heapCodePrefix={heapCodePrefix}
        artistsList={artistsList}
        status={artistsStatus}
        existingArtists={trackFormData.artists}
        onArtistModalOpen={onArtistModalOpen}
        artists={artists}
        setArtists={setArtists}
        setError={formMethods.setError}
        errors={errors}
        setActiveIndex={setActiveIndex}
      />
    </BlockWrapper>
  );
  const InputContributors = (
    <BlockWrapper>
      <AddArtistsForm
        name="contributors"
        entityLabel="Credits"
        resourceType={resourceType}
        dropdownOptions={creditRoles}
        errors={errors['contributors']}
        setError={setError}
        disabled={disabled}
        ref={register({ requiredContributorNames: true })}
        heapCodePrefix={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.CREDITS}`}
        contributorsList={contributorsList}
        contributors={contributors}
        setContributors={setContributors}
        existingContributors={trackFormData.contributors}
        onContributorModalOpen={onContributorModalOpen}
      />
    </BlockWrapper>
  );

  const InputFramePicker = (
    <>
      <FormCol xs={6} md={3}>
        <FieldLabel text="Thumbnail frame" tooltip="Select a timestamp to use as a preview frame" />
        <TextInput
          name="video_thumbnail_timestamp"
          placeholder="0.01"
          error={errors['video_thumbnail_timestamp']}
          disabled={disabled}
          ref={register({
            min: { value: 0.01, message: 'Enter seconds (> 0), e.g. 3.28' },
            max: { value: assetDuration, message: `Enter seconds (< ${assetDuration}), e.g. 3.28` },
            valueAsNumber: true,
            validate: value =>
              value === '' ||
              (validator.isFloat(value) &&
                // A timestamp of 0 is invalid for extracting a thumbnail (see VP-212)
                parseFloat(value) > 0) ||
              `Enter seconds (> 0 and < ${assetDuration}), e.g. 3.28`,
          })}
        />
      </FormCol>
    </>
  );

  const InputAtmosISRC = (
    <>
      {!hasBeenReleased && (
        <FormCol xs={6} md={4}>
          <FieldLabel text=" " />
          <VerticalCenter style={{ height: '42px' }}>
            <Checkbox
              light
              name="_meta.includeExistingAtmosISRC"
              label={'Enter your own Dolby Atmos ISRC'}
              disabled={disabled || hasBeenReleased}
              ref={register({ required: false })}
              heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.CHECKBOX_ISRC}`}
            />
            <InfoTooltip text="This code uniquely identifies an album Dolby Atmos track and helps music stores track sales of a track in a release. Leave this option unchecked if you do not own an ISRC for your Dolby Atmos track and Venice will auto-generate a ISRC. If you have pre-purchased your own ISRC or are migrating a previously distributed release to Venice, select and enter your ISRC." />
          </VerticalCenter>
        </FormCol>
      )}
      {formMethods.watch('_meta.includeExistingAtmosISRC') && (
        <FormCol xs={6} md={4} style={{ minHeight: 64 }}>
          <>
            <FieldLabel text="Dolby Atmos ISRC" required={!hasBeenReleased} />
            <Tooltip text={hasBeenReleased && STRINGS.ALREADY_DELIVERED_ISRC.asString}>
              <TextInput
                name="atmos_isrc_code"
                placeholder="Enter Dolby Atmos ISRC"
                disabled={disabled || hasBeenReleased}
                error={errors['atmos_isrc_code']}
                ref={register({
                  validate: value => validator.isISRC(value.toUpperCase()) || 'Must be valid ISRC',
                })}
              />
            </Tooltip>
          </>
        </FormCol>
      )}
    </>
  );

  const InputISRC = (
    <>
      {!hasBeenReleased && (
        <FormCol xs={6} md={4}>
          <FieldLabel text=" " />
          <VerticalCenter style={{ height: '42px' }}>
            <Checkbox
              light
              name="_meta.includeExistingISRC"
              label={'Enter your own stereo ISRC'}
              disabled={disabled || hasBeenReleased}
              ref={register({ required: false })}
              heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.CHECKBOX_ISRC}`}
            />
            <InfoTooltip text="This code uniquely identifies a stereo album track and helps music stores track sales of a track in a release. Leave this option unchecked if you do not own an ISRC for your stereo track and Venice will auto-generate a ISRC. If you have pre-purchased your own ISRC or are migrating a previously distributed release to Venice, select and enter your ISRC." />
          </VerticalCenter>
        </FormCol>
      )}
      <FormCol xs={6} md={3} style={{ minHeight: 64 }}>
        {formMethods.watch('_meta.includeExistingISRC') && (
          <>
            <FieldLabel text="Stereo ISRC" required={!hasBeenReleased} />
            <Tooltip text={hasBeenReleased && STRINGS.ALREADY_DELIVERED_ISRC.asString}>
              <TextInput
                name="isrc_code"
                placeholder="Enter Stereo ISRC"
                disabled={disabled || hasBeenReleased}
                error={errors['isrc_code']}
                ref={register({
                  validate: value => validator.isISRC(value.toUpperCase()) || 'Must be valid ISRC',
                })}
              />
            </Tooltip>
          </>
        )}
      </FormCol>
    </>
  );

  const InputParentalWarning = (
    <>
      <FieldLabel text="Explicit Content" required />
      <StyledSelect
        name="parental_warning"
        error={errors['parental_warning']}
        disabled={disabled}
        ref={register({ required: true })}
        variant={'dark'}
        heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.EXPLICIT}`}>
        <option value="">Select...</option>
        {PARENTAL_WARNINGS.map((warning, i) => (
          <option key={i} value={warning.value}>
            {warning.label}
          </option>
        ))}
      </StyledSelect>
    </>
  );

  const InputGenre = (
    <>
      <FieldLabel text="Genre" required />
      <StyledSelect
        name="genre"
        disabled={disabled}
        error={errors['genre']}
        ref={register({ required: true })}
        heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.GENRE}`}>
        <option value="">Select...</option>
        {Object.values(ENUMS.ASSET_GENRE).map((genre, i) => (
          <option key={i} value={genre}>
            {genre}
          </option>
        ))}
      </StyledSelect>
    </>
  );

  const InputLanguage = (
    <>
      <FieldLabel text="Language" required />
      <StyledSelect
        name="lang"
        disabled={disabled}
        error={errors['lang']}
        ref={register({ required: true })}
        heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.LANGUAGE}`}>
        <option value="">Select...</option>
        {LANGUAGES2.map((language, i) => (
          <option key={i} value={language.value}>
            {language.label}
          </option>
        ))}
      </StyledSelect>
    </>
  );

  const InputYTShortsPreview = (
    <>
      <FormCol xs={12} md={12}>
        <FieldLabel text=" " />
        <VerticalCenter style={{ height: '42px' }}>
          <Checkbox
            light
            name="_meta.includeYTShortsPreview"
            label={'Create YouTube Shorts Preview (Optional)'}
            disabled={disabled}
            ref={register({ required: false })}
          />
          <InfoTooltip text="Automatically creates a YT Shorts Preview that leads to the full length track after release. Only takes effect if you distribute to YT Music AND Content ID." />
        </VerticalCenter>
      </FormCol>
      {formMethods.watch('_meta.includeYTShortsPreview') &&
        (canCreateYTShortsPreviews ? (
          <>
            <FormCol xs={12}>
              <Text>
                A short preview (up to 60 seconds) will be automatically generated by YouTube if you provide information
                here and select both YouTube and YouTube Content ID during distribution. The generated short will not be
                shown on your artist channel or profile. It will show up in YT Shorts Feeds and Search only.{' '}
                <Link
                  href="https://support.venice.tech/distribution/what-is-a-youtube-shorts-preview"
                  textDecoration="underline"
                  target="_blank">
                  Learn more.
                </Link>
              </Text>
              <Text marginTop={'1.5rem'}>
                In order to utilize this feature, <b>all tracks</b> on this release must conform to the{' '}
                <Link
                  href="https://support.venice.tech/distribution/can-i-submit-my-release-to-youtube-content-id"
                  textDecoration={'underline'}
                  target="_blank">
                  YouTube Content ID eligibility requirements.
                </Link>
              </Text>
            </FormCol>
            <FormCol xs={6} md={4}>
              <FieldLabel
                text="Preview Days"
                tooltip="The number of days before the track release date that the preview will be available on YouTube Shorts."
                required
              />
              <TextInput
                name="yt_shorts_preview_release_date"
                placeholder="7"
                error={errors['yt_shorts_preview_release_date']}
                disabled={disabled}
                ref={register({
                  min: { value: 1, message: 'Enter days (> 0), e.g. 7' },
                  max: { value: 30, message: 'Enter days (< 30), e.g. 7' },
                  valueAsNumber: true,
                  required: true,
                  validate: value =>
                    value === '' || (validator.isInt(value) && parseInt(value, 10) > 0) || 'Enter days (< 30), e.g. 7',
                })}
              />
            </FormCol>
            <FormCol xs={6} md={4}>
              <FieldLabel text="Start Timestamp (seconds)" required />
              <TextInput
                name="yt_shorts_preview_start_seconds"
                placeholder="0"
                error={errors['yt_shorts_preview_start_seconds']}
                disabled={disabled}
                ref={register({
                  min: { value: 0, message: 'Enter seconds, e.g. 3' },
                  max: { value: assetDuration, message: `Enter seconds (< ${assetDuration}), e.g. 3` },
                  valueAsNumber: true,
                  required: true,
                  validate: value => {
                    const endSeconds = getValues('yt_shorts_preview_end_seconds') || Number.MAX_SAFE_INTEGER;
                    return (
                      value === '' ||
                      (validator.isInt(value) && parseInt(value, 10) < endSeconds) ||
                      'Enter seconds (< end timestamp), e.g. 0'
                    );
                  },
                })}
              />
            </FormCol>
            <FormCol xs={6} md={4}>
              <FieldLabel text="End Timestamp (seconds)" required />
              <TextInput
                name="yt_shorts_preview_end_seconds"
                placeholder="30"
                error={errors['yt_shorts_preview_end_seconds']}
                disabled={disabled}
                ref={register({
                  min: { value: 0, message: 'Enter seconds, e.g. 30' },
                  valueAsNumber: true,
                  required: true,
                  validate: value => {
                    const startSeconds = getValues('yt_shorts_preview_start_seconds') || 0;
                    return (
                      value === '' ||
                      (validator.isInt(value) &&
                        parseInt(value, 10) > startSeconds &&
                        parseInt(value, 10) <= startSeconds + 60) ||
                      'Enter seconds (> start timestamp and < 60s total), e.g. 30'
                    );
                  },
                })}
              />
            </FormCol>
          </>
        ) : (
          <>
            <Col>
              <Text>
                This feature is only available to our Pro members.{' '}
                <Link href={`/settings#membership`} textDecoration={'underline'}>
                  Upgrade to Pro here (save your progress first)
                </Link>
                .
              </Text>
            </Col>
          </>
        ))}
    </>
  );

  const [currentUser] = useCurrentUser();

  const onSubmit = async (...args) => {
    await handleSubmit(trackFile, atmosFile, ...args);
  };

  const handleTrackFile = async e => {
    const file = e.target.files[0];

    const metadata = await getMediaMetadata(file, { onStart: startReleaseSpinner, onEnd: stopReleaseSpinner });

    const durationInSeconds = metadata.general?.Duration ? Number.parseFloat(metadata.general.Duration) : 10000.0; //10000.0 seconds is a default value

    if (durationInSeconds < 2) {
      showValidationMessages(['Tracks must be at least 2 seconds in duration to be accepted by streaming services']);
      formMethods.setError('primary_audio_file', 'Duration is less than 2 seconds');
      throw new Error('Duration is less than 2 seconds');
    }

    const isVideo = file.type.includes('video');

    const validateAsAdmin = currentUser?.system_admin;
    const validationResult = isVideo
      ? validateVideo({ metadata, file, validateAsAdmin })
      : validateAudio({ metadata, file, validateAsAdmin });

    if (!validationResult.isFileValid) {
      showValidationMessages(validationResult.messages);
      formMethods.setError('primary_audio_file', 'File Encoding is not valid');
      throw new Error('Invalid video file');
    }

    setFileType(file.type);

    const resourceType = isVideo ? 'Video' : 'SoundRecording';
    formMethods.setValue('resource_type', isVideo ? 'Video' : null);
    setResourceType(resourceType);

    setAssetDuration(isNaN(durationInSeconds) ? 10000.0 : durationInSeconds);

    if (isVideo) {
      setIsConfirmVidoeQualityOpen(true);
    }

    formMethods.setValue('primary_audio_file_metadata', metadata);
    formMethods.setValue('primary_audio_file', file, { shouldDirty: true });
    setTrackFile(file);
    setHasTrack(true);
  };

  [
    'has_immersive_audio',
    'immersive_audio_file',
    'immersive_audio_file_metadata',
    'primary_audio_file',
    'primary_audio_file_metadata',
    'resource_type',
  ].forEach(str => {
    formMethods.control.register(str);
  });

  const handleAtmosFile = async e => {
    const file = e.target.files[0];
    const metadata = await getMediaMetadata(file, { onStart: startReleaseSpinner, onEnd: stopReleaseSpinner });

    const validateAsAdmin = currentUser?.system_admin;
    const validationResult = validateImmersiveAudio({ metadata, file, validateAsAdmin });

    if (!validationResult.isFileValid) {
      showValidationMessages(validationResult.messages);
    }

    setIsAtmosFileValid(validationResult.isFileValid);
    setAtmosFile(file);
    setAtmosFileMetadata(metadata);
  };

  const handleAtmosFileRemove = async () => {
    await formMethods.setValue('has_immersive_audio', false, { shouldDirty: true });
    await formMethods.setValue('immersive_audio_file', null, { shouldDirty: true });
    setAtmosFile(null);
    setAtmosFileMetadata(undefined);
    setHasImmersiveAudio(false);
  };

  const emptyTrackForm = () => {
    setTrackFile(null);
    setHasTrack(false);
  };

  const isAtmosUploadEnabled = resourceType === 'SoundRecording' && canReleaseAtmos;
  const isAtmosUploadVisible = resourceType !== 'Video' && canReleaseAtmos;
  const acceptedUploadTypes = canReleaseVideo ? 'video/mp4, .mov, audio/wav, audio/mp3' : 'audio/wav, audio/mp3';

  const UploadButtonLabel = useMemo(
    () =>
      canReleaseVideo ? (
        <FieldLabel text={'AUDIO/VIDEO FILE'} tooltip={`MP4, WAV, MOV, and MP3 files are supported.`} />
      ) : (
        <FieldLabel text={'AUDIO FILE'} tooltip={`WAV and MP3 files are supported.`} />
      ),
    [canReleaseVideo]
  );

  return (
    <>
      <form onSubmit={formMethods.handleSubmit(onSubmit)} autoComplete="off">
        <br />
        <Row>
          <Col xs={12}>
            <Row>
              <FormCol xs={12} md={6}>
                {UploadButtonLabel}
                <VerticalCenter>
                  <UploadTrackButton
                    file={trackFile}
                    disabled={disabled}
                    fileHandler={handleTrackFile}
                    error={errors['primary_audio_file']}
                    hasFile={hasTrack}
                    uploadedFile={previousAssetName}
                    uploadedFileUrl={presignedTrackUrl?.url}
                    accept={acceptedUploadTypes}
                  />
                </VerticalCenter>
              </FormCol>
            </Row>
            <Row>
              {isAtmosUploadVisible && (
                <FormCol xs={12} md={6}>
                  <FieldLabel text={STRINGS.DOLBY_ATMOS_FILE.asString} />
                  <VerticalCenter>
                    <UploadTrackButton
                      file={atmosFile}
                      disabled={!isAtmosUploadEnabled}
                      fileHandler={handleAtmosFile}
                      accept={'audio/wav, audio/flac'}
                      error={errors['immersive_audio_file']}
                      hasFile={hasImmersiveAudio}
                      onRemove={handleAtmosFileRemove} // Remove button only for Atmos
                    />
                  </VerticalCenter>
                </FormCol>
              )}
            </Row>
            <Row>{InputISRC}</Row>
            {hasImmersiveAudio && <Row>{InputAtmosISRC}</Row>}
            <Row>
              <FormCol xs={12} md={6}>
                <InputTrackTitle
                  disabled={disabled}
                  error={errors['title']}
                  register={register}
                  heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.TITLE}`}
                />
              </FormCol>
              <FormCol xs={12} md={6}>
                {InputTrackSubTitle}
              </FormCol>
            </Row>
            <Row>
              <FormCol xs={12}>{InputArtists}</FormCol>
            </Row>
            <Row>
              <FormCol xs={12} md={6}>
                {InputContributors}
              </FormCol>
            </Row>
            <Row>{isVideoType && InputFramePicker}</Row>
            <Row>
              <FormCol xs={4} md={3}>
                {InputParentalWarning}
              </FormCol>
              <FormCol xs={4} md={3}>
                {InputGenre}
              </FormCol>
              <FormCol xs={4} md={3}>
                {InputLanguage}
              </FormCol>
            </Row>
            <Row>
              <FlexCol column xs={12} md={6}>
                <TrackFormBlockInput
                  label={'Lyrics'}
                  formName="lyrics"
                  disabled={disabled}
                  register={register}
                  error={errors['lyrics']}
                  dataHeap={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.LYRICS}`}
                />
              </FlexCol>
              {isVideoType ? (
                <FlexCol column xs={12} md={6}>
                  <TrackFormBlockInput
                    label={'Video Description'}
                    error={errors['video_description']}
                    tooltip="This will display below your video on your Vevo YouTube"
                    formName="video_description"
                    disabled={disabled}
                    register={register}
                    dataHeap={`${heapCodePrefix}-${HEAP.PUBLIC.MANAGE_RELEASE.TYPE.VIDEO_DESCRIPTION}`}
                    validate={value => {
                      if (value.length > MAX_VIDEO_DESCRIPTION_LENGTH) {
                        return 'Description must be less than 5000 characters';
                      }
                      return true;
                    }}
                  />
                </FlexCol>
              ) : null}
            </Row>
          </Col>
        </Row>
        {isVideoType === false ? <Row>{InputYTShortsPreview}</Row> : null}
        <Button submit invisible disabled={disabled || submitDisabled} />
      </form>
      <VideoQualityConfirmationModal
        variant="dark"
        isOpen={isConfirmVidoeQualityOpen}
        onClose={() => setIsConfirmVidoeQualityOpen(false)}
        noFooter={true}
        headerText={'Confirm video quality'}
        emptyTrackForm={emptyTrackForm}
      />
    </>
  );
}

export default withTheme(TrackForm);
