import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import asModal from 'features/Modal/as-modal';
import { Box, Flex, VStack, Spacer, HStack, Square, Text, Center, SimpleGrid } from '@chakra-ui/react';
import MessageContainer from '../../components/Co-ManagerMessageContainer/co-manager-message-container';
import UserMessageInput from '../../components/Co-ManagerUserMessageInput/co-manager-user-message-input';
import CoManagerAIIcon from 'features/Co-manager/co-manager-ai-icon';
import { chakraTheme } from '../../theme';
import { say, listen } from '../../utils/API/co-manager';
import useMessages from '../../hooks/use-co-manager-messages';
import { useCoManagerMessageAdditivePageQuery } from '../../data-client/use-co-manager-thread-data';
import coManagerLogo from '../../assets/co-manager-logo.svg';
import icons from '../../utils/icons';

/**
 * Create a message object
 *
 * @param {string} text
 * @param {import('../../hooks/use-co-manager-messages').MessageType} type
 * @param {number} [timestamp]
 * @returns {import('../../hooks/use-co-manager-messages').MessageWithoutId}
 */
const makeMessage = (text, type, timestamp = Date.now(), metadata = {}) => ({
  text,
  type,
  timestamp,
  ...metadata,
});

const THREAD_RELOAD_CHECK_TIMEOUT_MS = 7 * 60 * 1000;

function CoManagerToolbar({ children, ...props }) {
  return (
    <HStack
      spacing="1rem"
      shouldWrapChildren={false}
      {...props}
      align="start"
      flexShrink={0}
      maxWidth="100%"
      overflowX="auto">
      {children}
    </HStack>
  );
}

/**
 * @type {React.FC<{{ initialMessages: Array<import('../../hooks/use-co-manager-messages').MessageWithoutId>, userInputStyles, children: React.ReactElement, height: string  }}>}
 * @return {JSX.Element}
 */
function CoManagerModal(props) {
  const {
    threadId,
    initialMessages,
    guidedPrompts,
    userInputStyles,
    children,
    height = 'calc(100vh - 7.5rem - 100px)',
    setConnectionStatusFn,
    setSuggestions,
    onReloadThread,
    isTrialMode,
  } = props;

  const reloadThreadTimeoutRef = useRef();
  const { messages, appendMsg, setTyping, resetList } = useMessages();
  const {
    modelData: oldMessageModelData,
    pageArgs: oldMessagePageArgs,
    isProcessing: isProcessingOldMessageQuery,
    loadNextPage: loadNextOldMessagePage,
    reset: resetOldMessages,
  } = useCoManagerMessageAdditivePageQuery({
    threadId,
    reactQueryArgs: {
      enabled: !!threadId,
    },
  });

  const formattedOldMessages = useMemo(() => {
    return oldMessageModelData.models.map(({ chat_bot_thread_message_id: id, message_content: { role, content } }) => ({
      id,
      type: role,
      text: content,
    }));
  }, [oldMessageModelData]);

  const [sending, setSending] = useState(false);
  const [serverConnectionState, setServerConnectionState] = useState(null);
  const assignedToken = useRef(undefined);
  const sseCleanup = useRef(undefined);

  // We only want to render a single toolbar
  const toolbarChildren = React.Children.toArray(children).find(
    child => React.isValidElement(child) && child.type === CoManagerToolbar
  );

  const connectionStatusCallback = useCallback(setConnectionStatusFn, [setConnectionStatusFn]);

  const onSend = useCallback(
    (userInputText, metadata = {}) => {
      if (!userInputText.startsWith(RATING_INPUT)) {
        setSending(true);
        // Store local message
        appendMsg(makeMessage(userInputText, 'user', undefined, metadata));
      }

      // Send message to the server
      say(userInputText, assignedToken.current, metadata.rating, metadata.guide, threadId, metadata.promptLibrary).then(
        res => {
          try {
            setTyping(true);
            let messageAccumulator = '';

            sseCleanup.current =
              sseCleanup.current ??
              listen(
                res.data.token,
                event => {
                  assignedToken.current = res.data.token;
                  if (event.actor === 'delta') {
                    messageAccumulator += event.text.value;
                    appendMsg(makeMessage(messageAccumulator, 'typing', event.timestamp, event.metadata));
                  } else if (event.actor === 'suggestions') {
                    setSuggestions(JSON.parse(event.text));
                  } else {
                    setTyping(false);
                    messageAccumulator = '';
                    appendMsg(makeMessage(event.text, event.actor, event.timestamp, event.metadata));

                    if (messages.length < 3) {
                      onReloadThread();
                    }
                    clearTimeout(reloadThreadTimeoutRef.current);
                    reloadThreadTimeoutRef.current = setTimeout(onReloadThread, THREAD_RELOAD_CHECK_TIMEOUT_MS);
                    setSending(false);
                  }
                },
                state => {
                  setServerConnectionState(state);
                  connectionStatusCallback(state);
                }
              );
          } catch (error) {
            setSending(false);
            console.error(error);
            appendMsg(
              makeMessage(
                "I'm sorry, your message response could not be processed correctly. Please try again.",
                'ai',
                Date.now(),
                {}
              )
            );
          }
        },
        err => {
          const error = 'Error - Refresh page and try again.';
          setServerConnectionState(error);
          connectionStatusCallback(error);
          setSending(false);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      appendMsg,
      setTyping,
      connectionStatusCallback,
      setSuggestions,
      threadId /*, onReloadThread  messages <- causes infinite loop */,
    ]
  );

  const RATING_INPUT = 'Conversation Rating: ';

  /**
   * Save conversation rating to the back end
   */
  const sendRating = useCallback(
    rating => {
      onSend(`${RATING_INPUT}${rating}`, { rating });
    },
    [onSend]
  );

  // Stop listening for Server-Sent Events after unmount
  useEffect(() => {
    if (initialMessages.length > 0 && initialMessages.at(-1).type === 'user') {
      onSend(initialMessages.at(-1).text, { guide: initialMessages.at(-1).guide });
    }

    // Reset signal
    if (initialMessages.length === 0) {
      // This code will be called when component is removed
      if (typeof sseCleanup.current === 'function') sseCleanup.current();
      sseCleanup.current = undefined;
      assignedToken.current = undefined;
      setSending(false);
      setTyping(false);
      setSuggestions([]);
      resetList();
      resetOldMessages();
      setServerConnectionState('Ready');
      clearTimeout(reloadThreadTimeoutRef.current);
    }

    return () => {
      clearTimeout(reloadThreadTimeoutRef.current);
      // This code will be called when component is removed
      if (typeof sseCleanup.current === 'function') sseCleanup.current();
      sseCleanup.current = undefined;
    };
  }, [initialMessages, onSend, resetList, setSuggestions, resetOldMessages, setTyping]);

  return (
    <Flex flexDirection="column" height={height} alignItems="center" margin={0}>
      {messages.length === 0 && isProcessingOldMessageQuery === false && !threadId && guidedPrompts ? (
        <Center style={{ flexGrow: 1, position: 'relative', width: '100%', height: '100%' }}>
          <VStack height="100%" justifyContent="space-between">
            <Box height="5rem"></Box>
            <Box marginBottom="4rem">
              <CoManagerAIIcon style={{ height: '5rem', width: '5rem' }} />
            </Box>
            <SimpleGrid mt="1rem" columns="2" spacing="1rem" width="100%" maxWidth="48rem" flexWrap="wrap">
              {guidedPrompts.map((prompt, index) => (
                <Box key={index}>
                  <Box
                    border="1px solid"
                    borderColor="cream200"
                    color="black80"
                    borderRadius="1rem 1rem 0 1rem"
                    height="4rem"
                    width="100%"
                    userSelect="none"
                    alignItems="start"
                    cursor="pointer"
                    display="inline-flex"
                    flexDirection="column"
                    justifyContent="start"
                    padding="0 1rem"
                    overflow="hidden"
                    fontSize="1rem"
                    onClick={() => {
                      if (isTrialMode && index > 0) {
                        window.location.href = `/settings#membership`;
                        return;
                      }

                      onSend(prompt.prompt, { guide: prompt.isGuide ? prompt.prompt : undefined, promptLibrary: true });
                    }}>
                    <Text fontWeight="600" pt="0.5rem" color={isTrialMode && index > 0 ? 'black30' : 'black80'}>
                      {isTrialMode && index > 0 ? (
                        <>
                          <icons.unlock style={{ display: 'inline', marginRight: '0.25rem' }} />
                          {prompt.title}
                        </>
                      ) : (
                        prompt.title
                      )}
                    </Text>

                    <Text isTruncated={true} color="black30">
                      {prompt.subTitle}
                    </Text>
                  </Box>
                </Box>
              ))}
            </SimpleGrid>
          </VStack>
        </Center>
      ) : threadId && isProcessingOldMessageQuery && messages.length === 0 && formattedOldMessages.length === 0 ? (
        <Center style={{ width: '100%', height: '100%' }}>Loading Previous Conversation</Center>
      ) : (
        <MessageContainer
          messages={messages}
          prefixMessages={formattedOldMessages}
          hasMoreMessageHistory={oldMessagePageArgs.hasMoreData}
          isLoadingMessageHistory={isProcessingOldMessageQuery}
          onLoadMoreMessages={loadNextOldMessagePage}
          onRating={sendRating}></MessageContainer>
      )}
      <Flex flexDirection="row" paddingBlock="1rem" alignItems="flex-start" w="100%" overflow="hidden">
        {toolbarChildren ? (
          <>
            {toolbarChildren}
            <Spacer />
          </>
        ) : null}
      </Flex>
      <Flex flexDirection="row" paddingBlock="0.5rem" width="100%" paddingInlineEnd="1rem">
        <Square
          width="56px"
          height="56px"
          border="3px solid"
          borderColor="cream100"
          borderRadius="50%"
          marginRight="1rem">
          <img src={coManagerLogo} style={{ width: '40px', height: '40px' }} alt="" />
        </Square>
        <Box
          borderTop="3px"
          borderTopColor={chakraTheme.colors.gray30}
          borderTopStyle="solid"
          p="7px 16px 7px 20px"
          width="100%"
          {...userInputStyles}>
          <UserMessageInput
            onSend={onSend}
            disabled={sending || (isTrialMode && messages.length === 0)}></UserMessageInput>
        </Box>
      </Flex>
      <Flex flexDirection="row" paddingBlock="0.5rem" width="100%">
        {serverConnectionState ? (
          <Text color={serverConnectionState.includes('Error') ? 'red100' : 'green100'}>{serverConnectionState}</Text>
        ) : (
          <Text color="green100">Ready</Text>
        )}
        <Text color="black70">&nbsp;· Co-Manager Version 1.1.beta</Text>
      </Flex>
    </Flex>
  );
}

export default asModal(CoManagerModal);
export { CoManagerModal as CoManagerInline, makeMessage, CoManagerToolbar };
