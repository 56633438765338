import React from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';
import { useReleaseArtwork } from 'hooks/use-release-artwork';
import ENUMS from '../../constants/ENUMS.gen.json';

const { ASSET_RELEASE_TYPE } = ENUMS;

const COVER_ART_SIZE = `250`;
const COVER_ART_SIZE_PX = `${COVER_ART_SIZE}px`;

export default function Release({ release }) {
  const imgUrl = useReleaseArtwork(release?.id, `${COVER_ART_SIZE}x${COVER_ART_SIZE}`);

  const trackCount = release.releaseSets?.[0].setTracks.length || 0;

  return (
    <>
      <Text
        position={'absolute'}
        top="2.125rem"
        right="2.125rem"
        background="white"
        p="2px 8px"
        color="black100"
        borderRadius={'1rem'}>
        {trackCount} {trackCount > 1 ? 'tracks' : 'track'}
      </Text>
      <Image height={COVER_ART_SIZE_PX} width={COVER_ART_SIZE_PX} src={imgUrl} alt={`${release.title} release cover`} />
      <Heading maxW={COVER_ART_SIZE_PX} as="h3" fontSize={'lg'} mt="1rem" noOfLines={1}>
        {release.title}
        {ASSET_RELEASE_TYPE.SINGLE === release.type ? ` - ${release.type}` : null}
      </Heading>
      <Box mt="0.5rem">
        <Text noOfLines={1}>{release.display_artist_name}</Text>
      </Box>
    </>
  );
}
