import React, { useState, useEffect, useContext, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import { useReleaseForm } from './use-release-form';
import { getImplicitReleaseType } from 'utils/metadata';

import Card, { CARDS } from 'views/ManageRelease/card';
import { mapPartyFields } from '../features/DraggableTrack/map-party-fields';

const DIRECTIONS = {
  BACKWARD: 'BACKWARD',
  FORWARD: 'FORWARD',
};

const ANIMATION_ACTIONS = {
  RESET_POSITION: 'RESET_POSITION',
  ADVANCE_CARD: 'ADVANCE_CARD',
};

const leftCardSelected = {
  _id: 'LEFT_SELECTED',
  prev_content: '-100%',
  left_content: '0%',
  right_content: '100%',
  next_content: '200%',
  leftOpacity: 1,
  rightOpacity: 0.5,
  opacityUp: 0,
  opacityDn: 1,
  remUp: '0rem',
  remDn: '1rem',
};

const rightCardSelected = {
  _id: 'RIGHT_SELECTED',
  prev_content: '-200%',
  left_content: '-100%',
  right_content: '0%',
  next_content: '100%',
  leftOpacity: 0.5,
  rightOpacity: 1,
  opacityUp: 1,
  opacityDn: 0,
  remUp: '1rem',
  remDn: '0rem',
};

const nullObjects = {
  preRelease: {
    IncludePreOrder: {
      formData: {
        _meta: {
          includePreOrder: 'no',
        },
      },
    },
    PreOrderDate: {
      formData: {
        pre_order_release_date: null,
      },
    },
    InstantGratTracks: {
      formData: {
        track_instant_grat_dates: [],
      },
    },
  },
};

export const CardFlowContext = React.createContext();
const useCardFlow = () => useContext(CardFlowContext);
export const CardFlowProvider = ({
  saveRelease,
  _passthrough = {},
  children,
  stackIndex,
  cardIndex,
  omnishareWhitelist,
  isReviewMode,
  onDataChanged,
  onExternalEvent,
  onCardIndexChanged,
  onCardStacksChanged,
}) => {
  // Data state
  const [layoutState, setLayoutState] = useState({});
  const [cardData, setCardData] = useState({});
  const { releaseFormObject, releaseTracks, metaConditionals } = useReleaseForm();

  // Navigation state
  const [cardStacks, setCardStacks] = useState([[]]);
  const [currentStackIndex, setCurrentStackIndex] = useState(stackIndex);
  const [currentCardIndex, setCurrentCardIndex] = useState(cardIndex);
  const [currentCardName, setCurrentCardName] = useState('left_content');
  const [currentDirection, setCurrentDirection] = useState(DIRECTIONS.FORWARD);

  // Animation state
  const [doImmediate, setDoImmediate] = useState(false);
  const [doReset, setDoReset] = useState(false);
  const [to, setTo] = useState(leftCardSelected);
  const [animationAction, setAnimationAction] = useState(null);

  const [leftCardState, setLeftCardState] = useState({
    featured: true,
    transitioning: false,
    content: true,
  });
  const [rightCardState, setRightCardState] = useState({
    featured: false,
    transitioning: false,
    content: false,
  });

  const refWasBlocked = useRef(false);
  const refDirection = useRef(null);

  // Animation

  useEffect(() => {
    let nextStackIndex = currentStackIndex,
      nextCardIndex = currentCardIndex,
      nextCardName,
      nextTo;

    const setValues = (nextTo, nextStackIndex, nextCardIndex, nextCardName, nextDirection) => {
      setTo(nextTo);
      setCurrentStackIndex(nextStackIndex);
      setCurrentCardIndex(nextCardIndex);
      setCurrentCardName(nextCardName);
      setCurrentDirection(nextDirection);

      setLeftCardState(prevState => ({
        ...prevState,
        transitioning: true,
        featured: refDirection.current === DIRECTIONS.FORWARD ? false : true,
      }));
      setRightCardState(prevState => ({
        ...prevState,
        transitioning: true,
        featured: refDirection.current === DIRECTIONS.FORWARD ? true : false,
      }));
    };

    switch (animationAction) {
      case ANIMATION_ACTIONS.RESET_POSITION:
        if (!doReset) {
          setDoReset(true);

          if (refDirection.current === DIRECTIONS.FORWARD) {
            nextStackIndex =
              currentCardIndex === cardStacks[currentStackIndex].length - 1
                ? currentStackIndex + 1 > cardStacks.length - 1
                  ? cardStacks.length - 1
                  : currentStackIndex + 1
                : currentStackIndex;
            nextCardIndex =
              nextStackIndex === currentStackIndex
                ? currentCardIndex + 1 > cardStacks[currentStackIndex].length - 1
                  ? cardStacks[currentStackIndex].length - 1
                  : currentCardIndex + 1
                : 0;
            nextCardName = 'right_content';
            nextTo = leftCardSelected;
          } else {
            nextStackIndex =
              currentCardIndex === 0 ? (currentStackIndex - 1 < 0 ? 0 : currentStackIndex - 1) : currentStackIndex;
            nextCardIndex =
              nextStackIndex === currentStackIndex
                ? currentCardIndex - 1 < 0
                  ? 0
                  : currentCardIndex - 1
                : cardStacks[nextStackIndex].length - 1;
            nextCardName = 'left_content';
            nextTo = rightCardSelected;
          }

          setValues(nextTo, nextStackIndex, nextCardIndex, nextCardName, refDirection.current);
        } else {
          setAnimationAction(ANIMATION_ACTIONS.ADVANCE_CARD);
        }
        break;

      case ANIMATION_ACTIONS.ADVANCE_CARD:
        if (doReset) {
          setDoReset(false);
        }

        if (refDirection.current === DIRECTIONS.FORWARD) {
          nextCardName = 'right_content';
          nextTo = rightCardSelected;

          if (!refWasBlocked.current) {
            nextStackIndex =
              currentCardIndex === cardStacks[currentStackIndex].length - 1
                ? currentStackIndex + 1 > cardStacks.length - 1
                  ? cardStacks.length - 1
                  : currentStackIndex + 1
                : currentStackIndex;
            nextCardIndex =
              nextStackIndex === currentStackIndex
                ? currentCardIndex + 1 > cardStacks[currentStackIndex].length - 1
                  ? cardStacks[currentStackIndex].length - 1
                  : currentCardIndex + 1
                : 0;
          }
        } else {
          nextCardName = 'left_content';
          nextTo = leftCardSelected;

          if (!refWasBlocked.current) {
            nextStackIndex =
              currentCardIndex === 0 ? (currentStackIndex - 1 < 0 ? 0 : currentStackIndex - 1) : currentStackIndex;
            nextCardIndex =
              nextStackIndex === currentStackIndex
                ? currentCardIndex - 1 < 0
                  ? 0
                  : currentCardIndex - 1
                : cardStacks[nextStackIndex].length - 1;
          }
        }

        setValues(nextTo, nextStackIndex, nextCardIndex, nextCardName, refDirection.current);

        setAnimationAction(null);
        break;
      default:
        break;
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationAction, doReset]);

  const prevReleaseFormObject = useRef(JSON.stringify(releaseFormObject));
  const prevReleaseTracks = useRef(JSON.stringify(releaseTracks));
  const initialRender = useRef(true);
  // Release data
  useEffect(() => {
    //THIS IS TO PROTECT AGAINST NEVER-ENDING useEffect LOOPS
    if (
      JSON.stringify(releaseFormObject) === prevReleaseFormObject.current &&
      JSON.stringify(releaseTracks) === prevReleaseTracks.current &&
      !initialRender.current
    ) {
      return;
    }
    initialRender.current = false;
    prevReleaseFormObject.current = JSON.stringify(releaseFormObject);
    prevReleaseTracks.current = JSON.stringify(releaseTracks);

    const newCardData = {
      UploadCoverArt: cardData?.UploadCoverArt || {},
    };

    if (releaseFormObject) {
      newCardData[CARDS.CoreDetails] = {
        formData: {
          title: releaseFormObject.title,
          sub_title: releaseFormObject.sub_title,
        },
      };

      // --

      newCardData[CARDS.ReleaseDetails] = {
        formData: {
          asset_release_upc: releaseFormObject.asset_release_upc,
          copyright_year: releaseFormObject.copyright_year,
          display_artist_name: releaseFormObject.display_artist_name,
          display_label_name: releaseFormObject.display_label_name,
          genre: releaseFormObject.genre,
          lang: releaseFormObject.lang,
          original_release_date: releaseFormObject.original_release_date,
          _meta: {
            isCompilation: releaseFormObject._meta.isCompilation,
            includeExistingUPC: releaseFormObject._meta.includeExistingUPC,
            releaseType: releaseFormObject._meta.releaseType,
            includeOriginalReleaseDate: releaseFormObject._meta.includeOriginalReleaseDate,
            includeYTShortsPreview: releaseFormObject._meta.includeYTShortsPreview,
          },
        },
      };

      // --

      newCardData[CARDS.ReleaseArtists] = {
        formData: {
          artists: mapPartyFields(releaseFormObject.artists),
        },
      };

      // --

      newCardData[CARDS.LiveReleaseDate] = {
        formData: {
          release_date: releaseFormObject.release_date,
          release_date_time: releaseFormObject.release_date_time,
          _meta: {
            includeReleaseDateTime: releaseFormObject._meta.includeReleaseDateTime,
          },
        },
      };

      // --

      newCardData[CARDS.DeliveryPlatformsAndTerritories] = {
        formData: {
          dsp_platforms: releaseFormObject.dsp_platforms || [],
          territories: releaseFormObject.territories || [],
        },
      };

      // --

      newCardData[CARDS.PriceTierRelease] = {
        formData: {
          price_tier: releaseFormObject.price_tier,
        },
      };

      // --

      newCardData[CARDS.PriceTierTrack] = {
        formData: {
          track_price_tiers: releaseFormObject.track_price_tiers,
        },
      };

      // --

      let includePreOrder = metaConditionals.includePreOrder;

      if (!includePreOrder) {
        includePreOrder = releaseFormObject.pre_order_release_date ? 'yes' : 'no';
      }

      newCardData[CARDS.IncludePreOrder] = {
        formData: {
          _meta: {
            includePreOrder: includePreOrder,
          },
        },
      };

      // --

      newCardData[CARDS.PreOrderDate] = {
        formData: {
          pre_order_release_date: releaseFormObject.pre_order_release_date,
        },
      };

      newCardData[CARDS.Tracklist] = {
        formData: {},
        tracklistData: releaseTracks,
      };

      // --

      let includeInstantGratDates = metaConditionals.includeInstantGratDates;

      if (!includeInstantGratDates) {
        includeInstantGratDates = releaseFormObject._meta.includeInstantGratDates;
      }

      newCardData[CARDS.InstantGratTracks] = {
        formData: {
          track_instant_grat_dates: releaseFormObject.track_instant_grat_dates,
          _meta: {
            includeInstantGratDates: includeInstantGratDates,
          },
        },
      };
    }

    setCardData(newCardData);
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseFormObject, releaseTracks]);

  // Track data
  useEffect(() => {
    if (releaseFormObject && Array.isArray(releaseTracks) && releaseTracks.length) {
      const releaseType = getImplicitReleaseType(releaseTracks);
      const newCardData = {};

      newCardData.Tracklist = {
        tracklistData: releaseTracks.map(track => ({
          ...track.data,
          artists: mapPartyFields(track?.data?.artists),
          contributors: mapPartyFields(track?.data?.contributors),
          yt_shorts_preview_release_date: track?.data?.preview?.release_date,
          yt_shorts_preview_start_seconds: track?.data?.preview?.start_point,
          yt_shorts_preview_end_seconds: track?.data?.preview?.end_point,
          _meta: {
            includeExistingISRC: !!track.data?.isrc_code,
            includeExistingAtmosISRC: !!track.data?.atmos_isrc_code,
            includeYTShortsPreview: !!track.data?.preview?.release_date,
          },
        })),
        formData: {
          _meta: {
            trackIds: releaseTracks.map(track => track.id),
          },
        },
      };

      newCardData.ReleaseDetails = {
        formData: {
          _meta: {
            releaseType,
          },
        },
      };

      if (releaseType === 'VideoSingle') {
        // set pre_order_release_date, etc to null
        Object.keys(nullObjects.preRelease).forEach(key => {
          newCardData[key] = nullObjects.preRelease[key];
        });
      }

      updateMultipleCards(newCardData);
    }
  }, [releaseFormObject, releaseTracks]);

  // Data Utilities

  // DEBT:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onDataChanged(cardData), [cardData]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onCardStacksChanged(cardStacks), [cardStacks]);

  // MUTATE CARD DATA
  const updateCard_Data = (id, state) => {
    setCardData(prevState => {
      return { ...prevState, [id]: { ...prevState[id], ...state } };
    });
  };

  // MUTATE CARD DATA
  const updateCard_Form = (id, formData) => {
    setCardData(prevState => {
      const newState = JSON.parse(JSON.stringify(prevState));
      const newMeta = formData?._meta || {};

      if (!newState[id]) newState[id] = {};
      if (!newState[id].formData) newState[id].formData = {};
      if (!newState[id].formData._meta) newState[id].formData._meta = {};

      newState[id].formData = {
        ...newState[id].formData,
        ...formData,
        _meta: { ...newState[id].formData._meta, ...newMeta },
      };
      return newState;
    });
  };

  // MUTATE CARD DATA
  const updateMultipleCards = state => {
    setCardData(prevState => {
      const newState = { ...prevState };
      Object.keys(state).forEach(key => {
        if (!newState[key]) newState[key] = {};
        newState[key] = {
          ...newState[key],
          ...state[key],
          formData: {
            ...newState[key].formData,
            ...state[key].formData,
            _meta: {
              ...newState[key].formData?._meta,
              ...state[key].formData?._meta,
            },
          },
        };
      });

      return newState;
    });
  };

  // Navigation
  const oldStacks = useRef([]);
  const prevCardData = useRef(JSON.stringify(cardData));

  useEffect(() => {
    // PROTECT AGAINST NEVER-ENDING useEffect LOOPS
    if (prevCardData.current === JSON.stringify(cardData)) {
      return;
    }

    prevCardData.current = JSON.stringify(cardData);

    let i = 0;
    const stack = [];
    const includePreOrder = cardData[CARDS.IncludePreOrder]?.formData?._meta?.includePreOrder;
    const isCompilation = cardData[CARDS.ReleaseDetails]?.formData?._meta?.isCompilation;
    const releaseType = cardData[CARDS.ReleaseDetails]?.formData?._meta?.releaseType;
    const trackList = cardData[CARDS.Tracklist]?.tracklistData;
    const isDeliveringToApple =
      cardData[CARDS.DeliveryPlatformsAndTerritories]?.formData?.dsp_platforms?.includes('apple');

    stack.push(<Card key={i++} id={CARDS.StartRelease} />);
    stack.push(<Card key={i++} id={CARDS.CoreDetails} />);
    stack.push(<Card key={i++} id={CARDS.ReleaseDetails} />);

    if (!isCompilation) {
      stack.push(<Card key={i++} id={CARDS.ReleaseArtists} />);
    }

    stack.push(<Card key={i++} id={CARDS.UploadCoverArt} />);
    stack.push(<Card key={i++} id={CARDS.Tracklist} />);

    stack.push(<Card key={i++} id={CARDS.LiveReleaseDate} />);
    stack.push(<Card key={i++} id={CARDS.DeliveryPlatformsAndTerritories} />);

    if (isDeliveringToApple) {
      stack.push(<Card key={i++} id={CARDS.PriceTierRelease} />);
      if (trackList?.length > 1) {
        stack.push(<Card key={i++} id={CARDS.PriceTierTrack} />);
      }
    }

    if (releaseType !== 'VideoSingle') {
      stack.push(<Card key={i++} id={CARDS.IncludePreOrder} />);

      if (includePreOrder === 'yes') {
        stack.push(<Card key={i++} id={CARDS.PreOrderDate} />);

        if (trackList?.length > 1) {
          stack.push(<Card key={i++} id={CARDS.InstantGratTracks} />);
        }
      }
    }

    // TODO: figure out why setCardStacks with an identical object triggers useEffect above
    if (!isEqual(oldStacks.current, [stack])) {
      setCardStacks([stack]); // Array is [nested] to support multiple levels
    }

    oldStacks.current = [stack];
  }, [cardData]);

  useEffect(() => {
    setCurrentStackIndex(stackIndex);
    setCurrentCardIndex(cardIndex);
  }, [stackIndex, cardIndex]);

  useEffect(() => {
    onCardIndexChanged(currentStackIndex, currentCardIndex, cardData);
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStackIndex, currentCardIndex]);

  const getCurrentCardId = () =>
    React.isValidElement(cardStacks[currentStackIndex][currentCardIndex])
      ? cardStacks[currentStackIndex][currentCardIndex].props.id
      : '';

  const advanceCard = () => {
    // If blocked in the direction of motion, reset first
    if (
      (refDirection.current === DIRECTIONS.FORWARD && currentCardName === 'right_content') ||
      (refDirection.current === DIRECTIONS.BACKWARD && currentCardName === 'left_content')
    ) {
      refWasBlocked.current = true;
      setAnimationAction(ANIMATION_ACTIONS.RESET_POSITION);
    } else {
      refWasBlocked.current = false;
      setAnimationAction(ANIMATION_ACTIONS.ADVANCE_CARD);
    }
  };

  const gotoNextCard = () => {
    refDirection.current = DIRECTIONS.FORWARD;

    setDoImmediate(currentCardIndex === cardStacks[currentStackIndex].length - 1);
    advanceCard();
  };

  const gotoPrevCard = () => {
    refDirection.current = DIRECTIONS.BACKWARD;

    setDoImmediate(currentCardIndex === 0);
    advanceCard();
  };

  const updateLayoutState = (id, state) => {
    setLayoutState(prevState => ({
      ...prevState,
      [id]: { ...prevState[id], ...state },
    }));
  };

  const refreshLayoutState = () => {
    setLayoutState(prevState => ({ ...prevState, _meta: new Date().getTime() }));
  };

  const handleAnimationRest = () => {
    if (leftCardState.transitioning && leftCardState.content) {
      setLeftCardState(prevState => ({
        ...prevState,
        transitioning: false,
        content: refDirection.current === DIRECTIONS.FORWARD ? false : true,
      }));
      setRightCardState(prevState => ({
        ...prevState,
        transitioning: false,
        content: refDirection.current === DIRECTIONS.FORWARD ? true : false,
      }));
    }

    if (leftCardState.transitioning && !leftCardState.content) {
      setLeftCardState(prevState => ({
        ...prevState,
        transitioning: false,
        content: refDirection.current === DIRECTIONS.FORWARD ? false : true,
      }));
      setRightCardState(prevState => ({
        ...prevState,
        transitioning: false,
        content: refDirection.current === DIRECTIONS.FORWARD ? true : false,
      }));
    }
  };

  return (
    <CardFlowContext.Provider
      value={{
        saveRelease,
        currentStackIndex,
        //setCurrentStackIndex,
        currentCardIndex,
        //setCurrentCardIndex,
        currentCardName,
        currentDirection,
        doImmediate,
        doReset,
        to,
        gotoNextCard,
        gotoPrevCard,
        cardData,
        updateCard_Data,
        updateCard_Form,
        updateMultipleCards,
        handleAnimationRest,
        leftCardState,
        rightCardState,
        layoutState,
        updateLayoutState,
        refreshLayoutState,
        onExternalEvent,
        cardStacks,
        getCurrentCardId,
        isReviewMode,
        omnishareWhitelist,
        nullObjects,
        _passthrough,
      }}>
      {children}
    </CardFlowContext.Provider>
  );
};

export default useCardFlow;
