import * as React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useTheme,
  Heading,
  Button,
} from '@chakra-ui/react';
import { ClipLoader } from 'react-spinners';
import styled from '@emotion/styled/macro';

//DO NOT USE THIS MODAL FOR NEW DEVELOPMENT. DEPRECATE AT SOME POINT SOON.
export default function ConfirmationModal({
  headerText,
  body,
  cancelText,
  confirmText,
  confirmButtonProps,
  disclosure,
  onSubmit,
  isLoading,
  ...props
}) {
  const theme = useTheme();

  const ConfirmClipLoader = styled(ClipLoader)`
    width: 0.75rem;
    height: 0.75rem;
    margin: 0 1.5rem;
  `;

  const confirmButtonText = isLoading ? <ConfirmClipLoader /> : confirmText || 'Remove user';
  const confirmButtonVariant =
    typeof confirmButtonText === 'string' && confirmButtonText.toLowerCase().includes('remove')
      ? 'smallRed'
      : 'smallDark';

  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Heading as="h2" fontSize="1.5rem">
            {headerText}
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody style={{ whiteSpace: 'pre-wrap' }}>{body}</ModalBody>
        <ModalFooter>
          <Button onClick={disclosure.onClose} style={{ marginRight: theme.space[3] }} variant="small">
            {cancelText || 'Cancel'}
          </Button>
          <Button disabled={isLoading} {...confirmButtonProps} variant={confirmButtonVariant} onClick={onSubmit}>
            {confirmButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
