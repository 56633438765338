import React from 'react';
import ConfirmModal from '../../utils/useModals/confirm-modal';

const DeleteThreadModal = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <ConfirmModal isOpen={!!isOpen} headerContent={'Remove Conversation'} onConfirm={onConfirm} onCancel={onCancel}>
      Are you sure you want to remove this conversation?
    </ConfirmModal>
  );
};

export default DeleteThreadModal;
