import React, { Fragment } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import {
  Button,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useTheme,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Box from 'components/Box/Box';
import numeral from 'numeral';
import { RiArrowRightSLine as ChevronRightIcon } from 'react-icons/ri';

const determineMessageComponent = (
  royaltyTransfersLoading,
  royaltyTransfersError,
  hasAccountButNoStatements,
  stripeAccountNotConnected
) => {
  const Loading = (
    <Row>
      <Flex width="100%" justifyContent={'center'} xs={12}>
        <Spinner color="black" size="lg" />
      </Flex>
    </Row>
  );

  const NoDataMessage = (
    <Row>
      <Col xs={12}>
        <Box>
          Just getting started? Once you’ve gotten paid, statements
          <br />
          will appear here. Until then, keep on releasing killer tracks!{' '}
        </Box>
      </Col>
    </Row>
  );

  const StripeConnectMessage = (
    <Row>
      <Col xs={12}>
        <Box>
          In order to see your royalties, go to settings and connect your
          <br />
          bank account via Stripe
        </Box>
        <br />
        <br />
        <Button as={Link} to={'/settings'} variant={'smallLighter'}>
          Go to Settings
        </Button>
      </Col>
    </Row>
  );

  if (royaltyTransfersError) return 'Problem loading statements';
  if (royaltyTransfersLoading) return Loading;
  if (hasAccountButNoStatements) return NoDataMessage;
  if (stripeAccountNotConnected) return StripeConnectMessage;

  return null;
};

const StatementTh = ({ children, ...props }) => (
  <Th paddingBottom={'2rem'} color={'black100'} border={'none'} whiteSpace={'normal'} {...props}>
    <Text fontWeight={'bold'} fontSize={'sm'} whiteSpace={'normal'}>
      {children}
    </Text>
  </Th>
);

const StatementTd = ({ children, initial, ...props }) => {
  const textProps = initial
    ? {
      fontWeight: 'bold',
      noOfLines: 2,
      isTruncated: true,
      whiteSpace: 'normal',
    }
    : {
      noOfLines: 2,
      isTruncated: true,
      whiteSpace: 'normal',
    };

  return (
    <Td color={'black100'} border={'none'} {...props}>
      <Text {...textProps} w="inherit">
        {children}
      </Text>
    </Td>
  );
};

//Use 'initial' prop for the monthly summary row
//Other rows should be breakdown values
const StatementTr = ({ children, initial, ...props }) => {
  const borderProps = initial
    ? { borderTop: '2px solid', borderColor: 'black20', borderBottom: 'none' }
    : { border: 'none' };
  return (
    <Tr {...borderProps} {...props}>
      {children}
    </Tr>
  );
};

const StatementTable = ({ royaltyTransfers = [], account, payeeType }) => {
  const payeeName = account?.name;
  const theme = useTheme();

  return (
    <TableContainer width="100%" whiteSpace={'break-spaces'} overflowX={'auto'}>
      <Table variant={'analytics'} size={'sm'} whiteSpace={'break-spaces'}>
        <Thead background={'none'}>
          <Tr>
            <StatementTh width="20%">Month</StatementTh>
            <StatementTh width="36%">Status</StatementTh>
            <StatementTh width="12%">Earned Per Month</StatementTh>
            <StatementTh width="12%">Paid Per Month</StatementTh>
            <StatementTh width="15%">Payee</StatementTh>
            <StatementTh width="5%">&nbsp;</StatementTh>
          </Tr>
        </Thead>
        <Tbody>
          {royaltyTransfers
            .sort((a, b) => {
              // Dates are in sortable ISO format. Using string comparison to sort.
              return a.date > b.date ? -1 : 1;
            })
            .map(row => {
              const { date, transferred_amount, id, period_paid_earnings, breakdown_items, is_closed } = row;
              const parsedDate = new Date(date);
              const mon = parsedDate.toLocaleString('default', { month: 'long', timeZone: 'UTC' });
              const year = parsedDate.getFullYear();

              return (
                <Fragment key={id + 'statement'}>
                  {is_closed && (
                    <StatementTr initial={true}>
                      <StatementTd initial={true}>
                        {mon}
                        <br />
                        {year}
                      </StatementTd>
                      <StatementTd initial={true}>{row.status_text}</StatementTd>
                      <StatementTd initial={true}>{numeral(period_paid_earnings).format('$0,0.00')}</StatementTd>
                      <StatementTd initial={true}>{numeral(transferred_amount).format('$0,0.00')}</StatementTd>
                      <StatementTd initial={true}>{payeeName}</StatementTd>
                      <StatementTd initial={true}>
                        <Button
                          rightIcon={<ChevronRightIcon color={theme.colors.black100} size={`1.5rem`} />}
                          as={Link}
                          variant={'transparent'}
                          to={`/statements/${id}/${payeeType === 'group' ? 'label' : 'payee'}`}></Button>
                      </StatementTd>
                    </StatementTr>
                  )}
                  {breakdown_items.map(item => {
                    const { month, recoup_usd_total: transferred_amount, royalty_transfer_id, rcdate } = item;
                    return (
                      <StatementTr initial={false} key={royalty_transfer_id + rcdate}>
                        <StatementTd>{month}</StatementTd>
                        <StatementTd>{item.status_text}</StatementTd>
                        <StatementTd>{numeral(transferred_amount).format('$0,0.00')}</StatementTd>
                      </StatementTr>
                    );
                  })}
                </Fragment>
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const StatementsList = ({ royaltyTransfers, stripeAccountNotConnected, account, payeeType, royaltyTransferQuery }) => {
  const hasAccountButNoStatements = !royaltyTransfers?.length;

  const Message = determineMessageComponent(
    royaltyTransferQuery.isLoading,
    royaltyTransferQuery.isError,
    hasAccountButNoStatements,
    stripeAccountNotConnected
  );

  if (!royaltyTransfers) return null;

  return (
    <Box>
      {Message ? (
        <>{Message}</>
      ) : (
        <StatementTable royaltyTransfers={royaltyTransfers} account={account} payeeType={payeeType} />
      )}
    </Box>
  );
};

export default StatementsList;
