import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

export const getAccessorLengths = (data, accessor, defaultLength = 0) =>
  data.reduce((count, entry) => Math.max((entry[accessor] || '').length, count), Math.max(defaultLength, 0));

export const getColumnLengths = (data, columns) => {
  let totalCount = 0;
  const columnCounts = columns.map(column => {
    const maxAccessorCount = getAccessorLengths(data, column.accessor, column.Header.length);
    totalCount += maxAccessorCount;
    return maxAccessorCount;
  });
  return { totalCount, columnCounts };
};

export const shouldUseTargetAsMin = (columns, columnPercents) => {
  const percentsHigherThanTargets = columns.filter((column, i) => {
    const { targetWidth = 100 } = column.dynamicWidthConfig;
    const columnPercent = columnPercents[i];
    return columnPercent > targetWidth;
  });
  //If we have more than 1 text percent higher than the target then we
  // limit every column because they cannot share space.
  return percentsHigherThanTargets.length > 1;
};

//Sets property on columns as a side effect. Make sure columns are not shared!
export const setDynamicColumnWidth = (column, columnPercent, useTargetAsMin) => {
  const { targetWidth = 100, minWidth = 0 } = column.dynamicWidthConfig;

  let percent = Math.max(columnPercent, minWidth);
  percent = useTargetAsMin ? Math.min(targetWidth, percent) : percent;
  set(column, 'headerStyle.width', `${percent}%`);
};

export const calculateDynamicColumns = (options = {}) => {
  const { data, columns } = options;

  const copiedColumns = cloneDeep(columns);
  const dynamicColumns = copiedColumns.filter(x => x.dynamicWidthConfig);

  const { totalCount, columnCounts } = getColumnLengths(data, dynamicColumns);
  const columnPercents = columnCounts.map(count => (count / totalCount) * 100);

  const useTargetAsMin = shouldUseTargetAsMin(dynamicColumns, columnPercents);
  dynamicColumns.forEach((column, i) => setDynamicColumnWidth(column, columnPercents[i], useTargetAsMin));

  return copiedColumns;
};
