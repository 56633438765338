import React from 'react';
import { Box } from '@chakra-ui/react';

function ToggableBanner({ showBanner, children, bannerStyleProps = {} }) {
  return showBanner ? (
    <Box role="banner" bg="black80" width="100%" display="flex" justifyContent="center" {...bannerStyleProps}>
      <Box w="full" maxW="1280px" px="5" py="2" color="cream.100">
        {children}
      </Box>
    </Box>
  ) : (
    <></>
  );
}

export default ToggableBanner;
