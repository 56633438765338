import ManageClipsStateManager from '../../manage-clips-state-manager';
import Schema from '../../../schema-gen/release';

class ClipReleaseInfoState extends ManageClipsStateManager {
  dependentFields = ['artists', 'genre', 'secondary_genre', 'lang', 'original_release_date'];
  title = 'Release Info';
  path = '/manage/clip/release_info';
  componentName = 'ClipReleaseInfo';

  constructor(args) {
    const { stateData, ...rest } = args;

    super(
      {
        ...rest,
        stateData,
        mutableFields: ['original_release_date', 'genre', 'lang', 'artists'],
      },
      {
        original_release_date: Schema.OriginalReleaseDate.optional(),
        genre: Schema.Genre,
        secondary_genre: Schema.Genre.optional(),
        lang: Schema.Language,
        artists: Schema.Artists,
      }
    );
  }

  validateField(fieldName, value) {
    const _value = value;
    const validator = this.schema[fieldName];

    if (
      fieldName === 'artists' &&
      (!Array.isArray(_value) || !_value.find(artist => artist.roles.find(r => r === 'MainArtist')))
    )
      return validator.safeParse(null)?.success; //force invalid

    if (validator) {
      const result = validator.safeParse(_value);
      return result.success;
    } else {
      console.warn('No validator found for field ' + fieldName);
    }
  }

  validateProps() {
    if (!this.mutableFields.artists?.find(artist => artist.roles.find(r => r === 'MainArtist'))) {
      return (this._allValid = false);
    }

    super.validateProps();
  }
}

export default ClipReleaseInfoState;
