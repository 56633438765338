import React from 'react';
import { Box, IconButton, Grid, GridItem, Text } from '@chakra-ui/react';
import LabeledSelect from '../labeled-select/labeled-select';

import MultiRowFormElementContainer from '../multi-row-form-element-container/multi-row-form-element-container';
import { DeleteIcon } from '@chakra-ui/icons';

export default function RoleManager({
  rowsData,
  name,
  addRowText,
  onChange,
  RowComponent,
  HeaderComponent,
  secondaryAction,
  errorMessages,
  loading = false,
}) {
  if (loading) {
    return <Text>Loading...</Text>;
  }
  return (
    <>
      {HeaderComponent && (
        <HeaderComponent>
          <IconButton aria-label="Delete" icon={<DeleteIcon />} />
        </HeaderComponent>
      )}
      <Box>
        <MultiRowFormElementContainer
          rowsData={rowsData}
          onChange={onChange}
          name={name}
          addRowText={addRowText}
          RowComponent={({ ...props }) => <RowComponent errorMessages={errorMessages} {...props} />}
          secondaryAction={secondaryAction}
        />
      </Box>
    </>
  );
}

//ids, updateRow, rowData
export function RoleRow({
  idx,
  updateRow,
  onBlur,
  rowData,
  typeOptions,
  rolesOptions,
  defaultRole,
  onDeleteRow,
  PartySelectorComponent,
  isRequired = false,
  typeOfSelect,
  errorMessages,
  ...props
}) {
  function update({ target: { value, name } }) {
    const newRowData = { ...rowData, [name]: value };
    //since roles have a default option and it MAY not be changed causing a trigger,
    // we need to check the value of that element and put in what the default is declared to be
    if (name !== 'role' && !rowData.role) {
      newRowData.role = defaultRole;
    }
    updateRow({ idx, ...newRowData });
  }

  const errorMessageKey = `${typeOfSelect.toLowerCase()}s.${rowData.asset_party_id}_${rowData.role}`;
  const errorMessage = errorMessages?.[errorMessageKey];

  return (
    <>
      <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} position="relative">
        <GridItem width={'45%'} data-foo={'foo'}>
          {PartySelectorComponent && (
            <PartySelectorComponent isTruncated update={update} rowData={rowData} idx={idx} options={typeOptions} />
          )}
          {!PartySelectorComponent && (
            <LabeledSelect
              name="asset_party_id"
              label=""
              value={rowData?.asset_party_id}
              onChange={update}
              onBlur={onBlur}
              options={typeOptions || []}
              isTruncated
              {...props}
            />
          )}
        </GridItem>
        <GridItem width={'45%'}>
          <LabeledSelect
            placeholder={`Select ${typeOfSelect} Role`}
            name="role"
            label=""
            id={`${idx}-role`}
            value={rowData?.role || defaultRole || ''}
            onChange={update}
            onBlur={onBlur}
            options={rolesOptions}
            {...props}
          />
        </GridItem>
        <GridItem>
          <IconButton
            disabled={isRequired}
            marginTop={'.25rem'}
            marginLeft={'.25rem'}
            aria-label={'delete'}
            icon={<DeleteIcon />}
            onClick={() => onDeleteRow(idx)}
          />
        </GridItem>
        {errorMessage ? (
          <Box
            color={'red100'}
            style={{
              fontSize: 'xsm',
              position: 'absolute',
              bottom: '-.675rem',
              left: '.3rem',
              whiteSpace: 'nowrap',
            }}>
            {errorMessage}
          </Box>
        ) : null}
      </Grid>
    </>
  );
}
