import uniqWith from 'lodash/uniqWith';
import { Box } from '@chakra-ui/react';
import { AsyncSelect } from '../components/Select/select';
import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import * as API from '../utils/API/API';
import asyncDebounce from './async-debounce';

const Track = API.track();

function SearchAndFilter({ history }) {
  const trackSearchData = useMutation(trackSearchTerm => {
    return Track.search(trackSearchTerm);
  });

  const queryClient = useQueryClient();

  const loadTracks = async inputValue => {
    const tracks = await trackSearchData.mutateAsync(inputValue);
    const dedupedTracks = uniqWith(tracks, (track1, track2) => track1.isrc_code === track2.isrc_code);

    return dedupedTracks.map(item => ({
      value: item.id,
      label: (
        <Box display="grid" gridTemplateColumns="3fr 1fr" alignItems="center">
          <Box>
            <Box>{item.title}</Box>
            <Box fontSize="sm" color="black.100">
              {item.display_artist_name ||
                item?.trackParties?.find(a => a.roles.includes('MainArtist'))?.party?.full_name}
            </Box>
          </Box>
          <Box color="black.100" justifySelf="flex-end">
            {item.isrc_code ? `(${item.isrc_code})` : ''}
          </Box>
        </Box>
      ),
    }));
  };

  const debouncedLoadTracks = asyncDebounce(loadTracks, 2000);

  return (
    <AsyncSelect
      onChange={async selection => {
        if (selection.value) {
          history.push(`/analytics/track/${selection.value}`);
          queryClient.clear();
        }
      }}
      name="tracks"
      placeholder="Search by title or ISRC"
      closeMenuOnSelect={true}
      loadOptions={debouncedLoadTracks}
      tabSelectsValue={false}
      noOptionsMessage={() => <Box color="black.100">No tracks</Box>}
      removeDropdownIndicator={true}
    />
  );
}

export default SearchAndFilter;
