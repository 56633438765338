import { REQUIRED_AUDIO_ROLES, REQUIRED_VIDEO_ROLES } from '../../components/AddArtistsForm/ADD_ARTIST_FORM_CONSTANTS';
import { creditRoles } from '../../constants/roles';

export function validateContributors(contributors, resourceType) {
  const errors = [];
  const requiredRoles = resourceType === 'Video' ? REQUIRED_VIDEO_ROLES : REQUIRED_AUDIO_ROLES;
  const contributorRoles = contributors.map(c => c.roles?.[0]);

  const missingRequiredRoles = requiredRoles.reduce((acc, role) => {
    if (!contributorRoles.includes(role.value)) {
      acc.push(role.label);
    }
    return acc;
  }, []);

  const contributerRoleLabels = creditRoles.map(credit => credit.label);

  const missingValues = contributors.reduce((a, c) => {
    if (!c.roles || !c.roles.length || !c.full_name) {
      a.push(
        c.full_name || (contributerRoleLabels.includes(c.roles?.[0]) ? `${c.roles?.[0]}` : 'Contributor Name Missing')
      );
    }
    return a;
  }, []);

  const missingContributors = [...missingRequiredRoles, ...missingValues];
  if (missingContributors.length > 0) {
    if (missingContributors.length < 4) {
      errors.push(`Please provide all values for the following: ${missingContributors.join(', ')}`);
    } else {
      errors.push('Multiple contributors are missing values.');
    }
  }
  return errors;
}
