import React, { useState } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  Checkbox,
  ModalOverlay,
  Text,
  Button,
  Link as ChakraLink,
  useTheme,
} from '@chakra-ui/react';
import URLS from 'constants/URLS.gen.json';

const EXPECTED_TERMS_ACCEPTED = {
  TOS: 'accepted_terms_url',
  DISTRIBUTION: 'accepted_distribution_terms_url',
  SYNC_OPT_IN: 'accepted_opt_in_sync_terms_url',
};

function AcceptTermsModal({ disclosure, handleAccept, logout, needsAccepting }) {
  const [acceptedTerms, setAcceptedTerms] = useState([]);

  const theme = useTheme();

  function toggleAcceptedTerm(term) {
    if (acceptedTerms.includes(term)) return setAcceptedTerms([...acceptedTerms.filter(t => t !== term)]);
    setAcceptedTerms([...acceptedTerms, term]);
  }

  function Link({ children, ...props }) {
    return (
      <ChakraLink {...props} color={theme.colors.pink}>
        {children}
      </ChakraLink>
    );
  }

  return (
    <Modal isOpen={disclosure.isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Box color={theme.colors.black100}>
            <Text marginTop={'1rem'}>
              We’ve updated our Terms of Service and Privacy Policy. Please agree in order to use our platform.
            </Text>
            <Text color={theme.colors.black100} marginY={'1rem'}>
              Note: Any signed and countersigned Premium Services agreement may have terms that supersede the terms
              presented in the standard Distribution Terms or Sync Opt-In Terms agreements below.
            </Text>

            {needsAccepting.includes(EXPECTED_TERMS_ACCEPTED.TOS) && (
              <Flex>
                <Checkbox
                  spacing="1rem"
                  light
                  name="agree"
                  defaultValue={false}
                  onChange={() => toggleAcceptedTerm(EXPECTED_TERMS_ACCEPTED.TOS)}
                />
                &nbsp;
                <Text>
                  I agree to the&nbsp;
                  <Link href={URLS.TOS.accepted_terms_url} target="_blank">
                    Terms of Service
                  </Link>
                  &nbsp;and&nbsp;
                  <Link href={URLS.TOS.privacy} target="_blank">
                    Privacy Policy
                  </Link>
                  .
                </Text>
              </Flex>
            )}

            {needsAccepting.includes(EXPECTED_TERMS_ACCEPTED.DISTRIBUTION) && (
              <Flex>
                <Checkbox
                  spacing="1rem"
                  light
                  name="agree"
                  defaultValue={false}
                  onChange={() => toggleAcceptedTerm(EXPECTED_TERMS_ACCEPTED.DISTRIBUTION)}
                />
                &nbsp;
                <Text>
                  I agree to the&nbsp;
                  <Link href={URLS.TOS.accepted_distribution_terms_url} target="_blank">
                    Distribution Terms of Service
                  </Link>
                  .
                </Text>
              </Flex>
            )}

            {needsAccepting.includes(EXPECTED_TERMS_ACCEPTED.SYNC_OPT_IN) && (
              <Flex>
                <Checkbox
                  spacing="1rem"
                  variant="lightMode"
                  name="agree"
                  defaultValue={false}
                  onChange={() => toggleAcceptedTerm(EXPECTED_TERMS_ACCEPTED.SYNC_OPT_IN)}
                />
                &nbsp;
                <Text>
                  I agree to the&nbsp;
                  <Link href={URLS.TOS.accepted_opt_in_sync_terms_url} target="_blank">
                    Sync Opt In Terms of Service
                  </Link>
                  .
                </Text>
              </Flex>
            )}
          </Box>
          <Box mt="8">
            <Flex xs={12} justifyContent={'space-between'} mb="1rem">
              <Button variant="small" onClick={logout}>
                Decline
              </Button>
              <Button
                variant="smallDark"
                onClick={handleAccept}
                disabled={acceptedTerms.length !== Object.entries(needsAccepting).length}>
                Accept
              </Button>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AcceptTermsModal;
