import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const Users = () => {
  const getMe = user_group_id =>
    AuthAxios.get(`${DataApiUrl}/users/me`, { params: { user_group_id } }).then(res => res.data.data);

  const getAll = () =>
    AuthAxios.get(`${DataApiUrl}/users`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getByEmail = email =>
    AuthAxios.get(`${DataApiUrl}/users`, {
      params: { email: encodeURIComponent(email) },
    })
      .then(res => res.data.data[0])
      .catch(err => console.error(err.message));

  const updateUser = (id, data) =>
    AuthAxios.patch(`${DataApiUrl}/users/${id}`, data)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const invite = id =>
    AuthAxios.post(`${DataApiUrl}/users/${id}/invite`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const create = email =>
    AuthAxios.post(`${DataApiUrl}/users`, { email: email })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const changePassword = (user_id, redirect_url) =>
    AuthAxios.post(`${DataApiUrl}/users/changePassword`, {
      user_id: user_id,
      redirect_url: redirect_url,
    })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getMfaStatus = () => AuthAxios.get(`${DataApiUrl}/users/mfaStatus`).then(res => res.data.data);

  const setMfaStatus = mfa_status =>
    AuthAxios.post(`${DataApiUrl}/users/mfaStatus`, { mfa_status }).then(res => res.data.data);

  const getStripeConnectUrl = (id, origin, countryCode) =>
    AuthAxios.get(`${DataApiUrl}/users/${id}/paymentAccount/stripeConnectUrl`, {
      params: { origin, countryCode },
    })
      .then(res => res.data.data.expressDashboardLink)
      .catch(err => console.error(err.message));

  const getPaymentAccount = id =>
    AuthAxios.get(`${DataApiUrl}/users/${id}/paymentAccount`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getReferralCode = id =>
    AuthAxios.get(`${DataApiUrl}/users/${id}/paymentAccount/referralCode`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  return {
    getMe,
    getAll,
    getByEmail,
    updateUser,
    invite,
    create,
    changePassword,
    getPaymentAccount,
    getStripeConnectUrl,
    getReferralCode,
    getMfaStatus,
    setMfaStatus,
  };
};

export default Users;
