import React, { useState } from 'react';
import Box from '../Box/Box';
import { Icon, Input, Stack, Text, Button, Flex } from '@chakra-ui/react';
import icons from '../../utils/icons';

/**
 * @callback EmailCollectionSubmitCallback
 * @param {string} email
 * @param {string} name
 * @returns {void}
 */

/**
 * @param {EmailCollectionSubmitCallback} onSubmit
 */
const EmailCollectionFormMessage = ({ onSubmit }) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };
  const handleNameChange = e => {
    setName(e.target.value);
  };

  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]+$/;
    return regex.test(email);
  };

  const handleSend = e => {
    e.preventDefault();
    if (name.length < 1 || !validateEmail(email)) {
      if (name.length < 1) {
        window.alert('Please enter your name.');
      } else {
        window.alert('Please enter a valid email address.');
      }
    } else {
      onSubmit({ email, name });
    }
  };

  return (
    <div onSubmit={handleSend}>
      <Icon
        as={icons.robot}
        color="pink"
        display="block"
        pr="0.25rem"
        pb="0.25rem"
        w="1.25rem"
        h="1.25rem"
        lineHeight="1.25rem"
        title="AI Assisted Answer"></Icon>
      <Box variant="agentMessage">
        <Stack spacing={3}>
          <Text>{'Please provide us with your name and email for further assistance.'}</Text>
          <Input
            onChange={handleNameChange}
            placeholder="Enter your name"
            name={name}
            size="md"
            bg="white"
            border="1px"
            _placeholder={{ color: '#686573' }}
          />
          <Input
            onChange={handleEmailChange}
            placeholder="Enter your email"
            email={email}
            size="md"
            bg="white"
            border="1px"
            _placeholder={{ color: '#686573' }}
          />
          <Flex display="flex" justifyContent="flex-end">
            <Button bg="black" color="white" onClick={handleSend} size="md" width="85px">
              Submit
            </Button>
          </Flex>
        </Stack>
      </Box>
    </div>
  );
};

export default EmailCollectionFormMessage;
