import React, { useEffect, useState, useCallback } from 'react';
import { Flex, useToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import Section from '../section';
import SubSection from '../sub-section';
import SectionHeaderBar from '../section-header-bar';
import GroupAPI from 'utils/API/group';
import { useLocation } from 'react-router-dom';
import CallToActionSection from './call-to-action-section';
import SelectArtistSection from './select-artist-section';

const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const apiPrefix = process.env.REACT_APP_DATA_API_URL;
const redirectUri = apiPrefix + '/auth/spotify';

const s4aToastStrategies = {
  no_live_releases: {
    title: `Spotify For Artists invitation failed. No live Spotify releases could be found for this artist.`,
    status: 'error',
  },
  fail: {
    title: `Spotify For Artists invitation failed, Please Contact yourfriends@venicemusic.co.`,
    status: 'error',
  },
  already_connected: {
    title: `This artist is already connected to Spotify For Artists!`,
    status: 'info',
  },
};

export default function SpecialAccessContainer({ groupId }) {
  const Group = GroupAPI();

  const toast = useToast();
  const search = useLocation();
  const [selectedArtist, setSelectedArtist] = useState(null);
  const [hasSpotifyDeliveries, setHasSpotifyDeliveries] = useState(false);

  useEffect(() => {
    const searchUrl = new URL(window.location);
    const searchParams = new URLSearchParams('?' + searchUrl.hash.split('?')[1]);
    const statusParam = searchParams.get('status');
    const strategy = s4aToastStrategies?.[statusParam];
    if (strategy) {
      toast(strategy);
    }
  }, [search]);

  const setArtistData = useCallback(
    artist => {
      setSelectedArtist(artist);
      const spotifyJobs = artist.releaseParties
        .flatMap(rp => rp.release.distributionJobs)
        .filter(dj => dj.dsp === 'spotify');
      setHasSpotifyDeliveries(!!spotifyJobs.length);
    },
    [setSelectedArtist, setHasSpotifyDeliveries]
  );

  const artistsEligibleForS4aQuery = useQuery(
    ['artistsEligibleForS4A'],
    () => Group.getArtistsEligibleForS4A(groupId),
    {
      onSuccess: res => {
        if (res.results.length === 1) {
          setArtistData(res.results[0]);
        }
      },
    }
  );

  const handleOnChangeArtist = ({ target: { value } }) => {
    const artist = artistsEligibleForS4aQuery?.data?.results?.find(a => a.id === parseInt(value));
    setArtistData(artist);
  };

  const handleGetAccess = async () => {
    const state = `asset_party_id:${selectedArtist.id}`;

    const args = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      redirect_uri: redirectUri,
      state: state,
    });

    window.location = 'https://accounts.spotify.com/authorize?' + args;
  };

  const artistResults = artistsEligibleForS4aQuery?.data?.results;
  const artistSpotifyId = selectedArtist?.platform_id?.spotify;
  return (
    <>
      <Section>
        <SubSection>
          <SectionHeaderBar name="Special Access"></SectionHeaderBar>
          <Flex>
            <CallToActionSection
              onClick={handleGetAccess}
              disabled={!selectedArtist || !hasSpotifyDeliveries || !artistSpotifyId}
              title="Spotify For Artists"
              description="Spotify for Artists helps you get the most out of Spotify. Manage your artist profile, learn about your audience,
                and develop your fanbase."
            />
            <SelectArtistSection
              artistResults={artistResults}
              isLoading={artistsEligibleForS4aQuery?.isLoading}
              onChange={handleOnChangeArtist}
              ctaEnabled={selectedArtist && hasSpotifyDeliveries && artistSpotifyId}
            />
          </Flex>
        </SubSection>
      </Section>
    </>
  );
}
