import { SUPPORTED_DSPS } from './supported-dsps';
import forEachRight from 'lodash/forEachRight';

// iterate through elements in reverse to fill in gaps of empty data with 0's
// and figure out when our most recent data for each DSP is
export function calculateDspDataStatus(streamCounts) {
  let dspMeta = {};

  SUPPORTED_DSPS.forEach(dsp => (dspMeta[dsp] = { hasReachedStartOfData: false, lastUpdated: null }));
  forEachRight(streamCounts, dailyCounts => {
    SUPPORTED_DSPS.forEach(dsp => {
      if (!dspMeta[dsp].hasReachedStartOfData && dsp in dailyCounts) {
        dspMeta[dsp].hasReachedStartOfData = true;
        dspMeta[dsp].lastUpdated = dailyCounts.date;
      }
      // if we're somewhere in the middle of existing data and there's not
      // a data point already, make it a 0 so the graph won't have blanks
      if (dspMeta[dsp].hasReachedStartOfData && !(dsp in dailyCounts)) {
        dailyCounts[dsp] = 0;
      }
    });
  });

  return dspMeta;
}
