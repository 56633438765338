import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import App from './App';
import { Auth0Provider } from './utils/auth0';
import history from './utils/history';
import initNumeral from './utils/numeral';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import AUTH0_CONSTANTS from './constants/AUTH0.gen.json';
const AUTH0 = AUTH0_CONSTANTS[global.APP.RUNTIME];

initNumeral();

console.log = global.APP.RUNTIME !== 'development' ? () => {} : console.log;

// Enable for detailed console telemetry on every component re-render
const enableTelemetry = false;

if (enableTelemetry && global.APP.RUNTIME === 'development') {
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    collapseGroups: true,
    titleColor: 'green',
    diffPathColor: 'lightgray',
    diffNameColor: 'darkturquoise',
  });
}

if (process.env.REACT_APP_RUNTIME === 'production') {
  // Redirect to support page if headless browser detected
  const t1 = navigator.webdriver;
  const t2 = /Headless/.test(window.navigator.userAgent);
  // eslint-disable-next-line no-eval
  const t3 = eval.toString().length === 33 && !window.chrome;
  const t4 = /headless/i.test(window.navigator.appVersion);
  const t5 = [
    '__webdriver_evaluate',
    '__selenium_evaluate',
    '__webdriver_script_function',
    '__webdriver_script_func',
    '__webdriver_script_fn',
    '__fxdriver_evaluate',
    '__driver_unwrapped',
    '__webdriver_unwrapped',
    '__driver_evaluate',
    '__selenium_unwrapped',
    '__fxdriver_unwrapped',
    '__puppeteer_evaluation_script__',
  ].some(key => typeof window['document'][key] !== 'undefined');
  const t6 = ['_phantom', '__nightmare', '_selenium', 'callPhantom', 'callSelenium', '_Selenium_IDE_Recorder'].some(
    key => typeof window[key] !== 'undefined'
  );
  let t7 = false;
  for (const documentKey in window['document']) {
    if (documentKey.match(/\$[a-z]dc_/) && window['document'][documentKey]['cache_']) {
      t7 = true;
    }
  }
  const t8 =
    window['external'] && window['external'].toString() && window['external'].toString()['indexOf']('Sequentum') != -1;
  const t9 = window['document']['documentElement']['getAttribute']('selenium');
  const t10 = window['document']['documentElement']['getAttribute']('webdriver');
  const t11 = window['document']['documentElement']['getAttribute']('driver');

  if (t1 || t2 || t3 || t4 || t5 || t6 || t7 || t8 || t9 || t10 || t11) {
    window.location.href = '/contact-support';
  }
}

const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const isShare = history.location.pathname.indexOf('/share') === 0;
ReactDOM.render(
  <HttpsRedirect disabled={process.env.REACT_APP_HTTPS_ENFORCEMENT !== 'true'}>
    {!isShare ? (
      <Auth0Provider
        domain={AUTH0.DOMAIN}
        client_id={AUTH0.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={`${window.location.origin}?type=auth0`}
        onRedirectCallback={onRedirectCallback}
        audience={AUTH0.AUDIENCE}
        cacheLocation="localstorage"
        useRefreshTokens>
        <App enableAuth0={true} />
      </Auth0Provider>
    ) : (
      <Auth0Provider>
        <App enableAuth0={false} />
      </Auth0Provider>
    )}
  </HttpsRedirect>,
  document.getElementById('root')
);
