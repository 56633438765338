import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl() + '/memberships';

const Membership = () => {
  const listTiers = () =>
    AuthAxios.get(`${DataApiUrl}/tiers`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  const cancelSubscription = data =>
    AuthAxios.patch(`${DataApiUrl}/membershipSettings/user/subscription`, data)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const upgradeSubscription = data =>
    AuthAxios.patch(`${DataApiUrl}/membershipSettings/user/upgrade-stripe-subscription`, data)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const accelerateTrialSubscription = data =>
    AuthAxios.patch(`${DataApiUrl}/membershipSettings/user/accelerate-trial-subscription`, data)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const updatePaymentDefault = data =>
    AuthAxios.patch(`${DataApiUrl}/membershipSettings/user/paymentDefault`, data)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getClientSecret = () =>
    AuthAxios.get(`${DataApiUrl}/membershipSettings/user/stripe-client-secret`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getPreviewProratedUpgradeAmount = (subscription_proration_date, upgrade_to, discount_id) =>
    AuthAxios.get(`${DataApiUrl}/membershipSettings/user/preview-prorated-upgrade-amount`, {
      params: { subscription_proration_date, upgrade_to, discount_id },
    }).then(res => res.data.data);

  return {
    listTiers,
    cancelSubscription,
    upgradeSubscription,
    accelerateTrialSubscription,
    updatePaymentDefault,
    getClientSecret,
    getPreviewProratedUpgradeAmount,
  };
};

export default Membership;
