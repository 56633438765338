import Footer from 'components/Footer/footer';
import HeaderActAs from 'components/HeaderActAs/header-act-as';
import NavBar from 'components/NavBar/nav-bar';
import React, { useCallback, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { VStack, Box, Flex } from '@chakra-ui/react';
import useCurrentUser from '../../data-client/use-current-user';
import { useGlobalData } from '../../utils/global-data';
import { useUserTierChecks } from '../../hooks/authorization-hooks';
import { DateTime } from 'luxon';
import IsInGracePeriodBanner from '../../features/ToggableBanner/is-in-grace-period';
import SpotifyForArtistsBanner from '../../features/ToggableBanner/spotify-for-artists';

const FOOTER_HEIGHT = '4.5rem';
const S4A_BANNER_CLOSED = 's4a-banner-closed';

function ViewWrapper({
  children,
  designLayer,
  navbarDesignLayer,
  hideNavbar,
  solidFooter,
  disableHorizontalScroll,
  wrapperStyle,
  contentStyle,
  alignWithNav,
  ...props
}) {
  const { view, setView, dbUser } = useGlobalData();

  const [refContentWrapper, setRefContentWrapper] = useState(null);
  const [contentWrapperHeight, setContentWrapperHeight] = useState(0);
  const [spotify4ArtistsClosed, setSpotify4ArtistsClosed] = useState(true);
  const { canAccessSpotifyForArtists } = useUserTierChecks();

  useEffect(() => {
    const alwaysShowS4aBanner = process.env.REACT_APP_ALWAYS_SHOW_S4A_BANNER;
    const isMoreThanTwoWeeks = DateTime.fromISO(dbUser?.accepted_terms_at).diffNow('weeks').weeks < -2;
    const s4aBannerClosed = !!localStorage.getItem(S4A_BANNER_CLOSED);
    const showBannerBasedOnUser =
      dbUser && (isMoreThanTwoWeeks === false || alwaysShowS4aBanner) && s4aBannerClosed === false;

    const spotifyForArtistsBannerShouldBeClosed =
      canAccessSpotifyForArtists == false || showBannerBasedOnUser === false;

    setSpotify4ArtistsClosed(spotifyForArtistsBannerShouldBeClosed);
  }, [canAccessSpotifyForArtists, dbUser]);

  const [meData] = useCurrentUser();
  // Ref

  useEffect(() => {
    if (refContentWrapper) {
      const rect = refContentWrapper.getBoundingClientRect();

      setContentWrapperHeight(rect.height);
    }
  }, [refContentWrapper]);

  // DEBT:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRefContentWrapper = useCallback(node => {
    if (node && !node.isEqualNode(refContentWrapper)) {
      setRefContentWrapper(node);
    }
  });

  const onSpotify4ArtistsClose = () => {
    setSpotify4ArtistsClosed(true);
    localStorage.setItem(S4A_BANNER_CLOSED, 'true');
  };

  useEffect(() => {
    if (props.name !== view) {
      setView(props.name);
    }

    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.name]);

  const withStickyFooter = true;
  return (
    <React.Fragment>
      {/* potental to add and remove banners if more are wanted/needed */}
      <Helmet>
        <style>{`
          body {
            ${disableHorizontalScroll ? 'overflow-x: hidden;' : ''}
          }
        `}</style>
      </Helmet>
      <VStack height="100vh" width="100vw" overflowY="scroll" {...props}>
        <IsInGracePeriodBanner meData={meData} />
        <SpotifyForArtistsBanner showBanner={!spotify4ArtistsClosed} onCloseBanner={onSpotify4ArtistsClose} />
        {meData && Number(meData.id) !== Number(meData.auth0RealUserId) && <HeaderActAs meData={meData} />}
        <Flex
          flexDir="column"
          padding="0"
          flexGrow="1"
          width="100%"
          position="relative"
          id="contentWrapper"
          withStickyFooter={withStickyFooter}
          style={{ ...wrapperStyle, marginTop: 0 }}
          ref={onRefContentWrapper}>
          {!hideNavbar && <NavBar designLayer={navbarDesignLayer === null ? designLayer : navbarDesignLayer} />}
          <Box
            padding="2rem 1.25rem 0"
            maxWidth="1280px"
            marginLeft="auto"
            marginRight="auto"
            width="100%"
            alignWithNav={alignWithNav}
            {...contentStyle}>
            {children}
          </Box>
        </Flex>
        {!window.location.pathname.includes('edit') && (
          <Footer
            withStickyFooter={withStickyFooter}
            solidFooter={solidFooter}
            style={{
              marginTop: 0,
              height: FOOTER_HEIGHT,
            }}
          />
        )}
      </VStack>
    </React.Fragment>
  );
}

ViewWrapper.defaultProps = {
  designLayer: 0,
  navbarDesignLayer: null,
  disableHorizontalScroll: false,
  heightToAccomodate: 0,
  hideNavbar: false,
  alignWithNav: false,
};
export default ViewWrapper;
