import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/private-route';
import useUser from 'data-client/use-current-user.jsx';
import MarketingSelector from './marketing-selector';

export default function MarketingRouter({ isPrivate, userGroupId }) {
  const [user] = useUser();
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  if (!user?.currentGroup?.id) return <></>;

  return (
    <>
      <RouteComponent path="/marketing" exact render={() => <Redirect to={`/marketing/${user?.currentGroup?.id}`} />} />
      <RouteComponent
        path="/marketing/:groupId"
        exact
        render={props => <MarketingSelector {...props} userGroupId={userGroupId} />}
      />
    </>
  );
}
