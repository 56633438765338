import { withTheme } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';

import DropdownSearchHeader from 'components/DropdownSearchHeader/dropdown-search-header';
import _debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ClipLoader } from 'react-spinners';

import { renderFieldError } from 'utils/form';

import { Chevron, disableSelection, Label, LabelIcon, List, ListItem } from '../../features/styles';

const Wrapper = styled.div`
  min-width: 10rem;
  position: relative;
`;

const Activator = styled.div`
  background: ${props => props.theme.colors.background.dropdown[props.colorVariant].default};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border.dropdown[props.colorVariant].default};
  color: ${props => props.theme.colors.text.dropdown[props.colorVariant].default};
  cursor: pointer;
  font-weight: ${props => props.theme.fonts.weights.regular};
  overflow: hidden;
  position: relative;
  transition: background 250ms ease, border-radius 250ms ease;
  height: 2.75rem;
  &:hover {
    ${props =>
    !props.isMenuShowing &&
      `
			background: ${props.theme.colors.background.dropdown[props.colorVariant].hover};
		`}
  }

  ${props =>
    props.isMenuShowing &&
    `
		border-radius: 4px 4px 0 0;
		color: ${props.theme.colors.text.dropdown[props.colorVariant].active};
	`}

  ${props =>
    props.disabled &&
    `
		pointer-events: none;
		background: ${props.theme.colors.background.dropdown[props.colorVariant].disabled};
		color: ${props.theme.colors.text.dropdown[props.colorVariant].disabled};
	`}

	${props =>
    props.isSelected &&
    `
		font-weight: ${props.theme.fonts.weights.medium};
	`}

  ${props =>
    props.invalid &&
    `
    margin: .25rem 0;
    border: 1px solid ${props.theme.colors.border.dropdown[props.colorVariant].error};
  `}

  ${props =>
    props.loading === 'true' &&
    `
    pointer-events: none;
    opacity: 0.75;
  `}
`;

const ButtonLabel = styled(Label)`
  ${disableSelection}
  &:hover {
    svg path {
      stroke: ${props => props.theme.colors.brand.accentHover};
    }
  }
`;

const ClickShrowd = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;

  ${props =>
    props.loading === 'true' &&
    `
    pointer-events: none;
  `}
`;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => (props.short ? props.length * 2 + 0.5 : props.length * 3)}rem;
  max-height: 200px;
  overflow-y: scroll;
`;

const FadeWrapper = styled.div`
  opacity: ${props => (props.isAnimating ? 0 : 1)};
  transition: opacity 250ms ease;
`;

const HiddenSelect = styled.select`
  display: none;
`;

// eslint-disable-next-line
const Dropdown = React.forwardRef(
  (
    {
      className,
      disabled,
      error,
      name,
      loading,
      onChange,
      onButtonClick,
      buttonText,
      options,
      style,
      theme,
      short,
      defaultValue,
      dark,
      light,
      value,
    },
    ref
  ) => {
    const colorVariant = 'dark';
    const [isMenuShowing, setIsMenuShowing] = useState(false);
    // eslint-disable-next-line
    const [isAnimating, setIsAnimating] = useState(false);
    const [selectedItem, setSelectedItem] = useState(options.find(o => o.value === value));
    const [refActivator, setRefActivator] = useState(null);
    const [filterString, setFilterString] = useState('');
    const [filteredItems, setFilteredItems] = useState([]);
    const refContainer = useRef(null);

    useEffect(() => {
      const matchingOption = options?.find(x => x.value === value);

      if (matchingOption && (!selectedItem || matchingOption.value !== selectedItem.value)) {
        setSelectedItem(matchingOption);
        onChange(matchingOption.value, matchingOption);
      }
    }, [options, value]);

    useEffect(() => {
      if (isMenuShowing) {
        setFilteredItems(
          options.filter(option => option.label?.toLowerCase().indexOf(filterString.toLowerCase()) !== -1)
        );
      } else {
        setFilterString('');
      }
    }, [isMenuShowing, filterString, options]);

    // Global document click handler

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);

      return () => document.removeEventListener('click', handleDocumentClick);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDocumentClick = e => {
      if (e.target !== refContainer.current && !refContainer.current.contains(e.target)) {
        setIsMenuShowing(false);
      }
    };

    // Calculate dropdown menu height

    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRefActivator = useCallback(node => {
      if (node && !node.isEqualNode(refActivator)) {
        setRefActivator(node);
      }
    });

    // Event Listeners

    const handleActivatorClick = () => {
      setIsMenuShowing(prev => !prev);
    };

    const handleItemClick = item => {
      setSelectedItem(item);
      setIsMenuShowing(false);
      onChange(item.value, item);
    };

    const handleFilterChange = _debounce(value => setFilterString(value));

    return (
      <Wrapper ref={refContainer} style={style}>
        <Activator
          className={className}
          isMenuShowing={isMenuShowing}
          isSelected={!!selectedItem?.value}
          disabled={disabled ? true : undefined}
          colorVariant={colorVariant}
          invalid={!!error}
          loading={loading?.toString()}
          ref={onRefActivator}>
          <Label dropdown short={short}>
            {selectedItem?.label}
          </Label>
          {loading ? (
            <ClipLoader
              color={short ? theme.colors.brand.layer0 : theme.colors.brand.textWhite}
              cssOverride={{
                position: 'absolute',
                top: 0,
                right: '1rem',
                bottom: 0,
                margin: 'auto 0',
                height: '20px',
                width: '20px',
              }}
            />
          ) : (
            <Chevron
              type="chevronDown"
              isMenuShowing={isMenuShowing}
              color={theme.colors.text.dropdown[colorVariant][disabled ? 'disabled' : 'default']}
              small={short}
            />
          )}
          <ClickShrowd loading={loading?.toString()} onClick={() => handleActivatorClick()} />
        </Activator>
        <List
          style={{
            display: !isMenuShowing ? 'none' : 'block',
          }}
          colorVariant={colorVariant}>
          <DropdownSearchHeader
            includeDeselectAll={false}
            includeSelectAll={false}
            onFilterChange={e => handleFilterChange(e.target.value)}
            dark={light}
            light={dark}
          />
          {buttonText && (
            <ListItem onClick={onButtonClick} colorVariant={colorVariant} button>
              <ButtonLabel dropdown short={short}>
                <LabelIcon type="plus" /> <span>{buttonText}</span>
              </ButtonLabel>
            </ListItem>
          )}
          <ListItemWrapper short={short} length={options.length}>
            <FadeWrapper isAnimating={isAnimating}>
              {filteredItems.map((option, i) => (
                <ListItem
                  key={i}
                  onClick={() => handleItemClick(option)}
                  isSelected={option.value === selectedItem?.value}
                  colorVariant={colorVariant}>
                  <Label dropdown short={short}>
                    {option.label}
                  </Label>
                </ListItem>
              ))}
            </FadeWrapper>
          </ListItemWrapper>
        </List>
        <HiddenSelect name={name} defaultValue={defaultValue} ref={ref}>
          <option value="" />
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </HiddenSelect>
        {error?.[selectedItem.id] ? renderFieldError(error?.[selectedItem.id]) : error ? renderFieldError(error) : null}
      </Wrapper>
    );
  }
);

Dropdown.defaultProps = {
  disabled: false,
  doShowError: false,
  error: null,
  options: [],
  buttonText: null,
  short: false,
  dark: false,
  light: false,
  onChange: () => {},
  onButtonClick: () => {},
  value: '',
  defaultValue: '',
};

export default withTheme(Dropdown);
