import React, { useState } from 'react';
import { Duration } from 'luxon';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { Box, Button, Heading, Text, useTheme, useDisclosure } from '@chakra-ui/react';
import { FiArrowLeft as ArrowLeft } from 'react-icons/fi';
import SearchBar from 'components/search-bar';
import useDebounce from 'utils/debounce';
import ReleasedTracksTable from './previous-released-tracks-table';
import ManageContentBox from 'components/ManageContentBox/manage-content-box';
import FallbackCoverArt from 'assets/fallback-cover-art.png';
import { useReleaseArtwork } from 'hooks/use-release-artwork';
import CardLoadingOverlay from '../../components/loading-containers/card-loading-overlay';
import asModal from '../../features/Modal/as-modal';

import { track } from 'utils/API';
const { findTracksForClipsCopy, copyTrackReleaseForClips } = track();
const PAGE_SIZE = 8;
export default function ManageNewClipFromPreviousRelease() {
  const history = useHistory();
  const theme = useTheme();

  const [searchTerm, setSearchTerm] = useState('');
  const [onPage, setOnPage] = useState(0);

  const [errorMessage, setErrorMessage] = useState(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedTrack, setSelectedTrack] = useState(null);

  const errorCopyingModal = useDisclosure();

  const releaseTracksQuery = useQuery(['getTracks', debouncedSearchTerm, onPage], () =>
    findTracksForClipsCopy({ search: debouncedSearchTerm, page_index: onPage, page_size: PAGE_SIZE })
  );

  const { isLoading: isCopying, mutate: copyTrackRelease } = useMutation(
    ({ asset_track_id, asset_release_id }) => copyTrackReleaseForClips({ asset_track_id, asset_release_id }),
    {
      onSuccess: data => {
        if (data) {
          return history.push(`/manage/clip?id=${data?.asset_release_id}`);
        }
      },
      onError: error => {
        setErrorMessage(error.response?.data?.detail || 'There was an uknown error');
        setSelectedTrack(null);
        errorCopyingModal.onOpen();
      },
    }
  );

  function selectReleaseAction(track) {
    setSelectedTrack(track);
  }

  async function confirmSelection({ asset_track_id, asset_release_id }) {
    copyTrackRelease({ asset_track_id, asset_release_id });
  }

  const ErrorCopyingModal = asModal(function ErrorCopying() {
    return <Text margin={'1rem 0'}>{errorMessage}</Text>;
  });

  if (isCopying)
    return <CardLoadingOverlay text={'Setting up your release'} style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} />;

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box width="100%" maxWidth={'37.5rem'}>
        <Box pt="2rem" pb="1rem">
          <Button variant="transparent" leftIcon={<ArrowLeft />} onClick={() => history.goBack()}>
            Back to Release Type
          </Button>
        </Box>

        <Heading as="h4" color={theme.colors.black80} fontWeight="bold" fontSize="1rem">
          What previous release would you like to copy the info and content?
        </Heading>

        {!selectedTrack ? (
          <Box>
            <Box mt={'4rem'}>
              <Text>search for a previous release</Text>
              <SearchBar
                searchTerm={searchTerm}
                onSearchTermChange={setSearchTerm}
                testCode={'release-track-search'}
                inputGroupProps={{ style: { borderRadius: '0.5rem' } }}
                placeholder="Search by Artist or track name"
              />
            </Box>
            <Box mt={'4rem'}>
              <Text>{searchTerm ? '' : 'or choose a recent track'}</Text>
            </Box>
            <ReleasedTracksTable
              releaseTracksQuery={releaseTracksQuery}
              setOnPage={setOnPage}
              onPage={onPage}
              selectReleaseAction={selectReleaseAction}
            />
          </Box>
        ) : (
          <ConfirmCopySelectionComponent selectedTrack={selectedTrack} confirmSelection={confirmSelection} />
        )}
        <ErrorCopyingModal
          variant="light"
          hideCancel={true}
          headerText={'Error copying release'}
          isOpen={errorCopyingModal.isOpen}
          onClose={errorCopyingModal.onClose}
          onSubmit={errorCopyingModal.onClose}
          submitText="OK"
        />
      </Box>
    </Box>
  );
}

function getFormattedDurationFromTrack(track) {
  const durationSeconds = track?.assets?.[0]?.attributes?.duration || 0;

  //In rare cases where ffmpeg can't parse the duration we don't want to crash.
  //and need to just return nothing.
  if (durationSeconds === 'N/A') {
    return '';
  }

  const asset_duration = Duration.fromObject({ seconds: durationSeconds });
  return asset_duration.toFormat('m:ss');
}

function ConfirmCopySelectionComponent({ selectedTrack, confirmSelection }) {
  const imgUrl = useReleaseArtwork(selectedTrack?.asset_release_id, '50x50');
  return (
    <Box display={'flex'} justifyContent={'center'} flexDir={'column'} alignItems={'center'}>
      <ManageContentBox width="25rem">
        <Box display={'flex'} justifyContent={'center'}>
          <Box>
            <Box display={'flex'} alignItems={'center'}>
              <Box width="50px">
                <img src={imgUrl || FallbackCoverArt} />
              </Box>
              <Box marginLeft="2rem">
                <Text fontSize={'1.15rem'} fontWeight={'bold'}>
                  {selectedTrack.release_title}
                </Text>
                <Text>{selectedTrack.track_display_artist_name}</Text>
              </Box>
            </Box>
            <Box display="flex" mt="1rem" justifyContent={'space-between'}>
              <Box>{selectedTrack.track_title}</Box>
              {/*0th asset should always be the last created as received from the api.*/}
              <Box>{getFormattedDurationFromTrack(selectedTrack)}</Box>
            </Box>
          </Box>
        </Box>
      </ManageContentBox>
      <Text mt="1rem">To generate clips from your track, it must be at least 60 seconds.</Text>
      <Button
        mt={'1rem'}
        onClick={() =>
          confirmSelection({
            asset_release_id: selectedTrack?.asset_release_id,
            asset_track_id: selectedTrack?.asset_track_id,
          })
        }
        width="25rem"
        variant="dark">
        yes, create release
      </Button>
    </Box>
  );
}
