import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';

const Wrapper = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.background.dropdownSectionHeader[props.colorVariant].default};
  box-sizing: border-box;
  color: ${props => props.theme.colors.text.dropdownSectionHeader[props.colorVariant].default};
  display: flex;
  text-transform: uppercase;
  font-size: ${props => props.theme.fonts.sizes.xsm};
  letter-spacing: 0.1rem;
  width: 100%;
  height: 2rem;
  padding: 0 1rem;
  font-weight: 700;
`;

const DropdownSectionHeader = React.forwardRef(({ className, label, dark, light }, ref) => {
  const colorVariant = dark ? 'dark' : light ? 'light' : 'dark';
  return (
    <Wrapper className={className} colorVariant={colorVariant}>
      {label}
    </Wrapper>
  );
});

DropdownSectionHeader.displayName = 'DropdownSectionHeader';

DropdownSectionHeader.defaultProps = {
  label: 'Category',
};

export default withTheme(DropdownSectionHeader);
