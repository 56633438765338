import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogLogs } from '@datadog/browser-logs';
import styled from '@emotion/styled/macro';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { ReactQueryDevtools } from 'react-query/devtools';
import Helmet from 'react-helmet';
import 'focus-visible/dist/focus-visible';

import Theme, { chakraTheme } from './theme';
import PrivateRoute from './components/PrivateRoute/private-route';
import Generic from './views/ErrorPages/generic';
import { ModalsProvider } from './utils/useModals';
import { GlobalDataProvider, useGlobalData } from './utils/global-data';
import { AudioPlayerProvider } from './utils/audio-player';
import { UploaderProvider } from './utils/uploader';
import { useAuth0 } from './utils/auth0';
import { ReleaseFormProvider } from './utils/use-release-form';
import { FiltersProvider } from './utils/use-filters';
import Routes from './utils/routes';

const LOGO_SIZE = '208x208';

const LoadingWrapper = styled.div``;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

function RoutesWithAuth0() {
  const [loadingMessage] = useState('');
  const { loading, isAuthenticated, logout, user } = useAuth0();
  const { selectedUserGroup, loadFile, fileUrls, fileUrlStatus } = useGlobalData();

  useEffect(() => {
    localStorage.setItem('scale_music_omni_token', '');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedUserGroup && selectedUserGroup.id) {
      const logoDoesntExist =
        !fileUrlStatus.group ||
        !fileUrlStatus.group[selectedUserGroup.id] ||
        !fileUrlStatus.group[selectedUserGroup.id].logo ||
        !fileUrlStatus.group[selectedUserGroup.id].logo[LOGO_SIZE];
      if (logoDoesntExist) {
        loadFile('group', 'logo', LOGO_SIZE, { groupId: selectedUserGroup.id });
      }
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrlStatus, fileUrls, selectedUserGroup]);

  if (!isAuthenticated) {
    return <PrivateRoute path="/" exact render={() => <div>{loadingMessage}</div>} />;
  }

  if (loading) {
    return <LoadingWrapper>{loadingMessage}</LoadingWrapper>;
  }

  return <Routes logout={logout} user={user} isPrivate={true} />;
}

if (global.APP.RUNTIME !== 'development') {
  datadogLogs.init({
    clientToken: 'pub26c267ceb8abc166ed08d7537abb4117',
    forwardErrorsToLogs: true,
    sampleRate: 100,
    version: process.env.REACT_APP_GIT_SHA,
    service: 'public-browser',
  });
}

const onErrorHandler = (error, info) => {
  datadogLogs.logger.info('Error boundary hit', {
    pathname: window?.location?.pathname,
    error,
    info,
  });
};

const theme = extendTheme(chakraTheme);

function App({ enableAuth0 }) {
  return (
    <ErrorBoundary FallbackComponent={() => <Generic />} onError={onErrorHandler}>
      <FiltersProvider>
        <QueryClientProvider client={queryClient}>
          <GlobalDataProvider>
            <AudioPlayerProvider>
              <UploaderProvider>
                <ReleaseFormProvider>
                  <Router>
                    <ChakraProvider theme={theme}>
                      <Theme>
                        <ModalsProvider>
                          <Helmet>
                            <style>{`body { background-color: #ffffff; }`}</style>
                          </Helmet>
                          {enableAuth0 ? <RoutesWithAuth0 /> : <Routes isPrivate={false} />}
                          <ReactQueryDevtools initialIsOpen={false} />
                        </ModalsProvider>
                      </Theme>
                    </ChakraProvider>
                  </Router>
                </ReleaseFormProvider>
              </UploaderProvider>
            </AudioPlayerProvider>
          </GlobalDataProvider>
        </QueryClientProvider>
      </FiltersProvider>
    </ErrorBoundary>
  );
}

export default App;
