import React, { useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useGlobalData } from 'utils/global-data';
import useCardFlow from 'utils/use-card-flow';
import * as API from 'utils/API/API';
import { Col, Row } from 'react-styled-flexboxgrid';
import { DateTime } from 'luxon';
import Button from 'components/Button/button';
import FieldLabel from 'components/FieldLabel/field-label';
import TextInput from 'features/TextInput/text-input';
import StyledSelect from 'components/StyledSelect/styled-select';
import InfoTooltip from 'components/InfoTooltip/info-tooltip';
import ENUMS from '../../../constants/ENUMS.gen.json';
import { LANGUAGES2 } from 'constants/dictionaries';
import Checkbox from 'features/Checkbox/checkbox';
import DatePicker from 'features/DatePicker/date-picker';
import { CardContext } from '../card-context';
import { HStack } from '@chakra-ui/react';

import { CardContentWrapper, CardHeaderBlock, CardLoadingWrapper } from './card-style';

import { ButtonCol, FormGridRow, VerticalCenter } from 'styles';
import { CARDS } from '../card';
import HEAP from '../../../constants/HEAP.gen.json';
import { validateUPC } from 'utils/productIdentifiers/validateUPC';

const Release = API.release();

// as we approach the end of the year, default the copyright_year to the following year
const OCTOBER = 10;
const getCopyrightYear = () =>
  DateTime.local().month >= OCTOBER ? Number(DateTime.local().year) + 1 : DateTime.local().year;

const ReleaseDetails = () => {
  const CARD_ID = CARDS.ReleaseDetails;
  const {
    id,
    getCardData,
    handleSubmitError,
    handleResetSaveAndPreview,
    afterSaveActions,
    loadingAny,
    NAV_DIRECTIONS,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const {
    _passthrough,
    updateMultipleCards,
    updateCard_Form,
    refreshLayoutState,
    cardData,
    isReviewMode,
    loadingSaveRelease,
    isFlowAnimating,
  } = useCardFlow();

  const { release, dbUser } = useGlobalData();
  const releaseId = release?.id;

  const formMethods_ReleaseDetails = useForm({ mode: 'onBlur' });
  const formValues_ReleaseDetails = formMethods_ReleaseDetails.getValues({
    nest: true,
  });
  const errors_ReleaseDetails = formMethods_ReleaseDetails.errors;

  useEffect(() => {
    if (id === CARD_ID) {
      const cardData = getCardData('formData');
      formMethods_ReleaseDetails.reset({
        ...cardData,
        copyright_year: cardData?.copyright_year || getCopyrightYear(),
        display_label_name: cardData?.display_label_name || `${dbUser.currentGroup.name}, Distributed by Venice Music`,
      });
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_ReleaseDetails.handleSubmit(
        data => save_ReleaseDetails(data, _passthrough.inducePreview),
        _data => handleSubmitError(_passthrough.inducePreview)
      )();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  useEffect(() => {
    refreshLayoutState();
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors_ReleaseDetails]);

  const handleUPCValidation = async value => {
    if (!value) {
      return true;
    }

    const validationErrors = validateUPC(value);
    if (validationErrors.length) {
      return validationErrors[0];
    }

    const res = await API.release().getAll({ upc: value, page_size: 1, page_index: 0 });
    const upcRelease = res.results?.[0];
    if (upcRelease && upcRelease.id !== release.id) {
      return 'This is a duplicate UPC';
    }

    return true;
  };

  const save_ReleaseDetails = (data, forcePreview = false, navigation = null) => {
    const saveData = { ...formValues_ReleaseDetails, ...data };

    if (saveData._meta.isCompilation) {
      updateMultipleCards({
        ReleaseDetails: { formData: saveData },
        ReleaseArtists: { formData: { artists: [] } },
      });
    } else {
      updateCard_Form(id, saveData);
    }

    const showPreview = forcePreview || isReviewMode;
    afterSaveActions(showPreview, navigation);
  };

  const getSelectionYears = () => {
    return Array(4)
      .fill(Number(new Date().getFullYear()))
      .map((year, i) => (
        <option key={i} value={Number(year) + 1 - i}>
          {year + 1 - i}
        </option>
      ))
      .reverse();
  };

  const releaseUPCPermanent = release?.hasBeenDelivered;
  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <FormProvider {...formMethods_ReleaseDetails}>
          <form
            onSubmit={formMethods_ReleaseDetails.handleSubmit(data =>
              save_ReleaseDetails(data, false, NAV_DIRECTIONS.FORWARD)
            )}
            autoComplete="off">
            <input
              name="_meta.releaseType"
              type="hidden"
              ref={formMethods_ReleaseDetails.register({ required: false })}
            />
            <CardHeaderBlock omnishareas>
              <Row>
                <Col xs={8}>
                  <h3>Release Details</h3>
                </Col>
                <ButtonCol xs={4}>
                  {!isReviewMode && (
                    <Button
                      text="Back"
                      icon="chevronLeft"
                      onClick={() =>
                        save_ReleaseDetails(formMethods_ReleaseDetails.watch(), false, NAV_DIRECTIONS.BACK)
                      }
                      disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                      tertiary
                      heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_BUTTON_BACK}
                    />
                  )}
                  <Button
                    text={isReviewMode ? 'Save & Preview' : 'Next'}
                    rightIcon={isReviewMode ? null : 'chevronRight'}
                    onClick={formMethods_ReleaseDetails.handleSubmit(data =>
                      save_ReleaseDetails(data, false, NAV_DIRECTIONS.FORWARD)
                    )}
                    loading={loadingSaveRelease || loadingAny}
                    disabled={isFlowAnimating}
                    submit
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_BUTTON_NEXT}
                  />
                </ButtonCol>
              </Row>
            </CardHeaderBlock>
            <Row>
              <Col xs={12}>
                <FormGridRow>
                  <Col xs={4}>
                    <HStack alignItems="flex-start">
                      <FieldLabel text="Display Artist Name" />
                      <InfoTooltip text="Enter the performing artist names how you would like them to be displayed on music platforms" />
                    </HStack>
                    <TextInput
                      name="display_artist_name"
                      data-lpignore={true}
                      error={errors_ReleaseDetails['display_artist_name']}
                      disabled={loadingSaveRelease || loadingAny}
                      ref={formMethods_ReleaseDetails.register({
                        required: true,
                      })}
                      heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_INPUT_ARTIST}
                    />
                  </Col>
                  <Col xs={8}>
                    <HStack alignItems="flex-start">
                      <FieldLabel text="Display Label Name" />
                      <InfoTooltip text="Identifies the owner (artist or label) of the rights to the master sound recording (aka “p line”)" />
                    </HStack>
                    <TextInput
                      name="display_label_name"
                      data-lpignore={true}
                      type="text"
                      disabled={loadingSaveRelease || loadingAny}
                      error={errors_ReleaseDetails['display_label_name']}
                      ref={formMethods_ReleaseDetails.register({
                        required: true,
                      })}
                      heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_INPUT_LABEL}
                    />
                  </Col>
                </FormGridRow>
                <FormGridRow>
                  <Col xs={4}>
                    <HStack alignItems="flex-start">
                      <FieldLabel text="Copyright Year" />
                      <InfoTooltip text="Select the year the sound recording rights were granted to the rights owner" />
                    </HStack>
                    <StyledSelect
                      name="copyright_year"
                      disabled={loadingSaveRelease || loadingAny}
                      error={errors_ReleaseDetails['copyright_year']}
                      variant={'dark'}
                      ref={formMethods_ReleaseDetails.register({
                        required: true,
                      })}
                      heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_SELECT_COPYRIGHT}>
                      <option value="">Select...</option>
                      {getSelectionYears()}
                    </StyledSelect>
                  </Col>
                  <Col xs={4}>
                    <HStack alignItems="flex-start">
                      <FieldLabel text="Genre" />
                      <InfoTooltip text="If the desired genre is not listed, select the genre that most closely matches the mood of your release" />
                    </HStack>
                    <StyledSelect
                      name="genre"
                      disabled={loadingSaveRelease || loadingAny}
                      error={errors_ReleaseDetails['genre']}
                      ref={formMethods_ReleaseDetails.register({
                        required: true,
                      })}
                      heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_SELECT_GENRE}>
                      <option value="">Select...</option>
                      {Object.values(ENUMS.ASSET_GENRE).map((genre, i) => (
                        <option key={i} value={genre}>
                          {genre}
                        </option>
                      ))}
                    </StyledSelect>
                  </Col>
                  <Col xs={4}>
                    <FieldLabel text="Language" />
                    <StyledSelect
                      name="lang"
                      disabled={loadingSaveRelease || loadingAny}
                      error={errors_ReleaseDetails['lang']}
                      ref={formMethods_ReleaseDetails.register({
                        required: true,
                      })}
                      heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_SELECT_LANGUAGE}>
                      <option value="">Select...</option>
                      {LANGUAGES2.map((language, i) => (
                        <option key={i} value={language.value}>
                          {language.label}
                        </option>
                      ))}
                    </StyledSelect>
                  </Col>
                </FormGridRow>
                <FormGridRow>
                  <Col xs={4}>
                    <FieldLabel text="" />
                    <VerticalCenter style={{ height: '42px' }}>
                      <Checkbox
                        light
                        name="_meta.includeExistingUPC"
                        label={'Enter your own UPC'}
                        disabled={loadingSaveRelease || loadingAny || releaseUPCPermanent}
                        ref={formMethods_ReleaseDetails.register({
                          required: false,
                        })}
                        heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_CHECKBOX_UPC}
                      />
                      <InfoTooltip text="This 12 digit code uniquely identifies albums and helps music stores track sales of an album release. Leave this option unchecked if you do not own a UPC for your release and Venice will auto-generate a UPC. If you have pre-purchased your own UPC or are migrating a previously distributed release to Venice, select and enter your UPC" />
                    </VerticalCenter>
                  </Col>
                  <Col xs={4}>
                    {formMethods_ReleaseDetails.watch('_meta.includeExistingUPC') && (
                      <>
                        <FieldLabel text="UPC" />
                        <TextInput
                          name="asset_release_upc"
                          data-lpignore={true}
                          type="text"
                          placeholder="Enter UPC"
                          onChange={e => {
                            const value = e.target.value;
                            e.target.value = value.trim();
                          }}
                          error={errors_ReleaseDetails['asset_release_upc']}
                          disabled={loadingSaveRelease || loadingAny || releaseUPCPermanent}
                          ref={formMethods_ReleaseDetails.register({
                            validate: value => {
                              return (
                                !formMethods_ReleaseDetails.watch('_meta.includeExistingUPC') ||
                                (!!value && handleUPCValidation(value))
                              );
                            },
                          })}
                          heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_INPUT_UPC}
                        />
                      </>
                    )}
                  </Col>
                </FormGridRow>
                <FormGridRow>
                  <Col xs={4}>
                    <FieldLabel text="" />
                    <VerticalCenter style={{ height: '42px' }}>
                      <Checkbox
                        light
                        name="_meta.includeOriginalReleaseDate"
                        label={'Re-Release'}
                        disabled={loadingSaveRelease || loadingAny}
                        ref={formMethods_ReleaseDetails.register({
                          required: false,
                        })}
                        heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_CHECKBOX_RERELEASE}
                      />
                      <InfoTooltip text="If an album was previously released through another distributor, select Re-release and enter the original release date. This ensures the track or album is properly sequenced in your discography and not categorized as a new release." />
                    </VerticalCenter>
                  </Col>
                  <Col xs={4}>
                    {formMethods_ReleaseDetails.watch('_meta.includeOriginalReleaseDate') && (
                      <>
                        <FieldLabel text="Original release date" />
                        <DatePicker
                          floatTop
                          name="original_release_date"
                          placeholder="Choose date"
                          setValue={formMethods_ReleaseDetails.setValue}
                          defaultSelectedDate={formMethods_ReleaseDetails.watch('original_release_date')}
                          maxDate={DateTime.local().minus({ days: 1 }).toISODate()}
                          disabled={loadingSaveRelease || loadingAny}
                          error={errors_ReleaseDetails['original_release_date']}
                          clearErrors={formMethods_ReleaseDetails.clearErrors}
                          ref={formMethods_ReleaseDetails.register({
                            required: true,
                          })}
                        />
                      </>
                    )}
                  </Col>
                </FormGridRow>
                <FormGridRow>
                  <Col xs={4}>
                    <FieldLabel text="" />
                    <VerticalCenter style={{ height: '42px' }}>
                      <Checkbox
                        light
                        name="_meta.isCompilation"
                        label={'Compilation'}
                        disabled={loadingSaveRelease || loadingAny}
                        ref={formMethods_ReleaseDetails.register({
                          required: false,
                        })}
                        heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DETAILS_CHECKBOX_COMPILATION}
                      />
                      <InfoTooltip text="Used for albums where each track features varying primary or featured artists (label compilations, original movie soundtracks, etc.) Selecting compilation will display the release artist name as ‘Various Artists’." />
                    </VerticalCenter>
                  </Col>
                </FormGridRow>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};

export default ReleaseDetails;
