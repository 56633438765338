import React, { useState } from 'react';
import { Region, WaveForm } from 'wavesurfer-react';
import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { DateTime } from 'luxon';
// import pinkMarker from './pinkMarker.svg';

const ClipWaveForm = ({ waveFormId, handleRegionUpdate, regionConfig = {}, loading }) => {
  const theme = useTheme();
  const [regionBounds, setRegionBounds] = useState({
    start: DateTime.fromMillis(regionConfig.start * 1000).toFormat('mm:ss'),
    end: DateTime.fromMillis(regionConfig.end * 1000).toFormat('mm:ss'),
  });

  const waveFormBoxStyles = {
    w: '100%',
    bg: theme.colors.black10,
    padding: '1.5rem 2rem .75rem 2rem',
    borderRadius: '.75rem',
  };

  const onRegionUpdate = (region, event) => {
    setRegionBounds({
      start: DateTime.fromMillis(region.start * 1000).toFormat('mm:ss'),
      end: DateTime.fromMillis(region.end * 1000).toFormat('mm:ss'),
    });
    handleRegionUpdate(region, event);
  };

  return (
    <>
      <Box {...waveFormBoxStyles}>
        <WaveForm id={waveFormId} cursorColor="transparent">
          {!loading && (
            <Region onUpdateEnd={onRegionUpdate} {...{ loop: true }} key={regionConfig.id} {...regionConfig} />
          )}
          {loading && <Text>Analyzing audio...</Text>}
        </WaveForm>
        {!loading && (
          <Flex justifyContent="space-between" color={theme.colors.black60} fontSize=".75rem">
            {/* playBackSeconds is being passed through in the GenerateNewWaveForm component to limit scope */}
            <Text>{regionBounds.start}</Text>
            <Text>{regionBounds.end}</Text>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default ClipWaveForm;
