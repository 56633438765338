import React from 'react';
import {
  Box,
  Icon,
  Table,
  Thead,
  Th,
  Tr,
  Td,
  useDisclosure,
  chakra,
  Center,
  Spinner,
  useTheme,
  Tbody,
  IconButton,
  Flex,
  useToast,
} from '@chakra-ui/react';
import { useQueryClient, useMutation } from 'react-query';
import { useTable, useSortBy } from 'react-table';
import { RiArrowUpLine, RiArrowDownLine } from 'react-icons/ri';
import TablePaginationFooter from 'components/Table/table-pagination-footer';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import { CheckIcon } from '@chakra-ui/icons';
import ConfirmationModal from 'components/Modal/confirmation-modal';
import * as API from 'utils/API/API';
import SearchBar from 'components/search-bar';

const MarketingDiscoveryModeTracks = API.marketingDiscoveryModeTracks();

const TableRow = ({ track, userGroupId }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const requiredOptInModal = useDisclosure();

  const updateOptInMutation = useMutation(data => MarketingDiscoveryModeTracks.updateOptIn(data), {
    onError: error => {
      toast({
        title: error?.response?.data?.detail || error?.message || `Failed to update track.`,
        status: 'error',
      });
    },
  });

  const onConfirmOptIn = async ({ select_for_campaign, isrc_code }) => {
    await updateOptInMutation.mutateAsync({
      isrc_code,
      opt_in: !select_for_campaign,
      user_group_id: userGroupId,
    });
    requiredOptInModal.onClose();
    await queryClient.invalidateQueries(['discoveryModeDraftTable']);
  };

  return (
    <>
      <Tr {...track.getRowProps()} borderColor={'black20'}>
        {track.cells.map(cell => (
          <Td key={`${cell.column.id}-${track.original.artist_name}-${track.original.isrc_code}`}>
            <Box>
              {cell.render('Cell')}
              {['intent_rate', 'stream_lift', 'listener_lift'].includes(cell.column.id) && '%'}
              {cell.column.id === 'recommended' ? (track.original?.recommended ? 'YES' : 'NO') : ''}
            </Box>
            {cell.column.id === 'select_for_campaign' && (
              <Flex width="100%" justifyContent="left">
                <IconButton
                  size="xs"
                  borderColor="black60"
                  borderWidth="1px"
                  borderStartStyle="solid"
                  backgroundColor="white"
                  aria-label="Required Release Artist"
                  icon={track.original?.select_for_campaign ? <CheckIcon /> : null}
                  onClick={requiredOptInModal.onOpen}
                />
              </Flex>
            )}
          </Td>
        ))}
      </Tr>
      <ConfirmationModal
        headerText={`Track opt-${track.original?.select_for_campaign ? 'out' : 'in'}`}
        body={`Are you sure you want to opt-${track.original?.select_for_campaign ? 'out' : 'in'} these tracks?`}
        confirmText="Confirm"
        disclosure={requiredOptInModal}
        onSubmit={() => onConfirmOptIn(track.original)}
        isLoading={updateOptInMutation.isLoading}
      />
    </>
  );
};

export default function MarketingDiscoveryModeTable({
  data,
  query,
  totalPages,
  page,
  setPage,
  searchTerm,
  setSearchTerm,
  emptyStateMsg = '',
  userGroupId,
}) {
  const theme = useTheme();

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Artist',
        accessor: 'artist_name',
      },
      {
        Header: 'Track',
        accessor: 'track_name',
      },
      {
        Header: 'ISRC',
        accessor: 'isrc_code',
      },
      {
        Header: 'Recommended',
        accessor: 'recommended',
      },
      {
        Header: 'Opt-In/Out',
        accessor: 'select_for_campaign',
      },
    ];
  }, []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'artist_name',
            desc: false,
          },
        ],
      },
    },
    useSortBy
  );

  const onNextPage = React.useMemo(() => () => setPage(p => p + 1), []);
  const onPrevPage = React.useMemo(() => () => setPage(p => p - 1), []);

  const isNextPageDisabled = React.useMemo(
    () => page + 1 >= totalPages || query.isLoading || query.isRefetching,
    [page, totalPages, query.isLoading, query.isRefetching]
  );

  const isPrevPageDisabled = React.useMemo(
    () => page <= 0 || query.isLoading || query.isRefetching,
    [page, query.isLoading, query.isRefetching]
  );

  return (
    <>
      {query.isFetched && !searchTerm && data?.length === 0 ? (
        <Box color="black70" p={6} textAlign="center" fontWeight="bold">
          {emptyStateMsg}
        </Box>
      ) : (
        <>
          <Box pb={5}>
            <SearchBar
              searchTerm={searchTerm}
              onSearchTermChange={setSearchTerm}
              width="25rem"
              placeholder="Search by artist, title or isrc"
            />
          </Box>
          <SkeletonLoader status={query.status} skeletonProps={{ height: '240px', width: '100%' }}>
            <Box overflowX="auto">
              <Table size="sm" variant="light" {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup, groupIndex) => (
                    <Tr key={`${groupIndex}-headergroup`} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, headerIndex) => (
                        <Th
                          key={`${groupIndex}-${headerIndex}-tableheader`}
                          isNumeric={column.isNumeric}
                          {...column.getHeaderProps(column.getSortByToggleProps())}>
                          <Box style={{ color: 'white' }} display="inline">
                            {column.isSorted ? (
                              <chakra.span pr="3" position="relative" top="1px">
                                {column.isSortedDesc ? (
                                  <Icon as={RiArrowUpLine} aria-label="sorted descending" />
                                ) : (
                                  <Icon as={RiArrowDownLine} aria-label="sorted ascending" />
                                )}
                              </chakra.span>
                            ) : null}
                            {column.render('Header')}
                          </Box>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {rows.map(track => {
                    prepareRow(track);
                    return (
                      <TableRow
                        key={`track-${track.original.artist_name}-${track.original.isrc_code}`}
                        track={track}
                        userGroupId={userGroupId}
                      />
                    );
                  })}
                </Tbody>
              </Table>
              {query.isRefetching && (
                <Box
                  style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    right: 0,
                    top: 0,
                    backgroundColor: theme.colors.brand.layer4,
                    opacity: '25%',
                  }}>
                  <Center h="full">
                    <Spinner color="black" size="lg" />
                  </Center>
                </Box>
              )}
            </Box>
            <TablePaginationFooter
              onPrevPage={onPrevPage}
              isPrevPageDisabled={isPrevPageDisabled}
              page={page}
              totalPages={totalPages}
              onNextPage={onNextPage}
              isNextPageDisabled={isNextPageDisabled}
            />
          </SkeletonLoader>
        </>
      )}
    </>
  );
}
