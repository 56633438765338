import { useState } from 'react';
import { SEARCH_RESULT_TYPES, SORT_DIRECTIONS } from '../../constants/constants';
import { useQuery } from 'react-query';
import * as API from '../../utils/API/API';
import { isISRC } from 'utils/helpers';

const Search = API.search();

/*
  (semi)Immutable container for storing options state
  and providing helpers for state resolution.
  All setters provide a new instance of itself which
  allow react to skip the expensive parts of state diff
  reconciliation while allowing the data wrapped by the
  container to mutate.

  THIS IS ONLY AS IMMUTABLE AS IT'S SETTERS ARE BEING USED.
  If properties are being assigned directly by the user then
  a new instance cannot be provided and react will have to traverse
  the object tree during state reconciliation.
 */
class ImmutableSortOptionsListContainer extends Array {
  clone() {
    return new ImmutableSortOptionsListContainer(...this);
  }

  get selected() {
    return this.find(option => option.selected) || this[0];
  }

  setSelected(option) {
    return new ImmutableSortOptionsListContainer(
      ...this.map(o => {
        o.selected = o.param === option.param;
        return o;
      })
    );
  }

  setSearchTerm(searchTerm) {
    this.searchTerm = searchTerm;
    return this.clone();
  }

  setSelectedByFriendly(friendlyString) {
    return new ImmutableSortOptionsListContainer(
      ...this.map(o => {
        o.selected = o.friendly === friendlyString;
        return o;
      })
    );
  }

  get direction() {
    return this.selected.direction;
  }

  setDirection(newDirection) {
    this.selected.direction = newDirection;
    return this.clone();
  }

  toggleDirection() {
    this.selected.direction =
      this.selected.direction === SORT_DIRECTIONS.ASC ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC;

    return this.clone();
  }

  get param() {
    return this.selected.param;
  }

  get friendly() {
    return this.selected.friendly;
  }

  has({ param }) {
    return !!this.find(o => o.param === param);
  }

  delete(option) {
    return new ImmutableSortOptionsListContainer(...this.filter(o => o.param !== option.param));
  }

  deleteByFriendly(friendlyString) {
    return new ImmutableSortOptionsListContainer(...this.filter(o => o.friendly !== friendlyString));
  }

  get disabled() {
    return !!this.selected.disabled;
  }
}

/*
   Allow this to mutate to retain the state the user puts it in
 */
const mutableDefaultSortOptions = [
  { friendly: 'Release date', param: 'date', selected: true, direction: SORT_DIRECTIONS.DESC },
  { friendly: 'Alphabetical', param: 'title', selected: false, direction: SORT_DIRECTIONS.ASC },
  { friendly: 'Total streams', param: 'streams', selected: false, direction: SORT_DIRECTIONS.DESC },
];

const mutableSearchOptions = [
  {
    friendly: 'Relevance',
    param: null,
    selected: true,
    direction: null,
    disabled: true,
  },
];

const searchSortOptionsList = new ImmutableSortOptionsListContainer(...mutableSearchOptions);

/*
  THIS IS ONLY AS IMMUTABLE AS IT'S SETTERS ARE BEING USED.
  If properties are being assigned directly by the user then
  a new instance cannot be provided and react will have to traverse
  the object tree during state reconciliation.
 */
class ImmutableCatalogStateContainer {
  pageIndex = 0;
  optionsList = new ImmutableSortOptionsListContainer(...mutableDefaultSortOptions);
  searchTerm = '';
  total = 0;
  pages = [];

  constructor(immutableCatalogState) {
    if (immutableCatalogState) {
      Object.entries(immutableCatalogState).forEach(entry => {
        const [key, value] = entry;
        this[key] = value;
      });
    }
  }

  clone() {
    return new ImmutableCatalogStateContainer(this);
  }

  resetPageState() {
    this.pages = [];
    this.pageIndex = 0;
    this.total = 0;
  }

  setTotal(total) {
    this.total = total;
    return this.clone();
  }

  get currentSearchTerm() {
    return this.searchTerm;
  }

  setCurrentSearchTerm(nextSearchTerm) {
    if (nextSearchTerm && nextSearchTerm !== this.searchTerm) {
      this.resetPageState();
      this.searchTerm = nextSearchTerm;
      return this.setOptionsList(searchSortOptionsList);
    }

    return this;
  }

  setOptionsList(optionsList) {
    this.resetPageState();
    this.optionsList = optionsList;
    return this.clone();
  }

  incrementPageIndex() {
    this.pageIndex = this.pageIndex + 1;
    return this.clone();
  }

  get currentPage() {
    return this.pages[this.pageIndex];
  }

  get hasNextPage() {
    return this.pages.flat().length < this.total;
  }

  toggleSortDirection() {
    this.resetPageState();
    this.optionsList = this.optionsList.toggleDirection();
    return this.clone();
  }

  concatPage(page) {
    this.pages = [...this.pages, page];
    return this.clone();
  }

  toQueryKey() {
    return [this.pageIndex, this.optionsList.param, this.optionsList.direction, this.searchTerm];
  }
}

const searchCatalogState = new ImmutableCatalogStateContainer({ optionsList: searchSortOptionsList });

export function useCatalogState(match) {
  const [catalogState, setCatalogState] = useState(new ImmutableCatalogStateContainer());

  const onSortChange = optionsList => {
    setCatalogState(prevState => {
      return prevState.setOptionsList(optionsList);
    });
  };

  const handleSetSearch = nextSearchTerm => {
    if (nextSearchTerm && searchCatalogState.searchTerm !== nextSearchTerm) {
      setCatalogState(searchCatalogState.setCurrentSearchTerm(nextSearchTerm));
    } else if (!nextSearchTerm) {
      setCatalogState(new ImmutableCatalogStateContainer());
    }
  };
  return [catalogState, setCatalogState, onSortChange, handleSetSearch];
}

export function useCatalogQuery(catalogState, setCatalogState, PAGE_SIZE) {
  return useQuery(
    ['releases', ...catalogState.toQueryKey()],
    () => {
      const endpoint = catalogState.searchTerm ? Search.search : Search.list;
      const isrc_code = isISRC(catalogState.searchTerm) ? catalogState.searchTerm : undefined;
      const inputParams = [
        { sortBy: catalogState.optionsList.param },
        { search: !isrc_code ? catalogState.searchTerm : undefined },
        { direction: catalogState.optionsList.direction },
        { isrc_code: isrc_code },
      ].reduce((a, c) => {
        return Object.entries(c).every(([, v]) => !!v) ? { ...a, ...c } : a;
      }, {});

      return endpoint(SEARCH_RESULT_TYPES.RELEASES, {
        page_size: PAGE_SIZE,
        page_index: catalogState.pageIndex,
        ...inputParams,
      });
    },
    {
      onSuccess: ({ results, total }) => {
        setCatalogState(prevState => {
          return prevState.concatPage(results).setTotal(total);
        });
      },
    }
  );
}
