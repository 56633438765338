import React, { useState } from 'react';
import ViewWrapper from 'views/ViewWrapper/view-wrapper';
import { Box, Heading, Text, Center, Spinner, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import SearchBar from 'components/search-bar';
import useDebounce from 'utils/debounce';
import { useSearchTracksAndReleases } from 'data-client/use-marketing-links';
import SearchHeading from 'features/SearchHeading/search-heading';
import Releases from 'components/ReleaseList/release-list-dsp-uri';
import Tracks from 'components/TrackTable/track-table-dsp-uri';
import { useSearchSpotify } from 'data-client/use-dsp-uri';
import FetchUriModal from './fetch-uri-modal';
import { RiArrowLeftSLine as ArrowLeftIcon } from 'react-icons/ri';

function GetDspUriBody({ searchTerm, searchQueryIsLoading, searchQueryData }) {
  const [isFetchUriModalOpen, setIsFetchUriModalOpen] = useState(false);
  const [searchSpotifyParams, setSearchSpotifyParams] = useState(null);
  const searchSpotifyResult = useSearchSpotify(searchSpotifyParams);

  const handleCopyToClipboard = async params => {
    setSearchSpotifyParams(params);
    setIsFetchUriModalOpen(true);
  };

  if (searchQueryIsLoading) {
    return (
      <Center mt="1rem">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Box>
        <SearchHeading searchTerm={searchTerm} searchQueryData={searchQueryData} />
      </Box>
      <Releases handleCopyToClipboard={handleCopyToClipboard} releases={searchQueryData?.releases?.results} />
      <Tracks handleCopyToClipboard={handleCopyToClipboard} tracks={searchQueryData?.tracks?.results} />
      <Box mt="3rem" />
      <FetchUriModal
        isOpen={isFetchUriModalOpen}
        onClose={() => setIsFetchUriModalOpen(false)}
        noFooter={true}
        headerText={'Copy URI Links'}
        searchSpotifyResult={searchSpotifyResult}
      />
    </>
  );
}

export default function GetDspUri(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const searchQuery = useSearchTracksAndReleases(debouncedSearchTerm, ['spotify']);

  return (
    <ViewWrapper designLayer={0} {...props}>
      <Box>
        <Button leftIcon={<ArrowLeftIcon />} as={RouterLink} variant="transparent" to="/marketing-links">
          Back to marketing
        </Button>
      </Box>
      <Box>
        <Heading as="h1">Get Spotify URI</Heading>
      </Box>
      <Box mb="2rem">
        <Text>Search for any delivered track or album in your Venice Catalog</Text>
      </Box>
      <Box mb="3.375rem">
        <SearchBar searchTerm={searchTerm} onSearchTermChange={setSearchTerm} width="37.5rem" testCode={'uri-search'} />
      </Box>
      <GetDspUriBody
        searchTerm={debouncedSearchTerm}
        searchQueryIsLoading={searchQuery.isFetching}
        searchQueryData={searchQuery?.data}
      />
    </ViewWrapper>
  );
}
