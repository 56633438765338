import React from 'react';
import { Box } from '@chakra-ui/react';
import ENUMS from 'constants/ENUMS.gen.json';

const { SOUND_RECORDING_ROLE } = ENUMS;

const allRoles = [
  ...[
    { title: 'Primary Artist', value: 'MainArtist' },
    { title: 'Featured Artist', value: 'FeaturedArtist' },
    { title: 'Remixer', value: 'Remixer' },
  ],

  ...Object.values(SOUND_RECORDING_ROLE).map(role => ({
    title: role.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()), //De-Camel Casses
    value: role,
  })),
];

export default function PartyRow({ party }) {
  return (
    <>
      {party.roles.map(partyRole => (
        <Box key={`${party.asset_party_id}-${partyRole}`} display="flex" width="100%">
          <Box width="200px">{party.full_name}</Box>
          <Box>{allRoles.find(role => role.value === partyRole)?.title || 'N/A'}</Box>
        </Box>
      ))}
    </>
  );
}
