import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';
const DataApiUrl = dataApiUrl();

const Metrics = () => {
  const getLabelMetrics = (id, params) =>
    AuthAxios.get(`${DataApiUrl}/metrics/group/${id}`, {
      params,
    }).then(res => res.data);

  const getTrackMetrics = (id, params) =>
    AuthAxios.get(`${DataApiUrl}/metrics/track/${id}`, {
      params,
    }).then(res => res.data);

  const getArtistMetrics = (labelId, artistId, params) =>
    AuthAxios.get(`${DataApiUrl}/metrics/group/${labelId}/artists/${artistId}`, { params }).then(({ data }) => data);

  return {
    getLabelMetrics,
    getTrackMetrics,
    getArtistMetrics,
  };
};

export default Metrics;
