import React from 'react';
import styled from '@emotion/styled/macro';
import { Spring } from 'react-spring/renderprops';
import { animated } from 'react-spring';

import useCardFlow from '../../utils/use-card-flow';

const PushDown = styled.div``;

const FlowWrapper = styled.div`
  box-sizing: border-box;
  left: 0;
  position: absolute;
  width: 100%;
`;

const CardRibbon = styled.div`
  box-sizing: border-box;
  left: 0;
  top: 0;
  position: relative;
  width: 100%;
`;

const CardWrapper = styled(animated.div)`
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const FlowCard = styled(animated.div)`
  position: absolute;
  width: 100%;
  top: 0;
  transition: height 250ms ease;

  ${props =>
    props.debug &&
    `
    &:before {
      content: '${props.debug}';
      color: black;
      font-family: 'Menlo';
      position: absolute;
      top: 0;
      left: 0;
      padding: 1rem;
      background: lightgray;
      z-index: 100;
    }
  `}
`;

const LoadingCard = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.brand.layer0};
  border-radius: 8px;
  flex-direction: column;
  height: 400px;
  justify-content: center;
  margin: 0 0.5rem;
  padding: 3rem 2rem;
`;

const CardFlow = ({ cardLeftOffset, cardWidth }) => {
  const {
    currentStackIndex,
    currentCardIndex,
    currentCardName,
    doImmediate,
    doReset,
    to,
    currentDirection,
    leftCardState,
    rightCardState,
    handleAnimationRest,
    cardStacks,
  } = useCardFlow();

  // Ref for FlowWrapper container

  const CardLoading = () => (
    <CardWrapper>
      <LoadingCard>
        <div
          style={{
            width: '100%',
            backgroundColor: '#2D2A38',
            height: '80px',
            marginBottom: '20px',
          }}></div>
        <div
          style={{
            width: '100%',
            backgroundColor: '#2D2A38',
            height: '20px',
            marginBottom: '20px',
          }}></div>
        <div
          style={{
            width: '100%',
            backgroundColor: '#2D2A38',
            height: '20px',
            marginBottom: '20px',
          }}></div>
      </LoadingCard>
    </CardWrapper>
  );

  // Sometimes spring spits out 'NaN%' or similar. This function scrubs that out.
  const getTranslationValue = (card, springProps, to) => {
    const value = key => (springProps[key].indexOf('NaN') === -1 ? springProps[key] : to[key]);

    switch (card) {
      case 'prev_content':
        return `translateX(calc(${value('prev_content')} - 1rem))`;
      case 'left_content':
        return `translateX(calc(${value('left_content')} - ${value('remUp')}))`;
      case 'right_content':
        return `translateX(calc(${value('right_content')} + ${value('remDn')}))`;
      case 'next_content':
        return `translateX(calc(${value('next_content')} + 1rem))`;
      default:
        return;
    }
  };

  const doShow = {
    prev_content: cardStacks && currentCardIndex !== 0,
    left_content: cardStacks && (currentCardName === 'left_content' || currentCardIndex > 0),
    right_content:
      cardStacks &&
      (currentCardName === 'right_content' || currentCardIndex < cardStacks[currentStackIndex].length - 1),
    next_content: cardStacks && currentCardIndex !== cardStacks[currentStackIndex].length - 1,
  };

  return (
    <>
      <FlowWrapper>
        <CardRibbon
          style={{
            maxWidth: `${cardWidth}px`,
            transform: `translateX(${cardLeftOffset}px)`,
          }}>
          <Spring
            //config={{ delay: 2500 }}
            to={to}
            reset={doReset}
            immediate={doImmediate}
            onRest={() => handleAnimationRest()}>
            {springProps => (
              <>
                {doShow.prev_content && (
                  <FlowCard
                    style={{
                      transform: getTranslationValue('prev_content', springProps, to),
                      opacity: 0.5,
                    }}>
                    {CardLoading(springProps)}
                  </FlowCard>
                )}

                {doShow.left_content && (
                  <FlowCard
                    style={{
                      transform: getTranslationValue('left_content', springProps, to),
                    }}>
                    {(leftCardState.transitioning || !leftCardState.content) && CardLoading(springProps)}
                    {(leftCardState.transitioning || leftCardState.content) && (
                      <CardWrapper
                        style={{
                          opacity:
                            leftCardState.featured && leftCardState.content
                              ? springProps.leftOpacity
                              : leftCardState.featured
                                ? currentDirection === 'BACKWARD'
                                  ? springProps.opacityDn
                                  : springProps.opacityUp
                                : springProps.opacityDn,
                        }}>
                        {leftCardState.featured
                          ? cardStacks[currentStackIndex][currentCardIndex]
                          : currentDirection === 'BACKWARD' || leftCardState.transitioning
                            ? cardStacks[currentStackIndex][currentCardIndex - 1]
                            : CardLoading(springProps)}
                      </CardWrapper>
                    )}
                  </FlowCard>
                )}

                {doShow.right_content && (
                  <FlowCard
                    style={{
                      transform: getTranslationValue('right_content', springProps, to),
                    }}>
                    {(rightCardState.transitioning || !rightCardState.content) && CardLoading(springProps)}
                    {(rightCardState.transitioning || rightCardState.content) && (
                      <CardWrapper
                        style={{
                          opacity: rightCardState.featured
                            ? currentDirection === 'BACKWARD'
                              ? springProps.opacityDn
                              : springProps.opacityUp
                            : springProps.opacityUp,
                        }}>
                        {rightCardState.featured
                          ? cardStacks[currentStackIndex][currentCardIndex]
                          : currentDirection === 'BACKWARD' || rightCardState.transitioning
                            ? cardStacks[currentStackIndex][currentCardIndex + 1]
                            : CardLoading(springProps)}
                      </CardWrapper>
                    )}
                  </FlowCard>
                )}

                {doShow.next_content && (
                  <FlowCard
                    style={{
                      transform: getTranslationValue('next_content', springProps, to),
                      opacity: 0.5,
                    }}>
                    {CardLoading(springProps)}
                  </FlowCard>
                )}
              </>
            )}
          </Spring>
        </CardRibbon>
      </FlowWrapper>
      <PushDown />
    </>
  );
};

export default CardFlow;
