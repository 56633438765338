import React from 'react';
import { RiCloseLine as CancelIcon } from 'react-icons/ri';
import { Button } from '@chakra-ui/react';

const SaveButton = ({ onCancel, onSave, disabled, ...props }) => {
  return (
    <>
      <Button marginRight={'1rem'} variant={'smallDark'} disabled={disabled} onClick={onSave}>
        Save Splits
      </Button>
      <Button variant="transparent" onClick={onCancel} rightIcon={<CancelIcon />}>
        Cancel
      </Button>
    </>
  );
};

export default SaveButton;
