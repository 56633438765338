import React, { useState, useCallback } from 'react';
import { CoManagerInline, CoManagerToolbar, makeMessage } from '../../features/Co-manager/co-manager-modal-inline';
import CoManagerActionItems from '../../features/Co-manager/co-manager-action-items';
import SavedThreadsTab from '../../features/Co-manager/saved-threads-tab';
import CoManagerActionItemModal from './co-manager-action-item-modal';
import { Tabs, TabList, TabPanels, TabPanel, Button, Spacer, useTab, useMultiStyleConfig } from '@chakra-ui/react';

import { useNaturalTimeCategorizedModels } from '../../hooks/use-categorized-models';
import { useCreateActionItem } from 'data-client/use-action-items';
import {
  useCoManagerThreadAdditivePageQuery,
  useCoManagerDeleteThreadMutation,
} from '../../data-client/use-co-manager-thread-data';
import { RiRefreshLine, RiBookmarkLine } from 'react-icons/ri';
import coManagerLogo from '../../assets/co-manager-logo.svg';
import icons from 'utils/icons';

const defaultPrompts = [
  'How do I get on a playlist?',
  'How do I increase my monthly listeners on Spotify?',
  'How do I grow my YouTube Music audience?',
];

const guidedPrompts = [
  {
    title: 'Refresh my bio',
    subTitle: 'for Spotify and socials',
    isGuide: true,
    prompt: 'Refresh my bio for Spotify and Socials',
  },
  {
    title: "Last week's follower growth",
    subTitle: 'on Instagram, YouTube and TikTok',
    isGuide: false,
    prompt: 'What are my follower stats for last week',
  },
  {
    title: 'Create a pitch',
    subTitle: 'to get my latest track on a Spotify playlist',
    isGuide: false,
    prompt:
      'Help me draft a spotify playlist pitch for my latest release that is 3 sentences long. I will pick a focus track from that release. Include my social follower growth in the pitch.',
  },
  {
    title: 'What are my artist IDs?',
    subTitle: 'For Spotify, Apple Music, YouTube and more',
    isGuide: false,
    prompt: 'What are my artists IDs for all platforms?',
  },
];

const TabWithIcon = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const styles = useMultiStyleConfig('Tabs', tabProps);

  const tabStyles = {
    ...styles.tab,

    outline: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '1px 1px 0 1px',
    borderColor: 'cream200',
    color: '#908A83',
    marginRight: '0.25rem',

    '&[aria-selected=true]': {
      outline: '0',
      color: '#645F5F',
      borderStyle: 'solid',
      borderWidth: '1px 1px 1px 1px',
      borderColor: 'cream200',
      borderBottomColor: 'cream100',
      backgroundColor: 'white',
    },
  };

  return (
    <Button {...tabProps} __css={tabStyles}>
      {tabProps.children}
    </Button>
  );
});

const TabButton = React.forwardRef((props, ref) => {
  const tabProps = useTab({ ...props, ref });

  return <Button {...tabProps}>{tabProps.children} </Button>;
});

const ChatView = props => {
  const { isTrialMode } = props;

  const [chatArgs, setChatArgs] = useState({ initialMessages: [], threadId: undefined });
  const [connectionStatus, setConnectionStatus] = useState(0);
  const [CoManagerActionItemModalOpen, setCoManagerActionItemModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [suggestions, setSuggestions] = React.useState(defaultPrompts);

  const {
    mutate: createActionItemMutation,
    error: createActionItemError,
    reset: resetCreateActionItem,
  } = useCreateActionItem();

  const {
    fetchPage: fetchThreadPage,
    modelData: threadData,
    isProcessing: isProcessingThreadDataOperation,
    loadNextPage: loadNextThreadPage,
    removeLocalModel: removeLocalThread,
    pageArgs,
  } = useCoManagerThreadAdditivePageQuery();
  const { categories: threadModelCategories } = useNaturalTimeCategorizedModels({ models: threadData.models });

  const { mutate: deleteThreadMutate } = useCoManagerDeleteThreadMutation({
    onSuccess: (queryFunc, threadId) => {
      removeLocalThread(threadId);

      if (threadId === chatArgs.threadId) {
        setChatArgs({ initialMessages: [], threadId: undefined });
      }
    },
  });

  const [newActionItem, setNewActionItem] = useState({});

  const openThread = useCallback(thread => {
    setChatArgs({ initialMessages: [], threadId: thread ? thread.chat_bot_thread_id : undefined });
    setTabIndex(0);
  }, []);

  /**
   * @param {string} prompt - Initial prompt to send to the chat
   */
  const sendPrompt = prompt => {
    setChatArgs({
      initialMessages: [makeMessage(prompt, 'user', Date.now())],
      threadId: undefined,
    });
  };

  const inlineChatInterface = (initialMessages, threadId, guidedPrompts) => (
    <CoManagerInline
      threadId={threadId}
      initialMessages={initialMessages}
      guidedPrompts={guidedPrompts}
      userInputStyles={{ border: '1px solid', borderColor: 'cream200', width: '100%', borderRadius: '2rem' }}
      setConnectionStatusFn={setConnectionStatus}
      onReloadThread={
        // We can fetch page 0 because threads with new activity should be moved
        // to the top of the order.
        () => fetchThreadPage(0)
      }
      setSuggestions={setSuggestions}
      height="calc(70vh - 16px)"
      isTrialMode={isTrialMode}>
      <CoManagerToolbar pb="0.2rem" width="100%">
        {/* Initial prompts */}
        {initialMessages.length === 0 &&
          suggestions.map(suggestion => (
            <Button
              flexShrink={0}
              onClick={() => sendPrompt(suggestion)}
              variant="outline"
              fontSize="1rem"
              _hover={{ background: 'cream200', color: 'white' }}>
              {suggestion}
            </Button>
          ))}
      </CoManagerToolbar>
    </CoManagerInline>
  );

  const inlineActionItemInterface = () => (
    <>
      <CoManagerActionItems w="100%" />
      <CoManagerActionItemModal
        isOpen={CoManagerActionItemModalOpen}
        headerText="Create Action Item"
        variant="light"
        actionItem={newActionItem}
        errors={createActionItemError?.response?.data}
        onClearErrors={() => {
          resetCreateActionItem();
        }}
        onUpdate={actionItem => {
          setNewActionItem(actionItem);
        }}
        onClose={() => {
          setNewActionItem({});
          setCoManagerActionItemModalOpen(false);
        }}
        onSubmit={() => {
          createActionItemMutation(newActionItem, {
            onSuccess: () => {
              setNewActionItem({});
              setCoManagerActionItemModalOpen(false);
            },
          });
        }}
      />
    </>
  );

  return (
    <>
      {
        // <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={20} width={'100%'} mt="2rem">
        //   <GridItem colSpan={{ base: 1, md: 2 }}>
        //     <ManageContentBox w="100%" maxWidth="100%" border="none" p="0">
        //       <ContentHeader>
        //         <Title>FOR YOU</Title>
        //       </ContentHeader>
        //       <HStack mt="1rem">
        //         {guidedPrompts.map((prompt, index) => (
        //           <CardButton
        //             title={prompt.title}
        //             subTitle={prompt.subTitle}
        //             icon={prompt.icon}
        //             variant="light"
        //             m={0}
        //             key={index}
        //             onClick={() => {
        //               popChatModal(prompt.title);
        //             }}
        //             disabled={/* TODO: Uncomment once other flows are ready:  isTrialMode && */ index > 0}
        //           />
        //         ))}
        //       </HStack>
        //     </ManageContentBox>
        //   </GridItem>
        //   <GridItem>
        //     <ManageContentBox w="100%" maxWidth="100%" p="0" borderRadius="1.2rem 1.2rem 0.3rem 0.3rem" minHeight="475px">
        //       <ContentHeader
        //         h="175px"
        //         borderRadius="1.2rem 1.2rem 0 0"
        //         alignItems="flex-end"
        //         pb="2rem"
        //         paddingInline="1rem"
        //         bgGradient="radial-gradient(109% 109% at 100% -14.6%, #238983 0%, rgba(130, 220, 199, 0) 100%), linear-gradient(140.88deg, rgba(255, 70, 237, 0.4) 6.62%, rgba(245, 94, 230, 0.183132) 53.6%, rgba(236, 115, 224, 0) 93.28%), linear-gradient(0deg, #FFFFFF, #FFFFFF);">
        //         <Title toolTip="Venice Co-Manager is designed to answer your questions based on your releases with Venice and strategies from our experts to grow your career">
        //           ASK VENICE CO-MANAGER
        //         </Title>
        //         {isTrialMode && (
        //           <Flex
        //             bgColor={'white'}
        //             color={'black60'}
        //             border="1px solid"
        //             borderColor={'black60'}
        //             height={'1.25rem'}
        //             padding={'0.5rem'}
        //             fontSize={'0.75rem'}
        //             borderRadius={'1rem'}
        //             align={'center'}
        //             fontWeight={700}
        //             marginBottom="0.25rem"
        //             marginLeft={'0.5rem'}
        //             onClick={() => (window.location.href = `/settings#membership`)}
        //             _hover={{ cursor: 'pointer' }}>
        //             <icons.unlock />
        //             <Text ml={'0.25rem'}>Pro Members Only</Text>
        //           </Flex>
        //         )}
        //       </ContentHeader>
        //       <AskCoManager
        //         m="1rem"
        //         disabled={isTrialMode}
        //         onSend={prompt => {
        //           popChatModal(prompt);
        //         }}
        //       />
        //       <CoManagerModal
        //         isOpen={CoManagerModalOpen}
        //         onClose={() => {
        //           setCoManagerModalOpen(false);
        //         }}
        //         headerText="Venice Co-manager"
        //         noFooter={true}
        //         variant="light"
        //         initialMessages={messages}
        //         modalProps={{
        //           closeOnOverlayClick: false,
        //           size: '4xl',
        //           scrollBehavior: 'inside',
        //         }}
        //         bodyProps={{
        //           padding: '0',
        //         }}
        //       />
        //     </ManageContentBox>
        //   </GridItem>
        //   <GridItem>
        //     <ManageContentBox w="100%" maxWidth="100%" p="0" borderRadius="1.2rem 1.2rem 0.3rem 0.3rem" minHeight="475px">
        //       <ContentHeader
        //         h="175px"
        //         borderRadius="1.2rem 1.2rem 0 0"
        //         alignItems="flex-end"
        //         pb="2rem"
        //         paddingInline="1rem"
        //         bgGradient="linear-gradient(140.88deg, rgba(70, 255, 111, 0.4) 6.62%, rgba(115, 236, 120, 0) 93.28%), radial-gradient(109% 109% at 100% -14.6%, #FF8A00 0%, rgba(164, 130, 220, 0) 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);">
        //         <Title>MARKETING CHECKLIST AND GOALS</Title>
        //         <IconButton
        //           variant="light"
        //           position="relative"
        //           bottom="-0.5rem"
        //           icon={<icons.plus size="1.5rem" />}
        //           onClick={() => {
        //             setCoManagerActionItemModalOpen(true);
        //           }}
        //         />
        //       </ContentHeader>
        //       <CoManagerActionItems w="100%" />
        //       <CoManagerActionItemModal
        //         isOpen={CoManagerActionItemModalOpen}
        //         headerText="Create Action Item"
        //         variant="light"
        //         actionItem={newActionItem}
        //         errors={createActionItemError?.response?.data}
        //         onClearErrors={() => {
        //           resetCreateActionItem();
        //         }}
        //         onUpdate={actionItem => {
        //           setNewActionItem(actionItem);
        //         }}
        //         onClose={() => {
        //           setNewActionItem({});
        //           setCoManagerActionItemModalOpen(false);
        //         }}
        //         onSubmit={() => {
        //           createActionItemMutation(newActionItem, {
        //             onSuccess: () => {
        //               setNewActionItem({});
        //               setCoManagerActionItemModalOpen(false);
        //             },
        //           });
        //         }}
        //       />
        //     </ManageContentBox>
        //   </GridItem>
        // </Grid>
      }
      <Tabs variant="unstyled" mt="3rem" index={tabIndex} onChange={index => setTabIndex(index)}>
        <TabList borderBottom="none">
          <TabWithIcon leftIcon={<img src={coManagerLogo} height="20" width="20" alt="" />} variant="enclosed">
            Co-Manager
          </TabWithIcon>
          {/*
        Hidden for launch

        <TabWithIcon leftIcon={<RiListRadio />} variant="enclosed">
          Goals and Checklist
        </TabWithIcon> */}
          <Spacer />
          <TabButton
            fontSize="1rem"
            leftIcon={<RiBookmarkLine />}
            m="0.25rem"
            variant="smallCream"
            hidden={threadData.total === 0}>
            Saved Conversations
          </TabButton>
          <Button fontSize="1rem" leftIcon={<RiRefreshLine />} m="0.25rem" variant="smallCream" onClick={openThread}>
            New Chat
          </Button>
          <Button
            fontSize="1rem"
            leftIcon={<icons.plus size="1.5rem" />}
            m="0.25rem"
            variant="smallCream"
            hidden={true}
            onClick={() => {
              setCoManagerActionItemModalOpen(true);
            }}>
            New
          </Button>
          {/*
         TODO: Create side-by-side view
         <IconButton icon={<RiLayoutColumnLine />} m="0.25rem" variant="transparent" _hover={{ ml: '0.5rem' }} />
        */}
        </TabList>
        <TabPanels
          height="70vh"
          backgroundColor="white"
          border="1px solid"
          borderColor="cream100"
          borderRadius="0px 8px 8px 8px">
          {/* Chat Interface */}
          <TabPanel>{inlineChatInterface(chatArgs.initialMessages, chatArgs.threadId, guidedPrompts)}</TabPanel>
          {/* Saved Messages */}
          <TabPanel h="100%" p="2rem 2rem 1.25rem 2rem">
            <SavedThreadsTab
              isLoading={isProcessingThreadDataOperation}
              hasMoreData={pageArgs.hasMoreData}
              threadModelCategories={threadModelCategories}
              savedThreadCount={threadData.total}
              onGoBackClick={() => setTabIndex(0)}
              onDeleteThread={thread => deleteThreadMutate(thread.chat_bot_thread_id)}
              onOpenThread={openThread}
              onLoadMoreThreads={loadNextThreadPage}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default ChatView;
