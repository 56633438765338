import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

function DownloadModal({ onYes, onNo, isOpen }) {
  return (
    <Modal isOpen={isOpen} onClose={onNo}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Download File</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>Would you like to download the file?</Box>
        </ModalBody>

        <ModalFooter>
          <Button text="Cancel" onClick={onNo}>
            Cancel
          </Button>
          <Button text="Download" onClick={onYes}>
            Download
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default DownloadModal;
