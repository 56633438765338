import React, { memo, useEffect } from 'react';
import ClipEditor from '../../../features/ClipEditor/clip-editor';
import { useHistory } from 'react-router-dom';
import { Flex, useTheme } from '@chakra-ui/react';

function ClipCreate({ stateManager, hasBeenSubmitted }) {
  const history = useHistory();
  const { audio_source, title, clips, cover_art, display_artist_name } = { ...stateManager.props };
  const theme = useTheme();
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');

  useEffect(() => {
    if (!audio_source) {
      history.push(`/manage/clip/setup?id=${id}`);
    }
  }, [audio_source]);

  const updateClips = newClips => {
    stateManager.updateClips(newClips);
  };

  return (
    <Flex
      flexDirection="column"
      border={`1px solid ${theme.colors.cream100}`}
      borderRadius=".875rem"
      padding="1.5rem"
      marginTop={'1rem'}
      width="100%">
      <ClipEditor
        file={audio_source}
        trackName={title}
        updateClips={updateClips}
        currentClips={clips}
        coverArt={cover_art}
        artistName={display_artist_name}
        hasBeenSubmitted={hasBeenSubmitted}
      />
    </Flex>
  );
}

export default memo(ClipCreate);
