import React, { useEffect, useState } from 'react';
import CoManagerModal, { makeMessage } from 'features/Co-manager/co-manager-modal-inline';
import { useCoManagerThreadAdditivePageQuery } from 'data-client/use-co-manager-thread-data';
import { useUserTierChecks } from 'hooks/authorization-hooks';

export default function CustomStrategyModal({ isOpen, onClose }) {
  const { fetchPage: fetchThreadPage } = useCoManagerThreadAdditivePageQuery();
  const [chatArgs, setChatArgs] = useState({ initialMessages: [], threadId: undefined });

  const sendPrompt = prompt => {
    setChatArgs({
      initialMessages: [makeMessage(prompt, 'user', Date.now())],
      threadId: undefined,
    });
  };

  useEffect(() => {
    if (isOpen) {
      sendPrompt(
        'Using my eligible tracks for spotify discovery mode and upcoming releases, help me create a strategy of what tracks to opt into Spotify discovery mode using best practices.'
      );
    }
  }, [isOpen]);

  const [_connectionStatus, setConnectionStatus] = useState(0);
  const [_suggestions, setSuggestions] = useState([]);
  const { canAccessCoManager } = useUserTierChecks();

  return (
    <CoManagerModal
      threadId={chatArgs.threadId}
      initialMessages={chatArgs.initialMessages}
      userInputStyles={{
        border: '1px solid',
        borderColor: 'cream200',
        width: '100%',
        borderRadius: '2rem',
      }}
      onReloadThread={() => fetchThreadPage(0)}
      setConnectionStatusFn={setConnectionStatus}
      setSuggestions={setSuggestions}
      isTrialMode={!canAccessCoManager}
      isOpen={isOpen}
      onClose={onClose}
      headerText="Venice Co-manager"
      noFooter={true}
      variant="light"
      modalProps={{
        closeOnOverlayClick: false,
        size: '4xl',
        scrollBehavior: 'inside',
        paddingInlineStart: '1rem',
        paddingInlineEnd: '0',
        paddingBlockEnd: '0',
      }}
    />
  );
}
