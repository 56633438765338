import { mapPartyFields } from './map-party-fields';
import { sortArtists } from '../../utils/form';

const handleEmptyContributors = contributors =>
  contributors?.length ? contributors : [{ roles: ['Composer'] }, { roles: ['Lyricist'] }];

const handleVideoDirector = (contributors, resource_type) => {
  if (resource_type?.toLowerCase() === 'video' && contributors?.[0]?.roles?.indexOf('Director') === -1) {
    return [{ roles: ['Director'] }, ...contributors];
  }

  return contributors;
};

const formatTrackData = (item, sequenceIndex) => {
  // add required role fields for track credits

  return {
    id: item.id,
    resourceType: item.resource_type,
    data: {
      ...item,
      duration: item.duration_time,
      artists: mapPartyFields(item.artists ? sortArtists(item.artists) : []),
      sequence: typeof item.sequence !== 'undefined' ? item.sequence : sequenceIndex,
      fulfillment_date: '', // TODO: This should be removed. Use instant_grat_date instead
      assets: item.assets || [],
      resourceType: item.resourceType || item.resource_type,
      _meta: {
        includeExistingISRC: !!item.isrc_code,
        includeExistingAtmosISRC: !!item.atmos_isrc_code,
        includeYTShortsPreview: !!item.preview?.release_date,
      },
    },
  };
};
export { formatTrackData, handleVideoDirector, handleEmptyContributors };
