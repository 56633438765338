import React from 'react';
import { RiLink } from 'react-icons/ri';
import { Flex, Box } from '@chakra-ui/react';

export default function CopyClipboardToast({ children }) {
  return (
    <Flex
      background="black100"
      alignItems="center"
      color="white"
      fontWeight="bold"
      padding="10px 60px"
      borderRadius="6px">
      <RiLink />
      <Box pl="8px">{children}</Box>
    </Flex>
  );
}
