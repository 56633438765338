import some from 'lodash/some';

// check for a user's individual permissions
export function hasPermission({ featureNames, permissions }) {
  if (!permissions) {
    // console.warn('No permissions object supplied to permissions check')
    return false;
  }

  const accessChecks = featureNames?.map(name => {
    // verify the feature name provided is in the provided permissions set
    const feature = permissions[name];
    if (!feature) {
      // console.warn(
      //   `Feature provided '${name}' did not match a feature in the provided permissions object: \n${Object.keys(
      //     permissions
      //   ).join(', ')}`
      // )
      return false;
    }

    // check for an enabled individual permission
    if (feature?.read === true) return true;

    return false;
  });

  return some(accessChecks);
}
