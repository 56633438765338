// WARNING: This component is DEPRECATED and is not recommended for reuse
// To use modals, see the asModal HoC in 'components/modals/asModal'

import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react';
import styled from '@emotion/styled/macro';
import { animated } from 'react-spring';
import { Spring } from 'react-spring/renderprops';
import Icon from '../../components/Icon/icon';

const Shrowd = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
`;

const Container = styled.div`
  background-color: ${props => props.theme.colors.brand.layer1};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  height: ${props => (props.config.height ? props.config.height : '400px')};
  max-height: ${props => (props.config.maxHeight ? props.config.maxHeight : '100%')};
  max-width: ${props => (props.config.maxWidth ? props.config.maxWidth : '100%')};
  position: relative;
  width: ${props => (props.config.width ? props.config.width : '500px')};
  z-index: 510;
  padding: ${props => props.contentPadding || '2rem 4em 3.5rem'};
  overflow-y: ${props => props.config.overflowY || 'scroll'};
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  height: 1rem;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 1rem;
`;

const Header = styled.div`
  position: relative;
`;

const Content = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: ${props => `calc(100% - ${props.headerHeight})`};
  padding: ${props => props.contentPadding || '2rem 4em 3.5rem'};

  form {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }

  > div {
    height: 100%;
  }
`;

const TopLink = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  a {
    color: ${props => props.theme.colors.brand.textWhite};
  }
`;

export const ModalHeaderWrapper = styled.div`
  padding: ${props => props.headerPadding || '3.5rem 4rem 0'};

  > span {
    display: block;
    margin-top: 0.25rem;
    font-size: ${props => props.theme.fonts.sizes.reg};
  }
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

const Modal = ({
  isShowing,
  children,
  header,
  config,
  showClose,
  onClose,
  onLoaded,
  onDoneAnimating,
  persistent,
  topLink,
}) => {
  const [refHeader, setRefHeader] = useState(null);
  const [headerHeight, setHeaderHeight] = useState('0px');
  const [doHideSpringElement, setDoHideSpringElement] = useState(true);

  const refContainer = useRef(null);

  // DEBT:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRefHeader = useCallback(node => {
    if (node && !node.isEqualNode(refHeader)) {
      setRefHeader(node);
    }
  });

  useLayoutEffect(() => {
    if (isShowing && refHeader) {
      const rect = refHeader.getBoundingClientRect();

      setHeaderHeight(`${rect.height}px`);
    }
  }, [isShowing, refHeader]);

  useEffect(() => {
    document.addEventListener('keyup', handleDocumentKeyUp);

    return () => document.removeEventListener('keyup', handleDocumentKeyUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Spring elements mount and unmount

  useEffect(() => {
    if (isShowing) {
      setDoHideSpringElement(false);
    }
  }, [isShowing]);

  const handleSpringRest = () => {
    if (!isShowing) setDoHideSpringElement(true);
    onLoaded();
  };

  // Event Handlers

  const handleDocumentKeyUp = e => {
    if (isShowing && e.key === 'Escape') onClose();
  };

  const handleClick = e => {
    if (!persistent && e.target === refContainer.current) {
      onClose();
    }
  };

  return (
    <Spring
      from={{ opacity: 0 }}
      to={isShowing ? { opacity: 1 } : { opacity: 0 }}
      onRest={() => handleSpringRest()}
      onDestroyed={() => onDoneAnimating()}>
      {springProps =>
        (isShowing || !doHideSpringElement) && (
          <Shrowd style={springProps} onClick={e => handleClick(e)} ref={refContainer}>
            <Container config={config.style} contentPadding={config.contentPadding}>
              <Header headerPadding={config.headerPadding} ref={onRefHeader}>
                {header}
              </Header>
              {showClose && !persistent && (
                <CloseButton onClick={() => onClose()}>
                  <Icon type="exit" />
                </CloseButton>
              )}
              {topLink && <TopLink>{topLink}</TopLink>}
              <Content headerHeight={headerHeight} contentPadding={config.contentPadding} config={config.style}>
                {children}
              </Content>
            </Container>
          </Shrowd>
        )
      }
    </Spring>
  );
};

Modal.defaultProps = {
  config: { style: {} },
  showClose: true,
  onClose: () => {},
  onLoaded: () => {},
  onDoneAnimating: () => {},
  topLink: null,
  persistent: false,
};

export default Modal;
