// drag drop file component
import React, { useState, useRef } from 'react';

import { Input, FormControl, FormLabel, FormErrorMessage, Box, Flex, IconButton, Text } from '@chakra-ui/react';

import { RiUploadLine } from 'react-icons/ri';

const DEFAULT_UPLOAD_TEXT = 'Drop files here or click to browse';

function DefaultFileHandlerComponent({ files, uploadText = DEFAULT_UPLOAD_TEXT }) {
  //files is a FileList type and does not have array functions. Thus the Array.from
  return (
    <Box width={'100%'} height={'100%'}>
      {Array.from(files).map(file => (
        <Box key={file?.name || 'no-file-selected-key-fu-react'} textAlign={'center'}>
          {uploadText}
        </Box>
      ))}
    </Box>
  );
}

/**
 *
 *
 * @param {*} {
 *   onChange,
 *   errorMessage,
 *   uploadText = DEFAULT_UPLOAD_TEXT,
 *   FileHandlerComponent = DefaultFileHandlerComponent,
 *   allowedExts,
 *   currentFile,
 *   name,
 *   maxNumberOfFiles = 1
 * }
 * @return {*}
 */
function DragAndDropUpload({
  onChange,
  errorMessage,
  uploadText = DEFAULT_UPLOAD_TEXT,
  FileHandlerComponent = DefaultFileHandlerComponent,
  allowedExts,
  currentFile,
  currentFiles,
  name,
  maxNumberOfFiles = 1,
}) {
  const [dragActive, setDragActive] = useState(false);
  const [files, setFiles] = useState(currentFiles || [currentFile]);
  const inputRef = useRef(null);

  // handle drag events
  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    e.persist();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  }

  // triggers when file is dropped
  async function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    e.persist();

    setDragActive(false);
    const files = e.dataTransfer?.files; //required because the event will clear itself out.
    if (files) {
      const status = await onChange(files);
      if (status === 'invalid') return setFiles(null);
      setFiles(files);
    }
  }

  // triggers when file is selected with click
  async function handleChange(e) {
    e.preventDefault();
    const files = e.target?.files;
    if (files) {
      const status = await onChange(files);
      if (status === 'invalid') return setFiles(null);
      setFiles(files);
    }
  }

  function onClick() {
    inputRef.current.click();
  }

  const [showHoverTooltip, setShowHoverTooltip] = useState(false);

  const uploadedAudioFileExt = files?.[0]?.name?.substr(files?.[0]?.name?.split('.'));
  const isAudioFile = allowedExts.some(ext => uploadedAudioFileExt?.includes(ext));
  const defaultUploadAppearance = !isAudioFile && files === null;

  return (
    <FormControl
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      isInvalid={!!errorMessage}
      id={`form-${name}-upload`}
      onDragEnter={handleDrag}
      onSubmit={e => e.preventDefault()}
      border={`2px dashed`}
      borderRadius={'0.75rem'}
      width="100%"
      height="100%">
      <Input
        display={'none'}
        ref={inputRef}
        type={'file'}
        id={`input-${name}-upload`}
        onChange={handleChange}
        accept={allowedExts ? allowedExts.map(ext => `.${ext}`).join(',') : '*'}
        multiple={maxNumberOfFiles > 1}
      />

      {dragActive ? (
        <Box
          height={'calc(100% + 4px)'}
          width={'calc(100% + 4px)'}
          border={'2px solid'}
          borderRadius={'0.75rem'}
          position={'absolute'}
          top={'-2px'}
          left={'-2px'}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      ) : (
        <>
          <FormLabel
            id={`label-${name}-upload`}
            htmlFor={`input-${name}-upload`}
            textAlign={'center'}
            verticalAlign={'middle'}
            width={'100%'}
            height={'100%'}>
            <Flex
              height={'100%'}
              flexDir={'column'}
              justifyContent={'center'}
              onMouseOver={() => setShowHoverTooltip(true)}
              onMouseOut={() => setShowHoverTooltip(false)}>
              {files?.[0] && !isAudioFile ? (
                <Flex direction="column" align={'center'}>
                  <FileHandlerComponent files={files} />
                  <Text
                    display={showHoverTooltip ? 'block' : 'none'}
                    margin={'0 auto'}
                    fontSize="0.75rem"
                    position="absolute"
                    marginTop="2.5rem">
                    {uploadText}
                  </Text>
                </Flex>
              ) : (
                <Flex flexDirection={'column'} width="100%">
                  <IconButton
                    icon={<RiUploadLine size={!isAudioFile ? '3rem' : '2rem'} />}
                    variant={'transparent'}
                    onClick={onClick}
                  />

                  <Text
                    marginTop={defaultUploadAppearance ? '1rem' : '.5rem'}
                    marginX={defaultUploadAppearance ? '4rem' : '2rem'}
                    fontSize={defaultUploadAppearance ? '1rem' : '.875rem'}>
                    {uploadText}
                  </Text>
                </Flex>
              )}
            </Flex>
          </FormLabel>
          {errorMessage ? <FormErrorMessage>{errorMessage}</FormErrorMessage> : ''}
        </>
      )}
    </FormControl>
  );
}

export default DragAndDropUpload;
