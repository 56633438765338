import React from 'react';
import {
  RiAccountCircleLine,
  RiAddCircleLine,
  RiAddLine,
  RiAppleFill,
  RiArrowDownLine,
  RiArrowDownSLine,
  RiArrowLeftLine,
  RiArrowLeftSLine,
  RiArrowRightLine,
  RiArrowRightSLine,
  RiArrowRightUpLine,
  RiArrowUpLine,
  RiArrowUpSLine,
  RiCalendarFill,
  RiCalendarLine,
  RiCheckFill,
  RiCheckDoubleFill,
  RiCheckboxBlankLine,
  RiCheckboxCircleLine,
  RiCheckboxLine,
  RiCloseCircleLine,
  RiCloseLine,
  RiCustomerService2Fill,
  RiDeleteBin7Line,
  RiDownloadFill,
  RiErrorWarningLine,
  RiFileCopyLine,
  RiFlashlightFill,
  RiGooglePlayFill,
  RiImageLine,
  RiInformationFill,
  RiInformationLine,
  RiInstagramFill,
  RiLoader3Line,
  RiLightbulbFlashLine,
  RiMailFill,
  RiMailLine,
  RiMusic2Fill,
  RiOpenArmFill,
  RiPauseCircleLine,
  RiPencilFill,
  RiPlayCircleLine,
  RiPlayListFill,
  RiRepeatLine,
  RiScissorsLine,
  RiSearchLine,
  RiSendPlane2Line,
  RiSettings5Line,
  RiSortAsc,
  RiSortDesc,
  RiSoundcloudFill,
  RiSparkling2Line,
  RiSpotifyFill,
  RiStarFill,
  RiTwitterFill,
  RiLockUnlockFill,
  RiLockLine,
  RiUploadFill,
  RiUser3Line,
  RiVideoLine,
  RiVidiconFill,
} from 'react-icons/ri';
import coManagerLogo from '../assets/co-manager-logo.svg';

export const strokeNotFill = ['settings', 'soundSource', 'soundWaves', 'image'];

const icons = {
  account: RiAccountCircleLine,
  alert: RiErrorWarningLine,
  agent: RiCustomerService2Fill,
  apple: RiAppleFill,
  arrowDown: RiArrowDownLine,
  arrowLeft: RiArrowLeftLine,
  arrowRight: RiArrowRightLine,
  arrowUp: RiArrowUpLine,
  audioPause: RiPauseCircleLine,
  audioPlay: RiPlayCircleLine,
  blackSendPlane: RiSendPlane2Line,
  calendar: RiCalendarLine,
  calendarFill: RiCalendarFill,
  checkMark: RiCheckFill,
  checkboxChecked: RiCheckboxLine,
  checkboxEmpty: RiCheckboxBlankLine,
  circleCheck: RiCheckboxCircleLine,
  circleSlash: RiCloseCircleLine,
  chevronDown: RiArrowDownSLine,
  chevronLeft: RiArrowLeftSLine,
  chevronRight: RiArrowRightSLine,
  chevronUp: RiArrowUpSLine,
  clip: RiScissorsLine,
  comanager: props => <img src={coManagerLogo} alt="" {...props} />,
  copy: RiFileCopyLine,
  doubleCheckMark: RiCheckDoubleFill,
  download: RiDownloadFill,
  edit: RiPencilFill,
  email: RiMailLine,
  exit: RiCloseLine,
  external: RiArrowRightUpLine,
  googlePlay: RiGooglePlayFill,
  image: RiImageLine,
  info: RiInformationLine,
  infoFill: RiInformationFill,
  instagram: RiInstagramFill,
  instantGrat: RiFlashlightFill,
  lightBulb: RiLightbulbFlashLine,
  loader: RiLoader3Line,
  lock: RiLockLine,
  magnifyingGlass: RiSearchLine,
  mail: RiMailFill,
  musicNote: RiMusic2Fill,
  openArms: RiOpenArmFill,
  playListAdd: RiPlayListFill,
  plus: RiAddLine,
  plusCircled: RiAddCircleLine,
  replace: RiRepeatLine,
  robot: RiSparkling2Line,
  settings: RiSettings5Line,
  sortAsc: RiSortAsc,
  sortDesc: RiSortDesc,
  soundcloud: RiSoundcloudFill,
  spotify: RiSpotifyFill,
  spotifyWhite: RiSpotifyFill,
  star: RiStarFill,
  trash: RiDeleteBin7Line,
  twitter: RiTwitterFill,
  unlock: RiLockUnlockFill,
  upload: RiUploadFill,
  user: RiUser3Line,
  videoFilled: RiVidiconFill,
  verify: RiCheckboxCircleLine,
  videoPause: RiPauseCircleLine,
  videoPlay: RiVideoLine,
  xMark: RiCloseCircleLine,
};
export default icons;
