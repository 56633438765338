import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Heading, Text, useTheme } from '@chakra-ui/react';
import { FiArrowLeft as ArrowLeft } from 'react-icons/fi';
import NextActionBox from 'components/next-action-box/next-action-box';

function ManageNewClipTypeSelector() {
  const history = useHistory();
  const theme = useTheme();

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box width="100%" maxWidth={'37.5rem'}>
        <Box pt="2rem" pb="1rem">
          <Button variant="transparent" leftIcon={<ArrowLeft />} onClick={() => history.goBack()}>
            Back to Release Type
          </Button>
        </Box>
        <Heading as="h4" color={theme.colors.black80} fontWeight="bold" fontSize="1.15rem">
          Select release type
        </Heading>
        <Text>Are you creating clips of a previous release or a new song?</Text>
        <NextActionBox
          title={'New Release'}
          text={'Song has not been released via Venice'}
          onClick={() => history.push('/manage/clip/setup')}
        />

        <NextActionBox
          title={'Released track'}
          text={'Select from a track previously released through Venice'}
          onClick={() => history.push('/manage/clip/new-from-released')}
        />
      </Box>
    </Box>
  );
}

export default ManageNewClipTypeSelector;
