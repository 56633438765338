import React, { useState, useEffect, useContext } from 'react';
import * as API from './API/API';
import { useGlobalData } from './global-data';
import { useUploader } from './uploader';
import { API_STATUS_KEYS, EMPTY_RELEASE_TITLE } from '../constants/constants';
import { buildStatus, noZoneDate, chopDate, withZoneTime, withZoneDate, isTrackSequenceValid } from './helpers';
import { formatTrackData } from '../features/DraggableTrack/format-track-data';
import { mapPartyFields } from '../features/DraggableTrack/map-party-fields';

export const ReleaseFormContext = React.createContext();
export const useReleaseForm = () => useContext(ReleaseFormContext);
export const ReleaseFormProvider = ({ children }) => {
  const { release, view, setRelease, setReleaseId, patchReleaseMetadata } = useGlobalData();

  const { uploadFile } = useUploader();

  const Release = API.release();
  const ReleaseSet = API.releaseSet();

  const Track = API.track();

  const [removeTrackStatus, setRemoveTrackStatus] = useState(buildStatus(null));
  const [createTrackStatus, setCreateTrackStatus] = useState(buildStatus(null));
  const [saveReleaseStatus, setSaveReleaseStatus] = useState(buildStatus(null));
  const [deleteReleaseStatus, setDeleteReleaseStatus] = useState(buildStatus(null));
  const [saveTracksStatus, setSaveTracksStatus] = useState(buildStatus(null));
  const [releaseFormObject, setReleaseFormObject] = useState(null);
  const [metaConditionals, setMetaConditionals] = useState({ isSaved: true });
  const [selectedSet, setSelectedSet] = useState(null);
  const [selectedSetId, setSelectedSetId] = useState(null);
  const [releaseTracks, setReleaseTracks] = useState([]);
  const [currentEditTrackIndex, setCurrentEditTrackIndex] = useState(null);
  const [trackSequenceStatus, setTrackSequenceStatus] = useState(buildStatus(null));
  const [isEditingRelease, setIsEditingRelease] = useState(true);
  const [createReleaseStatus, setCreateReleaseStatus] = useState(buildStatus(null));
  const [expandedTracks, setExpandedTracks] = useState({});
  const [isSomeTrackExpanded, setIsSomeTrackExpanded] = useState(false);

  useEffect(() => {
    // Do stuff on view change
    setDeleteReleaseStatus(buildStatus(null));
  }, [view]);

  useEffect(() => {
    if (release && release.sets && release.sets.length > 0) {
      if (!selectedSetId || release.sets[0].id !== selectedSetId) {
        setSelectedSetId(release.sets[0].id);
      }
    } else if (!release) {
      setSelectedSet(null);
      setSelectedSetId(null);
      setReleaseTracks([]);
      setCurrentEditTrackIndex(null);
    }

    if (release) {
      resetReleaseFormObject();
    } else {
      setReleaseFormObject(null);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release]);

  useEffect(() => {
    if (release && selectedSetId) {
      const set = release.sets.filter(item => item.id === selectedSetId)[0];
      setSelectedSet(set);
    }
  }, [selectedSetId, release]);

  useEffect(() => {
    if (selectedSet && selectedSet.tracks) {
      setReleaseTracks(selectedSet.tracks.map(t => formatTrackData(t)));
    }
  }, [selectedSet]);

  useEffect(() => {
    setIsSomeTrackExpanded(Object.values(expandedTracks).reduce((a, v) => a || v, false));
  }, [expandedTracks]);

  const createRelease = async (userGroupId, title = EMPTY_RELEASE_TITLE) => {
    setCreateReleaseStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

    const release = await Release.create(title, userGroupId).catch(err => {
      setCreateReleaseStatus(buildStatus('Cannot connect to server'));

      return null;
    });

    if (release?.id) {
      setReleaseId(release.id);
      setCreateReleaseStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    }

    return release;
  };

  const updateMetaConditionals = state => {
    setMetaConditionals(prevState => ({ ...prevState, ...state }));
  };

  const updateReleaseFormData = state => {
    setReleaseFormObject(prevState => ({ ...prevState, ...state }));
  };
  const updateReleaseFormMeta = state => {
    setReleaseFormObject(prevState => ({
      ...prevState,
      _meta: { ...prevState._meta, ...state },
    }));
  };

  const resetReleaseFormObject = () => {
    const release_date = withZoneDate(release.release_date);
    const release_date_time = withZoneTime(release.release_date);
    const original_release_date = chopDate(release.original_release_date);

    const newReleaseFormObject = {
      id: release.id,
      title: release.title,
      sub_title: release.sub_title,
      release_date: release_date,
      asset_release_upc: release.asset_release_upc,
      original_release_date: original_release_date,
      release_date_time: release_date_time,
      display_artist_name: release.display_artist_name,
      artists: mapPartyFields(release.artists || []),
      genre: release.genre,
      lang: release.lang,
      display_label_name: release.display_label_name,
      labels: release.labels || [],
      duration: release.duration,
      release_identifiers: release.release_identifiers,
      catalogNumber: release.catalog_number,
      copyright_year: release.copyright_year,
      dsp_platforms: release.dsp_platforms || [],
      territories: release.territories || [],
      pre_order_release_date: noZoneDate(release.pre_order_release_date),
      price_tier: release.price_tier,
      track_price_tiers: release.sets?.length ? release.sets[0]?.tracks?.map(track => track.price_tier) : [],
      track_instant_grat_dates: release.sets?.length
        ? release.sets[0]?.tracks?.map(track => chopDate(track.instant_grat_date) || null)
        : [],
      tracks: release.sets?.length ? release.sets[0].tracks : [],
      type: release.type,
      _meta: {
        includeOriginalReleaseDate: !!original_release_date && original_release_date !== chopDate(release.release_date),
        includeExistingUPC: !!release.asset_release_upc,
        includeReleaseDateTime:
          !!release_date &&
          !!release_date_time &&
          release.release_date.substr(release.release_date.length - 5, release.release_date.length) !== '00:00',
        includePreOrder: release.pre_order_release_date ? 'yes' : 'no',
        isCompilation: release.type === 'Compilation',
        releaseType: release.type,
        includeInstantGratDates: release.sets?.length
          ? release.sets[0]?.tracks?.map(track => !!track.instant_grat_date)
          : [],
        trackIds: release.sets?.[0]?.tracks?.map(track => track.id) || [],
      },
    };

    updateMetaConditionals({ isSaved: true });
    setReleaseFormObject(newReleaseFormObject);
  };

  const attachTrackToRelease = async trackId => {
    try {
      await Release.addTrack(release.id, selectedSet.id, trackId, release.sets[0].tracks.length + 1);

      const newRelease = await Release.get(release.id);

      setRelease(newRelease);
    } catch (err) {
      setCreateTrackStatus(buildStatus(API_STATUS_KEYS.ERROR));
    }
  };

  const addNewReleaseTrack = async data => {
    try {
      setCreateTrackStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
      const newTrack = await Track.create(data);

      // add to UI asap
      const newReleaseTracks = JSON.parse(JSON.stringify(releaseTracks));
      const formattedTrackData = formatTrackData(newTrack, newReleaseTracks.length + 1);

      const resp = await Release.addTrack(release.id, selectedSet.id, newTrack.id, formattedTrackData.data.sequence);
      if (!resp) {
        new Error('attaching track to release failed');
      }

      return { newTrack };
    } catch (error) {
      setCreateTrackStatus(buildStatus(API_STATUS_KEYS.ERROR));

      return { errorMessage: error?.response?.data?.detail };
    }
  };

  const patchTrack = async (trackId, trackIndex, submitObject) => {
    if (Object.keys(submitObject).length > 0) {
      try {
        const update = await Release.updateTrack(release.id, selectedSet.id, trackId, submitObject);
        updateTrack(update, trackIndex);
        setSaveTracksStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
        return update;
      } catch (e) {
        setSaveTracksStatus(buildStatus(API_STATUS_KEYS.ERROR));
        return false;
      }
    } else {
      setSaveTracksStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
      return false;
    }
  };

  const patchRelease = async (releaseId, submitObject) => {
    if (Object.keys(submitObject).length > 0) {
      try {
        setSaveReleaseStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

        const update = await Release.update(releaseId, submitObject).catch(err => {
          console.error(err.message);
        });

        if (update) {
          setSaveReleaseStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
          patchReleaseMetadata(update);
          return update;
        } else {
          setSaveReleaseStatus(buildStatus(API_STATUS_KEYS.ERROR));
          return false;
        }
      } catch (e) {
        setSaveReleaseStatus(buildStatus(e.message));
        return false;
      }
    }
  };

  const deleteRelease = async releaseId => {
    try {
      setDeleteReleaseStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

      const remove = await Release.softDelete(releaseId);

      if (remove) {
        setDeleteReleaseStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
        return remove;
      } else {
        setDeleteReleaseStatus(buildStatus(API_STATUS_KEYS.ERROR));
        return false;
      }
    } catch (e) {
      setDeleteReleaseStatus(buildStatus(e.message));
      return false;
    }
  };

  const removeReleaseTrack = async i => {
    if (!selectedSet) {
      return;
    }
    if (!releaseTracks[0]) {
      return;
    }
    try {
      setRemoveTrackStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
      await Release.removeTrack(release.id, selectedSet.id, releaseTracks[i].id);
      let newReleaseTracks = JSON.parse(JSON.stringify(releaseTracks));
      newReleaseTracks.splice(i, 1);
      setReleaseTracks(newReleaseTracks);
      await putTracksInSequence(newReleaseTracks);

      const newRelease = await Release.get(release.id);
      setRelease(newRelease);

      setRemoveTrackStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    } catch (err) {
      setRemoveTrackStatus(buildStatus(API_STATUS_KEYS.ERROR));
    }
  };

  const updateTrack = (item, i) => {
    let newTracks = JSON.parse(JSON.stringify(releaseTracks));
    newTracks[i] = formatTrackData(item, i + 1);
    setReleaseTracks(newTracks);
  };

  const updateTrackField = (i, data) => {
    let newTracks = JSON.parse(JSON.stringify(releaseTracks));
    newTracks[i] = { ...newTracks[i], ...data };
    setReleaseTracks(newTracks);
  };

  const putTracksInSequence = async tracks => {
    try {
      setTrackSequenceStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

      if (tracks.length && !isTrackSequenceValid(tracks)) {
        const newOrder = tracks.map((track, i) => ({
          id: track.data.id,
          sequence: i + 1,
        }));
        await ReleaseSet.updateTracks(release.id, selectedSet.id, newOrder);
        const newRelease = await Release.get(release.id);
        setRelease(newRelease);
      }

      setTrackSequenceStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    } catch (err) {
      setTrackSequenceStatus(buildStatus(err.message));
      return null;
    }
  };

  const updateTrackSequence = async newTracks => {
    try {
      setTrackSequenceStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

      const oldTrackIds = releaseTracks.map(item => item.id);
      const newTrackIds = newTracks.map(item => item.id);
      const objsAreEqual = JSON.stringify(oldTrackIds) === JSON.stringify(newTrackIds);

      if (!objsAreEqual) {
        let needToUpdate = [];

        oldTrackIds.forEach((id, i) => {
          let index = newTrackIds.indexOf(id);
          if (i !== index) {
            needToUpdate.push({ id: id, sequence: index + 1 });
          }
        });

        // update ui asap
        setReleaseTracks(newTracks);

        await ReleaseSet.updateTracks(release.id, selectedSet.id, needToUpdate);
        const newRelease = await Release.get(release.id);

        setRelease(newRelease);
      }

      setTrackSequenceStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    } catch (err) {
      setTrackSequenceStatus(buildStatus(err.message));
    }
  };

  return (
    <ReleaseFormContext.Provider
      value={{
        release,
        createRelease,
        createReleaseStatus,
        removeTrackStatus,
        selectedSet,
        releaseTracks,
        deleteRelease,
        addNewReleaseTrack,
        attachTrackToRelease,
        removeReleaseTrack,
        currentEditTrackIndex,
        updateTrackSequence,
        trackSequenceStatus,
        createTrackStatus,
        updateTrack,
        patchTrack,
        patchRelease,
        updateTrackField,
        isEditingRelease,
        releaseFormObject,
        metaConditionals,
        updateMetaConditionals,
        updateReleaseFormData,
        updateReleaseFormMeta,
        deleteReleaseStatus,
        saveReleaseStatus,
        saveTracksStatus,
        setSaveTracksStatus,
        isSomeTrackExpanded,
        setExpandedTracks,
      }}>
      {children}
    </ReleaseFormContext.Provider>
  );
};
