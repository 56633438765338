import React, { useState } from 'react';
import { Marker, WaveForm, WaveSurfer } from 'wavesurfer-react';
import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import MarkersPlugin from 'wavesurfer.js/src/plugin/markers';
import useWaveFormProps from './use-wave-form-props';
import { DateTime } from 'luxon';

// import pinkMarker from './pinkMarker.svg';

const MarkerWaveForm = ({ file, markers = [], onMarkerClickHandler }) => {
  const theme = useTheme();
  const [totalTime, setTotalTime] = useState();
  const waveFormProps = useWaveFormProps({ progressColor: [theme.colors.transparent], interact: false });

  const waveFormBoxStyles = {
    w: '100%',
    bg: theme.colors.black10,
    padding: '1.5rem 2rem .75rem 2rem',
    borderRadius: '.75rem',
  };

  const waveFormId = `marker-wave-from-` + file.name.replace(/[\W_]+/g, '-');

  const createMarkers = () => {
    return markers.map((marker, index) => (
      <Marker
        key={`${waveFormId}-marker-${index}`}
        {...marker}
        onClick={() => {
          onMarkerClickHandler(index);
        }}
      />
    ));
  };

  const onMount = wavesurfer => {
    if (wavesurfer.markers) {
      wavesurfer.clearMarkers();
    }
    if (file) {
      wavesurfer.loadBlob(file);
    }
    wavesurfer.on('ready', () => {
      const duration = wavesurfer.getDuration();
      setTotalTime(duration);
    });
  };

  const plugins = [
    {
      plugin: MarkersPlugin,
      options: {
        markers: [
          {
            draggable: false,
          },
        ],
      },
    },
  ];

  const trackDuration = DateTime.fromMillis(totalTime * 1000).toFormat('mm:ss');

  return (
    <Box {...waveFormBoxStyles}>
      <WaveSurfer plugins={plugins} onMount={onMount} container={`#${waveFormId}`} {...waveFormProps}>
        <div id={waveFormId}>
          <WaveForm id={waveFormId + `-marker-wave-form`} cursorColor="transparent">
            {createMarkers()}
          </WaveForm>
        </div>
      </WaveSurfer>
      <div style={{ marginTop: '1.5rem' }}>
        <Flex justifyContent="space-between" color={theme.colors.black60} fontSize=".75rem">
          <Text>0:00</Text>
          <Text>{trackDuration}</Text>
        </Flex>
      </div>
    </Box>
  );
};

export default MarkerWaveForm;
