import { FormControl } from '@chakra-ui/react';
import React from 'react';
import SearchSelect from '../../../features/SearchSelect/search-select';
import { Col } from 'react-styled-flexboxgrid';

export function ContributorColumn({ disabled, onChange, options, value, onRowActionButtonClick }) {
  return (
    <Col disabled={disabled} xs={4}>
      {!disabled && (
        <FormControl>
          <SearchSelect
            placeholder="Select Contributor"
            isDisabled={disabled}
            onChange={onChange}
            options={options}
            value={value}
            rowActionButtonText="Add Contributor"
            onRowActionButtonClick={onRowActionButtonClick}
          />
        </FormControl>
      )}
      {disabled && value.label}
    </Col>
  );
}
