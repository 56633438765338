import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/private-route';
import MarketingLinksDashboard from './marketing-links-dashboard';
import useUser from 'data-client/use-current-user.jsx';
import CreateMarketingLink from './create-marketing-link/create-marketing-link';

export default function MarketingLinksRouter({ isPrivate, userGroupId }) {
  //USE user hook
  const [user] = useUser();
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  //wait until user is loaded. Could do a loading screen ?
  if (!user?.currentGroup?.id) return <></>;

  return (
    <>
      <RouteComponent
        path="/marketing-links"
        exact
        render={() => <Redirect to={`/marketing-links/${user?.currentGroup?.id}`} />}
      />
      <RouteComponent
        path="/marketing-links/:groupId"
        exact
        render={props => <MarketingLinksDashboard {...props} userGroupId={userGroupId} />}
      />
      <RouteComponent
        path="/marketing-links/:groupId/create"
        exact
        render={props => <CreateMarketingLink {...props} />}
      />
    </>
  );
}
