import React, { useMemo, useEffect, useRef, useCallback, useState } from 'react';
import { Flex, Center, Button, Spinner, Text } from '@chakra-ui/react';
import UserMessage from 'components/Co-ManagerMessages/user-message';
import AgentMessage from 'components/Co-ManagerMessages/agent-message';
import LoaderMessage from 'components/Co-ManagerMessages/loader-message';
import AiAgentMessage from 'components/Co-ManagerMessages/ai-agent-message';
import EmailCollectionFormMessage from 'components/Co-ManagerMessages/email-collection-form-message';
import StarRating from 'components/StarRating/StarRating';
// import { partnerEmail } from '../../data-client/api-server';

/**
 * @typedef {object} MessagesOptions
 * @property {import('../../hooks/use-messages').Message[]} messages
 */

/**
 * @param {import('../../hooks/use-messages').Message} message
 */

/**
 * @param {MessagesOptions} opts
 * @return
 */
const Messages = ({
  messages,
  prefixMessages,
  isLoadingMessageHistory,
  hasMoreMessageHistory,
  onLoadMoreMessages,
  onRating,
}) => {
  // const onSubmit = useCallback(
  //   userNameEmail => {
  //     partnerEmail(userNameEmail.name, userNameEmail.email, messages);
  //   },
  //   [messages]
  // );
  const lastScrollTopRef = useRef(undefined);
  const shouldAutoScrollRef = useRef(true);
  const [rating, setRating] = useState(null);

  const renderMessage = message => {
    switch (message.type) {
      case 'user':
        return <UserMessage key={message.id} unfurled_text={message.unfurled_text || message.text}></UserMessage>;
      case 'emailCollection':
        return (
          <EmailCollectionFormMessage
            key={message.id}
            onSubmit={() => {
              /* Temp No-Op */
            }}></EmailCollectionFormMessage>
        );
      case 'ai':
      case 'assistant':
        return (
          <AiAgentMessage
            key={message.id}
            unfurled_text={message.unfurled_text || message.text}
            createdAt={message.createdAt}
            hasTime={message.hasTime}></AiAgentMessage>
        );
      case 'agent':
        return (
          <AgentMessage
            key={message.id}
            unfurled_text={message.unfurled_text || message.text}
            createdAt={message.createdAt}
            hasTime={message.hasTime}></AgentMessage>
        );
      case 'typing':
      case 'loading':
        return (
          <LoaderMessage
            key={message.id}
            unfurled_text={message.unfurled_text || message.text}
            variant="agentMessage"></LoaderMessage>
        );
      default:
        return <AgentMessage key={message.id} unfurled_text={message.unfurled_text || message.text}></AgentMessage>;
    }
  };
  const el = useRef(null);

  const sendRating = useCallback(rating => {
    setRating(rating);
    onRating(rating);
  });

  const onScroll = useCallback(e => {
    const {
      target: { clientHeight, scrollHeight, scrollTop },
    } = e;

    const scrollBottom = scrollTop + clientHeight;
    const shouldAutoscroll = Math.abs(scrollBottom - scrollHeight) < 15;
    const isScrollingUp = lastScrollTopRef.current === undefined ? false : scrollBottom < lastScrollTopRef.current;

    if (isScrollingUp && !shouldAutoscroll) {
      shouldAutoScrollRef.current = false;
    } else if (!isScrollingUp && shouldAutoscroll) {
      shouldAutoScrollRef.current = true;
    }
    lastScrollTopRef.current = scrollBottom;
  }, []);

  // On Render
  useEffect(() => {
    if (shouldAutoScrollRef.current) {
      el.current.parentElement.parentElement.scrollTo({
        top: el.current.parentElement.parentElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  });

  const renderedPrefixMessages = useMemo(() => {
    return prefixMessages.map(m => renderMessage(m));
  }, [prefixMessages]);

  return (
    <div style={{ flexGrow: 1, position: 'relative', width: '100%', height: '100%' }}>
      <div
        style={{ position: 'absolute', display: 'flex', left: 0, right: 0, top: 0, bottom: 0, overflow: 'auto' }}
        onScroll={onScroll}>
        <Flex flexDirection="column" gap="2rem" marginTop="auto" width="100%" p="0.75rem 0.5rem">
          {hasMoreMessageHistory && (
            <Center height="2rem">
              {isLoadingMessageHistory ? (
                <Spinner />
              ) : (
                <Button variant="transparent" color="red" onClick={onLoadMoreMessages}>
                  Load More History
                </Button>
              )}
            </Center>
          )}
          {renderedPrefixMessages}
          {messages.map(m => renderMessage(m))}
          {messages.length > 1 && typeof onRating == 'function' ? (
            <div style={{ marginTop: '-2rem', display: 'flex' }}>
              <Text>Rate your conversation:&nbsp;</Text>
              <StarRating size="1rem" rating={rating} setRating={sendRating} />{' '}
            </div>
          ) : null}
          <div id={'el'} ref={el}></div>
        </Flex>
      </div>
    </div>
  );
};

export default Messages;
