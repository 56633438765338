import React, { useState } from 'react';
import LabeledRadioToggle from '../../components/labeled-radio-toggle/labeled-radio-toggle';

function useLabeledMetaToggle(initialState = {}, onToggle) {
  const [metaState, setMetaState] = useState(initialState);

  const updateMetaState = (key, value) => {
    setMetaState(prevState => ({ ...prevState, [key]: value }));
  };

  const LabeledMetaToggle = ({ label, name, invalidMessage, eventHandlers = {}, disabled }) => {
    const [value, setValue] = useState(metaState[name]);

    return (
      <LabeledRadioToggle
        label={label}
        name={name}
        value={value ? 'yes' : 'no'}
        disabled={disabled}
        onChange={e => {
          const newValue = e.target.value === 'yes';
          setValue(newValue);
          onToggle(name, newValue);
          updateMetaState(name, newValue);
        }}
        {...eventHandlers}
      />
    );
  };
  return [LabeledMetaToggle, metaState];
}

export default useLabeledMetaToggle;
