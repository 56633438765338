import React, { useRef } from 'react';
import Button from '../../components/Button/button';
import STRINGS from '../../views/ManageRelease/Card/Tracks/strings';
import styled from '@emotion/styled/macro';

function UploadTrackButton({
  disabled,
  fileHandler,
  file,
  accept = 'video/mp4,audio/wav,audio/mp3',
  onRemove,
  hasFile,
  uploadedFile,
  uploadedFileUrl,
}) {
  const inputEl = useRef(null);
  const text = hasFile ? STRINGS.REPLACE_FILE : STRINGS.UPLOAD_FILE;

  const TrackNameContainer = styled.span`
    color: ${props => props.theme.colors.brand.textWhite};
    margin: ${props => props.theme.space[2]};
  `;

  const RemoveFileLink = styled.a`
    margin: ${props => props.theme.space[2]};
    cursor: pointer;
  `;

  return (
    <>
      <Button tertiary text={text} icon="upload" disabled={disabled} onClick={() => inputEl.current.click()} />
      <input
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        ref={inputEl}
        value={''} // file data is not cached in input.value
        onChange={e => {
          fileHandler(e);
        }}
      />
      &nbsp;
      {hasFile && file && <TrackNameContainer>{file.name}</TrackNameContainer>}
      {/* This condition is if the user has not uploaded a new file but there is already one uploaded previous.*/}
      {uploadedFile && !file && uploadedFileUrl && (
        <TrackNameContainer>
          <a href={uploadedFileUrl}>{uploadedFile}</a>
        </TrackNameContainer>
      )}
      {hasFile && onRemove && <RemoveFileLink onClick={onRemove}>Remove file</RemoveFileLink>}
    </>
  );
}

export default UploadTrackButton;
