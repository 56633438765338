import React, { useRef, useCallback, useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { ClipLoader } from 'react-spinners';

import DropFile from './drop-file';
import Icon from '../Icon/icon';

import { ImageWrapper } from '../../styles';

import HEAP from '../../constants/HEAP.gen.json';

const Container = styled.div`
  background-color: ${props => props.theme.colors.inputBackground};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  overflow: hidden;
  position: relative;
`;

const PlaceholderContent = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.brand.textWhite};
  display: flex;
  flex-direction: column;
  font-size: ${props => props.theme.fonts.sizes.reg};
  font-weight: ${props => props.theme.fonts.weights.bold};

  span {
    display: block;
    margin-top: 1rem;
  }
`;

const Center = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex: 1;
  justify-content: center;
  position: absolute;
  width: 100%;
`;

const Image = styled(ImageWrapper)`
  border-radius: 0.25rem;
  flex: 9;
  position: relative;
`;

const ReplaceImage = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${props => props.theme.colors.brand.textWhite};
  font-size: ${props => props.theme.fonts.sizes.reg};
  display: inline-flex;
  justify-content: flex-start;
  margin-top: 0.5rem;

  i {
    margin-right: 0.5rem;
  }
`;

const ImageReplaceContainer = styled.div``;

const DropFileWrapper = styled(DropFile)`
  align-items: center;
  border: 0;
  display: flex;
  justify-content: center;
  padding: 0;
`;

const InfoText = styled.div`
  color: ${props => props.theme.colors.brand.layer3};
  font-size: ${props => props.theme.fonts.sizes.sm};
  margin-top: 0.5rem;
`;

const ImageField = React.forwardRef(function ImageFieldComponent({
  accept,
  onChange,
  url,
  status = {},
  uploadStatus = {},
  innerLabel,
  disabled,
  extensionWhitelist = [],
  omnisharePrefix,
  infoText,
  style,
  canReplace = true,
  heapCode,
}) {
  const [refImageContainer, setRefImageContainer] = useState(null);
  const [imageContainerHeight, setImageContainerHeight] = useState(0);
  const refImageInput = useRef();

  useEffect(() => {
    window.addEventListener('resize', () => handleWindowResize());
    handleWindowResize();

    return () => window.removeEventListener('resize', () => handleWindowResize());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refImageContainer]);

  // DEBT:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRefImageContainer = useCallback(node => {
    if (node && !node.isEqualNode(refImageContainer)) {
      setRefImageContainer(node);
      handleWindowResize();
    }
  });

  const emulateInputClick = () => refImageInput.current.click();
  const input = (
    <input
      type="file"
      accept={accept}
      style={{ display: 'none' }}
      ref={refImageInput}
      onChange={e => onChange(e.target.files[0])}
    />
  );

  const handleWindowResize = () => {
    const rect = refImageContainer && refImageContainer.getBoundingClientRect();
    const newHeight = rect ? rect.width : 0;

    if (newHeight > 0) {
      setImageContainerHeight(newHeight);
    }
  };

  const isLoading = status?.inProgress || uploadStatus?.inProgress;
  const isLoadedSuccessfully = status?.complete && !status?.error;
  const noImage = !isLoading && !isLoadedSuccessfully;
  return (
    <>
      <Container
        ref={onRefImageContainer}
        style={{ height: imageContainerHeight, ...style }}
        data-heap={`${heapCode}-${HEAP.PUBLIC.COMMON.TYPE.UPLOAD}`}>
        {isLoading && (
          <Center>
            <ClipLoader />
          </Center>
        )}
        {noImage && !disabled && (
          <React.Fragment>
            <DropFileWrapper onChange={files => onChange(files[0])} extensionWhitelist={extensionWhitelist}>
              <PlaceholderContent>
                <Icon type="upload" />
                <span>{innerLabel}</span>
              </PlaceholderContent>
            </DropFileWrapper>
            {input}
          </React.Fragment>
        )}
        {!isLoading && isLoadedSuccessfully && url && <Image src={url} />}
      </Container>
      {isLoadedSuccessfully && (
        <ImageReplaceContainer omnishareas={`${omnisharePrefix}.ReplaceImageButton`}>
          {canReplace && (
            <ReplaceImage onClick={emulateInputClick} data-heap={`${heapCode}-${HEAP.PUBLIC.COMMON.TYPE.REPLACE}`}>
              <Icon type="edit" /> Replace Image
            </ReplaceImage>
          )}
          {input}
        </ImageReplaceContainer>
      )}
      {noImage && !disabled && <>{infoText && <InfoText>{infoText}</InfoText>}</>}
    </>
  );
});

ImageField.defaultProps = {
  style: {},
  omnishare: null,
  innerLabel: 'Upload Image',
  infoText: 'Please upload a .png or jpg file with a resolution of at least 500px x 500px',
  heapCode: 'image',
};

export default ImageField;
