import * as React from 'react';
import { Box, Stack, Text, useTheme } from '@chakra-ui/react';
import { Axis, buildChartTheme, Grid, AreaSeries, XYChart as VisXYChart, Tooltip } from '@visx/xychart';
import { curveMonotoneX } from '@visx/curve';
import { Text as VisxText } from '@visx/text';
import { LinearGradient } from '@visx/gradient';
import { timeFormat } from 'd3-time-format';
import numeral from 'numeral';
import { DateTime } from 'luxon';

import DspInfoGroup from './dsp-info-group';

const accessors = {
  xAccessor: d => {
    // this regex strips out the hyphens for dashes which will prevent us from getting an off by one error
    // thanks JavaScript, see: https://stackoverflow.com/a/31732581
    return new Date(d.date.replace(/-/g, '/').replace(/T.+/, ''));
  },
  yAccessor: dsp => {
    if (dsp === 'Spotify') return d => d.Spotify;
    if (dsp === 'Amazon') return d => d.Amazon;
    if (dsp === 'Apple') return d => d.Apple;
    if (dsp === 'Pandora') return d => d.Pandora;
  },
  curve: curveMonotoneX,
};

const formatWithWeekday = timeFormat('%a %m/%d');
const formatWithoutWeekday = timeFormat('%m/%d');
const formatDate = (date, width) => {
  const formattedDate = width > 500 ? formatWithWeekday(date) : formatWithoutWeekday(date);
  return formattedDate;
};
const formatNumber = number => numeral(number).format('0a');

const XYChart = ({ data, selectedDsps = [], width, height }) => {
  const chakraTheme = useTheme();

  const theme = buildChartTheme({
    xAxisLineStyles: {
      stroke: chakraTheme.colors.black['100'],
      strokeWidth: 1,
    },
    yAxisLineStyles: {
      stroke: chakraTheme.colors.black['100'],
      strokeWidth: 1,
    },
    xTickLineStyles: {
      stroke: chakraTheme.colors.black['20'],
      strokeWidth: 1,
    },
    yTickLineStyles: {
      stroke: chakraTheme.colors.black['20'],
      strokeWidth: 1,
    },
    backgroundColor: chakraTheme.colors.black['700'],
    // can't figure out a way to map these names to keys, so had to put the
    // LineSeries components in order and match them with this order
    colors: [
      chakraTheme.colors.dsp.Spotify,
      chakraTheme.colors.dsp.Apple,
      chakraTheme.colors.dsp.Amazon,
      chakraTheme.colors.dsp.Pandora,
    ],
    gridColor: '#565263',
    gridColorDark: '#222028',
    svgLabelBig: {
      fill: chakraTheme.colors.black['100'],
      fontFamily: chakraTheme.fonts.body,
      style: { userSelect: 'none' },
    },
    svgLabelSmall: {
      fill: chakraTheme.colors.black['100'],
      fontFamily: chakraTheme.fonts.body,
      style: { userSelect: 'none' },
    },
    tickLength: 4,
  });

  let numTicks = undefined;
  if (width < 500) numTicks = 5;
  if (width < 350) numTicks = 4;

  return (
    <VisXYChart
      width={width}
      height={height}
      margin={{ top: 16, right: 16, left: 48, bottom: 48 }}
      theme={theme}
      xScale={{ type: 'band' }}
      yScale={{ type: 'linear' }}>
      <Grid strokeDasharray="1,3" />
      <Axis orientation="left" tickFormat={number => formatNumber(number)} />
      <Axis orientation="bottom" tickFormat={date => formatDate(date, width)} numTicks={numTicks} />
      <LinearGradient
        from={chakraTheme.colors.dsp.Spotify}
        fromOpacity="0.5"
        toOpacity="0"
        to={chakraTheme.colors.white}
        id="spotify-gradient"
      />
      <AreaSeries
        dataKey="Spotify"
        data={selectedDsps.includes('Spotify') ? data : []}
        xAccessor={accessors.xAccessor}
        yAccessor={accessors.yAccessor('Spotify')}
        curve={curveMonotoneX}
        fill="url('#spotify-gradient')"
      />
      <LinearGradient
        from={chakraTheme.colors.dsp.Apple}
        fromOpacity="0.5"
        toOpacity="0"
        id="apple-gradient"
        to={chakraTheme.colors.white}
      />
      <AreaSeries
        dataKey="Apple"
        data={selectedDsps.includes('Apple') ? data : []}
        xAccessor={accessors.xAccessor}
        yAccessor={accessors.yAccessor('Apple')}
        curve={curveMonotoneX}
        fill="url('#apple-gradient')"
      />
      <LinearGradient
        from={chakraTheme.colors.dsp.Amazon}
        fromOpacity="0.5"
        toOpacity="0"
        id="amazon-gradient"
        to={chakraTheme.colors.white}
      />
      <AreaSeries
        dataKey="Amazon"
        data={selectedDsps.includes('Amazon') ? data : []}
        xAccessor={accessors.xAccessor}
        yAccessor={accessors.yAccessor('Amazon')}
        curve={curveMonotoneX}
        fill="url('#amazon-gradient')"
      />
      <LinearGradient
        from={chakraTheme.colors.dsp.Pandora}
        fromOpacity="0.5"
        toOpacity="0"
        id="pandora-gradient"
        to={chakraTheme.colors.white}
      />
      <AreaSeries
        dataKey="Pandora"
        data={selectedDsps.includes('Pandora') ? data : []}
        xAccessor={accessors.xAccessor}
        yAccessor={accessors.yAccessor('Pandora')}
        curve={curveMonotoneX}
        fill="url('#pandora-gradient')"
      />
      {selectedDsps.length === 0 && (
        <VisxText
          x={width / 2}
          y={height / 2}
          width={width}
          verticalAnchor="start"
          textAnchor="middle"
          fontSize="1em"
          fontWeight="bold"
          fill={chakraTheme.colors.black['100']}>
          No DSPs selected to show
        </VisxText>
      )}
      {data.length === 0 && (
        <VisxText
          x={width / 2}
          y={height / 2 - 36}
          width={width}
          verticalAnchor="start"
          textAnchor="middle"
          fontSize="1em"
          fontWeight="bold"
          fill={chakraTheme.colors.black['100']}>
          No data reported
        </VisxText>
      )}
      <Tooltip
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        showSeriesGlyphs
        style={{
          position: 'absolute',
          background: 'transparent',
          color: 'inherit',
          padding: 0,
          pointerEvents: 'none',
        }}
        renderTooltip={({ tooltipData }) => (
          <Box backgroundColor="black.100" borderRadius="lg" p={4} textTransform="uppercase">
            <Text color="black.20" letterSpacing="1px" fontWeight="medium" fontSize="xsm">
              {DateTime.fromJSDate(accessors.xAccessor(tooltipData.nearestDatum.datum)).toFormat('EEEE LL/dd')}
            </Text>
            <Stack marginTop={3} spacing={2}>
              {Object.entries(tooltipData.datumByKey).map(([key, value]) => (
                <DspInfoGroup key={key} dspName={key} data={value.datum[value.key]} color="white" />
              ))}
            </Stack>
          </Box>
        )}
        verticalCrosshairStyle={{
          stroke: chakraTheme.colors.black['100'],
          strokeWidth: 1,
        }}
      />
    </VisXYChart>
  );
};

export default XYChart;
