// Thanks to:
// https://github.com/TheGeekExplorer/GTIN-Validation/blob/master/JS/GTINCheckDigit.js
// https://github.com/hampus-nilsson/gs1-checkdigit/blob/main/checkdigit.js

const getCheckDigit = input => {
  let array = input.split('').reverse();

  let total = 0;
  let i = 1;
  array.forEach(number => {
    number = parseInt(number);
    if (i % 2 === 0) {
      total = total + number;
    } else {
      total = total + number * 3;
    }
    i++;
  });

  return Math.ceil(total / 10) * 10 - total;
};

const checkGTIN = gtin => {
  if (!gtin) {
    return false;
  }
  const gtinCheckDigit = parseInt(gtin.slice(-1), 10);
  const gtinWithoutCheckDigit = gtin.substring(0, gtin.length - 1);
  const tmpCheckDigit = getCheckDigit(gtinWithoutCheckDigit);

  if (gtinCheckDigit === tmpCheckDigit) {
    return true;
  }
};

const validateUPC = upc =>
  [
    {
      valid: [8, 12, 13, 14].includes(upc?.length),
      message: 'Length must be 8, 12, 13, or 14 characters',
    },
    {
      valid: checkGTIN(upc),
      message: 'Check digit must be valid',
    },
    {
      valid: upc?.match(/^[0-9]+$/),
      message: 'UPC must be numeric',
    },
  ]
    .filter(r => !r.valid)
    .map(r => r.message);

module.exports = {
  validateUPC,
  getCheckDigit,
};
