import React, { memo, useState, useRef } from 'react';
import { Button, Checkbox, Text, useTheme } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import useFormState, { FormStates } from '../../state-management/use-form-state';
import { territoryNames } from './territory-names';
import asModal from '../Modal/as-modal';

const TerritoryItem = ({ territoryName, selectTerritory, isSelected }) => {
  return (
    <div>
      <Checkbox isChecked={isSelected} onChange={selectTerritory}>
        {territoryName}
      </Checkbox>
    </div>
  );
};

const TerritoriesSelector = ({ defaultSelections, onSelectTerritories }) => {
  const hasDefaultSelections = Array.isArray(defaultSelections);

  const [formState, updateState] = useFormState(
    {
      territories: hasDefaultSelections ? defaultSelections : [],
      allSelected: !hasDefaultSelections ? false : defaultSelections?.length === territoryNames.length,
    },
    {
      territories: {
        [FormStates.DIRTY]: (value, state) => {
          state.allSelected = value.length === territoryNames.length;
        },
      },
      allSelected: {
        [FormStates.DIRTY]: (value, state) => {
          state.territories = value ? territoryNames : [];
        },
      },
      _after: {
        [FormStates.DIRTY]: (value, state) => {
          onSelectTerritories(state.territories);
        },
      },
    }
  );

  const toggleSelectAll = () => {
    updateState(({ allSelected: currentValue }) => {
      return {
        allSelected: !currentValue,
      };
    });
  };

  const selectTerritory = territory => {
    updateState(({ territories: current }) => {
      const territories = current.includes(territory) ? current.filter(t => t !== territory) : [...current, territory];
      return {
        territories,
      };
    });
  };

  return (
    <>
      <Checkbox isChecked={formState.allSelected} onChange={toggleSelectAll}>
        Select All
      </Checkbox>
      <div style={{ border: `1px solid #808080`, marginTop: '.25rem', marginBottom: '.25rem' }} />
      {territoryNames.map((territoryName, idx) => {
        return (
          <TerritoryItem
            key={territoryName}
            territoryName={territoryName}
            selectTerritory={() => selectTerritory(territoryName)}
            isSelected={formState.territories.includes(territoryName)}
          />
        );
      })}
    </>
  );
};

const MenuContainer = ({ defaultSelections, onSelectTerritories, isValid }) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const TerritoriesModal = asModal(TerritoriesSelector);
  const rightIcon = isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />;
  const selectionsRef = useRef(defaultSelections);

  const toggleOpen = () => {
    setIsOpen(open => !open);
  };

  const onSubmit = () => {
    setIsOpen(false);
    onSelectTerritories(selectionsRef.current);
  };

  return (
    <>
      <Button
        onClick={toggleOpen}
        variant={isValid ? '' : 'invalid'}
        width="20rem"
        justifyContent="space-between"
        fontWeight="normal"
        rightIcon={rightIcon}
        background={'white'}>
        Countries Selected ({defaultSelections?.length || 0})
      </Button>
      {!isValid ? <Text color={theme.colors.red100}>You must distribute to at least one country</Text> : ''}
      <TerritoriesModal
        headerProps={{ fontSize: '2xl' }}
        defaultSelections={defaultSelections}
        onSelectTerritories={selections => (selectionsRef.current = selections)}
        scrollBehavior={'inside'}
        variant={'light'}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        setIsOpen={setIsOpen}
        headerText={'Select Countries'}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default memo(MenuContainer);
