import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Tooltip, Text, useTheme } from '@chakra-ui/react';
import { BsCircle as NoStatusIcon } from 'react-icons/bs';
import { RiCheckboxCircleFill as GoodStatusIcon } from 'react-icons/ri';
import { AiFillWarning as ErrorStatusIcon } from 'react-icons/ai';

//id needs to be a prop because the left-rail is outside of the route that can find the id as a param.
function LeftRailLink({
  active,
  path,
  disabled,
  disabledHoverText,
  linkTitle,
  tainted,
  allValid,
  asset_release_id = null,
}) {
  const theme = useTheme();

  //if disabled remove path, if there is an id add it to the end.
  const to = disabled ? '#' : `${path}${asset_release_id ? `?id=${asset_release_id}` : ''}`;

  const LeftIcon = getLeftIcon(tainted, allValid);

  //rbga(0,0,0,0) makes a transparent border so the links don't jump around
  return (
    <Box
      marginY={'0.25rem'}
      paddingX={'0.25rem'}
      borderRadius={'0.5em'}
      border={`1px solid ${active ? theme.colors.black60 : 'rgba(0,0,0,0)'}`}>
      <Tooltip label={disabled ? disabledHoverText : null}>
        <Button
          pointerEvents={disabled ? 'none' : 'inherit'}
          disabled={disabled}
          leftIcon={<LeftIcon />}
          variant={'transparent'}
          as={Link}
          to={to}
          color={theme.colors.black10}
          fontSize={'1rem'}
          fontWeight={'normal'}
          height={'1.75rem'}>
          <Text isTruncated maxW={160}>
            {linkTitle}
          </Text>
        </Button>
      </Tooltip>
    </Box>
  );
}

const getLeftIcon = (isTainted, allValid) => {
  if (allValid) {
    return () => <GoodStatusIcon color={'green'} />;
  } else if (!isTainted) {
    return () => <NoStatusIcon />;
  } else {
    return () => <ErrorStatusIcon color={'orange'} />;
  }
};

export default LeftRailLink;
