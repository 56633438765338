import React, { useState, useEffect, useRef } from 'react';
import * as API from '../../utils/API/API';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { BarLoader } from 'react-spinners';
import useDebounce from 'utils/debounce';
import { buildStatus } from 'utils/helpers';
import { API_STATUS_KEYS } from 'constants/constants';
import validator from 'validator';

import Icon from '../Icon/icon';
import TextInput from 'features/TextInput/text-input';

const MAX_RESULTS = 5;

const Container = styled.div`
  position: relative;
`;

const ItemLoading = styled.div`
  position: absolute;
  width: 100%;
`;

const SearchResults = styled.div`
  background-color: ${props => props.theme.colors.searchBackground};
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.25);
  color: ${props => props.theme.colors.brand.layer0};
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: absolute;
  top: calc(100% + 0.5rem);
  width: 100%;
  z-index: 15;
`;

const ResultItem = styled.div`
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid ${props => props.theme.colors.brand.layer4};
  cursor: pointer;
  display: flex;
  padding: 1em;
  transition: all 250ms ease;

  &:hover {
    background-color: lightgray;
  }

  ${props =>
    props.isHighlighted &&
    `
    background-color: gray;

    &:hover {
      background-color: gray;
    }
  `}

  &:last-child {
    border: 0;
  }
`;

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.5rem;
`;

const LoadingError = styled.div`
  display: flex;
  padding: 0.5rem;

  i {
    margin-right: 0.5rem;
  }
`;

const ItemLabels = styled.div`
  display: flex;
  flex-direction: column;

  div:nth-child(2) {
    color: ${props => props.theme.colors.brand.layer3};
  }
`;

//try to resolve a display party from a track. Start with a required artist. Otherwise use the first track party.
function resolveTrackDisplayParty(track) {
  const requiredArtist = track.trackParties.find(trackParty => trackParty.party.is_required_release_artist);
  return requiredArtist?.party?.full_name || track.trackParties[0]?.party?.full_name || '';
}

const TrackSearch = React.forwardRef(
  ({ name, onSelectItem, placeholder, autoFocus, error, heapCode, ...props }, ref) => {
    const Track = API.track();
    const [input, setInput] = useState('');
    const [results, setResults] = useState([]);
    const [status, setStatus] = useState(buildStatus(null));
    const [highlightedIndex, setHighlightedIndex] = useState(-1);

    const debouncedSearchTerm = useDebounce(input, 500);
    const [showDropdown, setShowDropdown] = useState(false);

    const shouldCancel = useRef(false);
    const refResults = useRef();

    const searchInputHandler = e => {
      setStatus(buildStatus(null));
      setInput(e.target.value);
      setShowDropdown(!!e.target.value);

      if (!e.target.value) {
        setResults([]);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);

      return () => document.removeEventListener('click', handleDocumentClick);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (debouncedSearchTerm !== '') {
        setResults([]);
        search(input);
      }
      // DEBT:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchTerm]);

    //TODO: Sometimes Track artistfield text field doesn't allow save(?)

    const search = async () => {
      if (debouncedSearchTerm === '') return;
      let results, hits;

      setStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

      if (validator.isISRC(debouncedSearchTerm)) {
        results = await Track.byIsrc(debouncedSearchTerm).catch(err => {
          console.error(err.stack);
          setStatus(buildStatus(err.stack));
        });
      } else {
        results = await Track.search(debouncedSearchTerm).catch(err => {
          console.error(err.stack);
          setStatus(buildStatus(err.stack));
        });
      }

      hits =
        results
          ?.filter(item => item && item?.isrc_code)
          ?.filter((_, i) => i < MAX_RESULTS)
          ?.map(item => {
            const isrcSection = item.isrc_code ? `(${item.isrc_code})` : '';
            const displayArtistSection = item.display_artist_name
              ? item.display_artist_name
              : resolveTrackDisplayParty(item);

            return {
              label: item?.title,
              subLabel: `${displayArtistSection} ${isrcSection}`,
              value: item,
            };
          }) || [];

      if (shouldCancel.current) {
        shouldCancel.current = false;
        setStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
      } else if (!hits) {
        setResults([]);
        setStatus(buildStatus(API_STATUS_KEYS.ERROR));
      } else {
        setResults(hits);
        setShowDropdown(true);
        setStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
      }
    };

    const handleDocumentClick = () => {
      setShowDropdown(false);
      shouldCancel.current = true;
    };

    const selectItemHandler = item => {
      onSelectItem(item);

      setShowDropdown(false);
    };

    const highlightPreviousItem = () => {
      let newIndex = highlightedIndex - 1;
      newIndex = newIndex < 0 ? 0 : newIndex;
      updateHighlight(newIndex);
    };

    const highlightNextItem = () => {
      const maxIndex = Math.min(MAX_RESULTS - 1, results?.length - 1);
      let newIndex = highlightedIndex + 1;
      newIndex = newIndex > maxIndex ? maxIndex : newIndex;
      updateHighlight(newIndex);
    };

    const updateHighlight = newIndex => {
      setHighlightedIndex(newIndex);

      if (refResults?.current?.childNodes[newIndex]) {
        refResults.current.scrollTop = refResults.current.childNodes[newIndex].offsetTop;
      }
    };

    const onKeyUpHandler = e => {
      if (shouldCancel.current) {
        shouldCancel.current = false;
      }

      switch (e.keyCode) {
        // Up
        case 38:
          highlightPreviousItem();
          break;
        // Down
        case 40:
          if (showDropdown) {
            highlightNextItem();
          } else {
            setShowDropdown(true);
          }
          break;
        // Enter
        case 13:
          if (results.length && highlightedIndex !== -1) {
            selectItemHandler(results[highlightedIndex]);
          }
          break;
        // Tab, Esc
        case 9: // WTF: setShowDropdown doesn't re-render on Tab (but does on Esc)
        case 27:
          setShowDropdown(false);
          setHighlightedIndex(-1);
          shouldCancel.current = true;
          break;
        default:
          break;
      }
    };

    const noResultsMessage = 'No results';

    const onChangeHandler = e => searchInputHandler(e);
    return (
      <Container>
        <TextInput
          name={name}
          placeholder={placeholder || 'Search anything...'}
          onChange={onChangeHandler}
          onKeyUp={e => onKeyUpHandler(e)}
          autoFocus={autoFocus}
          error={error}
          ref={ref}
          data-lpignore={true}
          divInput
          data-heap={heapCode}
          {...props}
        />
        {!!input?.length && showDropdown && (
          <SearchResults
            ref={refResults}
            style={{
              minHeight: status[API_STATUS_KEYS.IN_PROGRESS] ? '4px' : 0,
            }}>
            {status[API_STATUS_KEYS.IN_PROGRESS] ? (
              <ItemLoading>
                <BarLoader cssOverride={{ width: '100%' }} />
              </ItemLoading>
            ) : status[API_STATUS_KEYS.ERROR] ? (
              <LoadingError>
                <Icon type="alert" color={props.theme.colors.brand.layer0} /> Couldn&apos;t load results
              </LoadingError>
            ) : null}
            {results.length === 0 && status[API_STATUS_KEYS.COMPLETE] ? (
              <NoResults>{noResultsMessage}</NoResults>
            ) : (
              results.map((item, i) => {
                return (
                  <ResultItem key={i} isHighlighted={highlightedIndex === i} onClick={() => selectItemHandler(item)}>
                    {/*<ImagePreview />*/}
                    <ItemLabels>
                      <div>{item.label}</div>
                      <div>{item.subLabel}</div>
                    </ItemLabels>
                  </ResultItem>
                );
              })
            )}
          </SearchResults>
        )}
      </Container>
    );
  }
);

TrackSearch.displayName = 'Search';

export default withTheme(TrackSearch);
