import * as React from 'react';
import { Box, Heading, useTheme } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';

const GlanceCardContainer = styled(Box)`
  ${props => !props.noSetHeight && 'height: 100%;'}
  min-height: 255px;
  padding: ${props => `${props.theme.space[6]} ${props.theme.space[5]}`};
  background-color: ${props => (props.nobg ? '' : props.theme.colors.black05)};
  border-color: ${props => props.theme.colors.black20};
  border: ${props => (props.noborder ? '' : '1px solid')};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  ${props => !props.showDecorativeText && `padding-top: 18px;`}
`;

const ChildContent = styled.div`
  height: 100%;
  width: 100%;
  margin-top: ${props => props.theme.space[2]};
`;

export default function GlanceCard({ dateRangeString, decorativeText, title, children, status, ...props }) {
  const theme = useTheme();
  const showDecorativeText = !!(dateRangeString || decorativeText);
  return (
    <GlanceCardContainer {...props} showDecorativeText={showDecorativeText}>
      {showDecorativeText ? (
        <Box
          color="black.60"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          textTransform="uppercase"
          fontWeight={theme.fonts.weights.medium}
          fontSize={theme.fonts.sizes.xsm}
          letterSpacing={'1px'}
          marginBottom={'4px'}>
          <SkeletonLoader
            status={status}
            skeletonProps={{ height: 16.5, width: '12.5%', borderRadius: 8, marginBottom: 2 }}>
            <Box>{decorativeText}</Box>
          </SkeletonLoader>
          <SkeletonLoader
            status={status}
            skeletonProps={{ height: 16.5, width: '18%', borderRadius: 8, marginBottom: 4 }}>
            <div>{dateRangeString}</div>
          </SkeletonLoader>
        </Box>
      ) : null}
      <SkeletonLoader status={status} skeletonProps={{ height: 30, borderRadius: 8, width: '50%' }}>
        <Heading
          color="black.100"
          as={'h2'}
          fontSize={theme.fonts.sizes.xlg}
          fontFamily={theme.fonts.families.text}
          data-card={'title'}>
          {title}
        </Heading>
      </SkeletonLoader>
      <ChildContent>
        <SkeletonLoader
          status={status}
          skeletonProps={{ borderRadius: 8 }}
          customError="There was a problem finding top tracks">
          {children}
        </SkeletonLoader>
      </ChildContent>
    </GlanceCardContainer>
  );
}
