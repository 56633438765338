import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { RiAddLine } from 'react-icons/ri';
export default function GetDspUriButton({ disabled }) {
  const { groupId } = useParams();
  return (
    <Button
      as={Link}
      disabled={disabled}
      variant="smallDark"
      to={`/dsp-uri/spotify/${groupId}`}
      testCode={'spotify-uri-page'}
      leftIcon={<RiAddLine />}>
      Get Spotify URI
    </Button>
  );
}
