import { useState, useRef } from 'react';
import _debounce from 'lodash/debounce';
import _differenceWith from 'lodash/differenceWith';
import _isEqual from 'lodash/isEqual';

export class FormStates {
  static PRISTINE = 'pristine';
  static DIRTY = 'dirty';
}

export default function useFormState(initialState = {}, subscriptions = {}, debounceTime = 200) {
  const [state, setState] = useState(initialState);

  const formStates = useRef(
    Object.keys(initialState).reduce((acc, key) => {
      return {
        ...acc,
        [key]: FormStates.PRISTINE,
      };
    }, {})
  );

  const subscribersRef = useRef(subscriptions);
  const subscribers = Object.assign({}, subscribersRef.current);

  const updateState = (newState = {}, options = {}) => {
    const { doDirtyState = true } = options;

    setState(currentState => {
      const nextState = typeof newState === 'function' ? newState(currentState) : newState;
      const diff = _differenceWith(Object.entries(nextState), Object.entries(currentState), (left, right) => {
        const [leftKey, leftValue] = left;
        const [rightKey, rightValue] = right;
        return leftKey === rightKey && _isEqual(leftValue, rightValue);
      });

      if (!diff.length) {
        return currentState;
      }

      const calculatedNewState = {
        ...currentState,
        ...nextState,
      };

      if (doDirtyState) {
        diff.forEach(entry => {
          const [key, value] = entry;
          _debounce(() => setTimeout(() => (formStates.current[key] = FormStates.DIRTY)), debounceTime)();
          if (subscribers[key] && typeof subscribers[key][FormStates.DIRTY] === 'function') {
            try {
              subscribers[key][FormStates.DIRTY](value, calculatedNewState);
            } catch (e) {
              e.message = `Error in ${key} subscriber: ${e.message}`;
              console.warn(e);
            }
          }

          if (subscribers._after && typeof subscribers._after[FormStates.DIRTY] === 'function') {
            try {
              subscribers._after[FormStates.DIRTY](value, calculatedNewState);
            } catch (e) {
              e.message = `Error in ${key} subscriber: ${e.message}`;
              console.warn(e);
            }
          }
        });
      }

      return calculatedNewState;
    });
  };

  const subscribeToState = (obj = {}) => {
    subscribersRef.current = { ...subscribersRef.current, ...obj };
  };

  return [state, updateState, subscribeToState, formStates.current, subscribersRef.current];
}

export function useFormStateManager(
  stateManager,
  { subscriptions, defaultErrorMessages, doWireValidations = false, debounceTime = 200 }
) {
  const [errorMessages, setErrorMessages] = useState({});

  let subscribers = subscriptions || {};
  const mutableFields = stateManager.mutableFields;

  const updateErrorMessages = (name, value) => {
    setErrorMessages(currentErrorMessages => {
      return {
        ...currentErrorMessages,
        [name]: value,
      };
    });
  };

  if (doWireValidations) {
    Object.keys(mutableFields).forEach(key => {
      if (key !== 'metaState') {
        subscribers[key] = {
          ...subscribers?.[key],
          [FormStates.DIRTY]: (value, state) => {
            try {
              const isValid = stateManager.validateField(key, value, state);
              if (!isValid) {
                updateErrorMessages(key, defaultErrorMessages[key]);
              } else {
                updateErrorMessages(key, null);
              }
            } catch (e) {
              e.message = `Error in ${key} subscriber: ${e.message}`;
              console.warn(e);
            }
          },
        };
      }
    });
  }

  const [state, updateState, subscribeToState, formStates] = useFormState(
    stateManager.mutableFields,
    null,
    debounceTime
  );

  subscribeToState(subscribers);

  const saveManagerState = stateManager.getSaveHandler();

  const refresh = () => {
    updateState(stateManager.mutableFields, { doDirtyState: false });
  };

  const updateAllState = (newState, options = {}) => {
    new Promise(resolve => {
      updateState(newState, options);
      saveManagerState(newState);
      resolve();
    });
  };

  const syncState = (options = {}) => {
    const anyDirty = Object.values(formStates).some(state => state === FormStates.DIRTY);
    if (!anyDirty) {
      saveManagerState(state, options);
    }
  };

  return {
    localState: state,
    updateLocalState: updateState,
    updateAllState,
    subscribeToState,
    formStates,
    errorMessages,
    syncState,
    refresh,
    updateErrorMessages,
  };
}
