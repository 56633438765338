import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Text } from '@chakra-ui/react';
import TextField from 'components/TextField/text-field';
import Input from 'components/Input/input';

function ContributorForm({ onSubmit, onChange, variant = 'dark', formMethods, display }) {
  const { register, errors, handleSubmit } = formMethods;

  const submitChanges = data => {
    onSubmit(data);
  };

  return (
    <Box key="initialStep" as="form" id="contributor" onSubmit={handleSubmit(submitChanges)} mt="4" display={display}>
      <Input variant={variant} title="Contributor Name">
        <TextField
          variant={variant}
          id="contributorName"
          name="contributorName"
          placeholder="Enter Text"
          onBlur={e => {
            e.persist();
            onChange(e.currentTarget.value);
          }}
          ref={register({
            required: 'Contributor requires a name',
            minLength: {
              value: 2,
              message: 'Name must be at least 2 characters long',
            },
          })}
          error={errors?.contributorName}
        />
        <Text paddingTop="0" color="black50" marginTop="0.75rem">
          Please enter names exactly as they are to be seen on DSPs
        </Text>
      </Input>
    </Box>
  );
}

export default function ContributorFormContainer({ defaultValues, variant = 'dark', ...props }) {
  const formMethods = useForm({ defaultValues });
  return (
    <FormProvider {...formMethods}>
      <ContributorForm variant={variant} {...props} formMethods={formMethods} />
    </FormProvider>
  );
}
