import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { chakraTheme } from '../../theme';

const darkButtonStyle = {
  backgroundColor: chakraTheme.colors.black100,
  color: 'white',
  borderRadius: '0.25rem',
  fontWeight: 600,
  fontSize: '.875rem',
  height: '2.25rem',
  width: '100%',
  margin: '0 auto',
  _hover: {
    backgroundColor: chakraTheme.colors.black90,
  },
};

const CallToActionSection = ({ onClick, disabled, title, description }) => (
  <Flex mb=".75rem" direction={'column'}>
    <Text m={'2rem 0 0 0'} w={'90%'} color={chakraTheme.colors.black100}>
      As a preferred partner of Spotify, Venice provides special access to Spotify for Artists and artist verification.
      Select your artist name from the dropdown to begin.
    </Text>
    <Text m={'1rem 0 3rem 0'} w={'90%'} color={chakraTheme.colors.black100}>
      An unverified Spotify artist profile (no blue check) must be associated with a <b>live</b> release on Spotify to
      be eligible to use Special Access.
    </Text>
    <Box
      style={{
        background: 'white',
        padding: '1.5rem',
        borderRadius: '.5rem',
        border: '1px solid',
        borderColor: chakraTheme.colors.black60,
        width: '21rem',
      }}
      opacity={disabled ? 0.4 : 1.0}>
      <Heading size="md" color="black100">
        {title}
      </Heading>
      <Text color="black100" m={'1rem 0'}>
        {description}
      </Text>
      <Button {...darkButtonStyle} variant={'solid'} onClick={onClick} disabled={disabled}>
        Get Access
      </Button>
    </Box>
  </Flex>
);

export default CallToActionSection;
