import { dataApiUrl } from '../url';
import AuthAxios, { NoAuthAxios } from '../auth-axios';

const DataApiUrl = dataApiUrl();
const TRACKS_URL = `${DataApiUrl}/tracks`;

const META_DATA_POST_RETRY_MS = 10 * 1000;

//TODO merge this function with the one below it once we cover all error handling
//for all the other queries.
const buildUncaughtTrackRequest =
  method =>
  async (args = null, data, opts) => {
    const subPath = args === null ? '' : args;
    const res = await AuthAxios[method](`${TRACKS_URL}${subPath}`, data, opts);
    return res?.data?.data || res.data;
  };

//TODO remove this error catch and allow the caller to decide what to do.
const trackRequest =
  method =>
  async (args = null, data, opts) => {
    const subPath = args === null ? '' : args;
    try {
      const res = await AuthAxios[method](`${TRACKS_URL}${subPath}`, data, opts);
      return res?.data?.data || res.data;
    } catch (e) {
      console.error(e);
    }
  };

const getTrack = trackRequest('get');
const postTrack = trackRequest('post');
const patchTrack = trackRequest('patch');
const deleteTrack = trackRequest('delete');

const uncaughtGetTrack = buildUncaughtTrackRequest('get');

const Track = () => {
  const search = (query, contextualSearchTerms) =>
    AuthAxios.get(TRACKS_URL, {
      params: { page_size: 10, page_index: 0, search: query, ...contextualSearchTerms },
    }).then(res => res?.data?.data.results);

  const findTracksForClipsCopy = async ({ page_size, search, page_index }) =>
    getTrack(`/find-tracks-for-clips-copy`, {
      params: { page_size, search, page_index },
    });

  const copyTrackReleaseForClips = async ({ asset_release_id, asset_track_id }) =>
    AuthAxios.post(`${TRACKS_URL}/copy-track-release-for-clips`, { asset_release_id, asset_track_id }).then(
      results => results.data.data
    );

  const get = id => getTrack(`/${id}`);

  const getUploadMultiPart = async ({ id, isImmersive, fileName }) =>
    uncaughtGetTrack(`/${id}/files/uploadMultiPart`, {
      params: { isImmersive, fileName: encodeURIComponent(fileName) },
    });

  const getUploadPartUrl = async ({ id, isImmersive, fileName, UploadId, PartNumber }) =>
    uncaughtGetTrack(`/${id}/files/uploadPartUrl`, {
      params: {
        isImmersive,
        fileName: encodeURIComponent(fileName),
        UploadId,
        PartNumber,
      },
    });

  const getCompleteUpload = async ({ id, isImmersive, fileName, uploadId, parts }) =>
    AuthAxios.post(`${TRACKS_URL}/${id}/files/completeUpload`, { parts, isImmersive, fileName, uploadId });

  const getUploadUrl = async ({ id, isImmersive, fileName }) =>
    getTrack(`/${id}/files/uploadUrl?isImmersive=${isImmersive}&fileName=${encodeURIComponent(fileName)}`);

  const postFileMetadata = async ({
    id,
    isImmersive,
    fileName,
    height,
    width,
    numberOfChannels,
    duration,
    audioCodec,
    videoCodec,
  }) =>
    await postTrack(`/${id}/files/metadata`, {
      fileName: encodeURIComponent(fileName),
      isImmersive,
      height,
      width,
      numberOfChannels,
      duration,
      audioCodec: encodeURIComponent(audioCodec),
      videoCodec: encodeURIComponent(videoCodec),
    });

  const byIsrc = isrc_codes =>
    getTrack('', { params: { page_size: 10, page_index: 0, isrc_code: isrc_codes } }).then(data => data.results);

  const create = data => AuthAxios.post(TRACKS_URL, data).then(res => res.data.data);

  const update = (id, data) => patchTrack(`/${id}`, data);

  const destroy = id => deleteTrack(`/${id}`);

  const addArtist = (track_id, partyId, roles) =>
    postTrack(`/${track_id}/artists`, {
      asset_party_id: partyId,
      artist_type: roles,
    });

  const removeArtist = (track_id, partyId) => deleteTrack(`/${track_id}/artists/${partyId}`);

  const updateArtist = (track_id, partyId, data) => patchTrack(`/${track_id}/artists/${partyId}`, data);

  const artists = track_id => getTrack(`/${track_id}/artists`);

  const file = track_id =>
    AuthAxios(`${TRACKS_URL}/${track_id}/files`, {
      responseType: 'blob',
    })
      .then(res => {
        return {
          data: res.data,
          encoding: res.headers['content-type'].split('/')[1],
        };
      })
      .catch(err => console.error(err.message));

  const updateFile = track_id => patchTrack(`/${track_id}/files`);

  const contributors = track_id => getTrack(`/${track_id}/contributors`);

  const revenue = track_id => getTrack(`/${track_id}/revenue`);

  const distributionHistory = track_id => getTrack(`/${track_id}/distributionHistory`);

  const getSplits = id => getTrack(`/${id}/splits`).then(data => data.results);

  const addSplit = (id, user_group_payee_id, asset_party_id, percentage, roles) => {
    const addSplitBody = {
      user_group_payee_id,
      percentage,
      roles,
    };

    return postTrack(`/${id}/splits`, asset_party_id ? { ...addSplitBody, asset_party_id } : addSplitBody);
  };

  const updateSplit = (trackId, splitId, data) => {
    return patchTrack(
      `/${trackId}/splits/${splitId}`,
      Object.entries(data).reduce((acc, entry) => {
        const [k, v] = entry;
        if (v) {
          acc[k] = v;
        }
        return acc;
      }, {})
    );
  };

  const deleteSplit = (trackId, splitId) => deleteTrack(`/${trackId}/splits/${splitId}`);

  //This endpoint only checks the DB, it does not check for the presesne of the file in s3.
  const verifyFile = trackId => getTrack(`/${trackId}/verifyFile`).then(res => res?.fileFound);

  const verifyFileIsInStorage = async ({ id, isImmersive, fileName, eTag }) =>
    uncaughtGetTrack(`/${id}/verifyFileIsInStorage`, {
      params: {
        isImmersive,
        fileName: encodeURIComponent(fileName),
        eTag: eTag ? encodeURIComponent(eTag) : undefined,
      },
    });

  const getSignedUrl = async ({ track_id, file_name }) => {
    return getTrack(`/${track_id}/presignedAssetUrl`, { params: { file_name } })
      .then(data => data)
      .catch(err => console.error(err.message));
  };

  const getFileFromSignedUrl = async (track_id, file_name) => {
    const { url } = await getSignedUrl({ track_id, file_name });
    return await NoAuthAxios.get(url, { responseType: 'blob' });
  };

  return {
    search,
    get,
    getUploadUrl,
    postFileMetadata,
    getFileFromSignedUrl,
    byIsrc,
    update,
    create,
    destroy,
    addArtist,
    removeArtist,
    updateArtist,
    artists,
    file,
    updateFile,
    contributors,
    revenue,
    distributionHistory,
    getSplits,
    addSplit,
    updateSplit,
    deleteSplit,
    verifyFile,
    verifyFileIsInStorage,
    getUploadMultiPart,
    getUploadPartUrl,
    getCompleteUpload,
    getSignedUrl,
    findTracksForClipsCopy,
    copyTrackReleaseForClips,
    validateFile: async (file, validationType) => {
      return postTrack(`/validateFile`, file, {
        headers: {
          'Content-Type': `text/plain;charset=utf8;`,
        },
        params: {
          'validation-type': validationType,
        },
      });
    },
  };
};

export default Track;
