import * as React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Box, Checkbox, Icon, Text, Heading, useTheme, Tooltip } from '@chakra-ui/react';
import { RiInformationLine } from 'react-icons/ri';

import TextField from 'components/TextField/text-field';
import Input from 'components/Input/input';

function PreviouslySavedIdTooltip() {
  return (
    <Tooltip
      placement="auto-end"
      label="Previously saved ids cannot be edited because this Artist is associated with a delivered release. Please reach out to yourfriends@venicemusic.co to make changes.">
      <Box as="span" ml=".5rem">
        <Icon as={RiInformationLine} />
      </Box>
    </Tooltip>
  );
}

function ArtistForm({ isEditing, onSubmit, formMethods, defaultValues, display, hasDeliveredRelease, ...props }) {
  const { register, errors, handleSubmit } = formMethods;
  const [skipApple, setSkipApple] = React.useState(false);
  const [skipSpotify, setSkipSpotify] = React.useState(false);
  const [skipVevo, setSkipVevo] = React.useState(false);
  const variant = props?.variant;

  const submitChanges = data => {
    onSubmit(data);
  };

  const readOnlySpotifyId = defaultValues?.spotifyId && hasDeliveredRelease;
  const readOnlyAppleId = defaultValues?.appleId && hasDeliveredRelease;
  const readOnlySoundcloudId = defaultValues?.soundcloudId && hasDeliveredRelease;
  const readOnlyVevoChannelName = defaultValues?.vevoChannelName && hasDeliveredRelease;

  const theme = useTheme();
  return (
    <Box
      display={display}
      key="initialStep"
      as="form"
      id="artist"
      onSubmit={handleSubmit(submitChanges)}
      maxHeight="60vh"
      position="relative"
      mb="2rem"
      overflowY="auto"
      pr="0.5rem"
      css={{
        '&::-webkit-scrollbar': {
          position: 'absolute',
          left: '-2px',
          width: '6px',
        },
        '&::-webkit-scrollbar-track': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: 'gray',
          borderRadius: '0.25rem',
        },
      }}>
      <Input title="Artist Name" variant={variant}>
        <TextField
          variant={variant}
          id="artistName"
          name="artistName"
          placeholder="Enter Text"
          ref={register({
            required: 'Artists require a name',
            minLength: {
              value: 2,
              message: 'Name must be at least 2 characters long',
            },
          })}
          error={errors?.artistName}
        />
      </Input>
      <Box my={4}>
        <Heading variant={variant === 'dark' ? 'h2Dark' : null} fontSize="lg">
          Streaming Platform IDs
        </Heading>
        <Text mt=".5rem" color={variant === 'dark' ? theme.colors.gray[500] : theme.colors.black100}>
          IDs are required to ensure that the Release, Tracks, and Artist are properly connected on streaming platforms.
          Please note, the channels and IDs will not be created until you have distributed your first audio/video
          releases through Venice to the specified streaming platform(s).
        </Text>
        <Text mt=".5rem" color={variant === 'dark' ? theme.colors.gray[500] : theme.colors.black100}>
          For more info on how to locate your artist ID's please visit the link{' '}
          <a
            target="_blank"
            href="https://support.venice.tech/distribution/how-do-i-fill-out-artist-ids-in-the-create-release"
            rel="noreferrer"
            style={{ color: '#FF335F' }}>
            here.
          </a>
        </Text>
      </Box>
      <Input
        title="Apple Music ID"
        style={{ marginBottom: theme.space[3] }}
        disabled={skipApple || readOnlyAppleId}
        tooltipComponent={readOnlyAppleId ? <PreviouslySavedIdTooltip /> : null}
        optional
        variant={variant}>
        <TextField
          variant={variant}
          id="appleId"
          icon={readOnlyAppleId ? 'lock' : ''}
          name="appleId"
          placeholder={!skipApple ? '1934825555' : ''}
          ref={register({
            required: !isEditing && !skipApple,
            minLength: {
              value: 1,
              message: 'Apple Music artist IDs must be at least a 1 digit number',
            },
            validate: {
              isNumber: value => !window.isNaN(value) || 'Apple Music artist IDs must be at least a 1 digit number',
            },
          })}
          error={errors?.appleId}
          doShowError={!skipApple}
        />
      </Input>
      {!isEditing && (
        <Checkbox
          variant={variant}
          mt={!skipApple && errors?.appleId && '2'}
          mb="4"
          size="sm"
          onChange={() => setSkipApple(!skipApple)}
          isChecked={skipApple}>
          No existing Apple ID, create one for me
        </Checkbox>
      )}
      <Input
        title="Spotify URI"
        variant={variant}
        style={{ marginBottom: theme.space[3] }}
        disabled={skipSpotify || readOnlySpotifyId}
        tooltipComponent={readOnlySpotifyId ? <PreviouslySavedIdTooltip /> : null}
        optional>
        <TextField
          id="spotifyId"
          variant={variant}
          name="spotifyId"
          icon={readOnlySpotifyId ? 'lock' : ''}
          placeholder={!skipSpotify ? 'spotify:artist:80HpiyWkp2Z7gFTkVae555' : ''}
          ref={register({
            required: !isEditing && !skipSpotify,
            pattern: {
              value: /^spotify:artist:.*/,
              message: 'Spotify artist IDs must start with spotify:artist: followed by an ID',
            },
          })}
          error={errors?.spotifyId}
          doShowError={!skipSpotify}
        />
      </Input>
      {!isEditing && (
        <Checkbox
          variant={variant}
          mt={!skipSpotify && errors?.spotifyId && '2'}
          mb="4"
          size="sm"
          onChange={() => setSkipSpotify(!skipSpotify)}
          isChecked={skipSpotify}>
          No existing Spotify ID, create one for me
        </Checkbox>
      )}
      <Input
        variant={variant}
        title="Vevo Channel Name"
        style={{ marginBottom: theme.space[3] }}
        disabled={skipVevo || readOnlyVevoChannelName}
        tooltipComponent={readOnlyVevoChannelName ? <PreviouslySavedIdTooltip /> : null}
        optional>
        <TextField
          id="vevoChannelName"
          variant={variant}
          name="vevoChannelName"
          icon={readOnlyVevoChannelName ? 'lock' : ''}
          placeholder={!skipVevo ? 'thuyVEVO' : ''}
          ref={register({
            required: !isEditing && !skipVevo,
            pattern: {
              value: /.*VEVO$/i,
              message: 'Vevo channel name must end with VEVO',
            },
          })}
          error={errors?.vevoChannelName}
          doShowError={!skipVevo}
        />
      </Input>
      {!isEditing && (
        <Checkbox
          variant={variant}
          mt={!skipVevo && errors?.vevoChannelName && '2'}
          mb="4"
          size="sm"
          onChange={() => setSkipVevo(!skipVevo)}
          isChecked={skipVevo}
          alignItems="baseline">
          No existing Vevo Channel, create one for me (official music video submissions only)
        </Checkbox>
      )}
      <Input
        variant={variant}
        title="SoundCloud ID"
        disabled={readOnlySoundcloudId}
        tooltipComponent={readOnlySoundcloudId ? <PreviouslySavedIdTooltip /> : null}
        style={{ marginBottom: theme.space[3] }}
        optional>
        <TextField
          id="soundcloudId"
          variant={variant}
          name="soundcloudId"
          icon={readOnlySoundcloudId ? 'lock' : ''}
          placeholder="blonded"
          ref={register({
            required: false, // SoundCloud ID is optional
            pattern: {
              value: /^[a-z0-9_-]+$/,
              message: 'Use only numbers, lowercase letters, underscores, or hyphens.',
            },
          })}
          error={errors?.soundcloudId}
          doShowError={true}
        />
      </Input>
    </Box>
  );
}

export default function ArtistFormContainer(props) {
  const formMethods = useForm({ defaultValues: props.defaultValues });
  const variant = props?.variant || 'dark';

  return (
    <FormProvider {...formMethods}>
      <ArtistForm variant={variant} {...props} formMethods={formMethods} />
    </FormProvider>
  );
}
