import React, { memo, useMemo } from 'react';
import { useFormStateManager } from '../../../state-management/use-form-state';
import ManageContentBox, { ContentHeader, Title } from 'components/ManageContentBox/manage-content-box';
import { Box, Text, useTheme, RadioGroup, Stack, Radio, Flex } from '@chakra-ui/react';
import Well from 'components/Well/well';
import LabeledDatePicker from 'features/LabeledDatePicker/labeled-date-picker';
import TerritoriesSelector from '../../../features/territories-selector/territories-selector';
import { territories } from '../../../constants/territories';
import DspsSelector from 'features/dsps-selector/dsps-selector';
import TimeSelector from 'features/TimePicker/time-selector';
import { DateTime } from 'luxon';

const datePostfixMap = {
  0: 'th',
  1: 'st',
  2: 'nd',
  3: 'rd',
};

function ClipDistribution({ stateManager }) {
  const theme = useTheme();

  const formManager = useFormStateManager(stateManager, {
    defaultErrorMessages: {
      dsp_platforms: 'object is false',
      territories: 'object is false',
      release_date: 'object is false',
    },
    doWireValidations: true,
  });

  const luxonMinDate = useMemo(() => DateTime.now().startOf('day').plus({ days: 1 }), []);

  const luxonReleaseDate = useMemo(() => {
    return DateTime.fromISO(formManager.localState.release_date);
  }, [formManager.localState.release_date]);

  const releaseDateInfoMessage = useMemo(() => {
    if (!luxonReleaseDate || luxonReleaseDate.invalid) {
      return '';
    }

    const worldWideSection =
      formManager.localState.territories.length === territories.length ? 'worldwide' : 'in the selected countries';
    const timeSection = luxonReleaseDate.toFormat('h:mm a');
    const monthSection = luxonReleaseDate.toFormat('LLLL d');
    const datePostfix = datePostfixMap[monthSection.at(-1)] || datePostfixMap[0];
    const year = luxonReleaseDate.toFormat('yyyy');

    return `Your release will be set to go live ${worldWideSection} at ${timeSection} Eastern time on ${monthSection}${datePostfix}, ${year}`;
  }, [luxonReleaseDate, formManager.localState.territories]);

  function onSelectDsps(value) {
    stateManager.saveState({ dsp_platforms: value });
  }

  function onSelectTerritories(value) {
    stateManager.saveState({ territories: value });
  }

  function onReleaseDateRadioChange(value) {
    formManager.updateAllState(currentState => {
      const { release_date } = currentState;

      //assumes radio values of "true" or not
      const is_timed_release = value === 'true';
      const parsedDateTime = DateTime.fromISO(release_date);
      const alteredReleaseDate = is_timed_release ? parsedDateTime : parsedDateTime.startOf('day');

      return {
        ...currentState,
        is_timed_release,
        release_date: alteredReleaseDate.toISO(),
      };
    });
  }

  function onDayChange(name, releaseDay) {
    const { release_date } = formManager.localState;

    const parsedLuxonDate = DateTime.fromFormat(releaseDay, 'yyyy-LL-dd');
    const currentReleaseDate = DateTime.fromISO(release_date);
    const alteredReleaseDate = currentReleaseDate.invalid
      ? parsedLuxonDate
      : currentReleaseDate.set({
          year: parsedLuxonDate.year,
          month: parsedLuxonDate.month,
          day: parsedLuxonDate.day,
        });

    stateManager.saveState({ release_date: alteredReleaseDate.toISO() });
    formManager.updateLocalState(currentState => {
      return {
        ...currentState,
        release_date: alteredReleaseDate.toISO(),
      };
    });
  }

  function onTimeChange(changeDateTime) {
    const { release_date } = formManager.localState;

    const currentReleaseDate = DateTime.fromISO(release_date);
    const alteredReleaseDate = currentReleaseDate.invalid
      ? changeDateTime
      : currentReleaseDate.set({
          hour: changeDateTime.hour,
          minute: changeDateTime.minute,
        });

    stateManager.saveState({ release_date: alteredReleaseDate.toISO() });
    formManager.updateLocalState(currentState => {
      return {
        ...currentState,
        release_date: alteredReleaseDate.toISO(),
      };
    });
  }

  function submitForm(evt) {
    evt?.preventDefault();
    formManager.updateAllState(formManager.localState);
  }

  const blurHandler = () => {
    stateManager.saveState(formManager.localState);
  };

  const dspOptions = useMemo(
    () =>
      (stateManager.stateData?.selectableDsps || [])
        .map(dsp => ({ name: dsp.name, value: dsp.identifier }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [stateManager.stateData?.selectableDsps]
  );

  return (
    <Box>
      <form onSubmit={submitForm}>
        <ManageContentBox>
          <ContentHeader mb="1.5rem">
            <Title>Where fans will listen to your release</Title>
          </ContentHeader>
          <DspsSelector
            containerProps={{
              width: '20rem',
              justifyContent: 'space-between',
              fontWeight: 'normal',
              mb: '1rem',
            }}
            defaultSelections={stateManager.mutableFields.dsp_platforms}
            onSelectDsps={onSelectDsps}
            dspOptions={dspOptions}
            isValid={Object.hasOwn(stateManager.validationResults, 'dsp_platforms') ? false : true}
          />
        </ManageContentBox>
        <ManageContentBox>
          <ContentHeader mb="1.5rem">
            <Title>Countries where your release will be available</Title>
          </ContentHeader>
          <Flex flexWrap="wrap" w="20rem">
            <TerritoriesSelector
              containerProps={{
                width: '20rem',
                justifyContent: 'space-between',
                fontWeight: 'normal',
                mb: '1rem',
              }}
              defaultSelections={stateManager.mutableFields.territories}
              onSelectTerritories={onSelectTerritories}
              isValid={Object.hasOwn(stateManager.validationResults, 'territories') ? false : true}
            />
          </Flex>
        </ManageContentBox>
        <ManageContentBox>
          <ContentHeader>
            <Title>When would you like this release to go live?</Title>
          </ContentHeader>
          <Text color="black100" m="1.5rem 0 1rem 0" fontSize="md">
            Select a Release Date
          </Text>
          <LabeledDatePicker
            mt=".75rem"
            onValueChange={onDayChange}
            labelText="Release Date"
            minDate={luxonMinDate.toISO()}
            value={luxonReleaseDate.invalid ? undefined : luxonReleaseDate.toFormat('yyyy-LL-dd')}
            name="release_date"
            onBlur={blurHandler}
          />
          <Text visibility={luxonReleaseDate.invalid ? 'visible' : 'hidden'} my=".5rem" color={theme.colors.pink}>
            Soonest in 2 days
          </Text>
          <Text m="2rem 0 1rem 0" color="black100" fontSize="md">
            Select A Release Time
          </Text>
          <RadioGroup
            onChange={onReleaseDateRadioChange}
            value={formManager.localState.is_timed_release?.toString()}
            defaultValue="false">
            <Stack direction="column">
              <>
                <Radio value="false" variant="smallLight">
                  Standard Release Time
                </Radio>
                <Text pl="1.5rem" mt="0">
                  This release will go live at 12:00AM local time in each territory (Recommended)
                </Text>
              </>
              <>
                <Radio value="true" variant="smallLight">
                  Timed Release
                </Radio>
                {formManager.localState.is_timed_release && (
                  <TimeSelector
                    styleProps={{ pl: '1.5rem' }}
                    onChange={onTimeChange}
                    value={luxonReleaseDate.invalid ? luxonMinDate : luxonReleaseDate}
                    name="release_name"
                    onBlur={blurHandler}
                  />
                )}
                <Text pl="1.5rem" mt="0">
                  This release will go live in all selected territories at 08:00am ET (recommended only for timed
                  editorial premieres)
                </Text>
              </>
            </Stack>
          </RadioGroup>
          {releaseDateInfoMessage && <Well styleProps={{ mt: '1rem' }}>{releaseDateInfoMessage}</Well>}
        </ManageContentBox>
      </form>
    </Box>
  );
}

export default memo(ClipDistribution, (prev, next) => {
  return prev === next;
});
