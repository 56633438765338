import Schema from '../../../schema-gen/distribution';
import ManageClipsStateManager from '../../manage-clips-state-manager';

class ClipDistributionState extends ManageClipsStateManager {
  dependentFields = ['dsp_platforms', 'territories', 'release_date', 'is_timed_release'];
  title = 'Distribution';
  path = '/manage/clip/distribution';
  componentName = 'ClipDistribution';

  constructor(args) {
    const { stateData, ...rest } = args;
    stateData.is_timed_release = !!stateData.is_timed_release || false;

    super(
      {
        ...rest,
        stateData,
        mutableFields: ['dsp_platforms', 'territories', 'release_date', 'is_timed_release'],
      },
      {
        dsp_platforms: Schema.Dsps,
        territories: Schema.Territories,
        release_date: Schema.ReleaseDate,
        it_timed_release: Schema.Is_Timed_Release,
      }
    );
  }
}

export default ClipDistributionState;
