import React, { useState, useEffect } from 'react';
import ViewWrapper from 'views/ViewWrapper/view-wrapper';
import {
  Box,
  Flex,
  Heading,
  CircularProgress,
  Tag,
  Text,
  List,
  ListItem,
  ListIcon,
  useToast,
  ButtonGroup,
  VStack,
} from '@chakra-ui/react';
import { useGetMarketingLinks, useGetLinksCreatedThisMonth } from 'data-client/use-marketing-links';
import { CheckIcon } from '@chakra-ui/icons';
import CreateMarketingLinkButton from './create-marketing-link-button';
import GetDspUriButton from './get-dsp-uri-button';
import MarketingLinksTable from './marketing-links-table';
import { useLocation } from 'react-router-dom';
import { RiLink } from 'react-icons/ri';
import { useUserTierChecks } from '../hooks/authorization-hooks';

const AMOUNT_ALLOWED_PER_MONTH = 5;

export default function MarketingLinksDashboard({ ...props }) {
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    if (location?.state?.linkfireURL) {
      toast({
        position: 'top',
        title: 'Marketing link created and copied',
        render: () => (
          <Box color="black100" p={3} bg="white" textAlign={'center'} borderRadius="8px">
            <Box fontWeight={'bold'} fontSize={'reg'}>
              <Box display="inline-block" verticalAlign={'sub'} mr="4px">
                <RiLink />
              </Box>
              Marketing link created and copied
            </Box>
          </Box>
        ),
      });

      navigator.clipboard.writeText(location.state.linkfireURL);
    }
  }, []); // prevent toast from rendering multiple times

  const [params, setParams] = useState({ page_index: 0 }); //page_size is defaulted to 15 in the BE.
  const { hasLimitedMarketingLinks } = useUserTierChecks();

  const {
    data: marketingLinks = {},
    isLoading: isLoadingLinks,
    refetch: refetchMarketingLinks,
  } = useGetMarketingLinks(params);

  const { data: monthlyCount } = useGetLinksCreatedThisMonth();

  const availableLinksThisMonth = AMOUNT_ALLOWED_PER_MONTH - Number(monthlyCount?.count || 0);

  const date = new Date();
  const firstDayNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  return (
    <ViewWrapper designLayer={0} disableHorizontalScroll={true} {...props}>
      <Box>
        <Box borderBottom="1px solid">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex>
              <Heading as="h1">Marketing Links</Heading>
              {hasLimitedMarketingLinks ? (
                <Flex pl="20px" alignItems="center">
                  <Tag background="#4ABA5C" fontWeight="bold" borderRadius="14px">
                    {availableLinksThisMonth} links available
                  </Tag>
                  <Text display="inline-block" padding="3px 10px">
                    {AMOUNT_ALLOWED_PER_MONTH} new links available on {firstDayNextMonth.toLocaleDateString()}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
            <Flex alignItems="center">
              <ButtonGroup gap="2">
                <GetDspUriButton />
                {marketingLinks?.total >= 1 ? (
                  <CreateMarketingLinkButton disabled={availableLinksThisMonth <= 0 && hasLimitedMarketingLinks} />
                ) : null}{' '}
              </ButtonGroup>
            </Flex>
          </Flex>
        </Box>

        <Box borderBottom="1px solid">
          <MarketingLinksMainView
            isLoadingLinks={isLoadingLinks}
            marketingLinks={marketingLinks}
            params={params}
            setParams={setParams}
            refetchMarketingLinks={refetchMarketingLinks}
          />
        </Box>
        <Box>
          <MarketingLinkDetails />
        </Box>
      </Box>
    </ViewWrapper>
  );
}

function MarketingLinksMainView({
  isLoadingLinks,
  refetchMarketingLinks,
  marketingLinks,
  params: { page_index: onIndex },
  setParams,
}) {
  const { results, total } = marketingLinks;

  if (isLoadingLinks) return <LoadingBox />;

  //This may not correctly convey deleted Links. I'm not sure if there is a concern here.
  if (total <= 0) return <EmptyLinks />;

  return (
    <MarketingLinksTable
      results={results}
      total={total}
      onIndex={onIndex}
      setIndex={index => setParams({ page_index: index })}
      refetchMarketingLinks={refetchMarketingLinks}
    />
  );
}

function LoadingBox() {
  return (
    <Box textAlign="center" padding="30px">
      <CircularProgress />
    </Box>
  );
}

function EmptyLinks({ disabled }) {
  return (
    <Box textAlign="center" padding="120px">
      <Heading as="h2" fontSize="1.5rem">
        Create your first marketing link
      </Heading>
      <Box textAlign="center" padding="30px">
        <CreateMarketingLinkButton disabled={disabled} />
      </Box>
    </Box>
  );
}

const Li = ({ children }) => (
  <ListItem display="flex" alignItems="top" marginTop="0.5em">
    <ListIcon mt="4px" as={CheckIcon} />
    <Box>{children}</Box>
  </ListItem>
);

function MarketingLinkDetails() {
  return (
    <>
      <Flex padding="20px 0" color="black100">
        <Box padding="20px" flexBasis="100%">
          <Heading as="h3" fontSize="1rem" padding="6px 0">
            Achieve More With Marketing Links
          </Heading>
          <Box>
            Marketing Links are a great way to connect your listeners with your music on any digital platform. Use these
            links in your social posts and bios, website, QR codes, and other shareable mediums to drive fans to your
            releases.
          </Box>
        </Box>
        <VStack>
          <Box padding="20px" flexBasis="100%">
            <Heading as="h3" fontSize="1rem" padding="6px 0">
              How Marketing Links Work:
            </Heading>
            <Box>
              <List>
                <Li>
                  Grow excitement for your upcoming track or album release by encouraging fans to pre-save your music on
                  Spotify, Apple Music, + Deezer.
                </Li>
                <Li>
                  Increase your chances of getting playlisted! Spotify views pre-saves as an indicator of success and is
                  likelier to playlist music with many pre-saves.
                </Li>
                <Li>
                  Links automatically update on the day of your release to include your music's complete list of
                  available streaming services.
                </Li>
                <Li>Link to specific tracks or entire releases - whatever you'd like the most ears on.</Li>
                <Li>Automatically updates if you change your Release Date through Venice.</Li>
              </List>
            </Box>
          </Box>
          <Box padding="20px" flexBasis="100%">
            <Heading as="h3" fontSize="1rem" padding="6px 0">
              Learn More about URIs:
            </Heading>
            <Box>
              A URI is a unique resource indicator code for music on their platform. It is a link to directly share your
              songs to fans, both before and after release. Make sure you've already submitted the release for digital
              distribution and that it has been delivered to Spotify.
            </Box>
          </Box>
        </VStack>
      </Flex>
      <Box height={'5rem'} />
    </>
  );
}
