import React from 'react';
import { Box, Text, useStyleConfig, useTheme, Icon } from '@chakra-ui/react';
import icons from '../../utils/icons';

function CardButton2({ icon, title, subTitle, onClick, selected = false, disabled = false, variant, ...rest }) {
  const styles = useStyleConfig('ButtonCard', { variant });
  const theme = useTheme();

  return (
    <Box
      className="card-button"
      __css={styles}
      {...rest}
      role="button"
      aria-disabled={disabled}
      aria-pressed={selected}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}>
      {icon && (
        <Icon
          h="3.5rem"
          w="3.5rem"
          marginBottom="1.25rem"
          as={icons[icon]}
          transition="inherit"
          sx={{
            '.card-button[aria-disabled="false"]:hover &': {
              height: 0,
              opacity: 0,
              transform: 'scale(0.3)',
              translateY: '-100px',
            },
          }}
        />
      )}
      {title && (
        <Text
          fontFamily={theme.fonts.families.display}
          fontSize={theme.fonts.sizes.xlg}
          marginBottom="0.5rem"
          textAlign={styles.textAlign}>
          {title}
        </Text>
      )}
      {subTitle && (
        <Text
          fontSize={theme.fonts.sizes.sm}
          textAlign={styles.textAlign}
          h={variant === 'dark' ? 'unset' : '0px'}
          opacity={variant === 'dark' ? 'unset' : '0'}
          transition={variant === 'dark' ? 'unset' : 'inherit'}
          sx={
            variant === 'dark'
              ? {}
              : {
                '.card-button[aria-disabled="false"]:hover &': {
                  height: 'initial',
                  opacity: 1,
                  transform: 'scale(1)',
                  translateY: '-100px',
                },
              }
          }>
          {subTitle}
        </Text>
      )}
    </Box>
  );
}

export default CardButton2;
