import React from 'react';
import styled from '@emotion/styled/macro';
import { Link } from 'react-router-dom';
import { Box, useTheme } from '@chakra-ui/react';

const Subtitle = styled.div`
  display: inline-flex;
  font-size: 12px;
  line-height: 20px;
  color: #9a98a0;
  vertical-align: bottom;

  span {
    margin-right: ${props => props.theme.space[1]};
  }

  a:first-of-type span {
    margin-left: ${props => props.theme.space[1]};
  }

  a:last-of-type span {
    margin-right: 0;
  }
`;

function MastheadSubtitle({ subtitle, data }) {
  const { transformData, Container } = subtitle;

  const theme = useTheme();
  const items = transformData(data);

  return items.length === 0 ? null : (
    <Box lineHeight={theme.space[5]} height={theme.space[5]} marginBottom={theme.space[0.5]}>
      <Subtitle>
        <Container>
          {items.map((item, i) => {
            const { path, text } = item;
            const delim = i === items.length - 1 ? '' : ', ';
            const string = text + delim;
            if (path) {
              return (
                <Link key={`dd-link-${path}`} to={path}>
                  <span>{string}</span>
                </Link>
              );
            }

            return string;
          })}
        </Container>
      </Subtitle>
    </Box>
  );
}

export default MastheadSubtitle;
