import { useQuery } from 'react-query';
import * as API from '../utils/API/API';
const Party = API.party();

export const getArtistList = ({ user_group_id }) => Party.list('artist', { user_group_id }).then(resp => resp.data);

export const useGetArtistsList = objectWithUserGroupId =>
  useQuery(
    ['artists', objectWithUserGroupId],
    () => (objectWithUserGroupId ? getArtistList(objectWithUserGroupId) : []),
    {
      staleTime: 60000,
      placeholderData: [],
    }
  );

export const getContributorList = ({ user_group_id }) =>
  Party.list('contributor', { user_group_id }).then(resp => resp.data);

export const useGetContributorsList = release =>
  useQuery(['contributors', release], () => (release ? getContributorList(release) : []), {
    staleTime: 60000,
    placeholderData: [],
  });
