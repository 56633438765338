import React from 'react';
import { Link } from 'react-router-dom';
import includes from 'lodash/includes';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { ClipLoader } from 'react-spinners';

import { strokeNotFill } from '../../utils/icons';

import { Label, LabelIcon, LabelText, disableSelection } from '../../features/styles';

const Wrapper = styled.div`
  ${disableSelection}
  display: inline-block;
  position: relative;

  ${props => props.fullWidth && `display: block;`}

  ${props =>
    props.invisible &&
    `
		position: absolute;
		visibility: hidden;
	`}
`;

const LinkWrapper = styled(Link)`
  color: inherit;
  text-decoration: none;

  ${props => props.disabled && `pointer-events: none;`}
`;

const AnchorWrapper = styled.a`
  color: inherit;
  text-decoration: none;

  ${props => props.disabled && `pointer-events: none;`}
`;

const ButtonWrapper = styled.button`
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  transition: width 250ms ease;
  width: 100%;

  ${props => props.disabled && `pointer-events: none;`}

  ${props => props.fullWidth && `width: 100%;`}
`;

export const Activator = styled.span`
  background: ${props => props.theme.colors.background.button[props.colorVariant].default};
  border: 1px solid ${props => props.theme.colors.border.button[props.colorVariant].default};
  border-radius: 6px;
  color: ${props => props.theme.colors.text.button[props.colorVariant].default};
  cursor: pointer;
  display: block;
  font-weight: ${props => props.theme.fonts.weights.regular};
  overflow: hidden;
  position: relative;
  transition: background 250ms ease, border-radius 250ms ease, border 250ms ease, color 250ms ease;

  i path {
    ${props =>
    includes(strokeNotFill, props.icon)
      ? `
			stroke: ${props.theme.colors.text.button[props.colorVariant].default};
			fill: transparent;
		`
      : `
			fill: ${props.theme.colors.text.button[props.colorVariant].default};
		`}
  }

  &:hover {
    background: ${props => props.theme.colors.background.button[props.colorVariant].hover};
    border: 1px solid ${props => props.theme.colors.border.button[props.colorVariant].hover};
    color: ${props => props.theme.colors.text.button[props.colorVariant].hover};

    i path {
      ${props =>
    includes(strokeNotFill, props.icon)
      ? `
				stroke: ${props.theme.colors.text.button[props.colorVariant].hover};
				fill: transparent;
			`
      : `
				fill: ${props.theme.colors.text.button[props.colorVariant].hover};
			`}
    }
  }

  &:active {
    background: ${props => props.theme.colors.background.button[props.colorVariant].active};
    border: 1px solid ${props => props.theme.colors.border.button[props.colorVariant].active};
    color: ${props => props.theme.colors.text.button[props.colorVariant].active};

    i path {
      ${props =>
    includes(strokeNotFill, props.icon)
      ? `
				stroke: ${props.theme.colors.text.button[props.colorVariant].active};
				fill: transparent;
			`
      : `
				fill: ${props.theme.colors.text.button[props.colorVariant].active};
			`}
    }
  }

  ${props =>
    props.loading === 'true' &&
    `
		pointer-events: none;
	`}

  ${props =>
    props.disabled &&
    `
		background: ${props.theme.colors.background.button[props.colorVariant].disabled};
		border: 1px solid ${props.theme.colors.border.button[props.colorVariant].disabled};
		color: ${props.theme.colors.text.button[props.colorVariant].disabled};

		svg {
			${
  includes(strokeNotFill, props.icon)
    ? `
				stroke: ${props.theme.colors.text.button[props.colorVariant].disabled};
				fill: transparent;
			`
          : `
				fill: ${props.theme.colors.text.button[props.colorVariant].disabled};
			`
}
		}
	`}

	${props =>
    props.round &&
    `
		border-radius: 100%;
	`}
`;

const ClickShrowd = styled.span`
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Button = ({
  className,
  icon,
  rightIcon,
  text,
  primary,
  secondary,
  tertiary,
  quaternary,
  short,
  naked,
  fullWidth,
  square,
  round,
  disabled,
  loading,
  style,
  submit,
  dark,
  cta,
  to,
  href,
  target,
  tabIndex,
  heapCode,
  testCode,
  invisible,
  onClick,
  form,
  ...props
}) => {
  const colorVariant = primary
    ? 'primary'
    : secondary
      ? 'secondary'
      : tertiary
        ? 'tertiary'
        : quaternary
          ? 'quaternary'
          : naked
            ? 'naked'
            : dark
              ? 'dark'
              : short
                ? 'short'
                : square
                  ? 'block'
                  : round
                    ? 'block'
                    : 'primary';

  const button = (
    <Activator
      colorVariant={colorVariant}
      icon={icon}
      round={round}
      disabled={disabled}
      style={style}
      loading={loading.toString()}>
      <Label
        button
        className={'buttonLabel'}
        round={round}
        square={square}
        short={short}
        cta={cta}
        loading={loading.toString()}>
        {icon && <LabelIcon type={icon} />}
        {text && <LabelText data-label-text={text}>{text}</LabelText>}
        {rightIcon && <LabelIcon type={rightIcon} right />}
      </Label>
      {loading && (
        <ClipLoader
          color={short ? props.theme.colors.brand.layer0 : props.theme.colors.brand.textWhite}
          cssOverride={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
            height: '20px',
            width: '20px',
          }}
        />
      )}
      <ClickShrowd
        data-heap={heapCode}
        data-cy={global.APP.RUNTIME === 'production' ? undefined : testCode}
        onClick={onClick}
      />
    </Activator>
  );

  return (
    <Wrapper fullWidth={fullWidth} className={className} invisible={invisible}>
      {href ? (
        <AnchorWrapper href={href} target={target}>
          {button}
        </AnchorWrapper>
      ) : to ? (
        <LinkWrapper disabled={disabled || loading} to={to}>
          {button}
        </LinkWrapper>
      ) : (
        <ButtonWrapper
          tabIndex={tabIndex}
          type={submit ? 'submit' : 'button'}
          disabled={disabled || loading}
          fullWidth={fullWidth}
          form={form}>
          {button}
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

Button.displayName = 'button';

Button.defaultProps = {
  icon: null,
  rightIcon: null,
  text: null,
  disabled: false,
  primary: false,
  secondary: false,
  tertiary: false,
  quaternary: false,
  short: false,
  fullWidth: false,
  square: false,
  loading: false,
  round: false,
  submit: false,
  target: '_top',
  tabIndex: 0,
  heapCode: '',
  testCode: '',
  onClick: () => {},
};

export default withTheme(Button);
