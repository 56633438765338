import styled from '@emotion/styled/macro';
import numeral from 'numeral';
import React from 'react';

const ChangeOrderButton = styled.button`
  background-color: transparent;
  border: 0;
  color: ${props => props.theme.colors.brand.layer4};
  opacity: 0.15;
  transition: opacity 250ms ease;

  &:disabled {
    opacity: 0.15 !important;
    pointer-events: none;
  }

  &:hover {
    opacity: 1 !important;
  }

  &:first-child {
    margin-bottom: 4px;
  }
`;

const ControlContainer = styled.div`
  align-items: center;
  color: ${props => props.theme.colors.brand.layer3};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5rem;
  max-width: 3rem;
  width: 3rem;

  > span {
    margin-bottom: 0.5rem;
  }

  &:hover button {
    opacity: 0.5;
  }
`;

function SequenceControls({ onTrackReorder, disabled, sequence, trackId, editable }) {
  return (
    <ControlContainer>
      {editable && (
        <ChangeOrderButton onClick={() => onTrackReorder(trackId, -1)} disabled={disabled}>
          ▲
        </ChangeOrderButton>
      )}
      <span>{numeral(sequence).format('00')}</span>
      {editable && (
        <ChangeOrderButton onClick={() => onTrackReorder(trackId, 1)} disabled={disabled}>
          ▼
        </ChangeOrderButton>
      )}
    </ControlContainer>
  );
}

export default SequenceControls;
