import React, { useRef } from 'react';
import DeleteThreadModal from './delete-thread-modal';
import { useDisclosure, IconButton, Button, Box, HStack, VStack, Flex, Text, Icon } from '@chakra-ui/react';
import CoManagerAIIcon from './co-manager-ai-icon';
import { RiDeleteBin6Line } from 'react-icons/ri';

function ThreadPreviewList({ threadModelCategories, onOpenThread, onDeleteThread }) {
  const deleteRef = useRef();
  const deleteConfirmationDisclosure = useDisclosure();

  return (
    <>
      <DeleteThreadModal
        isOpen={deleteConfirmationDisclosure.isOpen}
        onCancel={deleteConfirmationDisclosure.onClose}
        onConfirm={() => {
          onDeleteThread(deleteRef.current);
          deleteConfirmationDisclosure.onClose();
        }}
      />
      {(Object.keys(threadModelCategories) || []).map(categoryKey => {
        const threadModels = threadModelCategories[categoryKey];

        return (
          <Box key={categoryKey} width="100%">
            <Text pb="1rem" color="gray75">
              {categoryKey}
            </Text>
            {(threadModels || []).map(t => {
              const previewMessage = t?.preview_content?.content || '(No Message Preview Available)';
              return (
                <VStack w="100%" mb="1.5rem" maxWidth="60rem" key={t.chat_bot_thread_id} alignItems="start">
                  <Flex alignItems="center" w="100%">
                    <CoManagerAIIcon style={{ height: '2rem', width: '2rem' }} />
                    <Text
                      ml=".5rem"
                      textWrap="nowrap"
                      lineHeight="1.5rem"
                      fontSize=".875rem"
                      fontWeight="600"
                      color="black.100"
                      overflow="hidden"
                      height="1.5rem"
                      textOverflow="ellipsis">
                      Co-Manager about "{t.summary}"
                    </Text>
                  </Flex>
                  <HStack w="100%" justifyContent="space-between" pl="2.5rem">
                    <Text wordBreak="break-word" mr="1rem" maxHeight="2.5rem" overflow="hidden">
                      {previewMessage}
                    </Text>
                    <Flex alignItems="center" gap="2rem">
                      <Button variant="transparent" color="red" fontWeight="400" onClick={() => onOpenThread(t)}>
                        See More
                      </Button>
                      <IconButton
                        onClick={() => {
                          deleteRef.current = t;
                          deleteConfirmationDisclosure.onOpen();
                        }}
                        borderRadius="25px"
                        variant="tanTransparent"
                        icon={<Icon as={RiDeleteBin6Line} boxSize="1.25rem" color="cream.80" />}
                      />
                    </Flex>
                  </HStack>
                </VStack>
              );
            })}
          </Box>
        );
      })}
    </>
  );
}

export default ThreadPreviewList;
