export const ARTIST = { value: 'Artist', label: 'Artist' };
export const COMPOSER = { value: 'Composer', label: 'Composer' };
export const BAND = { value: 'Band', label: 'Band' };
export const COMPOSER_LYRICIST = { value: 'ComposerLyricist', label: 'Composer Lyricist' };
export const CONTRIBUTOR = { value: 'Contributor', label: 'Contributor' };
export const DIRECTOR = { value: 'Director', label: 'Director' };
export const LYRICIST = { value: 'Lyricist', label: 'Lyricist' };
export const ORIGINAL_PUBLISHER = { value: 'OriginalPublisher', label: 'Original Publisher' };
export const PRIMARY_MUSICIAN = { value: 'PrimaryMusician', label: 'Primary Musician' };
export const PRODUCER = { value: 'Producer', label: 'Producer' };
export const SOLOIST = { value: 'Soloist', label: 'Soloist' };
export const STUDIO_PERSONNEL = { value: 'StudioPersonnel', label: 'Studio Personnel' };
export const FEATURED_ARTIST = { value: 'FeaturedArtist', label: 'Featured Artist' };
export const LABEL = { value: 'Label', label: 'Label' };
export const MAIN_ARTIST = { value: 'MainArtist', label: 'Primary Artist' };
export const MANAGEMENT = { value: 'Management', label: 'Management' };
export const ENGINEER = { value: 'Engineer', label: 'Engineer' };
export const MASTERING_ENGINEER = { value: 'MasteringEngineer', label: 'Mastering Engineer' };
export const MIXING_ENGINEER = { value: 'MixingEngineer', label: 'Mixing Engineer' };
export const REMIXER = { value: 'Remixer', label: 'Remixer' };
export const ACTOR = { value: 'Actor', label: 'Actor' };
export const BASS = { value: 'Bass', label: 'Bass' };
export const BRASS = { value: 'Brass', label: 'Brass' };
export const CHORUS = { value: 'Chorus', label: 'Chorus' };
export const DRUMS = { value: 'Drums', label: 'Drums' };
export const FLUTE = { value: 'Flute', label: 'Flute' };
export const GUITAR = { value: 'Guitar', label: 'Guitar' };
export const HORN = { value: 'Horn', label: 'Horn' };
export const KEYBOARDS = { value: 'Keyboards', label: 'Keyboards' };
export const ORGAN = { value: 'Organ', label: 'Organ' };
export const PIANO = { value: 'Piano', label: 'Piano' };
export const PERCUSSION = { value: 'Percussion', label: 'Percussion' };
export const SAXOPHONE = { value: 'Saxophone', label: 'Saxophone' };
export const STRINGS = { value: 'Strings', label: 'Strings' };
export const TROMBONE = { value: 'Trombone', label: 'Trombone' };
export const TRUMPER = { value: 'Trumpet', label: 'Trumpet' };
export const VOCALS = { value: 'Vocals', label: 'Vocals' };

export const soundRecordingRoles = [
  ACTOR,
  BAND,
  BASS,
  BRASS,
  CHORUS,
  COMPOSER,
  COMPOSER_LYRICIST,
  CONTRIBUTOR,
  DRUMS,
  ENGINEER,
  FEATURED_ARTIST,
  FLUTE,
  GUITAR,
  HORN,
  KEYBOARDS,
  LABEL,
  LYRICIST,
  MAIN_ARTIST,
  MANAGEMENT,
  MASTERING_ENGINEER,
  MIXING_ENGINEER,
  ORGAN,
  PIANO,
  PERCUSSION,
  PRODUCER,
  SAXOPHONE,
  SOLOIST,
  STRINGS,
  STUDIO_PERSONNEL,
  TROMBONE,
  TRUMPER,
  VOCALS,
];

export const creditRoles = [
  ACTOR,
  BAND,
  BASS,
  BRASS,
  CHORUS,
  COMPOSER,
  COMPOSER_LYRICIST,
  CONTRIBUTOR,
  DIRECTOR,
  DRUMS,
  ENGINEER,
  FLUTE,
  GUITAR,
  HORN,
  KEYBOARDS,
  LYRICIST,
  MASTERING_ENGINEER,
  MIXING_ENGINEER,
  ORGAN,
  ORIGINAL_PUBLISHER,
  PERCUSSION,
  PIANO,
  PRODUCER,
  REMIXER,
  SAXOPHONE,
  SOLOIST,
  STRINGS,
  STUDIO_PERSONNEL,
  TROMBONE,
  TRUMPER,
  VOCALS,
];

export const deactivatedRoles = [ARTIST, PRIMARY_MUSICIAN];

export const artistRoles = [MAIN_ARTIST, FEATURED_ARTIST, REMIXER];

export const displayRoles = [...artistRoles].concat(...soundRecordingRoles);

export const labelRoles = new Map([
  ['admin', 'Label Admin'],
  ['member', 'Label Member'],
]);
