import * as React from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import numeral from 'numeral';

function determineFillColor(fillColor, dspName, chakraTheme) {
  if (fillColor) return fillColor;
  if (dspName) return chakraTheme.colors.dsp[dspName];

  return '';
}

export default function DspInfoGroup({ dspName, data, fillColor, color = 'black.100' }) {
  const chakraTheme = useTheme();
  const backgroundColor = determineFillColor(fillColor, dspName, chakraTheme);

  return (
    <Box
      sx={{
        color,
        display: 'grid',
        gridColumnGap: '2',
        alignItems: 'center',
        gridTemplateAreas: `
          "dot name"
          "... count"
        `,
        gridTemplateColumns: 'auto 1fr',
      }}>
      <Box gridArea="dot" backgroundColor={backgroundColor} height={2} width={2} borderRadius="full" />
      <Box gridArea="name" fontSize="xxsm" letterSpacing="1px" textTransform="uppercase" fontWeight="medium">
        {dspName}
      </Box>
      <Box gridArea="count" fontSize="lg" fontWeight="bold">
        {typeof data === 'number' ? numeral(data).format('0,0') : '--'}
      </Box>
    </Box>
  );
}
