import React from 'react';
import { ContentHeader, Title } from 'components/ManageContentBox/manage-content-box';

import HeaderAndContentBox from './clip-review-header-and-content';
import ListItem from './clip-review-list-item';
import { territories } from '../../../../constants/territories';
import { DateTime } from 'luxon';

export default function ReviewAndSubmitDistribution({ stateManager, stateData }) {
  const territoriesText = `${
    territories.length === stateData.territories.length ? 'All' : stateData.territories.length
  } countries selected`;

  const dpsText = `${
    stateData.selectableDsps?.length === stateData.dsp_platforms.length ? 'All' : stateData.dsp_platforms.length
  } DSPs selected`;

  const parsedReleaseDate = DateTime.fromISO(stateData.release_date).setZone('America/New_York', {
    keepLocalTime: true,
  });

  return (
    <HeaderAndContentBox stateManager={stateManager}>
      <ContentHeader>
        <Title>Where your fans will listen to your release</Title>
      </ContentHeader>
      <ListItem title={''} value={dpsText} />
      <ContentHeader>
        <Title>Countries where your release will be available</Title>
      </ContentHeader>
      <ListItem title={''} value={territoriesText} />
      <ContentHeader>
        <Title>When this release will go live</Title>
      </ContentHeader>
      <ListItem title={'Timed Release'} value={stateData.is_timed_release ? 'Yes' : 'No'} />
      <ListItem
        title={'Release Date'}
        value={parsedReleaseDate.toFormat(stateData.is_timed_release ? 'DDD hh:mm a ZZZZ' : 'DDD')}
      />
    </HeaderAndContentBox>
  );
}
