import React, { useMemo, useState, useEffect } from 'react';
import { Box, Input, InputLeftElement, InputGroup, useDisclosure, useTheme, Button } from '@chakra-ui/react';
import { RiSearchLine, RiAddLine } from 'react-icons/ri';
import debounce from 'lodash/debounce';

import AddArtistModal from './add-artist-modal';
import ArtistsTable from './artists-table';
import Section from './section';
import SubSection from './sub-section';
import SectionHeaderBar from './section-header-bar';
import Tooltip from 'components/Tooltip/tooltip';

import HEAP from '../constants/HEAP.gen.json';

const SYSTEM_ADMIN_ADD_ARTIST_TOOLTIP_COPY = 'System Admins can only add artists via the Create Release flow.';

export default function ArtistsTabContainer({ isAdmin, isSystemAdmin }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterTerm, setFilterTerm] = useState('');
  const assignSearchTerm = useMemo(() => debounce(value => setSearchTerm(() => value), 250), []);
  const handleChange = useMemo(
    () => e => {
      const value = e.target.value;
      setFilterTerm(() => value);
    },
    []
  );

  useEffect(() => {
    assignSearchTerm(filterTerm);
  }, [filterTerm]);

  const addArtistModal = useDisclosure();

  const theme = useTheme();

  return (
    <>
      <Section>
        <SubSection>
          <SectionHeaderBar
            name={`
              Artists
          `}>
            <Box display="flex" flexDirection="row">
              <InputGroup mr="2">
                <InputLeftElement pointerEvents="none">
                  <RiSearchLine color={theme.colors.black100} />
                </InputLeftElement>
                <Input
                  rounded="full"
                  borderColor="black100"
                  value={filterTerm}
                  _placeholder={{ color: 'black60' }}
                  placeholder="Filter artists"
                  onChange={handleChange}
                />
              </InputGroup>
              {isAdmin && (
                <Tooltip text={isSystemAdmin && SYSTEM_ADMIN_ADD_ARTIST_TOOLTIP_COPY}>
                  <Button
                    outline
                    variant="smallDark"
                    style={{ padding: '0 1rem', width: '10rem' }}
                    disabled={isSystemAdmin}
                    leftIcon={<RiAddLine />}
                    onClick={addArtistModal.onOpen}
                    heapCode={HEAP.PUBLIC.SETTINGS.TYPE.SETTINGS_BUTTON_ADD_ARTIST}
                    testCode="settings-button-add-payee">
                    Add new artist
                  </Button>
                </Tooltip>
              )}
            </Box>
          </SectionHeaderBar>
          <ArtistsTable searchTerm={searchTerm} />
        </SubSection>
      </Section>
      <AddArtistModal variant="light" disclosure={addArtistModal} />
    </>
  );
}
