import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const Split = () => {
  const byIsrc = isrc_code =>
    AuthAxios.get(`${DataApiUrl}/splits`, {
      params: { isrc_code },
    })
      .then(res => res.data.data)
      .catch(err => {
        throw err;
      });

  const putSplits = splits => AuthAxios.put(`${DataApiUrl}/splits`, { splits });
  const deleteSplits = splits => AuthAxios.delete(`${DataApiUrl}/splits`, { data: { splits } });
  return {
    byIsrc,
    putSplits,
    deleteSplits,
  };
};

export default Split;
