import z from 'zod';

const Title = z.string().min(1);

const SubTitle = z
  .enum([
    'Acapella',
    'Acoustic',
    'Alternative Version',
    'Ao Vivo',
    'Bonus Track',
    'Cover Version',
    'Deluxe',
    'Demo',
    'Edit',
    'Extended Version',
    'Freestyle',
    'Ghost Track',
    'Hidden Track',
    'Instrumental',
    'Karaoke Track',
    'Live',
    'Radio Edit',
    'Remastered',
    'Remix',
    'Silent Track',
  ])
  .optional();

const SoundRecordingRole = z.enum([
  'Artist',
  'Band',
  'Composer',
  'ComposerLyricist',
  'Contributor',
  'Director',
  'Engineer',
  'FeaturedArtist',
  'Lyricist',
  'MainArtist',
  'MasteringEngineer',
  'MixingEngineer',
  'OriginalPublisher',
  'PrimaryMusician',
  'Producer',
  'RemixedArtist',
  'Remixer',
  'Soloist',
  'StudioPersonnel',
]);

const Language = z.enum([
  'en',
  'pt',
  'es',
  'fr',
  'ar',
  'bn',
  'ru',
  'ro',
  'zh-Hans',
  'zh-Hant',
  'yue-Hant',
  'hi',
  'id',
  'ja',
]);

const Artist = z.object({
  asset_party_id: z.string().or(z.number()),
  roles: z.array(SoundRecordingRole).min(1),
});

const Contributor = z.object({
  asset_party_id: z.string().or(z.number()),
  roles: z.array(SoundRecordingRole).min(1),
});

const Lyrics = z.string().min(1).max(5000);
const ISRC = z.string().min(12).max(12);

const Model = {
  Track: z.object({
    title: Title,
    sub_title: SubTitle.optional(),
    artists: z.array(Artist).min(1),
    contributors: z.array(Contributor).optional(),
    language: Language.optional(),
    lyrics: Lyrics.optional(),
    isrc_code: ISRC.optional(),
  }),
};

const ParentalWarning = z.enum(['NotExplicit', 'Explicit', 'ExplicitContentEdited']);

const Genre = z.enum([
  'African',
  'Afrikaans',
  'Afro House',
  'Afro Soul',
  'Afro-Beat',
  'Afro-Pop',
  'Afro-fusion',
  'Alternative',
  'Amapiano',
  'Ambient',
  'Americana',
  'Arabic',
  'Audiobooks',
  'Bluegrass',
  'Blues',
  'Bollywood',
  'Chanukah',
  "Children's Music",
  'Chinese',
  'Christian & Gospel',
  'Christmas',
  'Classical',
  'Comedy',
  'Country',
  'Dance',
  'Devotional & Spiritual',
  'Electronic',
  'Folk',
  'Funk',
  'Garage',
  'Grunge',
  'Hardcore',
  'Hip Hop/Rap',
  'Holiday',
  'House',
  'Indian',
  'Indie Pop',
  'Indie Rock',
  'Inspirational',
  'Instrumental',
  'Jazz',
  'K-Pop',
  'Karaoke',
  'Korean',
  'Latin',
  'Mambo',
  'Neo-Soul',
  'New Age',
  'New Wave',
  'Opera',
  'Original Score',
  'Pop',
  'Punjabi',
  'R&B/Soul',
  'Reggae',
  'Rock',
  'Sing-Along',
  'Soul',
  'Soundtrack',
  'Spoken Word',
  'Techno',
  'Trance',
  'Vocal',
  'World',
]);

const validators = {
  Model,
  Title,
  SubTitle,
  Artist,
  Contributor,
  Language,
  Lyrics,
  ISRC,
  Genre,
  ParentalWarning,
};

export { validators as default };
