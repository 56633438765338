import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';

import Icon from 'components/Icon/icon';
import Tooltip from 'components/Tooltip/tooltip';

import InfoBadge from 'components/InfoBadge/info-badge';

import { displayRoles } from 'constants/roles';

const SummaryItemWrapper = styled.div`
  color: ${props => props.theme.colors.brand.textWhite};
  cursor: pointer;
  display: flex;
  margin-bottom: 0.5rem;
  position: relative;
  width: 100%;

  ${props =>
    (props.disabled || props.readonly) &&
    `
    cursor: default;
    //pointer-events: none;
  `}
`;

const Activator = styled.div`
  display: flex;
`;

const TrackSequence = styled.div`
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin: 0 0.5rem 0 0.5rem;

  ${props =>
    props.invalid &&
    `
    color: ${props.theme.colors.negative};
  `}
`;

const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const EditWrapper = styled.span`
  cursor: pointer;

  i {
    height: 0.75rem;
    width: 0.75rem;
  }

  ${props =>
    props.readonly &&
    `
    cursor: default;
    pointer-events: none;

    i {
      visibility: hidden;
    }
  `}

  ${props =>
    !props.disabled &&
    !props.readonly &&
    `
    &:hover {
      svg path {
        color: ${props.theme.colors.brand.accent};
      }
    }
  `}
`;

const PrimaryLabel = styled.div`
  font-size: ${props => props.theme.fonts.sizes.sm};
  margin-left: 1rem;
  transition: all 250ms ease;
  min-width: 100px;

  ${props =>
    props.invalid &&
    `
    color: ${props.theme.colors.negative};
  `}
`;

const SeconaryLabel = styled.div`
  color: ${props => props.theme.colors.brand.ghost};
  font-weight: ${props => props.theme.fonts.weights.medium};
  transition: all 250ms ease;
`;

const PartyBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const PartyRow = styled.div`
  display: flex;
`;

const PartyLabel = styled.div`
  font-size: ${props => props.theme.fonts.sizes.reg};
  font-weight: ${props => props.theme.fonts.weights.medium};
`;

const InfoBadgeWrapper = styled(InfoBadge)`
  margin: -1px 0 0 0.5rem;
`;

const TrackTitle = styled(PrimaryLabel)`
  font-size: ${props => props.theme.fonts.sizes.reg};
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin: 0;

  ${props =>
    props.invalid &&
    `
    color: ${props.theme.colors.negative};
  `}
`;

const TrackArtist = styled(SeconaryLabel)`
  font-size: ${props => props.theme.fonts.sizes.sm};
  margin: 0.5rem 0 0;

  ${props =>
    props.invalid &&
    `
    color: ${props.theme.colors.negative};
  `}
`;

const Icons = styled.div`
  cursor: default;

  i {
    margin-right: 0.5rem;
    width: 10px;
  }
`;

const TooltipWrapper = styled(Tooltip)`
  margin: 0;
`;

const ClickShrowd = styled.div`
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`;

const SummaryItem = React.forwardRef(
  (
    {
      className,
      disabled,
      onClick,
      primaryText,
      secondaryText,
      error,
      required,
      track,
      parties,
      text,
      incomplete,
      readonly,
      ...props
    },
    ref
  ) => {
    const getDspId = (party, dsp) => (party?.platform_id && party.platform_id[dsp]) || null;

    const handleItemClick = e => {
      if (!readonly) onClick(e);
    };

    return (
      <SummaryItemWrapper className={className} disabled={disabled} onClick={handleItemClick} readonly={readonly}>
        {track && (
          <Activator>
            <EditWrapper disabled={disabled} readonly={readonly}>
              <Icon type="edit" />
            </EditWrapper>
            <InfoBadgeWrapper colors={{ positive: props.theme.colors.brand.textWhite }} incomplete={incomplete} />
            <TrackSequence invalid={!!incomplete}>{track.sequence}</TrackSequence>
            <TrackInfo>
              <TrackTitle invalid={!!incomplete}>{primaryText || '[No title added]'}</TrackTitle>
              <TrackArtist invalid={!!incomplete}>{secondaryText || '[No artist added]'}</TrackArtist>
            </TrackInfo>
          </Activator>
        )}

        {!!parties?.length && (
          <Activator>
            <EditWrapper disabled={disabled} readonly={readonly}>
              <Icon type="edit" />
            </EditWrapper>
            <PrimaryLabel>{primaryText}</PrimaryLabel>
            <PartyBlock>
              {parties.map((party, i) => (
                <PartyRow key={i}>
                  <Icons>
                    {['apple', 'spotify'].map((dsp, i) => (
                      <TooltipWrapper key={i} text={getDspId(party, dsp)}>
                        <Icon
                          type={dsp}
                          color={
                            getDspId(party, dsp) ? props.theme.colors.brand.textWhite : props.theme.colors.brand.layer3
                          }
                        />
                      </TooltipWrapper>
                    ))}
                  </Icons>
                  <PartyLabel>
                    {party.full_name},{' '}
                    {displayRoles.find(item => (party?.roles?.length ? item.value === party?.roles[0] : null))?.label}
                  </PartyLabel>
                </PartyRow>
              ))}
            </PartyBlock>
          </Activator>
        )}

        {!track && !parties?.length && (
          <>
            <Activator>
              <EditWrapper disabled={disabled} readonly={readonly}>
                <Icon type="edit" />
              </EditWrapper>
              <TooltipWrapper text={error}>
                <PrimaryLabel invalid={error}>{primaryText}</PrimaryLabel>
              </TooltipWrapper>
            </Activator>
            <SeconaryLabel>{secondaryText}</SeconaryLabel>
          </>
        )}
        {disabled && <ClickShrowd />}
      </SummaryItemWrapper>
    );
  }
);

SummaryItem.defaultProps = {
  primaryText: '',
  secondaryText: '',
  disabled: false,
  track: null,
  readonly: false,
  required: true,
  parties: null,
  error: null,
  onClick: () => null,
};

SummaryItem.displayName = 'SummaryItem';

export default withTheme(SummaryItem);
