import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Text, Box, Button, Heading, useTheme, useDisclosure, Flex } from '@chakra-ui/react';
import { FiArrowLeft as ArrowLeft } from 'react-icons/fi';
import NextActionBox from 'components/next-action-box/next-action-box';
import { AiFillLock as Lock } from 'react-icons/ai';

import asModal from 'features/Modal/as-modal';
import useCurrentUser from 'data-client/use-current-user.jsx';
import { useGlobalData } from 'utils/global-data';
import NoAccessModal from 'utils/useModals/no-access-modal';
import { useUserTierChecks } from 'hooks/authorization-hooks';

function TypeSelector() {
  const history = useHistory();
  const theme = useTheme();

  const { isCoreTier, isInTrial } = useUserTierChecks();
  const [currentUser] = useCurrentUser();
  const { selectedUserGroup } = useGlobalData();

  const isOwner = selectedUserGroup?.email === currentUser?.email;

  const feedbackVideoModal = useDisclosure();
  const feedbackClipsModal = useDisclosure();

  const videoAccessHeading = 'Want to distribute music videos?';
  const videoAccessText = `Our Essentials membership allows you to distribute your music videos to Vevo, Apple Music, and Tidal, for the best value you'll find anywhere.`;

  const clipsAccessHeading = 'Want to Create Clips?';
  const clipsAccessText = `Easily clip and release multiple versions of your songs to match the latest social trends, whether it's speeding up, slowing down, or highlighting the best parts. Included in the Essentials Membersip.`;

  const upgradeWarningText =
    'You will need to have your membership owner upgrade the account within their member settings to get this benefit.';

  const isOwnerVideoAccessConfig = {
    text: (
      <>
        {videoAccessText}
        <br />
        <br />
        Click below to upgrade now.
      </>
    ),
    confirmButton: {
      text: 'Learn More',
      onClick: () => (location.href = `/settings#membership`),
    },
    showCancelButton: true,
  };

  const isNotOwnerVideoAccessConfig = {
    text: videoAccessText,
    warningText: upgradeWarningText,
    confirmButton: {
      text: 'Got It',
      onClick: () => feedbackVideoModal.onClose(),
    },
    showCancelButton: false,
  };

  const isOwnerClipsAccessConfig = {
    text: (
      <>
        {clipsAccessText}
        <br />
        <br />
        Click below to upgrade now.
      </>
    ),
    confirmButton: {
      text: 'Learn More',
      onClick: () => (location.href = `/settings#membership`),
    },
    showCancelButton: true,
  };

  const isNotOwnerClipsAccessConfig = {
    text: clipsAccessText,
    warningText: upgradeWarningText,
    confirmButton: {
      text: 'Got It',
      onClick: () => feedbackClipsModal.onClose(),
    },
    showCancelButton: false,
  };

  const FeedbackVideoModal = asModal(() => (
    <NoAccessModal
      {...(isOwner ? isOwnerVideoAccessConfig : isNotOwnerVideoAccessConfig)}
      onClose={feedbackVideoModal.onClose}
    />
  ));

  const FeedbackClipsModal = asModal(() => (
    <NoAccessModal
      {...(isOwner ? isOwnerClipsAccessConfig : isNotOwnerClipsAccessConfig)}
      onClose={feedbackClipsModal.onClose}
    />
  ));

  function isHighEnoughToDo(func, modal) {
    if (!isCoreTier) return func();
    return modal.onOpen();
  }

  const onClipsClicked = () => isHighEnoughToDo(() => history.push('/manage/clip/new'), feedbackClipsModal);
  const onVideoClicked = () => isHighEnoughToDo(() => history.push('/manage/video/new'), feedbackVideoModal);

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box width="100%" maxWidth={'37.5rem'}>
        <Box pt="2rem" pb="1rem">
          <Button variant="transparent" leftIcon={<ArrowLeft />} onClick={() => history.goBack()}>
            Back
          </Button>
        </Box>
        <Heading as="h4" color={theme.colors.black80} fontWeight="bold" fontSize="1.15rem">
          What type of release?
        </Heading>
        <Heading as="h5" color={theme.colors.black60} fontWeight="normal" fontSize="0.875rem" mt="0.5rem" mb="3rem">
          Select the release type to continue
        </Heading>

        {isInTrial && (
          <Text
            color="white"
            width="100%"
            backgroundColor="#da7e3c"
            borderRadius="0.3rem"
            p="1rem"
            mt="1rem"
            mb="0.3rem">
            {isOwner ? (
              <>
                During your trial, you may create releases but you will need to upgrade to a full membership to
                distribute them.{' '}
                <Link href="/settings#membership" textDecoration="underline">
                  Upgrade Now
                </Link>
              </>
            ) : (
              <>
                During your trial, you may create releases but you will need to upgrade to a full membership to
                distribute them. Please contact the account owner to upgrade.
              </>
            )}
          </Text>
        )}

        <NextActionBox
          title={'Audio Release'}
          text={'Single, EP, Full Album'}
          onClick={() => history.push('/manage/release/new')}
        />
        <NextActionBox
          title={'Video'}
          text={'Music videos'}
          isLockedText={isCoreTier ? 'Not available in Core' : null}
          onClick={onVideoClicked}
        />

        <NextActionBox
          title={'Clips'}
          isLockedText={isCoreTier ? 'Not available in Core' : null}
          text={
            <>
              Clip sections of tracks to share with social media.
              <br /> (TikTok, Instagram, etc)
            </>
          }
          onClick={onClipsClicked}
        />
      </Box>
      <FeedbackVideoModal
        variant="light"
        noFooter={true}
        headerText={
          <Flex align="center">
            <Lock color={theme.colors.pink} />
            <Box ml="0.5rem">{videoAccessHeading}</Box>
          </Flex>
        }
        isOpen={feedbackVideoModal.isOpen}
        onClose={feedbackVideoModal.onClose}
      />
      <FeedbackClipsModal
        variant="light"
        noFooter={true}
        headerText={
          <Flex align="center">
            <Lock color={theme.colors.pink} />
            <Box ml="0.5rem">{clipsAccessHeading}</Box>
          </Flex>
        }
        isOpen={feedbackClipsModal.isOpen}
        onClose={feedbackClipsModal.onClose}
      />
    </Box>
  );
}

export default TypeSelector;
