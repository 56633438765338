import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CardContextProvider from './card-context';
import useCardFlow from 'utils/use-card-flow';

//Card Components
import StartRelease from './Card/start-release';
import CoreDetails from './Card/core-details';
import ReleaseDetails from './Card/release-details';
import ReleaseArtists from './Card/release-artist';
import UploadCoverArt from './Card/upload-cover-art';
import TrackManager from './Card/track-manager';
import LiveReleaseDate from './Card/live-release-date';
import DeliveryPlatformsAndTerritories from './Card/delivery-platforms-and-territories';
import PriceTierRelease from './Card/price-tier-release';
import PriceTierTrack from './Card/price-tier-track';
import IncludePreOrder from './Card/include-pre-order';
import PreOrderDate from './Card/pre-order-date';
import InstantGratTracks from './Card/instant-grat-tracks';

export const CARDS = {
  StartRelease: 'StartRelease',
  CoreDetails: 'CoreDetails',
  ReleaseArtists: 'ReleaseArtists',
  UploadCoverArt: 'UploadCoverArt',
  Tracklist: 'Tracklist',
  ReleaseDetails: 'ReleaseDetails',
  LiveReleaseDate: 'LiveReleaseDate',
  DeliveryPlatformsAndTerritories: 'DeliveryPlatformsAndTerritories',
  PriceTierRelease: 'PriceTierRelease',
  PriceTierTrack: 'PriceTierTrack',
  IncludePreOrder: 'IncludePreOrder',
  PreOrderDate: 'PreOrderDate',
  InstantGratTracks: 'InstantGratTracks',
};

const CardComponentMap = {
  [CARDS.StartRelease]: StartRelease,
  [CARDS.CoreDetails]: CoreDetails,
  [CARDS.ReleaseArtists]: ReleaseArtists,
  [CARDS.UploadCoverArt]: UploadCoverArt,
  [CARDS.Tracklist]: TrackManager,
  [CARDS.ReleaseDetails]: ReleaseDetails,
  [CARDS.LiveReleaseDate]: LiveReleaseDate,
  [CARDS.DeliveryPlatformsAndTerritories]: DeliveryPlatformsAndTerritories,
  [CARDS.PriceTierRelease]: PriceTierRelease,
  [CARDS.PriceTierTrack]: PriceTierTrack,
  [CARDS.IncludePreOrder]: IncludePreOrder,
  [CARDS.PreOrderDate]: PreOrderDate,
  [CARDS.InstantGratTracks]: InstantGratTracks,
};

//TODO / TECH DEBT: Why is this lin error?
// eslint-disable-next-line react/display-name
const Card = React.memo(({ id, ...props }) => {
  const { match, history } = props;

  const { cardData, onExternalEvent, isReviewMode } = useCardFlow();

  useEffect(() => {
    onExternalEvent({ name: 'REVIEW_MODE_CHANGED', data: { cardData } });
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewMode]);

  const ActiveComponent = CardComponentMap[CARDS[id]];

  return (
    <CardContextProvider id={id} match={match} history={history}>
      <ActiveComponent />
    </CardContextProvider>
  );
});

export default withRouter(Card);
