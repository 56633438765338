import React, { useState, useEffect, useContext, useRef } from 'react';
import union from 'lodash/union';
import ReactHowler from 'react-howler';
import { buildStatus, getBlobDuration } from './helpers';
import * as API from './API/API';

export const AUDIO_EXTENSIONS = ['mp3', 'wav'];
export const VIDEO_EXTENSIONS = ['mp4']; //, 'mpg', 'avi', 'mov', 'm4v']

export const AudioPlayerContext = React.createContext();
export const useAudioPlayer = () => useContext(AudioPlayerContext);
export const AudioPlayerProvider = ({ children }) => {
  const [fileLinks, setFileLinks] = useState({});
  const [fileLinkStatus, setFileLinkStatus] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [mute, setMute] = useState(false);
  const [activeTrackId, setActiveTrackid] = useState(null);

  let refFileLinks = useRef({});
  let refTryGetFiles = useRef({});
  let audio = useRef();

  const activeFileLink = fileLinks[activeTrackId] ? fileLinks[activeTrackId].url : null;
  const totalDuration = fileLinks[activeTrackId] ? fileLinks[activeTrackId].duration : null;

  const Track = API.track();

  useEffect(() => {
    audio.current.volume(volume);
  }, [volume]);

  useEffect(() => {
    audio.current.mute(mute);
  }, [mute]);

  const setFileLinkStatusHandler = (trackId, encoding, status) =>
    setFileLinkStatus(prevState => {
      const newState = { ...prevState };
      if (!newState[trackId]) newState[trackId] = {};
      if (!newState[trackId][encoding]) newState[trackId][encoding] = {};
      newState[trackId][encoding] = buildStatus(status);
      return newState;
    });

  const play = id => {
    if (!id) {
      stop();
      setActiveTrackid(null);
    } else if (activeTrackId !== id) {
      stop();
      setActiveTrackid(id);
      setTimeout(() => setIsPlaying(true), 0); // Wait a tick for the track id to be set
    } else {
      setIsPlaying(true);
    }
  };

  const pause = () => {
    setIsPlaying(false);
  };

  const stop = () => {
    audio.current.stop();
    setIsPlaying(false);
  };

  const getTrackDuration = trackId => (fileLinks[trackId] ? fileLinks[trackId].duration : null);

  const handleHowlerPlay = () => {
    if (fileLinks[activeTrackId] && AUDIO_EXTENSIONS.includes(fileLinks[activeTrackId].encoding)) {
      setIsPlaying(true);
    }
  };

  const handleHowlerPause = () => {
    if (fileLinks[activeTrackId] && AUDIO_EXTENSIONS.includes(fileLinks[activeTrackId].encoding)) {
      setIsPlaying(false);
    }
  };

  const handleHowlerEnd = () => {
    if (fileLinks[activeTrackId] && AUDIO_EXTENSIONS.includes(fileLinks[activeTrackId].encoding)) {
      setIsPlaying(false);
    }
  };

  const Howler = (
    <ReactHowler
      src={[activeFileLink]}
      playing={isPlaying && fileLinks[activeTrackId] && AUDIO_EXTENSIONS.includes(fileLinks[activeTrackId].encoding)}
      onPlay={() => handleHowlerPlay()}
      onPause={() => handleHowlerPause()}
      onEnd={() => handleHowlerEnd()}
      format={['mp3']}
      ref={audio}
      autoplay={false}
    />
  );

  // Limit to a single file per extension
  const addFileLink = (trackId, url, duration, encoding) => {
    if (!refFileLinks.current[trackId] || refFileLinks.current[trackId].indexOf(encoding) === -1) {
      const encodings = union(refFileLinks.current[trackId] || [], [encoding]);

      refFileLinks.current = { ...refFileLinks.current, [trackId]: encodings };
      setFileLinks(prevState => ({
        ...prevState,
        [trackId]: { url, duration, encoding },
      }));
    }
  };

  // Returns true if every status in fileLinkStatus[trackId] has an error or fileLinkStatus[trackId] doesn't exist
  const noFileFound = trackId =>
    fileLinkStatus[trackId]
      ? Object.keys(fileLinkStatus[trackId]).reduce(
        (a, encoding) =>
          a && !fileLinkStatus[trackId][encoding].inProgress && !!fileLinkStatus[trackId][encoding].error,
        true
      )
      : true;

  // Returns true if any status in fileLinkStatus[trackId] is inProgress, complete, or errored out
  const isFileRequestBlocked = trackId =>
    trackId && fileLinkStatus[trackId]
      ? Object.keys(fileLinkStatus[trackId]).reduce(
        (a, encoding) =>
          a ||
            fileLinkStatus[trackId][encoding].inProgress ||
            fileLinkStatus[trackId][encoding].complete ||
            !!fileLinkStatus[trackId][encoding].error,
        false
      )
      : false;

  const tryGetTrackFiles = trackId => {
    VIDEO_EXTENSIONS.forEach(extension => tryGetTrackFile(trackId, extension));
    AUDIO_EXTENSIONS.forEach(extension => tryGetTrackFile(trackId, extension));
  };

  const resetFileLinkStatus = trackId => {
    if (fileLinkStatus[trackId]) {
      Object.keys(fileLinkStatus[trackId]).forEach(key => {
        fileLinkStatus[trackId][key] = buildStatus(null);
      });
    }
  };

  const tryGetTrackFile = async (trackId, encoding, force) => {
    if (!trackId || isFileRequestBlocked(trackId) || force) {
      return;
    }

    setFileLinkStatusHandler(trackId, encoding, 'inProgress');
    const res = await Track.file(trackId, encoding); // TODO: BE is ignoring extension, returning e.g. 3 x WAVs
    if (!res) {
      setFileLinkStatusHandler(trackId, encoding, 'File for track not found');
      return;
    }

    // If res.encoding is new
    if (!refTryGetFiles.current[trackId] || refTryGetFiles.current[trackId].indexOf(res.encoding) === -1) {
      // Add requested encoding to tracked list
      const encodings = union(refTryGetFiles.current[trackId] || [], [res.encoding]);
      refTryGetFiles.current = {
        ...refTryGetFiles.current,
        [trackId]: encodings,
      };

      const blob = new Blob([res.data], { type: `audio/${res.encoding}` });
      const url = window.URL.createObjectURL(blob);

      const duration = await getBlobDuration(blob);

      addFileLink(trackId, url, duration, res.encoding);
      setFileLinkStatusHandler(trackId, res.encoding, 'complete');

      // TODO: Encoding list should be cleared upon new track file upload
    }
  };

  return (
    <AudioPlayerContext.Provider
      value={{
        play,
        pause,
        stop,
        fileLinks,
        fileLinkStatus,
        resetFileLinkStatus,
        addFileLink,
        isPlaying,
        setIsPlaying,
        totalDuration,
        audio,
        mute,
        setMute,
        volume,
        setVolume,
        activeTrackId,
        setActiveTrackid,
        getTrackDuration,
        tryGetTrackFiles,
        setFileLinkStatusHandler,
        isFileRequestBlocked,
        noFileFound,
      }}>
      {Howler}
      {children}
    </AudioPlayerContext.Provider>
  );
};
