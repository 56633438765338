import React from 'react';
import { Avatar, Box, Stack, Td, useTheme } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled/macro';
import numeral from 'numeral';
import getImageUrlWithFallback from 'analytics/get-image-url-with-fallback';
import { determineShouldShowToTForTrack } from 'analytics/determine-should-show-tot-for-track';

const ArtistAvatar = styled(Avatar)`
  margin: 0 1.5rem;
`;

const NegativeTrend = styled.span`
  color: ${props => props.theme.colors.red['400']};
`;
const PositiveTrend = styled.span`
  color: ${props => props.theme.colors.green['400']};
`;

const NeutralTrend = styled.span``;
const ArtistButton = styled(ChevronRightIcon)`
  cursor: pointer;
`;

export function ArtistsCell({ rowData, ...props }) {
  const full_name = rowData?.full_name;
  const image_url = rowData?.image_url;
  return (
    <Td {...props}>
      <Stack display="inline-flex" direction="row" align={'center'}>
        <ArtistAvatar
          size="sm"
          icon={<div />} // override default profile icon
          src={getImageUrlWithFallback(image_url, 'small')}
        />
        <Box isTruncated maxW={500}>
          {full_name}
        </Box>
      </Stack>
    </Td>
  );
}

export function TrendCell({ context: { currentMetricsInterval }, rowData: { time_over_time, release_date } }) {
  const shouldShowTotStat = determineShouldShowToTForTrack(release_date);
  const chakraTheme = useTheme();

  const timeRangeInDays = currentMetricsInterval.interval + 1;

  const subset = timeRangeInDays < 30 ? 'WoW' : timeRangeInDays >= 30 && timeRangeInDays < 90 ? 'MoM' : '';

  const value = Number(Number.parseFloat(time_over_time) * 100).toFixed(2);
  const totNum = Number(time_over_time);

  return (
    <Td>
      {
        /*shouldShowTotStat ? (
        <>
          {totNum === 0 && <NeutralTrend theme={chakraTheme}>&mdash;</NeutralTrend>}
          {totNum < 0 && (
            <NegativeTrend theme={chakraTheme}>{`${!isNaN(value) && value ? value : ' - '}%`}</NegativeTrend>
          )}
          {totNum > 0 && (
            <PositiveTrend theme={chakraTheme}>{`${!isNaN(value) && value ? value : ' - '}%`}</PositiveTrend>
          )}
          {totNum !== 0 && <Subset> {subset}</Subset>}
        </>
      ) : (
        <NeutralTrend theme={chakraTheme}>&mdash;</NeutralTrend>) TODO reenable all this when mom/wow metrics are back*/
        <NeutralTrend theme={chakraTheme}></NeutralTrend>
      }
    </Td>
  );
}

export function StreamsCell({ context: { currentMetricsInterval }, rowData: { streams } }) {
  const timeRangeInDays = currentMetricsInterval.interval;
  return (
    <Td>
      {numeral(streams).format('0,0')}{' '}
      <Box as="span" color="black.60" fontSize="0.6rem" fontWeight="bold">
        Last {timeRangeInDays} days
      </Box>
    </Td>
  );
}

export function GotoArtistCell() {
  return (
    <Td>
      <Box textAlign="right">
        <ArtistButton w={10} h={10} />
      </Box>
    </Td>
  );
}
