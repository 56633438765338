import React from 'react';
import styled from '@emotion/styled/macro';
import Icon from '../../components/Icon/icon';
import { renderFieldError } from '../../utils/form';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Input = styled.input`
  appearance: none;
  background-color: ${props => props.theme.colors.inputBackground};
  border: 1px solid transparent;
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${props => (props.disabled ? props.theme.colors.disabled : 'inherit')};
  font-size: ${props => props.theme.fonts.sizes.reg};
  height: 3rem;
  padding: 1rem;
  width: 100%;

  ${props =>
    props.icon &&
    `
    padding-left: 1.6rem;
  `}

  ${props =>
    props.invalid &&
    `
    border-color: ${props.theme.colors.negative};;
    outline-color: ${props.theme.colors.negative};;
  `}

  ${props =>
    props.outline &&
    `
    background-color: transparent;
    border: 1px solid ${props.theme.colors.inputBackground};
  `}

  ${props =>
    props.prePopulated &&
    `
    background-color: ${props.theme.colors.prePopulated}10;
    border-color: ${props.theme.colors.prePopulated}50;
  `}

  ${props =>
    props.disabled &&
    `
    background-color: transparent;
    border-color: ${props.theme.colors.brand.layer2};
    color: ${props.theme.colors.brand.layer4};
  `}

  ${props =>
    props.themeVariant === 'light' &&
    `
    background-color: ${props.theme.colors.brand.layer3};
    color: ${props.theme.colors.brand.textWhite};


    ${
      props.disabled &&
      `
      background-color: transparent;
      color: ${props.theme.colors.brand.layer3};
      border-color: ${props.theme.colors.brand.layer3};
    `
    }
  `}
`;

const IconWrapper = styled(Icon)`
  margin-left: 9px;
  margin-top: 13px;
  position: absolute;
  width: 0.8rem;
`;

function TextField({
  name,
  className,
  icon,
  type = 'text',
  onKeyPress,
  onKeyUp,
  prePopulated,
  error,
  doShowError,
  autoFocus,
  autoComplete,
  ...props
}) {
  return (
    <Container>
      <Input
        key={`input-${name}`}
        className={className}
        icon={icon}
        name={name}
        invalid={error}
        type={type}
        onKeyPress={onKeyPress ? e => onKeyPress(e, name) : null}
        onKeyUp={onKeyUp ? e => onKeyUp(e) : null}
        prePopulated={prePopulated}
        autoFocus={autoFocus}
        themeVariant={props.themeVariant}
        autoComplete={autoComplete || 'on'}
        {...props}
      />
      {icon && <IconWrapper type={icon} />}
      {doShowError && error && renderFieldError(error)}
    </Container>
  );
}

TextField.defaultProps = {
  type: 'default',
  icon: null,
  placeholder: '',
  disabled: false,
  isValid: true,
  name: 'input',
  doShowError: true,
};

export default TextField;
