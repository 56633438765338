import React, { useState } from 'react';
import { useMutation } from 'react-query';

import Input from 'components/Input/input';
import TextField from 'components/TextField/text-field';
import * as API from 'utils/API/API';

import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
const Group = API.group();

function ChangeCompanyNameModal({ isOpen, onClose, group, theme, refetchUser }) {
  const [formValue, setFormValue] = useState(group?.name);

  const updateLabelNameMutation = useMutation(() => Group.updateGroup(group.id, { name: formValue }), {
    onError: err => {
      console.error(err);
    },
    onSuccess: () => {
      refetchUser();
    },
    onSettled: () => {
      onClose();
    },
  });

  function onChange({ target: { value } }) {
    setFormValue(value);
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Company name</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mt="4">
            <Input variant="light" title="Name">
              <TextField
                name="name"
                placeholder="Company Name"
                id="name"
                required={true}
                defaultValue={group?.name}
                onChange={onChange}
              />
            </Input>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="small" onClick={onClose} style={{ marginRight: theme.space[3] }}>
            Cancel
          </Button>
          <Button variant="smallDark" onClick={updateLabelNameMutation.mutate}>
            Save changes
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
export default ChangeCompanyNameModal;
