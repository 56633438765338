import React, { memo, useMemo, useState, useRef, useEffect } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { getMediaMetadata } from 'utils/metadata';
import { useDisclosure, Text, useToast } from '@chakra-ui/react';

import ManageContainer, { BodyContainer, LeftRailContainer, SaveController } from '../manage-containers';

import { useCreateRelease, useUpdateRelease, useGetRelease } from 'data-client/use-releases';

import ClipSetupState from './pages/clip-setup-state';
import ClipDistributionState from './pages/clip-distribution-state';
import ClipReleaseInfoState from './pages/clip-release-info-state';
import CreateClipsState from './pages/clip-create-state';
import ClipTrackDetailsState from './pages/clip-track-details-state';
import ClipReviewAndSubmitState from './pages/clip-reivew-and-submit/clip-review-and-submit-state';

import ClipCreate from './pages/clip-create';
import ClipSetup from './pages/clip-setup';
import ClipReleaseInfo from './pages/clip-release-info';
import ClipDistribution from './pages/clip-distribution';
import ClipTrackDetails from './pages/clip-track-details';
import ClipConfirmation from './pages/clip-confirmation';
import ClipReviewAndSubmit from './pages/clip-reivew-and-submit/clip-review-and-submit';

import ClipLeftRail from './clip-left-rail';

import LeftRailStateCollection from './clip-left-rail-state-collection';
import StateManagerCollection from '../../state-management/state-manager-collection';

import CardLoadingOverlay from '../../components/loading-containers/card-loading-overlay';

import useNavigateAwayCatcher from './useNavigateAwayCatcher';
import ClipStateCollection from '../../features/ClipEditor/clip-state-collection';
import ClipStateManager from '../../features/ClipEditor/clip-state-manager';
import useCurrentUser from '../../data-client/use-current-user';

import { useQuery, useMutation } from 'react-query';
import * as API from 'utils/API/API';
import ConfirmExitCreateClips from '../../utils/useModals/confirm-exit-create-clips.js';
import SubmittedReleaseConfirmExitModal from '../../utils/useModals/submitted-release-confirm-exit.js';
import asModal from 'features/Modal/as-modal';

const Release = API.release();
const Track = API.track();
const DSP = API.dsp();

const MissingTitleModal = asModal(function MissingTitle() {
  return (
    <Text margin={'2rem 0 1rem 0'} color="black">
      A Release Title on the Setup page must be added prior to saving
    </Text>
  );
});

const InvalidAudioSourceModal = asModal(function InvalidAudioSource() {
  return (
    <Text margin={'2rem 0 1rem 0'} color="black">
      This track isn't long enough to generate clips from. Please retry with a track that is at least 60 seconds.
    </Text>
  );
});

export const MANAGE_CLIPS_VIEWS = {
  SETUP: 'ClipSetup',
  CREATE: 'ClipCreate',
  DISTRIBUTION: 'ClipDistribution',
  RELEASE_INFO: 'ClipReleaseInfo',
  TRACK_DETAILS: 'ClipTrackDetails',
  REVIEW: 'ClipReview',
};

function ClipRouteContainer({ loadedData = { type: 'Clip' } }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateData, setStateData] = useState(loadedData);
  const [isDraftSaved, setIsDraftSaved] = useState(true);
  const [lastSaved, setLastSaved] = useState('');
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const missingTitleModal = useDisclosure();
  const invalidAudioSourceModal = useDisclosure();

  const toast = useToast();

  useEffect(() => {
    const checkAudioSource = async audioSource => {
      const metadata = await getMediaMetadata(audioSource);
      //If we can't get the duration locally, then we have to assume we cannot move forward with the release.
      const durationInSeconds = metadata.general?.Duration ? Number.parseFloat(metadata.general.Duration) : 0;
      if (durationInSeconds < 60) {
        invalidAudioSourceModal.onOpen();
      }
    };

    if (loadedData.audio_source) {
      checkAudioSource(loadedData.audio_source);
    }
  }, [loadedData.audio_source]);

  const softDeleteQuery = useMutation([id, 'softDelete'], () => Release.softDelete(id), {
    onSuccess: () => {
      exitWithoutSavingModal.onClose();
      history.push('/search/releases');
    },
  });

  const { mutateAsync: updateRelease, isLoading: isUpdatingRelease } = useUpdateRelease({
    onSuccess: data => {
      setStateData(current => {
        const clips = buildDefaultClipsStateCollection(data, current.audio_source);
        const metaState = buildDefaultMetastate(data);

        return { ...data, clips, metaState, cover_art: current.cover_art, audio_source: current.audio_source };
      });

      setIsDraftSaved(true);
      setLastSaved(new Date());
    },
  });

  //TODO WHEN LOADING FROM BACKEND SET TAINTED TO TRUE IF BE DATA EXISTS
  const taintedViewsRef = useRef({
    [MANAGE_CLIPS_VIEWS.SETUP]: false,
    [MANAGE_CLIPS_VIEWS.CREATE]: false,
    [MANAGE_CLIPS_VIEWS.DISTRIBUTION]: false,
    [MANAGE_CLIPS_VIEWS.RELEASE_INFO]: false,
    [MANAGE_CLIPS_VIEWS.TRACK_DETAILS]: false,
  });

  const getSaveStateFuncForView = view => {
    return function saveState(data) {
      taintedViewsRef.current[view] = true;
      setStateData(currentState => {
        //File has changed wipe out clips
        if (data.audio_source && currentState.audio_source !== data.audio_source) {
          data.clips = new ClipStateCollection();
        }
        return { ...currentState, ...data };
      });
      setIsDraftSaved(false);
    };
  };

  useNavigateAwayCatcher(isDraftSaved);
  let setupState,
    createClipState,
    distributionState,
    releaseInfoState,
    trackDetailsState,
    reviewAndSubmitState,
    leftRailStateCollection,
    reviewStateCollection;

  try {
    setupState = new ClipSetupState({
      stateData,
      name: MANAGE_CLIPS_VIEWS.SETUP,
      saveState: getSaveStateFuncForView(MANAGE_CLIPS_VIEWS.SETUP),
      currentPath: location.pathname,
      tainted: loadedData.user_last_touched || taintedViewsRef.current[MANAGE_CLIPS_VIEWS.SETUP],
    });

    releaseInfoState = new ClipReleaseInfoState({
      stateData,
      name: MANAGE_CLIPS_VIEWS.RELEASE_INFO,
      saveState: getSaveStateFuncForView(MANAGE_CLIPS_VIEWS.RELEASE_INFO),
      currentPath: location.pathname,
      tainted: loadedData.user_last_touched || taintedViewsRef.current[MANAGE_CLIPS_VIEWS.RELEASE_INFO],
    });

    createClipState = new CreateClipsState({
      stateData,
      name: MANAGE_CLIPS_VIEWS.CREATE,
      saveState: getSaveStateFuncForView(MANAGE_CLIPS_VIEWS.CREATE),
      currentPath: location.pathname,
      tainted: loadedData.user_last_touched || taintedViewsRef.current[MANAGE_CLIPS_VIEWS.CREATE],
    });

    distributionState = new ClipDistributionState({
      stateData,
      name: MANAGE_CLIPS_VIEWS.DISTRIBUTION,
      saveState: getSaveStateFuncForView(MANAGE_CLIPS_VIEWS.DISTRIBUTION),
      currentPath: location.pathname,
      tainted: loadedData.user_last_touched || taintedViewsRef.current[MANAGE_CLIPS_VIEWS.DISTRIBUTION],
    });

    trackDetailsState = new ClipTrackDetailsState({
      stateData,
      name: MANAGE_CLIPS_VIEWS.TRACK_DETAILS,
      saveState: getSaveStateFuncForView(MANAGE_CLIPS_VIEWS.TRACK_DETAILS),
      currentPath: location.pathname,
      tainted: loadedData.user_last_touched || taintedViewsRef.current[MANAGE_CLIPS_VIEWS.TRACK_DETAILS],
    });

    //For routing state machine in LeftRailStateCollection and possible args in the future
    reviewAndSubmitState = new ClipReviewAndSubmitState({
      stateData,
      name: MANAGE_CLIPS_VIEWS.REVIEW,
      saveState: () => {},
      currentPath: location.pathname,
      tainted: false,
      hideDirectNavigation: true,
    });

    leftRailStateCollection = new LeftRailStateCollection(
      setupState,
      createClipState,
      releaseInfoState,
      distributionState,
      trackDetailsState,
      reviewAndSubmitState
    );

    reviewStateCollection = new StateManagerCollection(
      setupState,
      createClipState,
      releaseInfoState,
      distributionState,
      trackDetailsState
    );
  } catch (e) {
    console.error(e);
    throw e;
  }

  const exitWithoutSavingModal = useDisclosure();

  async function updateReleaseToState() {
    //parsing out upc
    const { asset_release_upc: _notSending, ...body } = stateData;
    try {
      await updateRelease(body);
      return { success: true };
    } catch (error) {
      toast({
        status: 'error',
        title: 'Draft was not saved.',
        description: error?.response?.data?.detail,
        duration: 3000,
      });
      return { success: false };
    }
  }

  async function saveDraft() {
    if (!stateData.title) {
      missingTitleModal.onOpen();
      return false;
    }
    await updateReleaseToState();
  }

  const onConfirmExitCreateClips = () => {
    if (!loadedData.title && !lastSaved) {
      return softDeleteQuery.mutate();
    }
    exitWithoutSavingModal.onClose();
    history.push('/search/releases');
  };

  async function onExit() {
    if (!isDraftSaved) {
      exitWithoutSavingModal.onOpen();
      return;
    }
    onConfirmExitCreateClips();
  }

  async function submitForDistribution() {
    let succeeded = false;

    try {
      setIsSubmitting(true);
      const { success } = await updateReleaseToState();
      if (!success) return; //prevents double toasting.

      await Release.publish(stateData.asset_release_id, stateData.dsp_platforms);
      succeeded = true;
    } catch (err) {
      const error = Array.isArray(err) ? err[0]?.meta : 'Error submitting the release';
      console.error(error);

      return toast({
        status: 'error',
        title: 'Error submitting the release.',
        description: err?.response?.data?.detail,
        duration: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }

    if (succeeded) {
      history.push(`/manage/clip/confirmation?id=${id}`);
    }
  }

  function getLeftRailProps() {
    const { cover_art, display_artist_name, title, clips, audio_source, asset_release_id } = stateData;
    const {
      ClipCreate: createClipsState,
      ClipTrackDetails: trackDetailsState,
      ...otherManagers
    } = leftRailStateCollection.props;

    return {
      cover_art,
      display_artist_name,
      title,
      clips,
      audio_source,
      asset_release_id,
      createClipsState,
      trackDetailsState,
      pageStates: Object.values(otherManagers),
    };
  }

  const isLoading = isUpdatingRelease || isSubmitting;
  const hideLeftRail = location.pathname.includes('confirmation') || location.pathname.includes('review');
  const canSubmit = leftRailStateCollection.filter(manager => !manager.allValid).length === 0;

  return (
    <>
      <ManageContainer hideLeftRail={hideLeftRail}>
        <LeftRailContainer title={'Create Clips'} onExit={onExit}>
          <ClipLeftRail {...getLeftRailProps()} />
          <SaveController
            onSaveDraft={saveDraft}
            canSaveDrafts={!stateData?.latest_submission_queue_id}
            isDraftSaved={isDraftSaved}
            lastSaved={lastSaved}
            canSubmit={canSubmit}
            onReview={() => history.push(`/manage/clip/review?id=${id}`)}
          />
        </LeftRailContainer>

        <BodyContainer fullWidth={hideLeftRail ? true : false} stateManagerCollection={leftRailStateCollection}>
          <Route path={setupState.path}>
            <ClipSetup
              {...setupState.props}
              hasBeenSubmitted={stateData?.latest_submission_queue_id}
              saveState={setupState.getSaveHandler()}
              doValidateField={setupState.validateField.bind(setupState)}
              tainted={setupState.tainted}
            />
          </Route>

          <Route path={releaseInfoState.path}>
            <ClipReleaseInfo
              {...releaseInfoState.props}
              releaseGroupId={stateData.user_group_id}
              hasBeenSubmitted={stateData?.latest_submission_queue_id}
              saveState={releaseInfoState.getSaveHandler()}
              doValidateField={releaseInfoState.validateField.bind(releaseInfoState)}
              tainted={releaseInfoState.tainted}
            />
          </Route>

          <Route path={distributionState.path}>
            <ClipDistribution
              {...distributionState.props}
              stateManager={distributionState}
              hasBeenSubmitted={stateData?.latest_submission_queue_id}
              saveState={distributionState.getSaveHandler()}
              doValidateField={distributionState.validateField.bind(distributionState)}
              tainted={distributionState.tainted}
            />
          </Route>

          <Route path={createClipState.path}>
            <ClipCreate stateManager={createClipState} hasBeenSubmitted={stateData?.latest_submission_queue_id} />
          </Route>

          <Route path={trackDetailsState.path}>
            <ClipTrackDetails
              stateManager={trackDetailsState}
              {...trackDetailsState.props}
              releaseGroupId={stateData.user_group_id}
              hasBeenSubmitted={stateData?.latest_submission_queue_id}
              saveState={trackDetailsState.getSaveHandler()}
              doValidateField={trackDetailsState.validateField.bind(trackDetailsState)}
              tainted={trackDetailsState.tainted}
            />
          </Route>

          <Route path={'/manage/clip/review'}>
            <ClipReviewAndSubmit
              stateManager={reviewAndSubmitState}
              reviewStateCollection={reviewStateCollection}
              submitForDistribution={submitForDistribution}
              releaseGroupId={stateData.user_group_id}
            />
          </Route>

          <Route path={'/manage/clip/confirmation'}>
            <ClipConfirmation confirmationState={reviewAndSubmitState} />
          </Route>

          <Route
            exact
            path={'/manage/clip'}
            render={() => (
              <Redirect
                to={`${setupState.path}${loadedData.asset_release_id ? `?id=${loadedData.asset_release_id}` : ''}`}
              />
            )}
          />
        </BodyContainer>
      </ManageContainer>
      {isLoading ? (
        <CardLoadingOverlay
          text={isSubmitting ? 'Submitting Release' : 'Saving Draft'}
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        />
      ) : (
        ''
      )}
      <MissingTitleModal
        variant="light"
        hideCancel={true}
        headerText="Release title required"
        isOpen={missingTitleModal.isOpen}
        onClose={missingTitleModal.onClose}
        onSubmit={missingTitleModal.onClose}
        submitText="OK"
      />
      <InvalidAudioSourceModal
        variant="light"
        hideCancel={true}
        hideClose={true}
        headerText="Track too short"
        isOpen={invalidAudioSourceModal.isOpen}
        onSubmit={() => softDeleteQuery.mutate()}
        submitText="OK"
      />
      <ConfirmExitCreateClips
        onConfirm={onConfirmExitCreateClips}
        onCancel={exitWithoutSavingModal.onClose}
        isShowing={exitWithoutSavingModal.isOpen && !stateData.latest_submission_queue_id}
      />
      <SubmittedReleaseConfirmExitModal
        onConfirm={onConfirmExitCreateClips}
        onCancel={exitWithoutSavingModal.onClose}
        isShowing={exitWithoutSavingModal.isOpen && stateData.latest_submission_queue_id}
      />
    </>
  );
}

function LoadingReleaseWrapper() {
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');
  const history = useHistory();

  const [user] = useCurrentUser();

  const { mutate: createRelease, isLoading: isCreatingRelease } = useCreateRelease({
    onSuccess: data => {
      history.push(`/manage/clip/setup?id=${data.asset_release_id}`);
    },
  });

  const {
    data,
    isLoading: isLoadingRelease,
    isError: releaseGetErrored,
  } = useGetRelease(id, {
    enabled: !!id,
    cacheTime: 0,
    staleTime: Infinity,
  });

  const { data: selectableDsps = [], isLoading: isLoadingDsps } = useQuery(
    [`clipDspList`, data?.asset_release_id],
    () => DSP.getAll(),
    {
      enabled: !!data?.asset_release_id,
      cacheTime: 0,
      staleTime: Infinity,
      select: dspArray => dspArray.filter(dsp => dsp.clip_support),
    }
  );

  const { data: cover_art, isLoading: isLoadingCoverArt } = useQuery(
    [`clipReleaseArtwork`, data?.asset_release_id],
    () => Release.defaultArtwork(data.asset_release_id, '250x250'),
    {
      enabled: !!data?.asset_release_id,
      cacheTime: 0,
      staleTime: Infinity,
      select: coverArt => {
        if (!coverArt) return;
        return new Blob([coverArt]);
      },
    }
  );

  const { data: audio_source, isLoading: isLoadingAudioSource } = useQuery(
    [`clipReleaseAudioSource`, data?.track?.asset_track_id],
    async () => {
      const track_id = data?.track?.asset_track_id;
      //relies on the 0th asset being the latest, we need the original file name
      //because the signed url handler pops the file extension off of the end.
      const file_name = data?.track?.assets?.[0]?.original_file_name;
      const audioSource = file_name ? await Track.getFileFromSignedUrl(track_id, file_name) : undefined;

      if (!audioSource || !audioSource.data) return;

      const audioFile = new File(
        [new Blob([audioSource.data])],
        data?.track?.assets?.[0].original_file_name || data?.track?.title || 'track 1'
      );
      return audioFile;
    },
    {
      enabled: !!data?.track?.asset_track_id,
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (id || !user) return;
    const user_group_id = user.currentGroup?.user_group_id;
    createRelease({ type: 'Clip', user_group_id });
  }, [id, user]);

  const loadedData = useMemo(() => {
    if (releaseGetErrored) {
      return history.push('/search/releases');
    }

    if (!data) {
      return;
    }
    //setup defaults
    const clips = buildDefaultClipsStateCollection(data, audio_source);
    const metaState = buildDefaultMetastate(data);
    const dsp_platforms = data?.dsp_platforms ? data.dsp_platforms : selectableDsps.map(dsp => dsp.identifier);

    return { ...data, clips, metaState, dsp_platforms, audio_source, cover_art, selectableDsps };
  }, [data, audio_source, cover_art, selectableDsps]);

  if (
    !loadedData ||
    isLoadingRelease ||
    isCreatingRelease ||
    isLoadingCoverArt ||
    isLoadingAudioSource ||
    isLoadingDsps
  )
    return <CardLoadingOverlay text={'Loading Release'} style={{ backgroundColor: 'rgba(0,0,0,0.5)' }} />;

  return <ClipRouteContainer loadedData={loadedData} />;
}

function buildDefaultMetastate(releaseData) {
  //These args should be null/undefined if they are not set by a user
  //or '' if they are set but not filled in.
  const { isrc_code = null, lyrics = null } = releaseData?.track || {}; //we should only have a single source track on clips

  return {
    isrc_code: isrc_code !== null,
    lyrics: lyrics !== null,
  };
}

function buildDefaultClipsStateCollection(releaseData, audioSource) {
  const clipStateCollection = new ClipStateCollection();
  const clipTracks = audioSource ? releaseData?.tracks : [];

  clipTracks.forEach((track, index) => {
    const { start_time_seconds = 0, end_time_seconds = 60, playback_rate = 1 } = track.clip_attributes;

    const clipStateManager = new ClipStateManager(
      {
        stateData: {
          clipName: track.title,
          startTimeSeconds: start_time_seconds,
          endTimeSeconds: end_time_seconds,
          speedPercent: playback_rate * 100,
          file: audioSource,
        },
      },
      `${releaseData.title}-${index}`,
      null,
      clipTracks.length
    );
    clipStateCollection.push(clipStateManager);
  });
  return clipStateCollection;
}
export default memo(LoadingReleaseWrapper);
