import React from 'react';
import { Flex, IconButton, Box } from '@chakra-ui/react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

function TablePaginationFooter({ onPrevPage, isPrevPageDisabled, page, totalPages, onNextPage, isNextPageDisabled }) {
  return (
    <Flex alignItems="center" justifyContent="center" mt="1">
      <IconButton
        variant="ghost"
        size="sm"
        onClick={onPrevPage}
        disabled={isPrevPageDisabled}
        width="2.25rem"
        borderColor="black50"
        color="black100"
        icon={<RiArrowLeftSLine />}
      />
      <Box mx="2" color="black100" fontWeight="medium">
        {page + 1} of {totalPages}
      </Box>
      <IconButton
        variant="ghost"
        size="sm"
        onClick={onNextPage}
        disabled={isNextPageDisabled}
        width="2.25rem"
        borderColor="black50"
        color="black100"
        icon={<RiArrowRightSLine />}
      />
    </Flex>
  );
}

export default TablePaginationFooter;
