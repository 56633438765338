import React from 'react';
import { Box, Heading, Button } from '@chakra-ui/react';
import ManageContentBox from 'components/ManageContentBox/manage-content-box';
import { useHistory } from 'react-router-dom';

export default function HeaderAndContentBox({ stateManager, title, children }) {
  title = title || stateManager.title; //sometimes we use the state manager's title sometimes we use something else.
  const history = useHistory();

  const { path } = stateManager;
  const params = new URLSearchParams(window.location.search);
  const id = params.get('id');

  return (
    <Box marginTop="1.5rem">
      <Heading as="h3" fontSize={'1.15rem'}>
        {title}
      </Heading>
      <ManageContentBox>
        <Box display={'flex'} alignItems={'flex-end'}>
          <Box width="100%">{children}</Box>
          <Box display={'flex'} justifyContent={'flex-end'} width="3.75rem">
            <Button
              lineHeight={'0,75rem'}
              fontWeight={'bold'}
              variant={'smallLighter'}
              onClick={() => history.push(`${path}?id=${id}`)}>
              Edit
            </Button>
          </Box>
        </Box>
      </ManageContentBox>
    </Box>
  );
}
