import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { DateTime } from 'luxon';

import InfoTooltip from '../InfoTooltip/info-tooltip';

const ColoredChip = styled.span`
  align-items: center;
  background-color: ${props => props.theme.colors[props.bgColor]};
  border-radius: 999px;
  color: ${props => props.theme.colors.brand.layer0};
  display: inline-flex;
  padding: 8px 16px;
  width: max-content;
`;

const ReleaseToolTip = styled(InfoTooltip)`
  &&, svg {
    path {
      color: ${props => props.theme.colors.brand.layer0};
    }
`;

const ReleaseStatusChip = ({ status, releaseDate, updatedAt, ...props }) => {
  if (!status) {
    console.warn('No status identified');
    return null;
  }

  // Enum values come from https://github.com/qandamedia/scale-data-api/blob/3713bbfe6619ccd27bc63032ae2df4cca3e3ba03/src/models/objection/release.js#L343-L351
  const statusProperties = {
    Draft: {
      color: 'cream200',
      message: `Updated${updatedAt ? `: ${DateTime.fromISO(updatedAt).toFormat('D')}` : ''}`,
    },
    'Ready to submit': {
      color: 'green100',
      message: 'Your release is ready to be reviewed.',
    },
    'Ready to submit updates': {
      color: 'green100',
      message: 'Submit these updates to keep the selected platforms current.',
    },
    'Pre-delivery review': {
      color: 'blue100',
      message: 'Your latest release submission is being reviewed before sending.',
    },
    'Reviewing updates': {
      color: 'blue100',
      message: 'Your latest release submission is being reviewed before sending.',
    },
    Delivered: {
      color: 'green100',
      message: `Your release has been sent to the selected platforms and ${
        releaseDate < new Date() ? 'went' : 'will go'
      } live ${
        releaseDate
          ? `on: ${`${DateTime.fromISO(releaseDate, {
              zone: 'America/New_York',
            }).toFormat('D')} at ${DateTime.fromISO(releaseDate, {
              zone: 'America/New_York',
          }).toFormat('ttt')}`}`
          : ''
      }.`,
    },
    Takendown: {
      color: 'cream200',
      message: 'Takendown from all platforms.',
    },
  };

  const chipProperties = statusProperties[status] || {
    color: 'cream200',
    message: '',
  };

  return (
    <ColoredChip bgColor={chipProperties.color} {...props}>
      {status}
      {chipProperties.message && <ReleaseToolTip text={chipProperties.message} type="info" />}
    </ColoredChip>
  );
};

export default withTheme(ReleaseStatusChip);
