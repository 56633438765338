import { Col, Row } from 'react-styled-flexboxgrid';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useContext, useMemo, useState } from 'react';
import TimePicker from 'features/TimePicker/time-picker';
import { DateTime } from 'luxon';
import Button from 'components/Button/button';
import Calendar from 'features/Calendar/calendar';
import useCardFlow from 'utils/use-card-flow';
import { RadioGroup, Radio, Stack, useTheme } from '@chakra-ui/react';
import { ButtonCol, FlexBox, VerticalCenter, Paragraph14 } from 'styles';
import { CardContext } from '../card-context';
import { useModals } from '../../../utils/useModals';
import { isReleaseDateWithin48Hours, isVideoRelaseDateWithin14Days } from 'utils/manage-release';
import get from 'lodash/get';

import { CardLoadingWrapper, CardContentWrapper, CardHeaderBlock } from './card-style';

import { CARDS } from '../card';
import HEAP from '../../../constants/HEAP.gen.json';

const FORM_INCLUDE_RELEASE_DATE_TIME_KEY = '_meta.includeReleaseDateTime';
const VIDEO_TYPE = 'Video';

const LiveReleaseDate = () => {
  const theme = useTheme();

  const radioHelpTextStyle = {
    fontWeight: 500,
    lineHeight: '1.25rem',
    color: theme.colors.black50,
    maxWidth: '21.875rem',
  };

  const CARD_ID = CARDS.LiveReleaseDate;
  const {
    id,
    loadingAny,
    getCardData,
    getCardDataById,
    afterSaveActions,
    NAV_DIRECTIONS,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const formMethods_LiveReleaseDate = useForm({ shouldUnregister: false });
  const formValues_LiveReleaseDate = formMethods_LiveReleaseDate.getValues({
    nest: true,
  });

  const { cardData, updateCard_Form, isReviewMode, _passthrough } = useCardFlow();

  const [includeReleaseDateTime, setIncludeReleaseDateTime] = useState(false);

  getCardDataById('InstantGratTracks', 'formData');
  const { showModal } = useModals();

  useEffect(() => {
    if (id === CARD_ID) {
      const cardData = getCardData('formData');
      formMethods_LiveReleaseDate.reset({
        ...cardData,
        release_date: cardData?.release_date || DateTime.local().plus({ days: 14 }).toISODate(),
      });
      //Have to set the radio here since we don't always have the data ready when we have the useState.
      setIncludeReleaseDateTime(() => get(cardData, FORM_INCLUDE_RELEASE_DATE_TIME_KEY, false));
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      save_LiveReleaseDate(
        { release_date: formMethods_LiveReleaseDate.watch('release_date') },
        _passthrough.inducePreview
      );
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough.induceSave, _passthrough.inducePreview]);

  const save_LiveReleaseDate = (data, forcePreview = false, navigation = null) => {
    const saveData = { ...formValues_LiveReleaseDate, ...data };

    updateCard_Form(id, saveData);

    const showPreview = forcePreview || isReviewMode;
    afterSaveActions(showPreview, navigation);
  };

  const handleOnChange = data => {
    const isVideoRelease = !!cardData?.Tracklist?.tracklistData?.find(track => track.resourceType === VIDEO_TYPE);
    if (isVideoRelease && isVideoRelaseDateWithin14Days({ release_date: data }))
      return showModal('WARNING_VIDEO_RELEASE_DATE_TOO_SOON');
    if (isReleaseDateWithin48Hours({ release_date: data })) showModal('WARNING_RELEASE_DATE_TOO_SOON');
  };

  const onReleaseTimeRadioChange = useMemo(
    () => value => {
      const changedIncludeReleaseDateTime = value === 'true';
      //Set here to rerender the form.
      setIncludeReleaseDateTime(() => changedIncludeReleaseDateTime);
      //Need to save the formMethod since this is the landing place for all data mutations.
      formMethods_LiveReleaseDate.setValue(FORM_INCLUDE_RELEASE_DATE_TIME_KEY, changedIncludeReleaseDateTime);
    },
    []
  );

  const { release_date, release_date_time } = formValues_LiveReleaseDate;
  const releaseDate =
    release_date && release_date_time ? DateTime.fromISO(`${release_date}T${release_date_time}`) : DateTime.now();
  const timePickerDisabled = includeReleaseDateTime === false || loadingSaveRelease || loadingAny;
  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <FormProvider {...formMethods_LiveReleaseDate}>
          <CardHeaderBlock omnishareas>
            <Row>
              <Col xs={5}>
                <FlexBox style={{ justifyContent: 'center' }}>
                  <h3>Release Date</h3>
                </FlexBox>
              </Col>
              <ButtonCol xs={7}>
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={formMethods_LiveReleaseDate.handleSubmit(data =>
                      save_LiveReleaseDate(data, false, NAV_DIRECTIONS.BACK)
                    )}
                    disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DATE_BUTTON_BACK}
                  />
                )}
                <Button
                  text={isReviewMode ? 'Save & Preview' : 'Next'}
                  rightIcon={isReviewMode ? null : 'chevronRight'}
                  onClick={formMethods_LiveReleaseDate.handleSubmit(data =>
                    save_LiveReleaseDate(data, false, NAV_DIRECTIONS.FORWARD)
                  )}
                  loading={loadingSaveRelease || loadingAny}
                  disabled={isFlowAnimating}
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DATE_BUTTON_NEXT}
                />
              </ButtonCol>
            </Row>
            <Row>
              <Col xs={12}>
                <Paragraph14 />
              </Col>
            </Row>
          </CardHeaderBlock>
          <Row>
            <Col xs={5}>
              <FlexBox style={{ justifyContent: 'center' }}>
                <Calendar
                  name="release_date"
                  disabled={loadingSaveRelease || loadingAny}
                  setValue={formMethods_LiveReleaseDate.setValue}
                  defaultSelectedDate={formMethods_LiveReleaseDate.watch('release_date')}
                  minDate={DateTime.local().toISODate()}
                  maxDate={DateTime.local().plus({ years: 1 }).toISODate()}
                  ref={formMethods_LiveReleaseDate.register({ required: true })}
                  onChange={handleOnChange}
                />
              </FlexBox>
            </Col>
            <Col xs={7}>
              <Row>
                <Col xs={12}>
                  <RadioGroup
                    value={includeReleaseDateTime.toString()}
                    defaultValue="false"
                    onChange={onReleaseTimeRadioChange}>
                    <Stack>
                      <Radio value="false">Standard release time</Radio>
                      <p style={{ marginLeft: '1.5rem', marginBottom: '3rem', ...radioHelpTextStyle }}>
                        This release will go live at 12:00AM local time in each territory on the{' '}
                        {releaseDate.toLocaleString(DateTime.DATE_FULL) + ' '}
                        (Recommended)
                      </p>
                      <Radio
                        value="true"
                        data-heap={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_DATE_CHECKBOX_TIMED}>
                        Timed release
                      </Radio>
                      <p style={{ marginLeft: '1.5rem', ...radioHelpTextStyle }}>
                        This release will go live in all selected territories at{' '}
                        {releaseDate.toFormat('h:mma').toLowerCase()} ET (recommended only for timed editorial
                        premieres)
                      </p>
                    </Stack>
                  </RadioGroup>
                  {/*The disabled cover for the TimePicker is positioned absolutely so its parent needs to work with
                  that.*/}
                  <FlexBox style={{ marginTop: '1rem', marginLeft: '.75rem', position: 'relative' }}>
                    <TimePicker
                      name={`release_date_time`}
                      setValue={formMethods_LiveReleaseDate.setValue}
                      defaultValue={formMethods_LiveReleaseDate.watch('release_date_time')}
                      disabled={timePickerDisabled}
                      ref={formMethods_LiveReleaseDate.register({
                        required: false,
                      })}
                    />
                    <VerticalCenter>
                      <span style={timePickerDisabled ? {} : { fontWeight: 600, color: theme.colors.cream100 }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;Eastern Time
                      </span>
                    </VerticalCenter>
                  </FlexBox>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};
export default LiveReleaseDate;
