import React from 'react';
import RoleManager from '../../components/role-manager/role-manager';
import { useAddContributorModal } from '../../components/AddArtistsForm/add-contributor-modal';
import ENUMS from 'constants/ENUMS.gen.json';

import { RoleRow } from 'components/role-manager/role-manager';
import { Button, Text } from '@chakra-ui/react';

const { SOUND_RECORDING_ROLE } = ENUMS;

const contributorRolesOptions = Object.values(SOUND_RECORDING_ROLE).map(role => ({
  title: role.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()), //De-Camel Casses
  value: role,
}));

export function ContributorRowRole({
  rolesOptions,
  defaultRole,
  addContributorModal,
  contributorsOptions,
  errorMessage,
  ...props
}) {
  return (
    <RoleRow
      CreateNewComponent={() => (
        <>
          <Button variant="smallLighter" onClick={addContributorModal.onOpen}>
            Create new contributor
          </Button>
        </>
      )}
      errorMessage={errorMessage}
      rolesOptions={rolesOptions}
      typeOptions={contributorsOptions}
      defaultRole={defaultRole}
      typeOfSelect={'Contributor'}
      {...props}
    />
  );
}

export default function ContributorRoleManager({
  loading,
  selectedContributors = [],
  onChange,
  contributors,
  onCreateContributor,
  errorMessages,
}) {
  const [AddContributorModal, addContributorModalDisclosure] = useAddContributorModal({ onCreateContributor });
  const contributorOptions = contributors.map(c => ({ title: c.full_name, value: c.id }));

  const contributorRows = selectedContributors
    .map(({ asset_party_id, roles = [] }) => {
      return roles.map(role => ({
        asset_party_id,
        role,
      }));
    })
    .flat();

  if (loading) {
    return <Text>Loading...</Text>;
  }

  function onRoleManagerChange(changeEvent) {
    const {
      target: { name, value: contributorRows },
    } = changeEvent;

    const contributorMap = contributorRows.reduce((contributorMap, contributorRow) => {
      const { asset_party_id, role } = contributorRow;

      if (asset_party_id in contributorMap === false) {
        contributorMap[asset_party_id] = { asset_party_id, roles: [] };
      }
      contributorMap[asset_party_id].roles.push(role);

      return contributorMap;
    }, {});

    onChange({ target: { name, value: Object.values(contributorMap) } });
  }

  return (
    <>
      <AddContributorModal variant={'light'} />
      <RoleManager
        errorMessages={errorMessages}
        contributors={contributors}
        loading={loading}
        rowsData={contributorRows}
        onChange={onRoleManagerChange}
        name={'contributors'}
        addRowText={'Add Contributor'}
        secondaryAction={{
          text: 'Create New Contributor',
          onClick: addContributorModalDisclosure.onOpen,
        }}
        RowComponent={({ ...props }) =>
          ContributorRowRole({
            rolesOptions: contributorRolesOptions,
            addContributorModalDisclosure,
            contributorsOptions: contributorOptions,
            defaultRole: 'Composer',
            ...props,
          })
        }
      />
    </>
  );
}
