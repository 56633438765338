import React, { memo } from 'react';
import { Box, Heading, Text, Button, useDisclosure, useTheme, Flex } from '@chakra-ui/react';
import { AiFillCheckCircle as CheckCircle } from 'react-icons/ai';

import { RiHome2Fill as Home } from 'react-icons/ri';
import { Link, useHistory } from 'react-router-dom';
import { useUserTierChecks } from 'hooks/authorization-hooks';
import NextActionBox from 'components/next-action-box/next-action-box';
import asModal from '../../../features/Modal/as-modal';

import URLS from 'constants/URLS.gen.json';
import NoAccessModal from 'utils/useModals/no-access-modal';
import useCurrentUser from 'data-client/use-current-user';
import { useGlobalData } from 'utils/global-data';
import { LockIcon } from '@chakra-ui/icons';

const MEMBERSHIP_URL = URLS.MEMBERSHIP[process.env.REACT_APP_RUNTIME];
const FeedbackModal = asModal(function FeedbackNoAccess() {
  return (
    <Box>
      <Text marginBottom={'4rem'}>This benefit is only available to Grow+ and Professional members</Text>
      <Button onClick={() => (location.href = `/settings#membership`)}>Upgrade Now</Button>
    </Box>
  );
});

function ClipConfirmation({ confirmationState }) {
  const { isGrowTierOrAbove } = useUserTierChecks();

  const feedbackModal = useDisclosure();
  const [currentUser] = useCurrentUser();
  const { selectedUserGroup } = useGlobalData();

  const theme = useTheme();

  const isOwner = selectedUserGroup?.email === currentUser?.email;

  const { clips, release_date, dsp_platforms } = confirmationState.stateData || {};
  const history = useHistory();

  const feedbackAccessHeading = `Want to hear industry feedback on your music?`;
  const feedbackAccessText = `Our bi-weekly feedback sessions feature members of the Venice Artist Services Team, Sync Team, and renowned producers, executives, and more as guests. These sessions are a great opportunity to hear industry feedback on your tracks to increase your exposure and continue your progression and development. This is one of the many great features only available to Grow+ and Professional Members.`;

  const upgradeWarningText =
    'You will need to have your membership owner upgrade the account within their member settings to get this benefit.';

  const isOwnerFeedbackAccessConfig = {
    text: feedbackAccessText,
    confirmButton: {
      text: 'Learn More',
      onClick: () => (location.href = `/settings#membership`),
    },
    showCancelButton: true,
  };

  const isNotOwnerFeedbackAccessConfig = {
    text: feedbackAccessText,
    warningText: upgradeWarningText,
    confirmButton: {
      text: 'Got It',
      onClick: () => feedbackModal.onClose(),
    },
    showCancelButton: false,
  };

  //This is called on render and returns the function to use
  function getOnFeebackClickFunction() {
    if (isGrowTierOrAbove) return () => (location.href = `${MEMBERSHIP_URL}/#events`);
    return feedbackModal.onOpen;
  }

  const FeedbackAccessModal = asModal(() => (
    <NoAccessModal
      {...(isOwner ? isOwnerFeedbackAccessConfig : isNotOwnerFeedbackAccessConfig)}
      onClose={feedbackModal.onClose}
    />
  ));

  //TODO figure out what color of green we are wanting.
  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box maxWidth={'37.5rem'}>
        <Button variant="transparent" as={Link} leftIcon={<Home />} to={'/releases'}>
          <Text as="span">Return to Catalog</Text>
        </Button>

        <Heading display={'flex'} justifyContent={'flex-start'} as="h2" fontSize={'1.2rem'} color={'green'} mb={'1rem'}>
          <CheckCircle /> Success!
        </Heading>

        <Box>
          <Box as="span" fontWeight={'bold'}>
            {`Your ${clips.length} clip${
              clips.length === 1 ? '' : 's'
            } will become available starting ${new Intl.DateTimeFormat('en-US', { dateStyle: 'full' }).format(
              new Date(release_date)
            )} at midnight on ${dsp_platforms.length} dsps.`}
          </Box>
          <Box>In the mean time you can take the following actions:</Box>
        </Box>

        <NextActionBox
          text={'Distribute clips for other songs in your catalog.'}
          title={'Create a different clip'}
          onClick={() => history.push('/manage/clip/new-from-released')}
        />

        <NextActionBox
          text={'Have your music heard by our Artist Services team and special guests during our bi-weekly sessions.'}
          title={'Attend a feedback session'}
          onClick={getOnFeebackClickFunction()}
          isLockedText={isGrowTierOrAbove ? null : 'Not available in Essentials'}
        />
      </Box>
      <FeedbackModal noFooter={true} isOpen={feedbackModal.isOpen} onClose={feedbackModal.onClose} />
      <FeedbackAccessModal
        variant="light"
        noFooter={true}
        headerText={
          <Flex align="center">
            <LockIcon color={theme.colors.pink} />
            <Box ml="0.5rem">{feedbackAccessHeading}</Box>
          </Flex>
        }
        isOpen={feedbackModal.isOpen}
        onClose={feedbackModal.onClose}
      />
    </Box>
  );
}

export default memo(ClipConfirmation);
