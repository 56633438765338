import ManageClipsStateManager from '../../manage-clips-state-manager';
import Schema from '../../../schema-gen/track';
import { z } from 'zod';

class ClipTrackDetailsState extends ManageClipsStateManager {
  dependentFields = ['audio_source', 'clips', 'track', 'metaState'];
  componentName = 'ClipTrackDetails';

  trackFields = [];

  title = 'Track Details';
  path = '/manage/clip/track_details';

  static mutableFields = [
    'isrc_code',
    'lyrics',
    'title',
    'sub_title',
    'artists',
    'lang',
    'genre',
    'contributors',
    'metaState',
    'parental_warning',
    'audio_source',
  ];

  parentTrack = {};

  constructor({ stateData, ...args }) {
    const track = stateData?.track || {};
    const metaState = stateData?.metaState || {};
    const audio_source = stateData?.audio_source || {};

    const flatStateData = {
      ...track,
      metaState,
      audio_source,
    };

    super(
      {
        stateData: flatStateData,
        mutableFields: ClipTrackDetailsState.mutableFields,
        ...args,
      },
      //BASE SCHEMA IS MODIFIED WHEN METASTATE CHANGES
      {
        isrc_code: Schema.ISRC,
        title: Schema.Title,
        sub_title: Schema.SubTitle.nullable().optional(),
        artists: z.array(Schema.Artist).min(1),
        contributors: z.array(Schema.Contributor).min(1),
        genre: Schema.Genre,
      }
    );

    this.parentTrack = track;
    //THIS IS SO WE CAN FLATTEN THE STATE HERE BUT PERSIST IT UPWARDS AS A TRACK OBJECT
    this.trackFields = [
      'isrc_code',
      'lyrics',
      'title',
      'sub_title',
      'artists',
      'lang',
      'contributors',
      'genre',
      'parental_warning',
    ];
    this.title = track.title || stateData?.audio_source?.name || 'Track Details';
    //Track sub_title gets saved as '' to show that the default sub_title was selected by the user. Otherwise
    //undefined or null should be set to use the release version.
    this.stateData.sub_title =
      track.sub_title === undefined || track.sub_title === null ? stateData?.sub_title : track.sub_title;
    this.stateData.parental_warning = track.parental_warning ? track.parental_warning : 'NotExplicit';
  }

  /*
  DON'T RELY ON SUPER METHOD BECAUSE PROTOTYPICAL INHERITANCE
  DOESN'T ALLOW PARENT'S TO ACCESS CHILD'S METHODS
   */
  getSaveHandler() {
    return this.saveState.bind(this);
  }

  /*
  OVERWRITE THE SAVE STATE METHOD TO ROLLUP TRACK STATE
  TO ITS PARENT AS A TRACK OBJECT
   */
  saveState(newStateObj) {
    const keys = Object.keys(newStateObj);
    const newState = keys.reduce(
      (acc, key) => {
        const value = newStateObj[key];
        if (this.trackFields.includes(key)) {
          return {
            ...acc,
            track: {
              ...acc.track,
              [key]: value,
            },
          };
        } else {
          return {
            ...acc,
            [key]: value,
          };
        }
      },
      {
        track: this.parentTrack,
      }
    );
    this.onSaveState(newState);
  }

  // CHANGES BASED ON METASTATE
  get mutableFields() {
    const lyricsFields = !this.stateData.metaState?.lyrics ? [] : ['lang', 'parental_warning', 'lyrics'];
    const isrcFields = !this.stateData.metaState?.isrc_code ? [] : ['isrc_code'];
    const mutableFields = [...ClipTrackDetailsState.mutableFields, ...isrcFields, ...lyricsFields];

    return Object.entries(this.stateData).reduce((data, [key, value]) => {
      if (!mutableFields.includes(key)) {
        return data;
      }

      return { ...data, [key]: value };
    }, {});
  }

  validateField(fieldName, value) {
    const _value = value;
    const validator = this.schema[fieldName];

    if (
      fieldName === 'artists' &&
      (!Array.isArray(_value) || !_value.find(artist => artist.roles.find(r => r === 'MainArtist')))
    )
      return validator.safeParse(null)?.success; //force invalid

    if (fieldName === 'contributors' && !areContributorsValid(_value)) return validator.safeParse(null)?.success; //force invalid

    if (validator) {
      const result = validator.safeParse(_value);
      return result.success;
    } else {
      console.warn('No validator found for field ' + fieldName);
    }
  }

  //CHANGES BASED ON METASTATE
  validateProps() {
    if (this.stateData?.metaState?.lyrics) {
      // ONLY REQUIRE LANGUAGE IF LYRICS ARE PRESENT
      this.schema.lang = Schema.Language;
      this.schema.parental_warning = Schema.ParentalWarning;
      this.schema.lyrics = Schema.Lyrics;
    } else {
      delete this.schema.lang;
      delete this.schema.parental_warning;
      delete this.schema.lyrics;
    }

    if (this.stateData?.metaState?.isrc_code) {
      this.schema.isrc_code = Schema.ISRC;
    } else {
      delete this.schema.isrc_code;
    }

    if (this.stateData.audio_source?.name === undefined) {
      return (this._allValid = false);
    }

    if (!this.mutableFields.artists?.find(artist => artist.roles.find(r => r === 'MainArtist'))) {
      return (this._allValid = false);
    }

    if (!areContributorsValid(this.mutableFields.contributors)) {
      return (this._allValid = false);
    }

    super.validateProps();
  }
}

function areContributorsValid(contributors) {
  if (!Array.isArray(contributors)) return false;

  //This essentially just goes through all of the roles of all of the contributors and makes sure there is at least 1 lyrcist and one composer.
  return (
    [
      ...new Set(
        Object.values(
          contributors.reduce(
            (acc, contributor) => {
              if (contributor.roles.find(c => c === 'ComposerLyricist')) {
                acc.composer = true;
                acc.lyricist = true;
              }
              if (contributor.roles.find(c => c === 'Composer')) acc.composer = true;
              if (contributor.roles.find(c => c === 'Lyricist')) acc.lyricist = true;
              return acc;
            },
            { composer: false, lyricist: false }
          )
        )
      ),
    ].join() === 'true'
  );
}

export default ClipTrackDetailsState;
