import { formatTrackData } from '../features/DraggableTrack/format-track-data';

// Why is it so complicated?
// https://support.tunecore.com/hc/en-us/articles/115006689928-What-is-the-difference-between-a-Single-an-EP-and-an-Album-
export const getImplicitReleaseType = tracks => {
  if (!tracks || !tracks.length) return null;
  const formattedTracks = tracks[0].data ? tracks : tracks.map((track, i) => formatTrackData(track, i + 1));

  let totalTime = 0;
  let totalTracks = 0;

  formattedTracks.forEach(track => {
    totalTime += track.data.duration || 0;
    totalTracks++;
  });

  let releaseType = 'Album';

  if (totalTracks <= 3 && parseInt(totalTime, 10) < 30 * 60) {
    if (parseInt(totalTime, 10) > 10 * 60) {
      releaseType = 'EP';
    } else {
      releaseType = 'Single';
    }
  }

  if (totalTracks >= 4 && totalTracks <= 6 && totalTime < 30 * 60 * 60) {
    releaseType = 'EP';
  }

  if (formattedTracks[0].data.resource_type === 'Video') {
    releaseType = 'VideoSingle';
  }

  return releaseType;
};

const readChunk = file => (chunkSize, offset) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target.error) {
        reject(event.target.error);
      }
      resolve(new Uint8Array(event.target.result));
    };
    reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
  });

export async function getMediaMetadata(file, options = { onStart: () => {}, onEnd: () => {} }) {
  try {
    options.onStart();
    const mediaInfo = await new Promise(resolve => {
      // eslint-disable-next-line no-undef
      return MediaInfo(
        {
          format: 'object',
          locateFile: (path, prefix) => prefix + path,
        },
        mediaInfo => {
          resolve(mediaInfo);
        }
      );
    });

    const result = await mediaInfo.analyzeData(() => file.size, readChunk(file));
    const trackMetadata = result?.media?.track || [];
    return {
      general: trackMetadata.filter(d => d['@type'] === 'General')[0],
      video: trackMetadata.filter(d => d['@type'] === 'Video'),
      audio: trackMetadata.filter(d => d['@type'] === 'Audio'),
    };
  } finally {
    options.onEnd();
  }
}
