import StateManagerCollection from '../../state-management/state-manager-collection';
import ClipStateManager from './clip-state-manager';

class ClipStateCollection extends StateManagerCollection {
  StateManagerItemClass = ClipStateManager;

  get highestIdx() {
    if (this.length) {
      return this.reduce((acc, clip) => {
        return clip.idx > acc ? clip.idx : acc;
      }, 0);
    }

    return undefined;
  }

  createMarkers(markerProps = {}) {
    return this.map(clip => {
      return {
        ...markerProps,
        time: clip.stateData.startTimeSeconds,
        label: clip.props.name,
      };
    });
  }

  reduceToClips() {
    return this.reduce((attributesAcc, clipStateManager) => {
      const { clipName, endTimeSeconds, startTimeSeconds, speedPercent } = clipStateManager.stateData;
      attributesAcc.push({
        clipName,
        startTimeSeconds,
        speedPercent,
        endTimeSeconds,
      });
      return attributesAcc;
    }, []);
  }
}

export default ClipStateCollection;
