import { useEffect, useRef } from 'react';
export default function useNavigationAwayCatcher(isDraftSaved) {
  const eventListenerRef = useRef();

  function onNavigateAway(e) {
    if (isDraftSaved) return;

    e.preventDefault();
  }

  useEffect(() => {
    eventListenerRef.current = event => {
      const returnValue = onNavigateAway(event);

      if (typeof returnValue === 'string') {
        return (event.returnValue = returnValue);
      }

      if (event.defaultPrevented) {
        return (event.returnValue = '');
      }
    };
  }, [isDraftSaved]);

  useEffect(() => {
    const eventListener = event => eventListenerRef.current(event);

    window.addEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, []);
}
