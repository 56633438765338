import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import Button from '../components/Button/button';
import asModal from './Modal/as-modal';

function VariousArtistsInformationModal({ onClose }) {
  return (
    <Box pb="2rem">
      <Text>
        Your release contains 4 or more artists at the release level, meaning streaming services like Spotify will
        display it as "Various Artists".
      </Text>
      <Text mt="1rem">
        If you don't want this, select fewer than 4 artists at the release level. If you intend to list a project with 4
        or more release artists, go back one step and select 'Compilation'.
      </Text>
      <Flex justifyContent="end" mt="1rem">
        <Button onClick={onClose} style={{ marginTop: '.75rem' }} text={'I understand'} />
      </Flex>
    </Box>
  );
}

export default asModal(VariousArtistsInformationModal);
