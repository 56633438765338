import { useQuery } from 'react-query';

const getCache = () => {
  try {
    const allCache = JSON.parse(window.localStorage.getItem('metrics-cache')) || {};
    return allCache;
  } catch (e) {
    //cache was corrupted in some way. just delete it
    window.localStorage.removeItem('metrics-cache');
    return {};
  }
};
const useCachedMetrics = (requestKey, requestFunction, requestOptions) => {
  //cached is one big object because if we had keys it could eventually fill up thier local storage.
  const rightNow = new Date().getTime();

  //go ahead and remove all of the stale cache
  const updatedCache = Object.entries(getCache()).reduce((a, [key, value]) => {
    if (value.staleTime > rightNow) return { ...a, [key]: value };
    return a;
  }, []);

  window.localStorage.setItem('metrics-cache', JSON.stringify(updatedCache));

  const cached = updatedCache?.[requestKey];

  async function cacheAndReturnResults() {
    const results = await requestFunction();
    const staleTime = new Date().getTime() + 60 * (60 * 1000);

    //sets the key and the values with a stale date.
    window.localStorage.setItem(
      'metrics-cache',
      JSON.stringify({ ...getCache(), [requestKey]: { results, staleTime } })
    );
    return results;
  }

  async function returnCachedResults() {
    return cached.results;
  }

  return useQuery(
    requestKey,
    cached && cached.staleTime > new Date().getTime() ? returnCachedResults : cacheAndReturnResults,
    requestOptions
  );
};

export default useCachedMetrics;
