import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl() + '/action-items';

const ActionItems = () => {
  const listActionItems = params => AuthAxios.get(`${DataApiUrl}/`, { params }).then(res => res.data.data.results);
  const createActionItem = data => AuthAxios.post(`${DataApiUrl}/`, data).then(res => res.data.data);
  const getActionItem = id => AuthAxios.get(`${DataApiUrl}/${id}`).then(res => res.data.data);
  const updateActionItem = (id, data) =>
    AuthAxios.patch(`${DataApiUrl}/${id}`, {
      ...data,
      id: undefined,
      user_group_id: undefined,
      created_at: undefined,
      updated_at: undefined,
    }).then(res => res.data.data);
  const deleteActionItem = id => AuthAxios.delete(`${DataApiUrl}/${id}`).then(res => res.data.data);

  return {
    listActionItems,
    createActionItem,
    getActionItem,
    updateActionItem,
    deleteActionItem,
  };
};

export default ActionItems;
