import React, { memo, useState, useCallback, useEffect } from 'react';

import { Box, Text, useTheme, useDisclosure } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import ManageContentBox, { ContentHeader, Title } from 'components/ManageContentBox/manage-content-box';
import LabeledInput from 'components/labeled-input/labeled-input';
import LabeledSelect from 'components/labeled-select/labeled-select';
import LabeledRadioToggle from 'components/labeled-radio-toggle/labeled-radio-toggle';
import LabeledTextarea from 'components/labeled-textarea/labeled-textarea';
import Well from 'components/Well/well';
import party from '../../../utils/API/party';
import ENUMS from 'constants/ENUMS.gen.json';
import AudioFileUploader from '../audio-file-uploader';
import useLabeledMetaToggle from '../../../features/use-labeled-meta-toggle/use-labeled-meta-toggle';
import ArtistRoleManager from '../../../features/party-role-managers/artist-role-manager';
import ContributorRoleManager from '../../../features/party-role-managers/contributor-role-manager';
import asModal from 'features/Modal/as-modal';

import { LANGUAGES2 } from '../../../constants/dictionaries';

const Party = party();
const { ASSET_GENRE } = ENUMS;

const versionNames = [
  'Acapella',
  'Acoustic',
  'Alternative Version',
  'Ao Vivo',
  'Bonus Track',
  'Cover Version',
  'Deluxe',
  'Demo',
  'Edit',
  'Extended Version',
  'Freestyle',
  'Ghost Track',
  'Hidden Track',
  'Instrumental',
  'Karaoke Track',
  'Live',
  'Radio Edit',
  'Remastered',
  'Remix',
  'Silent Track',
];
const languagesOptions = LANGUAGES2.map(lang => ({ value: lang.value, title: lang.label }));

const genres = Object.values(ASSET_GENRE);

const ERROR_MESSAGES = {
  audio_source: `An audio file is required`,
  title: `title is required`,
  version_name: `Unrecognized version`,
  artists: 'At least a primary artist or remixer is required',
  contributors: 'A composer and a lyricist are required',
  genre: 'A genre is required',
  lang: 'A language is required',
  lyrics: 'Lyrics are required',
  isrc_code: 'ISRCs must be 12 characters long',
};

const RequiredContributorsWarningModal = asModal(function RequiredContributorsWarning() {
  return (
    <Text margin={'2rem 0 1rem 0'} color="black100">
      Certain DSPs including Spotify required that all composers and lyricist credits use legal first and last names.
      Please create a new contributor using the legal first and last name of the composer or lyricist.
    </Text>
  );
});

function ClipTrackDetails({
  stateManager,
  audio_source,
  doValidateField,
  saveState,
  tainted,
  hasBeenSubmitted,
  releaseGroupId,
}) {
  const theme = useTheme();
  const requiredContributorsWarningModal = useDisclosure();

  const { title, sub_title, artists, contributors, genre, lang, lyrics, parental_warning, isrc_code } =
    stateManager.stateData;
  const [requiredReleaseArtist, setRequiredReleaseArtist] = useState(undefined);
  const [artistsOptions, setArtistsOptions] = useState([]);
  const [allContributors, setAllContributors] = useState([]);

  const [dirtyItems, setDirtyItems] = useState({ artists: false, contributors: false });

  const [localData, setLocalData] = useState({
    audio_source,
    title,
    sub_title,
    artists,
    contributors,
    genre,
    lang,
    lyrics,
    parental_warning,
    isrc_code,
  });

  const [errorMessages, setErrorMessages] = useState({
    audio_source: null,
    title: null,
    sub_title: null,
    artists: null,
    contributors: null,
    genre: null,
    lang: null,
    lyrics: null,
    isrc_code: null,
  });

  function updateErrorMessages(name, value) {
    setErrorMessages(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  function onFormInputChange({ target: { name, value } }) {
    setLocalData({ ...localData, [name]: value });
    const valid = doValidateField(name, value);
    if (!valid) {
      updateErrorMessages(name, ERROR_MESSAGES[name]);
    } else {
      updateErrorMessages(name, null);
    }
  }

  const onTrimmedInputChange = useCallback(
    ({ target: { name, value } }) => {
      onFormInputChange({ target: { name, value: value.trim() } });
    },
    [onFormInputChange]
  );

  const onNullPreferredInputChange = useCallback(
    ({ target: { name, value } }) => {
      onFormInputChange({ target: { name, value: value || null } });
    },
    [onFormInputChange]
  );

  useEffect(() => {
    if (tainted) {
      Object.entries(localData).forEach(([name, value]) => {
        const valid = doValidateField(name, value);

        if (!valid) {
          if (!valid) {
            updateErrorMessages(name, ERROR_MESSAGES[name]);
          } else {
            updateErrorMessages(name, null);
          }
        }
      });

      setDirtyItems({ artists: true, contributors: true });
    }
  }, []);

  useEffect(() => {
    if (!dirtyItems.artists) return;
    const valid = doValidateField('artists', localData.artists);
    if (!valid) {
      updateErrorMessages('artists', ERROR_MESSAGES.artists);
    } else {
      updateErrorMessages('artists', null);
    }
  }, [localData.artists]);

  useEffect(() => {
    if (!dirtyItems.contributors) return;
    localData.contributors.forEach(c => {
      c.roles.forEach(role => {
        if (['Composer', 'Lyricist', 'ComposerLyricist'].includes(role)) {
          const validContributorNames = allContributors
            .find(contributor => contributor.asset_party_id === parseInt(c.asset_party_id, 10))
            ?.full_name.includes(' ');

          const errorKey = `contributors.${c.asset_party_id}_${role}`;
          const isComposerLyricistRole = role.includes('ComposerLyricist');
          const formattedRole = role.toString().toLowerCase();

          if (!validContributorNames) {
            requiredContributorsWarningModal.onOpen();

            updateErrorMessages(
              errorKey,
              isComposerLyricistRole
                ? 'Please select a valid composer and lyricist.'
                : `Please select a valid ${formattedRole}.`
            );
          } else {
            updateErrorMessages(errorKey, null);
          }
          return validContributorNames;
        }
      });
    });

    const valid = doValidateField('contributors', localData.contributors);
    if (!valid) {
      updateErrorMessages('contributors', ERROR_MESSAGES.contributors);
    } else {
      updateErrorMessages('contributors', null);
    }
  }, [localData.contributors]);

  const handleToggleOff = metaKey => {
    if (metaKey === 'lyrics') {
      const updateData = {
        ...localData,
        lyrics: null,
        lang: null,
        parental_warning: 'NotExplicit',
        metaState: {
          ...localData.metaState,
          [metaKey]: false,
        },
      };
      saveState(updateData);
      setLocalData(updateData);
    } else {
      const updateData = {
        ...localData,
        [metaKey]: null,
        metaState: {
          ...localData.metaState,
          [metaKey]: false,
        },
      };
      saveState(updateData);
      setLocalData(updateData);
    }
  };

  const handleToggleOn = metaKey => {
    if (metaKey === 'lyrics') {
      const updateData = {
        ...localData,
        lyrics: '',
        metaState: {
          ...localData.metaState,
          lyrics: true,
        },
      };
      saveState(updateData);
      setLocalData(updateData);
    } else {
      const updateData = {
        ...localData,
        metaState: {
          ...localData.metaState,
          [metaKey]: true,
        },
      };
      saveState(updateData);
      setLocalData(updateData);
    }
  };

  const onMetaToggle = (metaKey, metaValue) => {
    if (!metaValue) {
      handleToggleOff(metaKey);
    } else {
      handleToggleOn(metaKey);
    }
  };

  const [LabeledMetaToggle, metaState] = useLabeledMetaToggle(
    {
      isrc_code: !!localData.isrc_code || localData.metaState?.isrc_code,
      lyrics: localData.lyrics !== null || localData.metaState?.lyrics,
    },
    onMetaToggle
  );

  const { isFetching, refetch: refetchParties } = useQuery(
    ['parties', releaseGroupId],
    () => Party.listGroupParties(releaseGroupId),
    {
      enabled: releaseGroupId !== undefined && releaseGroupId !== null,
      onSuccess: results => {
        const [artists, contributors] = results.data.reduce(
          ([artists, contributors], party) => {
            if (party.type === 'artist') {
              artists.push(party);
            }
            if (party.type === 'contributor') {
              contributors.push(party);
            }
            return [artists, contributors];
          },
          [[], []]
        );
        const requiredArtist = artists.find(artist => artist.is_required_release_artist);
        const localArtists = localData.artists || [];
        const requiredReleaseArtistChosen = !!localArtists.find(
          artist => requiredArtist && requiredArtist.asset_party_id === artist.asset_party_id
        );

        if (requiredReleaseArtistChosen === false && requiredArtist && localArtists.length === 0) {
          setLocalData({
            ...localData,
            artists: [{ asset_party_id: requiredArtist.id, roles: ['MainArtist'] }],
          });
          saveState({
            ...localData,
            artists: [{ asset_party_id: requiredArtist.id, roles: ['MainArtist'] }],
          });
        }

        setArtistsOptions(artists);
        setAllContributors(contributors);
        setRequiredReleaseArtist(requiredArtist);
      },
    }
  );

  const onCreateArtist = artist => {
    setArtistsOptions((currentOptions = []) => {
      return [...currentOptions, artists];
    });

    const currentArtists = localData.artists || [];
    setLocalData({
      ...localData,
      artists: [...currentArtists, { asset_party_id: artist.asset_party_id, roles: [] }],
    });
    refetchParties();
  };

  const onCreateContributor = contributor => {
    setAllContributors((currentOptions = []) => {
      return [...currentOptions, contributor];
    });

    const currentContributors = localData.contributors || [];
    setLocalData({
      ...localData,
      contributors: [...currentContributors, { asset_party_id: contributor.asset_party_id, roles: [] }],
    });
    refetchParties();
  };

  function onPartyChange({ target: { name, value } }) {
    const updated = { [name]: value };
    setDirtyItems({ ...dirtyItems, [name]: true });
    saveState({ ...localData, ...updated });
    setLocalData({ ...localData, ...updated });
  }

  function onAudioFileLoaded({ file }) {
    saveState({ ...localData, audio_source: file });
    setLocalData({ ...localData, audio_source: file });
  }

  function submitForm(evt) {
    evt?.preventDefault();
    saveState(localData);
  }

  const onBuildTrackCreationProperties = useCallback(() => ({ resource_type: ENUMS.RESOURCE_TYPE.CLIP_SOURCE }), []);

  return (
    <Box>
      <ManageContentBox>
        {hasBeenSubmitted ? (
          <>
            <ContentHeader>
              <Title toolTip={'With a published clips release, you cannot change the audio file'}>Audio File</Title>
            </ContentHeader>
            <Box>{localData.audio_source.name}</Box>
          </>
        ) : (
          <>
            <ContentHeader>
              <Title toolTip={'File must be a wav or mp3'}>Audio Upload</Title>
            </ContentHeader>
            <Box marginTop={'1rem'} height="140px">
              <AudioFileUploader
                audio_source={localData.audio_source}
                track={localData}
                onUploaded={onAudioFileLoaded}
                onBuildTrackCreationProperties={onBuildTrackCreationProperties}
              />
            </Box>
          </>
        )}
      </ManageContentBox>

      {/* Name */}
      <ManageContentBox>
        <ContentHeader>
          <Title>What is the name of the track?</Title>
        </ContentHeader>
        <LabeledInput
          name="title"
          label="Track Title"
          value={localData?.title || ''}
          onChange={onFormInputChange}
          onBlur={submitForm}
          invalidMessage={errorMessages.title}
        />
        <LabeledSelect
          width="14rem"
          name="sub_title"
          label="Version (optional)"
          value={localData.sub_title || ''}
          onChange={onNullPreferredInputChange}
          onBlur={submitForm}
          invalidMessage={errorMessages?.sub_title}
          options={versionNames}
        />
        {localData.title ? (
          <Well>
            <Text fontWeight="bold">TRACK TITLE PREVIEW</Text>
            <Text>{`${localData.title} ${localData.sub_title ? `(${localData.sub_title})` : ''}`}</Text>
          </Well>
        ) : (
          ''
        )}
      </ManageContentBox>
      {/* Artists */}
      <ManageContentBox>
        <ContentHeader>
          <Title>What artists are on the track?</Title>
        </ContentHeader>
        <Box
          border={'1px solid'}
          borderColor={errorMessages.artists ? theme.colors.red100 : 'transparent'}
          borderRadius={'0.15rem'}
          padding={'0.25rem'}>
          <ArtistRoleManager
            onCreateArtist={onCreateArtist}
            loading={isFetching}
            artists={artistsOptions}
            onChange={onPartyChange}
            selectedArtists={localData.artists}
            requiredReleaseArtist={requiredReleaseArtist}
          />
          {errorMessages.artists ? <Text color={theme.colors.red100}>{errorMessages.artists}</Text> : ''}
        </Box>
      </ManageContentBox>

      {/*  Contributors */}
      <ManageContentBox>
        <ContentHeader>
          <Title>Who should be in the tracks credits?</Title>
        </ContentHeader>
        <Box
          border={'1px solid'}
          borderColor={errorMessages.contributors ? theme.colors.red100 : 'transparent'}
          borderRadius={'0.15rem'}
          padding={'0.25rem'}>
          <ContributorRoleManager
            errorMessages={errorMessages}
            loading={isFetching}
            contributors={allContributors}
            onChange={onPartyChange}
            selectedContributors={localData.contributors}
            onCreateContributor={onCreateContributor}
          />
          {errorMessages.contributors ? <Text color={theme.colors.red100}>{errorMessages.contributors}</Text> : ''}
        </Box>
        <Well styleProps={{ marginTop: '1rem', background: 'black10' }}>
          You are required to add at least one composer (producer) and one lyricist per streaming services'
          requirements.
        </Well>
      </ManageContentBox>

      {/* Genre */}
      <ManageContentBox>
        <ContentHeader>
          <Title>What genre is this track?</Title>
        </ContentHeader>
        <LabeledSelect
          width="14rem"
          name="genre"
          label="Primary Genre"
          value={localData.genre || ''}
          onChange={onFormInputChange}
          onBlur={submitForm}
          options={genres}
          invalidMessage={errorMessages.genre}
        />
      </ManageContentBox>

      {/* ISRC */}
      <ManageContentBox>
        <LabeledMetaToggle name={'isrc_code'} label="Do you have your own ISRC?" disabled={hasBeenSubmitted} />
        <Well variant="collapsible">
          Leave this option unchecked if you do not own an ISRC for your track and Venice will auto-generate one for
          you. If you have pre-purchased your own ISRC or are migrating a previously distributed release to Venice,
          select and enter your ISRC. ISRC, or International Standard Recording Code, uniquely identifies an album track
          and helps music stores track sales of a track in a release.
        </Well>
        {/* TODO when a release is already submitted, this should be handled differently.*/}
        {metaState.isrc_code ? (
          <LabeledInput
            disabled={hasBeenSubmitted}
            name="isrc_code"
            label="ISRC"
            value={localData.isrc_code || ''}
            onChange={onTrimmedInputChange}
            onBlur={submitForm}
            invalidMessage={errorMessages.isrc_code}
          />
        ) : (
          ''
        )}
      </ManageContentBox>

      {/* Lyrics */}
      <ManageContentBox>
        <LabeledMetaToggle name="lyrics" label="Does this track have lyrics?" />
        {metaState.lyrics ? (
          <>
            <LabeledRadioToggle
              name="parental_warning"
              label="Does this track have explicit material?"
              value={localData.parental_warning}
              onChange={onFormInputChange}
              onBlur={submitForm}
              yesValue="Explicit"
              noValue="NotExplicit"
            />
            <LabeledSelect
              width="14rem"
              name="lang"
              label="Language"
              value={localData.lang}
              onChange={onFormInputChange}
              onBlur={submitForm}
              invalidMessage={errorMessages.lang}
              options={languagesOptions}
            />
            <LabeledTextarea
              label={'Lyrics'}
              name={'lyrics'}
              value={localData.lyrics || ''}
              onChange={onFormInputChange}
              onBlur={submitForm}
              placeholder={'lyrics go here'}
              invalidMessage={errorMessages.lyrics}
              limit={5000}
            />
          </>
        ) : (
          ''
        )}
      </ManageContentBox>
      <RequiredContributorsWarningModal
        variant="light"
        hideCancel
        isOpen={requiredContributorsWarningModal.isOpen}
        onClose={requiredContributorsWarningModal.onClose}
        onSubmit={requiredContributorsWarningModal.onClose}
        submitText="OK"
        headerText="Invalid Contributor Name for Role"
      />
    </Box>
  );
}

export default memo(ClipTrackDetails);
