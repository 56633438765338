import { COMPOSER, DIRECTOR, LYRICIST } from '../../constants/roles';

export const REQUIRED_AUDIO_ROLES = [COMPOSER, LYRICIST].map(r => Object.assign({}, r));
export const REQUIRED_VIDEO_ROLES = [DIRECTOR, ...REQUIRED_AUDIO_ROLES].map(r => Object.assign({}, r));
export const BLANK_CONTRIBUTOR = {
  appleId: undefined,
  asset_party_id: undefined,
  asset_track_party_id: undefined,
  platform_id: undefined,
  roles: [],
  spotifyId: undefined,
  soundcloudId: undefined,
  type: 'contributor',
  user_group_id: 'undefined',
  full_name: '',
};
