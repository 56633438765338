import React, { useState } from 'react';
import { VStack, Box } from '@chakra-ui/react';
import ActionItem from '../../components/ActionItems/action-item';
import { useListActionItems, useUpdateActionItem, useDeleteActionItem } from 'data-client/use-action-items';
import CoManagerActionItemModal from './co-manager-action-item-modal';

const isAnyTimestampFromToday = timestamps => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return timestamps.some(timestamp => {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    return date.getTime() === today.getTime();
  });
};

function CoManagerActionItems({ ...props }) {
  const [page, setPage] = useState(0);
  const [CoManagerActionItemModalOpen, setCoManagerActionItemModalOpen] = useState(false);
  const { status, data: actionItemsList, error } = useListActionItems({ page_index: page, page_size: 10 });
  const {
    mutate: updateActionItemMutation,
    error: updateActionItemError,
    reset: resetUpdateActionItem,
  } = useUpdateActionItem();
  const { mutate: deleteActionItemMutation } = useDeleteActionItem();
  const [updateActionItem, setUpdateActionItem] = useState({});

  const toggleActionItem = actionItem => {
    if (actionItem.completions && isAnyTimestampFromToday(actionItem.completions)) {
      updateActionItemMutation({
        ...actionItem,
        completions: actionItem.completions.filter(timestamp => !isAnyTimestampFromToday([timestamp])),
      });
    } else {
      updateActionItemMutation({
        ...actionItem,
        completions: [...(actionItem.completions || []), new Date().getTime()],
      });
    }
  };

  if (status === 'loading') return <Box>Loading...</Box>;

  if (status === 'error') return <Box>Error: {error.message}</Box>;

  if (actionItemsList.length === 0)
    return (
      <Box align="center" mt="2rem">
        Click the "+ New" button to create your first action item.
      </Box>
    );

  return (
    <VStack align="stretch" spacing="0.25rem" {...props}>
      {actionItemsList.map(actionItem => {
        return (
          <ActionItem
            key={actionItem.action_item_id}
            actionItem={actionItem}
            onToggleComplete={() => toggleActionItem(actionItem)}
            onDelete={() => deleteActionItemMutation(actionItem.action_item_id)}
            onEdit={() => {
              setUpdateActionItem(actionItem);
              setCoManagerActionItemModalOpen(true);
            }}
          />
        );
      })}

      <CoManagerActionItemModal
        isOpen={CoManagerActionItemModalOpen}
        headerText="Create Action Item"
        variant="light"
        actionItem={updateActionItem}
        errors={updateActionItemError?.response?.data}
        onClearErrors={() => {
          resetUpdateActionItem();
        }}
        onUpdate={actionItem => {
          setUpdateActionItem(actionItem);
        }}
        onClose={() => {
          setUpdateActionItem({});
          setCoManagerActionItemModalOpen(false);
        }}
        onSubmit={() => {
          updateActionItemMutation(updateActionItem, {
            onSuccess: () => {
              setUpdateActionItem({});
              setCoManagerActionItemModalOpen(false);
            },
          });
        }}
      />
    </VStack>
  );
}

export default CoManagerActionItems;
