import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useTheme,
  Button,
  Flex,
} from '@chakra-ui/react';

const BtnSubmit = ({ text, onClick, variant }) => (
  <Button testCode="modal-save" variant={variant} onClick={onClick} submit>
    {text || 'Save'}
  </Button>
);

const BtnCancel = ({ text, onClick }) => (
  <Button testCode="modal-cancel" variant="small" onClick={onClick} style={{ marginRight: useTheme().space[3] }}>
    {text || 'Cancel'}
  </Button>
);

export default function asModal(Component) {
  return class extends React.Component {
    displayName() {
      'asModal(Component)';
    }
    render() {
      const {
        isOpen,
        onClose,
        headerText,
        cancelText,
        confirmText,
        submitText,
        onSubmit,
        noFooter,
        scrollBehavior,
        noHeader,
        modalProps = {},
        headerProps = {},
        bodyProps = {},
        footerLeftContent,
        hideCancel,
        hideClose,
        ...props
      } = this.props;

      const variant = props?.variant || 'dark';

      return (
        <Modal isOpen={isOpen} onClose={onClose} variant={variant} scrollBehavior={scrollBehavior} {...modalProps}>
          <ModalOverlay />
          <ModalContent>
            {!noHeader && (
              <ModalHeader padding={'2rem 1.5rem'} fontSize="lg" {...headerProps}>
                {headerText}
              </ModalHeader>
            )}
            {!hideClose && (
              <ModalCloseButton
                size="lg"
                color={variant === 'light' ? 'black' : 'cream100'}
                marginTop="1rem"
                marginRight="0.5rem"
              />
            )}
            <ModalBody padding="0 1.5rem 1.5rem 1.5rem" {...bodyProps}>
              <Component {...this.props} />
            </ModalBody>
            {!noFooter && (
              <Flex direction="row" justifyContent={'space-between'} padding={'1rem 1.5rem'}>
                <Flex justifyItems={'flex-start'}>{footerLeftContent}</Flex>
                <Flex justifyItems={'space-between'}>
                  {!hideCancel ? <BtnCancel text={cancelText} onClick={onClose} /> : ''}
                  {onSubmit ? (
                    <BtnSubmit
                      variant={variant === 'light' ? 'smallDark' : 'darkMode'}
                      text={submitText || confirmText}
                      onClick={onSubmit}
                    />
                  ) : (
                    ''
                  )}
                </Flex>
              </Flex>
            )}
          </ModalContent>
        </Modal>
      );
    }
  };
}
