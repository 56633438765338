import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const Groups = () => {
  const get = id =>
    AuthAxios.get(`${DataApiUrl}/groups/${id}`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const users = (id, params) =>
    AuthAxios.get(`${DataApiUrl}/groups/${id}/users`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const inviteUser = (groupId, email, role) =>
    AuthAxios.post(`${DataApiUrl}/groups/${groupId}/invite`, {
      email,
      role,
    }).then(res => res.data.data);

  const resendInvite = (groupId, email) =>
    AuthAxios.post(`${DataApiUrl}/groups/${groupId}/resendInvite`, {
      email,
    }).then(res => res.data.data);

  const updateUser = (id, userId, role) =>
    AuthAxios.patch(`${DataApiUrl}/groups/${id}/users/${userId}`, {
      role,
    })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const removeUser = (id, userId) =>
    AuthAxios.delete(`${DataApiUrl}/groups/${id}/users/${userId}`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getStripeConnectUrl = (id, origin, countryCode) =>
    AuthAxios.get(`${DataApiUrl}/groups/${id}/paymentAccount/stripeConnectUrl`, { params: { origin, countryCode } })
      .then(res => res.data.data.expressDashboardLink)
      .catch(err => console.error(err.message));

  const getPaymentAccount = id =>
    AuthAxios.get(`${DataApiUrl}/groups/${id}/paymentAccount`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const createPayeeIndividual = (id, name, email) =>
    AuthAxios.post(`${DataApiUrl}/groups/${id}/payees/user`, {
      name,
      email,
    })
      .then(res => res.data.data)
      .catch(err => {
        throw err.response.data;
      });

  const getPayees = id =>
    AuthAxios.get(`${DataApiUrl}/groups/${id}/payees`)
      .then(res => res.data.data.results)
      .catch(err => {
        throw err.response;
      });

  const updateGroup = (id, params) => {
    AuthAxios.patch(`${DataApiUrl}/groups/${id}`, params)
      .then(res => res.data)
      .catch(err => {
        throw err.response;
      });
  };

  const hasReleases = id =>
    AuthAxios.get(`${DataApiUrl}/groups/${id}/hasReleases`)
      .then(res => res.data.data.data)
      .catch(err => console.error(err.message));

  const getArtistsEligibleForS4A = id =>
    AuthAxios.get(`${DataApiUrl}/groups/${id}/artists-eligible-for-s4a`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  return {
    get,
    users,
    inviteUser,
    resendInvite,
    updateUser,
    removeUser,
    getStripeConnectUrl,
    getPaymentAccount,
    createPayeeIndividual,
    getPayees,
    updateGroup,
    hasReleases,
    getArtistsEligibleForS4A,
  };
};

export default Groups;
