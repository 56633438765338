import { z } from 'zod';

class ManageClipsStateManager {
  title;
  path;
  dependentFields = [];
  #mutableFields;
  validationResults;
  _allValid;
  #validations;
  tainted;

  constructor({ saveState, stateData, currentPath, name, tainted = false, mutableFields }, schema) {
    this.onSaveState = saveState;
    this.schema = schema;
    this.name = name;
    this.currenthPath = currentPath;
    this.tainted = tainted;

    if (!mutableFields) {
      this.#mutableFields = this.dependentFields;
    } else {
      this.#mutableFields = mutableFields;
    }

    this.stateData = stateData;
  }

  get pathMatches() {
    return this.currenthPath.includes(this.path);
  }

  get props() {
    return Object.entries(this.stateData).reduce((data, [key, value]) => {
      if (!this.dependentFields.includes(key))
        return {
          ...data,
        };
      return { ...data, [key]: value };
    }, {});
  }

  get mutableFields() {
    return Object.entries(this.stateData).reduce((data, [key, value]) => {
      if (!this.#mutableFields.includes(key)) return data;
      return { ...data, [key]: value };
    }, {});
  }

  //This can be dynamically changed by overriding this function
  get linkTitle() {
    return this.title;
  }

  get disabled() {
    return false;
  }

  get disabledHoverText() {
    return '';
  }

  static walkRecursively(node, callback, shouldWalkNodeFn = () => true, parentKeys = []) {
    if (!!node && typeof node === 'object') {
      Object.keys(node).forEach(key => {
        const currentKeys = [...parentKeys, key];
        const currentNode = node[key];

        if (currentNode === null) {
          callback(currentNode, currentKeys);
        } else {
          let shouldWalkNode;

          try {
            shouldWalkNode = shouldWalkNodeFn(currentNode, currentKeys);
          } catch (e) {
            e.context = {
              node,
              parentKeys,
            };
            e.message = `Error in shouldWalkNodeFn for key ${key}: ${e.message}`;
            throw e;
          }

          if (shouldWalkNode) {
            try {
              ManageClipsStateManager.walkRecursively(currentNode, callback, shouldWalkNodeFn, currentKeys);
            } catch (e) {
              e.message = `Error in callback for key ${key}: ${e.message}`;
              e.context = {
                node,
                parentKeys,
              };
            }
          }
        }
      });
    } else if (!!node && Array.isArray(node)) {
      node.forEach(item => {
        if (item === null) {
          callback(item, parentKeys);
        } else {
          let shouldWalkNode;
          try {
            shouldWalkNode = shouldWalkNodeFn(item, parentKeys);
          } catch (e) {
            e.context = {
              node,
              parentKeys,
            };
            e.message = `Error in shouldWalkNodeFn array item: ${e.message}`;
            throw e;
          }

          if (shouldWalkNode) {
            try {
              ManageClipsStateManager.walkRecursively(item, callback, shouldWalkNodeFn, parentKeys);
            } catch (e) {
              e.message = `Error in callback for array item: ${e.message}`;
              e.context = {
                node,
                parentKeys,
              };
            }
          }
        }
      });
    } else {
      callback(node, parentKeys);
    }
  }

  //LARGELY COPIED FROM YouMightNotNeed.com/lodash#get
  static getLeafByPath(obj, path, defValue) {
    if (!path) return undefined;

    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    // Find value
    return pathArray.reduce((prevObj, key) => {
      return prevObj?.[key] ? prevObj[key] : defValue;
    }, obj);
  }

  //LARGELY COPIED FROM YouMightNotNeed.com/lodash#set
  static setLeafByPath(obj, path, value) {
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    pathArray.reduce((acc, key, i) => {
      if (acc[key] === undefined) {
        acc[key] = {};
      }

      if (i === pathArray.length - 1) {
        acc[key] = value;
      }

      return acc[key];
    }, obj);
  }

  getMutableFieldByPath(path, defaultValue) {
    return ManageClipsStateManager.getLeafByPath(this.mutableFields, path, defaultValue);
  }

  getValidationErrorByPath(path, defaultValue) {
    return ManageClipsStateManager.getLeafByPath(this.validationResults, path, defaultValue);
  }

  get validations() {
    if (!this.tainted) {
      return Object.keys(this.mutableFields).reduce((validations, field) => ({ ...validations, [field]: true }), {});
    }

    if (this.tainted) {
      this.#validations = {};

      const updateValidations = (node, path) => {
        const error = this.getValidationErrorByPath(path, false); //ManageClipsStateManager.getLeafByPath(this.validationErrors, path, false);
        ManageClipsStateManager.setLeafByPath(this.#validations, path, !error);
      };

      const shouldWalkNodeFn = (node, path) => {
        // IF CHILDREN ARE VALIDATED, DON'T WALK THEM
        if (node?.validations) {
          this._allValid = !node.allValid ? node.allValid : this.allValid;
          ManageClipsStateManager.setLeafByPath(this.#validations, path, node.validations);
          return false;
        }
        return true;
      };

      ManageClipsStateManager.walkRecursively(this.mutableFields, updateValidations, shouldWalkNodeFn);
    }

    return this.#validations;
  }

  getSaveHandler() {
    return this.saveState.bind(this);
  }

  saveState(newState) {
    this.validateProps(newState);
    this.onSaveState(newState);
  }

  toJSON() {
    return this.props;
  }

  get allValid() {
    if (this._allValid === undefined) {
      this.validateProps();
    }
    return this._allValid;
  }

  validateFieldWithSchema(fieldName, value, schema) {
    const _value = value || this.mutableFields[fieldName];
    const result = schema.safeParse(_value);
    return result.success;
  }

  validateField(fieldName, value) {
    const _value = value;
    const validator = this.schema[fieldName];

    if (validator) {
      const result = validator.safeParse(_value);
      return result.success;
    } else {
      throw Error('No validator found for field ' + fieldName);
    }
  }

  validateProps() {
    if (!this.validationResults) {
      this.validationResults = {};
    }

    const result = z.object(this.schema).safeParse(this.mutableFields);
    this._allValid = result.success;

    result?.error?.issues?.map(issue => {
      ManageClipsStateManager.setLeafByPath(this.validationResults, issue.path, issue);
    });
  }
}

export default ManageClipsStateManager;
