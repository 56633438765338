import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import SkeletonLoader from '../components/loading-containers/skeleton-loader';
import { ParentSize } from '@visx/responsive';
import XYChart from './xy-chart';
import { SUPPORTED_DSPS } from './supported-dsps';
import DspFilterButton from './dsp-filter-button';
import GlanceCard from './glance-card';
import { useAnalyticsContext } from './analytics-context';

function PerformanceChart({ loadingStatus = {} }) {
  const {
    dateRangeString,
    decorativeText,
    dataSubset,
    updateAnalyticsState,
    dsps: { selectedDsps, handleDspFilter },
  } = useAnalyticsContext();

  const handleChangeDsp = dspName => updateAnalyticsState(handleDspFilter(dspName));

  return (
    <GlanceCard
      noSetHeight
      nobg="true"
      noborder="true"
      dateRangeString={dateRangeString}
      decorativeText={decorativeText}
      title="DSP Performance">
      <Box height="100%">
        <SkeletonLoader
          status={loadingStatus.status}
          skeletonProps={{ height: '400px' }}
          customError="There was a problem getting trend data, try refreshing the page and reach out to support if the problem persists.">
          <ParentSize>
            {({ width }) => <XYChart data={dataSubset} selectedDsps={selectedDsps} width={width} height={400} />}
          </ParentSize>
        </SkeletonLoader>
      </Box>
      <Box mt={loadingStatus.isSuccess ? 0 : 2}>
        <Stack direction="row" spacing={4}>
          {SUPPORTED_DSPS.map(dsp => (
            <DspFilterButton
              key={dsp}
              dspName={dsp}
              selectedDsps={selectedDsps}
              handleDspFilter={() => handleChangeDsp(dsp)}
            />
          ))}
        </Stack>
      </Box>
    </GlanceCard>
  );
}

export default PerformanceChart;
