import ASSET_LIMITS from '../constants/ASSET_LIMITS.gen.json';

const { FILE_TYPE_BYTE_LIMIT_MAP, ADMIN_UPLOAD_BYTE_LIMIT } = ASSET_LIMITS;

const EXACT_FRAME_RATES = [23.976, 24, 25, 30];

const bytesToGB = value => Math.ceil(value / 1073741824);
const validExtensionSet = new Set(['mp3', 'wav', 'mp4', 'mov', 'flac']);

const getFileValidationMessages = ({ file, validateAsAdmin }) => {
  const messages = [];

  const type = file.name.split('.').at(-1);

  if (!type || validExtensionSet.has(type) === false) {
    messages.push(`${type} is not a supported extension type.`);
  }

  const sizeLimit = validateAsAdmin
    ? ADMIN_UPLOAD_BYTE_LIMIT
    : FILE_TYPE_BYTE_LIMIT_MAP[type] || FILE_TYPE_BYTE_LIMIT_MAP['default'];

  if (file.size > sizeLimit) {
    messages.push(`${type} files must be smaller than ${bytesToGB(sizeLimit)}GB.`);
  }
  return messages;
};

const validateImmersiveAudio = ({ metadata, file, validateAsAdmin }) => {
  const messages = getFileValidationMessages({ file, validateAsAdmin });
  const videoCodec = metadata?.video[0];
  const audioCodec = metadata?.audio[0];
  const generalInfo = metadata?.general;

  if (videoCodec) {
    messages.push('No video codecs should be present on immersive audio');
  }

  if (!audioCodec) {
    messages.push('No audio codec found');
  } else {
    if (audioCodec.BitRate < 160000) {
      messages.push('Bit rate should be greater than 160000');
    }
    if (audioCodec.Channels < 6) {
      messages.push('ATMOS audio channel number should be bigger than 5');
    }
    if (audioCodec.Channels > 8) {
      if (generalInfo.Format !== 'Wave' && generalInfo.Format !== 'Flac') {
        messages.push('Format should be WAVE, FLAC');
      }
      // ATMOS must be 24-bit LPCM audio at 48kHz.
      if (audioCodec.BitDepth != 24) {
        messages.push(`ATMOS audio's bits per sample should be 24 for Apple`);
      }
      if (audioCodec.SamplingRate != 48000) {
        messages.push(`ATMOS audio's sampling rate should be 48k for Apple`);
      }
    } else {
      // Surround must be at least 48kHz with 16-bit or 24-bit resolution LPCM.
      if (audioCodec.BitDepth != 16 && audioCodec.BitDepth != 24) {
        messages.push(`Surround audio's bits per sample should be either 16 or 24 for Apple`);
      }
      if (audioCodec.SamplingRate < 48000) {
        messages.push(`Surround audio's sampling rate should be bigger or equal than 48k for Apple`);
      }
    }
  }

  if (audioCodec?.Format !== 'PCM') {
    messages.push(`ATMOS audio's codec should be PCM for Apple`);
  }
  return { isFileValid: messages.length === 0, messages };
};

const validateAudio = ({ metadata, file, validateAsAdmin }) => {
  const messages = getFileValidationMessages({ file, validateAsAdmin });
  const audioCodec = metadata?.audio[0];
  const audioChannels = Number(audioCodec?.Channels);

  if (!audioCodec) {
    messages.push('No audio codec found');
  } else if (audioChannels !== 2) {
    messages.push('Audio content must have 2 audio channels.');
  }

  return { isFileValid: messages.length === 0, messages };
};

const isValid29FrameRate = frameRate => {
  //Any frame rates that are in the thousandths seems to be acceptable at the 29.97 range
  return 29.97 <= frameRate && frameRate <= 30;
};

const validateVideo = ({ metadata, file, validateAsAdmin }) => {
  const messages = getFileValidationMessages({ file, validateAsAdmin });
  const videoCodec = metadata?.video[0];
  const audioCodec = metadata?.audio[0];

  if (!videoCodec) {
    messages.push('No video codec found');
  } else {
    if (
      !(
        (videoCodec.Width == 720 && videoCodec.Height == 480) ||
        (videoCodec.Width == 1920 && videoCodec.Height == 1080) ||
        (videoCodec.Width == 3840 && videoCodec.Height == 2160)
      )
    ) {
      messages.push('Video content resolution should be 720x480, 1920x1080 or 3840x2160 for Apple.');
    }

    if (Number(videoCodec.Duration) < 30) {
      messages.push('Video content must be 30 seconds or more.');
    }

    if (videoCodec.ScanType !== 'Progressive') {
      messages.push('Video content scan type must be progressive.');
    }

    const frameRateNumber = Number(videoCodec.FrameRate);
    if (EXACT_FRAME_RATES.includes(frameRateNumber) === false && isValid29FrameRate(frameRateNumber) === false) {
      messages.push('Video content frame rate must be 23.976, 24, 25, 29.97x, or 30.');
    }
  }

  if (!audioCodec) {
    messages.push('No audio codec found');
  } else {
    const audioChannels = Number(audioCodec.Channels);

    if (audioChannels !== 2) {
      messages.push('Video content must have 2 audio channels.');
    }

    //Apple audio sample rate is 48kHz for all stereo videos. We follow apple's lead with all other     dsps on this front. https://help.apple.com/itc/videoaudioassetguide/#/itc284123bb3
    const videoSampleRate = Number(audioCodec.SamplingRate);

    if (videoSampleRate !== 48000) {
      messages.push('Video content stereo audio sampling rate must be 48kHz.');
    }
  }

  return { isFileValid: messages.length === 0, messages };
};

export { validateImmersiveAudio, validateAudio, validateVideo };
