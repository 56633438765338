import React, { useCallback, useEffect, useRef, useState } from 'react';
import ClipStateCollection from './clip-state-collection';
import ClipStateManager from './clip-state-manager';
import Button from '../../components/Button/button';
import CoverArt from 'components/CoverArt/cover-art';
import { Flex, Heading, useTheme, Text, Spacer } from '@chakra-ui/react';
import MarkerWaveForm from '../../components/TrackWaveform/marker-waveform';
import GenerateNewWaveForm from '../../components/TrackWaveform/generate-new-waveform';

const defaultPlaceholderArray = [
  'Song Title (sped up)',
  'Song Title (slowed down)',
  'Song Title (intro)',
  'Song Title (chorus)',
  'Song Title (bridge)',
  'Song Title (outro)',
];

function ClipEditor({ trackName, file, updateClips, currentClips, coverArt, artistName, hasBeenSubmitted }) {
  const theme = useTheme();
  const [clips, setClips] = useState(currentClips || new ClipStateCollection());

  //on loading some clips will not have the onSaveFunction.
  clips.forEach(
    clip =>
      (clip.onSaveState = newState =>
        setClips(current => {
          return current.replaceManager(newState);
        }))
  );
  const [activeClipPlayer, setActiveClipPlayer] = useState(-1);

  function deleteClipHandler(clip) {
    return function () {
      setActiveClipPlayer(-1);
      setClips(current => {
        const newClips = current.without(clip);
        updateClips(newClips);
        return newClips;
      });
    };
  }

  const highestIndex = useRef(clips.highestIdx || 0);

  function defaultClipStateFactory(trackName, file) {
    const number = highestIndex.current;
    highestIndex.current = highestIndex.current + 1;

    return new ClipStateManager(
      {
        stateData: {
          clipName: ``,
          startTimeSeconds: 0,
          endTimeSeconds: 60,
          speedPercent: 100,
          file,
        },
        saveState(newState) {
          setClips(current => {
            return current.replaceManager(newState);
          });
        },
      },
      `${trackName}-${number}`,
      null,
      highestIndex.current
    );
  }

  const onNewClip = useCallback(() => {
    if (clips.length >= 6) {
      alert('You can only have 6 clips per track.');
    }

    setClips(currentClips => {
      return currentClips.addManager(defaultClipStateFactory(trackName, file));
    });
  }, [trackName, file]);

  const onMarkerClickHandler = index => {
    const anchorElement = document.getElementById(`anchor-${clips[index].name}`);
    anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  useEffect(() => {
    if (clips.length === 0) {
      onNewClip();
    }

    updateClips(clips);
  }, [clips, onNewClip]);

  if (!file) {
    return null;
  }

  const createSetPlayingHandler = idx => () => {
    setActiveClipPlayer(currentIdx => (idx === currentIdx ? -1 : idx));
  };

  return (
    <>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        textAlign={'left'}
        fontSize={'1rem'}
        fontWeight={'bold'}
        color={theme.colors.black80}
        paddingBottom={'1rem'}>
        {coverArt && <CoverArt file={coverArt} />}
        <Heading as={'h3'} fontSize={'1.5rem'} ml={'1rem'}>
          {trackName ? trackName : file.name}
        </Heading>
        {artistName && (
          <Text fontWeight={'normal'} ml={'1rem'}>
            by {artistName}
          </Text>
        )}
        <Spacer />
        {clips.length} Clip{clips.length > 1 ? `s` : ''}
      </Flex>
      <MarkerWaveForm
        file={file}
        markers={clips.createMarkers({
          color: theme.colors.pink,
        })}
        onMarkerClickHandler={onMarkerClickHandler}
      />
      {clips.map((clip, i) => (
        <GenerateNewWaveForm
          key={`generate-new-wave-form-${clip.name}`}
          clipKey={clip.name}
          {...clip.props}
          clip={clip}
          onDeleteClip={deleteClipHandler(clip)}
          canDelete={clips.length > 1 && !hasBeenSubmitted}
          isActivePlayer={activeClipPlayer === i}
          setIsActivePlayer={createSetPlayingHandler(i)}
          hasBeenSubmitted={hasBeenSubmitted}
          clipNamePlaceholder={defaultPlaceholderArray[i % defaultPlaceholderArray.length]}
        />
      ))}
      <Flex justifyContent={'left'} paddingTop={'1rem'} paddingBottom={'1rem'}>
        <Button
          disabled={!file || clips.length >= 6 || hasBeenSubmitted}
          onClick={onNewClip}
          dark={true}
          text={'+ Create Additional Clip'}
        />
      </Flex>
    </>
  );
}

export default ClipEditor;
