import React, { useState, useCallback } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import UpdateStripePaymentInfo from './stripe-update-account';
import CreditCardIndicator from '../../components/CreditCardIndicator/credit-card-indicator';
import FieldLabel from '../../components/FieldLabel/field-label';

const SpacedDot = () => {
  return (
    <Text padding="0 3px" margin="0" position="relative" top="2px" display="inline-block" fontSize="1.75rem">
      &#183;
    </Text>
  );
};

const ManagePaymentInfo = ({ stripeSubscription }) => {
  const { cardInfo, cancelAtPeriodEnd, isCanceled, currentPeriodEnd } = stripeSubscription;
  const [isEditing, setIsEditing] = useState(false);

  const onEditFinished = useCallback(() => {
    setIsEditing(false);
  }, [setIsEditing]);

  const subscriptionWillBeCanceledText = `Your membership is set to not auto-renew and will be inactive as of ${currentPeriodEnd}. If you would like to enable your membership, select “Enable Membership” below.`;

  return cancelAtPeriodEnd ? (
    <Box>
      <Text color="red">{subscriptionWillBeCanceledText}</Text>
    </Box>
  ) : (
    <Box width={'100%'}>
      <FieldLabel light text="Payment Method"></FieldLabel>
      {isEditing ? (
        <UpdateStripePaymentInfo closeStripeElement={onEditFinished} isAccountReactivation={isCanceled} />
      ) : (
        <Flex justifyContent="space-between" alignItems="center" width="100%">
          <Flex alignItems="center">
            <Box margin="0 2px" padding="2px" border="1px solid" borderRadius="2px" position="relative" top="2px">
              <CreditCardIndicator cardBrand={cardInfo.brand} />
            </Box>
            <Box>
              <SpacedDot />
              <SpacedDot />
              <SpacedDot />
              <SpacedDot /> {cardInfo.last4}
            </Box>
          </Flex>
          <Box textAlign={'right'}>
            <Button variant="smallDark" onClick={() => setIsEditing(true)}>
              {isCanceled ? 'Reactivate' : 'Change Card Details'}
            </Button>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default ManagePaymentInfo;
