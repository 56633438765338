import { useState, useMemo, useRef, useCallback } from 'react';

/** @typedef {string|number} MessageId */
/** @typedef {"typing"|"user"|"agent"|"loading"|"ai"|"emailCollection"|"delta"} MessageType */

/**
 * @typedef {object} Message
 * @property {string} [text]
 * @property {string} [unfurled_text]
 * @property {number} [timestamp]
 * @property {MessageType} type
 * @property {MessageId} id
 */

/**
 * @typedef {Omit<Message, 'id'>} MessageWithoutId
 */

const TYPING_ID = '_TYPING_';
const TIME_GAP = 5 * 60 * 1000;
let lastTs = 0;

/**
 * Returns a string with at least 64-bits of randomness.
 *
 * Doesn't trust Javascript's random function entirely. Uses a combination of
 * random and current timestamp, and then encodes the string in base-36 to
 * make it shorter.
 *
 * From: https://medium.com/este-js-framework/its-ok-to-use-javascript-generated-guid-in-a-browser-373ca6431cf7
 * Code: https://github.com/google/closure-library/blob/555e0138c83ed54d25a3e1cd82a7e789e88335a7/closure/goog/string/string.js#L1177
 *
 * @return {string} A random string, e.g. sn1s7vb4gcic.
 */
function getRandomString() {
  const x = 2147483648;
  return (
    Math.floor(Math.random() * x).toString(36) +
    // eslint-disable-next-line no-bitwise
    Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36)
  );
}

const urlRegex = /\b((https?):\/\/|(www)\.)[-A-Z0-9+&@#/%?=~_|$!:,.]*[A-Z0-9+&@#/%=~_|$](?![^(]*\))/gi;
const emailRegex = /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

/**
 * @param {MessageWithoutId} msg
 * @param {MessageId} [id]
 * @returns {Message & { createdAt: number, hasTime: boolean }}
 */
export const makeMsg = (msg, id) => {
  const ts = msg.timestamp || Date.now();
  const hasTime = msg.hasTime || ts - lastTs > TIME_GAP;

  if (typeof msg.text === 'string') {
    msg.unfurled_text = msg.unfurled_text || msg.text.replace(urlRegex, '[$&]($&)').replace(emailRegex, '<$&>');
  }

  if (hasTime) {
    lastTs = ts;
  }

  return {
    ...msg,
    id: id || msg.id || getRandomString(),
    createdAt: ts,
    hasTime,
  };
};

/**
 * @export
 * @param {MessageWithoutId[]} [initialState=[]]
 * @return
 */
export default function useMessages(initialState = []) {
  const initialMsgs = useMemo(() => initialState.map(t => makeMsg(t)), [initialState]);
  const [messages, setMessages] = useState(initialMsgs);
  const isTypingRef = useRef(false);

  /** @type {(msgs: Message[]) => void} */
  const prependMsgs = useCallback(msgs => {
    setMessages(prev => [...msgs, ...prev]);
  }, []);

  /** @type {(id: MessageId, msg: MessageWithoutId) => void} */
  const updateMsg = useCallback((id, msg) => {
    setMessages(prev => prev.map(t => (t.id === id ? makeMsg(msg, id) : t)));
  }, []);

  /** @type {(msg: MessageWithoutId) => void} */
  const appendMsg = useCallback(
    msg => {
      const newMsg = makeMsg(msg);

      if (isTypingRef.current) {
        updateMsg(TYPING_ID, newMsg);
      } else {
        setMessages(prev => [...prev, newMsg]);
      }
    },
    [updateMsg]
  );

  /** @type {(id: MessageId) => void} */
  const deleteMsg = useCallback(id => {
    setMessages(prev => prev.filter(t => t.id !== id));
  }, []);

  /** @type {MessageWithoutId[]} */
  const resetList = useCallback((list = []) => {
    setMessages(list);
  }, []);

  /**
   * Set typing indicator to true/false for the remote party
   *
   * @param {boolean} typing
   * @return {void}
   */
  const setTyping = useCallback(
    typing => {
      if (typing === isTypingRef.current) return;

      if (typing) {
        appendMsg({
          id: TYPING_ID,
          type: 'typing',
        });
      } else {
        deleteMsg(TYPING_ID);
      }
      isTypingRef.current = typing;
    },
    [appendMsg, deleteMsg]
  );

  return {
    messages,
    prependMsgs,
    appendMsg,
    updateMsg,
    deleteMsg,
    resetList,
    setTyping,
  };
}
