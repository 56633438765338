import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

export default function SearchHeading({ searchTerm, searchQueryData }) {
  const noResults = !searchQueryData?.tracks?.results.length && !searchQueryData?.releases?.results.length;

  if (noResults) {
    return (
      <Box mt="1rem" w="100%">
        <Heading as="h2" fontSize="xl" w="100%" textAlign={'center'}>
          Nothing coming up.
        </Heading>
        <Text w="100%" textAlign={'center'} color="black100" mt="1rem" fontSize={'1rem'}>
          Try searching for a specific track or release from your Venice catalog
        </Text>
      </Box>
    );
  }
  return (
    <Heading as="h2" fontSize="xl" mb="2.5rem">
      {!searchTerm ? 'Or choose from one of the following recently delivered releases' : 'Releases'}
    </Heading>
  );
}
