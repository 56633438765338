import ManageClipsStateManager from '../manage/manage-clips-state-manager';

class StateManagerCollection extends Array {
  StateManagerItemClass;

  constructor(...managers) {
    super(...managers);
    if (
      process.env.RUNTIME === 'local' &&
      managers.length > 0 &&
      managers.some(m => !(m instanceof ManageClipsStateManager))
    ) {
      throw new Error(`${this.constructor.name} only accepts ${this.StateManagerItemClass} as acceptable items`);
    }
    super.forEach(manager => {
      this[manager.name] = manager;
    });
  }

  without(...managers) {
    return new this.constructor(...super.filter(manager => !managers.includes(manager)));
  }

  addManager(manager) {
    return new this.constructor(...this, manager);
  }

  replaceManager(manager) {
    let newManagers = [];

    for (const m of Array.from(this)) {
      if (m.name === manager.name) {
        newManagers = [...newManagers, manager];
      } else {
        newManagers = [...newManagers, m];
      }
    }
    return new this.constructor(...newManagers);
  }

  transform(predicate) {
    return new this.constructor(...super.map(predicate));
  }
}

export default StateManagerCollection;
