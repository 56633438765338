import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { renderFieldError } from 'utils/form';
import Icon from '../Icon/icon';

const Wrapper = styled.div`
  position: relative;
`;

export const SelectWrapper = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: ${props => {
    return props.theme.colors.background.dropdown[props.variant].default;
  }};
  border: 1px solid ${props => props.theme.colors.border.dropdown[props.variant].default};
  border-radius: 4px;
  color: ${props => props.theme.colors.text.dropdown[props.variant].default};
  font-size: ${props => props.theme.fonts.sizes.reg};
  line-height: 18px;
  margin: 0;
  padding: 0.75rem 1rem;
  padding-right: 2.5rem;
  transition: all 250ms ease;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.colors.background.dropdown[props.variant].hover};
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    background-color: ${props.theme.colors.background.dropdown[props.variant].disabled};
    color: ${props.theme.colors.text.dropdown[props.variant].disabled};
  `}

  ${props =>
    props.invalid &&
    `
    background: ${props.theme.colors.background.dropdown[props.variant].error};
    border: 1px solid ${props.theme.colors.border.dropdown[props.variant].error};
    color: ${props.theme.colors.text.dropdown[props.variant].error};
  `}
`;

const SelectChevron = styled(Icon)`
  position: absolute;
  left: calc(100% - 1.875rem);
  top: 50%;
  transform: translateY(-50%);
`;

const StyledSelect = React.forwardRef(({ className, name, error, disabled, children, heapCode, ...props }, ref) => {
  const variant = props?.variant || 'dark';

  return (
    <Wrapper className={className} variant={variant}>
      <SelectChevron type="chevronDown" />
      <SelectWrapper
        name={name}
        ref={ref}
        invalid={error}
        disabled={disabled}
        data-heap={heapCode}
        variant={variant}
        {...props}>
        {children}
      </SelectWrapper>
      {error && renderFieldError(error)}
    </Wrapper>
  );
});
StyledSelect.displayName = 'StyledSelect';

StyledSelect.defaultProps = {
  heapCode: null,
};

export default withTheme(StyledSelect);
