import React from 'react';
import { useTheme, Box, Heading, Text } from '@chakra-ui/react';
export default function ListItem({ title, value, subText }) {
  const theme = useTheme();
  return (
    <Box my={'1rem'}>
      <Heading as="h5" fontSize=".8rem" mb={'0.5rem'}>
        {title}
      </Heading>
      {subText ? (
        <Text fontSize={'0.75rem'} mb={'0.5'}>
          {subText}
        </Text>
      ) : (
        ''
      )}
      <Text color={theme.colors.black90}>{value}</Text>
    </Box>
  );
}
