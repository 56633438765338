import React from 'react';
import { chakra, useTheme, useStyleConfig } from '@chakra-ui/system';

//This allows variants on html small element.
const Small = ({ variant, children, ...props }) => {
  const theme = useTheme();

  const Small = chakra('small', {
    ...theme.components.Small,
  });

  const styles = useStyleConfig('Small', { variant });

  return (
    <Small __css={styles} {...props}>
      {children}
    </Small>
  );
};

export default Small;
