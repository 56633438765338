import React from 'react';
import { Box, Heading, Text, useTheme } from '@chakra-ui/react';

import HeaderAndContentBox from './clip-review-header-and-content';
import ListItem from './clip-review-list-item';
import SimpleAudioPlayer from 'components/MediaPlayer/simple-audio-player';
import PartyRow from './clip-review-and-submit-party-row';

export default function ReviewAndSubmitTrackDetails({ stateManager, stateData, artistsQuery, contributorsQuery }) {
  const { isLoading: artistsLoading, data: artists } = artistsQuery;
  const { isLoading: contributorsLoading, data: contributors } = contributorsQuery;

  const theme = useTheme();

  return (
    <HeaderAndContentBox stateManager={stateManager} title={`Track Details`}>
      <Box my={'1rem'}>
        <Heading as="h5" fontSize=".8rem" mb={'0.5rem'}>
          What is the name of the track?
        </Heading>
        <Box color={theme.colors.black90}>
          <SimpleAudioPlayer file={stateData.audio_source} />
          {stateData.track?.title}
        </Box>
      </Box>
      <Box my={'1rem'}>
        <Heading as="h5" fontSize=".8rem" mb={'0.5rem'}>
          What artists are on the track?
        </Heading>
        <Box>
          {artistsLoading ? (
            <Box>Loading ... </Box>
          ) : (
            stateData.track?.artists?.map(artist => (
              <PartyRow
                key={`artist-${artist.id}`}
                party={{ ...artists?.find(a => Number(a.id) === Number(artist.asset_party_id)), ...artist }}
              />
            ))
          )}
        </Box>
      </Box>
      <Box my={'1rem'}>
        <Heading as="h5" fontSize=".8rem" mb={'0.5rem'}>
          Who should be in the tracks credits?
        </Heading>
        <Box>
          {contributorsLoading ? (
            <Box>Loading ... </Box>
          ) : (
            stateData.track?.contributors?.map(contributor => (
              <PartyRow
                key={`contributor-${contributor.id}`}
                party={{
                  ...contributors?.find(c => Number(c.id) === Number(contributor.asset_party_id)),
                  ...contributor,
                }}
              />
            ))
          )}
        </Box>
      </Box>
      <ListItem title={'What genre is this track?'} value={stateData.track?.genre} subText={'Primary Genre'} />
      <ListItem title={'Do you have your own ISRC'} value={stateData.track?.isrc_code ? 'Yes' : 'No'} />
      {stateData.track?.isrc_code ? (
        <>
          <Text fontWeight={'bold'}>ISRC</Text>
          <Text color={theme.colors.black90}>{stateData.track?.isrc_code}</Text>
        </>
      ) : (
        ''
      )}
      <ListItem title={'Does this track have lyrics?'} value={stateData.track?.lyrics === null ? 'No' : 'Yes'} />
      <ListItem
        title={'Does this track have explicit material?'}
        value={stateData.track?.parental_warning === 'Explicit' ? 'Yes' : 'No'}
      />
      <ListItem title={'What language is this track in?'} value={stateData.track?.lang} />
      <ListItem title={'Lyrics (Only sent to Apple)'} value={stateData.track?.lyrics} />
    </HeaderAndContentBox>
  );
}
