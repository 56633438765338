import React from 'react';
import asModal from 'features/Modal/as-modal';
import {
  Box,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useTheme,
  ButtonGroup,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import { FormHelperText } from '@chakra-ui/react/dist/chakra-ui-react.cjs';

function CoManagerActionItemModal({ actionItem, onUpdate, errors = '', onClearErrors, ...props }) {
  const theme = useTheme();

  const withError = errors !== undefined;

  const formLabelStyles = {
    mb: '0.15rem',
    fontSize: '.6rem',
    textTransform: 'uppercase',
    color: theme.colors.black60,
  };

  const inputStyles = {
    borderColor: theme.colors.black60,
    borderRadius: '.25rem',
    mb: `0${!withError ? '.75rem' : ''}`,
  };

  const handleModelUpdate = event => {
    const { target } = event;
    const { name, value } = target;
    onUpdate({ ...actionItem, [name]: value });
  };

  const isTitleError = errors.includes('"title"');
  const isFrequencyError = errors.includes('"frequency"');
  const isFrequencyRepeatError = errors.includes('"frequency_repeat"');
  const isDescriptionError = errors.includes('"description"');

  return (
    <Box key="add-action-item" as="form" id="add-action-item" mt="1rem">
      <Stack color="black100">
        <FormControl>
          <FormLabel {...formLabelStyles}>Title</FormLabel>
          <Input
            name="title"
            isRequired
            errorBorderColor="crimson"
            onChange={handleModelUpdate}
            onFocus={onClearErrors}
            value={actionItem.title}
            isInvalid={isTitleError}
            {...inputStyles}></Input>
          {isTitleError && <FormHelperText margin=".5rem 0 1rem 0">Title is required</FormHelperText>}
        </FormControl>
        <FormControl>
          <FormLabel {...formLabelStyles}>Frequency</FormLabel>
          <ButtonGroup isAttached variant="outline">
            {['Once', 'Daily', 'Weekly', 'Forever'].map(frequency => (
              <Button
                borderColor={isFrequencyError ? 'crimson' : theme.colors.black60}
                backgroundColor={
                  actionItem.frequency === frequency.toLocaleLowerCase() ? theme.colors.black100 : 'white'
                }
                color={actionItem.frequency === frequency.toLocaleLowerCase() ? 'white' : theme.colors.black100}
                onClick={() => {
                  onClearErrors();
                  onUpdate({
                    ...actionItem,
                    frequency: frequency.toLocaleLowerCase(),
                    frequency_repeat: frequency === 'Daily' || frequency === 'Weekly' ? 1 : undefined,
                  });
                }}>
                {frequency}
              </Button>
            ))}
          </ButtonGroup>
          {isFrequencyError && <FormHelperText margin=".5rem 0 1rem 0">Frequency is required</FormHelperText>}
        </FormControl>
        {actionItem.frequency === 'daily' || actionItem.frequency === 'weekly' ? (
          <FormControl>
            <FormLabel {...formLabelStyles}>Time(s)</FormLabel>
            <Slider
              name="frequency_repeat"
              flex="1"
              min={1}
              defaultValue={1}
              max={actionItem.frequency === 'daily' ? 30 : 10}
              onFocus={onClearErrors}
              onChange={val => onUpdate({ ...actionItem, frequency_repeat: val })}
              isInvalid={isFrequencyRepeatError}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb fontSize="sm" boxSize="32px" children={actionItem.frequency_repeat} />
            </Slider>
            {isFrequencyRepeatError && (
              <FormHelperText margin=".5rem 0 1rem 0">
                Time(s) required to be between 1 and {actionItem.frequency === 'daily' ? 30 : 10}
              </FormHelperText>
            )}
          </FormControl>
        ) : undefined}
        <FormControl>
          <FormLabel {...formLabelStyles}>Description</FormLabel>
          <Textarea
            name="description"
            isRequired
            errorBorderColor="crimson"
            onChange={handleModelUpdate}
            value={actionItem.description}
            noOfLines={3}
            isInvalid={isDescriptionError}
            onFocus={onClearErrors}
            {...inputStyles}></Textarea>
          {isDescriptionError && <FormHelperText margin=".5rem 0 1rem 0">Description is invalid.</FormHelperText>}
        </FormControl>
      </Stack>
    </Box>
  );
}

export default asModal(CoManagerActionItemModal);
