import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Box, Heading, Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import CustomTab from './custom-tab';
import ViewWrapper from 'views/ViewWrapper/view-wrapper';
import * as API from 'utils/API/API';
import { buildStatus } from 'utils/helpers';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@chakra-ui/react';

import { labelRoles } from 'constants/roles';
import ArtistsTabContainer from './artists-tab-container';
import PayeesTabContainer from './payees-tab-container';
import Team from './team';
import CompanyProfile from './company-profile';
import MyProfile from './my-profile';
import StripeModal from './stripe-modal';
import { useUserTierChecks } from '../hooks/authorization-hooks';
import SpecialAccessContainer from './special-access/special-access-container';
import { MembershipTab } from './membership-tab';

const User = API.user();
const Payment = API.payment();
const settingsTabs = ['my_profile', 'membership', 'label_profile', 'team', 'artists', 'payees', 'special_access'];

const SettingsContainer = ({ ...props }) => {
  const { data: user } = useQuery(['user'], () => User.getMe());

  const isAdmin = user?.currentGroup?.role === labelRoles.get('admin');

  const showCompanySection = ['Label Member', 'Label Admin'].includes(user?.currentGroup?.role);

  const selectedUserGroup = user?.currentGroup;
  const groupId = user?.currentGroup?.id;

  const theme = useTheme();

  const { canAccessSpotifyForArtists } = useUserTierChecks();
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [selectedStripeType, setSelectedStripeType] = useState(null);
  const [stripeLinkStatus, setStripeLinkStatus] = useState(buildStatus(null));
  const { hash } = useLocation();

  let initialTab = 0;
  if (hash) {
    const [nonQueryPortion] = hash.split('?').filter(x => x);
    const tabName = nonQueryPortion.replace('#', '');
    const tabIndex = settingsTabs.indexOf(tabName);
    initialTab = tabIndex === -1 ? 0 : tabIndex;
  }

  const loadStripeLoginLink = async (id, setter) => {
    const { origin } = window.location;
    try {
      const stripeData = await Payment.getStripeLoginLink(id, origin);
      if (stripeData?.url) {
        setter(stripeData);
      }
    } catch (error) {
      //There isn't really much we can do here if our stripe request fails. We should continue
      //to try to move the page forward and user's will have to context support if they can't do what they need to.
      console.error(`Missing stripe data, ${error}`);
    }
  };

  const openStripeModalHandler = type => {
    setSelectedStripeType(type);
    setShowStripeModal(true);
  };

  const closeStripeModalHandler = () => {
    setShowStripeModal(false);
  };

  const showSpecialAccess = showCompanySection && canAccessSpotifyForArtists;

  return (
    <ViewWrapper designLayer={0} disableHorizontalScroll={true} {...props}>
      <Box>
        <Box>
          <Heading as="h1">Settings</Heading>
        </Box>
        <Tabs
          orientation="vertical"
          flexDirection={{ base: 'column', sm: 'row' }}
          variant="unstyled"
          defaultIndex={initialTab}
          onChange={index => {
            history.pushState(null, null, `#${settingsTabs[index]}`);
          }}>
          <TabList
            mt={{ base: '0', sm: '4' }}
            mx={{ base: '4', sm: '0' }}
            flexDirection={{ base: 'row', sm: 'column' }}
            overflowX={{ base: 'auto', sm: 'inherit' }}>
            <CustomTab>My Profile</CustomTab>
            <CustomTab>Membership</CustomTab>
            <CustomTab display={showCompanySection ? 'inherit' : 'none'}>
              {showCompanySection && 'Label Profile'}
            </CustomTab>
            <CustomTab display={showCompanySection ? 'inherit' : 'none'}>{showCompanySection && 'Team'}</CustomTab>
            <CustomTab display={showCompanySection ? 'inherit' : 'none'}>{showCompanySection && 'Artists'}</CustomTab>
            <CustomTab display={showCompanySection ? 'inherit' : 'none'}>{showCompanySection && 'Payees'}</CustomTab>
            <CustomTab display={showSpecialAccess ? 'inherit' : 'none'}>
              {showSpecialAccess && 'Special Access'}
            </CustomTab>
          </TabList>
          <TabPanels mb="32">
            <TabPanel p="0">
              {
                <MyProfile
                  user={user}
                  stripeLinkStatus={stripeLinkStatus}
                  openStripeModalHandler={openStripeModalHandler}
                  loadStripeLoginLink={loadStripeLoginLink}
                  theme={theme}
                />
              }
            </TabPanel>
            <TabPanel p="0">
              <MembershipTab user={user} />
            </TabPanel>
            <TabPanel p="0">
              {showCompanySection && (
                <CompanyProfile
                  selectedUserGroup={selectedUserGroup}
                  stripeLinkStatus={stripeLinkStatus}
                  openStripeModalHandler={openStripeModalHandler}
                  loadStripeLoginLink={loadStripeLoginLink}
                  theme={theme}
                />
              )}
            </TabPanel>
            <TabPanel p="0">
              {showCompanySection && <Team isAdmin={isAdmin} groupId={groupId} theme={theme} />}
            </TabPanel>
            <TabPanel p="0">
              {showCompanySection && <ArtistsTabContainer isAdmin={isAdmin} isSystemAdmin={user?.system_admin} />}
            </TabPanel>
            <TabPanel p="0">
              {showCompanySection && <PayeesTabContainer groupId={groupId} isAdmin={isAdmin} />}
            </TabPanel>
            <TabPanel p="0">
              {showSpecialAccess && <SpecialAccessContainer groupId={groupId} isAdmin={isAdmin} />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      {showStripeModal && (
        <StripeModal
          setStripeLinkStatus={setStripeLinkStatus}
          selectedStripeType={selectedStripeType}
          showStripeModal={showStripeModal}
          closeStripeModalHandler={closeStripeModalHandler}
          stripeLinkStatus={stripeLinkStatus}
          user={user}
          selectedUserGroup={selectedUserGroup}
        />
      )}
    </ViewWrapper>
  );
};

export default SettingsContainer;
