import React, { useState } from 'react';
import icons from '../../utils/icons';
import { Radio, HStack, Box } from '@chakra-ui/react';

function StarRating({ rating, setRating, count, size }) {
  const [hover, setHover] = useState(null);

  return (
    <HStack spacing="0">
      {[...Array(count || 5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <Box
            as="label"
            key={index}
            color={ratingValue <= (hover || rating) ? 'pink' : '#e4e5e9'}
            onMouseEnter={() => setHover(ratingValue)}
            onMouseLeave={() => setHover(null)}
            paddingLeft="2px">
            <Radio name="rating" onChange={() => setRating(ratingValue)} value={ratingValue} display="none"></Radio>
            <icons.star cursor={'pointer'} size={size || 20} transition="color 200ms" />
          </Box>
        );
      })}
    </HStack>
  );
}

export default StarRating;
