import styled from '@emotion/styled/macro';
import { Col } from 'react-styled-flexboxgrid';
import { ButtonGroup } from 'styles';

export const RightCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end;
`;

export const CoverPadder = styled.div`
  bottom: -2rem;
  height: 2rem;
  position: absolute;
  width: 100%;
`;

export const BankInfoDescription = styled.p`
  color: ${props => props.theme.colors.black100};
  font-size: ${props => props.theme.fonts.sizes.reg};
`;

export const SmallText = styled.small`
  color: ${props => props.theme.colors.black100};
`;

export const FieldValue = styled.div`
  color: ${props => props.theme.colors.black100};
`;

export const CenterContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

export const ModalError = styled.div`
  color: ${props => props.theme.colors.negative};
`;

export const ModalButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;

export const StripeContainer = styled.div`
  div {
    color: ${props => props.theme.colors.white};
  }
  a {
    text-decoration: none;
    font-weight: 700;
  }
`;
