export const TIMEFRAME_OPTIONS = {
  ALL_TIME: {
    value: 'all_time',
    label: 'From All Time',
  },
  YTD: {
    value: 'ytd',
    label: 'Year to Date',
  },
};

// These need to be ISO-639.2 (used by DDEX)
// https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes
export const LANGUAGES2 = [
  { value: 'en', label: 'English (EN)' },
  { value: 'pt', label: 'Portuguese (PT)' },
  { value: 'es', label: 'Spanish (ES)' },
  { value: 'fr', label: 'French (FR)' },
  { value: 'ar', label: 'Arabic (AR)' },
  { value: 'bn', label: 'Bengali (BN)' },
  { value: 'ru', label: 'Russian (RU)' },
  { value: 'ro', label: 'Romanian (RO)' },
  { value: 'zh-Hans', label: 'Chinese-Simplified (ZH-HANS)' },
  { value: 'zh-Hant', label: 'Chinese-Traditional (ZH-HANT)' },
  { value: 'yue-Hant', label: 'Cantonese (YUE-HANT)' },
  { value: 'hi', label: 'Hindi (HI)' },
  { value: 'id', label: 'Indonesian (ID)' },
  { value: 'ja', label: 'Japanese (JA)' },
].sort((a, b) => a.label.localeCompare(b.label)); // puts list in alphabetical order

export const PARENTAL_WARNINGS = [
  { label: 'Non-Explicit', value: 'NotExplicit' },
  { label: 'Explicit', value: 'Explicit' },
  { label: 'Clean', value: 'ExplicitContentEdited' },
];
