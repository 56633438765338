import * as React from 'react';
import { useTheme } from '@chakra-ui/react';
import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';

const PieChart = ({ data, width, height }) => {
  const chakraTheme = useTheme();
  const minSize = Math.min(width, height);
  const half = minSize / 2;

  if (data?.length === 0) return 'No data to show';

  return (
    <svg width={minSize} height={minSize}>
      <Group top={half} left={half}>
        <Pie data={data} pieValue={d => d.streams} outerRadius={half} innerRadius={() => half - 42}>
          {pie => {
            return pie.arcs.map(arc => {
              return (
                <g key={arc.data.dspName}>
                  <path d={pie.path(arc)} fill={arc.data.fillColor || chakraTheme.colors.dsp[arc.data.dspName]}></path>
                </g>
              );
            });
          }}
        </Pie>
      </Group>
    </svg>
  );
};

export default PieChart;
