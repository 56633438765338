import React from 'react';
import { Checkbox, Flex, Box, Button } from '@chakra-ui/react';

function MultiCheckboxes({ options, checked, setChecked, disabled }) {
  function onChange({ target: { value } }) {
    if (!checked.includes(value)) return setChecked([...checked, value]);
    setChecked([...checked.filter(v => v !== value)]);
  }

  function selectAll() {
    setChecked([...options.map(option => option.value)]);
  }

  function deSelectAll() {
    setChecked([]);
  }

  return (
    <Box>
      <Flex justifyContent="flex-end" mb="20px">
        <Button mr="20px" p=".25rem" variant="link" onClick={selectAll} disabled={disabled}>
          Select All
        </Button>
        <Button variant="link" p=".25rem" onClick={deSelectAll} disabled={disabled}>
          Deselect All
        </Button>
      </Flex>
      <Flex direction="column" maxHeight="400px" overflow="auto">
        {options.map(option => (
          <Checkbox
            disabled={disabled}
            value={option.value}
            onChange={onChange}
            isChecked={checked.includes(option.value)}>
            {option.label}
          </Checkbox>
        ))}
      </Flex>
    </Box>
  );
}

export default MultiCheckboxes;
