import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = `${dataApiUrl()}/v2-releases`;

export async function getReleaseById({ id }) {
  return AuthAxios.get(`${DataApiUrl}/${id}`).then(res => {
    return res.data.data;
  });
}

export async function createRelease(body) {
  return AuthAxios.post(DataApiUrl, body).then(res => {
    return res.data.data;
  });
}

export async function updateRelease(body) {
  return AuthAxios.patch(`${DataApiUrl}/${body.asset_release_id}`, body).then(res => {
    return res.data.data;
  });
}

export async function addTrackToRelease(body) {
  return AuthAxios.post(`${DataApiUrl}/${body.asset_release_id}/add-track-to-release`, body).then(res => {
    return res.data.data;
  });
}
