import React from 'react';
import { chakra, useTheme, useStyleConfig } from '@chakra-ui/system';

//the html type header is required because another place in the app does a standard query off of they html element type.
//This also serves as an example of how to use the theme with a standard html element with chakra.
const Header = ({ variant, children, ...props }) => {
  const theme = useTheme();

  const Header = chakra('header', {
    ...theme.components.Header,
  });

  const styles = useStyleConfig('Header', { variant });

  return (
    <Header __css={styles} {...props}>
      {children}
    </Header>
  );
};

export default Header;
