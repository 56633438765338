import React from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { Input, InputGroup, InputLeftElement, useTheme } from '@chakra-ui/react';

const SearchBar = ({ searchTerm, onSearchTermChange, testCode, ...props }) => {
  const theme = useTheme();

  const { inputGroupProps: { style: inputGroupPropsStyle, ...inputGroupProps } = {} } = props;
  return (
    <InputGroup
      style={{
        //inputgroup doesn't allow for variants, styling via style is the most efficent way to make it look the way requested
        alignItems: 'center',
        backgroundColor: theme.colors.white,
        borderRadius: '2.5rem',
        border: `1px solid ${theme.colors.black60}`,
        display: 'flex',
        minWidth: '2.5rem',
        transition: 'border-radius 250ms ease',
        width: props?.width || '100%',
        zIndex: '20',
        padding: '0 16px',
        height: '40px',
        ...inputGroupPropsStyle,
      }}
      {...inputGroupProps}>
      <InputLeftElement children={<RiSearchLine color={theme.colors.black60} />} />

      <Input
        variant="unstyled"
        border="none"
        backgroundColor="transparent"
        width="100%"
        marginRight="1rem"
        placeholder={props.placeholder || 'Search name, title, isrc, or upc'}
        _placeholder={{ color: theme.colors.black50 }}
        value={searchTerm}
        color={theme.colors.black60}
        onChange={({ target: { value } }) => onSearchTermChange(value)}
        data-cy={global.APP.RUNTIME === 'production' ? undefined : testCode}
      />
    </InputGroup>
  );
};

export default SearchBar;
