import * as React from 'react';
import { useQuery } from 'react-query';
import { Image } from '@chakra-ui/react';
import FallbackCoverArt from 'assets/fallback-cover-art.png';
import { convertBufferIntoImgUrl } from 'utils/helpers';
import * as API from 'utils/API/API';

const Release = API.release();

export default function Artwork({ src, releaseId, ...props }) {
  const releaseArtworkQuery = useQuery(
    [`releaseArtwork`, releaseId],
    () => Release.defaultArtwork(releaseId, '720x720'),
    { enabled: !!releaseId, staleTime: Infinity }
  );

  const imgUrl = React.useMemo(() => {
    if (releaseArtworkQuery.isSuccess) {
      return convertBufferIntoImgUrl(releaseArtworkQuery.data);
    }
    return FallbackCoverArt;
  }, [releaseArtworkQuery.isSuccess, releaseArtworkQuery.data]);

  return <Image src={releaseId ? imgUrl : src} fallbackSrc={FallbackCoverArt} borderRadius={4} {...props} />;
}
