import React from 'react';
import { RoleRow } from './role-manager';
import { Box, Text, Flex } from '@chakra-ui/react';
import LabeledSelect from '../labeled-select/labeled-select';

export default function ArtistRowRole({
  rolesOptions,
  addArtistModal,
  artistsOptions,
  requiredArtist,
  isFirst,
  displayArtists,
  ...props
}) {
  if (isFirst && requiredArtist) {
    return (
      <RoleRow
        PartySelectorComponent={({ ...props }) => <RequiredArtistDisplay {...props} artist={requiredArtist} />}
        requiredArtist={requiredArtist}
        addArtistModal={addArtistModal}
        rolesOptions={rolesOptions}
        isRequired={true}
        typeOfSelect={'Artist'}
        {...props}
      />
    );
  }
  return (
    <RoleRow
      PartySelectorComponent={({ ...props }) => <ArtistLabeledSelect displayArtists={displayArtists} {...props} />}
      rolesOptions={rolesOptions}
      typeOptions={artistsOptions}
      typeOfSelect={'Artist'}
      {...props}
    />
  );
}

export function ArtistLabeledSelect({ displayArtists, update, onBlur, rowData, options, ...props }) {
  return (
    <LabeledSelect
      width={'100%'}
      name="asset_party_id"
      label=""
      value={rowData?.asset_party_id}
      onChange={update}
      onBlur={onBlur}
      options={options || []}
      {...props}
    />
  );
}

export function RequiredArtistDisplay({ artist, ...props }) {
  return (
    <>
      <Box
        width={'100%'}
        marginTop={'5px'}
        direction={'row'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        {...props}
        padding={'.437rem'}>
        <Flex direction={'row'} alignItems={'center'} justify={'space-between'}>
          <input type="hidden" name="asset_party_id" value={artist?.asset_party_id} />
          <Text fontSize={'1rem'} color={'#222028'} isTruncated>
            {artist?.full_name}
          </Text>
        </Flex>
      </Box>
    </>
  );
}
