import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Button from 'components/Button/button';
import Release from 'components/ReleaseList/release';

export default function ReleaseList({ releases = [], handleCopyToClipboard }) {
  return (
    <Box>
      <Flex flexFlow="row wrap" justifyContent="flex-start">
        {releases.map(release => (
          <ReleaseItem key={release.id} release={release} handleCopyToClipboard={handleCopyToClipboard} />
        ))}
      </Flex>
    </Box>
  );
}

function ReleaseItem({ release, handleCopyToClipboard }) {
  function handleCopyToClipboardInternal() {
    if (handleCopyToClipboard && release) {
      handleCopyToClipboard({
        query: `artist:${release.display_artist_name} upc:${release.asset_release_upc}`,
        types: ['album', 'artist'],
      });
    }
  }

  return (
    <Box position="relative" mr="3.375rem" mb="2.5rem">
      <Release release={release}></Release>
      <Box mt="1.5rem">
        <Button quaternary icon="copy" text="Copy URI" onClick={handleCopyToClipboardInternal} />
      </Box>
    </Box>
  );
}
