import React from 'react';
import styled from '@emotion/styled/macro';
import veniceHardcode from '../../assets/default/venice-hardcode.png';
import { useGlobalData } from '../../utils/global-data';

const Wrapper = styled.div`
  background-image: url(${veniceHardcode});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 5rem;
  height: 5rem;
  top: 1rem;
  position: relative;
`;

const LOGO_SIZE = '208x208';

export default function Logo({ className, white }) {
  const { selectedUserGroup, fileUrls } = useGlobalData();

  const logoUrl =
    selectedUserGroup &&
    fileUrls.group &&
    fileUrls.group[selectedUserGroup.id] &&
    fileUrls.group[selectedUserGroup.id].logo &&
    fileUrls.group[selectedUserGroup.id].logo[LOGO_SIZE];

  return <Wrapper className={className} white={white} logoUrl={logoUrl} />;
}
