import { DateTime } from 'luxon';

export const isReleaseDateWithin48Hours = data => {
  const { release_date } = data;
  const timeNow = DateTime.now();
  const releaseDate = DateTime.fromISO(release_date);
  const diff = releaseDate.diff(timeNow, 'hours');
  return diff.toObject().hours <= 48;
};

export const isVideoRelaseDateWithin14Days = data => {
  const { release_date } = data;
  const timeNow = DateTime.now();
  const releaseDate = DateTime.fromISO(release_date);
  const diff = releaseDate.diff(timeNow, 'days');
  return diff.toObject().days <= 14;
};
