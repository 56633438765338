import React, { useState, useEffect } from 'react';

import { API_STATUS_KEYS } from '../../constants/constants';
import { useAudioPlayer } from '../../utils/audio-player';
import { buildStatus } from '../../utils/helpers';
import { useUploader } from '../../utils/uploader';
import { IconButton, Box, useTheme, Spinner } from '@chakra-ui/react';
import {
  RiPauseCircleLine as AudioPauseIcon,
  RiPlayCircleLine as AudioPlayIcon,
  RiErrorWarningLine as AudioWarningIcon,
} from 'react-icons/ri';

import HEAP from '../../constants/HEAP.gen.json';

const MediaError = ({ children }) => {
  return (
    <Box color="red" fontSize=".75rem" position="absolute" margin="0.25rem 0 0 -0.25rem" whiteSpace="nowrap">
      {children}
    </Box>
  );
};

const AudioPlayButton = ({ disabled, isPlaying, onClick, loading, error, propsOverwrite = {} }) => {
  const theme = useTheme();

  if (loading) return <Spinner size="sm" marginX=".75rem" color={theme.colors.black100} />;

  if (error) return <AudioWarningIcon size="1.5rem" color={theme.colors.black100} />;

  const icon = isPlaying ? <AudioPauseIcon size="1.5rem" /> : <AudioPlayIcon size="1.5rem" />;

  const buttonProps = {
    border: 'none',
    variant: 'transparent',
    color: theme.colors.black100,
    icon: icon,
    onClick: onClick,
    disabled: disabled,
    ...propsOverwrite,
  };

  return <IconButton {...buttonProps} />;
};

function MediaPlayer({ trackId, heapCodePrefix, buttonPropsOverwite = {} }) {
  const { activeTrackId, fileLinks, fileLinkStatus, play, pause, stop, isPlaying, tryGetTrackFiles } = useAudioPlayer();

  const [fileUrl, setFileUrl] = useState(null);
  const [isSelected, setIsSelected] = useState(false);

  //TECH DEBT : Determine if the following functions are ever used. vvvvvv
  const { uploadStatus, uploadProgress } = useUploader();

  const [fileUploadStatus, setFileUploadStatus] = useState(buildStatus(null));
  const [fileUploadProgress, setFileUploadProgress] = useState({
    value: 0,
    type: null,
  });

  useEffect(() => {
    if (trackId) {
      const progress =
        uploadProgress.trackMedia && uploadProgress.trackMedia[trackId]
          ? { value: uploadProgress.trackMedia[trackId] }
          : { value: 0, type: null };
      setFileUploadProgress(progress);

      const status = (uploadStatus.trackMedia && uploadStatus.trackMedia[trackId]) || fileUploadStatus;
      setFileUploadStatus(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackId, uploadProgress, uploadStatus]);

  useEffect(() => {
    if (fileUploadProgress?.value === 100) {
      setTimeout(() => setFileUploadStatus(prevState => ({ ...prevState, value: 0 })), 1000);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUploadProgress]);

  const uploadError = uploadStatus?.trackMedia ? uploadStatus.trackMedia[trackId]?.error : null;

  const isUploading =
    uploadStatus?.trackMedia &&
    uploadStatus.trackMedia[trackId] &&
    uploadStatus.trackMedia[trackId][API_STATUS_KEYS.IN_PROGRESS];
  //TECH DEBT : TO HERE ^^^^^^^^

  useEffect(() => {
    setFileUrl(fileLinks[trackId] ? fileLinks[trackId].url : null);
  }, [trackId, fileLinks]);

  useEffect(() => {
    setIsSelected(trackId === activeTrackId);
  }, [trackId, activeTrackId]);

  const handlePlayButtonClicked = () => {
    if (trackId && !fileLinkStatus[trackId]) {
      tryGetTrackFiles(trackId);
    }

    doMediaPlay();
  };

  const doMediaPlay = () => {
    if (!isSelected) {
      playerStop();
      playerPlay();
    } else if (isPlaying) {
      playerPause();
    } else {
      playerPlay();
    }
  };

  const playerPlay = () => {
    play(trackId);
  };

  const playerPause = () => {
    pause();
  };

  const playerStop = () => {
    stop();
  };

  useEffect(() => {
    return () => {
      playerStop();
    };
  }, []);

  const fileError = Object.keys(fileLinkStatus[trackId] || {}).reduce(
    (a, encoding) => fileLinkStatus[trackId][encoding][API_STATUS_KEYS.ERROR] || '',
    ''
  );

  const isLoading = !fileError && fileLinkStatus[trackId] && !fileUrl && !isUploading;

  return (
    <Box position="relative">
      {
        <>
          <AudioPlayButton
            onClick={() => handlePlayButtonClicked()}
            isPlaying={isSelected && isPlaying}
            disabled={!trackId}
            loading={isLoading}
            error={!!fileError}
            propsOverwrite={buttonPropsOverwite}
            progress={fileUploadProgress?.value || 0}
            heapCode={`${heapCodePrefix}-${HEAP.PUBLIC.COMMON.TYPE.PLAY}`}
          />
          {(fileError || uploadError) && <MediaError>{fileError || uploadError}</MediaError>}
        </>
      }
    </Box>
  );
}

MediaPlayer.defaultProps = {
  type: null,
  fileStatus: { inProgress: false, complete: false, error: null },
  heapCodePrefix: HEAP.PUBLIC.COMMON.PREFIX.MEDIA,
};

export default MediaPlayer;
