//TECH DEBT: This component could benefit from a separate container layer for state management and xhr handling.
import React, { useState, useCallback } from 'react';
import Section from './section';
import SubSection from './sub-section';
import SectionHeaderBar from './section-header-bar';
import { Col, Row } from 'react-styled-flexboxgrid';
import FieldLabel from 'components/FieldLabel/field-label';
import Input from 'components/Input/input';
import TextField from 'components/TextField/text-field';
import ConfirmationModal from 'components/Modal/confirmation-modal';
import { useDeleteStripeMutation } from '../data-client/use-delete-stripe';
import * as API from 'utils/API/API';
import { useAuth0 } from 'utils/auth0';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import USER from 'constants/USER.gen.json';

import {
  Box,
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  Stack,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  useDisclosure,
  Button,
  useTheme,
} from '@chakra-ui/react';
import HEAP from '../constants/HEAP.gen.json';
import { API_STATUS_KEYS } from 'constants/constants';
import getFormValues from 'utils/get-form-values';

import { RightCol, CoverPadder, BankInfoDescription, StripeContainer, FieldValue, SmallText } from './settings-styles';

const User = API.user();
const MFA_STATUS = USER.MFA_STATUS;

function MyProfile({ stripeLinkStatus, openStripeModalHandler, loadStripeLoginLink, user }) {
  const { user: auth0User, logout } = useAuth0();
  const editNameModal = useDisclosure();
  const changeMfaStatusModal = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();

  const theme = useTheme();

  const [userPaymentLoginLink, setUserPaymentLoginLink] = useState(null);

  const { data: userPaymentAccount } = useQuery(['userAccount', user?.id], () => User.getPaymentAccount(user?.id), {
    enabled: !!user,
  });
  const { data: mfaResponse } = useQuery(['mfaStatus', user?.id], () => User.getMfaStatus(user?.id), {
    enabled: !!user,
  });

  const payeeStripeId = userPaymentAccount?.stripe_account_id;

  if (userPaymentAccount && !userPaymentLoginLink) {
    loadStripeLoginLink(userPaymentAccount.id, setUserPaymentLoginLink);
  }
  const changePasswordHandler = async () => {
    const link = await User.changePassword(auth0User.sub, window.location.href);
    window.location = link.ticket;
  };

  const handleLogout = () => {
    window.heap && window.heap.resetIdentity();
    logout();
  };

  const updateUserMutation = useMutation(({ id, name }) => User.updateUser(id, { name }), {
    onError: () =>
      toast({
        title: `Something went wrong updating your profile`,
        status: 'error',
      }),
  });

  const { mutate: setMfaStatusMutation, isLoading: mfaStatusUpdating } = useMutation(
    ({ mfa_status }) => User.setMfaStatus(mfa_status),
    {
      onSuccess: data => {
        if (data.mfa_status === MFA_STATUS.PENDING_ENROLLMENT) {
          handleLogout();
        }
        queryClient.invalidateQueries(['mfaStatus']);
      },
      onError: error => {
        toast({
          title: error.details ?? 'Something went wrong updating your MFA status',
          status: 'error',
        });
      },
    }
  );

  const { mutate: stripeResetMutation, isLoading: stripeResetIsLoading } = useDeleteStripeMutation({
    onSuccess: () => queryClient.invalidateQueries(['userAccount']),
    onError: error => {
      const apiError = error?.response?.data || {};
      toast({
        title: apiError.detail || apiError.title || 'Your request could not processed. Please try again later.',
        status: 'error',
      });
    },
  });

  const stripePayeeButtonText = userPaymentLoginLink?.stripe_account?.details_submitted
    ? 'View Payee Stripe Dashboard'
    : 'Complete Stripe Onboarding';

  const stripeUserDashboardButton = (
    <Button
      secondary
      loading={stripeLinkStatus[API_STATUS_KEYS.IN_PROGRESS]}
      disabled={!userPaymentLoginLink?.url}
      onClick={() => {
        window.open(userPaymentLoginLink?.url, '_blank');
      }}>
      {stripePayeeButtonText}
    </Button>
  );

  const onStripeResetClick = useCallback(
    () =>
      stripeResetMutation({
        accountId: userPaymentAccount?.id,
      }),
    [stripeResetMutation, userPaymentAccount]
  );

  const onMfaButtonClick = useCallback(
    newMfaStatus => {
      changeMfaStatusModal.onClose();
      setMfaStatusMutation({ mfa_status: newMfaStatus });
    },
    [setMfaStatusMutation]
  );

  const missingStripePaymentAccount = !userPaymentAccount?.stripe_account_id;
  const unlinkStripeButton = (
    <Button
      variant="smallDark" //come back to this
      loading={stripeLinkStatus[API_STATUS_KEYS.IN_PROGRESS]}
      disabled={missingStripePaymentAccount || stripeResetIsLoading}
      onClick={onStripeResetClick}>
      Reset Stripe
    </Button>
  );

  return (
    <>
      <Section>
        <SubSection>
          <SectionHeaderBar name={`My Profile`} />
          <Col>
            <Row>
              <Col xs={4}>
                <FieldLabel light text="Name" />
                <FieldValue>{user?.name}</FieldValue>
                <br />
                <Button variant="small" testCode="change-name" onClick={editNameModal.onOpen}>
                  Change Name
                </Button>
              </Col>
              <Col xs={4}>
                <FieldLabel light text="Password" />
                <FieldValue>**************</FieldValue>
                <br />
                <Button variant="small" testCode="change-password" onClick={changePasswordHandler}>
                  Change Password
                </Button>
              </Col>
              <Col xs={4}>
                <FieldLabel light text="Email Address" />
                <FieldValue>{user?.email}</FieldValue>
              </Col>
            </Row>
          </Col>
        </SubSection>
        {mfaResponse && (
          <SubSection>
            <SectionHeaderBar name={`Account Security (Multi-Factor Authentication)`} />
            <Row>
              <Col xs={6}>
                <BankInfoDescription>
                  Requires one of the following apps on your phone to enroll.
                  <ul style={{ marginTop: '1rem' }}>
                    <li>
                      Google Authenticator -{' '}
                      <a targe="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                        App Store
                      </a>{' '}
                      or{' '}
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN"
                        rel="noreferrer">
                        Google Play
                      </a>
                    </li>
                    <li>
                      Microsoft Authenticator -{' '}
                      <a targe="_blank" href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">
                        App Store
                      </a>{' '}
                      or{' '}
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                        rel="noreferrer">
                        Google Play
                      </a>
                    </li>
                    <li>Any other compatible TOTP app</li>
                  </ul>
                </BankInfoDescription>
              </Col>
              <RightCol xs={6}>
                <Button
                  variant={mfaResponse.mfa_status === MFA_STATUS.NONE ? 'smallDark' : 'small'}
                  onClick={() => changeMfaStatusModal.onOpen()}
                  loading={mfaStatusUpdating}
                  disabled={mfaStatusUpdating}
                  testCode="mfa">
                  {mfaResponse.mfa_status === MFA_STATUS.NONE ? 'Enroll in MFA' : 'Turn off MFA'}
                </Button>
              </RightCol>
            </Row>
            <ConfirmationModal
              headerText="Update MFA Setting"
              body={
                mfaResponse.mfa_status === MFA_STATUS.NONE
                  ? 'Do you have one of the recommended security apps installed on your phone?\n\nYou will need to log out and log back in to complete the enrollment process.'
                  : 'Are you sure you want to disable the MFA protection for your account?'
              }
              confirmText={mfaResponse.mfa_status === MFA_STATUS.NONE ? "I'm ready" : 'Turn off MFA'}
              disclosure={changeMfaStatusModal}
              onSubmit={() =>
                onMfaButtonClick(
                  mfaResponse.mfa_status === MFA_STATUS.NONE ? MFA_STATUS.PENDING_ENROLLMENT : MFA_STATUS.NONE
                )
              }
            />
          </SubSection>
        )}
        {/* Hide if no user payment account */}
        {userPaymentAccount && (
          <SubSection>
            <SectionHeaderBar name={`Personal Banking Info`} />
            <Row>
              <Col xs={6}>
                {!payeeStripeId ? (
                  <BankInfoDescription>
                    Sign in or Create an Account on Stripe for automatic monthly payments.
                  </BankInfoDescription>
                ) : (
                  <BankInfoDescription>Stripe Account Connected</BankInfoDescription>
                )}
                <SmallText>
                  Have questions? email <a href="mailto:yourfriends@venicemusic.co">yourfriends@venicemusic.co</a>
                </SmallText>
              </Col>
              <RightCol xs={6}>
                {!payeeStripeId ? (
                  <StripeContainer>
                    <Button
                      variant="smallDark"
                      onClick={() => openStripeModalHandler('payee')}
                      heapCode={HEAP.PUBLIC.SETTINGS.TYPE.SETTINGS_BUTTON_CONNECT_BANK_TO_PAYEE}
                      testCode="stripe"
                      loading={stripeLinkStatus[API_STATUS_KEYS.IN_PROGRESS]}
                      disabled={stripeLinkStatus[API_STATUS_KEYS.IN_PROGRESS]}>
                      Connect Bank Info
                    </Button>
                  </StripeContainer>
                ) : (
                  <Stack direction="row">
                    <StripeContainer>{stripeUserDashboardButton}</StripeContainer>
                    <StripeContainer>{unlinkStripeButton}</StripeContainer>
                  </Stack>
                )}
              </RightCol>
            </Row>
          </SubSection>
        )}
        <SubSection>
          <SectionHeaderBar name="Sign Out">
            <Button
              variant="smallDark"
              onClick={handleLogout}
              heapCode={HEAP.PUBLIC.SETTINGS.TYPE.SETTINGS_BUTTON_SIGN_OUT}>
              Sign Out
            </Button>
          </SectionHeaderBar>
        </SubSection>
        <CoverPadder />
      </Section>
      <ChakraModal isOpen={editNameModal.isOpen} onClose={editNameModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit name</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box
              as="form"
              id="editName"
              onSubmit={async ev => {
                ev.preventDefault();
                const formData = getFormValues(ev);
                await updateUserMutation.mutateAsync({
                  id: user.id,
                  name: formData.name,
                });
                await queryClient.invalidateQueries(['user']);
                editNameModal.onClose();
              }}
              mt="4">
              <Input variant="light" title="Name">
                <TextField name="name" placeholder="My Name" id="name" required={true} defaultValue={user?.name} />
              </Input>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="small" onClick={editNameModal.onClose} style={{ marginRight: theme.space[3] }}>
              Cancel
            </Button>
            <Button variant="smallDark" submit form="editName">
              Save changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
}

export default MyProfile;
