import * as React from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useTheme,
  useToast,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import { useMutation } from 'react-query';
import * as API from 'utils/API/API';

import ContributorForm from './contributor-form';
import { useGlobalData } from 'utils/global-data';

const Party = API.party();

export function useAddContributorModal({ onCreateContributor }) {
  const disclosure = useDisclosure();
  const Component = ({ ...props }) => (
    <AddContributorModal disclosure={disclosure} onSaved={onCreateContributor} {...props} />
  );
  return [Component, disclosure];
}

export default function AddContributorModal({ variant = 'dark', disclosure, userGroupId, onSaved = () => {} }) {
  const { release, selectedUserGroup } = useGlobalData();
  const theme = useTheme();
  const toast = useToast();
  const [confirmStep, setConfirmStep] = React.useState(false);
  const [confirmValues, setConfirmValues] = React.useState({});
  const [notFullName, setNotFullName] = React.useState(false);
  const [contributorName, setContributorName] = React.useState();

  const onSubmitContributorForm = () => {
    setConfirmStep(true);
    setNotFullName(!contributorName?.includes(' '));
  };

  React.useEffect(() => {
    if (contributorName) {
      setConfirmValues({ contributorName });
    }
  }, [setConfirmValues, contributorName]);

  const createContributorMutation = useMutation(
    args =>
      Party.create({
        full_name: args.full_name,
        type: 'contributor',
        user_group_id: release?.user_group_id || selectedUserGroup.id || userGroupId,
      }),
    {
      onError: () =>
        toast({
          title: `Something went wrong adding the contributor`,
          status: 'error',
        }),
    }
  );

  const handleOnClose = () => {
    setConfirmValues({});
    setConfirmStep(false);
    setNotFullName(false);
    disclosure.onClose();
  };

  const onSubmit = ev => {
    ev.preventDefault();

    // fire off mutation if validation succeeded
    createContributorMutation.mutate(
      {
        full_name: confirmValues.contributorName,
      },
      {
        onSuccess: data => {
          handleOnClose();
          onSaved(data);
        },
      }
    );
  };

  const ConfirmStep = () => {
    return (
      <Box
        key="confirmStep"
        as="form"
        id="contributor"
        onSubmit={onSubmit}
        color={variant === 'dark' ? 'cream200' : 'black100'}
        mt="4">
        <Box fontSize="sm" textTransform="uppercase">
          Contributor Name
        </Box>
        <Box fontSize="md" mb="6">
          {confirmValues.contributorName}
        </Box>
        {notFullName && (
          <Box>
            Streaming services require legal first and last names for lyricists and composers. If the contributor you
            are adding is a lyricist or composer, hit back to add their legal names. If not, you can confirm.
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Modal variant={variant} isOpen={disclosure.isOpen} onClose={handleOnClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader padding={'2rem 1.5rem'} fontSize="lg">
          {confirmStep ? 'Confirm' : 'Create'} new contributor
        </ModalHeader>
        <ModalCloseButton size="lg" color="black" marginTop="1rem" />
        <ModalBody padding="0 1.5rem 1.5rem 1.5rem">
          {confirmStep ? <ConfirmStep /> : null}
          <ContributorForm
            variant={variant}
            onChange={setContributorName}
            onSubmit={onSubmitContributorForm}
            display={confirmStep ? 'none' : 'block'}
          />
        </ModalBody>
        <ModalFooter paddingRight="1.5rem">
          <Button
            variant="secondary"
            text={confirmStep ? 'Back' : 'Cancel'}
            children={[confirmStep ? 'Back' : 'Cancel']}
            onClick={
              confirmStep
                ? () => {
                  setConfirmStep(false);
                  setNotFullName(false);
                }
                : disclosure.onClose
            }
            style={{
              marginRight: theme.space[3],
              backgroundColor: theme.colors.transparent,
              borderColor: theme.colors.transparent,
            }}
          />
          <Button
            style={{
              borderRadius: 6,
            }}
            variant="smallDark"
            text={confirmStep ? 'Confirm' : 'Create contributor'}
            children={[confirmStep ? 'Confirm' : 'Create contributor']}
            submit
            form="contributor"
            onClick={confirmStep ? onSubmit : onSubmitContributorForm}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
