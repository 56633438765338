import React, { useState, useContext } from 'react';
import * as API from './API/API';
import useSignedPostUploader from './use-signed-post-uploader';
import { buildStatus, getMediaTypeFromExtension } from './helpers';
import { API_STATUS_KEYS, MEDIA_TYPES } from '../constants/constants';
import axios from 'axios';
import retry from 'async-retry';

//There can only be 10,000 parts MAX in a multipart upload.
//With our current chunk size we should max out at no more than 5000 parts
//which will also fit into our current max express request json size.
const CHUNK_SIZE = 10242880; //<- 10mb
const VERIFY_UPLOAD_RETRY_MS = 10 * 1000;
const FILE_VERIFICATION_TIME_MS = 60 * 1000;

const uploadRetrySettings = {
  retries: 5,
  factor: 2,
  maxTimeout: 20 * 1000, // 20 seconds
};

export const UploaderContext = React.createContext();
export const useUploader = () => useContext(UploaderContext);
export const UploaderProvider = ({ children }) => {
  const Release = API.release();
  const Track = API.track();
  const [uploadStatus, setUploadStatus] = useState({});
  const [uploadProgress, setUploadProgress] = useState({});

  const [uploadPercent, setUploadPercent] = useState(null);
  const [uploadStatusText, setUploadStatusText] = useState();

  const { uploadFile: upload } = useSignedPostUploader({
    setProgress: percent => {
      percent === 100
        ? setUploadStatus(buildStatus(API_STATUS_KEYS.COMPLETE))
        : setUploadProgress(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
    },
    setError: () => setUploadStatus(buildStatus(API_STATUS_KEYS.ERROR)),
  });

  const setStatusToInvalidFileType = () => {
    setUploadStatus(buildStatus('Invalid file type'));
    return {
      statusCode: 400,
      body: 'invalid file type',
    };
  };

  const uploadCoverArt = async params => {
    const { mediaType, id, id2, file } = params;
    const fileName = file.name;
    if (!mediaType == MEDIA_TYPES.IMAGE) return setStatusToInvalidFileType();

    const signedPost = await Release.getUploadUrl({ id, setId: id2, artworkIndex: 0, fileName });

    try {
      await upload({
        signedPost,
        file,
      });

      return true;
    } catch (err) {
      setUploadStatus(buildStatus(API_STATUS_KEYS.ERROR));
      return false;
    }
  };

  const uploadFile = async (fileType, file, id, id2, metadata) => {
    setUploadStatus(prevState => ({
      ...prevState,
      [fileType]: {
        ...prevState[fileType],
        [id]: buildStatus(API_STATUS_KEYS.IN_PROGRESS),
      },
    }));

    const { name } = file;
    const extensionData = name.split('.');
    let extension = (extensionData[extensionData.length - 1] || '').toLowerCase();

    if (extension === 'jpeg') {
      extension = 'jpg';
    }

    const mediaType = getMediaTypeFromExtension(extension);

    //If it is cover art, handle it the old way
    if (fileType === 'coverArt') return uploadCoverArt({ mediaType, file, id, id2 });

    setUploadStatusText('Initializing Upload ...');

    if (mediaType !== MEDIA_TYPES.AUDIO && mediaType !== MEDIA_TYPES.VIDEO) return setStatusToInvalidFileType();

    const isImmersive = fileType === 'immersive_audio_file';
    const fileName = file.name;

    const partsCountArray = Array.from({ length: Math.ceil(file.size / CHUNK_SIZE) }, (_, i) => i + 1);

    const baseArguments = {
      id,
      isImmersive,
      fileName,
    };
    let UploadId;

    if (!id) {
      throw Error('No Track ID provided for multipart upload');
    }

    try {
      const resp = await retry(() => Track.getUploadMultiPart(baseArguments), uploadRetrySettings);
      UploadId = resp.UploadId;
    } catch (e) {
      e.message = `Error getting multipart upload: ${e.message}`;
      console.error(e);
      setUploadStatus(buildStatus(API_STATUS_KEYS.ERROR));
      setUploadPercent(null);
      return false;
    }

    let alreadyUploaded = 0;

    setUploadStatusText('Uploading File ...');

    const uploadedParts = [];

    for (const PartNumber of partsCountArray) {
      const { signedPost } = await retry(
        () => Track.getUploadPartUrl({ ...baseArguments, UploadId, PartNumber }),
        uploadRetrySettings
      );
      const start = (PartNumber - 1) * CHUNK_SIZE;
      const end = PartNumber * CHUNK_SIZE;
      const blob = PartNumber < partsCountArray.length ? file.slice(start, end) : file.slice(start);

      try {
        //The type of URL from multiPart Upload is different than the other signed post and the upload function from
        //use-signed-post-uploader will not work in this instance. So just using axios works.
        const partResult = await retry(
          () =>
            axios.put(signedPost, blob, {
              onUploadProgress: progressEvent => {
                setUploadPercent(Number((((alreadyUploaded + progressEvent.loaded) / file.size) * 100).toFixed(2)));
              },
            }),
          uploadRetrySettings
        );
        alreadyUploaded += blob.size;

        //Track the uploaded parts to deliver to the backend. This way the backend request doesn't
        //have to get multiple pages worth of parts which take a potentially long amount of time
        //The index for each uploaded part is equivalent to the partNumber - 1
        uploadedParts.push(partResult.headers.etag);
      } catch (e) {
        setUploadStatus(buildStatus(API_STATUS_KEYS.ERROR));
        setUploadPercent(null);
        return false;
      }
    }

    let verifyCodeETag = '';
    setUploadStatusText('Finalizing File Upload ...');
    try {
      const completedUpload = await retry(
        () => Track.getCompleteUpload({ ...baseArguments, uploadId: UploadId, parts: uploadedParts }),
        uploadRetrySettings
      );
      verifyCodeETag = completedUpload.data.data.ETag;
    } catch (e) {
      setUploadStatus(buildStatus(API_STATUS_KEYS.ERROR));
      setUploadPercent(null);
      return false;
    }

    //The file will not be pieced together yet. Need to check every few seconds to verify that it exists.
    setUploadStatusText('Verifying File Upload ...');
    const verifyUploadPromise = new Promise((resolve, reject) => {
      (async () => {
        //Make sure that it is impossible for errors to escape this!
        try {
          let giveUpTimeEpochMS = Date.now() + FILE_VERIFICATION_TIME_MS;
          let continueChecking = true;
          do {
            const { fileFound } = await retry(
              () => Track.verifyFileIsInStorage({ ...baseArguments, eTag: verifyCodeETag }),
              uploadRetrySettings
            );

            continueChecking = !fileFound && Date.now() < giveUpTimeEpochMS;
            if (continueChecking) {
              await new Promise(sleepResolve => setTimeout(sleepResolve, VERIFY_UPLOAD_RETRY_MS));
            }
          } while (continueChecking);
          resolve(true);
        } catch (error) {
          reject('Could Not Verify that the file exists');
        }
      })();
    });

    try {
      await verifyUploadPromise;
    } catch (err) {
      setUploadStatus(buildStatus(API_STATUS_KEYS.ERROR));
      return false;
    }

    try {
      const audioData = metadata.audio[0] || {};
      const videoData = metadata.video[0] || {};

      await Track.postFileMetadata({
        ...baseArguments,
        height: Number(videoData.Height),
        width: Number(videoData.Width),
        numberOfChannels: Number(audioData.Channels),
        audioCodec: (audioData.Format || '').toLowerCase(),
        videoCodec: (videoData.Format || '').toLowerCase(),
      });
    } catch (err) {
      setUploadStatus(buildStatus(API_STATUS_KEYS.ERROR));
      return false;
    }

    setUploadStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
    setUploadStatusText(null);
    return true;
  };

  return (
    <UploaderContext.Provider
      value={{
        uploadFile,
        uploadStatus,
        uploadProgress,
        uploadPercent,
        uploadStatusText,
      }}>
      {children}
    </UploaderContext.Provider>
  );
};
