import React from 'react';
import {
  chakra,
  Icon,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  useTheme,
  Box,
  Button as ChakraButton,
  Flex,
} from '@chakra-ui/react';
import { useSortBy, useTable, useRowSelect, usePagination } from 'react-table';
import { RiArrowDownLine, RiArrowUpLine, RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { useAnalyticsContext } from '../analytics-context';
import { useQuery } from 'react-query';
import GlanceCard from '../glance-card';
import CellWrapper from '../../components/Table/cell-wrapper';
import RowWrapper from '../../components/Table/row-wrapper';
import SkeletonLoader from '../../components/loading-containers/skeleton-loader';

function AnalyticsTableContainer({
  title,
  columns = [],
  domainData = [],
  RowComponent = false,
  dataProvider,
  onRowSelected = false,
  queryKey,
  rowsPerPage = 10,
}) {
  const theme = useTheme();
  const CellComponents = columns.map(c => c.CellComponent);
  const analyticsContext = useAnalyticsContext();
  const qKey = queryKey ? queryKey({ context: analyticsContext }) : title;

  const data = useQuery(qKey, () => dataProvider({ domainData, context: analyticsContext }), {
    enabled: !!dataProvider && domainData.isSuccess && !!analyticsContext,
  });

  return (
    <SkeletonLoader status={domainData.status}>
      {!!data.data && (
        <GlanceCard
          noborder="true"
          nobg="true"
          title={title}
          data-cy={global.APP.RUNTIME === 'production' ? undefined : 'analytics-table-heading'}>
          <AnalyticsTable
            data={data.data}
            columns={columns}
            RowComponent={RowComponent}
            CellComponents={CellComponents}
            context={analyticsContext}
            theme={theme}
            onRowSelected={onRowSelected}
            domainData={domainData}
            rowsPerPage={rowsPerPage}
          />
        </GlanceCard>
      )}
    </SkeletonLoader>
  );
}

function AnalyticsTable({
  data = [],
  columns = [],
  RowComponent,
  CellComponents,
  context,
  theme,
  onRowSelected,
  domainData,
  rowsPerPage,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    pageOptions,
    page,
    state: { pageIndex },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
  } = useTable({ columns, data, initialState: { pageSize: rowsPerPage } }, useSortBy, usePagination, useRowSelect);
  return (
    <>
      <Table {...getTableProps} color={'black.100'} variant={'analytics'}>
        <Thead background={'none'}>
          {headerGroups.map((headerGroup, hgIdx) => (
            <Tr key={`header-group-${hgIdx}`}>
              {headerGroup.headers.map((col, idx) => {
                const weight = col.isSorted ? theme.fonts.weights.bold : theme.fonts.weights.medium;
                return (
                  <Th
                    key={idx}
                    fontWeight={weight}
                    {...col.getHeaderProps(col.getSortByToggleProps())}
                    color={'black.60'}
                    border={'none'}>
                    {col.isSorted && (
                      <chakra.span pr="3" position="relative" bottom="1px">
                        {col.isSortedDesc ? (
                          <Icon as={RiArrowUpLine} aria-label="sorted descending" />
                        ) : (
                          <Icon as={RiArrowDownLine} aria-label="sorted ascending" />
                        )}
                      </chakra.span>
                    )}
                    {col.render('Header')}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps}>
          {page.map((row, rowIdx) => {
            prepareRow(row);
            const { key, role } = row.getRowProps();
            return (
              <RowWrapper
                RowComponent={RowComponent}
                idx={rowIdx}
                key={key}
                role={role}
                {...context}
                row={row}
                domainData={domainData}
                onRowSelected={onRowSelected}>
                {row.cells.map((cell, i) => {
                  const CellComponent = CellComponents[i] || false;
                  const { key: cellKey, role: cellRole } = cell.getCellProps();
                  return (
                    <CellWrapper
                      key={cellKey}
                      role={cellRole}
                      idx={i}
                      row={row}
                      cell={cell}
                      background={'none'}
                      CellComponent={CellComponent}
                      rowIdx={rowIdx}
                      context={context}>
                      {cell.render('Cell')}
                    </CellWrapper>
                  );
                })}
              </RowWrapper>
            );
          })}
        </Tbody>
      </Table>
      <Flex alignItems="center" justifyContent="center" mt="1">
        <ChakraButton variant="ghost" size="sm" onClick={() => previousPage()} disabled={!canPreviousPage}>
          <RiArrowLeftSLine />
        </ChakraButton>
        <Box mx="2" color="black.100" fontWeight="medium">
          {pageOptions.length === 0 ? 0 : pageIndex + 1} of {pageOptions.length}
        </Box>
        <ChakraButton variant="ghost" size="sm" onClick={() => nextPage()} disabled={!canNextPage}>
          <RiArrowRightSLine />
        </ChakraButton>
      </Flex>
    </>
  );
}

export default AnalyticsTableContainer;
