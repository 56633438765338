import Button from '../../components/Button/button';
import InfoBadge from '../../components/InfoBadge/info-badge';
import React from 'react';
import styled from '@emotion/styled/macro';
import * as API from 'utils/API/API';
import { useQuery } from 'react-query';

const ValidationBadgeWrapper = styled.div`
  margin: 0.5rem 1rem 0 0;
`;

const TrashButton = styled(Button)`
  div {
    height: 0.75rem;
    width: 0.75rem;
  }
`;

function TrackPersistanceControls({
  incomplete,
  disabled,
  handleDeleteClicked,
  isDirty,
  addDetailsClickedHandler,
  onSubmit,
  isExpanded,
  canDelete = true,
  errorText,
  hasBeenReleased,
  trackId,
}) {
  const Track = API.track();

  const { data: fileFound } = useQuery(['track', trackId], () => Track.verifyFile(trackId), {
    enabled: !!trackId,
  });

  const showError = incomplete < 0 || !fileFound ? -1 : 0;

  return (
    <>
      {errorText && <span>{errorText}</span>}
      {isExpanded && canDelete && !hasBeenReleased && (
        <>
          <TrashButton
            omnishareas
            tertiary
            short
            icon="trash"
            disabled={disabled}
            onClick={() => handleDeleteClicked()}
          />
          &nbsp;&nbsp;
        </>
      )}

      {!isExpanded && (
        <ValidationBadgeWrapper>
          <InfoBadge incomplete={showError} />
        </ValidationBadgeWrapper>
      )}
      <Button
        text={isExpanded ? (isDirty ? 'Cancel' : 'Close') : 'enter track details'}
        onClick={() => addDetailsClickedHandler()}
        disabled={disabled && isExpanded}
        loading={disabled && !isExpanded}
        short
      />
      {isExpanded && (
        <>
          &nbsp;&nbsp;
          <Button primary short text="Save" submit loading={disabled} disabled={!isDirty} onClick={onSubmit} />
        </>
      )}
    </>
  );
}

export default TrackPersistanceControls;
