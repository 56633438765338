import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';

import MEMBERSHIP from '../../constants/MEMBERSHIP.gen.json';
import INTERVAL from '../../constants/INTERVAL.gen.json';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';

import {
  useGetPreviewProratedUpgradeAmount,
  useUpdateUpgradeStripeSubscription,
} from '../../data-client/use-membership';

import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_PUBLISHABLE_KEY);

const NextActionModalContent = function ModalContent({ clientSecret, afterAuth, closeModal }) {
  const stripe = useStripe();
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  if (!stripe || isAuthenticating) {
    return <CircularProgress />;
  }

  return (
    <>
      <Heading fontSize={'1.6rem'}>Authentication Required</Heading>
      <Text>
        For your security, your payment provider requires an extra verification step. Click the button below to be sent
        to your provider so you can verify the purchase. Following verification, you will be sent back to Venice to
        complete your purchase.
      </Text>
      <br />
      <Flex width="100%" justifyContent="center" paddingTop="1rem">
        <Box width="100%" paddingLeft="6px">
          <Button
            width="100%"
            variant="wallet"
            onClick={() => {
              setIsAuthenticating(true);
              stripe
                .confirmCardPayment(clientSecret)
                .then(res => afterAuth(res))
                .finally(() => setIsAuthenticating(false));
            }}>
            Verify Purchase
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default function UpgradeSubscriptionModal({
  modalOpen,
  setModalOpen,
  stripeSubscription,
  upgradeToTier,
  tiers,
  refetchUser,
}) {
  const prorateDateInSeconds = useMemo(() => Math.floor(new Date().getTime() / 1000), [modalOpen]);
  const [upgradeResults, setUpgradeResults] = useState(null);
  const [isUpgrading, setIsUpgrading] = useState(false);

  const upgradeTier = tiers.find(t => t.tierName === upgradeToTier?.tierName && t.interval === upgradeToTier?.interval);
  const currentTierTitle = MEMBERSHIP.SUBSCRIPTION_TIER_DISPLAY_NAMES[stripeSubscription.tier];
  const upgradeTierTitle = MEMBERSHIP.SUBSCRIPTION_TIER_DISPLAY_NAMES[upgradeToTier.tierName];
  const upgrade_to = upgradeTier?.id;
  const discount_id = upgradeToTier?.discountId;

  const {
    data,
    isLoading: isLoadingProrationAmount,
    isFetched: prorationIsFetched,
    error: prorationError,
  } = useGetPreviewProratedUpgradeAmount({
    subscription_proration_date: prorateDateInSeconds,
    upgrade_to,
    discount_id,
    enabled: modalOpen,
  });

  const { mutate: upgradeSubscription } = useUpdateUpgradeStripeSubscription();

  function onUpgradeSubscription() {
    setIsUpgrading(true);
    upgradeSubscription(
      { upgrade_to, discount_id },
      {
        onSuccess: res => {
          setUpgradeResults(res);
          setIsUpgrading(false);
        },
        onError: _err => {
          setUpgradeResults('error');
          setIsUpgrading(false);
        },
      }
    );
  }

  function closeModal() {
    if (isUpgrading) {
      return;
    }
    //reloads with new subscription
    if (upgradeResults?.status === 'active') {
      refetchUser();
    }
    setUpgradeResults(null);
    setModalOpen(false);
  }

  function ModalWrapper({ children }) {
    return (
      <Modal isCentered isOpen={modalOpen} onClose={closeModal} closeOnOverlayClick={true}>
        <ModalOverlay />
        <ModalContent p={'24px'} maxW={'40rem'}>
          <ModalCloseButton disabled={isUpgrading} />
          {children}
        </ModalContent>
      </Modal>
    );
  }

  if (upgradeResults?.status === 'requires_action') {
    return (
      <ModalWrapper>
        <Elements stripe={stripePromise} options={{ clientSecret: upgradeResults.clientSecret }}>
          <NextActionModalContent
            afterAuth={res => {
              if (res.error) {
                setUpgradeResults({ status: '3ds-failed' });
              } else {
                setIsUpgrading(false);
                refetchUser();
                closeModal();
              }
            }}
            closeModal={closeModal}
            clientSecret={upgradeResults.clientSecret}
          />
        </Elements>
      </ModalWrapper>
    );
  }

  if (prorationError && prorationIsFetched) {
    return (
      <ModalWrapper>
        <Heading fontSize={'1.6rem'}>Your subscription could not be upgraded.</Heading>

        <Text>Please contact support to upgrade your subscription.</Text>
        <Flex width="100%" justifyContent="center" paddingTop="1rem">
          <Box paddingRight="6px">
            <Button border="1px solid" borderColor="black50" width="100%" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
        </Flex>
      </ModalWrapper>
    );
  }

  if (!isLoadingProrationAmount && !upgradeResults && data) {
    return (
      <ModalWrapper>
        <Heading fontSize={'1.6rem'}>Confirm tier upgrade</Heading>
        <VStack justifyContent="center" h="full">
          <Text>
            {`Please confirm that you are upgrading from the ${currentTierTitle} Tier to the ${upgradeTierTitle} Tier.`}
          </Text>
          <Text fontWeight="bold" padding="10px 0">{`Your card on file ${
            stripeSubscription?.cardInfo.last4 ? '(ending in ' + stripeSubscription?.cardInfo.last4 + ')' : ''
          } will be charged $${data.proratedAmount}* today.`}</Text>
          <Text>{`* ${
            data.futureCredits > 0
              ? `Because you already have paid for the ${currentTierTitle} tier, we've prorated your ${upgradeTierTitle} tier price.`
              : ''
          } ${
            discount_id !== '' && discount_id != null ? `Your discount code has been applied successfully.` : ''
          } Your membership will renew via credit card at
            ${
              data.futureCredits > 0
                ? ` $${upgradeTier.price}/${INTERVAL[upgradeTier.interval].short} after your credit of $${
                    data.futureCredits
                  } is used up`
                : ` $${upgradeTier.price}/${INTERVAL[upgradeTier.interval].short}`
            }.`}</Text>
        </VStack>
        <Flex width="100%" justifyContent="center" paddingTop="1rem">
          <Box width="100%" paddingRight="6px">
            <Button border="1px solid" borderColor="black50" width="100%" onClick={closeModal}>
              Cancel
            </Button>
          </Box>
          <Box width="100%" paddingLeft="6px">
            <Button
              data-cy={global.APP.RUNTIME === 'production' ? undefined : 'modal-button-upgrade-tier'}
              disabled={isUpgrading}
              width="100%"
              variant="wallet"
              onClick={onUpgradeSubscription}>
              Upgrade tier
            </Button>
          </Box>
        </Flex>
      </ModalWrapper>
    );
  }

  if (upgradeResults) {
    const WelcomeMessage = () => (
      <Flex justifyContent="center" flexDir="column" alignItems="center">
        <Icon as={BsFillCheckCircleFill} w="12" h="12" color="green" />
        <Text fontWeight="bold">{`Welcome to the ${upgradeTierTitle} Tier!`}</Text>
      </Flex>
    );

    const ErrorMessage = () => (
      <Flex justifyContent="center" flexDir="column" alignItems="center">
        <Icon as={MdCancel} w="12" h="12" color="red" />
        <Text>There was an error upgrading your subscription. Please contact support for help.</Text>
      </Flex>
    );

    const ThreeDSFailureMessage = () => (
      <Flex justifyContent="center" flexDir="column" alignItems="center">
        <Icon as={MdCancel} w="12" h="12" color="red" />
        <Text>
          Your payment provider requires an extra verification step to complete your purchase, which failed. You can
          either retry that payment method or use a different card for purchase. If this continues, please contact
          Venice Support via this
          <a href="https://venice-music.atlassian.net/servicedesk/customer/portal/1/group/1/create/2">form.</a>
        </Text>
      </Flex>
    );

    let MessageComponent;

    switch (upgradeResults.status) {
      case 'active':
        MessageComponent = WelcomeMessage;
        break;
      case '3ds-failed':
        MessageComponent = ThreeDSFailureMessage;
        break;
      default:
        MessageComponent = ErrorMessage;
        break;
    }

    return (
      <ModalWrapper>
        <Box>
          <MessageComponent />
        </Box>
      </ModalWrapper>
    );
  }

  return (
    <ModalWrapper>
      <CircularProgress />
    </ModalWrapper>
  );
}
