import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { CardContext } from '../card-context';

import Button from 'components/Button/button';
import Calendar from 'features/Calendar/calendar';

import useCardFlow from 'utils/use-card-flow';

import { ButtonCol, Paragraph14 } from 'styles';

import { CardContentWrapper, CardLoadingWrapper, CardHeaderBlock } from './card-style';

import { CARDS } from '../card';

import HEAP from '../../../constants/HEAP.gen.json';

const PreOrderDate = () => {
  const CARD_ID = CARDS.PreOrderDate;
  const {
    id,
    handleResetSaveAndPreview,
    NAV_DIRECTIONS,
    afterSaveActions,
    getCardData,
    getCardDataById,
    loadingAny,
    isFlowAnimating,
    loadingSaveRelease,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { cardData, updateMultipleCards, isReviewMode, _passthrough } = useCardFlow();

  // If pre-order or both

  const formMethods_PreOrderDate = useForm();
  const formValues_PreOrderDate = formMethods_PreOrderDate.getValues({
    nest: true,
  });

  const otherCardData_PreOrderDate = {
    LiveReleaseDate: getCardDataById('LiveReleaseDate', 'formData'),
    IncludePreOrder: getCardDataById('IncludePreOrder', 'formData'),
    Tracklist: getCardDataById('Tracklist', 'tracklistData'),
    InstantGratTracks: getCardDataById('InstantGratTracks', 'formData'),
  };

  const isEndOfFlow_PreOrderDate =
    otherCardData_PreOrderDate.IncludePreOrder?._meta?.includePreOrder === 'yes' &&
    otherCardData_PreOrderDate.Tracklist?.length <= 1;

  useEffect(() => {
    if (id === CARD_ID) {
      formMethods_PreOrderDate.reset(getCardData('formData'));
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_PreOrderDate.handleSubmit(data => save_PreOrderDate(data, _passthrough.inducePreview))();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  const save_PreOrderDate = (data, forcePreview = false, navigation = null) => {
    const saveData = { ...formValues_PreOrderDate, ...data };
    const updateObject = { [id]: { formData: saveData } };

    // if instant grat dates earlier than pre_order date have been set,
    //  update them to be the pre_order date
    if (Array.isArray(otherCardData_PreOrderDate.InstantGratTracks?.track_instant_grat_dates)) {
      const track_instant_grat_dates = otherCardData_PreOrderDate.InstantGratTracks?.track_instant_grat_dates.map(
        date => {
          const preOrderDate = DateTime.fromISO(saveData.pre_order_release_date);

          if (date && DateTime.fromISO(date) < preOrderDate) {
            return preOrderDate.toISODate();
          }

          return date;
        }
      );

      updateObject.InstantGratTracks = {
        formData: { track_instant_grat_dates },
      };
    }

    updateMultipleCards(updateObject);

    const showPreview =
      navigation !== NAV_DIRECTIONS.BACK && (isEndOfFlow_PreOrderDate || isReviewMode || forcePreview);
    afterSaveActions(showPreview, navigation);
  };

  let PreOrderDate_maxDate = DateTime.local().plus({ years: 1 }).toISODate();
  if (otherCardData_PreOrderDate.LiveReleaseDate?.release_date) {
    PreOrderDate_maxDate = DateTime.fromISO(otherCardData_PreOrderDate.LiveReleaseDate.release_date)
      .minus({ days: 1 })
      .toISODate();
  }

  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <CardHeaderBlock omnishareas>
          <Row>
            <Col xs={6}>
              <h3>Pre-Order Date</h3>
            </Col>
            <ButtonCol xs={6}>
              {!isReviewMode && (
                <Button
                  text="Back"
                  icon="chevronLeft"
                  onClick={() => save_PreOrderDate(formMethods_PreOrderDate.watch(), false, NAV_DIRECTIONS.BACK)}
                  disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                  tertiary
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_BUTTON_BACK}
                />
              )}
              <Button
                text={isEndOfFlow_PreOrderDate || isReviewMode ? 'Save & Preview' : 'Next'}
                rightIcon={isEndOfFlow_PreOrderDate || isReviewMode ? null : 'chevronRight'}
                loading={loadingSaveRelease || loadingAny}
                onClick={formMethods_PreOrderDate.handleSubmit(data =>
                  save_PreOrderDate(data, false, NAV_DIRECTIONS.FORWARD)
                )}
                disabled={!formMethods_PreOrderDate.watch('pre_order_release_date') || isFlowAnimating}
                heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_BUTTON_NEXT}
              />
            </ButtonCol>
          </Row>
          <Row>
            <Col xs={12}>
              <Paragraph14 />
            </Col>
          </Row>
        </CardHeaderBlock>
        <Row>
          <Col xs={12} md={6}>
            <Calendar
              name="pre_order_release_date"
              disabled={loadingSaveRelease || loadingAny}
              setValue={formMethods_PreOrderDate.setValue}
              defaultSelectedDate={formMethods_PreOrderDate.watch('pre_order_release_date')}
              minDate={DateTime.local().plus({ days: 1 }).toISODate()}
              maxDate={PreOrderDate_maxDate}
              ref={formMethods_PreOrderDate.register({ required: true })}
            />
          </Col>
        </Row>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};

export default PreOrderDate;
