export const searchSelectStyles = theme => ({
  control: (base, { isFocused }) => ({
    ...base,
    backgroundColor: theme.colors.white,
    boxShadow: 'none',
    borderRadius: isFocused ? `0.25rem 0.25rem 0 0` : '0.25rem',
    border: `1px solid ${theme.colors.black100}`,
  }),
  menu: base => ({
    ...base,
    color: theme.colors.black60,
    backgroundColor: theme.colors.white,
    borderRadius: '0  0 0.25rem 0.25rem',
    marginTop: 0,
  }),
  dropdownIndicator: base => ({
    ...base,
    color: theme.colors.black60,
  }),
  singleValue: base => ({
    ...base,
    color: theme.colors.black60,
  }),
  option: (base, { isSelected, isDisabled }) => {
    return {
      ...base,
      color: isDisabled ? 'gray' : theme.colors.black60,
      backgroundColor: isSelected ? theme.colors.black20 : theme.colors.white,
      outline: isSelected ? `1px solid ${theme.colors.black50}` : 'none',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      padding: '0.5rem 1rem',
    };
  },
  multiValueLabel: base => {
    return {
      ...base,
      whiteSpace: 'nowrap',
    };
  },
});
