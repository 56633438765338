import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useUploader } from 'utils/uploader';
import { useGlobalData } from 'utils/global-data';
import { buildStatus } from 'utils/helpers';
import { useReleaseForm } from 'utils/use-release-form';
import useCardFlow from 'utils/use-card-flow';
import ImageField from 'components/ImageField/image-field';
import { ARTWORK_SIZE } from 'constants/constants';
import { API_STATUS_KEYS } from 'constants/constants';
import { CardContext } from '../card-context';

import Button from 'components/Button/button';
import { ButtonCol } from 'styles';

import { CardContentWrapper, CardLoadingWrapper, CardHeaderBlock } from './card-style';

import { CARDS } from '../card';
import HEAP from '../../../constants/HEAP.gen.json';

const UploadCoverArt = () => {
  const CARD_ID = CARDS.UploadCoverArt;

  const {
    id,
    handleResetSaveAndPreview,
    CardLoadingSpinner,
    loadingAny,
    NAV_DIRECTIONS,
    loadingSaveRelease,
    isFlowAnimating,
    getCardDataById,
    getCardData,
    afterSaveActions,
  } = useContext(CardContext);
  const { loadFile, fileUrls, fileUrlStatus, release, addReleaseImageUrl } = useGlobalData();

  const { cardData, updateCard_Data, refreshLayoutState, isReviewMode, _passthrough } = useCardFlow();

  const { uploadFile, uploadStatus } = useUploader();

  const { selectedSet } = useReleaseForm();

  const coverArtUploadStatus =
    release && uploadStatus.coverArt && (uploadStatus.coverArt[release.id] || buildStatus(null));
  const releaseImageUrlStatus = fileUrlStatus['release'] || {};
  const releaseImageUrls = fileUrls['release'] || {};

  const artworkStatus =
    release &&
    selectedSet &&
    releaseImageUrlStatus[release.id] &&
    releaseImageUrlStatus[release.id][selectedSet.id] &&
    releaseImageUrlStatus[release.id][selectedSet.id][0] &&
    releaseImageUrlStatus[release.id][selectedSet.id][0][ARTWORK_SIZE]
      ? releaseImageUrlStatus[release.id][selectedSet.id][0][ARTWORK_SIZE]
      : buildStatus(null);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      save_UploadCoverArt(_passthrough.inducePreview);
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  useEffect(() => {
    if (release && selectedSet && selectedSet.id) {
      const doesntExist =
        !releaseImageUrlStatus[release.id] ||
        !releaseImageUrlStatus[release.id][selectedSet.id] ||
        !releaseImageUrlStatus[release.id][selectedSet.id][0][ARTWORK_SIZE];
      if (doesntExist) {
        loadFile('release', 'coverArt', ARTWORK_SIZE, {
          releaseId: release.id,
          setId: selectedSet.id,
          artworkIndex: 0,
        });
        setTimeout(() => refreshLayoutState(), 1000);
      }
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSet]);

  useEffect(() => {
    if (!getCardDataById(CARD_ID, 'imageUrl')) {
      const status =
        release &&
        selectedSet &&
        releaseImageUrlStatus[release.id] &&
        releaseImageUrlStatus[release.id][selectedSet.id] &&
        releaseImageUrlStatus[release.id][selectedSet.id][0][ARTWORK_SIZE];
      if (selectedSet && status?.complete && !status.error) {
        updateCard_Data(CARD_ID, {
          imageUrl: releaseImageUrls[release.id][selectedSet.id][0][ARTWORK_SIZE],
        });
      }
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release, fileUrlStatus, fileUrls, selectedSet]);

  useEffect(() => {
    if (artworkStatus[API_STATUS_KEYS.COMPLETE]) {
      refreshLayoutState();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artworkStatus]);

  const uploadCoverArtHandler = async file => {
    const blob = new Blob([file]);
    const url = window.URL.createObjectURL(blob);
    const img = new Image();
    img.src = url;

    img.onload = async e => {
      const { height, width } = e.target;

      if (height < 3000 || width < 3000) {
        alert('Cover art must be at least 3000 x 3000 px');
        return;
      } else if (height !== width) {
        alert('Dimensions must be square (3000 x 3000)');
        return;
      } else if (blob.size > 20000000) {
        alert('Cover art must be smaller than 20MB');
        return;
      } else if (!selectedSet) {
        alert('Release has no set');
        return;
      } else {
        updateCard_Data(CARD_ID, { imageUrl: null });
        await uploadFile('coverArt', file, release.id, selectedSet.id);
        addReleaseImageUrl(release.id, selectedSet.id, 0, ARTWORK_SIZE, url);
      }
    };
  };

  const save_UploadCoverArt = (forcePreview = false, navigation = null) => {
    if (isReviewMode) return afterSaveActions(forcePreview || isReviewMode, navigation);

    window.location = `/releases/${release.id}/edit/0/${navigation === NAV_DIRECTIONS.BACK ? 3 : 5}`;
  };

  return (
    <CardContentWrapper>
      <CardLoadingWrapper loading={loadingAny.toString()}>
        <div omnishareas={`Release.${release?.id}.UploadCoverArt`}>
          <CardHeaderBlock omnishareas>
            <Row>
              <Col xs={8}>
                <h3>Cover Art</h3>
              </Col>
              <ButtonCol xs={4}>
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={() => save_UploadCoverArt(false, NAV_DIRECTIONS.BACK)}
                    disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_COVERART_BUTTON_BACK}
                  />
                )}
                <Button
                  text={isReviewMode ? 'Save & Preview' : 'Next'}
                  rightIcon={isReviewMode ? null : 'chevronRight'}
                  onClick={() => save_UploadCoverArt(false, NAV_DIRECTIONS.FORWARD)}
                  loading={
                    loadingSaveRelease ||
                    loadingAny ||
                    (coverArtUploadStatus ? coverArtUploadStatus[API_STATUS_KEYS.IN_PROGRESS] : false)
                  }
                  disabled={isFlowAnimating || coverArtUploadStatus?.[API_STATUS_KEYS.IN_PROGRESS]}
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_COVERART_BUTTON_NEXT}
                />
              </ButtonCol>
            </Row>
            <Row>
              <Col xs={12}>
                <p>3000 x 3000px, JPEG or a PNG.</p>
              </Col>
            </Row>
          </CardHeaderBlock>
          <Row>
            <Col xs={12}>
              <div style={{ width: '200px' }}>
                <ImageField
                  name="CoverArt"
                  url={getCardData('imageUrl')}
                  innerLabel="Upload Cover Art"
                  extensionWhitelist={['jpg', 'jpeg', 'png']}
                  infoText={null}
                  status={artworkStatus}
                  uploadStatus={coverArtUploadStatus}
                  onChange={uploadCoverArtHandler}
                  disabled={
                    loadingSaveRelease ||
                    loadingAny ||
                    (coverArtUploadStatus ? coverArtUploadStatus[API_STATUS_KEYS.IN_PROGRESS] : false)
                  }
                  release
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_COVERART_IMAGE}
                />
              </div>
            </Col>
          </Row>
        </div>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};
export default UploadCoverArt;
