/* eslint-disable no-prototype-builtins */
import React from 'react';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import includes from 'lodash/includes';
import { FieldError, FieldWarning } from '../styles';
import * as API from './API/API';
import { MAIN_ARTIST } from '../constants/roles';
import { platformObjectToDSPArray } from './helpers';

const withFormMetadata = object => {
  let newObject = {};
  Object.keys(object).forEach(
    key =>
      (newObject[key] = {
        value: object[key],
        _isValid: false,
        _isEdited: false,
      })
  );
  return newObject;
};

const withoutFormMetadata = (object, value = 'value') => {
  let newObject = {};
  Object.keys(object).forEach(key => (newObject[key] = object[key][value]));
  return newObject;
};

const formNotationToArray = (obj, fieldName) => {
  const keys = Object.keys(obj);
  const returnArr = [];

  keys
    .filter(key => key.indexOf(fieldName) !== -1)
    .forEach(key => {
      const index = parseInt(key.split('[')[1].split(']')[0]);
      const field = key.split('.')[1];
      const item = {};

      item[field] = obj[key];

      if (returnArr.length === index) {
        returnArr.push(item);
      } else {
        returnArr[index] = Object.assign({}, returnArr[index], item);
      }
    });

  return returnArr;
};

const areFieldsEqual = (fields, object1, object2) => isEqual(pick(object1, ...fields), pick(object2, ...fields));

// dbData is actually coming from the releaseFormObject
const isFieldDirty = (key, formData, dbData) => {
  let isDirty = false;

  const keysToCheck = key => {
    // Map single field to multiple fields, e.g.:
    // case 'labelLine':
    //   return ['p_line', 'c_line']

    switch (key) {
      default:
        return [key];
    }
  };

  const JSONCompare = (data1, data2) => JSON.stringify(data1) === JSON.stringify(data2);
  const fieldCompare = (data1, data2) => (data1 === null && data2 === '') || JSONCompare(data1, data2);

  const fieldsDoMatch = (formKey, formData, dbKey, dbData) => {
    let isMatch = true;
    const ignoreArraySizeDifference = ['track_price_tiers', 'track_instant_grat_dates'];
    const dbField = dbData[dbKey];
    const formField = formData[formKey];

    if (Array.isArray(formField)) {
      if (
        !includes(ignoreArraySizeDifference, formKey) &&
        ((formField.length > 0 && !Array.isArray(dbField)) || formField.length !== dbField.length)
      ) {
        return false;
      }

      // For each item in the form array,
      //  For each field in the form array item
      //    if the value of the form array item !== the db array item
      //      return false

      // dbData   == release from DB (releaseFormObject)
      // formData == cardData

      switch (formKey) {
        case 'artists':
        case 'contributors':
          formField.forEach((item, i) => {
            Object.keys(item).forEach(field => {
              const dbArray = dbField[i];
              const formArray = formField[i];

              // Check fields until done, or a mismatch is found
              if (isMatch) {
                switch (field) {
                  case 'party_id':
                    isMatch = parseInt(dbArray[field]) === parseInt(formArray[field]);
                    break;
                  case 'roles':
                  case 'role':
                    isMatch = JSONCompare(
                      dbArray['role'] ? dbArray['role'] : dbArray['roles'] ? dbArray['roles'][0] : '',
                      Array.isArray(formArray[field]) ? formArray[field][0] : formArray[field]
                    );
                    break;
                  case 'appleId':
                    // skip as these aren't saved here anymore
                    break;
                  case 'spotifyId':
                    // skip as these aren't saved here anymore
                    break;
                  case 'soundcloudId':
                    // skip as these aren't saved here anymore
                    break;
                  case 'sequence':
                    isMatch = parseInt(dbArray[field]) === parseInt(formArray[field]);
                    break;
                  case 'dspIds':
                    isMatch = JSONCompare(
                      dbArray.hasOwnProperty('dspIds')
                        ? dbArray.dspIds
                        : dbArray.platform_id
                        ? platformObjectToDSPArray(dbArray.platform_id)
                        : [],
                      formArray[field]
                    );
                    isMatch = true;
                    break;
                  default:
                    // hack to not check some fields that can be different based on a row saved on the db
                    if (['created_at', 'asset_release_party_id', 'updated_at'].includes(field)) {
                      break;
                    }
                    isMatch = JSONCompare(dbArray[field], formArray[field]);
                    break;
                }
              }
            });
          });
          break;
        case 'track_instant_grat_dates':
          isMatch =
            dbField?.length !== formField?.length || !dbField.filter((dbDate, i) => formField[i] !== dbDate).length;
          break;
        case 'track_price_tiers':
          isMatch = dbField?.length !== formField?.length || JSONCompare(dbField, formField);
          break;
        default:
          isMatch = JSONCompare(dbField, formField);
      }
    } else {
      const meta = formData._meta || {};

      switch (formKey) {
        case 'type':
          isMatch = true;
          break;
        case 'dsp_platforms':
          isMatch = JSONCompare(dbField, formField.length === 1 && formField[0] === 'all' ? 'all' : formField);
          break;
        case 'territories':
          isMatch = JSONCompare(
            dbField,
            formField.length === 1 && formField[0] === 'worldwide' ? 'worldwide' : formField
          );
          break;
        case 'asset_release_upc':
          isMatch = meta.includeExistingUPC ? fieldCompare(dbField, formField) : true;
          break;
        case 'release_date_time':
          isMatch = meta.includeReleaseDateTime ? fieldCompare(dbField, formField) : true;
          break;
        case 'original_release_date':
          isMatch = (formData._meta || {}).includeOriginalReleaseDate ? fieldCompare(dbField, formField) : true;
          break;
        case 'pre_order_release_date':
          isMatch =
            !meta.hasOwnProperty('includePreOrder') || meta.includePreOrder === 'yes'
              ? fieldCompare(dbField, formField)
              : true;
          break;
        case 'instant_grat_date':
          // eslint-disable-next-line
          isMatch = dbField == formField; // truthy to allow undefined == null
          break;
        default:
          isMatch = fieldCompare(dbField, formField);
      }
    }

    return isMatch;
  };

  keysToCheck(key).forEach(mappedKey => {
    isDirty =
      isDirty ||
      !(
        fieldsDoMatch(key, formData, mappedKey, dbData) ||
        (!formData.hasOwnProperty(key) && !dbData.hasOwnProperty(mappedKey))
      );
  });

  return isDirty;
};

const renderFieldError = error => <FieldError>{error && (error.message || 'This field is required')}</FieldError>;
const renderFieldWarning = waring => <FieldWarning>{waring && (waring.message || 'Field alert')}</FieldWarning>;

const formatPlatformId = (platform, id) => {
  let t;
  const getLastAndChopTail = s => s[s.length - 1].split('?')[0].split('"')[0];

  if (isEmpty(id)) return '';

  switch (platform) {
    case 'spotify':
      if (id.indexOf('spotify.com/') !== -1) {
        // https://open.spotify.com/artist/62w9ueINUGbQbHTj4ZOzdE
        t = id.split('artist/');
      } else {
        // spotify:artist:62w9ueINUGbQbHTj4ZOzdE, 62w9ueINUGbQbHTj4ZOzdE
        t = id.split('artist:');
      }

      return `spotify:artist:${getLastAndChopTail(t)}`;
    case 'apple':
      if (id.indexOf('/id') !== -1) {
        // https://music.apple.com/za/artist/toshi-sakura/id1510278910
        t = id.split('/id');
      } else {
        // https://music.apple.com/za/artist/toshi-sakura/1510278910, 1510278910
        t = id.split('/');
      }

      return getLastAndChopTail(t);
    default:
      return id;
  }
};

const generatePartySubmitObject = (party, sequence) => {
  const roles = party.role ? [party.role] : [...party.roles] || [];
  const platform_id = {};

  if (party.spotifyId) {
    platform_id['spotify'] = formatPlatformId('spotify', party.spotifyId);
  }

  if (party.appleId) {
    platform_id['apple'] = formatPlatformId('apple', party.appleId);
  }

  if (party.soundcloudId) {
    platform_id['soundcloud'] = formatPlatformId('soundcloud', party.soundcloudId);
  }

  delete party.spotifyId;
  delete party.appleId;
  delete party.soundcloudId;

  const party_id = parseInt(party.id) || parseInt(party.party_id);
  const asset_party_id = parseInt(party.asset_party_id);
  return {
    id: party_id,
    party_id: party_id,
    asset_party_id,
    roles,
    platform_id,
    sequence,
  };
};

const sortArtists = artists =>
  artists
    .sort((a, b) => a.sequence - b.sequence)
    .sort((a, b) => b.roles.includes(MAIN_ARTIST.value) - a.roles.includes(MAIN_ARTIST.value));

// From https://stackoverflow.com/questions/4234589/validation-of-file-extension-before-uploading-file/4237161
const validateFileExtensions = (files, extWhitelist = []) => {
  for (let i = 0; i < files.length; i++) {
    let sFileName = files[i].name;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < extWhitelist.length; j++) {
        let sCurExtension = extWhitelist[j];
        if (
          sFileName
            .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
            // eslint-disable-next-line
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        return false;
      }
    }
  }

  return true;
};

const createNewParties = async arrParties => {
  const parties = arrParties;

  for (let x = 0; x < parties.length; x++) {
    const party = parties[x];

    if (!party.party_id) {
      const newParty = await API.party().create(party.full_name);
      parties[x] = { ...newParty, ...party };
    } else {
      party.id = party.party_id;
    }

    delete party.doSaveToRoster;
    delete party.party_id;
  }

  return parties;
};

export {
  withFormMetadata,
  withoutFormMetadata,
  formNotationToArray,
  renderFieldError,
  areFieldsEqual,
  isFieldDirty,
  formatPlatformId,
  generatePartySubmitObject,
  createNewParties,
  validateFileExtensions,
  renderFieldWarning,
  sortArtists,
};
