import React from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { RiInformationLine as InfoIcon } from 'react-icons/ri';
import IconedToolTip from 'components/IconedToolTip/IconedToolTip';
export const HeaderCol = ({ xs, tooltipText, label, style }) => {
  return (
    <Col xs={xs} style={style}>
      <span
        style={{
          fontSize: '0.625rem',
          fontWeight: '500',
          letterSpacing: '2px',
          minHeight: '0.75rem',
          textTransform: 'uppercase',
        }}>
        {label}
      </span>
      &nbsp;
      <IconedToolTip icon={InfoIcon} text={tooltipText} />
    </Col>
  );
};
