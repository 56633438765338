export function splitDataIntoSubsets(streamCounts, numDaysBack) {
  // a negative index on splice takes the last N items from the array
  const subsetOfData = [...streamCounts].splice(-numDaysBack);

  /**  This looks roughly like this:

            subsetOfData
              │
            ┌─┴──┐
            │    │
     [1,2,3,4,5,6]
     │    │
     └──┬─┘
        │
     prevSubsetOfData

     if the length of streamCounts isn't 2x the numDaysBack,
     we don't have enough streamCounts for an accurate % change
     hence the condition.

    */

  let prevSubsetOfData = [];
  if (streamCounts.length >= numDaysBack * 2) {
    prevSubsetOfData = [...streamCounts].splice(-numDaysBack * 2, numDaysBack);
  }
  return { subsetOfData, prevSubsetOfData };
}
