import React, { memo } from 'react';
import { Box, Flex, Heading, Button, Text, useTheme } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import LeftRailLink from 'components/left-rail-link/left-rail-link';
import CoverArt from 'components/CoverArt/cover-art';

function LeftRail({
  createClipsState,
  trackDetailsState,
  pageStates,
  display_artist_name,
  title,
  audio_source,
  cover_art,
  asset_release_id,
}) {
  const theme = useTheme();

  return (
    <>
      <Flex marginY={'2rem'}>
        <CoverArt file={cover_art} />
        <Box isTruncated marginLeft={'0.5rem'}>
          <Text isTruncated fontSize={'0.75rem'}>
            {display_artist_name}
          </Text>
          <Text isTruncated fontSize={'0.75rem'}>
            {title}
          </Text>
        </Box>
      </Flex>
      <Box>
        {pageStates.map(state => {
          return state.linkTitle ? (
            <LeftRailLink {...state} key={`left-rail-link-${state.name}`} asset_release_id={asset_release_id} />
          ) : null;
        })}
      </Box>
      <Box marginTop={'3rem'}>
        <Heading
          as="h5"
          fontSize={'.75rem'}
          fontWeight={'normal'}
          color={theme.colors.black10}
          textTransform={'uppercase'}>
          Source + Clips
        </Heading>
        <Box>
          {!audio_source && !trackDetailsState.active && trackDetailsState.linkTitle === 'Track Details' ? (
            <Button
              as={Link}
              to={`${trackDetailsState.path}${asset_release_id ? `?id=${asset_release_id}` : ''}`}
              variant={'transparent'}
              color={theme.colors.red100}>
              + Add audio source
            </Button>
          ) : (
            <LeftRailLink
              {...trackDetailsState}
              allValid={trackDetailsState.allValid}
              isActive={trackDetailsState.active}
              asset_release_id={asset_release_id}
            />
          )}
        </Box>
        <LeftRailLink
          {...createClipsState}
          allValid={createClipsState.allValid}
          isActive={createClipsState.active}
          asset_release_id={asset_release_id}
        />
      </Box>
    </>
  );
}

export default memo(LeftRail);
