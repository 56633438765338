import React from 'react';
import { Textarea, Text, FormControl, FormLabel, FormErrorMessage, useTheme, Tooltip } from '@chakra-ui/react';

const ValueLimitText = ({ limit, value, limitUnit, limitTextStyle }) => {
  if (!limit) return null;
  const valueLength = value?.length || 0;

  return <Text {...limitTextStyle}>{`${valueLength}/${limit} ${limitUnit}`}</Text>;
};

export default function LabeledTextarea({
  name,
  htmlId,
  label,
  value,
  onChange,
  onBlur,
  variant,
  invalidMessage,
  tooltip,
  limit,
  limitText = 'Characters',
  ...inputProps
}) {
  const theme = useTheme();

  function _onChange({ target: { value, name } }) {
    if (limit) value = value.substring(0, limit);
    onChange({ target: { value, name } });
  }

  //Since this insn't a real chakra component variants do not work as the other elements do.
  // An attempt to make this a terue chakra multipart compoent caused
  //the input to loose focus on typeing. So this way of doing it was landed on.
  const {
    div: divStyle,
    label: labelStyle,
    textarea: textareaStyle,
    limitText: limitTextStyle,
  } = variant ? theme.components.LabeledTextarea.variants[variant] : theme.components.LabeledTextarea?.baseStyle || {};

  if (!name) console.error('LabeledInput requires a name property.');

  htmlId = htmlId || name;

  return (
    <FormControl {...divStyle} isInvalid={!!invalidMessage}>
      <Tooltip label={tooltip} placement="top-start">
        <FormLabel {...labelStyle} htmlFor={htmlId}>
          {label}
        </FormLabel>
      </Tooltip>
      <Textarea
        name={name}
        id={htmlId}
        value={value}
        onChange={_onChange}
        onBlur={onBlur}
        {...textareaStyle}
        {...inputProps}
      />
      <ValueLimitText value={value} limit={limit} limitUnit={limitText} limitTextStyle={limitTextStyle} />
      {invalidMessage ? <FormErrorMessage>{invalidMessage}</FormErrorMessage> : ''}
    </FormControl>
  );
}
