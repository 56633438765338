import { useToast } from '@chakra-ui/react';

export function useErrorResponseToast() {
  const toast = useToast();

  return (error, overrides = {}) => {
    toast({
      status: 'error',
      title: error.response.data.title,
      description: error.response.data.detail,
      ...overrides,
    });
  };
}
