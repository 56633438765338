const ERRORS = {
  GENERAL: {
    DATA_PROBLEM: 'Could not retrieve data',
    WRONG: 'Something went wrong',
  },
  PERFORMANCE: {
    DATA_PROBLEM: 'Could not retrieve performance data',
  },
  DSPS: {
    DATA_PROBLEM: 'Could not retrieve DSP list',
  },
  ARTIST: {
    DATA_PROBLEM: 'Could not retrieve artist data',
  },
  STATEMENTS: {
    NO_LIST: 'Could not retrieve Statements list',
  },
  RELEASE: {
    NOT_FOUND: 'Release not found',
  },
  TRACK: {
    NOT_FOUND: 'Track data not found',
  },
  SETS: {
    NOT_FOUND: 'Sets not found',
  },
  SET_TRACKS: {
    NOT_FOUND: 'Set tracks not found',
  },
  GROUPS: {
    NO_GET: 'Could not get groups',
  },
  PAYEES: {
    DUPLICATE_EMAIL: 'Email address exists',
  },
};

export default ERRORS;
