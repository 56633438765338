import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);

let _initOptions, _client;

const getAuth0Client = async () => {
  try {
    return await createAuth0Client(_initOptions);
  } catch (e) {
    throw new Error('getAuth0Client Error', e);
  }
};

//https://community.auth0.com/t/using-auth0-outside-of-react-components/38904/14
export const getTokenSilently = async (...p) => {
  if (!_client) {
    _client = await getAuth0Client();
  }
  return await _client.getTokenSilently(...p);
};

export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    const initAuth0 = async () => {
      _initOptions = initOptions;
      const auth0FromHook = await getAuth0Client(initOptions);
      setAuth0(auth0FromHook);
      try {
        if (window.location.search.includes('code=') && !window.location.search.includes('type=stripe')) {
          //If auth0 gets in a bad state during this redirect the user will be presented with a black screen and refreshes will not work.
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }

        const isAuthenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser();
          setUser(user);
          const tokenData = await auth0FromHook.getTokenSilently();
          setToken(tokenData);

          localStorage.setItem('scale_music_auth0_token', tokenData);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        await auth0FromHook.logout({ returnTo: window.location.origin });
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  const logoutHandler = async (...p) => {
    await auth0Client.logout({ returnTo: window.location.origin });
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        token,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => logoutHandler(...p),
      }}>
      {children}
    </Auth0Context.Provider>
  );
};
