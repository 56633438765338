import React, { useState, useEffect, useContext } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Link } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import MultiCheckList from 'features/MultiCheckList/multi-check-list';
import { filterDSPsByReleaseType } from 'utils/helpers';
import { specialDSPNames } from 'constants/dsps';
import { territories } from 'constants/territories';
import { useGlobalData } from 'utils/global-data';
import useCardFlow from 'utils/use-card-flow';
import Button from 'components/Button/button';
import { ButtonCol, Paragraph14 } from 'styles';
import FieldLabel from 'components/FieldLabel/field-label';
import { API_STATUS_KEYS } from 'constants/constants';

import { CardContext } from '../card-context';
import { CARDS } from '../card';

import isEqual from 'lodash/isEqual';

import { CardLoadingWrapper, CardContentWrapper, CardHeaderBlock } from './card-style';
import { useUserTierChecks } from '../../../hooks/authorization-hooks';
import HEAP from '../../../constants/HEAP.gen.json';

const YOUTUBE = 'youtube';
const YOUTUBE_FP = 'youtubeFP';
const DSP_PLATFORMS = 'dsp_platforms';
const DeliveryPlatformsAndTerritories = () => {
  const CARD_ID = CARDS.DeliveryPlatformsAndTerritories;

  const {
    id,
    loadingAny,
    getCardData,
    getCardDataById,
    handleSubmitError,
    handleResetSaveAndPreview,
    afterSaveActions,
    NAV_DIRECTIONS,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { isExecutiveTier, isCoreTier } = useUserTierChecks();

  const { dsps, dspsStatus } = useGlobalData();

  const { cardData, updateCard_Form, isReviewMode, _passthrough, onExternalEvent } = useCardFlow();

  const formMethods_DeliveryPlatformsAndTerritories = useForm();
  const formValues_DeliveryPlatformsAndTerritories = formMethods_DeliveryPlatformsAndTerritories.getValues({
    nest: true,
  });
  const errors_DeliveryPlatformsAndTerritories = formMethods_DeliveryPlatformsAndTerritories.errors;
  const otherCardData_DeliveryPlatformsAndTerritories = {
    ReleaseDetails: getCardDataById('ReleaseDetails', 'formData'),
  };

  const handleDSPPlatformsSaved = (dsp_platforms = []) => {
    onExternalEvent({ name: 'DSPS_SAVED', data: { dsp_platforms } });
  };

  const [hasYoutube, setHasYoutube] = useState(false);

  const isEndOfFlow_DeliveryPlatformsAndTerritories =
    !formMethods_DeliveryPlatformsAndTerritories.watch('dsp_platforms')?.includes('apple') &&
    cardData?.ReleaseDetails?.formData?._meta?.releaseType === 'VideoSingle';

  useEffect(() => {
    if (id === CARD_ID) {
      const data = JSON.parse(JSON.stringify(getCardData('formData')));

      if (isEqual(data?.territories, ['Worldwide'])) {
        data.territories = territories.map(t => t.value);
      }

      formMethods_DeliveryPlatformsAndTerritories.reset(data);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_DeliveryPlatformsAndTerritories.handleSubmit(
        data => save_DeliveryPlatformsAndTerritories(data, _passthrough.inducePreview),
        _data => handleSubmitError(_passthrough.inducePreview)
      )();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  //The solution provided is desigend to work with react-hook-forms and not interfeer with the odd
  //items from create release. This can be handled much more elgantly once we re-factor create release, but for now the suckage will continue.
  useEffect(() => {
    // The user is an executive tier if they have deal. They are not subject to the same thing.
    // Core Tier members do not have access to youtubeFP so the logic bellow is not necessary for them.
    if (isExecutiveTier || isCoreTier) return;

    //initial state will sometimes be null.
    const selectedDsps = formValues_DeliveryPlatformsAndTerritories.dsp_platforms;
    if (!selectedDsps) return;

    const hasyouTubeFP = selectedDsps.includes(YOUTUBE_FP);
    const hasYoutubeA = selectedDsps.includes(YOUTUBE);

    // If the user had YT Music selected, then removed it, we remove YT Content ID from the list of DSPs
    if (hasYoutube && hasyouTubeFP && !hasYoutubeA) {
      formMethods_DeliveryPlatformsAndTerritories.setValue(
        //set makes it unique so there is not a double entry
        DSP_PLATFORMS,
        [...selectedDsps.filter(dsp => dsp !== YOUTUBE_FP)]
      );

      return setHasYoutube(false);
    }

    // If the user selected only YT Content ID, we add YT Music to the list of DSPs
    if (hasyouTubeFP && !hasYoutubeA) {
      formMethods_DeliveryPlatformsAndTerritories.setValue(
        //set makes it unique so there is not a double entry
        DSP_PLATFORMS,
        [...new Set([...selectedDsps, YOUTUBE_FP, YOUTUBE])]
      );

      setHasYoutube(true);
    } else if (hasYoutubeA && !hasYoutube) {
      setHasYoutube(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues_DeliveryPlatformsAndTerritories]);

  const save_DeliveryPlatformsAndTerritories = (data, forcePreview = false, navigation = null) => {
    const saveData = { ...formValues_DeliveryPlatformsAndTerritories, ...data };

    // If there aren't specific territory exclusions, use special keyword "Worldwide"
    if (
      isEqual(
        territories.map(t => t.value),
        saveData.territories
      )
    ) {
      saveData.territories = ['Worldwide'];
    }

    handleDSPPlatformsSaved((saveData._meta || {}).dsp_platforms);
    updateCard_Form(id, saveData);

    const showPreview =
      navigation !== NAV_DIRECTIONS.BACK &&
      (isEndOfFlow_DeliveryPlatformsAndTerritories || isReviewMode || forcePreview);

    afterSaveActions(showPreview, navigation);
  };

  let curatedDSPs = filterDSPsByReleaseType(
    dsps,
    otherCardData_DeliveryPlatformsAndTerritories.ReleaseDetails?._meta?.releaseType
  );

  // Core tier members do not have access to youtubeFP so it needs to be removed from the list.
  if (isCoreTier) {
    curatedDSPs = curatedDSPs.filter(dsp => dsp.identifier !== YOUTUBE_FP);
  }

  curatedDSPs = curatedDSPs.map(dsp => ({
    value: dsp.identifier,
    label: specialDSPNames[dsp.identifier] || dsp.name,
  }));

  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <FormProvider {...formMethods_DeliveryPlatformsAndTerritories}>
          <CardHeaderBlock omnishareas>
            <Row>
              <Col xs={6}>
                <h3>Delivery Platforms and Territories</h3>
              </Col>
              <ButtonCol xs={6}>
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={() =>
                      save_DeliveryPlatformsAndTerritories(
                        formMethods_DeliveryPlatformsAndTerritories.watch(),
                        false,
                        NAV_DIRECTIONS.BACK
                      )
                    }
                    disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_SUB_TITLE_INPUT_SUB_TITLE}
                  />
                )}
                <Button
                  text={isEndOfFlow_DeliveryPlatformsAndTerritories || isReviewMode ? 'Save & Preview' : 'Next'}
                  rightIcon={isEndOfFlow_DeliveryPlatformsAndTerritories || isReviewMode ? null : 'chevronRight'}
                  onClick={formMethods_DeliveryPlatformsAndTerritories.handleSubmit(data =>
                    save_DeliveryPlatformsAndTerritories(data, false, NAV_DIRECTIONS.FORWARD)
                  )}
                  loading={loadingSaveRelease || loadingAny}
                  disabled={getCardData(CARD_ID) === null || isFlowAnimating}
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_PLATFORMS_BUTTON_NEXT}
                />
              </ButtonCol>
            </Row>
            <Row>
              <Col xs={4}>
                <Paragraph14>
                  YouTube Content ID has strict requirements. Please review{' '}
                  <Link
                    href="https://support.venice.tech/distribution/can-i-submit-my-release-to-youtube-content-id"
                    textDecoration={'underline'}
                    target="_blank">
                    eligibility requirements
                  </Link>{' '}
                  before manually selecting in the dropdown below.
                </Paragraph14>
              </Col>
            </Row>
          </CardHeaderBlock>
          <Row>
            <Col xs={6} md={4}>
              <FieldLabel text="Platforms" tooltip="Digital Service Providers to distribute to" />
              <MultiCheckList
                label="Platforms"
                name="dsp_platforms"
                error={errors_DeliveryPlatformsAndTerritories['dsp_platforms']}
                clearErrors={formMethods_DeliveryPlatformsAndTerritories.clearErrors}
                options={curatedDSPs}
                disabled={dspsStatus[API_STATUS_KEYS.IN_PROGRESS] || loadingSaveRelease || loadingAny}
                ref={formMethods_DeliveryPlatformsAndTerritories.register({
                  required: true,
                })}
                dark
                selectAllFilter={option => option.value !== YOUTUBE_FP}
                heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_PLATFORMS_SELECT_PLATFORMS}
              />
            </Col>
            <Col xs={6} md={4}>
              <FieldLabel text="Territories" tooltip="Territories where this release will be available" />
              <MultiCheckList
                label="Territories"
                name="territories"
                error={errors_DeliveryPlatformsAndTerritories['territories']}
                clearErrors={formMethods_DeliveryPlatformsAndTerritories.clearErrors}
                options={(territories || []).map(territory => ({
                  value: territory.value,
                  label: territory.label,
                }))}
                disabled={loadingSaveRelease || loadingAny}
                ref={formMethods_DeliveryPlatformsAndTerritories.register({
                  required: true,
                })}
                windowed
                dark
                heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_PLATFORMS_SELECT_TERRITORIES}
              />
            </Col>
          </Row>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};
export default DeliveryPlatformsAndTerritories;
