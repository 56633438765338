import { useQuery, useMutation } from 'react-query';

import { getReleaseById, createRelease, updateRelease, addTrackToRelease } from '../utils/API/v2-releases';
import trackAPI from '../utils/API/track';
import { worldwideTerritory, territoryNames } from '../features/territories-selector/territory-names';
import { DateTime } from 'luxon';

const trackAPIClient = trackAPI();

export function useGetRelease(id, options) {
  //We need to specify a different query id from just 'release' because
  //react-query may try to share query results across different release endpoints!!
  return useQuery(['release-v2', id], () => getReleaseById({ id }), {
    select: formatReceiveData,
    ...options,
  });
}

export function useCreateRelease(options) {
  return useMutation(
    async body => {
      const releaseData = formatSendData(body);
      return await createRelease(releaseData);
    },
    {
      ...options,
      onSuccess: data => {
        const formated = formatReceiveData(data);
        if (typeof options?.onSuccess === 'function') return options.onSuccess(formated);

        return formated;
      },
    }
  );
}

export function useUpdateRelease(options) {
  return useMutation(body => updateRelease(formatSendData(body)), {
    ...options,
    onSuccess: data => {
      const formated = formatReceiveData(data);
      if (typeof options?.onSuccess === 'function') return options.onSuccess(formated);

      return formated;
    },
  });
}

export function useAddTrackToRelease(options) {
  return useMutation(async body => await addTrackToRelease(body), { ...options });
}

function formatSendData(data) {
  if (data.type === 'Clip') return formatSendClips(data);

  return data;
}

function formatClipTrack(clipData = {}, existingClipTrack = {}, sourceTrack = {}, sequence) {
  //We don't want to copy the aset_track_id or isrc_code from the source track because
  //we want clips to exist as their own tracks.1
  const { asset_track_id: source_asset_track_id, lang, parental_warning, deals } = sourceTrack;
  const {
    clipName,
    startTimeSeconds: start_time_seconds,
    endTimeSeconds: end_time_seconds,
    speedPercent = 100,
  } = clipData;
  const { asset_track_id, isrc_code = null } = existingClipTrack;

  return {
    asset_track_id,
    //A default is required by the schema. Setting to the not case when it is not set on the sourceTrack.
    parental_warning: parental_warning || 'NotExplicit',
    lang,
    deals,
    //Every clip track should have its own isrc separate from any source track as well!
    //This is because source tracks do not have to be delivered or have isrcs to be used.
    //They just exist in the well of the user's asset_tracks.
    isrc_code,
    title: clipName || `Clip ${sequence}`,
    clip_attributes: {
      source_asset_track_id,
      start_time_seconds,
      end_time_seconds,
      playback_rate: speedPercent / 100,
    },
    setTrack: {
      sequence,
    },
  };
}

function formatSendClips(data) {
  const { release_date, clips: clipsContainer, track: sourceTrack, tracks = [], ...releaseData } = data;
  if (!sourceTrack) return releaseData;

  const clipArray = clipsContainer ? clipsContainer.reduceToClips() : [];
  const clipTracks = clipArray.map((clipData, i) => formatClipTrack(clipData, tracks[i], sourceTrack, i + 1));

  //If no clips exist on save, we still want to save one track so
  //that we can retain the source track on reloads.
  if (clipTracks.length === 0) {
    clipTracks.push(formatClipTrack(undefined, undefined, sourceTrack, 1));
  }

  //format the time to be in eastern here so we don't have to dance around timezones with luxon fighting against the local system  constantly on the frontend
  const timeZoneDateTime = DateTime.fromISO(release_date).setZone('America/New_York', {
    keepLocalTime: true,
  });

  const territories =
    !releaseData.territories || releaseData.territories.length === territoryNames.length
      ? [worldwideTerritory]
      : releaseData.territories;

  return { ...releaseData, release_date: timeZoneDateTime.toISO(), tracks: clipTracks, sourceTrack, territories };
}

function formatReceiveData(data) {
  if (data.type === 'Clip') return formatReceiveClips(data);

  return data;
}

function formatReceiveClips(data) {
  const { territories: backendTerritories, sourceTrack, ...clipsData } = data;
  const territories =
    !backendTerritories || backendTerritories.includes(worldwideTerritory) ? territoryNames : data.territories;

  //Keeping the name track for now since track is used across much of the gui.
  return { ...clipsData, territories, track: sourceTrack };
}
