import React from 'react';
import { Select, Flex, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';

const timeOptions = Array(12)
  .fill(0)
  .map((_, index) => {
    const luxonTime = DateTime.fromObject({ hours: index });
    return [luxonTime, luxonTime.plus({ minutes: 30 })];
  })
  .flat()
  .map(luxonTime => luxonTime.toFormat('h:mm'));

const meridiemOptions = ['AM', 'PM'];

export default function TimeSelector({
  value = DateTime.fromObject({ hours: 0, minutes: 0 }),
  onChange = () => {},
  onBlur = () => {},
  displayTimezone = 'ET',
  styleProps,
}) {
  const onSelectTime = e => {
    const { value: selectedTime } = e.target;

    const currentMeridiemString = value.toFormat('a');
    const parsedLuxonTime = DateTime.fromFormat(`${selectedTime} ${currentMeridiemString}`, 'h:mm a');
    onChange(parsedLuxonTime);
  };

  const onSelectMeridiem = e => {
    const { value: selectedMeridiem } = e.target;

    const currentTimeString = value.toFormat('h:mm');
    const parsedLuxonTime = DateTime.fromFormat(`${currentTimeString} ${selectedMeridiem}`, 'h:mm a');
    onChange(parsedLuxonTime);
  };

  const onSelectBlur = () => {
    onBlur(value);
  };

  return (
    <Flex w="15rem" alignItems="center" {...styleProps}>
      <Select
        w="15rem"
        mr="1rem"
        value={value.toFormat('h:mm')}
        borderColor="black50"
        onChange={onSelectTime}
        onBlur={onSelectBlur}>
        {timeOptions.map(time => {
          return (
            <option value={time} key={time}>
              {time}
            </option>
          );
        })}
      </Select>
      <Select value={value.toFormat('a')} borderColor="black50" onChange={onSelectMeridiem} onBlur={onSelectBlur}>
        {meridiemOptions.map(meridiem => (
          <option value={meridiem} key={meridiem}>
            {meridiem}
          </option>
        ))}
      </Select>
      <Text ml="1rem" color="black100">
        {displayTimezone}
      </Text>
    </Flex>
  );
}
