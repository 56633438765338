import { Box, Flex, Square, Textarea, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import icons from '../../utils/icons';

const UserMessageInput = ({ onSend, placeholder = 'What can I help you with?', disabled = false, ...rest }) => {
  const [text, setText] = useState('');
  const [textareaheight, setTextareaheight] = useState(1);

  const handleInputChange = e => {
    setText(e.target.value);

    // Calculate whether the text is wrapped and increase the size of the
    // text area to contain all text.
    const height = e.target.scrollHeight;
    const rowHeight = 22;
    const requiredRows = Math.ceil((height - 16) / rowHeight) - 1;

    if (requiredRows > textareaheight) {
      setTextareaheight(requiredRows);
    }
  };

  /** @type {React.KeyboardEventHandler<HTMLTextAreaElement>} */
  const handleInputKeyUp = e => {
    // If the user presses enter, send the message. Shift+Enter allows a newline.
    if (e.key === 'Enter' && disabled === false && e.shiftKey === false) {
      handleSend(e);
    }
  };

  /** @type {React.ChangeEventHandler<HTMLTextAreaElement>} */
  const handleSend = e => {
    e.preventDefault();

    if (text.length === 0) {
      return;
    }

    onSend(text);

    setText('');
    setTextareaheight(1);
  };

  return (
    <form onSubmit={handleSend}>
      <Flex flexDirection="row" gap="2" alignItems="center" {...rest}>
        <Box width="100%">
          <Textarea
            onChange={handleInputChange}
            onKeyUp={handleInputKeyUp}
            value={text}
            placeholder={placeholder}
            variant="unstyled"
            resize="none"
            fontSize="1rem"
            rows={textareaheight}
            isDisabled={disabled}
            color="gray.900"
          />
        </Box>
        <Square width="40px">
          <IconButton
            type="submit"
            icon={icons.blackSendPlane({ style: { width: '1.5rem', height: '1.5rem' } })}
            variant="transparent"
            isDisabled={disabled}></IconButton>
        </Square>
      </Flex>
    </form>
  );
};

export default UserMessageInput;
