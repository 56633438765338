import * as React from 'react';
import { Button } from '@chakra-ui/react';
import { RiAddLine, RiCloseFill } from 'react-icons/ri';

import { capitalizeFirstLetter } from 'utils/format';

export default function DspFilterButton({ dspName, selectedDsps, handleDspFilter }) {
  return (
    <Button
      onClick={handleDspFilter}
      rounded="full"
      size="sm"
      rightIcon={selectedDsps.includes(dspName) ? <RiCloseFill /> : <RiAddLine />}
      variant={selectedDsps.includes(dspName) ? 'solid' : 'outline'}
      bg={selectedDsps.includes(dspName) ? `dsp.${dspName}` : 'transparent'}
      borderWidth={1}
      borderColor={selectedDsps.includes(dspName) ? 'transparent' : `dsp.${dspName}`}
      color={selectedDsps.includes(dspName) ? 'black' : `dsp.${dspName}`}
      _hover={{
        opacity: 0.9,
      }}>
      {capitalizeFirstLetter(dspName)}
    </Button>
  );
}
