import React, { useEffect, useState } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import DraggableTrack from '../../../../features/DraggableTrack';
import { useDisclosure } from '@chakra-ui/react';
import AddContributorModal from '../../../../components/AddArtistsForm/add-contributor-modal';
import HEAP from '../../../../constants/HEAP.gen.json';

export default function TrackList({
  tracks = false,
  handleTrackReorder,
  updateDataStore,
  handleRemoveNewTrackStub,
  contributorsList,
  loading,
  hasBeenReleased,
  isVideoType,
  setFileType,
  onContributorCreated,
}) {
  const [listTracks, setListTracks] = useState([]);
  const addContributorModal = useDisclosure();

  useEffect(() => {
    if (tracks && !loading) {
      setListTracks(
        tracks.map(t => {
          const { id, data, resourceType } = t;
          return {
            trackId: id,
            resourceType,
            ...data,
          };
        })
      );
    }
  }, [tracks, loading]);

  return (
    <>
      <AddContributorModal disclosure={addContributorModal} onSaved={onContributorCreated} />
      {listTracks.map((track, i) => (
        <Col xs={12} key={i}>
          <DraggableTrack
            isVideoType={isVideoType}
            setFileType={setFileType}
            contributorsList={contributorsList}
            trackId={track.trackId}
            trackFormData={track}
            onTrackReorder={handleTrackReorder}
            trackIndex={track.sequence - 1}
            updateDataStore={updateDataStore(track)}
            heapCodePrefix={HEAP.PUBLIC.CREATE_RELEASE.PREFIX.CREATE_RELEASE_TRACKLIST_TRACK}
            defaultExpanded={track.expanded}
            handleRemoveNewTrackStub={handleRemoveNewTrackStub}
            onContributorModalOpen={addContributorModal.onOpen}
            hasBeenReleased={hasBeenReleased}
          />
        </Col>
      ))}
    </>
  );
}
