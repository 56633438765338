import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const Payee = () => {
  const update = (id, data) =>
    AuthAxios.patch(`${DataApiUrl}/payees/${id}`, data)
      .then(res => res.data.data)
      .catch(err => {
        throw err.response;
      });
  const remove = id =>
    AuthAxios.delete(`${DataApiUrl}/payees/${id}`)
      .then(res => res.data.data)
      .catch(err => {
        throw err.response;
      });

  return { update, remove };
};

export default Payee;
