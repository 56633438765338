import { useTheme } from '@chakra-ui/react';

export default function useWaveFormProps(override = {}) {
  const theme = useTheme();

  const waveFormProps = {
    progressColor: [theme.colors.pinkHalfOpacity],
    waveColor: [
      theme.colors.black100,
      theme.colors.black0000,
      theme.colors.black0000,
      theme.colors.black0000,
      theme.colors.black0000,
      theme.colors.black0000,
      theme.colors.black0000,
      theme.colors.black0000,
      theme.colors.black0000,
      theme.colors.black30,
      theme.colors.black30,
      theme.colors.black30,
      theme.colors.black30,
      theme.colors.black30,
      theme.colors.black30,
      theme.colors.black30,
    ],
    fillParent: true,
    barGap: 1,
    barHeight: 2.5,
    barWidth: 1.5,
    height: 48, //wavesurfer measures in px
    zIndex: 3,
    position: 'absolute',
    scrollParent: false,
    normalize: true,
    autoCenter: true,
  };

  return {
    ...waveFormProps,
    ...override,
  };
}
