import ManageClipsStateManager from '../../manage-clips-state-manager';
import { default as validators } from '../../../schema-gen/clip';

class CreateClipsState extends ManageClipsStateManager {
  dependentFields = ['audio_source', 'clips', 'cover_art', 'title', 'display_artist_name'];
  static mutableFields = ['clips'];

  title = 'Create Clips';
  path = '/manage/clip/create_clips';
  componentName = 'ClipCreate';

  constructor({ saveState, stateData, currentPath, name, tainted }) {
    super(
      {
        saveState,
        stateData,
        currentPath,
        name,
        tainted,
        showTooltip: true,
        mutableFields: CreateClipsState.mutableFields,
      },
      {
        clips: validators.clips,
      }
    );
  }

  get description() {
    return `Move the highlighted slider to select where you want your 60-second clip to begin.
    You can create up to 6 clips to send to platforms.
    `;
  }

  get linkTitle() {
    if (!this.props.clips?.length) return this.title;
    return `${this.title} (${this.props.clips.length})`;
  }

  get disabled() {
    return !this.props.audio_source || this.props.audio_source instanceof File === false;
  }

  get disabledHoverText() {
    return 'A song must be uploaded prior to creating clips';
  }

  saveState(newState) {
    this.onSaveState(newState);
  }

  updateClips(clips) {
    this.saveState({ clips });
  }
}

export default CreateClipsState;
