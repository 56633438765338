import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import numeral from 'numeral';
import InfoTooltip from '../InfoTooltip/info-tooltip';

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const ProgressChunk = styled.div`
  background-color: ${props => props.theme.colors.white};
  height: 0.5rem;
  margin-right: ${props => (!props.last ? '0.25rem' : 0)};

  ${props =>
    props.disabled &&
    `
    background-color: ${props.theme.colors.gray};
  `}

  ${props =>
    props.first &&
    `
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  `}

  ${props =>
    props.last &&
    `
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  `}
`;

const ProgressChunkWrapper = styled.div`
  flex: ${props => props.flex || 1};
  position: relative;
`;

const ProgressChunkProgress = styled.div`
  background-color: ${props => props.theme.colors.positive};
  height: 0.5rem;
  left: 0;
  top: 0;
  transition: width 250ms ease;
  width: ${props => `${props.progress}%`};
  z-index: 10;

  ${props =>
    props.first &&
    `
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  `}

  ${props =>
    props.last &&
    `
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  `}
`;

const StatusText = styled.div`
  font-size: ${props => props.theme.fonts.sizes.xxsm};
  height: 1rem;
  margin-top: 0.25rem;
`;

const ProgressLabelSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkText = styled.div`
  margin: 0.75rem 0 0.25rem 0;
  margin-right: ${props => (props.small ? '0.25rem' : 0)};
`;

const LabelRow = styled.div`
  align-items: flex-end;
  color: ${props => (props.completed ? props.theme.colors.positive : props => props.theme.colors.white)};
  cursor: pointer;
  font-family: ${props => (props.small ? props.theme.fonts.families.numerals : props.theme.fonts.families.text)};
  font-size: ${props => (props.small ? props.theme.fonts.sizes.xxsm : props.theme.fonts.sizes.xsm)};
  font-weight: ${props => props.theme.fonts.weights.medium};
  display: flex;
  transition: color 250ms ease;

  &:hover {
    color: ${props => (props.completed ? props.theme.colors.positiveHover : props => props.theme.colors.brand.ghost)};
  }

  ${props =>
    props.blockClick &&
    `
    cursor: default;
    pointer-events: none;
  `}
`;

const DetailsText = styled.div`
  align-items: center;
  display: flex;
  font-size: ${props => props.theme.fonts.sizes.xxsm};
  font-weight: ${props => props.theme.fonts.weights.medium};
  height: 1rem;
  line-height: 0.75rem;
  margin-top: 0.25rem;
  max-width: 50%;
`;

const DetailsTooltip = styled(InfoTooltip)`
  margin-left: 1rem;
  padding-top: 0.25rem;
`;

const ProgressTrackerBar = React.forwardRef(({ chunks, className, onClick }) => {
  return (
    <Wrapper className={className}>
      {chunks.map(({ allowClick, label, progress, flex, details, status }, i) => {
        const currentStatus =
          progress === 100 ? 'complete' : i === 0 || chunks[i - 1].progress === 100 ? 'inProgress' : 'idle';
        return (
          <ProgressChunkWrapper flex={flex} key={i}>
            <ProgressChunk
              disabled={currentStatus === 'idle'}
              first={i === 0}
              label={label}
              last={i === chunks.length - 1}
              progress={progress}>
              <ProgressChunkProgress
                first={i === 0}
                last={i === chunks.length - 1}
                progress={currentStatus !== 'idle' ? progress : 0}
              />
            </ProgressChunk>
            <ProgressLabelSection>
              <LabelRow blockClick={!allowClick} completed={currentStatus === 'complete'} onClick={() => onClick(i)}>
                <LinkText small tooltip={null}>
                  {numeral(i + 1).format('00')}
                </LinkText>
                <LinkText tooltip={null}>{label}</LinkText>
              </LabelRow>
              {status && status[currentStatus] && <StatusText>{status && status[currentStatus]}</StatusText>}
              {details && details[currentStatus] && (
                <DetailsText completed={currentStatus === 'complete'}>
                  {details[currentStatus].text}
                  <DetailsTooltip
                    iconSize={'0.75rem'}
                    iconStyle={{
                      width: '0.75rem',
                      height: '0.75rem',
                      top: '-0.5rem',
                    }}
                    text="Distribute"
                  />
                </DetailsText>
              )}
            </ProgressLabelSection>
          </ProgressChunkWrapper>
        );
      })}
    </Wrapper>
  );
});

ProgressTrackerBar.displayName = 'ProgressTrackerBar';

ProgressTrackerBar.defaultProps = {
  chunks: [],
  onClick: () => {},
};

export default withTheme(ProgressTrackerBar);
