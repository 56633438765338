import React from 'react';
import { Select, FormControl, FormLabel, FormErrorMessage, Tooltip, useTheme } from '@chakra-ui/react';
import { RiInformationFill as InfoIcon } from 'react-icons/ri';
import IconedToolTip from 'components/IconedToolTip/IconedToolTip';
export default function LabeledSelect({
  name,
  width,
  htmlId,
  label,
  value,
  onChange,
  onBlur,
  variant,
  options,
  placeholder = 'Please select an option',
  tooltip,
  invalidMessage,
  hasToolTipIcon = true, //this only applies if there is a tooltip
  ...selectProps
}) {
  const theme = useTheme();

  const {
    div: divStyle,
    label: labelStyle,
    select: selectStyle,
  } = variant ? theme.components.LabeledSelect.variants[variant] : theme.components.LabeledSelect?.baseStyle || {};

  if (!name) console.error('SelectInput requires a name property.');

  htmlId = htmlId || name;

  function makeOptions() {
    return options.map(o => {
      let option = o;

      if (typeof option !== 'object') {
        option = { title: option, value: option };
      }

      return (
        <option key={option.value} value={option.value}>
          {option.title || option.value}
        </option>
      );
    });
  }

  return (
    <FormControl {...divStyle} isInvalid={!!invalidMessage} width={width || '100%'}>
      {!!tooltip && hasToolTipIcon ? (
        <FormLabel
          {...labelStyle}
          htmlFor={htmlId}
          display={'flex'}
          color={invalidMessage ? theme.colors.red100 : 'inherit'}>
          {label}
          <IconedToolTip
            iconProps={{ marginTop: '0.25rem', marginLeft: '0.25rem', size: 3 }}
            icon={InfoIcon}
            text={tooltip}
          />
        </FormLabel>
      ) : (
        <Tooltip label={tooltip} placement="top-start">
          <FormLabel {...labelStyle} htmlFor={htmlId} color={invalidMessage ? theme.colors.red100 : 'inherit'}>
            {label}
          </FormLabel>
        </Tooltip>
      )}
      <Select
        placeholder={placeholder}
        name={name}
        id={htmlId}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...selectStyle}
        {...selectProps}>
        {makeOptions()}
      </Select>
      {invalidMessage ? <FormErrorMessage>{invalidMessage}</FormErrorMessage> : ''}
    </FormControl>
  );
}
