import { withTheme } from '@emotion/react/macro';
import { MEDIA_TYPES } from 'constants/constants';
import React, { useState } from 'react';
import TabNav from '../../features/TabNav/tab-nav';
import MediaPlayer from '../MediaPlayer/media-player';
import SplitsFormContainer from './splits-form-table/splits-form-container';
import { Artists, Credits } from './track-metadata-components';
import TrackHeader from './track-header';
import { useReleaseStatusTrackContext } from './release-status-track-context';
import { Spinner, Center, Box, useTheme } from '@chakra-ui/react';
//import Box from '../Box';

const TrackWrapper = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      backgroundColor={theme.colors.black10}
      borderRadius="0.5rem"
      minHeight="80px"
      padding="0 2rem 0 1.5rem"
      marginBottom="1rem">
      {children}
    </Box>
  );
};

const CollapsedContent = ({ children }) => {
  return (
    <Box
      boxSizing="border-box"
      display="flex"
      justifyContent="space-between"
      padding="1.25rem 0"
      alignItems="center"
      width="100%"
      zIndex="5">
      {children}
    </Box>
  );
};

const ExpandedContent = ({ children }) => {
  return (
    <Box boxSizing="border-box" marginTop="1rem" padding="1rem 0 5.75rem 2.5rem" width="calc(100% - 4rem)" zIndex="10">
      {children}
    </Box>
  );
};

const TrackBody = withTheme(({ setIsLyricsExpanded, isLyricsExpanded, ...props }) => {
  const { trackData, viewConfig, refetchData } = useReleaseStatusTrackContext();
  const { isLoading, hasFetchError } = viewConfig;

  if (isLoading)
    return (
      <Center>
        <Spinner thickness="6px" speed="0.65s" color={props.theme.colors.brand.accentHover} size="xl" />
      </Center>
    );
  if (hasFetchError)
    return (
      <Center>
        There was an error fetching your content. <br />
        <a href="#" onClick={() => refetchData()}>
          {' '}
          Please try again
        </a>
        .
      </Center>
    );
  return [
    <Artists
      key="artist-tab"
      theme={props.theme}
      trackData={trackData}
      setIsLyricsExpanded={setIsLyricsExpanded}
      isLyricsExpanded={isLyricsExpanded}
    />,
    <Credits trackData={trackData} key="credits-tab" />,
    <SplitsFormContainer
      key="splits-tab"
      userGroupId={trackData.user_group_id}
      trackId={trackData.id}
      isrc_code={trackData.isrc_code}
      editing={viewConfig.isEditingSplits}
    />,
  ][viewConfig.activeTab];
});

const ReleaseStatusTrack = ({ saveSplits, ...props }) => {
  const { trackData, trackId, viewConfig } = useReleaseStatusTrackContext();
  const resourceType = trackData.resource_type;
  const [isLyricsExpanded, setIsLyricsExpanded] = useState(false);

  return (
    <TrackWrapper>
      <CollapsedContent>
        <MediaPlayer trackId={trackId} type={resourceType === 'Video' ? MEDIA_TYPES.VIDEO : MEDIA_TYPES.AUDIO} />
        <TrackHeader saveSplits={saveSplits} {...props} />
      </CollapsedContent>
      {viewConfig.showContent && (
        <ExpandedContent>
          <TabNav
            options={[
              { label: 'Details', onClick: viewConfig.setActiveTab },
              { label: 'Credits', onClick: viewConfig.setActiveTab },
              {
                label: 'Sound Recording Splits',
                onClick: viewConfig.setActiveTab,
                tooltip: 'Sound Recording Splits only. We do not currently support Publishing Splits.',
              },
            ]}
            disabled={viewConfig.disabledTabs}
            selected={viewConfig.activeTab}
          />
          <TrackBody {...props} isLyricsExpanded={isLyricsExpanded} setIsLyricsExpanded={setIsLyricsExpanded} />
        </ExpandedContent>
      )}
    </TrackWrapper>
  );
};

export default withTheme(ReleaseStatusTrack);
