import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { ButtonCol, ErrorCol, MetadataLabel, MetadataRow } from 'styles';
import { Text } from '@chakra-ui/react';
import Button from 'components/Button/button';
import Modal, { ModalHeaderWrapper } from 'features/Modal/modal';
import styled from '@emotion/styled/macro';

const SimpleSplitsTable = ({ isrc_code, splits }) => {
  return (
    <div style={{}}>
      <h5 style={{ fontWeight: 700, marginBottom: '1rem' }}>Splits for {isrc_code}</h5>
      <SimpleSplitsTableRow header short />
      <div>
        {!splits?.length && (
          <SimpleSplitsTableRow
            row={{ percentage: '-', roles: ['-'], payee: { payment_account: { user: { name: '-' } } } }}
          />
        )}
        {splits?.map(s => (
          <SimpleSplitsTableRow key={`split-${s.id}`} row={s} />
        ))}
      </div>
    </div>
  );
};

const TextCol = styled(Col)`
  overflow: hidden;
  white-space: nowrap;
  ${props => props.ellipsis && `text-overflow: ellipsis;`}
`;

const SimpleSplitsTableRow = ({ row, header, short }) => (
  <MetadataRow short={short} style={{ marginBottom: header ? '0.5rem' : '1rem', justifyContent: 'space-between' }}>
    <TextCol ellipsis={!header} xs={6}>
      {header ? (
        <MetadataLabel>Name</MetadataLabel>
      ) : (
        row?.payee?.payment_account?.user?.name || row?.payee?.payment_account?.group?.name
      )}
    </TextCol>
    <TextCol ellipsis={!header} xs={4}>
      {header ? <MetadataLabel>Role</MetadataLabel> : row.roles[0]}
    </TextCol>
    <TextCol ellipsis={true} xs={2}>
      {header ? <MetadataLabel>Split %</MetadataLabel> : `${row.percentage}${row.percentage === '-' ? '' : '%'}`}
    </TextCol>
  </MetadataRow>
);

const ConfirmIsrcModal = ({ isShowing, isrc_code, hideModal, splits, errorMsg, onConfirm }) => {
  return (
    <Modal
      header={
        <ModalHeaderWrapper style={{ paddingLeft: '1.5rem' }}>
          <Text fontSize="xl" fontWeight="bold" color="cream.100">
            Confirm ISRC
          </Text>
          <Text color="cream.100" marginTop="0.5rem">
            {splits?.length
              ? 'This ISRC already has splits associated with it.'
              : 'This ISRC has no splits associated with it.'}
          </Text>
        </ModalHeaderWrapper>
      }
      isShowing={isShowing}
      config={{
        contentPadding: '1.5rem 1.5rem',
        style: {
          width: '500px',
          height: 'auto',
        },
      }}
      onClose={() => hideModal('confirmIsrc')}>
      <SimpleSplitsTable isrc_code={isrc_code} splits={splits} />

      <Row style={{ marginTop: '2rem' }}>
        <ErrorCol xs={6}>{errorMsg}</ErrorCol>
        <ButtonCol xs={6}>
          <Button text="Cancel" tertiary onClick={() => hideModal('confirmIsrc')} />
          <Button text="Confirm ISRC" submit onClick={onConfirm} />
        </ButtonCol>
      </Row>
    </Modal>
  );
};

export default ConfirmIsrcModal;
