import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { ErrorCol } from 'styles';
import {
  Text,
  Modal as ChakraModal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button as ChakraButton,
} from '@chakra-ui/react';

const ConfirmSplitChangesModal = ({ isShowing, hideModal, releases = [], errorMsg, onConfirm }) => {
  return (
    <ChakraModal isOpen={isShowing} onClose={() => hideModal('confirmIsrc')}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader fontSize="xl">
          {/* We are only passing in "other releases" - offset by 1 */}
          This change will apply on {releases.length + 1} releases
        </ModalHeader>
        <ModalBody color="black100" marginTop="0.5rem">
          The split percentages cannot differ for a single ISRC.
          <br />
          Changing the splits here will also apply to the following releases:
          {releases.map(r => (
            <Row key={`split-check-release-${r.id}`}>
              <Col>
                <Text
                  color="black100"
                  marginTop="0.875rem"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis">
                  {r.title || 'untitled'} | {r.type || '[unknown type]'} | {r.asset_release_upc || '[no upc]'}
                </Text>
              </Col>
            </Row>
          ))}
        </ModalBody>

        <ModalFooter>
          <ErrorCol xs={6}>{errorMsg}</ErrorCol>
          <ChakraButton variant="small" onClick={() => hideModal('confirmIsrc')}>
            Cancel
          </ChakraButton>
          <ChakraButton variant="smallDark" submit onClick={onConfirm}>
            Confirm Changes
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default ConfirmSplitChangesModal;
