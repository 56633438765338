import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { FormProvider, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import numeral from 'numeral';

import Button from 'components/Button/button';
import DatePicker from 'features/DatePicker/date-picker';
import Checkbox from 'features/Checkbox/checkbox';
import InfoTooltip from 'components/InfoTooltip/info-tooltip';
import { HStack } from '@chakra-ui/react';

import useCardFlow from 'utils/use-card-flow';
import { useReleaseForm } from 'utils/use-release-form';
import { CardContext } from '../card-context';

import { ButtonCol, Paragraph14 } from 'styles';

import { TrackRowContainer, TrackRowBody, CardLoadingWrapper, CardContentWrapper, CardHeaderBlock } from './card-style';

import { CARDS } from '../card';

import HEAP from '../../../constants/HEAP.gen.json';

const InstantGatTracks = () => {
  const CARD_ID = CARDS.InstantGratTracks;
  const {
    id,
    getCardData,
    getCardDataById,
    handleResetSaveAndPreview,
    NAV_DIRECTIONS,
    afterSaveActions,
    loadingAny,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { cardData, updateCard_Form, isReviewMode, _passthrough } = useCardFlow();

  const { releaseTracks } = useReleaseForm();
  const formMethods_InstantGratTracks = useForm();
  const formValues_InstantGratTracks = formMethods_InstantGratTracks.getValues({
    nest: true,
  });
  const errors_InstantGratTracks = formMethods_InstantGratTracks.errors;

  const otherCardData_InstantGratTracks = {
    PreOrderDate: getCardDataById('PreOrderDate', 'formData'),
  };

  useEffect(() => {
    if (id === CARD_ID) {
      formMethods_InstantGratTracks.reset(getCardData('formData'));
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_InstantGratTracks.handleSubmit(data => save_InstantGratTracks(data, _passthrough.inducePreview))();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  const save_InstantGratTracks = (data, forcePreview = false, navigation = null) => {
    let saveData = { ...formValues_InstantGratTracks, ...data };

    saveData._meta.includeInstantGratDates.forEach((option, i) => {
      if (!option) {
        saveData.track_instant_grat_dates[i] = null;
      }
    });

    updateCard_Form(id, saveData);

    const showPreview = isReviewMode || forcePreview;
    afterSaveActions(showPreview, navigation);
  };

  const InstantGratTracks_minDate = otherCardData_InstantGratTracks.PreOrderDate?.pre_order_release_date;

  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <FormProvider {...formMethods_InstantGratTracks}>
          <CardHeaderBlock omnishareas>
            <Row>
              <Col xs={6}>
                <HStack>
                  <h3>Instant Grat Tracks</h3>
                  <InfoTooltip text="Pre-orders must select at least one Instant Gratification track. Instant Gratification allows consumers to buy specific, select tracks before the official release of your album. When someone pre-orders the entire album, they will immediately receive the Instant Gratification track selected. You cannot enable instant Gratification for more than 49% of the tracks on a given release. If you do not desire to make a track available for stream or download upon pre-order purchase, select at least one track and set the release date to the global release date of the album." />
                </HStack>
              </Col>
              <ButtonCol xs={6}>
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={() =>
                      save_InstantGratTracks(formMethods_InstantGratTracks.watch(), false, NAV_DIRECTIONS.BACK)
                    }
                    disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_BUTTON_BACK}
                  />
                )}
                <Button
                  text="Save & Preview"
                  loading={loadingSaveRelease || loadingAny}
                  onClick={formMethods_InstantGratTracks.handleSubmit(data => save_InstantGratTracks(data, true))}
                  submit
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_BUTTON_NEXT}
                />
              </ButtonCol>
            </Row>
            <Row>
              <Col xs={12}>
                <Paragraph14 />
              </Col>
            </Row>
          </CardHeaderBlock>
          <Row>
            <Col xs={12} md={6} style={{ marginBottom: '5rem' }}>
              {!isFlowAnimating &&
                releaseTracks &&
                releaseTracks.map((track, i) => (
                  <TrackRowContainer key={i}>
                    <Checkbox
                      light
                      label={numeral(track.data.sequence).format('00')}
                      name={`_meta.includeInstantGratDates[${i}]`}
                      disabled={loadingSaveRelease || loadingAny}
                      onClick={() =>
                        !formMethods_InstantGratTracks.watch(`_meta.includeInstantGratDates[${i}]`) &&
                        formMethods_InstantGratTracks.clearErrors(`track_instant_grat_dates[${i}]`)
                      }
                      ref={formMethods_InstantGratTracks.register({
                        required: false,
                      })}
                    />
                    <TrackRowBody
                      disabled={!formMethods_InstantGratTracks.watch(`_meta.includeInstantGratDates[${i}]`)}>
                      <span>{track.data.title}</span>
                      <div>
                        <DatePicker
                          floatRight
                          floatTop={releaseTracks?.length < 3 ? i > 0 : i > 1}
                          name={`track_instant_grat_dates[${i}]`}
                          disabled={
                            loadingSaveRelease ||
                            loadingAny ||
                            !formMethods_InstantGratTracks.watch(`_meta.includeInstantGratDates[${i}]`)
                          }
                          setValue={formMethods_InstantGratTracks.setValue}
                          defaultSelectedDate={formMethods_InstantGratTracks.watch(`track_instant_grat_dates[${i}]`)}
                          minDate={InstantGratTracks_minDate}
                          maxDate={DateTime.local().plus({ years: 1 }).toISODate()}
                          placeholder="Choose date"
                          clearErrors={formMethods_InstantGratTracks.clearErrors}
                          error={
                            errors_InstantGratTracks['track_instant_grat_dates'] &&
                            errors_InstantGratTracks['track_instant_grat_dates'][i]
                          }
                          ref={formMethods_InstantGratTracks.register({
                            validate: value =>
                              formMethods_InstantGratTracks.watch(`_meta.includeInstantGratDates[${i}]`)
                                ? !!value
                                : true,
                          })}
                        />
                      </div>
                    </TrackRowBody>
                  </TrackRowContainer>
                ))}
              <br />
              <br />
            </Col>
          </Row>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};

export default InstantGatTracks;
