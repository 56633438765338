import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Heading, Button, Text, useTheme } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import VeniceLogo from 'assets/default/venice-hardcode.png';

const FOOTER_HEIGHT = '4.5rem';

const GenericWithCtaHome = ({ headingMessage, bodyMessage }) => {
  const theme = useTheme();
  return (
    <Box
      minHeight={`calc(100vh - ${FOOTER_HEIGHT})`}
      maxWidth={'1280px'}
      padding={'1.25rem 2rem 1.25rem'}
      color={theme.colors.black100}>
      <Helmet>
        <style>{`
          body, #root {
            background-color: #FFFFFF;
            height: 100%;
            color:
          }

        `}</style>
      </Helmet>
      <Box w="140px">
        <a href="/">
          <img src={VeniceLogo} alt="Venice logo" />
        </a>
      </Box>
      <Box maxWidth={'64ch'}>
        <Heading fontSize="5xl">{headingMessage || 'Looks like we skipped a beat.'}</Heading>
        <Text my={4}>
          {bodyMessage ||
            `It seem like you have run into some unintended behavior. Please ensure you are using the latest web browser version as this is required to best access all pages and features. You are always welcome to reach out to support if you’re having persistent issues.`}
        </Text>
        <Text mt={4} mb={8}>
          Contact us at{' '}
          <a style={{ color: theme.colors.pink }} href="mailto:yourfriends@venicemusic.co">
            yourfriends@venicemusic.co
          </a>{' '}
          for assistance.
        </Text>
        <Button variant={'dark'} as={Link} to="/">
          Go Home
        </Button>
      </Box>
    </Box>
  );
};

export default GenericWithCtaHome;
