import React from 'react';

import numeral from 'numeral';
import { PAYEE_TYPES } from '../../data-client/user';

import { Heading, Box, Flex, Progress, Link, Text } from '@chakra-ui/react';
import Well from 'components/Well/well';

const StatementsHeader = ({
  TimeframeFilter,
  isLabelAdmin,
  payeeType,
  royaltyTransferSummary,
  account,
  PersonalOrLabelButtons,
}) => {
  if (!account || !royaltyTransferSummary) {
    return null;
  }

  const headerBoxStyling = {
    variant: 'uppercaseLabel',
    marginBottom: '1rem',
    color: 'black100',
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
    fontSize: 'sm',
    fontWeight: 'bold',
  };

  return (
    <Flex justifyContent="space-between" flexWrap="wrap" flexBasis={2}>
      <Heading width="100%">Payment Statements</Heading>
      {account?.ready_for_payment === false && (
        <Text
          fontSize="sm"
          color="white"
          width="100%"
          backgroundColor="#da7e3c"
          borderRadius="0.3rem"
          p="1rem"
          mt="1rem"
          mb="0.3rem">
          {isLabelAdmin ? (
            <>
              We need more information to complete your payment as a label. Please{' '}
              <Link href="/settings#label_profile" textDecoration="underline">
                Complete Stripe Onboarding
              </Link>
              . The below information may be inaccurate until this is complete.
            </>
          ) : (
            <>
              We need more information to complete your payment as a payee. Please{' '}
              <Link href="/settings#my_profile" textDecoration="underline">
                Complete Stripe Onboarding
              </Link>
              . The below information may be inaccurate until this is complete.
            </>
          )}
        </Text>
      )}
      <Flex width="100%" justifyContent="space-between" my="1.25rem">
        <Box maxWidth="20rem">{TimeframeFilter}</Box>
        {isLabelAdmin && <Box>{PersonalOrLabelButtons}</Box>}
      </Flex>
      <Box
        width={'100%'}
        padding={'1rem'}
        borderTop={`1px`}
        borderTopRadius={'0.5rem'}
        borderLeft={`1px`}
        borderRight={`1px`}>
        <Flex spacing="2rem" alignItems="start" direction={{ base: 'column', sm: 'row' }}>
          <Box marginTop="1rem" width={{ base: '100%', sm: '50%' }}>
            {isLabelAdmin && payeeType === PAYEE_TYPES.USER ? (
              <>
                <Box {...headerBoxStyling}>Paid Earnings</Box>
                <Heading as={'h3'} disabled={royaltyTransferSummary.total === null}>
                  {numeral(royaltyTransferSummary.total).format('$0,0.00')}
                </Heading>
              </>
            ) : (
              <>
                <Box {...headerBoxStyling}>{`Total Paid${account?.name ? ` to ${account.name}` : ''}`} </Box>
                <Heading as={'h3'} disabled={royaltyTransferSummary.total === null}>
                  {numeral(royaltyTransferSummary.total).format('$0,0.00')}
                </Heading>
              </>
            )}
            <Well variant="collapsible" styleProps={{ width: '20rem' }}>
              These are the earnings paid for the selected time period. Please note that music stores & streaming
              services deliver reports & royalties on their own schedule and at different frequencies.
            </Well>
          </Box>
          {royaltyTransferSummary.pending_transfer_amount ? (
            <Box marginTop="1rem" width={{ base: '100%', sm: '50%' }} alignItems="start" alignContent="start">
              {royaltyTransferSummary.pending_transfer_amount < 50 ? (
                <>
                  <Box {...headerBoxStyling}>Earnings awaiting $50 threshold</Box>
                  <Heading as={'h3'}>
                    {numeral(royaltyTransferSummary.pending_transfer_amount).format('$0,0.00')}
                  </Heading>
                  <Progress
                    value={(royaltyTransferSummary.pending_transfer_amount / 50) * 100}
                    colorScheme="green"
                    borderRadius="0.38rem"
                    borderColor="black50"
                    background="#F0EFF1"
                  />
                </>
              ) : (
                <>
                  <Box {...headerBoxStyling}>Pending transfers for next payout</Box>
                  <Heading as={'h3'}>
                    {numeral(royaltyTransferSummary.pending_transfer_amount).format('$0,0.00')}
                  </Heading>
                </>
              )}
              <Well variant="collapsible" styleProps={{ width: '20rem' }}>
                Venice will pay out earnings each month if revenue received from music stores and streaming platforms on
                the payment date is $50 USD or more. For example, if we have received $20 in January and $30 in
                February, you will be paid a total of $50 in (minus payment processing fees) in the next payment period.{' '}
                <Link
                  href="https://support.venice.tech/distribution/im-on-a-release-but-havent-been-paid-help"
                  color="red"
                  isExternal>
                  Learn More
                </Link>
                .
              </Well>
            </Box>
          ) : null}
        </Flex>
      </Box>
    </Flex>
  );
};

export default StatementsHeader;
