import React, { useContext, useEffect, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Col, Row } from 'react-styled-flexboxgrid';

import Button from 'components/Button/button';
import PartyArray from 'features/PartyArray/party-array';
import VariousArtistInformationModal from 'features/various-artists-information-modal';
import useCardFlow from 'utils/use-card-flow';
import { useReleaseForm } from 'utils/use-release-form';
import { CardContext } from '../card-context';
import InfoTooltip from 'components/InfoTooltip/info-tooltip';
import { artistRoles } from '../../../constants/roles';
import { CardContentWrapper, CardHeaderBlock, ValidationMessage } from './card-style';

import { ButtonCol } from 'styles';

import { CARDS } from '../card';
import * as API from 'utils/API/API';
import { useToast, useDisclosure, HStack } from '@chakra-ui/react';
import AddArtistModal from '../../../settings/add-artist-modal';
import { useQueryClient } from 'react-query';
import styled from '@emotion/styled/macro';

import HEAP from '../../../constants/HEAP.gen.json';
import { useUserTierChecks } from 'hooks/authorization-hooks';
import { generatePartySubmitObject } from '../../../utils/form';

const Party = API.party();
const Release = API.release();
const Group = API.group();

export const CardLoadingWrapper = styled.div`
  transition: opacity 250ms ease;

  ${props =>
    props.loading === true &&
    `
    opacity: 0.25
  `};
`;

const CardLoadingContainer = ({ children, loading, ...rest }) => (
  <CardLoadingWrapper loading={loading.toString()} {...rest}>
    {!loading && children}
  </CardLoadingWrapper>
);

const ReleaseArtists = () => {
  const CARD_ID = CARDS.ReleaseArtists;
  const {
    id,
    getCardData,
    NAV_DIRECTIONS,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
    loadingAny,
    afterSaveActions,
    handleSubmitError,
  } = useContext(CardContext);

  const toast = useToast();
  const { release } = useReleaseForm();
  const queryClient = useQueryClient();

  const [displayVariousArtistsModal, setDisplayVariousArtistsModal] = useState(false);

  const {
    data: artistQueryData,
    isFetching: isFetchingArtists,
    status: artistsStatus,
  } = useQuery(
    ['artists', release?.id],
    () => (release ? Party.list('artist', { user_group_id: release.user_group_id }) : []),
    {
      staleTime: 60000,
    }
  );

  const addArtistModal = useDisclosure();

  const closeVariousArtistsModal = useCallback(() => {
    setDisplayVariousArtistsModal(false);
  }, []);

  const formMethods_ReleaseArtists = useForm();
  const [artists, setArtists] = React.useState(release?.artists);
  const [shouldTriggerVariousArtistsModal, setShouldTriggerVariousArtistsModal] = useState(
    release?.artists?.length < 4
  );

  const [error, setError] = React.useState(null);

  const { refreshLayoutState, _passthrough, cardData, isReviewMode } = useCardFlow();

  const [activeIndex, setActiveIndex] = React.useState(null);

  useEffect(() => {
    if (id === CARD_ID) {
      formMethods_ReleaseArtists.reset(getCardData('formData'));
      refreshLayoutState();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (!artists) {
      return;
    }

    if (artists.length >= 4) {
      setDisplayVariousArtistsModal(shouldTriggerVariousArtistsModal);
      setShouldTriggerVariousArtistsModal(false);
    } else {
      setShouldTriggerVariousArtistsModal(true);
    }
  }, [artists]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_ReleaseArtists.handleSubmit(
        data => save_ReleaseArtists(data, _passthrough.inducePreview),
        _data => handleSubmitError(_passthrough.inducePreview)
      )();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  useEffect(() => {
    if (release?.artists) {
      setArtists(release.artists);
    }
  }, [release?.id]);

  const saveReleaseArtistsMutation = useMutation(data => Release.update(release.id, data), {
    onError: () => {
      toast({
        title: `Something went wrong adding the artist`,
        status: 'error',
      });
    },
    onSuccess: () => {
      window.location = `/releases/${release.id}/edit/0/4`;
    },
  });

  const save_ReleaseArtists = async () => {
    if (!artists?.length) {
      setError('Please add at least one artist');
      return;
    }

    if (!artists?.some(a => a.roles.includes('MainArtist'))) {
      // error handled by setError fn prop on PartyArray
      return;
    }

    if (artists.some(a => !a.roles?.length)) {
      setError('Please select role for all artists');
      return;
    }

    await saveReleaseArtistsMutation.mutateAsync({
      artists: artists.map((a, i) => generatePartySubmitObject({ ...a }, i + 1)),
    });
  };

  const onCreateArtist = async data => {
    await queryClient.invalidateQueries(['artists']);
    setArtists(prev => prev.map((a, i) => (activeIndex === i ? data : a)));
  };

  const saveReleaseArtistsHandler = () => {
    const includesRequiredReleaseArtist = artists.find(a => a.is_required_release_artist);
    if (!includesRequiredReleaseArtist) {
      setError(
        'All releases must include at least one required artist (as a primary) that is designated in the Settings > Artists tab'
      );
      return;
    }
    setError(null);
    return save_ReleaseArtists();
  };

  const { needsRequiredReleaseArtist } = useUserTierChecks();
  const saveRequiredReleaseArtistsHandler = needsRequiredReleaseArtist
    ? saveReleaseArtistsHandler
    : save_ReleaseArtists;

  const { data: hasRelease, isFetching: hasRelease_isLoading } = useQuery(
    ['groupHasRelease'],
    () => Group.hasReleases(release.user_group_id),
    {
      enabled: !!release,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const isLoading = loadingAny || hasRelease_isLoading || isFetchingArtists || !artists || artistsStatus === 'loading';

  return (
    <CardContentWrapper omnishareas>
      <AddArtistModal disclosure={addArtistModal} useReleaseGroupId={true} onCreateArtist={onCreateArtist} />
      <CardLoadingContainer loading={isLoading} omnishareas>
        <FormProvider {...formMethods_ReleaseArtists}>
          <form autoComplete="off">
            <CardHeaderBlock omnishareas>
              <Row>
                <Col xs={4}>
                  <HStack>
                    <h3>Release Artists</h3>
                    <InfoTooltip text="Release artists are the primary or featured performing artists that perform on all tracks on an album. Submissions with more than 3 primary artists listed in this section will be auto-converted to ‘Various Artists’ by most major DSPs (Spotify, Apple Music, etc.) If a primary or featured artist only performs on a specific track on an album, add them at the track level in the Tracklist section." />
                  </HStack>
                </Col>
                <ButtonCol xs={8}>
                  <ValidationMessage>{error && <span>{error}</span>}</ValidationMessage>
                  {!isReviewMode && (
                    <Button
                      text="Back"
                      icon="chevronLeft"
                      onClick={() => afterSaveActions(false, NAV_DIRECTIONS.BACK)}
                      disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                      tertiary
                      heapCode={`${HEAP.PUBLIC.CREATE_RELEASE.PREFIX.CREATE_RELEASE_ARTISTS}-button-back`}
                    />
                  )}
                  <Button
                    text={isReviewMode ? 'Save & Preview' : 'Next'}
                    rightIcon={isReviewMode ? null : 'chevronRight'}
                    onClick={saveRequiredReleaseArtistsHandler}
                    loading={loadingSaveRelease || loadingAny}
                    disabled={isFlowAnimating}
                    heapCode={`${HEAP.PUBLIC.CREATE_RELEASE.PREFIX.CREATE_RELEASE_ARTISTS}-button-next`}
                  />
                </ButtonCol>
              </Row>
              <Row>
                <Col xs={12}>
                  <p>
                    Artists added here will automatically appear on the release and each track.{' '}
                    {/*Featured Artists on Singles will automatically be added on the release level.*/}
                  </p>
                </Col>
              </Row>
            </CardHeaderBlock>
            <Row>
              <Col xs={12}>
                <PartyArray
                  name="artists"
                  release={release}
                  artistRoleOptions={artistRoles}
                  disabled={loadingSaveRelease || loadingAny}
                  heapCodePrefix={HEAP.PUBLIC.CREATE_RELEASE.PREFIX.CREATE_RELEASE_ARTISTS}
                  artistsList={artistQueryData?.data || []}
                  status={artistsStatus}
                  existingArtists={release?.artists}
                  onArtistModalOpen={addArtistModal.onOpen}
                  artists={artists}
                  setArtists={setArtists}
                  setActiveIndex={setActiveIndex}
                  setError={(type, error) => setError(error.message)}
                />
              </Col>
            </Row>

            {!hasRelease && needsRequiredReleaseArtist && (
              <Row>
                <Col xs={12} style={{ marginTop: '1em' }}>
                  You can set your “required release artist” <a href="/settings#artists">here</a>
                </Col>
              </Row>
            )}
          </form>
        </FormProvider>
      </CardLoadingContainer>
      <CardLoadingSpinner loading={isLoading} />
      <VariousArtistInformationModal
        noFooter={true}
        isOpen={displayVariousArtistsModal}
        onClose={closeVariousArtistsModal}
        headerText="That's a lot of artists"
      />
    </CardContentWrapper>
  );
};
export default ReleaseArtists;
