import axios from 'axios';

const createFormData = (file, fields) => {
  const formData = new FormData();
  Object.entries(fields).forEach(([k, v]) => {
    formData.append(k, v);
  });

  formData.append('file', file);
  return formData;
};

export const uploadFile = async (signedPost, file, setError, setProgress) => {
  let lastPercent = 0;

  const { url, fields } = signedPost;

  const formData = createFormData(file, fields);

  const onUploadProgress = progressEvent => {
    const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    if (lastPercent !== percent) {
      setProgress(percent);
      lastPercent = percent;
    }
  };

  let config = {
    onUploadProgress,
  };

  setProgress(0);
  try {
    return axios.post(url, formData, config);
  } catch (err) {
    setError(err);
  } finally {
    setProgress(100);
  }
};

const useSignedPostUploader = ({ setProgress, setError }) => {
  return {
    //signedPost  :  'data' that is returned from an axios request
    //               *example* {data:signedPost} = axios(`{endpoint-that-returns-a-signed-post}`)
    //file:       :  file data for upload
    //contentType :  the HTTP content type that gets forwarded along
    uploadFile: async ({ file, signedPost }) => {
      return uploadFile(signedPost, file, setError, setProgress);
    },
  };
};

export default useSignedPostUploader;
