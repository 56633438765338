import React from 'react';
import {
  Box,
  Text,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  useTheme,
  useToast,
  Link,
} from '@chakra-ui/react';
import asModal from 'features/Modal/as-modal';
import { useGetReferralCode } from 'data-client/use-referral-code';
import { useReferralLinkCopyEventMutation } from 'data-client/member-events';

function ReferralModal() {
  const theme = useTheme();
  const toast = useToast();
  const affiliateIdUseQuery = useGetReferralCode();
  const referralLinkCopyEventMutation = useReferralLinkCopyEventMutation();

  const refLink = affiliateIdUseQuery?.data ? `${affiliateIdUseQuery?.data?.link}3rQaFh9M` : '';
  const spaceBetween = '0.25rem';

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(refLink);
    referralLinkCopyEventMutation.mutate();
    toast({ title: `Copied ${refLink} to clipboard.` });
  }

  return (
    <Box padding="0 .5rem .5rem .5rem">
      <Heading as="h1" size="lg" mt={0}>
        Earn $100 per referral!
      </Heading>
      <InputGroup size="lg">
        <Input
          pr="8rem"
          fontSize="sm"
          readOnly
          value={affiliateIdUseQuery?.isLoading ? 'Loading...' : refLink}
          variant="filled"
          borderRadius={'2rem'}
          backgroundColor={'#F0EFF1'}
        />
        <InputRightElement width="7rem" pr="1rem">
          <Button
            h="1.75rem"
            size="sm"
            variant="dark"
            disabled={!affiliateIdUseQuery.isFetched}
            onClick={handleCopyToClipboard}>
            Copy your link
          </Button>
        </InputRightElement>
      </InputGroup>
      <Text fontSize="lg" color="#333333" mt="1rem" mb="1rem">
        Invite your friends to join Venice at a discounted price! For every person that signs up, you'll earn cash
        straight to your wallet.
      </Text>
      <TableContainer fontSize="lg">
        <Table variant="unstyled" width="60%">
          <Thead backgroundColor={theme.colors.white}>
            <Tr borderColor="#CBD5E0" borderBottom={'1px'}>
              <Th borderRight={0} pl={0}>
                TIER
              </Th>
              <Th pl={0}>YOU EARN</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr border={0}>
              <Td pt={spaceBetween} pb={spaceBetween} pl={0}>
                Pro
              </Td>
              <Td pt={spaceBetween} pb={spaceBetween} pl={0}>
                $100 per sign up
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Text fontSize="lg" color="#333333" mt="1rem">
        You can find all the details of the Venice Referral Program{' '}
        <Link href="https://blog.venicemusic.co/venice-music-referral-program" color="#fe446c">
          here
        </Link>
        .
      </Text>
    </Box>
  );
}

export default asModal(ReferralModal);
