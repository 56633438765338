import React, { useEffect, useCallback, useState, useRef } from 'react';
import includes from 'lodash/includes';
import { ClipLoader } from 'react-spinners';
import { Spring } from 'react-spring/renderprops';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import MultiCheckListItem from '../../components/MultiCheckListItem/multi-check-list-item';
import { renderFieldError } from 'utils/form';
import { useFormContext } from 'react-hook-form';
import { FixedSizeList } from 'react-window';

import DropdownSearchHeader from 'components/DropdownSearchHeader/dropdown-search-header';

import { Chevron, Label, List } from '../styles';
import DropdownSectionHeader from 'components/DropdownSectionHeader/dropdown-section-header';

const Wrapper = styled.div`
  position: relative;
`;

const Activator = styled.div`
  background: ${props => props.theme.colors.background.dropdown[props.colorVariant].default};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.border.dropdown[props.colorVariant].default};
  color: ${props => props.theme.colors.text.dropdown[props.colorVariant].default};
  cursor: pointer;
  font-weight: ${props => props.theme.fonts.weights.regular};
  overflow: hidden;
  position: relative;
  transition: background 250ms ease, border-radius 250ms ease;

  &:hover {
    ${props =>
    !props.isMenuShowing &&
      `
			background: ${props.theme.colors.background.dropdown[props.colorVariant].hover};
		`}
  }

  ${props =>
    props.invalid &&
    `
    border-color: ${props.theme.colors.negative};
    outline-color: ${props.theme.colors.negative};
  `}

  ${props =>
    props.isMenuShowing &&
    `
		color: ${props.theme.colors.text.dropdown[props.colorVariant].active}
		border-radius: 4px 4px 0 0;
	`}

	${props =>
    props.isSelected &&
    `
		font-weight: ${props.theme.fonts.weights.medium};
	`}

	${props =>
    props.disabled &&
    `
    color: ${props.theme.colors.text.dropdown[props.colorVariant].disabled}
		pointer-events: none;
		cursor: default;
	`}
`;

const ClickShrowd = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: ${props => (props.short ? props.length * 2 + 0.5 : props.length * 3)}rem;
  max-height: ${props => (props.hasCategory ? '192px' : '200px')};
  overflow-y: scroll;

  ${props =>
    props.windowed &&
    `
		overflow-y: hidden;
	`}
`;

const FadeWrapper = styled.div`
  opacity: ${props => (props.isAnimating ? 0 : 1)};
  transition: opacity 250ms ease;
`;

const HiddenSelect = styled.select`
  display: none;
`;

const MultiCheckList = React.forwardRef(
  (
    {
      className,
      dark,
      disabled,
      categoryLabel,
      clearErrors,
      error,
      label,
      loading,
      defaultValue,
      placeholder,
      light,
      name,
      options,
      short,
      theme,
      windowed,
      heapCode,
      selectAllFilter,
    },
    ref
  ) => {
    const colorVariant = dark ? 'dark' : light ? 'light' : 'dark';

    const [isMenuShowing, setIsMenuShowing] = useState(false);
    const [doHideSpringElement, setDoHideSpringElement] = useState(true);
    const [refActivator, setRefActivator] = useState(null);
    const [refList, setRefList] = useState(null);
    const [menuHeight, setMenuHeight] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
    const refContainer = useRef(null);

    const [filterString, setFilterString] = useState('');

    const { setValue, getValues, watch } = useFormContext();
    let formValues = getValues(name);

    const selectedQty = (watch(name) || []).length;
    const labelString = selectedQty > 0 ? `${label} (${selectedQty})` : placeholder ? placeholder : label;

    useEffect(() => {
      if (isMenuShowing) {
        setDoHideSpringElement(false);
        setFilterString('');
      }
    }, [isMenuShowing]);

    // Global document click handler

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);

      return () => document.removeEventListener('click', handleDocumentClick);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDocumentClick = e => {
      if (
        e.target !== refContainer.current &&
        !refContainer.current.contains(e.target) &&
        !['li', 'svg', 'path'].includes(e.target.nodeName.toLowerCase()) // Hack alert! Can't get at react-window list with ref
      ) {
        setIsMenuShowing(false);
      }
    };

    // Calculate dropdown menu height

    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRefActivator = useCallback(node => {
      if (node && !node.isEqualNode(refActivator)) {
        setRefActivator(node);
      }
    });

    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRefList = useCallback(node => {
      if (node && !node.isEqualNode(refList)) {
        setRefList(node);
      }
    });

    useEffect(() => {
      if (refActivator) {
        const height = options.length * (short ? 32 : 48) + 48 + (categoryLabel ? 32 : 0) + (short ? 0 : -8);
        const maxHeight = 15 * 16;
        setMenuHeight(height >= maxHeight ? maxHeight : height);
      }
      // DEBT:
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refActivator, options]);

    // Event Listeners

    const handleActivatorClick = () => {
      setIsMenuShowing(!isMenuShowing);
    };

    const filter = typeof selectAllFilter === 'function' ? selectAllFilter : _ => true;

    const handleSelection = type => {
      switch (type) {
        case 'all':
          setValue(
            name,
            options.filter(filter).map(option => option.value)
          );
          break;
        case 'none':
          setValue(name, []);
          break;
        default:
          break;
      }

      clearErrors();
    };

    const handleClick = value => {
      if (!includes(formValues, value)) {
        setValue(name, (formValues || []).concat([value]));
      } else {
        setValue(
          name,
          formValues.filter(option => option !== value)
        );
      }

      clearErrors();
    };

    const handleFilterChange = value => {
      setFilterString(value);
    };

    const handleSpringRest = () => {
      if (!isMenuShowing) setDoHideSpringElement(true);
      setIsAnimating(false);
    };

    const filteredItems = options.filter(
      option => option.label.toLowerCase().indexOf(filterString.toLowerCase()) !== -1
    );

    return (
      <Wrapper ref={refContainer}>
        <Activator
          className={className}
          isMenuShowing={isMenuShowing}
          disabled={disabled}
          colorVariant={colorVariant}
          invalid={error}
          ref={onRefActivator}>
          <Label dropdown short={short}>
            {labelString}
          </Label>
          {loading ? (
            <ClipLoader
              color={theme.colors.brand.textWhite}
              cssOverride={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                margin: 'auto',
                right: '1rem',
                width: '20px',
                height: '20px',
              }}
            />
          ) : (
            <Chevron
              color={theme.colors.text.dropdown[colorVariant][disabled ? 'disabled' : 'default']}
              isMenuShowing={isMenuShowing}
              small={short}
              type="chevronDown"
            />
          )}
          <ClickShrowd onClick={() => handleActivatorClick()} data-heap={heapCode} />
        </Activator>
        {!doHideSpringElement && (
          <Spring
            config={{ duration: 250 }}
            from={{ opacity: 0, height: '0px' }}
            onStart={() => setIsAnimating(true)}
            onRest={() => handleSpringRest()}
            to={
              isMenuShowing
                ? {
                  height: `${menuHeight}px`,
                  opacity: 1,
                }
                : {
                  height: '0px',
                  opacity: 0,
                }
            }>
            {props => (
              <List
                colorVariant={colorVariant}
                style={{
                  display: !isMenuShowing && doHideSpringElement ? 'none' : 'block',
                  ...props,
                }}>
                <DropdownSearchHeader
                  disabledDeselectAll={selectedQty === 0}
                  disabledSelectAll={selectedQty === options.length}
                  onFilterChange={e => handleFilterChange(e.target.value)}
                  onDeselectAll={() => handleSelection('none')}
                  onSelectAll={() => handleSelection('all')}
                  dark={light}
                  light={dark}
                />
                {categoryLabel ? <DropdownSectionHeader label={categoryLabel} dark={dark} light={light} /> : null}
                <ListItemWrapper
                  windowed={windowed}
                  short={short}
                  length={options.length}
                  hasCategory={!!categoryLabel}>
                  <FadeWrapper isAnimating={isAnimating}>
                    {windowed ? (
                      !isAnimating ? (
                        <FixedSizeList
                          height={200}
                          itemCount={filteredItems.length}
                          itemSize={48}
                          width={'100%'}
                          innerRef={onRefList}>
                          {({ index, style }) => (
                            <MultiCheckListItem
                              style={style}
                              label={filteredItems[index].label}
                              isChecked={includes(watch(name), filteredItems[index].value)}
                              onClick={() => handleClick(filteredItems[index].value)}
                            />
                          )}
                        </FixedSizeList>
                      ) : null
                    ) : (
                      filteredItems.map((option, i) => (
                        <div key={i}>
                          <MultiCheckListItem
                            label={option.label}
                            isChecked={includes(watch(name), option.value)}
                            onClick={() => handleClick(option.value)}
                          />
                        </div>
                      ))
                    )}
                  </FadeWrapper>
                </ListItemWrapper>
              </List>
            )}
          </Spring>
        )}
        <HiddenSelect multiple name={name} defaultValue={defaultValue} disabled={disabled} ref={ref}>
          {options.map((option, i) => (
            <option key={i} value={option.value}>
              {option.label}
            </option>
          ))}
        </HiddenSelect>
        {error && renderFieldError(error)}
      </Wrapper>
    );
  }
);

MultiCheckList.displayName = 'MultiCheckList';

export default withTheme(MultiCheckList);
