import React, { useState } from 'react';
import {
  Box,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Center,
  Spinner,
  Flex,
  Link,
} from '@chakra-ui/react';
import { PopupButton } from '@typeform/embed-react';
import { useCancelStripeSubscription } from 'data-client/use-membership';
import HEAP from '../../constants/HEAP.gen.json';
import MEMBERSHIP_DETAILS from '../../constants/MEMBERSHIP.gen.json';

const { MEMBERSHIP } = HEAP;
const { GRACE_PERIOD_DAYS } = MEMBERSHIP_DETAILS;

export function UpdateExistingPayment({ stripeSubscription, refetchUser, userName, userEmail }) {
  const [showCancelSection, setShowCancelSection] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { currentPeriodEnd, cancelAtPeriodEnd, subscriptionAmount, isCanceled, tier } = stripeSubscription;

  const gracePeriodDays = GRACE_PERIOD_DAYS[stripeSubscription.tier];

  const cancelStripeSubscriptionMutation = useCancelStripeSubscription();

  const handleSubscriptionUpdate = () => {
    cancelStripeSubscriptionMutation.mutate(
      {
        cancelAtPeriodEnd: !cancelAtPeriodEnd,
      },
      {
        onSuccess: () => {
          refetchUser();
          setShowCancelSection(false);
        },
      }
    );
    onClose();
  };

  const coreTier = stripeSubscription.tier === 'core';
  const isInTrial = stripeSubscription.isInTrial;

  if (cancelStripeSubscriptionMutation.isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const enableSubscriptionFlow = (
    <Box>
      <Text variant="modalHelp">
        {isCanceled
          ? `Re-enabling your subscription will charge your current credit card on file $${subscriptionAmount}
           and re-enable your membership. This will ensure your music is not taken down and that you continue
           to receive access to your Venice Membership benefits.`
          : `Re-enabling your subscription will remove the cancellation request and your membership will continue
           at the original price (renewing on ${currentPeriodEnd}).`}
      </Text>

      <Button
        mt="3rem"
        onClick={handleSubscriptionUpdate}
        variant={'wallet'}
        fontWeight="bold"
        width="50%"
        height="2.5rem"
        display="inline-block">
        Enable Membership
      </Button>
    </Box>
  );

  const cancellationFlow = (
    <>
      <Button onClick={() => setShowCancelSection(!showCancelSection)} variant="small">
        {isInTrial ? 'Cancel trial' : 'Cancel renewal'}
      </Button>

      {/* Cancellation of an existing plan */}
      {showCancelSection && !isInTrial ? (
        <Box mt="1.5rem">
          <Text>
            Your membership is set to expire on {currentPeriodEnd}. Following that date you will have {gracePeriodDays}{' '}
            days of takedown protection before your music is removed from all streaming services and social networks and
            you cease to have access to your membership benefits. If you would like to continue click “Cancel Renewal”
            below.
          </Text>
          <Button
            variant="light"
            onClick={onOpen}
            display="block"
            mt="3rem"
            fontSize="lg"
            color="red100"
            borderColor="red100"
            px="2rem"
            fontWeight="normal">
            {cancelAtPeriodEnd ? 'Enable membership' : 'Cancel renewal'}
          </Button>
        </Box>
      ) : null}

      {/* Cancellation of a trial */}
      {showCancelSection && isInTrial ? (
        <Box mt="1.5rem">
          <Text>
            Your trial is set to convert to a full membership on {currentPeriodEnd}. If you cancel, you will lose access
            to platform features such as Co-Manager, Events and Community.
          </Text>
          <Text mt="1rem">
            Once cancelled, your trial will expire on {currentPeriodEnd} with no refund and no further charges. If you
            would like to continue click “Cancel trial”.
          </Text>
          <Button
            variant="light"
            onClick={onOpen}
            display="block"
            mt="3rem"
            fontSize="lg"
            color="red100"
            borderColor="red100"
            px="2rem"
            fontWeight="normal">
            Cancel trial
          </Button>
        </Box>
      ) : null}

      <Modal isOpen={isOpen} onClose={onClose} trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm cancellation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {coreTier ? (
              <Text mb="1.5rem">
                Please confirm you understand that at the expiration of your current membership, your music will be
                taken down from all DSPs.
              </Text>
            ) : isInTrial ? (
              <Text mb="1.5rem">
                Please confirm you understand that cancelling your trial will result in the loss of access to all of the
                features of your membership at the end of your trial period. You will no longer have access to
                Co-Manager, Events, Sync Briefs and Community.
              </Text>
            ) : (
              <Text mb="1.5rem">
                Please confirm you understand that at the expiration of your current membership your music will be taken
                down from all DSPs. If you are interested instead in paying monthly, please submit a request{' '}
                <Link
                  color="pink"
                  target="_blank"
                  href={'https://venice-music.atlassian.net/servicedesk/customer/portal/1/group/1/create/2'}>
                  here.
                </Link>
              </Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Flex width="100%">
              <Button
                onClick={onClose}
                variant="light"
                width="50%"
                mr="0.5rem"
                borderWidth="1px"
                fontWeight="bold"
                color="black80"
                height="2.5rem"
                data-heap={MEMBERSHIP.SETTINGS.TYPE.SETTING_CANCEL_SUBSCRIPTION_NO}>
                {isInTrial ? 'Keep my benefits' : 'Keep my music up'}
              </Button>
              <PopupButton
                id={'l22NuR1I'}
                hidden={{
                  name: userName,
                  email: userEmail,
                  tier,
                  subscription_start_date:
                    stripeSubscription.subscriptionMembership?.stripe_subscription_record?.current_period_start ?? '-',
                  is_trial: isInTrial,
                }}
                style={{
                  fontWeight: 'bold',
                  width: '50%',
                  height: '2.5rem',
                  display: 'inline-block',
                  marginRight: '0.5rem',
                }}
                onSubmit={handleSubscriptionUpdate}>
                {isInTrial ? 'Cancel trial' : 'Cancel renewal'}
              </PopupButton>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );

  return cancelAtPeriodEnd ? enableSubscriptionFlow : cancellationFlow;
}
