import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const defaultListParams = {
  page_size: 10,
  page_index: 0,
};

const Search = () => {
  const search = (entityType, params) =>
    AuthAxios.get(`${DataApiUrl}/${entityType}/search`, {
      params: { ...defaultListParams, ...params },
    })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const list = (entityType, params) =>
    AuthAxios.get(`${DataApiUrl}/${entityType}`, {
      params: { ...defaultListParams, ...params },
    })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  return {
    search,
    list,
  };
};

export default Search;
