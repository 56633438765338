import React, { useContext, useEffect, useState } from 'react';
import { CardLoadingWrapper, CardContentWrapper, CardHeaderBlock } from './card-style';
import isEmpty from 'lodash/isEmpty';
import clone from 'lodash/clone';
import TrackListManager from './Tracks/track-list-manager';

import { ButtonCol } from 'styles';
import Button from 'components/Button/button';
import { FormProvider, useForm } from 'react-hook-form';
import { CardContext } from '../card-context';
import { Col, Row } from 'react-styled-flexboxgrid';
import { useGlobalData } from 'utils/global-data';
import useCardFlow from 'utils/use-card-flow';
import { useReleaseForm } from 'utils/use-release-form';
import Tooltip from 'components/Tooltip/tooltip';

import { CARDS } from '../card';
import { formatTrackData } from '../../../features/DraggableTrack/format-track-data';
import { useUploader } from '../../../utils/uploader';
import { API_STATUS_KEYS } from '../../../constants/constants';
import { useGetArtistsList, useGetContributorsList } from '../../../data-client/track-parties';

import { Alert, AlertIcon, AlertTitle, AlertDescription } from '@chakra-ui/react';

import * as API from 'utils/API/API';
import HEAP from '../../../constants/HEAP.gen.json';

const Release = API.release();

const TrackManager = () => {
  const CARD_ID = CARDS.Tracklist;
  const {
    id,
    getCardData,
    NAV_DIRECTIONS,
    afterSaveActions,
    loadingAny,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { release, dbUser } = useGlobalData();

  const { uploadFile, uploadStatus, uploadPercent, uploadStatusText } = useUploader();

  const { updateCard_Data, updateMultipleCards, isReviewMode, saveRelease, cardData, _passthrough } = useCardFlow();

  const { releaseTracks, patchTrack, updateTrackSequence, isSomeTrackExpanded, addNewReleaseTrack, createTrackStatus } =
    useReleaseForm();

  const [uploadingTrack, setUploadingTrack] = useState(false);

  const formMethods_Tracklist = useForm();

  //Data ultimately derives from tr
  const cardData_Tracklist = getCardData('tracklistData') || [];

  useEffect(() => {
    if (releaseTracks.length) {
      formMethods_Tracklist.clearErrors('minTracks');
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseTracks]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      save_Tracklist(true);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  const handleTrackReorder = (trackId, direction) => {
    // get this track's index
    let trackIndex = releaseTracks.findIndex(track => track.id === trackId);

    // calculate new index for this track
    let newIndex = trackIndex + direction;

    if (newIndex < 0 || newIndex > releaseTracks.length - 1) {
      return;
    }

    // reorder track array
    let reorderedTracks = releaseTracks.filter(track => track.id !== trackId);
    reorderedTracks.splice(newIndex, 0, releaseTracks[trackIndex]);

    updateTrackSequence(reorderedTracks);
  };

  const handleTracksChanged = tracks => {
    if (!(tracks || []).length) {
      formMethods_Tracklist.setError('minTracks', {
        type: 'validation',
        message: 'Please add at least one track',
      });
    }
  };

  const save_Tracklist = (forcePreview = false, navigation = null) => {
    // Validation
    if (!cardData_Tracklist.length && navigation !== NAV_DIRECTIONS.BACK) {
      formMethods_Tracklist.setError('minTracks', {
        type: 'validation',
        message: 'Please add at least one track',
      });

      return;
    } else if (cardData_Tracklist.length === 1) {
      updateMultipleCards({
        InstantGratTracks: {
          formData: {
            track_instant_grat_dates: [null],
          },
        },
      });
    }

    const showPreview = forcePreview || isReviewMode;
    afterSaveActions(showPreview, navigation);
  };

  const createNewTrackStub = () => {
    const length = cardData_Tracklist.length;
    const lastTrack = cardData_Tracklist[length - 1];
    const sequence = length > 0 ? lastTrack.sequence + 1 : 1;
    const display_artist_name = cardData?.[CARDS.ReleaseArtists]?.formData.artists[0];

    updateCard_Data(CARD_ID, {
      tracklistData: [
        ...cardData_Tracklist,
        {
          stubbed: true,
          expanded: true,
          sequence,
          display_artist_name,
          artists: cardData?.[CARDS.ReleaseArtists]?.formData.artists,
          user_group_id: dbUser.currentGroup.id,
        },
      ],
    });
  };

  const saveRefreshAndRefreshPage = async () => {
    await saveRelease();
    window.location.reload();
  };

  const handleFileUploads = async (data, trackId) => {
    const { primary_audio_file, immersive_audio_file, primary_audio_file_metadata, immersive_audio_file_metadata } =
      data;
    // can't Promise.all these because AWS wants a reduced request rate lest it 503

    //This is also used for video uploads.
    if (primary_audio_file) {
      const uploadPrimarySuccess = await uploadFile(
        'primary_audio_file',
        primary_audio_file,
        trackId,
        undefined,
        primary_audio_file_metadata
      );

      if (!uploadPrimarySuccess) {
        formMethods_Tracklist.setError('primary_audio_file', {
          message: 'Could not upload file, please contact support',
        });
        return false;
      }
    }

    if (immersive_audio_file) {
      const uploadImmersiveSuccess = await uploadFile(
        'immersive_audio_file',
        immersive_audio_file,
        trackId,
        undefined,
        immersive_audio_file_metadata
      );

      if (!uploadImmersiveSuccess) {
        formMethods_Tracklist.setError('immersive_audio_file', {
          message: 'Could not upload file, please contact support',
        });
        return false;
      }
    }

    return true;
  };

  const updateCardTracks = async (trackId, data) => {
    const success = await handleFileUploads(data, trackId);
    if (!success) {
      return false;
    }
    const idx = cardData_Tracklist.findIndex(t => t.id === trackId);
    const oldTrack = cardData_Tracklist[idx];

    const updatedTrack = {
      ...oldTrack,
      ...data,
    };

    const tracklistData = clone(cardData_Tracklist);
    tracklistData[idx] = updatedTrack;

    handleTracksChanged(tracklistData);
    updateCard_Data(CARD_ID, { tracklistData });
    await patchTrack(trackId, idx, updatedTrack);
    await saveRefreshAndRefreshPage();
    return true;
  };

  const removeNewTrackStub = (tracklist = []) => tracklist.filter(t => !t.stubbed);

  const saveNewTrack = async track => {
    const { newTrack, errorMessage = 'There was an error saving, please try again later.' } = await addNewReleaseTrack({
      ...track,
      user_group_id: release.user_group_id,
    });
    if (!newTrack) {
      formMethods_Tracklist.setError('onSubmit', { message: errorMessage });
      return false;
    } else {
      const success = await handleFileUploads(track, newTrack.id);
      if (success) {
        handleRemoveNewTrackStub(newTrack);
        await saveRefreshAndRefreshPage();
        return newTrack;
      }
      return false;
    }
  };

  const handleRemoveNewTrackStub = (...newTracks) => {
    updateCard_Data(CARD_ID, {
      tracklistData: [...removeNewTrackStub(cardData_Tracklist), ...newTracks],
    });
  };

  const onSave = track => async data => {
    setUploadingTrack(true);
    const result = track.id ? await updateCardTracks(track.id, data) : await saveNewTrack(data);
    setUploadingTrack(false);
    return result;
  };

  //Runs queries that we will cache to allow components lower in the tree to use.
  //If we don't have these then there is considerable popin (if using the loading props)
  // or incorrect selections in components when the queries finally do complete.
  // queries being cached are in
  // public-browser/src/views/ManageRelease/Card/Tracks/track-form.jsx
  // public-browser/src/views/ManageRelease/Card/Tracks/track-list-manager.jsx
  // In time this component tree should be reduced substantially.
  const { isFetching: isFetchingArtists } = useGetArtistsList(release);
  const { isFetching: isFetchingContributors } = useGetContributorsList(release);

  const waitingForPageData =
    uploadingTrack ||
    loadingAny ||
    isFetchingArtists ||
    isFetchingContributors ||
    createTrackStatus === API_STATUS_KEYS.IN_PROGRESS;

  return (
    <CardContentWrapper>
      <CardLoadingWrapper loading={waitingForPageData ? 'true' : 'false'}>
        <FormProvider {...formMethods_Tracklist}>
          <CardHeaderBlock omnishareas>
            <Row>
              <Col xs={6}>
                <h3>{CARDS.Tracklist}</h3>
              </Col>
              <ButtonCol xs={6}>
                {!isEmpty(formMethods_Tracklist.errors) && (
                  <Alert status="error" variant="solid">
                    <AlertIcon />
                    <AlertTitle mr={2}>Error:</AlertTitle>
                    <AlertDescription>
                      {formMethods_Tracklist.errors.minTracks && formMethods_Tracklist.errors.minTracks.message}
                      {formMethods_Tracklist.errors.onSubmit && formMethods_Tracklist.errors.onSubmit.message}
                      {formMethods_Tracklist.errors.immersive_audio_file &&
                        formMethods_Tracklist.errors.immersive_audio_file.message}
                      {formMethods_Tracklist.errors.primary_audio_file &&
                        formMethods_Tracklist.errors.primary_audio_file.message}
                      {Object.keys(formMethods_Tracklist.errors).length
                        ? Object.entries(formMethods_Tracklist.errors).map(entry => entry.message)
                        : ''}
                    </AlertDescription>
                  </Alert>
                )}
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={() => save_Tracklist(false, NAV_DIRECTIONS.BACK)}
                    disabled={
                      loadingSaveRelease ||
                      loadingAny ||
                      isFlowAnimating ||
                      isSomeTrackExpanded ||
                      uploadStatus === API_STATUS_KEYS.IN_PROGRESS
                    }
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TRACKLIST_BUTTON_BACK}
                  />
                )}
                <Tooltip text={isSomeTrackExpanded ? 'Save or close tracks before proceeding' : null} position="left">
                  <Button
                    rightIcon={isReviewMode ? null : 'chevronRight'}
                    text={isReviewMode ? 'Save & Preview' : 'Next'}
                    onClick={() => save_Tracklist(false, NAV_DIRECTIONS.FORWARD)}
                    loading={loadingSaveRelease || loadingAny}
                    disabled={isFlowAnimating || isSomeTrackExpanded || uploadStatus === API_STATUS_KEYS.IN_PROGRESS}
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TRACKLIST_BUTTON_NEXT}
                  />
                </Tooltip>
              </ButtonCol>
            </Row>
          </CardHeaderBlock>
          <Row>
            <Col xs={12}>
              <div omnishareas={`Release.${release?.id}.Tracklist.Sidebar`}>
                <TrackListManager
                  tracks={cardData_Tracklist.map((t, i) =>
                    formatTrackData(
                      {
                        ...t,
                        trackId: t.id,
                      },
                      i + 1
                    )
                  )}
                  onNewTrack={createNewTrackStub}
                  loading={loadingAny || uploadStatus === API_STATUS_KEYS.IN_PROGRESS}
                  handleTrackReorder={handleTrackReorder}
                  handleTracksChanged={handleTracksChanged}
                  handleRemoveNewTrackStub={handleRemoveNewTrackStub}
                  updateDataStore={onSave}
                  hasBeenReleased={release?.hasBeenDelivered}
                />
              </div>
            </Col>
          </Row>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner
        loading={waitingForPageData}
        percent={uploadPercent}
        text={
          uploadingTrack || createTrackStatus === API_STATUS_KEYS.IN_PROGRESS ? uploadStatusText : 'Validating Data'
        }
      />
    </CardContentWrapper>
  );
};

export default TrackManager;
