import { useQuery } from 'react-query';
import * as API from 'utils/API/API';

const Events = API.events();

export const useGetMembershipEvents = params => {
  return useQuery(['membership-events', params], () => {
    return Events.listEvents(params);
  });
};
