import numeral from 'numeral';

const initNumeral = () =>
  numeral.register('format', 'million', {
    regexps: {
      format: /(M)/,
      unformat: /(M)/,
    },
    format: function (value, format, roundingFunction) {
      let space = numeral._.includes(format, ' M') ? ' ' : '',
        output;

      // eslint-disable-next-line
      format = format.replace(/\s?\M/, '')

      let suffix = value >= 1000000000 ? 'B' : value >= 1000000 ? 'M' : value >= 1000 ? 'K' : '';
      value =
        value >= 1000000000
          ? value / 1000000000
          : value >= 1000000
          ? value / 1000000
          : value >= 1000
          ? value / 1000
          : value;

      output = numeral._.numberToFormat(value, format, roundingFunction);

      if (numeral._.includes(output, ')')) {
        output = output.split('');

        output.splice(-1, 0, space + suffix);

        output = output.join('');
      } else {
        output = output + space + suffix;
      }

      return output;
    },
    unformat: function (string) {
      return numeral._.stringToNumber(string) * 0.01;
    },
  });

export default initNumeral;
