import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Spring } from 'react-spring/renderprops';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import { renderFieldError } from 'utils/form';
import { Label } from '../styles';
import Calendar from 'features/Calendar/calendar';
import Icon from 'components/Icon/icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: flex-start;
  position: relative;

  &:focus {
    background: red;
  }
`;

const Activator = styled.button`
  margin: 0;
  padding: 0 0.75rem;
  border: 1px solid ${props => (props.colorVariant === 'dark' ? 'transparent' : props.theme.colors.black80)};
  align-items: center;
  background: ${props => props.theme.colors.background.dropdownList[props.colorVariant].default};
  border-radius: 0.25rem 0.25rem 0 0;
  color: ${props => props.theme.colors.text.dropdown[props.colorVariant].default};
  cursor: pointer;
  display: flex;
  font-weight: ${props => props.theme.fonts.weights.regular};
  overflow: hidden;
  transition: background 250ms ease, border-radius 250ms ease;
  position: relative;

  &:hover {
    ${props =>
    !props.isMenuShowing &&
      `
			background: ${props.theme.colors.background.dropdown[props.colorVariant].hover};
		`}
  }

  ${props =>
    props.isMenuShowing &&
    `
		color: ${props.theme.colors.text.dropdown[props.colorVariant].active};
	`}

  ${props =>
    !props.isMenuShowing &&
    `
			border-radius: 0.25rem;
	`}

	${props =>
    props.disabled &&
    `
		background: ${props.theme.colors.background.dropdown[props.colorVariant].disabled};
		color: ${props.theme.colors.text.dropdown[props.colorVariant].disabled};
		pointer-events: none;
	`}

  ${props =>
    props.invalid &&
    `
    background: ${props.theme.colors.background.dropdown[props.colorVariant].error};
    border: 1px solid ${props.theme.colors.border.dropdown[props.colorVariant].error};
    color: ${props.theme.colors.text.dropdown[props.colorVariant].error};
  `}

	${props =>
    props.isSelected &&
    `
		font-weight: ${props.theme.fonts.weights.medium};
	`}
`;

const ClickShrowd = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
`;

const CalendarIcon = styled(Icon)`
  min-height: 1.5rem;
  margin-right: 0.5rem;
  min-width: 1.5rem;
`;

const DateLabel = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.xsm};
  font-weight: ${props => props.theme.fonts.weights.bold};
  line-height: 18px;
`;

const DateLabelLight = styled(Label)`
  font-size: ${props => props.theme.fonts.sizes.reg};
  font-weight: ${props => props.theme.fonts.weights.regular};
  color: ${props => props.theme.colors.black50};
  line-height: 18px;
  width: auto;
  padding: 0.625rem 0.25rem;
`;

const DropdownWrapper = styled.div`
  background: ${props => props.theme.colors.background.dropdownList[props.colorVariant].default};
  border-radius: 0.5rem;
  padding: 1.5rem 1rem;
  margin: 0;
  position: absolute;
  z-index: 10;

  ${props =>
    !props.floatTop &&
    `
		top: calc(100% + 0.5rem);
  	//border-radius: ${props.floatRight ? '0.5rem' : '0'} ${!props.floatRight ? '0.5rem' : '0'} 0.5rem 0.5rem;
		box-shadow: 0px 5px 10px ${props.theme.colors.brand.layer0};
	`}

  ${props =>
    props.floatTop &&
    `
		bottom: calc(100% + 0.5rem);
  	//border-radius: 0.5rem 0.5rem ${!props.floatRight ? '0.5rem' : '0'} ${props.floatRight ? '0.5rem' : '0'};
		box-shadow: 0px -5px 10px ${props.theme.colors.brand.layer0};
	`}

	${props =>
    props.floatRight &&
    `
		right: 0;
	`}
`;

const DatePicker = React.forwardRef(
  (
    {
      dark = false,
      defaultSelectedDate,
      disabled,
      error,
      floatRight,
      floatTop,
      light,
      maxDate,
      minDate,
      name,
      placeholder,
      setValue,
      clearErrors,
      style,
      theme,
      className,
    },
    ref
  ) => {
    const colorVariant = dark ? 'dark' : light ? 'light' : 'dark';

    const [isMenuShowing, setIsMenuShowing] = useState(false);
    const [doHideSpringElement, setDoHideSpringElement] = useState(true);
    const [refActivator, setRefActivator] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const refContainer = useRef(null);

    useEffect(() => setSelectedDate(defaultSelectedDate), [defaultSelectedDate]);

    // Spring elements mount and unmount

    useEffect(() => {
      if (isMenuShowing) {
        setDoHideSpringElement(false);
      }
    }, [isMenuShowing]);

    const handleSpringRest = () => {
      if (!isMenuShowing) setDoHideSpringElement(true);
    };

    // Global document click handler

    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);

      return () => document.removeEventListener('click', handleDocumentClick);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDocumentClick = e => {
      if (e.target !== refContainer.current && !refContainer.current.contains(e.target)) {
        setIsMenuShowing(false);
      }
    };

    const handleCalendarSelect = date => {
      setSelectedDate(date);
      setIsMenuShowing(false);
    };

    // Calculate dropdown menu height

    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRefActivator = useCallback(node => {
      if (node && !node.isEqualNode(refActivator)) {
        setRefActivator(node);
      }
    });

    // Event Listeners
    const handleActivatorClick = () => {
      setIsMenuShowing(!isMenuShowing);
    };

    return (
      <Wrapper ref={refContainer} style={style}>
        <Activator
          className={className}
          isMenuShowing={isMenuShowing}
          disabled={disabled}
          colorVariant={colorVariant}
          tabIndex={0}
          invalid={error}
          ref={onRefActivator}
          type="button">
          {colorVariant !== 'dark' && (
            <CalendarIcon
              type={colorVariant === 'dark' ? 'calendar' : 'calendarFill'}
              isMenuShowing={isMenuShowing}
              color={theme.colors.text.dropdown[colorVariant][disabled ? 'disabled' : 'default']}
            />
          )}
          {colorVariant !== 'dark' && (
            <DateLabelLight dropdown>{!selectedDate ? placeholder : selectedDate}</DateLabelLight>
          )}
          {colorVariant === 'dark' && <DateLabel dropdown>{!selectedDate ? placeholder : selectedDate}</DateLabel>}
          {colorVariant === 'dark' && (
            <CalendarIcon
              type={colorVariant === 'dark' ? 'calendar' : 'calendarFill'}
              isMenuShowing={isMenuShowing}
              color={theme.colors.text.dropdown[colorVariant][disabled ? 'disabled' : 'default']}
            />
          )}
          <ClickShrowd onClick={!disabled ? () => handleActivatorClick() : () => null} />
        </Activator>
        <Spring
          from={{ opacity: 0, maxHeight: '0px' }}
          to={
            isMenuShowing
              ? {
                maxHeight: `1000px`,
                opacity: 1,
              }
              : {
                maxHeight: '0px',
                opacity: 0,
              }
          }
          onRest={() => handleSpringRest()}>
          {props => (
            <DropdownWrapper
              colorVariant={colorVariant}
              style={{
                display: !isMenuShowing && doHideSpringElement ? 'none' : 'block',
                ...props,
              }}
              floatRight={floatRight}
              floatTop={floatTop}
              isMenuShowing={isMenuShowing}>
              <Calendar
                name={name}
                setValue={setValue}
                clearErrors={clearErrors}
                defaultSelectedDate={defaultSelectedDate}
                onChange={date => handleCalendarSelect(date)}
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                uiTheme={colorVariant}
                ref={ref}
              />
            </DropdownWrapper>
          )}
        </Spring>
        {error && renderFieldError(error)}
      </Wrapper>
    );
  }
);

DatePicker.displayName = 'DatePicker';

DatePicker.defaultProps = {
  disabled: false,
  dark: false,
  floatRight: false,
  floatTop: false,
  light: false,
  placeholder: 'Choose date',
};

export default withTheme(DatePicker);
