import React, { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import FieldLabel from '../../../../components/FieldLabel/field-label';
import TextInput from '../../../../features/TextInput/text-input';
import { validateTitle } from 'views/ManageRelease/manage-release-functions';

export const InputTrackTitle = ({ disabled, register, heapCode }) => {
  const toast = useToast();
  const [error, setError] = useState(null);

  const handleOnBlur = () => {
    toast.closeAll();
  };

  const handleOnFocus = () => {
    if (error) {
      toast({
        status: 'error',
        title: 'Track Title Error',
        id: error,
        description: error,
        duration: null,
      });
    }
  };

  return (
    <>
      <FieldLabel text="Track Title" required />
      <TextInput
        name="title"
        disabled={disabled}
        invalid={!!error}
        placeholder="Enter title exactly as you want it shown on DSPs"
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        ref={register({
          required: false,
          validate: title => {
            const { isValid, errorMessage } = validateTitle(title);
            setError(errorMessage);

            if (!isValid && !toast.isActive(errorMessage)) {
              toast({
                status: 'error',
                title: 'Track Title Error',
                id: errorMessage,
                description: errorMessage,
                duration: null,
              });
            } else if (isValid) {
              setError(null);
              toast.closeAll();
            }

            return isValid;
          },
        })}
        heapCode={heapCode}
      />
    </>
  );
};
