//TECH DEBT Deprecated. Use Labeled Input or Labeled TextArea
import React from 'react';
import styled from '@emotion/styled/macro';
import Icon from '../Icon/icon';
import { renderFieldError } from '../../utils/form';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const calculateVariant = (props, defaultStyle, darkStyle) => {
  const variant = props?.variant;
  return variant === 'dark' ? darkStyle : defaultStyle;
};

const Input = styled.input`
  background-color: ${props => calculateVariant(props, props.theme.colors.textWhite, props.theme.colors.black80)};
  border: 1px solid ${props => calculateVariant(props, props.theme.colors.black100, props.theme.colors.black80)};
  border-color: ${props => calculateVariant(props, props.theme.colors.black100, props.theme.colors.black80)};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props =>
      props.variant !== 'dark'
        ? props.theme.colors.black50
        : calculateVariant(props, 'inherit', props.theme.colors.black60)};
  }
  :-ms-input-placeholder {
    color: ${props =>
      props.variant !== 'dark'
        ? props.theme.colors.black60
        : calculateVariant(props, 'inherit', props.theme.colors.black60)};
  }
  border-radius: 0.25rem;
  box-sizing: border-box;
  color: ${props =>
    calculateVariant(
      props,
      props.disabled ? props.theme.colors.disabled : props.variant !== 'dark' ? 'black' : 'inherit',
      props.theme.colors.cream100
    )};
  font-size: ${props => props.theme.fonts.sizes.reg};
  height: 2.75rem;
  padding: 0.75rem;
  width: 100%;

  ${props =>
    props.icon &&
    `
    padding-left: 1.6rem;
  `}

  ${props =>
    props.invalid &&
    `
    border-color: ${props.theme.colors.negative};;
    outline-color: ${props.theme.colors.negative};;
  `}

  ${props =>
    props.outline &&
    `
    background-color: transparent;
    border: 1px solid ${props.theme.colors.inputBackground};
  `}

  ${props =>
    props.prePopulated &&
    `
    background-color: ${props.theme.colors.prePopulated}10;
    border-color: ${props.theme.colors.prePopulated}50;
  `}

  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    background-color: transparent;
    border-color: ${props.theme.colors.brand.layer2};
    color: ${props.theme.colors.brand.layer4};
  `}

  ${props =>
    props.themeVariant === 'light' &&
    `
    background-color: ${props.theme.colors.brand.layer3};
    color: ${props.theme.colors.brand.textWhite};


    ${
      props.disabled &&
      `
      background-color: transparent;
      color: ${props.theme.colors.brand.layer3};
      border-color: ${props.theme.colors.brand.layer3};
    `
    }
  `}
`;

const IconWrapper = styled(Icon)`
  margin-left: 9px;
  margin-top: 13px;
  position: absolute;
  width: 0.8rem;
`;

const TextField = React.forwardRef(
  (
    {
      name,
      className,
      icon,
      type = 'text',
      onKeyPress,
      onKeyUp,
      prePopulated,
      error,
      doShowError,
      autoFocus,
      autoComplete,
      ...props
    },
    ref
  ) => {
    const variant = props?.variant;

    return (
      <Container>
        <Input
          key={`input-${name}`}
          className={className}
          icon={icon}
          name={name}
          invalid={error}
          type={type}
          onKeyPress={onKeyPress ? e => onKeyPress(e, name) : null}
          onKeyUp={onKeyUp ? e => onKeyUp(e) : null}
          prePopulated={prePopulated}
          autoFocus={autoFocus}
          themeVariant={props.themeVariant}
          ref={ref}
          autoComplete={autoComplete || 'on'}
          variant={variant}
          {...props}
        />
        {icon && <IconWrapper type={icon} />}
        {doShowError && error && renderFieldError(error)}
      </Container>
    );
  }
);

TextField.defaultProps = {
  type: 'default',
  icon: null,
  placeholder: '',
  disabled: false,
  isValid: true,
  name: 'input',
  doShowError: true,
};

export default TextField;
