import React from 'react';
import ViewWrapper from 'views/ViewWrapper/view-wrapper';
import { Box, Heading, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import MarketingDiscoveryModeDetails from './marketing-discovery-mode-details';
import MarketingDiscoveryModeLive from './marketing-discovery-mode-live';
import MarketingDiscoveryModeHistory from './marketing-discovery-mode-history';
import MarketingDiscoveryModeDraft from './marketing-discovery-mode-draft';

const CustomTab = ({ children }) => {
  return (
    <Tab
      color="black100"
      borderTopRadius={'0.5rem'}
      whiteSpace="nowrap"
      paddingX={{ base: '6', sm: '8' }}
      paddingY={{ base: '3', sm: '5' }}
      justifyContent="flex-start"
      _selected={{ bg: 'black10', fontWeight: 'bold' }}>
      {children}
    </Tab>
  );
};

export default function MarketingDiscoveryMode({ ...props }) {
  const { userGroupId } = props;
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <ViewWrapper designLayer={0} disableHorizontalScroll={true} {...props}>
      <Box>
        <Heading mb="2rem">Spotify Discovery Mode</Heading>
        <Box>
          <Tabs variant="unstyled" index={tabIndex} onChange={index => setTabIndex(index)}>
            <TabList>
              <CustomTab>Draft Campaign</CustomTab>
              <CustomTab>Live</CustomTab>
              <CustomTab>History</CustomTab>
            </TabList>
            <TabPanels>
              <TabPanel p="5" backgroundColor="black10" borderBottomRadius={'1rem'} borderTopRightRadius={'1rem'}>
                <MarketingDiscoveryModeDraft userGroupId={userGroupId} />
              </TabPanel>
              <TabPanel p="5" backgroundColor="black10" borderBottomRadius={'1rem'} borderTopRadius={'1rem'}>
                <MarketingDiscoveryModeLive userGroupId={userGroupId} />
              </TabPanel>
              <TabPanel p="5" backgroundColor="black10" borderBottomRadius={'1rem'} borderTopRadius={'1rem'}>
                <MarketingDiscoveryModeHistory userGroupId={userGroupId} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <MarketingDiscoveryModeDetails isHistoryPage={tabIndex > 1} />
      </Box>
    </ViewWrapper>
  );
}
