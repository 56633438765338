import * as React from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useTheme,
  useToast,
  Heading,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient, useMutation } from 'react-query';
import * as API from 'utils/API/API';
import ArtistForm from './artist-form';
import { useGlobalData } from 'utils/global-data';
import { ClipLoader } from 'react-spinners';
import { PersistentWell } from '../components/Well/well';

const Party = API.party();

const clipLoader = <ClipLoader cssOverride={{ width: '0.75rem', height: '0.75rem', margin: '0 1.5rem' }} />;

export function useArtistModal({ useReleaseGroupId = false, onCreateArtist }) {
  const disclosure = useDisclosure();
  const Component = ({ ...props }) => (
    <AddArtistModal
      disclosure={disclosure}
      useReleaseGroupId={useReleaseGroupId}
      onCreateArtist={onCreateArtist}
      {...props}
    />
  );
  return [Component, disclosure];
}

export default function AddArtistModal({ disclosure, useReleaseGroupId, onCreateArtist, ...props }) {
  const { release, selectedUserGroup } = useGlobalData();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const toast = useToast();
  const [confirmStep, setConfirmStep] = React.useState(false);
  const [confirmValues, setConfirmValues] = React.useState({});

  const variant = props?.variant || 'dark';

  const onSubmitArtistForm = data => {
    setConfirmValues({ ...data });
    setConfirmStep(true);
  };

  const inviteArtistMutation = useMutation(
    args =>
      Party.create({
        full_name: args.full_name,
        platform_id: {
          spotify: args.spotify,
          apple: args.apple,
          soundcloud: args.soundcloud,
          vevo: args.vevo,
        },
        type: 'artist',
        user_group_id: useReleaseGroupId ? release?.user_group_id : selectedUserGroup.id,
      }),
    {
      onSuccess: async data => {
        onCreateArtist && onCreateArtist(data);
      },
      onError: () =>
        toast({
          title: `Something went wrong adding the artist`,
          status: 'error',
        }),
    }
  );

  const modalButtonText = confirmStep ? 'Confirm' : 'Create artist';

  return (
    <Modal variant={variant} isOpen={disclosure?.isOpen} onClose={disclosure?.onClose} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{confirmStep ? 'Confirm' : 'Create'} new artist</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {confirmStep ? (
            <Box
              key="confirmStep"
              as="form"
              id="artist"
              onSubmit={async ev => {
                ev.preventDefault();

                // fire off mutation if validation succeeded
                await inviteArtistMutation.mutateAsync({
                  full_name: confirmValues.artistName,
                  spotify: confirmValues.spotifyId,
                  apple: confirmValues.appleId,
                  soundcloud: confirmValues.soundcloudId,
                  vevo: confirmValues.vevoChannelName,
                });
                disclosure.onClose();
                await queryClient.invalidateQueries(['artists']);
                setConfirmStep(false);
                setConfirmValues({});
              }}
              color={variant === 'dark' ? 'cream.200' : 'black100'}
              mt="4">
              <Box fontSize="sm" textTransform="uppercase">
                Artist Name
              </Box>
              <Box fontSize="md" mb="6">
                {confirmValues.artistName}
              </Box>
              <Heading as={'h4'} fontSize="lg" mb="4">
                DSP IDs
              </Heading>
              <Box fontSize="sm" textTransform="uppercase">
                Apple Music ID
              </Box>
              <Box fontSize="md" mb="6">
                {confirmValues.appleId || '(Creating new ID)'}
              </Box>
              <Box fontSize="sm" textTransform="uppercase">
                Spotify URI
              </Box>
              <Box fontSize="md" mb="6">
                {confirmValues.spotifyId || '(Creating new ID)'}
              </Box>
              <Box fontSize="sm" textTransform="uppercase">
                Vevo Channel Name
              </Box>
              <Box fontSize="md" mb="6">
                {confirmValues.vevoChannelName || '(Creating new channel)'}
              </Box>
              <Box fontSize="sm" textTransform="uppercase">
                Soundcloud URI
              </Box>
              <Box fontSize="md" mb="6">
                {confirmValues.soundcloudId || '-'}
              </Box>
            </Box>
          ) : null}
          <ArtistForm variant={variant} onSubmit={onSubmitArtistForm} display={confirmStep ? 'none' : 'block'} />
          {confirmStep && (
            <PersistentWell styleProps={{ bg: 'pink', color: 'white' }}>
              <h2 style={{ color: 'white', paddingBottom: '1rem' }}>Already distributed music under this name?</h2>
              Please note that If you have already distributed music under this artist name AND have selected to create
              a new id, your music will not display under your correct artist profile. You can edit the artist profile
              to add existing IDs later in Settings -{'>'} Artists.
            </PersistentWell>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant={variant === 'light' ? 'small' : null}
            onClick={confirmStep ? () => setConfirmStep(false) : disclosure?.onClose}
            style={{
              marginRight: theme.space[3],
              backgroundColor: theme.colors.transparent,
              borderColor: variant === 'dark' ? theme.colors.transparent : null,
            }}>
            {confirmStep ? 'Back' : 'Cancel'}
          </Button>
          <Button
            variant={variant === 'light' ? 'smallDark' : null}
            disabled={inviteArtistMutation.isLoading}
            type="submit"
            form="artist"
            testCode="submit-artist">
            {inviteArtistMutation.isLoading ? clipLoader : modalButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
