import React from 'react';
import { Box, Stack } from '@chakra-ui/react';
import DspInfoGroup from './dsp-info-group';
import { ParentSize } from '@visx/responsive';
import PieChart from './pie-chart';
import GlanceCard from './glance-card';
import { useAnalyticsContext } from './analytics-context';
import HEAP from '../constants/HEAP.gen.json';

function DspPieChart({ domainData }) {
  const { dateRangeString, decorativeText, rollups } = useAnalyticsContext();

  const pieChartData = rollups?.dsps ?? [];
  return (
    <GlanceCard
      status={domainData.status}
      dateRangeString={dateRangeString}
      decorativeText={decorativeText}
      title="Top DSPs"
      data-heap={HEAP.PUBLIC.ANALYTICS.TYPE.ANALYTICS_CARD_DSP_PIE_CHART}>
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <Stack spacing={2}>
          {pieChartData.map(dsp => (
            <DspInfoGroup key={dsp.dspName} dspName={dsp.dspName} data={dsp.streams} />
          ))}
        </Stack>
        <ParentSize style={{ display: 'flex', justifyContent: 'center' }}>
          {({ width, height }) => <PieChart data={pieChartData} width={width} height={height} />}
        </ParentSize>
      </Box>
    </GlanceCard>
  );
}

export default DspPieChart;
