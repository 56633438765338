import React from 'react';
import { components } from 'react-select';
import styled from '@emotion/styled';

const FullWidthActionButton = styled.button`
  color: ${props => props.theme.colors.black100};
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const WhiteTextSpan = styled.span`
  color: white;
  padding: 0 0.5rem;
  font-size: 1.25rem;
  font-weight: 400;
`;

const MenuListActionButton = ({ onRowActionButtonClick, rowActionButtonText, ...props }) => {
  return (
    <components.MenuList {...props}>
      <FullWidthActionButton onClick={onRowActionButtonClick}>
        <WhiteTextSpan>+</WhiteTextSpan> {rowActionButtonText}
      </FullWidthActionButton>
      {props.children}
    </components.MenuList>
  );
};

export default MenuListActionButton;
