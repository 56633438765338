import React, { useRef } from 'react';
import { useTheme, FormLabel, Box } from '@chakra-ui/react';
import DatePicker from '../DatePicker/date-picker';

function LabeledDatePicker({ onValueChange, error, name, value, labelText, maxDate, minDate, ...styleProps }) {
  const theme = useTheme();
  const timePickerRef = useRef(null);
  const { label: labelStyle } = theme.components.LabeledInput.baseStyle;

  return (
    <Box {...styleProps} w="10rem">
      <FormLabel {...labelStyle}>{labelText}</FormLabel>
      <DatePicker
        light={true}
        defaultSelectedDate={value}
        setValue={onValueChange}
        name={name}
        ref={timePickerRef}
        uiTheme={'light'}
        placeholder={'00-00-0000'}
        error={error}
        maxDate={maxDate}
        minDate={minDate}
        value={value}
      />
    </Box>
  );
}

export default LabeledDatePicker;
