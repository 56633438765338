import React from 'react';
import { Box, Button, Heading, useTheme } from '@chakra-ui/react';
import { FiArrowLeft as ArrowLeft } from 'react-icons/fi';
import NextActionBox from 'components/next-action-box/next-action-box';
import { useHistory } from 'react-router-dom';

export default function MarketingSelector() {
  const history = useHistory();
  const theme = useTheme();
  localStorage.setItem('marketingLinkClicked', true); //this removed the "new" tag.

  return (
    <Box width={'100%'} display={'flex'} justifyContent={'center'}>
      <Box width="100%" maxWidth={'37.5rem'}>
        <Box pt="2rem" pb="1rem">
          <Button variant="transparent" leftIcon={<ArrowLeft />} onClick={() => history.goBack?.()}>
            Back
          </Button>
        </Box>
        <Heading as="h4" color={theme.colors.black80} fontWeight="bold" fontSize="2rem">
          Marketing
        </Heading>
        <Heading as="h5" color={theme.colors.black60} fontWeight="normal" fontSize="0.875rem" mt="0.5rem" mb="3rem">
          Select the option to continue
        </Heading>
        <NextActionBox
          title={'Marketing Links'}
          text={'Manage your marketing links'}
          onClick={() => history.push('/marketing-links')}
          iconColor="black50"
        />
        <NextActionBox
          title={'Spotify Discovery Mode'}
          text={'Opt-in / opt-out eligible tracks into Spotify Discovery Mode'}
          onClick={() => history.push('/marketing-discovery-mode')}
          iconColor="black50"
        />
      </Box>
    </Box>
  );
}
