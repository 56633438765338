const { getPayeeName } = require('../../../utils/helpers');

const checkPayeeIsLabel = (payeeId, groupPayees) => {
  const selectedPayee = groupPayees?.find(payee => payee.id === parseInt(payeeId)) || {};
  return !!selectedPayee.payment_account?.group;
};

const formatContributors = contributors =>
  !contributors
    ? []
    : contributors.map(contributor => ({
        ...contributor,
        value: contributor?.id,
        label: contributor?.full_name,
      }));

const formatPayees = groupPayees =>
  !groupPayees
    ? []
    : groupPayees.reduce((acc, payee) => {
        acc.push({
          ...payee,
          value: payee.id,
          label: getPayeeName(payee),
          isLabel: !!payee.payment_account?.group,
        });
        return acc;
      }, []);

export { checkPayeeIsLabel, formatContributors, formatPayees };
