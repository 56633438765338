import React from 'react';
import { Button, SimpleGrid, Link as ChakraLink, Spacer, Heading, useDisclosure } from '@chakra-ui/react';
import { RiArrowRightLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import ViewWrapper from '../ViewWrapper/view-wrapper';
import ComanagerView from '../../features/Co-manager/co-manager-view';
import useUser from 'data-client/use-current-user.jsx';
import ManageContentBox, { ContentHeader, Title } from '../../components/ManageContentBox/manage-content-box';
import MembershipEventsView from '../../features/MembershipEvents/membership-events-view';
import ContentBox from '../../components/Box/Box';
import TerminatedAgreementModal from '../../components/SearchResults/terminated-agreement-modal';

import { useUserTierChecks } from '../../hooks/authorization-hooks';

import box1Photo from '../../assets/photos/8cb1f1561023117a904dff655d132328.jpg';
import box2Photo from '../../assets/photos/ccaa140cd91b5d345a093eec154ca674.jpg';

const Home = props => {
  const [user] = useUser();
  const { canAccessEvents, canAccessCoManager, isExecutiveTier, isActive } = useUserTierChecks();
  const terminatedAgreementModal = useDisclosure();
  const history = useHistory();

  function handleCreateReleaseButton() {
    // Show agreement modal to executive tier without active service deals
    if (isExecutiveTier && !user?.serviceDeal) {
      terminatedAgreementModal.onOpen();
      return;
    }
    history.push('/manage');
  }

  return (
    <ViewWrapper designLayer={0} navbarDesignLayer={5} solidFooter {...props} pb="4.5rem" backgroundColor="cream50">
      <ContentBox borderBottom="1px solid" borderColor="#D2CFCA" paddingInline="2rem" paddingBlock="1rem">
        <Heading as="h2" fontWeight="400" fontFamily="headings" color="black80">
          Welcome back, {user?.name ?? 'Venice Friend'}
        </Heading>
      </ContentBox>

      <SimpleGrid mt="2rem" columns={{ xs: 1, sm: 2 }} spacing="1.5rem" width={'100%'}>
        <ManageContentBox
          height="291px"
          display="flex"
          flexDirection="column"
          alignItems="start"
          paddingBlock="2rem"
          borderRadius="1.2rem"
          border="none"
          backgroundImage={`url(${box1Photo})`}
          backgroundSize="cover"
          backgroundPosition="right"
          backgroundRepeat="no-repeat">
          <Spacer />

          <ContentHeader>
            <Title fontSize="1rem" textColor="pink" fontFamily="headings">
              Release
            </Title>
          </ContentHeader>

          <ContentBox fontWeight="400" marginBlock="1rem" textColor="white" fontSize="1.25rem" fontFamily="headings">
            Ready to release? Time to get your next drop ready.
          </ContentBox>

          <Button
            fontSize="1rem"
            leftIcon={<RiArrowRightLine />}
            disabled={!user?.subscription?.isActive}
            pointerEvents={!user?.subscription?.isActive ? 'none' : ''}
            onClick={handleCreateReleaseButton}
            variant="pink">
            Create a new release
          </Button>
        </ManageContentBox>

        <ManageContentBox
          height="291px"
          display="flex"
          flexDirection="column"
          alignItems="start"
          paddingBlock="2rem"
          borderRadius="1.2rem"
          border="none"
          backgroundImage={`url(${box2Photo})`}
          backgroundSize="cover"
          backgroundPosition="top"
          backgroundRepeat="no-repeat">
          <Spacer />

          <ContentHeader>
            <Title fontSize="1rem" textColor="pink" fontFamily="headings">
              Join Sync
            </Title>
          </ContentHeader>

          <ContentBox fontWeight="400" marginBlock="1rem" textColor="white" fontSize="1.25rem" fontFamily="headings">
            RSVP to our next feedback session to get feedback from our Sync team.
          </ContentBox>

          <Button
            fontSize="1rem"
            leftIcon={<RiArrowRightLine />}
            as={ChakraLink}
            href="https://community.venicemusic.co/spaces/12411298/search?term=feedback%20ses&filters=events&sort=created_at&sort_order=desc"
            disabled={!user?.subscription?.isActive}
            pointerEvents={!user?.subscription?.isActive ? 'none' : ''}
            variant="white"
            isExternal={true}>
            RSVP to our next sync feedback session
          </Button>
        </ManageContentBox>
      </SimpleGrid>

      <ComanagerView isTrialMode={!canAccessCoManager} />

      <Heading as="h2" mt="3rem" fontWeight="400" fontFamily="headings" color="black80" fontSize="2rem">
        Upcoming Events
      </Heading>

      <MembershipEventsView canAccessEvents={canAccessEvents && isActive} />

      <TerminatedAgreementModal
        isOpen={terminatedAgreementModal.isOpen}
        onClose={terminatedAgreementModal.onClose}
        variant="short"
        cancelText="close"
      />
    </ViewWrapper>
  );
};

export default Home;
