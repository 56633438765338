import React from 'react';
import { Helmet } from 'react-helmet';
import veniceHardcode from '../../assets/default/venice-hardcode.png';

const Generic = () => {
  // this component is at the top level above our providers so it is styled without our theme object or components supported by providers
  return (
    <>
      <Helmet>
        <style>{`
          body, #root {
            background-color: #ffffff;
            height: 100%;
          }

        `}</style>
      </Helmet>
      <section
        style={{
          height: '95vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontFamily: 'Roboto, sans serif',
          padding: 16,
        }}>
        <div
          style={{
            height: '100%',
            maxWidth: '64ch',
            lineHeight: 1.5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}>
          <img src={veniceHardcode} alt="Venice logo" style={{ height: 180 }} />
          <h1>Oh no, something went wrong!</h1>
          <p>
            It seems like you&apos;ve run into some unintended behavior. Our engineers are working on new features and
            have been automatically notified that you ended up here. You are always welcome to reach out to support if
            you&apos;re having a persistent problem.
          </p>
        </div>
      </section>
    </>
  );
};

export default Generic;
