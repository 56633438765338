import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import Icon from 'components/Icon/icon';
import LinkButton from 'features/LinkButton/link-button';
import { strokeNotFill } from 'utils/icons';
import { FlexRow } from 'styles';

const SearchFieldWrapper = styled.div`
  align-items: center;
  background: ${props => props.theme.colors.background.dropdownSearchHeader[props.colorVariant].default};
  display: flex;
  justify-content: space-between;
  min-width: 2.5rem;
  transition: border-radius 250ms ease;
  width: 100%;
  z-index: 20;
  padding: 0.75rem 1rem;
  color: ${props => props.theme.colors.text.dropdownSearchHeader[props.colorVariant].default};

  i path {
    ${props =>
    strokeNotFill.includes(props.icon)
      ? `
			fill: transparent;
			stroke: ${props.theme.colors.text.dropdownSearchHeader[props.colorVariant].default};
		`
      : `
			fill: ${props.theme.colors.text.dropdownSearchHeader[props.colorVariant].default};
		`}
  }

  &:hover {
    background: ${props => props.theme.colors.background.dropdownSearchHeader[props.colorVariant].hover};
    color: ${props => props.theme.colors.text.dropdownSearchHeader[props.colorVariant].hover};

    i path {
      ${props =>
    strokeNotFill.includes(props.icon)
      ? `
				fill: transparent;
				stroke: ${props.theme.colors.text.dropdownSearchHeader[props.colorVariant].hover};
			`
      : `
				fill: ${props.theme.colors.text.dropdownSearchHeader[props.colorVariant].hover};
			`}
    }
  }

  &:active {
    background: ${props => props.theme.colors.background.dropdownSearchHeader[props.colorVariant].active};
    color: ${props => props.theme.colors.text.dropdownSearchHeader[props.colorVariant].active};

    i path {
      ${props =>
    strokeNotFill.includes(props.icon)
      ? `
				fill: transparent;
				stroke: ${props.theme.colors.text.dropdownSearchHeader[props.colorVariant].active};
			`
      : `
				fill: ${props.theme.colors.text.dropdownSearchHeader[props.colorVariant].active};
			`}
    }
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex: 1;
`;
const TextInput = styled.input`
  margin-left: 0.5rem;
  background-color: transparent;
  flex: 1;
  border: none;
  font-size: ${props => props.theme.fonts.sizes.sm};
  outline: none;
  padding: 0;
  color: ${props => props.theme.colors.text.dropdownSearchHeader[props.colorVariant].default};
  width: 100%;

  &:hover {
    color: ${props => props.theme.colors.text.dropdownSearchHeader[props.colorVariant].hover};
  }

  &:active {
    color: ${props => props.theme.colors.text.dropdownSearchHeader[props.colorVariant].active};
  }

  ${props =>
    props.disabled &&
    `
		color: ${props => props.theme.colors.text.dropdownSearchHeader[props.colorVariant].disabled};
	`}
`;

const ControlLinkButton = styled(LinkButton)`
  white-space: nowrap;
  margin-left: 0.5rem;
`;

const DropdownSearchHeader = React.forwardRef(
  (
    {
      className,
      disabledDeselectAll,
      disabledSelectAll,
      includeDeselectAll,
      includeSelectAll,
      name,
      onDeselectAll,
      onSelectAll,
      onFilterChange,
      dark,
      light,
      nested,
    },
    ref
  ) => {
    const colorVariant = dark ? 'dark' : light ? 'light' : 'dark';

    return (
      <SearchFieldWrapper className={className} colorVariant={colorVariant}>
        <SearchWrapper>
          {nested && <Icon type="magnifyingGlass" />}
          <TextInput colorVariant={colorVariant} onChange={onFilterChange} data-lpignore={true} />
          {!nested && <Icon type="magnifyingGlass" />}
        </SearchWrapper>
        {nested && (
          <FlexRow>
            {includeSelectAll && (
              <ControlLinkButton
                small
                text={'Select All'}
                icon={null}
                disabled={disabledSelectAll}
                onClick={onSelectAll}
              />
            )}
            {includeDeselectAll && (
              <ControlLinkButton
                small
                text={'Deselect All'}
                icon={null}
                disabled={disabledDeselectAll}
                onClick={onDeselectAll}
              />
            )}
          </FlexRow>
        )}
      </SearchFieldWrapper>
    );
  }
);
DropdownSearchHeader.displayName = 'DropdownSearchHeader';

DropdownSearchHeader.defaultProps = {
  disabled: false,
  doShowError: false,
  error: null,
  options: [],
  placeholder: '',
  nested: true,
  name: 'DropdownSearchHeader',
  onChange: () => null,
  defaultCheckedValues: [],
  isCollapsible: false,
  includeDeselectAll: true,
  includeSelectAll: true,
};

export default withTheme(DropdownSearchHeader);
