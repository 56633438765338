/* Dev NOTE
The Radio Inputs from chakra would be prefered. However with the current version of chakra's radio buttons
The UI get jacked up simply by adding the radio button. There seems to be an incompatibality somewhere.
This soultion can be used as a component, and when/if we upgrade to a newer version of chakra we can
replace the boxes "as" with the chakra components.

*/
import React from 'react';
import { FormControl, FormLabel, useTheme, Tooltip, Box } from '@chakra-ui/react';

export default function LabeledRadioToggle({
  name,
  htmlId,
  label,
  value = 'no',
  onChange,
  onBlur,
  variant,
  tooltip,
  yesText = 'yes',
  noText = 'no',
  yesValue = 'yes',
  noValue = 'no',
  disabled,
  ...inputProps
}) {
  const theme = useTheme();

  //Since this insn't a real chakra component variants do not work as the other elements do.
  // An attempt to make this a terue chakra multipart compoent caused
  //the input to loose focus on typeing. So this way of doing it was landed on.
  const {
    div: divStyle,
    label: labelStyle,
    radio: radioStyle,
    radioGroup: radioGroupStyle,
    radioLabel: radioLabelStyle,
  } = variant
    ? theme.components.LabeledRadioToggle.variants[variant]
    : theme.components.LabeledRadioToggle?.baseStyle || {};

  if (!name) console.error('LabeledInput requires a name property.');

  htmlId = htmlId || name;

  return (
    <FormControl {...divStyle}>
      <Box>
        <Tooltip label={tooltip} placement="top-start">
          <FormLabel {...labelStyle} htmlFor={htmlId}>
            {label}
          </FormLabel>
        </Tooltip>
        <Box {...radioGroupStyle}>
          <Box as={'label'} htmlFor={`${name}_no`} {...radioLabelStyle}>
            <Box
              display={'inline'}
              as={'input'}
              type="radio"
              name={name}
              id={`${name}_no`}
              value={noValue}
              onChange={onChange}
              onBlur={onBlur}
              {...radioStyle}
              {...inputProps}
              checked={value === noValue}
              disabled={disabled}
            />
            {noText}
          </Box>

          <Box as={'label'} htmlFor={`${name}_yes`} {...radioLabelStyle}>
            <Box
              display={'inline'}
              as={'input'}
              type="radio"
              name={name}
              id={`${name}_yes`}
              value={yesValue}
              onChange={onChange}
              onBlur={onBlur}
              {...radioStyle}
              {...inputProps}
              checked={value === yesValue}
              disabled={disabled}
            />

            {yesText}
          </Box>
        </Box>
      </Box>
    </FormControl>
  );
}
