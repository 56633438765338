import React from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

const Pagination = ({
  onPrevPageHandler,
  onNextPageHandler,
  pageIndex,
  totalLength,
  pageSize,
  iconStyle,
  paginationProps,
}) => {
  const isPrevPageDisabled = pageIndex === 0;
  const isNextPageDisabled = (pageIndex + 1) * pageSize >= totalLength;
  return (
    <Flex alignItems="center" justifyContent="center" {...paginationProps}>
      <IconButton
        aria-label="previous page"
        icon={<RiArrowLeftSLine />}
        {...iconStyle}
        onClick={onPrevPageHandler}
        disabled={isPrevPageDisabled}
      />
      <Box mx="2" color="black100" fontWeight="medium">
        {pageIndex + 1} of {Math.ceil(totalLength / pageSize)}
      </Box>
      <IconButton
        aria-label="next page"
        icon={<RiArrowRightSLine />}
        {...iconStyle}
        onClick={onNextPageHandler}
        disabled={isNextPageDisabled}
      />
    </Flex>
  );
};

export default Pagination;
