import styled from '@emotion/styled/macro';
import Icon from 'components/Icon/icon';
import { strokeNotFill } from 'utils/icons';

export const disableSelection = `
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
`;

export const OmnishareText = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fonts.sizes.sm};
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin-bottom: 1rem;

  i {
    width: 0.75rem;
  }

  ${props =>
    props.wide &&
    `
    margin: 0 1.5rem 0 0;
    display: flex;
    align-items: center;

    > div {
      margin-right: 0.8rem;
    }
  `}
`;

export const Label = styled.span`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-size: ${props => props.theme.fonts.sizes.reg};
  height: 100%;
  line-height: 22px;
  overflow: hidden;
  padding: 0.75rem 1rem;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;

  ${props =>
    props.loading === 'true' &&
    `
    visibility: hidden;
  `}

  // Button

  ${props =>
    props.button &&
    `
    font-size: ${props.theme.fonts.sizes.sm};
    font-weight: ${props.theme.fonts.weights.bold};
    justify-content: center;
    line-height: 0.875rem;
  `}

  // Toggle Button

  ${props =>
    props.toggleButton &&
    `
    font-size: ${props.theme.fonts.sizes.sm};
    justify-content: center;
    line-height: ${props.theme.fonts.sizes.lg};
    padding: 0.5rem 1rem;
  `}

  ${props => {
    if (props.button) {
      if (props.round || props.square) {
        return `
          padding-left: 0.75rem;
          padding-right: 0.75rem;
        `;
      }

      if (props.short) {
        return `
          font-size: ${props.theme.fonts.sizes.xxsm};
          font-weight: ${props.theme.fonts.weights.medium};
          letter-spacing: 1px;
          line-height: 0.75rem;
          padding: 0.625rem 1rem;
          text-transform: uppercase;
        `;
      }

      if (props.cta) {
        return `
          font-size: ${props.theme.fonts.sizes.xlg};
          font-weight: ${props.theme.fonts.weights.bold};
          letter-spacing: 0.02em;
          line-height: 1.5rem;
          padding: 0.75rem 1.75;
        `;
      }
    }
  }}

  // Dropdown

  ${props => {
    if (props.dropdown) {
      if (props.short) {
        return `
          font-size: ${props.theme.fonts.sizes.xsm};
          line-height: 0.8125rem;
          width: calc(100% - 1.25rem);
          display: inline-block;
        `;
      }

      return `
        font-size: ${props.theme.fonts.sizes.reg};
        line-height: 18px;
        width: calc(100% - 1.5rem);
        display: inline-block;
      `;
    }
  }}
`;

export const TinyLogo = styled.div`
  align-items: center;
  align-self: center;
  background: ${props => props.theme.colors.brand.layer4};
  border-radius: 50%;
  color: ${props => props.theme.colors.brand.layer0};
  display: flex;
  font-size: 0.5rem;
  font-weight: ${props => props.theme.fonts.weights.bold};
  justify-content: center;
  height: 0.75rem;
  margin-right: 0.5rem;
  width: 0.75rem;
`;

export const List = styled.ul`
  background: ${props => props.theme.colors.background.dropdownList[props.colorVariant].default};
  border-radius: 0 0 4px 4px;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 100%;
  z-index: 101;
`;

export const ListItem = styled.li`
  color: ${props => props.theme.colors.text.dropdownList[props.colorVariant].default};
  cursor: pointer;
  font-size: ${props => props.theme.fonts.sizes.reg};
  font-weight: ${props => props.theme.fonts.weights.regular};
  transition: background 250ms ease;

  &:hover {
    ${props =>
    !props.isSelected &&
      `
      background: ${props.theme.colors.background.dropdownItem[props.colorVariant].hover};
    `}
  }

  ${props =>
    props.isSelected &&
    `
    color: ${props.theme.colors.text.dropdownItem[props.colorVariant].selected};
    cursor: default;
    font-weight: ${props.theme.fonts.weights.medium};
  `}

  ${props =>
    props.button &&
    `
    color: ${props.theme.colors.text.dropdownItem[props.colorVariant].button.default};
    font-size: ${props.theme.fonts.sizes.sm};
    font-weight: ${props.theme.fonts.weights.bold};

    &:hover {
      background: transparent;
      color: ${props.theme.colors.text.dropdownItem[props.colorVariant].button.hover};
    }
  `}
`;

export const LabelIcon = styled(Icon)`
  + span {
    margin-left: 0.5rem;
  }

  ${props =>
    props.right &&
    `
    margin-left: 0.5rem;
  `}
`;

export const LabelText = styled.span``;

export const Chevron = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto 1rem auto 0;
  transition: transform 250ms ease;

  ${props =>
    props.isMenuShowing &&
    `
    transform: rotate(180deg);
  `}

  ${props =>
    props.small &&
    `
    width: 0.75rem;
  `}
`;

export const HoverIconWrapper = styled.div`
  &:hover {
    i path {
      ${props =>
    strokeNotFill.includes(props.type)
      ? `
				fill: transparent;
				stroke: ${props.theme.colors.background.icon.hover};
			`
      : `
				fill: ${props.theme.colors.background.icon.hover};
			`}
    }
  }

  &:active {
    i path {
      ${props =>
    strokeNotFill.includes(props.type)
      ? `
				fill: transparent;
				stroke: ${props.theme.colors.background.icon.active};
			`
      : `
				fill: ${props.theme.colors.background.icon.active};
			`}
    }
  }
`;
