import React, { useState, useEffect } from 'react';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';

export default function MultiRowFormElementContainer({
  onChange,
  rowsData,
  name,
  RowComponent,
  addRowText,
  addRowDefaultValue = {},
  secondaryAction = {},
}) {
  const [localRowsData, setLocalRowsData] = useState(rowsData || []);

  useEffect(() => {
    setLocalRowsData(rowsData);
  }, [rowsData]);

  function onUpdateRow({ idx, ...data }) {
    const newData = localRowsData.map((d, index) => {
      if (index !== idx) return d;
      return data;
    });

    setLocalRowsData(newData);
    onChange({ target: { name, value: newData } });
  }

  function onDeleteRow(idx) {
    const newData = localRowsData.filter((d, index) => index !== idx);
    setLocalRowsData(newData);
    onChange({ target: { name, value: newData } });
  }

  function addRow() {
    setLocalRowsData(currentData => [...currentData, addRowDefaultValue]);
  }

  return (
    <Box>
      {localRowsData.map((rowData, idx, arr) => (
        <RowComponent
          key={`${idx}-${JSON.stringify(rowData)}`}
          isNew={JSON.stringify(rowData) === JSON.stringify(addRowDefaultValue)}
          idx={idx}
          rowData={rowData}
          updateRow={onUpdateRow}
          isFirst={idx === 0}
          isLast={arr.length === idx + 1}
          onDeleteRow={onDeleteRow}
        />
      ))}

      {addRowText ? (
        <ButtonGroup marginTop={'1.5rem'}>
          <Button variant="smallLighter" onClick={addRow}>
            {addRowText}
          </Button>
          {secondaryAction?.text && (
            <Button variant="smallLighter" onClick={secondaryAction.onClick}>
              {secondaryAction.text}
            </Button>
          )}
        </ButtonGroup>
      ) : (
        ''
      )}
    </Box>
  );
}
