import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import asModal from 'features/Modal/as-modal';

function ConfirmationModal({ text }) {
  return (
    <Box color="black100">
      <Text w="80%">{text}</Text>
    </Box>
  );
}

export default asModal(ConfirmationModal);
