import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';
import ARTWORK from '../../constants/ARTWORK.gen.json';
const DataApiUrl = dataApiUrl();

const Release = () => {
  const get = (id, with_status = false) =>
    AuthAxios.get(`${DataApiUrl}/releases/${id}`, {
      params: { with_status },
    }).then(res => res.data.data);

  const getStatus = id =>
    AuthAxios.get(`${DataApiUrl}/releases/${id}/status`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getDeliveredTo = id =>
    AuthAxios.get(`${DataApiUrl}/releases/${id}/deliveredTo`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  const getAll = params =>
    AuthAxios.get(`${DataApiUrl}/releases`, { params })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const create = (title, groupId) =>
    AuthAxios.post(`${DataApiUrl}/releases`, {
      title,
      user_group_id: parseInt(groupId),
    })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const update = (id, data) => AuthAxios.patch(`${DataApiUrl}/releases/${id}`, data).then(res => res.data.data);

  const softDelete = id =>
    AuthAxios.delete(`${DataApiUrl}/releases/${id}`)
      .then(res => res.data)
      .catch(err => console.error(err.message));

  const addTrack = (release_id, set_id, track_id, sequence) =>
    AuthAxios.post(`${DataApiUrl}/releases/${release_id}/sets/${set_id}/tracks`, { asset_track_id: track_id, sequence })
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const removeTrack = (release_id, set_id, release_track_id) =>
    AuthAxios.delete(`${DataApiUrl}/releases/${release_id}/sets/${set_id}/tracks/${release_track_id}`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const updateTrack = (release_id, set_id, release_track_id, data) =>
    AuthAxios.patch(`${DataApiUrl}/releases/${release_id}/sets/${set_id}/tracks/${release_track_id}`, data)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const tracks = release_id =>
    AuthAxios.get(`${DataApiUrl}/releases/${release_id}/tracks`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  const tracksBySet = (release_id, set_id) =>
    AuthAxios.get(`${DataApiUrl}/releases/${release_id}/sets/${set_id}/tracks`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  const artwork = (release_id, set_id, artwork_index, size) =>
    AuthAxios.get(`${DataApiUrl}/releases/${release_id}/sets/${set_id}/artworks/${artwork_index}`, {
      responseType: 'arraybuffer',
      params: { size },
    })
      .then(res => res.data)
      .catch(err => console.error(err.message));

  const defaultArtwork = (release_id, size) =>
    AuthAxios.get(`${DataApiUrl}/releases/${release_id}/defaultArtwork`, {
      responseType: 'arraybuffer',
      params: { size },
    })
      .then(res => res.data)
      .catch(err => console.error(err.message));

  const getArtworkForIds = (release_ids, size) => {
    return AuthAxios.get(`${DataApiUrl}/releases/artworks`, {
      params: {
        ids: release_ids,
        size: size || ARTWORK.displaySizes.SMALL,
      },
    });
  };

  const publish = (release_id, dsps) =>
    AuthAxios.post(`${DataApiUrl}/releases/${release_id}/publish`, { dsps })
      .then(res => res.status === 200)
      .catch(err => {
        //This passes the error the expected way to the FE if it was not formated in a
        //manner that was previously expected.
        const status = err.response.status;
        const errorToThrow =
          status === 401
            ? [{ meta: 'You are not authorized to publish releases.' }]
            : err.response.data.errors || [{ meta: err.response.data.detail }];
        throw errorToThrow;
      });

  const takedown = (release_id, dsps) =>
    AuthAxios.post(`${DataApiUrl}/releases/${release_id}/takedown`, {
      dsps,
    })
      .then(res => res.data)
      .catch(err => {
        const status = err.response.status;
        const errorToThrow = status === 401 ? new Error('You are not authorized to takedown releases.') : err;
        throw errorToThrow;
      });

  const sets = id =>
    AuthAxios.get(`${DataApiUrl}/releases/${id}/sets`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  const duplicate = id =>
    AuthAxios.post(`${DataApiUrl}/releases/${id}/copy`)
      .then(res => res.data.data)
      .catch(err => console.error(err.message));

  const getUploadUrl = async ({ id, setId, artworkIndex, fileName }) => {
    const fileParts = fileName.split('.');
    const ext = fileParts[fileParts.length - 1];
    const resp = await AuthAxios.get(`${DataApiUrl}/releases/${id}/sets/${setId}/artworks/${artworkIndex}/uploadUrl`, {
      params: { ext },
    });
    return resp.data.data;
  };

  const getDistributionHistory = id =>
    AuthAxios.get(`${DataApiUrl}/releases/${id}/distributionHistory`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  return {
    get,
    getStatus,
    getDeliveredTo,
    getUploadUrl,
    getAll,
    update,
    create,
    softDelete,
    addTrack,
    updateTrack,
    removeTrack,
    tracks,
    tracksBySet,
    artwork,
    defaultArtwork,
    publish,
    takedown,
    sets,
    duplicate,
    getDistributionHistory,
    getArtworkForIds,
  };
};

export default Release;
