// Used for save submission
const mapPartyFields = parties => {
  const createPartyFields = (party, i) => {
    const platformObject = party.platform_id ? party.platform_id : {};
    return {
      ...party,
      party_id: party.id || party.party_id || party.asset_party_id,
      spotifyId: party.spotifyId || platformObject.spotify,
      appleId: party.appleId || platformObject.apple,
      soundcloudId: party.soundcloudId || platformObject.soundcloud,
      sequence: party.type === 'artist' ? i + 1 : party.sequence,
    };
  };

  return parties?.map((party, i) => Object.assign({}, party, createPartyFields(party, i)));
};
export { mapPartyFields };
