import React from 'react';
import { Box, Heading, Divider, Flex, Image, Text, useToast, Center, Spacer, Spinner } from '@chakra-ui/react';
import Button from 'components/Button/button';
import asModal from 'features/Modal/as-modal';

function FetchUriModal({ searchSpotifyResult }) {
  const data = searchSpotifyResult?.data;
  return searchSpotifyResult?.isLoading ? (
    <Center m="5rem">
      <Spinner />
    </Center>
  ) : (
    <Box pb="2rem">
      <Box>
        <Text>The following track will have a link created.</Text>
      </Box>
      {data?.artists?.items?.length > 0 || data?.albums?.items?.length > 0 || data?.tracks?.items?.length > 0 ? (
        <Box>
          <ItemBlock items={data?.artists?.items} header="Artist" />
          <ItemBlock items={data?.albums?.items} header="Release" />
          <ItemBlock items={data?.tracks?.items} header="Track" />
        </Box>
      ) : (
        <Center m="5rem">No data</Center>
      )}
    </Box>
  );
}

function ItemBlock({ items, header }) {
  return items && items.length > 0 ? (
    <Box>
      <Heading as="h3" fontSize="0.8rem" mt="1rem" mb="1rem">
        {header}
      </Heading>
      {items?.length > 0 ? items?.map(item => <Item key={item.id} item={item} />) : null}
      <Divider size="lg" />
    </Box>
  ) : null;
}

function Item({ item }) {
  const toast = useToast();

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(item?.external_urls?.spotify);
    toast({ title: `Copied ${item?.external_urls?.spotify} to clipboard.` });
  }

  return (
    <Flex mb="1rem">
      <Center>
        <Image src={item?.images?.[0]?.url} alt={item?.name} height="3rem" width="3rem" />
      </Center>
      <Center>
        <Heading as="h4" fontSize="1rem" ml="16px">
          {item?.name}
        </Heading>
      </Center>
      <Spacer />
      <Center>
        <Button quaternary icon="copy" text="Copy URI" onClick={handleCopyToClipboard} />
      </Center>
    </Flex>
  );
}

export default asModal(FetchUriModal);
