import * as React from 'react';
import { Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute/private-route';
import useUser from 'data-client/use-current-user.jsx';
import GetDspUri from 'features/DspUri/get-dsp-uri';

export default function GetDspUriRouter({ isPrivate }) {
  //USE user hook
  const [user] = useUser();
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  //wait until user is loaded. Could do a loading screen ?
  if (!user?.currentGroup?.id) return <></>;

  return (
    <>
      <RouteComponent path="/dsp-uri/:dsp/:groupId" exact render={props => <GetDspUri {...props} />} />
    </>
  );
}
