import { useQuery } from 'react-query';
import Track from '../utils/API/track';

const { getSignedUrl } = Track();

export default function useGetPresignedTrackUrl({ track_id, file_name }) {
  return useQuery(['presignedUrl', track_id, file_name], () => getSignedUrl({ track_id, file_name }), {
    enabled: !!file_name,
  });
}
