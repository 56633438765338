import { FormControl, Input, Flex, IconButton, useTheme } from '@chakra-ui/react';

import * as PropTypes from 'prop-types';
import React from 'react';
import { Col } from 'react-styled-flexboxgrid';

import { ContributorColumn } from './contributor-column';
import { PayeeColumn } from './payee-column';
import { RolesColumn } from './roles-column';
import { formatContributors } from './splits-form-helpers';
import { RiDeleteBin7Line } from 'react-icons/ri';

const MetadataRow = ({ children, ...props }) => {
  return (
    <Flex marginBottom="1.5rem" {...props}>
      {children}
    </Flex>
  );
};

function DeleteSplitColumn(props) {
  return (
    <Col>
      {!props.disabled && <IconButton variant={'transparent'} onClick={props.onClick} icon={<RiDeleteBin7Line />} />}
    </Col>
  );
}

function PercentageColumn(props) {
  const theme = useTheme();
  return (
    <>
      {!props.disabled && (
        <Col position="relative" xs={2}>
          <FormControl isRequired>
            <Input
              backgroundColor={theme.colors.white}
              borderColor={theme.colors.black60}
              _hover={{
                borderColor: theme.colors.black60,
              }}
              placeholder="00.000"
              type={'number'}
              onChange={props.onChange}
              value={props.value}
              maxLength={6}
            />
          </FormControl>
        </Col>
      )}
      {props.disabled && (
        <Col display={'flex'} alignItems={'center'}>
          {props.value}
        </Col>
      )}
    </>
  );
}

PercentageColumn.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  disabled: PropTypes.bool,
};

const SplitsRow = ({
  isDisabled,
  payeesOptions,
  index,
  split,
  onChangeRowValue,
  handleRemoveRowClick,
  handleAddPayeeClick,
  handleAddContributorClick,
  availablePayeeContributors,
  errors,
}) => {
  const onChangePayee = data => onChangeRowValue(index, 'payee', data);
  const onChangeContributor = data => onChangeRowValue(index, 'contributor', data);
  const onChangeRoles = data => onChangeRowValue(index, 'roles', data);
  const onChangePercentage = e => {
    onChangeRowValue(index, 'percentage', e?.target?.value);
  };

  const onClickRemoveRow = () => {
    handleRemoveRowClick(split);
  };

  if (!split) {
    return null;
  }
  const contributorOptions = formatContributors(availablePayeeContributors[split?.payee?.id]);

  const disabledForReal = isDisabled || !!split.isLabelPayee;

  return (
    <MetadataRow>
      <PayeeColumn
        xs={3}
        value={split?.payee}
        onChange={onChangePayee}
        disabled={disabledForReal}
        invalid={errors?.payee}
        payeesOptions={payeesOptions || []}
        onRowActionButtonClick={handleAddPayeeClick}
        style={{ position: 'relative', left: '-0.5rem' }}
      />
      <ContributorColumn
        xs={4}
        disabled={disabledForReal}
        onChange={onChangeContributor}
        options={contributorOptions}
        value={split?.contributor}
        onRowActionButtonClick={handleAddContributorClick}
      />
      <RolesColumn
        xs={3}
        disabled={disabledForReal}
        onChange={onChangeRoles}
        value={split?.roles}
        invalid={errors?.roles}
      />
      <PercentageColumn xs={2} disabled={isDisabled} onChange={onChangePercentage} value={split?.percentage || ''} />
      <DeleteSplitColumn disabled={disabledForReal} onClick={onClickRemoveRow} />
    </MetadataRow>
  );
};
export default SplitsRow;
