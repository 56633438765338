import React from 'react';
import { Box, Button, Heading, Flex, Spacer, Text, useTheme } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import { DateTime } from 'luxon';

import { RiArrowLeftSLine as ArrowLeftIcon, RiDownloadLine as DownloadIcon } from 'react-icons/ri';

const HeaderSection = ({ title, date, csvUrlQuery }) => {
  const theme = useTheme();

  const { refetch: fetchUrl } = csvUrlQuery;

  const handleDownloadClick = async () => {
    await fetchUrl();
  };

  return (
    <Box>
      <Box>
        <Button leftIcon={<ArrowLeftIcon />} as={RouterLink} variant="transparent" to="/statements">
          Back to Statements
        </Button>
      </Box>
      <Flex w={'100%'}>
        <Box>
          <Heading as="h1" margin={0} lineHeight="2.75rem">
            {title}
          </Heading>
          <Text color={theme.colors.black60}>Report Date: {date && DateTime.fromISO(date).toFormat('LL-dd-yyyy')}</Text>
        </Box>
        <Spacer />
        <SkeletonLoader status={csvUrlQuery.status}>
          <Box>
            <Button
              color={theme.colors.black100}
              leftIcon={<DownloadIcon />}
              variant={'smallLighter'}
              onClick={handleDownloadClick}>
              Export CSV
            </Button>
          </Box>
        </SkeletonLoader>
      </Flex>
    </Box>
  );
};

export default HeaderSection;
