import React, { useState } from 'react';
import { Box, Flex, Button, IconButton } from '@chakra-ui/react';
import { RiCloseFill } from 'react-icons/ri';

export class WellVariant {
  static PERSISTENT = 'persistent';
  static COLLAPSIBLE = 'collapsible';
}

export function PersistentWell({ children, styleProps }) {
  return (
    <Flex width="100%" borderRadius="1rem" padding="1rem 0.5rem 1rem 1.5rem" {...styleProps}>
      <Box {...styleProps}>{children}</Box>
    </Flex>
  );
}

export function CollapsibleWell({ children, expandButtonText }) {
  const [isVisible, setIsVisible] = useState(false);

  const onCloseWell = () => setIsVisible(false);
  const onToggleWell = () => {
    setIsVisible(!isVisible);
    return [isVisible, setIsVisible];
  };

  return (
    <>
      <Button
        variant="transparent"
        color="black60"
        border="none"
        fontSize="0.875rem"
        fontWeight="normal"
        textDecoration="underline"
        onClick={onToggleWell}>
        {expandButtonText}
      </Button>
      {isVisible && (
        <Flex
          justifyContent="space-between"
          alignItems={'flex-start'}
          background="black10"
          width="100%"
          borderRadius="1rem"
          padding="1rem 0.5rem 1rem 1.5rem">
          <Box>{children}</Box>
          <Box minWidth="2rem">
            <IconButton
              variant="transparent"
              color="black"
              aria-label="Close Section"
              position={'relative'}
              top="-1rem"
              icon={<RiCloseFill size={'1.25rem'} />}
              onClick={onCloseWell}
              _focus={{ outline: 'none' }}
            />
          </Box>
        </Flex>
      )}
    </>
  );
}

export default function Well({
  children,
  variant = WellVariant.PERSISTENT,
  expandButtonText = 'Learn more',
  styleProps = {},
}) {
  const ContentComponent = variant === WellVariant.PERSISTENT ? PersistentWell : CollapsibleWell;

  return (
    <Box {...styleProps}>
      <ContentComponent {...styleProps} expandButtonText={expandButtonText}>
        {children}
      </ContentComponent>
    </Box>
  );
}
