import { useEffect, useState } from 'react';
import * as API from '../utils/API/API';
import { useQuery, useQueryClient } from 'react-query';

const User = API.user();

const QUERY_KEY = 'dbUser';

const useCurrentUser = (queryOptions = {}) => {
  const queryClient = useQueryClient();

  const [currentUser, setCurrentUser] = useState(null);

  const currentUserQuery = useQuery([QUERY_KEY], () => User.getMe(), {
    refetchOnMount: false,
    staleTime: Infinity,
    ...queryOptions,
  });

  useEffect(() => {
    if (currentUserQuery.isSuccess && currentUserQuery.isFetching === false && currentUserQuery.isLoading === false) {
      const latestQueryUser = currentUserQuery?.data;
      setCurrentUser(userInState =>
        !userInState || userInState.user_user_id !== latestQueryUser?.user_user_id ? latestQueryUser : userInState
      );
    }
  }, [currentUserQuery.data, currentUserQuery.isFetching, currentUserQuery.isLoading]);

  const onSet = (...args) => {
    setCurrentUser(...args);
    return queryClient.invalidateQueries([QUERY_KEY]);
  };

  return [currentUser, onSet, currentUserQuery];
};

export default useCurrentUser;
