import React from 'react';
import { chakra, useTheme, useStyleConfig } from '@chakra-ui/system';

//This allows variants on html strong element.
const Strong = ({ variant, children, ...props }) => {
  const theme = useTheme();

  const Strong = chakra('strong', {
    ...theme.components.Strong,
  });

  const styles = useStyleConfig('Strong', { variant });

  return (
    <Strong __css={styles} {...props}>
      {children}
    </Strong>
  );
};

export default Strong;
