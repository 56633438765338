import React, { useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useGetStripeClientSecret, useUpdateStripeDefaultPaymentMethod } from '../../data-client/use-membership';
import { loadStripe } from '@stripe/stripe-js';
import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react';

const DEFAULT_ACCOUNT_ERROR =
  'There was a problem updating your account. Please check with your bank, contact support, or try again later.';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_PUBLISHABLE_KEY);
const PAYMENT_CREATION_SUCCEEDED = 'succeeded';

export default function UpdateStripePaymentInfo({ closeStripeElement, isAccountReactivation }) {
  const { data: stripeClientData } = useGetStripeClientSecret();
  const { mutateAsync: updateDefaultPaymentMethod } = useUpdateStripeDefaultPaymentMethod();
  const [isProcessingUpdate, setIsProcessingUpdate] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [stripePaymentErrorMessage, setStripePaymentErrorMessage] = useState(null);

  async function onTransactionSubmit(elements, stripe) {
    setIsProcessingUpdate(true);
    setStripePaymentErrorMessage(undefined);

    try {
      //This takes the users entered CC information and sends directly to stripe.
      //WE need to use the BE to set this new CC as the default.
      const stripeResult = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });
      //The endpoint only sends a 200 that the request sent to stripe was a success. Not if stripe was able to
      //process it. If we want/need to we can consider using cache and webhooks to wait for the stripe response.

      if (stripeResult.error) {
        setStripePaymentErrorMessage(stripeResult.error?.message || DEFAULT_ACCOUNT_ERROR);
        return;
      }

      const {
        setupIntent: { id, status },
      } = stripeResult;

      if (status === PAYMENT_CREATION_SUCCEEDED) {
        //If creating a new CC didn't return an error, we can update the default payment method.
        const mutationResult = await updateDefaultPaymentMethod({ setupIntentId: id });
        const paymentSecret = mutationResult?.data?.data?.userInputNeededPaymentSecret;

        //If the payment method update returns a secret then we are reactivating a subscription
        //and need to confirm the payment on the frontend.
        if (paymentSecret) {
          //If this confirmation works then the return_url will be navigated to.
          const confirmationResult = await stripe.confirmPayment({
            clientSecret: paymentSecret,
            confirmParams: {
              return_url: window.location.href,
            },
          });

          if (confirmationResult.error) {
            setStripePaymentErrorMessage(confirmationResult.error?.message || DEFAULT_ACCOUNT_ERROR);
            return;
          }
        }

        setUpdateStatus(
          'The request to update your account was received. It will take a few minutes for the system to process the request.'
        );
      } else {
        setUpdateStatus(DEFAULT_ACCOUNT_ERROR);
      }
    } catch (err) {
      setUpdateStatus(DEFAULT_ACCOUNT_ERROR);
    } finally {
      setIsProcessingUpdate(false);
    }
  }

  if (!stripeClientData) {
    return <Spinner />;
  }

  if (updateStatus) {
    return <Box>{updateStatus}</Box>;
  }

  const confirmText = isAccountReactivation ? 'Reactivate Account' : 'Update credit card';
  return (
    <Box padding="6px 0">
      <Elements stripe={stripePromise} options={{ clientSecret: stripeClientData?.stripeClientSecret }}>
        <StripeElements
          onSubmit={onTransactionSubmit}
          cancel={closeStripeElement}
          isProcessingUpdate={isProcessingUpdate}
          confirmText={confirmText}
        />
      </Elements>
      {stripePaymentErrorMessage && <Text color="red">{stripePaymentErrorMessage}</Text>}
    </Box>
  );
}

function StripeElements({ onSubmit, cancel, isProcessingUpdate, confirmText }) {
  const elements = useElements();
  const stripe = useStripe();

  return (
    <>
      <Spinner display={isProcessingUpdate ? 'block' : 'none'} />
      <Box display={isProcessingUpdate ? 'none' : 'block'}>
        <PaymentElement id={'stripe-payment-element'} />
        <Flex padding="10px 0" margin="6px 0">
          <Button
            data-cy={global.APP.RUNTIME === 'production' ? undefined : 'submit-account-update-button'}
            variant="smallDark"
            onClick={() => onSubmit(elements, stripe)}>
            {confirmText}
          </Button>
          <Button background="none" variant="small" ml={'2rem'} onClick={cancel}>
            Cancel
          </Button>
        </Flex>
      </Box>
    </>
  );
}
