import React, { useState } from 'react';
import ViewWrapper from 'views/ViewWrapper/view-wrapper';
import { Box, Heading, Text, Center, Spinner, useDisclosure, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import SearchBar from 'components/search-bar';
import useDebounce from 'utils/debounce';
import { CreateMarketingLinkModal } from './create-marketing-link-modal';
import { useSearchTracksAndReleases } from 'data-client/use-marketing-links';
import SearchHeading from 'features/SearchHeading/search-heading';
import Releases from 'components/ReleaseList/release-list-marketing-link';
import Tracks from 'components/TrackTable/track-table-marketing-link';
import { RiArrowLeftSLine as ArrowLeftIcon } from 'react-icons/ri';

function CreateMarketingLinkBody({ searchTerm, searchQueryIsLoading, searchQueryData }) {
  const [createData, setCreateData] = useState({});
  const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure();

  const handleCreateClick = newCreateData => {
    setCreateData(newCreateData);
    onCreateModalOpen();
  };
  if (searchQueryIsLoading) {
    return (
      <Center mt="1rem">
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <Box>
        <SearchHeading searchTerm={searchTerm} searchQueryData={searchQueryData} />
      </Box>
      <Releases handleCreateClick={handleCreateClick} releases={searchQueryData?.releases?.results} />
      <Tracks handleCreateClick={handleCreateClick} tracks={searchQueryData?.tracks?.results} />
      <CreateMarketingLinkModal {...createData} isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
    </>
  );
}

export default function CreateMarketingLink(props) {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const searchQuery = useSearchTracksAndReleases(debouncedSearchTerm);

  return (
    <ViewWrapper designLayer={0} {...props}>
      <Box>
        <Button leftIcon={<ArrowLeftIcon />} as={RouterLink} variant="transparent" to="/marketing-links">
          Back to marketing
        </Button>
      </Box>
      <Box>
        <Heading as="h1">Create Marketing Link</Heading>
      </Box>
      <Box mb="2rem">
        <Text>Search for any delivered track or album in your Venice Catalog</Text>
      </Box>
      <Box mb="3.375rem">
        <SearchBar
          searchTerm={searchTerm}
          onSearchTermChange={setSearchTerm}
          testCode={'release-track-search'}
          width="37.5rem"
        />
      </Box>
      <CreateMarketingLinkBody
        searchTerm={debouncedSearchTerm}
        searchQueryIsLoading={searchQuery.isFetching}
        searchQueryData={searchQuery?.data}
      />
      <Box height={'5rem'} />
    </ViewWrapper>
  );
}
