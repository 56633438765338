import React, { useRef } from 'react';
import DeleteThreadModal from './delete-thread-modal';
import { useDisclosure, IconButton, Text, Icon, Flex, Box } from '@chakra-ui/react';
import { RiDeleteBin6Line } from 'react-icons/ri';

function FullscreenThreadPreviewList({ threadModelCategories, highlightedThreadId, onOpenThread, onDeleteThread }) {
  const deleteRef = useRef();
  const deleteConfirmationDisclosure = useDisclosure();

  return (
    <>
      <DeleteThreadModal
        isOpen={deleteConfirmationDisclosure.isOpen}
        onCancel={deleteConfirmationDisclosure.onClose}
        onConfirm={() => {
          onDeleteThread(deleteRef.current);
          deleteConfirmationDisclosure.onClose();
        }}
      />
      {(Object.keys(threadModelCategories) || []).map(categoryKey => {
        const threadModels = threadModelCategories[categoryKey];

        return (
          <Box key={categoryKey}>
            <Text pl="1rem" color="gray75">
              {categoryKey}
            </Text>
            {(threadModels || []).map(t => {
              const isHighlightedThread = t.chat_bot_thread_id === highlightedThreadId;
              return (
                <Flex
                  key={t.chat_bot_thread_id}
                  variant="transparent"
                  cursor="pointer"
                  height="2.75rem"
                  w="100%"
                  pl="1rem"
                  onClick={() => onOpenThread(t)}
                  alignItems="center"
                  w="100%"
                  bg={isHighlightedThread ? 'black.100' : 'transparent'}
                  _hover={{}}
                  justifyContent="space-between"
                  pr=".5rem">
                  <Text
                    sx={{ textWrap: 'nowrap' }}
                    whiteSpace="nowrap"
                    textAlign="left"
                    lineHeight="1.5rem"
                    height="1.5rem"
                    color="white"
                    fontSize=".8rem"
                    fontWeight="600"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    width="88%">
                    {t.summary}
                  </Text>
                  {isHighlightedThread && (
                    <IconButton
                      size={'xs'}
                      onClick={e => {
                        e.stopPropagation();
                        deleteRef.current = t;
                        deleteConfirmationDisclosure.onOpen();
                      }}
                      icon={<Icon as={RiDeleteBin6Line} color="cream.80" />}
                    />
                  )}
                </Flex>
              );
            })}
          </Box>
        );
      })}
    </>
  );
}

export default FullscreenThreadPreviewList;
