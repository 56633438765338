import React from 'react';
import Box from '../Box/Box';
import { Spinner, Flex, Text } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';

const LoaderMessage = ({ unfurled_text }) => {
  const text = unfurled_text || '';

  return (
    <Flex lineHeight="1.5rem" fontSize="1rem" gap="0.25rem" maxWidth="48rem" direction="row">
      <Box w="1.25rem" flexShrink="0">
        <Spinner size="sm"></Spinner>
      </Box>
      <Box>
        <Text verticalAlign="middle" lineHeight="1.25rem" fontSize="1rem" color="black80" fontWeight="600">
          Co-Manager...
        </Text>
        <Box paddingTop="0.25rem" className="markdown-styles">
          <ReactMarkdown>{text.length > 0 ? `${text} ⬤` : text}</ReactMarkdown>
        </Box>
      </Box>
    </Flex>
  );
};

export default LoaderMessage;
