import * as React from 'react';
import { Global, css } from '@emotion/react/macro';

// ATTENTION:
//  This file is for app-level global styles only.
//  Do not put component-specific styles in this file.

const GlobalStyle = props => (
  <Global
    styles={css`
      html {
        font-size: ${props.theme.fonts.sizes.base};
      }

      /*
        This will hide the focus indicator if the element receives focus via the mouse,
        but it will still show up on keyboard focus.
      */
      .js-focus-visible :focus:not([data-focus-visible-added]) {
        outline: none;
        box-shadow: none;
      }

      body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: ${props.theme.colors.brand.layer4};
        font-family: ${props.theme.fonts.families.text};
        font-size: ${props.theme.fonts.sizes.reg};
        font-weight: ${props.theme.fonts.weights.regular};
        margin: 0;
      }

      label {
        font-size: ${props.theme.fonts.sizes.reg};
        letter-spacing: 0.1rem;
      }

      textarea {
        font-family: ${props.theme.fonts.families.text};
      }

      h1,
      h2,
      h3 {
        color: ${props.theme.colors.brand.textWhite};
        font-family: ${props.theme.fonts.families.display};
        font-weight: bold;
        margin: 0;
      }

      h4,
      h5 {
        color: ${props.theme.colors.brand.textWhite};
        font-family: ${props.theme.fonts.families.text};
        margin: 0;
      }

      h1 {
        font-size: ${props.theme.fonts.sizes.xxxlg};
        margin: 1.5rem 0;
      }

      h2 {
        font-size: ${props.theme.fonts.sizes.lg};
      }

      h3 {
        font-size: ${props.theme.fonts.sizes.xxlg};
      }

      h4 {
        font-size: ${props.theme.fonts.sizes.xlg};
      }

      h5 {
        font-size: ${props.theme.fonts.sizes.lg};
      }

      code {
        font-family: ${props.theme.fonts.families.code};
      }

      small {
        font-size: ${props.theme.fonts.sizes.xxsm};
      }

      a {
        color: ${props.theme.colors.brand.accent};
        text-decoration: none;
        transition: all 250ms ease;

        &:hover {
          color: ${props.theme.colors.brand.accentHover};
        }
      }

      button {
        cursor: pointer;
        font-family: ${props.theme.fonts.families.text};
      }
    `}
  />
);

export default GlobalStyle;
