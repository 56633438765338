import { useQueries } from 'react-query';
import * as API from 'utils/API/API';
import useCurrentUser from './use-current-user';

const User = API.user();
const Group = API.group();

export const PAYEE_TYPES = {
  USER: 'user',
  GROUP: 'group',
};

export function usePaymentAccounts() {
  const [dbUser] = useCurrentUser();
  const currentGroup = dbUser?.currentGroup || {};
  const { id: groupId, name: groupName } = currentGroup;

  const paymentAccountQueries = useQueries([
    {
      queryKey: ['userPaymentAccounts', dbUser?.id],
      queryFn: () => User.getPaymentAccount(dbUser?.id),
      select: data => ({
        name: dbUser.name,
        payeeType: PAYEE_TYPES.USER,
        ...data,
      }),
      enabled: !!dbUser,
    },
    {
      queryKey: ['groupPaymentAccounts', groupId],
      queryFn: () => Group.getPaymentAccount(groupId),
      select: data => ({
        name: groupName,
        payeeType: PAYEE_TYPES.GROUP,
        ...data,
      }),
      enabled: !!dbUser && !!groupId,
    },
  ]);

  const paymentAccounts = paymentAccountQueries
    .filter(({ isSuccess, data }) => isSuccess && data)
    .map(({ data }) => data);

  const paymentAccountsIsLoading = paymentAccountQueries.some(({ isLoading }) => isLoading);

  return { paymentAccounts, paymentAccountsIsLoading };
}
