import React from 'react';
import { useTheme, Box, Flex } from '@chakra-ui/react';
import IconButton from 'components/IconButton/icon-button';
import { RiLightbulbFlashLine as VeniceTipsIcon, RiInformationFill as InfoIcon } from 'react-icons/ri';
import IconedToolTip from 'components/IconedToolTip/IconedToolTip';

export default function ManageContentBox({ children, ...props }) {
  const theme = useTheme();

  return (
    <Box
      padding={'1rem'}
      marginTop={'1rem'}
      borderWidth="1px"
      borderColor={theme.colors.black50}
      borderRadius={'0.3rem'}
      maxWidth={'46rem'}
      {...props}>
      {children}
    </Box>
  );
}

export function ContentHeader({ children, ...props }) {
  return (
    <Flex justifyContent={'space-between'} {...props}>
      {children}
    </Flex>
  );
}

export function ContentFooter({ children, ...props }) {
  return (
    <>
      <Flex direction="row" {...props} mb="1rem" alignItems="start">
        {children}
      </Flex>
    </>
  );
}

export function Title({ toolTip, children, ...props }) {
  const theme = useTheme();

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'start'}
      textAlign={'left'}
      fontSize={'1rem'}
      fontWeight={'bold'}
      color={theme.colors.black80}
      {...props}>
      {children}
      {toolTip ? (
        <IconedToolTip
          iconProps={{ marginTop: '0.5rem', marginLeft: '0.25rem', size: 3 }}
          icon={InfoIcon}
          text={toolTip}
        />
      ) : (
        ''
      )}
    </Flex>
  );
}

export function VeniceTip({ onClick, ...props }) {
  return (
    <IconButton
      variant="lightPink"
      style={{ padding: '0 0.5rem', fontSize: '0.75rem', height: '2rem', border: 'none' }}
      icon={VeniceTipsIcon}
      iconSize={5}
      onClick={onClick}
      {...props}>
      Venice Tips
    </IconButton>
  );
}
