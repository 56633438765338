function asyncDebounce(func, wait = 0) {
  let timerId, latestResolve, shouldCancel;

  return function (...args) {
    if (!latestResolve) {
      return new Promise((resolve, reject) => {
        latestResolve = resolve;
        timerId = setTimeout(invokeAtTrailing.bind({}, args, resolve, reject), wait);
      });
    }

    shouldCancel = true;
    return new Promise((resolve, reject) => {
      latestResolve = resolve;
      timerId = setTimeout(invokeAtTrailing.bind({}, args, resolve, reject), wait);
    });
  };

  function invokeAtTrailing(args, resolve, reject) {
    if (shouldCancel && resolve !== latestResolve) {
      reject({});
    } else {
      func.apply({}, args).then(resolve).catch(reject);
      shouldCancel = false;
      clearTimeout(timerId);
      timerId = latestResolve = null;
    }
  }
}

export default asyncDebounce;
