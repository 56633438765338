import React from 'react';
import ArtistRowRole from 'components/role-manager/artist-row-role';
import RoleManager from 'components/role-manager/role-manager';
import { useArtistModal } from 'settings/add-artist-modal';
import ENUMS from '../../constants/ENUMS.gen.json';

const {
  SOUND_RECORDING_ROLE: { REMIXER, REMIXED_ARTIST, MAIN_ARTIST },
} = ENUMS;

export default function ArtistRoleManager({
  onCreateArtist,
  loading,
  selectedArtists = [],
  onChange,
  requiredReleaseArtist,
  artists = [],
}) {
  const artistOptions = artists.map(c => ({ title: c.full_name, value: c.id })) || [];

  //We need to reduce all the artists down to a single role since that is what the user
  //selects against.
  const selectedArtistRows = selectedArtists.map(({ asset_party_id, roles }) => {
    const roleSet = new Set(roles);
    //Remixed artist is never a selectable option for a role so we can remove it.
    roleSet.delete(REMIXED_ARTIST);

    //A main artist is a role added onto a remixer so if we have a remixer then we
    //can cut away main artist
    if (roleSet.has(REMIXER)) {
      roleSet.delete(MAIN_ARTIST);
    }

    const role = [...roleSet][0];

    return {
      asset_party_id,
      role: role,
    };
  });

  const [AddArtistModal, addArtistModalDisclosure] = useArtistModal({ onCreateArtist });
  const requiredArtist = requiredReleaseArtist
    ? {
        full_name: requiredReleaseArtist.full_name,
        value: requiredReleaseArtist.asset_party_id,
      }
    : null;

  function determinePartyRoles(artistRoles = [], remixerRoleExistsInGroup) {
    const roleSet = new Set(artistRoles);

    //If there are any artist remixer then we need to add any remixers as main artists and
    //and non-remixers as remixed artists since they must have been remixed.
    if (remixerRoleExistsInGroup) {
      if (roleSet.has(REMIXER)) {
        //Remixers should always be seen as main artists.
        roleSet.add(MAIN_ARTIST);
      } else {
        //If a single remixer exists in a group of artists then everyone
        //not consided a remixer should be a remixed artist because their
        //work has been remixed.
        roleSet.add(REMIXED_ARTIST);
      }
    } else {
      roleSet.delete(REMIXER);
      roleSet.delete(REMIXED_ARTIST);
    }

    return [...roleSet];
  }

  function onRoleManagerChange(changeEvent) {
    const {
      target: { name, value: artists },
    } = changeEvent;

    const remixerRoleExists = !!artists.find(artist => artist.role === REMIXER);
    const remixerAdjustedArtists = artists.map(({ asset_party_id, role }) => ({
      asset_party_id,
      roles: determinePartyRoles([role], remixerRoleExists),
    }));

    onChange({ target: { name, value: remixerAdjustedArtists } });
  }

  return (
    <>
      <AddArtistModal variant="light" />
      <RoleManager
        loading={loading}
        rowsData={selectedArtistRows}
        onChange={onRoleManagerChange}
        name={'artists'}
        addRowText={'Add Artist'}
        secondaryAction={{
          text: 'Create New Artist',
          onClick: addArtistModalDisclosure.onOpen,
        }}
        RowComponent={({ ...props }) =>
          ArtistRowRole({
            rolesOptions: [
              { title: 'Primary Artist', value: 'MainArtist' },
              { title: 'Featured Artist', value: 'FeaturedArtist' },
              { title: 'Remixer', value: 'Remixer' },
            ],
            requiredArtist,
            addArtistModal: addArtistModalDisclosure,
            artistsOptions: artistOptions,
            ...props,
          })
        }
      />
    </>
  );
}
