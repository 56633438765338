import * as React from 'react';
import { Route } from 'react-router-dom';
import SettingsContainer from './settings-container';

import PrivateRoute from 'components/PrivateRoute/private-route';

export default function SettingsRouter({ isPrivate }) {
  const RouteComponent = isPrivate ? PrivateRoute : Route;

  return <RouteComponent path="/settings" exact render={props => <SettingsContainer {...props} />} />;
}
