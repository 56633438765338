import React, { useState } from 'react';
import { Box, Text, Image } from '@chakra-ui/react';

import Well from 'components/Well/well';
import { ContentHeader, Title } from 'components/ManageContentBox/manage-content-box';
import HeaderAndContentBox from './clip-review-header-and-content';
import ListItem from './clip-review-list-item';
import FallbackCoverArt from 'assets/fallback-cover-art.png';

export default function ReviewAndSubmitSetup({ stateManager, stateData }) {
  const reader = new FileReader();
  const [coverArtSrc, setCoverArtSrc] = useState(FallbackCoverArt);

  reader.onload = function () {
    setCoverArtSrc(reader.result);
  };

  if (stateData.cover_art instanceof Blob) {
    reader.readAsDataURL(stateData.cover_art);
  }

  return (
    <HeaderAndContentBox stateManager={stateManager}>
      <ContentHeader>
        <Title toolTip={'Upload the art that will display your release on streaming services'}>Cover Art</Title>
      </ContentHeader>

      <Image width="120px" height="120px" src={coverArtSrc} alt="cover art" rounded={'0.5rem'} />
      {/*
            //The image in the ticket had this, but the audio source is lower down. Need design to look it over as to why "Audio Source is there"

            <ContentHeader>
              <Title toolTip={'File must be a wav or mp3'}>Audio Upload</Title>

            </ContentHeader> */}

      <Well>
        <Text fontWeight="bold">RELEASE TITLE PREVIEW</Text>
        <Text>{`${stateData.title} ${stateData.sub_title ? `(${stateData.sub_title})` : ''}`}</Text>
      </Well>

      <ContentHeader>
        <Title>Release Data</Title>
      </ContentHeader>
      <Box>
        <ListItem title={'Release Title'} value={stateData.title} />
        <ListItem title={'Version'} value={stateData.sub_title} />
        <ListItem title={'Display Artist Name'} value={stateData.display_artist_name} />
        <ListItem title={'Display Label Name'} value={stateData.display_label_name} />
        <ListItem title={'Copyright Year'} value={stateData.copyright_year} />
      </Box>
    </HeaderAndContentBox>
  );
}
