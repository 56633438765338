import React from 'react';
import {
  Modal as ChakraModal,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button as ChakraButton,
} from '@chakra-ui/react';

/**
 * @param {object} props
 * @param {boolean} props.isShowing
 * @param {function} props.onCancel
 * @param {function} props.onConfirm
 */
const ConfirmExitCreateClips = ({ isShowing, onCancel, onConfirm }) => {
  return (
    <ChakraModal isOpen={isShowing} onClose={() => onCancel('confirmExitCreateClips')}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton marginTop="0.5rem" />

        <ModalHeader paddingLeft="1.5rem" paddingTop="2rem" fontSize="l" marginTop="0">
          Exit without saving?
        </ModalHeader>

        <ModalBody paddingLeft="1.5rem" color="black100" marginTop="1rem" marginBottom="1rem">
          If you do not save, your changes will be lost.
        </ModalBody>

        <ModalFooter paddingRight="1.5rem" paddingBottom="1.5rem">
          <ChakraButton variant="transparent" fontWeight="700" onClick={() => onCancel('confirmExitCreateClips')}>
            Cancel
          </ChakraButton>
          <ChakraButton marginLeft="2rem" variant="smallDark" submit onClick={onConfirm}>
            Exit without saving
          </ChakraButton>
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
};

export default ConfirmExitCreateClips;
