import React, { useContext } from 'react';
import { SUPPORTED_DSPS } from './supported-dsps';
import last from 'lodash/last';
import { DateTime } from 'luxon';
import { DASHED_8601 } from './LUXON_CONSTANTS';
import METRICS_PERIOD_INTERVALS from '../constants/METRICS_PERIOD_INTERVALS.gen.json';

export const AnalyticsContext = React.createContext();
export const useAnalyticsContext = () => useContext(AnalyticsContext);

function getBaseDate(dataSubset) {
  const yesterday = DateTime.now().minus({ days: 1 }).toFormat(DASHED_8601);
  if (window.location.pathname.includes('artists')) {
    return yesterday;
  }

  return last(dataSubset)?.date || yesterday;
}

class ImmutableAnalyticsStateContainer {
  dataSubset = [];
  prevPeriodDataSubset = [];
  totalStreams = 0;
  prevTotalStreams = 0;
  selectedDsps = SUPPORTED_DSPS;
  dspMetadata = {};
  streamPercentageChange = null;
  rollups = null;
  decorativeText = `Stats`;
  currentMetricsInterval = METRICS_PERIOD_INTERVALS.LAST_7_Days;
  dateRangeString;
  now;

  constructor(obj) {
    if (obj) {
      Object.keys(obj).forEach(key => {
        this[key] = obj[key];
      });
    }
    if (!obj?.now) {
      this.now = DateTime.now();
    }
  }

  clone() {
    return new this.constructor(this);
  }

  update(obj) {
    Object.keys(obj).forEach(key => {
      this[key] = obj[key];
    });
    return this.clone();
  }

  setSelectedDsps(value) {
    this.selectedDsps = value;
    return this.clone();
  }

  get streams() {
    return {
      totalStreams: this.totalStreams,
      prevTotalStreams: this.prevTotalStreams,
    };
  }

  getDateRangeString() {
    const startDate = this.now.minus({ days: this.currentMetricsInterval.interval });
    const endDate = this.now.minus({ days: 1 });
    const areDifferentMonths = startDate.month !== endDate.month;
    return `${startDate.toFormat('MMM d')}–${endDate.toFormat(`${areDifferentMonths ? 'MMM ' : ''}d`)}`;
  }

  get dsps() {
    return {
      selectedDsps: this.selectedDsps,
      dspMetadata: this.dspMetadata,
      handleDspFilter: dspName => {
        if (this.selectedDsps.includes(dspName)) {
          return this.setSelectedDsps(this.selectedDsps.filter(dsp => dsp !== dspName));
        } else {
          return this.setSelectedDsps([...this.selectedDsps, dspName]);
        }
      },
    };
  }
}

export default function AnalyticsContextProvider({ children }) {
  const [analyticsState, setAnalyticsState] = React.useState(new ImmutableAnalyticsStateContainer());

  const updateAnalyticsState = obj => {
    setAnalyticsState(prevState => {
      return prevState.update(obj);
    });
  };

  return (
    <AnalyticsContext.Provider
      value={{
        ...analyticsState,
        updateAnalyticsState,
        dateRangeString: analyticsState.getDateRangeString(),
        dsps: analyticsState.dsps,
        streams: analyticsState.streams,
      }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
