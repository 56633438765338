import React, { useState } from 'react';
import {
  Box,
  Button as ChakraButton,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  useBreakpointValue,
  useDisclosure,
  chakra,
  useToast,
  Center,
  Spinner,
  useTheme,
} from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTable, useSortBy } from 'react-table';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { RiArrowUpLine, RiArrowDownLine } from 'react-icons/ri';

import TablePaginationFooter from 'components/Table/table-pagination-footer';
import getFormValues from 'utils/get-form-values';
import RoleSelector from './role-selector';
import ConfirmationModal from 'components/Modal/confirmation-modal';
import FieldLabel from 'components/FieldLabel/field-label';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import * as API from 'utils/API/API';
import Input from 'components/Input/input';
import { labelRoles } from 'constants/roles';

const Group = API.group();
const User = API.user();

const TableRow = ({ groupId, teamMember, isCollapsed, currentUser }) => {
  const toast = useToast();
  const removeMemberModal = useDisclosure();
  const editMemberModal = useDisclosure();
  const removeMemberMutation = useMutation(() => Group.removeUser(groupId, teamMember.original.user_user_id));
  const resendInviteMutation = useMutation(() => Group.resendInvite(groupId, teamMember.original.email), {
    onSuccess: () => {
      toast({
        title: `Resent invite email to ${teamMember.original.email}`,
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: `Invite email failed to send, reach out to support if the problem persists.`,
        status: 'error',
      });
    },
  });
  const editMemberMutation = useMutation(role => Group.updateUser(groupId, teamMember.original.user_user_id, role));
  const queryClient = useQueryClient();

  const resendInvite = () => {
    resendInviteMutation.mutateAsync();
  };

  const onRemoveMember = async () => {
    await removeMemberMutation.mutateAsync();
    await queryClient.invalidateQueries(['teamMembers', Number(groupId)]);
    removeMemberModal.onClose();
  };

  const onEditMemeber = async event => {
    event.preventDefault();
    const formData = getFormValues(event);
    await editMemberMutation.mutateAsync(formData.role);
    await queryClient.invalidateQueries(['teamMembers', Number(groupId)]);
    editMemberModal.onClose();
  };

  const isLabelAdmin = teamMember.original.role === labelRoles.get('admin');
  const isSelf = teamMember.original.email === currentUser.email;

  return (
    <>
      <Tr key={teamMember.original.name} {...teamMember.getRowProps()} borderColor={'black20'}>
        {teamMember.cells.map((cell, index) => (
          <Td
            key={`${index}-cell`}
            isNumeric={cell.column.isNumeric}
            {...cell.getCellProps({
              color: cell.column.id === 'name' ? 'black100' : 'inherit',
              textAlign: cell.column.id === 'menu' ? 'center' : 'inherit',
            })}>
            <Box
              display="flex"
              alignItems={cell.column.id === 'email' ? 'center' : 'flex-start'}
              justifyContent={cell.column.id === 'email' ? 'flex-start' : 'center'}
              flexDirection={cell.column.id === 'email' ? 'row' : 'column'}
              minH="8">
              {cell.render('Cell')}
              {cell.column.id === 'name' && isCollapsed && (
                <Box
                  color="gray.500"
                  maxW={{
                    base: '140px',
                    xs: '160px',
                    sm: '200px',
                    md: 'inherit',
                  }}
                  whiteSpace="nowrap"
                  overflowX="hidden"
                  textOverflow="ellipsis"
                  title={teamMember.original.email}>
                  {teamMember.original.email}
                </Box>
              )}
              {cell.column.id === 'email' && (
                <Box color="black50" display="inline" ml="1">
                  {teamMember.original.accepted_terms_at ? '' : '(Pending)'}
                </Box>
              )}
              {cell.column.id === 'menu' && (
                <Menu>
                  <MenuButton
                    as={ChakraButton}
                    variant="outline"
                    size="sm"
                    borderColor="black50"
                    width="fit-content"
                    alignSelf="center"
                    visibility={isLabelAdmin && teamMember.original.accepted_terms_at ? 'hidden' : 'visible'}>
                    <HiOutlineDotsHorizontal />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={editMemberModal.onOpen}>Edit User</MenuItem>
                    {teamMember.original.accepted_terms_at && <MenuItem onClick={resendInvite}>Resend Invite</MenuItem>}
                    <MenuItem onClick={removeMemberModal.onOpen} isDisabled={isLabelAdmin || isSelf}>
                      Remove User
                    </MenuItem>
                  </MenuList>
                </Menu>
              )}
            </Box>
          </Td>
        ))}
      </Tr>
      <ConfirmationModal
        headerText="Remove user?"
        body={`Are you sure you want to remove ${teamMember.original.name} (${teamMember.original.email})? This action cannot be
            undone.`}
        confirmText="Remove user"
        disclosure={removeMemberModal}
        onSubmit={onRemoveMember}
      />
      <ConfirmationModal
        headerText="Edit team member"
        body={
          <Stack mt="6" spacing="8" as="form" id="editMember" onSubmit={onEditMemeber}>
            <Box>
              <FieldLabel light text="Name" />
              <Box color="black100">{teamMember.original.name}</Box>
            </Box>
            <Box>
              <FieldLabel light text="Email Address" />
              <Box color="black100">{teamMember.original.email}</Box>
            </Box>
            <Box>
              <Input variant="light" title="Role">
                <RoleSelector variant="light" defaultValue={teamMember.original.role} />
              </Input>
            </Box>
          </Stack>
        }
        confirmText="Save Edits"
        testCode="save-edit-confirm-button"
        confirmButtonProps={{
          form: 'editMember',
          style: {},
        }}
        disclosure={editMemberModal}
      />
    </>
  );
};

const TEAM_MEMBERS_PAGE_SIZE = 50;

export default function TeamTable({ groupId }) {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const userQuery = useQuery(['user'], () => User.getMe());
  const teamMembersQuery = useQuery(['teamMembers', page, groupId], () => Group.users(groupId, { page }), {
    enabled: !!groupId,
  });

  const isCollapsed = useBreakpointValue({
    base: true,
    xs: true,
    sm: false,
  });

  const columns = React.useMemo(
    () => [
      {
        Header: `Name${isCollapsed ? ' / Email' : ''}`,
        accessor: 'name',
      },
      {
        Header: 'Email Address',
        accessor: 'email',
      },
      {
        Header: 'Role',
        accessor: 'role',
      },
      {
        Header: '',
        accessor: 'menu',
        disableSortBy: true,
      },
    ],
    [isCollapsed]
  );

  const { data, totalPages } = React.useMemo(() => {
    const { data: queryData = { total: 1, results: [] } } = teamMembersQuery;
    const { total, results } = queryData;
    const totalPages = Math.max(Math.ceil(total / TEAM_MEMBERS_PAGE_SIZE), 1);

    return { data: results, totalPages };
  }, [teamMembersQuery.data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, toggleHideColumn } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'name',
            desc: false,
          },
        ],
      },
    },
    useSortBy
  );

  // hide the email column and stack it with the name column on small sizes
  React.useEffect(() => {
    if (isCollapsed) {
      toggleHideColumn('email', true);
    } else {
      toggleHideColumn('email', false);
    }
  }, [isCollapsed, toggleHideColumn]);

  // wait until the role can be checked as an admin before showing the menu
  const userRole = userQuery?.data?.currentGroup?.role;
  React.useEffect(() => {
    if (userRole === 'Label Admin') {
      toggleHideColumn('menu', false);
    }
  }, [userRole, toggleHideColumn, isCollapsed]);

  const onNextPage = React.useMemo(() => () => setPage(p => p + 1), []);
  const onPrevPage = React.useMemo(() => () => setPage(p => p - 1), []);

  const isNextPageDisabled = React.useMemo(
    () => page + 1 >= totalPages || teamMembersQuery.isLoading || teamMembersQuery.isRefetching,
    [page, totalPages, teamMembersQuery.isLoading, teamMembersQuery.isRefetching]
  );
  const isPrevPageDisabled = React.useMemo(
    () => page <= 0 || teamMembersQuery.isLoading || teamMembersQuery.isRefetching,
    [page, teamMembersQuery.isLoading, teamMembersQuery.isRefetching]
  );

  return (
    <SkeletonLoader status={teamMembersQuery.status} skeletonProps={{ height: '240px', width: '100%' }}>
      <Box overflowX="auto">
        <Table size="sm" variant="light" {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, groupIndex) => (
              <Tr key={`${groupIndex}-headergroup`} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, headerIndex) => (
                  <Th
                    key={`${groupIndex}-${headerIndex}-tableheader`}
                    isNumeric={column.isNumeric}
                    {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <Box style={{ color: 'white' }} display="inline">
                      {column.isSorted ? (
                        <chakra.span pr="3" position="relative" top="1px">
                          {column.isSortedDesc ? (
                            <Icon as={RiArrowUpLine} aria-label="sorted descending" />
                          ) : (
                            <Icon as={RiArrowDownLine} aria-label="sorted ascending" />
                          )}
                        </chakra.span>
                      ) : null}
                      {column.render('Header')}
                    </Box>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(teamMember => {
              prepareRow(teamMember);
              return (
                <TableRow
                  key={`team-member-${teamMember.original.user_user_id}`}
                  groupId={groupId}
                  teamMember={teamMember}
                  isCollapsed={isCollapsed}
                  currentUser={userQuery?.data}
                />
              );
            })}
          </Tbody>
        </Table>
        {teamMembersQuery.isRefetching && (
          <Box
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              right: 0,
              top: 0,
              backgroundColor: theme.colors.brand.layer4,
              opacity: '25%',
            }}>
            <Center h="full">
              <Spinner color="black" size="lg" />
            </Center>
          </Box>
        )}
      </Box>
      <TablePaginationFooter
        onPrevPage={onPrevPage}
        isPrevPageDisabled={isPrevPageDisabled}
        page={page}
        totalPages={totalPages}
        onNextPage={onNextPage}
        isNextPageDisabled={isNextPageDisabled}
      />
    </SkeletonLoader>
  );
}
