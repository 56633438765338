import React from 'react';
import { withTheme } from '@emotion/react/macro';
import Select from 'react-select';
import MenuListActionButton from './menu-list-action-button';
import { searchSelectStyles } from './search-select.styles';

//TECH DEBT Chakrafy this component. (it is in cosmos so it would not be considered deprecated yet.)
const SelectMultiValue = props => (props.index === 0 ? <div>{`Roles (${props?.value?.length || '0'})`}</div> : null);

const SearchSelect = ({
  theme,
  onChange,
  options,
  value,
  isMulti,
  isDisabled,
  onRowActionButtonClick,
  rowActionButtonText,
  ...props
}) => {
  const components = {};
  if (isMulti) {
    components.MultiValue = props => SelectMultiValue({ ...props, value });
  }

  if (onRowActionButtonClick && rowActionButtonText) {
    components.MenuList = props => MenuListActionButton({ ...props, onRowActionButtonClick, rowActionButtonText });
  }

  const formattedOptions = options.map(o => ({
    ...o,
    isDisabled: !isMulti ? value.label === o.label : false,
  }));

  return (
    <Select
      components={components}
      onChange={onChange}
      options={formattedOptions}
      placeholder="Search..."
      styles={searchSelectStyles(theme)}
      value={value}
      isMulti={isMulti}
      hideSelectedOptions={false}
      closeMenuOnSelect={!isMulti}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

export default withTheme(SearchSelect);
