import { useMemo } from 'react';
import { convertBufferIntoImgUrl } from 'utils/helpers';
import * as API from 'utils/API/API';
import FallbackCoverArt from 'assets/fallback-cover-art.png';
import { useQuery } from 'react-query';

const Release = API.release();

export function useReleaseArtwork(releaseId, dimensions) {
  const releaseArtworkQuery = useQuery(
    [`releaseArtwork`, releaseId],
    () => Release.defaultArtwork(releaseId, dimensions),
    { enabled: !!releaseId, staleTime: Infinity }
  );

  const imgUrl = useMemo(() => {
    //isSuccess can be true and there can be no data.
    if (releaseArtworkQuery.isSuccess && releaseArtworkQuery.data) {
      return convertBufferIntoImgUrl(releaseArtworkQuery.data);
    }
    return FallbackCoverArt;
  }, [releaseArtworkQuery.isSuccess, releaseArtworkQuery.data]);

  return imgUrl;
}
