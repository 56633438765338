import * as React from 'react';
import StyledSelect from 'components/StyledSelect/styled-select';
import { labelRoles } from 'constants/roles';
import { useTheme } from '@chakra-ui/react';

export default function RoleSelector({ ...props }) {
  const theme = useTheme();
  const options = [
    {
      value: '',
      label: 'Select...',
      disabled: true,
    },
    {
      value: labelRoles.get('member'),
      label: 'Label Member (Label members can manage releases and add payees)',
    },
    {
      value: labelRoles.get('admin'),
      label: 'Label Admin (All member privileges, plus view statements)',
    },
  ];
  return (
    <StyledSelect
      id="role"
      name="role"
      variant="dark"
      border={`1px solid ${theme.colors.black100} `}
      required={true}
      {...props}>
      {options.map(o => (
        <option key={o.value} value={o.value} disabled={o.disabled}>
          {o.label}
        </option>
      ))}
    </StyledSelect>
  );
}
