import React from 'react';
import { Stack, Image, Box, Td } from '@chakra-ui/react';

export default function ImageCell({ rowData, ...props }) {
  const { img_url, title } = rowData;
  const boxWidth = '3rem';

  return (
    <Td {...props}>
      <Stack display="inline-flex" direction="row" align={'center'} fontSize={'1.2rem'}>
        <Image
          alt={`${title} cover art`}
          boxSize={boxWidth}
          objectFit="cover"
          src={img_url}
          borderRadius=".4rem"
          fallback={
            <Box borderRadius=".4rem" w={boxWidth} h={boxWidth} style={{ backgroundColor: 'gray' }}>
              &nbsp;
            </Box>
          }
        />
        <Box isTruncated data-image-cell={'title'} maxW={500}>
          {title}
        </Box>
      </Stack>
    </Td>
  );
}
