import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import FieldLabel from 'components/FieldLabel/field-label';
import MultiCheckboxes from './multi-checkboxes';
import { useUserTierChecks } from '../../hooks/authorization-hooks';
const YOUTUBE = 'youtube';
const YOUTUBE_FP = 'youtubeFP';

function TakedownModal({ onClose, isOpen, issueTakedown, dsps, checked, setChecked, isTakingDown, requestSent }) {
  const { isExecutiveTier } = useUserTierChecks();
  const [hasYoutube, setHasYoutube] = useState(false);

  useEffect(() => {
    if (isExecutiveTier) return;

    const hasyouTubeFP = checked.includes(YOUTUBE_FP);
    const hasYoutubeA = checked.includes(YOUTUBE);

    //if either is checked and hasYoutube is true that means a user attemted to uncheck one of the two, so both need to be unchecked.
    if ((hasyouTubeFP || hasYoutubeA) && !(hasyouTubeFP && hasYoutubeA) && hasYoutube) {
      setChecked([...checked.filter(dsp => dsp !== YOUTUBE_FP && dsp !== YOUTUBE)]);
      return setHasYoutube(false);
    }

    //otherwise if either or are checked and both are not checked and has youtube is false it means one was checked so both need to be as well.
    if ((hasyouTubeFP || hasYoutubeA) && !(hasyouTubeFP && hasYoutubeA)) {
      //It is possible that the list didn't include both for some reason.
      //This checks to make sure that it is in the list before attempting to add
      //either of the two options to checked
      const newChecked = [...checked];
      if (dsps.includes(YOUTUBE) && !newChecked.includes(YOUTUBE)) newChecked.push(YOUTUBE);
      if (dsps.includes(YOUTUBE_FP) && !newChecked.includes(YOUTUBE_FP)) newChecked.push(YOUTUBE_FP);

      setChecked(newChecked);
      setHasYoutube(true);
    }
  }, [checked, setChecked, dsps]);

  const disableTakedownButton = isTakingDown || checked.length <= 0;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Takedown Release</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!requestSent ? (
            <Box id="issueTakedown">
              <FieldLabel
                text="Platforms"
                light={true}
                tooltip="Check the Digital Service Providers you wish to takedown from"
              />
              <MultiCheckboxes options={dsps} setChecked={setChecked} checked={checked} disabled={isTakingDown} />
            </Box>
          ) : (
            <Box>
              Your takedown was submitted to our team for review. You will receive an email once the takedown request
              has been received by all the DSPs requested (within 48 hours).
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Button text="Cancel" onClick={onClose} disabled={isTakingDown}>
            {requestSent ? 'Close' : 'Cancel'}
          </Button>
          {!requestSent && (
            <Button onClick={issueTakedown} disabled={disableTakedownButton}>
              Issue Takedown
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default TakedownModal;
