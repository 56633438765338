import React, { useState, useEffect, useCallback } from 'react';
import strings from './strings';
import HorizontalList from '../../../../components/horizontal-list';
import Button from '../../../../components/Button/button';
import styled from '@emotion/styled/macro';
import TrackList from './track-list';
import { useModals } from '../../../../utils/useModals';
import { useForm } from 'react-hook-form';
import { buildStatus } from '../../../../utils/helpers';
import { API_STATUS_KEYS } from '../../../../constants/constants';
import { useReleaseForm } from '../../../../utils/use-release-form';
import { useUserTierChecks } from '../../../../hooks/authorization-hooks';
import { useGetContributorsList } from '../../../../data-client/track-parties';

const ContentContainer = styled.section`
  min-height: 80px;
  border-radius: 8px;
  background: #2d2a38;
  box-shadow: 4px 4px 15px rgba(13, 13, 13, 0.23);
  color: #eee6db;
  text-align: center;
  padding: ${({ theme, vertical = 7, horizontal = 0 }) => `${theme.space[vertical]} ${theme.space[horizontal]}`};
`;

const VIDEO_TYPE = 'video';
const { NEW_TRACK, EXISTING_TRACK, NEW_TRACK_VIDEO, EXISTING_TRACK_VIDEO } = strings;

function useTrackManagerButtonCopy(hasFile, tracklistLength) {
  const { canReleaseVideo } = useUserTierChecks();

  if ((!hasFile || !tracklistLength) && canReleaseVideo) {
    return { newTrackText: NEW_TRACK_VIDEO, existingTrackText: EXISTING_TRACK_VIDEO };
  }

  return { newTrackText: NEW_TRACK, existingTrackText: EXISTING_TRACK };
}

const determineIsVideo = type => type?.toLowerCase?.()?.includes(VIDEO_TYPE);

function useUploadType(resourceType) {
  const [fileType, setFileType] = useState(resourceType);
  const [isVideoType, setIsVideoType] = useState(determineIsVideo(resourceType));

  useEffect(() => {
    setIsVideoType(determineIsVideo(fileType) || determineIsVideo(resourceType));
    setFileType(fileType || resourceType);
  }, [fileType, resourceType]);

  return {
    isVideoType,
    fileType,
    setFileType,
  };
}

export default function TrackListManager({
  tracks = [],
  saveTrackList,
  onNewTrack,
  handleTrackReorder,
  updateDataStore,
  handleRemoveNewTrackStub,
  loading,
  hasBeenReleased,
}) {
  const [trackLoadStatus, setTrackLoadStatus] = useState(buildStatus(null));
  const formMethods = useForm();
  const { showModal, updateModalState, hideModal } = useModals();

  const { release, selectedSet, attachTrackToRelease } = useReleaseForm();

  const {
    data: allContributors,
    refetch: refetchContributors,
    status: contributorsStatus,
  } = useGetContributorsList(release);

  const { isVideoType, fileType, setFileType } = useUploadType(tracks[0]?.resourceType);

  const onContributorCreated = useCallback(() => {
    refetchContributors();
  }, [refetchContributors]);

  const addByISRC = async data => {
    if (data?.value && data.value.id) {
      if (release.sets[0].tracks.length > 0 && data.value.resource_type === 'Video') {
        updateModalState('searchISRC', {
          error: 'Music videos cannot be released alongside audio tracks.',
        });
        return;
      }

      if (data.value.resource_type === 'Clip') {
        updateModalState('searchISRC', {
          error: 'Clip tracks cannot be added to this release.',
        });
        return;
      }

      if (release.sets[0].tracks.find(track => track.id === parseInt(data.value.id))) {
        updateModalState('searchISRC', {
          error: 'This track is already attached to this release',
        });
        return;
      }

      if (release.sets[0].tracks.find(track => track.isrc_code === data.value.isrc_code)) {
        updateModalState('searchISRC', {
          error: 'A track with this isrc is already attached to this release.',
        });
        return;
      }

      setTrackLoadStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
      await attachTrackToRelease(data.value.id, release);
      setTrackLoadStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
      hideModal('searchISRC');
      window.location.reload();
    } else {
      updateModalState('searchISRC', { error: 'Something went wrong' });
    }
  };

  useEffect(() => {
    updateModalState('searchISRC', {
      formMethods,
      onSelectItem: addByISRC,
      status: trackLoadStatus,
      error: null,
    });
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release, selectedSet, trackLoadStatus]);

  const TrackManagerButton = ({ text, onClick }) => <Button tertiary text={text} onClick={onClick} />;
  const { newTrackText, existingTrackText } = useTrackManagerButtonCopy(!!fileType, tracks.length);

  const contributorsList = allContributors?.map(c => ({
    label: c.full_name,
    value: c.id,
    ...c,
  }));

  return (
    <div>
      {tracks.length > 0 && (
        <TrackList
          tracks={tracks}
          saveTrackList={saveTrackList}
          handleTrackReorder={handleTrackReorder}
          updateDataStore={updateDataStore}
          handleRemoveNewTrackStub={handleRemoveNewTrackStub}
          contributorsList={contributorsList}
          loading={loading && contributorsStatus === 'loading'}
          hasBeenReleased={hasBeenReleased}
          isVideoType={isVideoType}
          setFileType={setFileType}
          onContributorCreated={onContributorCreated}
        />
      )}
      {!tracks.length && <ContentContainer>{strings.NO_TRACKS}</ContentContainer>}
      <br />
      {!hasBeenReleased && (!isVideoType || !tracks.length) && (
        <HorizontalList
          Items={[
            <TrackManagerButton key={newTrackText.asKey} text={newTrackText} onClick={onNewTrack} />,
            <TrackManagerButton
              key={existingTrackText.asKey}
              text={existingTrackText}
              onClick={() => showModal('searchISRC')}
            />,
          ]}
        />
      )}
    </div>
  );
}
