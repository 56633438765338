export const ARTWORK_SIZE = '720x720';

export const SUPPORT_LINK = 'https://support.venice.tech/distribution/';

export const USER_ROLES = {
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  LABEL_MEMBER: 'LABEL_MEMBER',
  BASIC_USER: 'BASIC_USER',
};

export const SEARCH_RESULT_TYPES = {
  ALL: 'ALL',
  RELEASES: 'RELEASES',
  ARTIST_PROFILES: 'ARTIST_PROFILES',
  TRACKS: 'TRACKS',
};

export const SORT_DIRECTIONS = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const API_STATUS_KEYS = {
  IN_PROGRESS: 'inProgress',
  COMPLETE: 'complete',
  ERROR: 'error',
};

export const APP_STATUSES = {
  NOT_FOUND: 'NOT_FOUND',
};

export const MEDIA_TYPES = {
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
};

// These are prices per single track. Album prices are calculated based on constants in node/src/classes/distributor/apple/apple-const.js
export const PRICE_TIERS = {
  FRONT: 1.29,
  MID: 0.99,
  BACK: 0.69,
  VIDEO: 1.99,
};

export const PAYMENTS_USER_TYPES = {
  LABEL: 'LABEL',
  PAYEE: 'PAYEE',
};

export const EMPTY_RELEASE_TITLE = ' ';
