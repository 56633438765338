export default (image, size) => {
  if (size === 'large') {
    return image?.large;
  }
  if (size === 'medium') {
    return image?.medium || image?.large;
  }
  if (size === 'small') {
    return image?.small || image?.medium || image?.large;
  }
};
