import React from 'react';
import ToggableBanner from './toggable-banner';
import { HStack, Text, CloseButton, Link } from '@chakra-ui/react';
import Icon from '../../components/Icon/icon';

export default function SpotifyForArtists({ showBanner, onCloseBanner }) {
  return (
    <ToggableBanner showBanner={showBanner} bannerStyleProps={{ color: 'cream.100' }}>
      <HStack justifyContent="space-between">
        <HStack>
          <Icon type="infoFill" />
          <Text pl=".5rem" fontFamily="Roboto" fontSize=".875rem" lineHeight="1.25rem">
            Get the most out of Spotify. Check out{' '}
            <Link fontWeight="800" href="/settings#special_access" color="cream.100">
              Spotify for Artists.
            </Link>
          </Text>
        </HStack>
        <CloseButton size="sm" onClick={onCloseBanner} />
      </HStack>
    </ToggableBanner>
  );
}
