import React, { useContext, useEffect } from 'react';

import { ARTWORK_SIZE } from 'constants/constants';
import ImageBlock from 'features/ImageBlock/image-block';
import { useGlobalData } from 'utils/global-data';
import useCardFlow from 'utils/use-card-flow';
import { useReleaseForm } from 'utils/use-release-form';
import { ButtonCol } from 'styles';
import { FormProvider, useForm } from 'react-hook-form';
import Button from 'components/Button/button';
import { Col, Row } from 'react-styled-flexboxgrid';
import FieldLabel from 'components/FieldLabel/field-label';
import ButtonToggle from 'components/ButtonToggle/button-toggle';
import { CardContext } from '../card-context';

import { CardLoadingWrapper, CardContentWrapper, CardHeaderBlock, ReleaseBlock } from './card-style';

import { CARDS } from '../card';
import HEAP from '../../../constants/HEAP.gen.json';

const PriceTierRelease = () => {
  const CARD_ID = CARDS.PriceTierRelease;
  const {
    id,
    loadingAny,
    getCardData,
    getCardDataById,
    handleResetSaveAndPreview,
    afterSaveActions,
    NAV_DIRECTIONS,
    loadingSaveRelease,
    isFlowAnimating,
    CardLoadingSpinner,
  } = useContext(CardContext);

  const { release, fileUrls } = useGlobalData();

  const { cardData, updateCard_Form, isReviewMode, _passthrough } = useCardFlow();

  const { selectedSet } = useReleaseForm();

  const releaseImageUrls = fileUrls['release'] || {};

  const formMethods_PriceTierRelease = useForm();
  const formValues_PriceTierRelease = formMethods_PriceTierRelease.getValues({
    nest: true,
  });
  const errors_PriceTierRelease = formMethods_PriceTierRelease.errors;

  const otherCardData_PriceTierRelease = {
    ReleaseDetails: getCardDataById('ReleaseDetails', 'formData'),
    Tracklist: getCardDataById('Tracklist', 'tracklistData') || [],
  };

  const releaseType = otherCardData_PriceTierRelease.ReleaseDetails?._meta?.releaseType;
  const isVideoSingle = releaseType === 'VideoSingle';
  const isSingle = releaseType === 'Single';
  const isEP = releaseType === 'EP';
  const isAlbum = releaseType === 'Album';

  const isEndOfFlow_PriceTierRelease = isVideoSingle && otherCardData_PriceTierRelease.Tracklist?.length === 1;

  useEffect(() => {
    if (id === CARD_ID) {
      formMethods_PriceTierRelease.reset(getCardData('formData'));
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData]);

  useEffect(() => {
    if (id === CARD_ID && _passthrough.induceSave) {
      formMethods_PriceTierRelease.handleSubmit(data => save_PriceTierRelease(data, _passthrough.inducePreview))();
      handleResetSaveAndPreview();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData, _passthrough]);

  const save_PriceTierRelease = (data, forcePreview = false, navigation = null) => {
    const saveData = { ...formValues_PriceTierRelease, ...data };

    updateCard_Form(id, saveData);

    const showPreview =
      navigation !== NAV_DIRECTIONS.BACK && (isEndOfFlow_PriceTierRelease || isReviewMode || forcePreview);
    afterSaveActions(showPreview, navigation);
  };

  let priceTierOptions;

  if (isVideoSingle || isSingle) {
    priceTierOptions = [{ label: '$1.99', value: 'Front' }];
  } else if (isEP) {
    priceTierOptions = [{ label: '$3.99', value: 'Front' }];
  } else if (isAlbum) {
    priceTierOptions = [
      { label: 'Front: $10.99', value: 'Front' },
      { label: 'Mid: $8.99', value: 'Mid' },
      { label: 'Back: $7.99', value: 'Back' },
    ];
  } else {
    priceTierOptions = [
      { label: 'Front', value: 'Front' },
      { label: 'Mid', value: 'Mid' },
      { label: 'Back', value: 'Back' },
    ];
  }

  return (
    <CardContentWrapper omnishareas>
      <CardLoadingWrapper loading={loadingAny.toString()} omnishareas>
        <FormProvider {...formMethods_PriceTierRelease}>
          <CardHeaderBlock omnishareas>
            <Row>
              <Col xs={6}>
                <h3>Release Price Tier for Downloads</h3>
              </Col>
              <ButtonCol xs={6}>
                {!isReviewMode && (
                  <Button
                    text="Back"
                    icon="chevronLeft"
                    onClick={formMethods_PriceTierRelease.handleSubmit(data =>
                      save_PriceTierRelease(data, false, NAV_DIRECTIONS.BACK)
                    )}
                    disabled={loadingSaveRelease || loadingAny || isFlowAnimating}
                    tertiary
                    heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TIERRELEASE_BUTTON_BACK}
                  />
                )}
                <Button
                  text={isReviewMode || isEndOfFlow_PriceTierRelease ? 'Save & Preview' : 'Next'}
                  rightIcon={isReviewMode || isEndOfFlow_PriceTierRelease ? null : 'chevronRight'}
                  loading={loadingSaveRelease || loadingAny}
                  onClick={formMethods_PriceTierRelease.handleSubmit(data =>
                    save_PriceTierRelease(data, false, NAV_DIRECTIONS.FORWARD)
                  )}
                  disabled={isFlowAnimating}
                  heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TIERRELEASE_BUTTON_NEXT}
                />
              </ButtonCol>
            </Row>
            <Row>
              <Col xs={4}>
                <p>
                  {`Set your desired album pricing for album downloads on iTunes and Amazon Music. Multiple tier pricing allows you to choose different prices for your album depending on the song's popularity. Album pricing may vary based on the total number of tracks. The album price cannot exceed the total price of the combined tracks. DSPs establish final retail prices for price tiers in each territory at their discretion. `}
                </p>
              </Col>
            </Row>
          </CardHeaderBlock>
          <Row>
            <Col xs={12}>
              <FieldLabel text="Project" />
              <br />
              <Row>
                <Col xs={12} md={6}>
                  <div
                    style={{
                      alignItems: 'center',
                      paddingRight: '1rem',
                      width: '100%',
                    }}>
                    <ReleaseBlock>
                      <div>
                        <ImageBlock
                          size={3}
                          src={
                            release?.id &&
                            releaseImageUrls &&
                            releaseImageUrls[release.id] &&
                            releaseImageUrls[release.id][selectedSet?.id] &&
                            releaseImageUrls[release.id][selectedSet?.id][0][ARTWORK_SIZE]
                          }
                        />
                      </div>
                      <div>
                        <strong>{release ? release.title : ''}</strong>
                        <small>{release ? release.display_artist_name : ''}</small>
                      </div>
                    </ReleaseBlock>

                    <div style={{ maxHeight: '34px', marginTop: '1.5rem', maxWidth: '23.25rem' }}>
                      <ButtonToggle
                        name={`price_tier`}
                        options={priceTierOptions}
                        error={errors_PriceTierRelease['price_tier']}
                        setValue={formMethods_PriceTierRelease.setValue}
                        disabled={loadingSaveRelease || loadingAny}
                        ref={formMethods_PriceTierRelease.register({
                          required: true,
                        })}
                        defaultValue={isVideoSingle ? 'Front' : undefined}
                        heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_TIERRELEASE_BUTTON_TOGGLE}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </FormProvider>
      </CardLoadingWrapper>
      <CardLoadingSpinner loading={loadingAny} />
    </CardContentWrapper>
  );
};
export default PriceTierRelease;
