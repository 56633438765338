import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Flex,
  Image,
  Heading,
  Text,
  Button,
} from '@chakra-ui/react';
import { useCreateMarketingLink } from 'data-client/use-marketing-links';
import { useErrorResponseToast } from 'hooks/use-error-response-toast';

const IMAGE_SIZE_PX = '128px';

export function CreateMarketingLinkModal({
  isOpen,
  onClose,
  type,
  imgUrl,
  title,
  artistName,
  trackId,
  releaseId,
  trackCount,
}) {
  const history = useHistory();
  const { groupId } = useParams();
  const errorResponseToast = useErrorResponseToast();
  const { mutate: createMarketingLinkMutation, isLoading: waitingOnCreate } = useCreateMarketingLink({
    onSuccess: marketingLink => {
      onClose();
      // have to push to the route w/ groupId so location state is maintained
      history.push(`/marketing-links/${groupId}`, { linkfireURL: marketingLink.data.data.linkfire_url });
    },
    onError: () => {
      // mimicking the expected shape of the toast so that we don't show the exact error from the backend
      const errorMessage = 'Error creating link, contact yourfriends@venicemusic.co.';
      errorResponseToast({
        response: {
          data: { title: errorMessage, message: errorMessage },
        },
      });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={waitingOnCreate === false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm Link Creation</ModalHeader>
        <ModalCloseButton disabled={waitingOnCreate} />
        <ModalBody>
          <Box mb="54px">The following {type} will have a link created.</Box>
          <Flex>
            <Box position={'relative'}>
              <Text
                position={'absolute'}
                top="0.8rem"
                right="0.8rem"
                background="white"
                p="2px 8px"
                color="black100"
                borderRadius={'1rem'}>
                {trackCount} {trackCount > 1 ? 'tracks' : 'track'}
              </Text>
              <Image src={imgUrl} minWidth={IMAGE_SIZE_PX} height={IMAGE_SIZE_PX} alt={`${title} cover`} />
            </Box>
            <Box ml="1rem">
              <Heading fontSize={'xlg'}>{title}</Heading>
              <Text mt="0.5rem">{artistName}</Text>
            </Box>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button variant={'transparent'} onClick={onClose} disabled={waitingOnCreate}>
            Cancel
          </Button>
          <Button
            variant={'smallDark'}
            disabled={waitingOnCreate}
            onClick={() => createMarketingLinkMutation({ track_id: trackId, release_id: releaseId })}>
            Create Link
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
