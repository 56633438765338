import React from 'react';
import Box from '../Box/Box';
import { Text, Flex, Icon } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import icons from '../../utils/icons';

const UserMessage = ({ unfurled_text, createdAt, hasTime = false }) => {
  return (
    <Flex lineHeight="1.5rem" fontSize="1rem" gap="0.25rem" maxWidth="48rem" direction="row">
      <Box w="1.25rem" flexShrink="0">
        <Icon
          as={icons.user}
          display="inline"
          color="pink"
          pr="0.25rem"
          w="1.25rem"
          h="1.25rem"
          title="Live Support Agent"></Icon>
      </Box>
      <Box>
        <Text verticalAlign="middle" lineHeight="1.25rem" fontSize="1rem" color="black80" fontWeight="600">
          You
        </Text>
        <Box paddingTop="0.5rem" className="markdown-styles">
          <Text dangerouslySetInnerHTML={{ __html: unfurled_text }}></Text>
        </Box>
        <Box variant="messageUnderBubble">
          {hasTime === true ? `${DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATETIME_SHORT)}` : undefined}
        </Box>
      </Box>
    </Flex>
  );
};

export default UserMessage;
