import React from 'react';
import { Box, Flex, Heading, useTheme } from '@chakra-ui/react';
import { VeniceTip } from 'components/ManageContentBox/manage-content-box';
import Button from '../components/Button/button';
import { Link } from 'react-router-dom';

function ManagePageLayout({ title, description, showTooltip = false, continueTo, children, canContinue, fullWidth }) {
  const theme = useTheme();

  return (
    <Box maxWidth={fullWidth ? '100%' : '48rem'} padding={'1rem'}>
      <Flex
        alignItems={'center'}
        justifyContent={'space-between'}
        textAlign={'left'}
        fontSize={'1rem'}
        fontWeight={'bold'}
        color={theme.colors.black80}
        paddingBottom={'1rem'}>
        <Heading as={'h3'} fontSize={'1.5rem'}>
          {title}
        </Heading>
        {showTooltip && <VeniceTip />}
      </Flex>
      <Box textAlign={'left'} fontSize={'1rem'} fontWeight={'bold'} color={theme.colors.black80}>
        {description && <Box>{description}</Box>}
      </Box>

      {children}

      {continueTo && (
        <Flex justifyContent={'right'} paddingTop={'1rem'}>
          <Button to={continueTo} as={Link} dark={true} text={'Continue'} disabled={!canContinue} />
        </Flex>
      )}
    </Box>
  );
}

export default ManagePageLayout;
