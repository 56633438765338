import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import styled from '@emotion/styled/macro';
import FieldLabel from '../../../../components/FieldLabel/field-label';
import { StyledTextarea } from '../../../../styles';

const TextareaWithHeight = styled(StyledTextarea)`
  height: 100px;
`;

export function TrackFormBlockInput({
  tooltip = '',
  label,
  formName,
  disabled,
  register,
  dataHeap,
  error,
  validate = () => true,
}) {
  return (
    <Box mb="1rem" position="relative">
      <FieldLabel text={label} tooltip={tooltip} />
      <TextareaWithHeight
        error={error}
        name={formName}
        disabled={disabled}
        ref={register({ required: false, validate })}
        data-heap={dataHeap}
      />
      {error?.message ? (
        <Text position="absolute" fontSize={'0.6875rem'} color="error">
          {error.message}
        </Text>
      ) : null}
    </Box>
  );
}
