import { API_STATUS_KEYS } from 'constants/constants';
import React, { createContext } from 'react';
import { useGlobalData } from 'utils/global-data';
import useCardFlow from 'utils/use-card-flow';
import { useReleaseForm } from 'utils/use-release-form';
import CardLoadingOverlay from 'components/loading-containers/card-loading-overlay';

const NAV_DIRECTIONS = {
  BACK: 'BACK',
  FORWARD: 'FORWARD',
};

export const CardContext = createContext();

const CardContextProvider = ({ id, match, history, children }) => {
  const { releaseStatus } = useGlobalData();

  const { gotoNextCard, gotoPrevCard, cardData, leftCardState, rightCardState, onExternalEvent } = useCardFlow();

  const { saveReleaseStatus } = useReleaseForm();

  const loadingAny = releaseStatus.all[API_STATUS_KEYS.IN_PROGRESS] && !releaseStatus.all[API_STATUS_KEYS.COMPLETE];

  const loadingSaveRelease = saveReleaseStatus[API_STATUS_KEYS.IN_PROGRESS];

  const isFlowAnimating = leftCardState.transitioning || rightCardState.transitioning;

  const getCardDataById = (id, attr) => (cardData[id] ? cardData[id][attr] : null);

  const getCardData = attr => {
    return cardData[id] ? cardData[id][attr] : null;
  };

  const handleResetSaveAndPreview = () => {
    onExternalEvent({ name: 'RESET_SAVE_AND_PREVIEW' });
  };

  const handleShowPreview = () => {
    onExternalEvent({ name: 'SHOW_PREVIEW' });
  };

  const handleSubmitError = showPreview => {
    if (showPreview) {
      afterSaveActions(true);
    }
  };

  const afterSaveActions = (showPreview, navigation) => {
    if (showPreview) {
      handleShowPreview();
    } else if (navigation === NAV_DIRECTIONS.BACK) {
      gotoPrevCard();
    } else if (navigation === NAV_DIRECTIONS.FORWARD) {
      gotoNextCard();
    }

    handleResetSaveAndPreview();
  };

  //TODO: the way this is handled is messy
  //explore another option to make how this works
  //more clear
  const CardLoadingSpinner = ({ loading, text, percent }) => {
    return (loading && <CardLoadingOverlay text={text} percent={percent}></CardLoadingOverlay>) || <></>;
  };
  return (
    <CardContext.Provider
      value={{
        id,
        match,
        history,
        loadingAny,
        loadingSaveRelease,
        isFlowAnimating,
        getCardDataById,
        getCardData,
        handleResetSaveAndPreview,
        handleShowPreview,
        handleSubmitError,
        afterSaveActions,
        CardLoadingSpinner,
        NAV_DIRECTIONS,
      }}>
      {children}
    </CardContext.Provider>
  );
};

export default CardContextProvider;
