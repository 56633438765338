import React from 'react';
import Tooltip from '../../components/Tooltip/tooltip';
import EditButton from './edit-button';
import { useReleaseStatusTrackContext } from './release-status-track-context';
import SaveButton from './save-button';
import Box from '../Box/Box';
import Strong from '../Strong/Strong';
import Small from '../Small/Small';
import { RiFlashlightFill as InstantGratIcon } from 'react-icons/ri';
import { useTheme, Flex } from '@chakra-ui/react';

function TrackHeader({ saveSplits, ...props }) {
  const theme = useTheme();
  const { trackData, splitsData, viewConfig } = useReleaseStatusTrackContext();

  return (
    <Flex paddin="0 2rem 0 1rem" width="100%" alignItems="center">
      <Box flexGrow="1">
        <Box marginBottom="0.25rem">
          <Strong marginRight="1.5rem">{trackData?.title || '[No title added]'}</Strong>
          {trackData.instant_grat_date && (
            <Box display="inline-block">
              <Tooltip text="Instant grat track" position="right">
                <InstantGratIcon color={theme.colors.black100} />
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box fontWeight="bold">{trackData?.artists?.length ? trackData.artists[0].full_name : '[No artist added]'}</Box>
      </Box>
      <Flex justifyContent="flex-end" flexWrap="wrap" flexBasis="60%" alignItems="center">
        <Flex flexDirection="column">
          {trackData.isrc_code && (
            <Small
              marginRight="1.5rem"
              display="inline-flex"
              color={theme.colors.black60}
              fontWeight="bold"
              fontSize=".75rem">
              Stereo ISRC: {trackData.isrc_code}
            </Small>
          )}
          {trackData.atmos_isrc_code && (
            <Small
              marginRight="1.5rem"
              display="inline-flex"
              color={theme.colors.black60}
              fontWeight="bold"
              fontSize=".75rem">
              Dolby Atmos ISRC: {trackData.atmos_isrc_code}
            </Small>
          )}
        </Flex>
        {viewConfig.isEditingSplits ? (
          <SaveButton
            onCancel={viewConfig.stopEditingSplits}
            loading={viewConfig.isLoading}
            onSave={saveSplits}
            disabled={!viewConfig.isValid}
            {...props}
          />
        ) : (
          <EditButton
            canEditSplits={viewConfig.canEditSplits}
            isNew={splitsData.some(split => !!split.stakeholder_track_split_id)}
            handleEditSplits={viewConfig.editSplits}
            handleExpandCollapseClicked={viewConfig.toggleExpanded}
            isExpanded={viewConfig.isExpanded}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default TrackHeader;
