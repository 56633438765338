import * as React from 'react';
import { Tab } from '@chakra-ui/react';

export default function CustomTab({ children, testCode, ...props }) {
  return (
    <Tab
      color="black100"
      fontSize="reg"
      borderLeftRadius={{ base: 'none', sm: 'full' }}
      whiteSpace="nowrap"
      paddingX={{ base: '6', sm: '8' }}
      paddingY={{ base: '3', sm: '5' }}
      justifyContent="flex-start"
      _selected={{ bg: 'black10', fontWeight: 'bold' }}
      {...props}
      data-cy={global.APP.RUNTIME === 'production' ? undefined : testCode}>
      {children}
    </Tab>
  );
}
