import React, { useState } from 'react';
import { useToast, useDisclosure, Grid, GridItem, Text } from '@chakra-ui/react';
import MarketingLinkConfirmDeleteModal from './marketing-link-confirm-delete-modal';
import MarketingLinksTableRow from './marketing-links-table-row';
import CopyClipboardToast from 'components/copied-clipboard-toast';
import Pagination from 'features/Pagination/pagination';
import DownloadModal from 'views/MarketingLink/download-modal';
import * as API from 'utils/API';
import { getPresignedUrl } from '../utils/API/marketing-links';
import { useUserTierChecks } from '../hooks/authorization-hooks';

const Payment = API.payment();

const PAGE_SIZE = 15;

export default function MarketingLinksTable({ results, total, onIndex, setIndex, refetchMarketingLinks }) {
  const [deleteId, setDeleteId] = useState(null);
  const toast = useToast();
  const { isOpen: isDeleteOpen, onOpen: onConfirmDelete, onClose: onDeleteClose } = useDisclosure();

  const [isDownloadOpen, setIsDownloadOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState({});

  const { canAccessMarketingAnalytics } = useUserTierChecks();

  //TODO Add delete function (next ticket)
  function handleDeleteClick(id) {
    setDeleteId(id);
    onConfirmDelete();
  }

  function handleCopyToClipboard(url) {
    navigator.clipboard.writeText(url);
    toast({
      position: 'top',
      render: () => <CopyClipboardToast>{`Copied "${url}" to clipboard.`}</CopyClipboardToast>,
    });
  }

  async function onDownloadClick(key) {
    const data = await getPresignedUrl(key);
    setDownloadUrl(data.url);
    setIsDownloadOpen(true);
  }

  async function onDownloadConfirm() {
    await Payment.getCsvFromUrl(downloadUrl);
    setIsDownloadOpen(false);
  }

  function onDownoadClose() {
    setIsDownloadOpen(false);
  }

  const onPrevPageHandler = () => setIndex(onIndex - 1);
  const onNextPageHandler = () => setIndex(onIndex + 1);

  const isPrevPageDisabled = onIndex === 0;
  const isNextPageDisabled = (onIndex + 1) * PAGE_SIZE > total;

  const templateColumns = '0.625fr 2.5fr 2fr 1.5fr 1.25fr 0.75fr';

  const gridConfig = {
    width: '100%',
    templateColumns,
    gap: 4,
    alignItem: 'center',
  };

  return (
    <>
      <Grid
        {...gridConfig}
        letterSpacing="0.125rem"
        marginTop="3rem"
        paddingBottom="0.5rem"
        minWidth="55rem"
        borderBottom="2px solid #999">
        <GridItem />
        <GridItem>
          <Text>RELEASE</Text>
        </GridItem>
        <GridItem>
          <Text>TRACKNAME</Text>
        </GridItem>
        <GridItem>
          <Text>ARTIST</Text>
        </GridItem>
        {/* <Th>Link Url</Th> */}
        <GridItem>
          <Text>FAN EMAILS</Text>
        </GridItem>
        <GridItem>
          <Text>ANALYTICS</Text>
        </GridItem>
      </Grid>
      {results.map(result => (
        <MarketingLinksTableRow
          key={result.id}
          linkData={result}
          handleDeleteClick={handleDeleteClick}
          handleCopyToClipboard={handleCopyToClipboard}
          handleDownloadClick={onDownloadClick}
          gridConfig={gridConfig}
          showAnalytics={canAccessMarketingAnalytics}
          refetchMarketingLinks={refetchMarketingLinks}
          canAccessAnalytics={canAccessMarketingAnalytics}
        />
      ))}
      <GridItem>
        <Pagination
          onPrevPageHandler={onPrevPageHandler}
          onNextPageHandler={onNextPageHandler}
          pageSize={PAGE_SIZE}
          pageIndex={onIndex}
          totalLength={total}
          iconStyle={{ border: 'none', variant: 'ghost' }}
        />
      </GridItem>
      <MarketingLinkConfirmDeleteModal
        onClose={onDeleteClose}
        isOpen={isDeleteOpen}
        deleteId={deleteId}
        refetchMarketingLinks={refetchMarketingLinks}
      />
      <DownloadModal isOpen={isDownloadOpen} onYes={onDownloadConfirm} onNo={onDownoadClose} />
    </>
  );
}
