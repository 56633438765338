// TECH DEBT -- We need to create a new Input component that uses Chakra, colors were updated here to save time

import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import InfoTooltip from '../InfoTooltip/info-tooltip';

const Container = styled.div`
  margin: 0 0 2rem;
  position: relative;
`;
const TooltipWrapper = styled.div`
  display: inline-flex;
  min-width: 1.75rem;
  justify-content: space-between;
`;

const AlertTooltipWrapper = styled(InfoTooltip)`
  i {
    height: calc(1rem - 1px);
  }
`;

const calculateVariant = (props, defaultStyle, darkStyle) => {
  const variant = props?.variant || 'dark';
  return variant === 'dark' ? darkStyle : defaultStyle;
};

const LabelWrapper = styled.label`
  color: ${props => calculateVariant(props, props.theme.colors.black100, props.theme.colors.cream100)};
  font-size: ${props => props.theme.fonts.sizes.xxsm};
  font-weight: ${props => props.theme.fonts.weights.medium};
  letter-spacing: 0.1rem;
  margin-bottom: 0.625rem;
  white-space: nowrap;
`;

function Input({ title, optional, style, tooltip, tooltipComponent, alert, disabled, children, ...props }) {
  const variant = props?.variant || 'dark';

  return (
    <Container style={style} className={props.className}>
      <LabelWrapper variant={variant}>
        {title && <span>{title.toUpperCase()}</span>}
        {!optional && ' *'}
        {tooltipComponent}
        <TooltipWrapper>
          {tooltip && <InfoTooltip text={tooltip} color={props.theme.colors.brand.layer4} type="info" />}
          {alert && <AlertTooltipWrapper text={alert} color={props.theme.colors.negative} type="alert" />}
        </TooltipWrapper>
      </LabelWrapper>
      {React.Children.map(children, child =>
        React.isValidElement(child) ? React.cloneElement(child, { disabled: disabled }) : child
      )}
    </Container>
  );
}

Input.defaultProps = {
  title: null,
  optional: false,
  style: {},
  disabled: false,
};

export default withTheme(Input);
