import { API_STATUS_KEYS, MEDIA_TYPES } from '../constants/constants';
import { DateTime } from 'luxon';

// Layout

const getAbsoluteOffset = element => {
  let top = 0,
    left = 0;

  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return { top, left };
};

// Strings

const pluralize = (str, num) =>
  Math.abs(num) !== 1
    ? str.substring(str.length - 1) === 'y'
      ? `${str.substring(0, str.length - 1)}ies`
      : `${str}s`
    : str;

const generateInitial = name => {
  return name[0].toUpperCase();
};

// DOM

const getCaretPosition = () => {
  if (window.getSelection && window.getSelection().getRangeAt) {
    let range = window.getSelection().getRangeAt(0);
    let selectedObj = window.getSelection();
    let rangeCount = 0;
    let childNodes = selectedObj.anchorNode.parentNode.childNodes;
    for (let i = 0; i < childNodes.length; i++) {
      // DEBT:
      // eslint-disable-next-line eqeqeq
      if (childNodes[i] == selectedObj.anchorNode) {
        break;
      }
      if (childNodes[i].outerHTML) rangeCount += childNodes[i].outerHTML.length;
      // DEBT:
      // eslint-disable-next-line eqeqeq
      else if (childNodes[i].nodeType == 3) {
        rangeCount += childNodes[i].textContent.length;
      }
    }
    return range.startOffset + rangeCount;
  }
  return -1;
};

// Math

const randomNumber = (low, high) => Math.floor(Math.random() * high + low);

const arrAvg = arr => arr.reduce((a, b) => a + b, 0) / arr.length;

// Time

const chopTime = date => (date ? (DateTime.fromISO(date).toUTC().toISO() || 'T').split('T')[1].substring(0, 5) : null);
const chopDate = date => (date ? (DateTime.fromISO(date, { setZone: true }).toISO() || 'T').split('T')[0] : null);
const withZoneTime = date =>
  date ? (DateTime.fromISO(date, { setZone: true }).toISO() || 'T').split('T')[1].substring(0, 5) : null;
const withZoneDate = date => (date ? (DateTime.fromISO(date, { setZone: true }).toISO() || 'T').split('T')[0] : null);
const noZoneTime = date =>
  date ? (DateTime.fromISO(date).toUTC().toISO() || 'T').split('T')[1].substring(0, 5) : null;
const noZoneDate = date => (date ? (DateTime.fromISO(date).toUTC().toISO() || 'T').split('T')[0] : null);
const offsetFromDateWithZone = (date, zone) => {
  const str = DateTime.fromISO(date, { zone }).toString();
  return str.substr(str.length - 6, str.length);
};

// Objects

const getByStringPath = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, ''); // strip a leading dot
  let a = s.split('.');
  for (let i = 0, n = a.length; i < n; ++i) {
    let k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

// Data

const getPayeeName = (payee, labelLabel = '(Label)') => {
  if (!payee?.payment_account?.user && !payee?.payment_account?.group) {
    return '';
  }

  const postfixGroup = labelLabel ? ` ${labelLabel}` : '';
  const type = payee?.payment_account?.user ? 'user' : 'label';

  if (payee.full_name) {
    return type === 'user' ? `${payee?.full_name}` : `${payee?.full_name}${postfixGroup}`;
  } else {
    return type === 'user'
      ? `${payee?.payment_account.user?.name}`
      : `${payee?.payment_account.group?.name}${postfixGroup}`;
  }
};

const getPayeeEmail = payee => {
  if (!payee?.payment_account?.user || payee?.payment_account?.group) {
    return '';
  }

  return payee.payment_account.user?.email;
};

const platformObjectToDSPArray = obj =>
  Object.keys(obj)
    .filter(key => key !== 'apple')
    .map(key => ({ dsp: key, dspId: obj[key] }));

const isTrackSequenceValid = tracks => {
  if (tracks[0]?.data?.sequence !== 1) return false;

  for (let i = 1; i < tracks.length; i++) {
    if (tracks[i]?.data?.sequence !== tracks[i - 1]?.data?.sequence + 1) {
      return false;
    }
  }

  return true;
};

const getMediaTypeFromExtension = extension => {
  if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') return MEDIA_TYPES.IMAGE;
  if (extension === 'wav' || extension === 'flac' || extension === 'mp3') return MEDIA_TYPES.AUDIO;
  if (extension === 'mp4' || extension === 'mov') return MEDIA_TYPES.VIDEO;

  return null;
};

const getResourceTypeFromFile = file => {
  const extensionData = file.name.split('.');
  const extension = (extensionData[extensionData.length - 1] || '').toLowerCase();
  const mediaType = getMediaTypeFromExtension(extension);
  let resourceType;

  switch (mediaType) {
    case MEDIA_TYPES.AUDIO:
      resourceType = 'SoundRecording';
      break;
    case MEDIA_TYPES.VIDEO:
      resourceType = 'Video';
      break;
    default:
      break;
  }

  return resourceType;
};

const getGroupLogo = (fileUrls, id, size = '208x208') => fileUrls?.group?.[id]?.logo?.[size] || null;

const filterDSPsByReleaseType = (dsps, type) => {
  if (!type) return dsps;
  if (!dsps) return [];

  switch (type) {
    case 'VideoSingle':
      return dsps.filter(dsp => dsp.video_support);
    default:
      return dsps.filter(dsp => dsp.sound_recording_support);
  }
};

const getBlobDuration = async blob => {
  const tempVideoEl = document.createElement('video');
  const durationP = new Promise(resolve =>
    tempVideoEl.addEventListener('loadedmetadata', () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null;
          resolve(tempVideoEl.duration);
          tempVideoEl.currentTime = 0;
        };
      }
      // Normal behavior
      else {
        resolve(tempVideoEl.duration);
      }
    })
  );

  tempVideoEl.src = typeof blob === 'string' || blob instanceof String ? blob : window.URL.createObjectURL(blob);

  return durationP;
};

const buildStatus = state => ({
  [API_STATUS_KEYS.IN_PROGRESS]: state === API_STATUS_KEYS.IN_PROGRESS,
  [API_STATUS_KEYS.COMPLETE]:
    state === API_STATUS_KEYS.COMPLETE || (state && state !== API_STATUS_KEYS.IN_PROGRESS) || false,
  [API_STATUS_KEYS.ERROR]: state !== API_STATUS_KEYS.IN_PROGRESS && state !== API_STATUS_KEYS.COMPLETE ? state : null,
});

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const convertBufferIntoImgUrl = arrayBuffer => {
  if (!arrayBuffer) return null;

  const blob = new Blob([arrayBuffer]);
  const url = window.URL.createObjectURL(blob);
  return url;
};

const isUpc = str => /^(\d{12,14})$/.test(str);
const isISRC = str => /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/.test(str);

export {
  isUpc,
  isISRC,
  getAbsoluteOffset,
  getCaretPosition,
  randomNumber,
  pluralize,
  getGroupLogo,
  generateInitial,
  arrAvg,
  getBlobDuration,
  getPayeeName,
  getPayeeEmail,
  filterDSPsByReleaseType,
  platformObjectToDSPArray,
  buildStatus,
  sleep,
  withZoneTime,
  withZoneDate,
  noZoneDate,
  noZoneTime,
  chopTime,
  chopDate,
  offsetFromDateWithZone,
  getMediaTypeFromExtension,
  getResourceTypeFromFile,
  isTrackSequenceValid,
  getByStringPath,
  convertBufferIntoImgUrl,
};
