import * as React from 'react';
import { LinkBox, LinkOverlay, Stat, StatArrow, Table, Tbody, Tr, Td, Text } from '@chakra-ui/react';
import numeral from 'numeral';
import HEAP from '../constants/HEAP.gen.json';

import { Link } from 'react-router-dom';

import GlanceCard from './glance-card';
import { useAnalyticsContext } from './analytics-context';

export default function TopTracksTable({ domainData }) {
  const { dateRangeString, decorativeText, currentMetricsInterval } = useAnalyticsContext();

  const tableData = domainData?.data?.data?.rollups?.tracks;

  return (
    <GlanceCard
      status={domainData.status}
      dateRangeString={dateRangeString}
      decorativeText={decorativeText}
      title={'Top Streaming Tracks'}
      data-heap={HEAP.PUBLIC.ANALYTICS.TYPE.ANALYTICS_CARD_TOP_TRACKS_TABLE}>
      <Table size="sm" variant="simple">
        <Tbody>
          {tableData?.length === 0 && (
            <Tr>
              <Td borderBottom="none" pl={0}>
                <Text color="black.100" fontSize="reg" fontWeight="bold">
                  No tracks to display
                </Text>
              </Td>
            </Tr>
          )}
          {tableData?.length &&
            tableData.map((track, index) => {
              const trackPercentageChange = track.time_over_time;
              return (
                <LinkBox as="tr" key={`${index}-${track?.isrc}`}>
                  <Td pl={0} color="black.60">
                    {String(index + 1).padStart(2, '0')}
                  </Td>
                  <Td>
                    <LinkBox>
                      <LinkOverlay as={Link} to={`/analytics/track/${track.asset_track_id}`}>
                        <Text noOfLines={2} color="black.100" fontSize="reg" fontWeight="bold">
                          {track.title}
                        </Text>
                      </LinkOverlay>
                    </LinkBox>
                    <Text color="black.60">{track?.display_artist_name || '-'}</Text>
                  </Td>
                  <Td pr={0} isNumeric>
                    <Text color="black.100" fontSize="lg" fontWeight="bold">
                      {numeral(track.streams).format('0,0')}
                    </Text>
                    <Stat
                      visibility="hidden"
                      fontSize="sm"
                      fontWeight="medium"
                      color={trackPercentageChange > 0 ? 'green.400' : 'red.400'}
                      whiteSpace="nowrap">
                      <StatArrow width={2} height={2} type={trackPercentageChange > 0 ? 'increase' : 'decrease'} />
                      {numeral(Math.abs(trackPercentageChange)).format('0%')}
                      <Text
                        key={currentMetricsInterval.interval}
                        display="inline"
                        ml={1}
                        fontSize="xsm"
                        color="gray.500">
                        {Number(currentMetricsInterval.interval) === 6 && 'WoW'}
                        {Number(currentMetricsInterval.interval) === 29 && 'MoM'}
                      </Text>
                    </Stat>
                  </Td>
                </LinkBox>
              );
            })}
        </Tbody>
      </Table>
    </GlanceCard>
  );
}
