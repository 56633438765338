import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import Icon from 'components/Icon/icon';
import { Spring } from 'react-spring/renderprops';

import { spinAnimation } from 'styles';

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

const IconSpinner = styled(Icon)`
  ${spinAnimation};
`;

const Label = styled.div`
  font-size: ${props => props.theme.fonts.sizes.sm};
  font-weight: ${props => props.theme.fonts.weights.medium};
  margin-left: 0.25rem;
`;

const SaveIndicator = React.forwardRef(({ className, icon, text, saving, ...props }, ref) => {
  const [doHideSpringElement, setDoHideSpringElement] = useState(true);

  // Spring elements mount and unmount

  useEffect(() => {
    if (saving) {
      setDoHideSpringElement(false);
    }
  }, [saving]);

  const handleSpringRest = () => {
    if (!saving) setDoHideSpringElement(true);
  };

  return (
    <Spring from={{ opacity: 0 }} to={saving ? { opacity: 1 } : { opacity: 0 }} onRest={() => handleSpringRest()}>
      {springProps => (
        <Wrapper
          className={className}
          style={{
            display: !saving && doHideSpringElement ? 'none' : 'flex',
            ...springProps,
          }}>
          <IconSpinner type={icon} />
          {text && <Label>{text}</Label>}
        </Wrapper>
      )}
    </Spring>
  );
});

SaveIndicator.displayName = 'SaveIndicator';

SaveIndicator.defaultProps = {
  icon: 'loader',
  text: 'Saving…',
  saving: false,
};

export default withTheme(SaveIndicator);
