/* Tech Debt : Should consider renaming to catalog */
import React from 'react';
import { withTheme } from '@emotion/react/macro';
import { Col, Row } from 'react-styled-flexboxgrid';

import ViewWrapper from '../ViewWrapper/view-wrapper';
import SearchResults from 'components/SearchResults/search-results';

import { APP_VIEWS } from '../../utils/routes';

const Search = () => {
  return (
    <ViewWrapper designLayer={0} disableHorizontalScroll={true}>
      <Row>
        <Col xs={12}>
          <SearchResults parentView={APP_VIEWS.SEARCH} />
        </Col>
      </Row>
    </ViewWrapper>
  );
};

export default withTheme(Search);
