const TOT_THREASHOLD = 14;

export function determineShouldShowToTForTrack(compareDate) {
  const currentDate = new Date();
  return new Date(compareDate).getTime() < new Date(currentDate.setDate(currentDate.getDate() - TOT_THREASHOLD));
}

export function determineShouldShowTotFromDomainData(domainData, TOT_THREASHOLD) {
  if (!domainData?.data?.data?.resource?.asset_track_id) return true;
  return determineShouldShowToTForTrack(domainData.data.data.resource.release_date, TOT_THREASHOLD);
}
