import styled from '@emotion/styled/macro';
import Icon from 'components/Icon/icon';
import { spinAnimation } from 'styles';

export const TrackRowContainer = styled.div`
  align-items: center;
  display: flex;
  height: 4rem;
  margin-bottom: 1rem;
  width: 100%;

  > span {
    color: ${props => props?.theme?.colors?.brand?.textWhite};
  }
`;

export const TrackRowBody = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  height: 100%;
  justify-content: space-between;
  margin-left: 2rem;
  width: 100%;

  > span {
    color: ${props => props?.theme?.colors?.brand?.textWhite};
    font-size: ${props => props?.theme?.fonts?.sizes?.reg};
    font-weight: ${props => props?.theme?.fonts?.weights?.medium};
    transition: color 250ms ease;
  }

  ${props =>
    props.disabled &&
    `
    span {
      color: ${props?.theme?.colors?.brand?.layer3};
    }
  `}
`;

export const IconSpinner = styled(Icon)`
  ${spinAnimation};
  width: 2rem;
  height: 2rem;
`;

export const CardLoadingWrapper = styled.div`
  transition: opacity 250ms ease;

  ${props =>
    props.loading === 'true' &&
    `
    opacity: 0.25
  `};
`;

export const CardContentWrapper = styled.div`
  margin: 0 0.5rem;
  background-color: ${props => props?.theme?.colors?.brand?.layer1};
  min-height: 500px;
  position: relative;

  ${props =>
    !props.invisible &&
    `
    background-color: ${props?.theme?.colors?.brand?.layer0};
    border-radius: 0.5rem;
    min-height: 400px;
    padding: 3rem 2rem;
  `}
`;

export const CardHeaderBlock = styled.div`
  margin-bottom: 2rem;
`;

export const InitialLoadContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;

  h3 {
    margin-bottom: 3rem;
  }
`;

export const ValidationMessage = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  margin-right: 1rem;
  width: 100%;
`;

export const ReleaseBlock = styled.div`
  display: flex;
  width: 100%;

  > div:first-child {
    flex-grow: 0;
    margin-right: 1rem;
  }

  strong {
    color: ${props => props?.theme?.colors?.brand?.ghost};
    display: block;
    margin-bottom: 0.5rem;
  }
`;
