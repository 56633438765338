import React, { memo, useState, useEffect, useCallback } from 'react';
import ManageContentBox, { ContentHeader, Title } from '../../../components/ManageContentBox/manage-content-box';
import { Box, useDisclosure, Spinner, Text, useTheme } from '@chakra-ui/react';
//import LabeledInput from '../../../components/labeled-input/labeled-input';
import LabeledSelect from '../../../components/labeled-select/labeled-select';
import ENUMS from '../../../constants/ENUMS.gen.json';
import { LANGUAGES2 } from '../../../constants/dictionaries';
import Well, { WellVariant } from 'components/Well/well';
import LabeledDatePicker from '../../../features/LabeledDatePicker/labeled-date-picker';

import ArtistRoleManager from '../../../features/party-role-managers/artist-role-manager';
import { getArtistList } from '../../../data-client/track-parties';
import { useQuery } from 'react-query';
import AddArtistModal from '../../../settings/add-artist-modal';
import useLabeledMetaToggle from '../../../features/use-labeled-meta-toggle/use-labeled-meta-toggle';

const { ASSET_GENRE } = ENUMS;
const languagesOptions = LANGUAGES2.map(lang => ({ value: lang.value, title: lang.label }));
const genreOptions = Object.values(ASSET_GENRE);

const ERROR_MESSAGES = {
  artists: 'At least a primary artist or remixer is required',
  asset_release_upc: 'Please enter a valid UPC',
  original_release_date: 'Please enter a valid date',
  genre: 'Please select a genre',
  lang: 'Please select a language',
};

const Divider = () => (
  <Box height="1px" width="100%" marginTop="1.5rem" marginBottom="1.5rem" backgroundColor="gray.300" />
);

function ClipReleaseInfo({
  artists,
  asset_release_upc,
  original_release_date,
  genre,
  lang,
  releaseGroupId,
  hasBeenSubmitted,
  doValidateField,
  saveState,
  tainted,
}) {
  const theme = useTheme();

  const [dirtyItems, setDirtyItems] = useState({ artists: false });
  const [requiredReleaseArtist, setRequiredReleaseArtist] = useState(undefined);

  const [localData, setLocalData] = useState({
    asset_release_upc,
    original_release_date,
    genre,
    lang,
    artists,
  });

  const [errorMessages, setErrorMessages] = useState({
    asset_release_upc: null,
    original_release_date: null,
    genre: null,
    lang: null,
    artists: null,
  });

  function updateErrorMessages(name, value) {
    setErrorMessages(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  function onFormInputChange({ target: { name, value } }) {
    setLocalData({ ...localData, [name]: value });
    const valid = doValidateField(name, value);
    if (!valid) {
      updateErrorMessages(name, ERROR_MESSAGES[name]);
    } else {
      updateErrorMessages(name, null);
    }
  }

  const onTrimmedInputChange = useCallback(
    ({ target: { name, value } }) => {
      onFormInputChange({ target: { name, value: value.trim() } });
    },
    [onFormInputChange]
  );

  useEffect(() => {
    if (tainted) {
      Object.entries(localData).forEach(([name, value]) => {
        if (name === 'metaState' || name === 'artists') return; //todo a better way to handle this is needed
        const valid = doValidateField(name, value);
        if (!valid) {
          if (!valid) {
            updateErrorMessages(name, ERROR_MESSAGES[name]);
          } else {
            updateErrorMessages(name, null);
          }
        }
      });

      setDirtyItems({ artists: true });
    }
  }, []);

  useEffect(() => {
    if (!dirtyItems.artists) return;

    const valid = doValidateField('artists', localData.artists);

    if (!valid) {
      updateErrorMessages('artists', ERROR_MESSAGES.artists);
    } else {
      updateErrorMessages('artists', null);
    }
  }, [localData.artists]);

  const [displayArtists, setDisplayArtists] = useState([]);

  const artistQuery = useQuery(['artists', releaseGroupId], () => getArtistList({ user_group_id: releaseGroupId }), {
    enabled: releaseGroupId !== undefined && releaseGroupId !== null,
    onSuccess: (data = []) => {
      const requiredArtist = data.find(artist => artist.is_required_release_artist);

      const localArtists = localData.artists || [];
      const requiredReleaseArtistChosen = !!localArtists.find(
        artist => requiredArtist && requiredArtist.asset_party_id === artist.asset_party_id
      );

      if (requiredReleaseArtistChosen === false && requiredArtist && localArtists.length === 0) {
        setLocalData({
          ...localData,
          artists: [{ asset_party_id: requiredArtist.id, roles: ['MainArtist'] }],
        });
        saveState({
          ...localData,
          artists: [{ asset_party_id: requiredArtist.id, roles: ['MainArtist'] }],
        });
      }
      setRequiredReleaseArtist(requiredArtist);
      setDisplayArtists(data);
    },
  });

  const addArtistModal = useDisclosure();

  //A useEffect could probably do the same thing here
  const onMetaToggle = (key, value) => {
    let newLocalData;
    if (!value) {
      newLocalData = {
        ...localData,
        [key]: null,
        metaState: {
          [key]: false,
        },
      };
    } else {
      newLocalData = {
        ...localData,
        metaState: {
          [key]: true,
        },
      };
    }
    setLocalData(newLocalData);

    saveState(newLocalData);
  };

  const [LabeledMetaToggle, metaState] = useLabeledMetaToggle(
    {
      asset_release_upc: !!localData.asset_release_upc,
      original_release_date: !!localData.original_release_date,
    },
    onMetaToggle
  );

  const onArtistChange = ({ target: { value: updatedArtists } }) => {
    setLocalData({
      ...localData,
      artists: updatedArtists.map(a => {
        return {
          ...a,
          asset_party_id: Number(a.asset_party_id),
        };
      }),
    });

    saveState({
      ...localData,
      artists: updatedArtists.map(a => {
        return {
          ...a,
          asset_party_id: Number(a.asset_party_id),
        };
      }),
    });

    setDirtyItems({ ...dirtyItems, artists: true }); //right now it is artists only b
  };

  const onBlurredReleaseUPCLabel = ({ target: { name, value } }) => {
    const finalValue = value === 'yes' ? '' : null;
    setLocalData({ ...localData, [name]: finalValue });
    submitForm();
  };

  const onValueChange = (name, value) => {
    setLocalData({ ...localData, [name]: value });
  };

  const onCreateArtist = newArtist => {
    const currentSelectedArtists = localData?.artists || [];
    setLocalData({
      ...localData,
      artists: [...currentSelectedArtists, { asset_party_id: newArtist.id, roles: ['MainArtist'] }],
    });
    saveState({
      ...localData,
      artists: [...currentSelectedArtists, { asset_party_id: newArtist.id, roles: ['MainArtist'] }],
    });
    artistQuery.refetch();
  };

  function submitForm(evt) {
    evt?.preventDefault();

    saveState(localData);
  }

  return (
    <Box>
      <ManageContentBox>
        <AddArtistModal disclosure={addArtistModal} onCreateArtist={onCreateArtist} variant={'light'} />
        <ContentHeader>
          <Title toolTip={'Artists added here will automatically appear on the release and each track.'}>
            Release Artists
          </Title>
        </ContentHeader>
        {artistQuery.isLoading && <Spinner />}
        {!artistQuery.isLoading && !artistQuery.isError && (
          <Box
            border={'1px solid'}
            borderColor={errorMessages.artists ? theme.colors.red100 : 'transparent'}
            borderRadius={'0.15rem'}
            padding={'0.25rem'}>
            <ArtistRoleManager
              onCreateArtist={onCreateArtist}
              name={'artist'}
              onChange={onArtistChange}
              loading={artistQuery.isLoading}
              artists={displayArtists}
              selectedArtists={localData?.artists}
              requiredReleaseArtist={requiredReleaseArtist}
            />
            {errorMessages.artists ? <Text color={theme.colors.red100}>{errorMessages.artists}</Text> : ''}
          </Box>
        )}
        <Divider />
        <LabeledSelect
          width={300}
          name="genre"
          label="Primary Genre"
          options={genreOptions}
          onBlur={submitForm}
          onChange={onFormInputChange}
          value={localData.genre || ''}
          invalidMessage={errorMessages.genre}
        />
        <Divider />
        <LabeledSelect
          width={300}
          name="lang"
          label="Language"
          value={localData.lang}
          onChange={onFormInputChange}
          onBlur={submitForm}
          options={languagesOptions}
          invalidMessage={errorMessages.lang}
        />
        {/* <Divider />
        <ContentHeader>
          <Title toolTip="12 digit, universal product code (UPC)">Do you have a UPC?</Title>
        </ContentHeader>
        <LabeledMetaToggle
          name="asset_release_upc"
          label=""
          disabled={hasBeenSubmitted}
          eventHandlers={{
            onBlur: onBlurredReleaseUPCLabel,
          }}
        />
        {metaState.asset_release_upc && (
          <>
            <LabeledInput
              name="asset_release_upc"
              label="UPC"
              value={localData.asset_release_upc}
              disabled={hasBeenSubmitted}
              onChange={onTrimmedInputChange}
              onBlur={submitForm}
              invalidMessage={errorMessages.asset_release_upc}
            />
          </>
        )}
        <Well variant={WellVariant.COLLAPSIBLE} expandButtonText={'Learn more about UPCs'}>
          This 12 digit code uniquely identifies albums and helps music stores track sales of an album release. Leave
          this option unchecked if you do not own a UPC for your release and Venice will auto-generate a UPC. If you
          have pre-purchased your own UPC or are migrating a previously distributed release to Venice, select and entter
          your UPC
        </Well> */}
        <ContentHeader>
          <Title toolTip={'For use when an album was previously released'}>Is this a re-release?</Title>
        </ContentHeader>
        <LabeledMetaToggle
          name="original_release_date"
          label=""
          eventHandlers={{
            onBlur: submitForm,
          }}
        />
        {metaState.original_release_date && (
          <LabeledDatePicker
            labelText="Original Release Date"
            error={errorMessages.original_release_date}
            value={localData.original_release_date}
            onValueChange={onValueChange}
            name={'original_release_date'}
            tooltip={'For use when an album was previously released'}
          />
        )}
        <Well variant={WellVariant.COLLAPSIBLE} expandButtonText={'Learn more'}>
          If an album was previously released through another distributor, select re-release and enter the original
          release date. This ensures the track or album is properly sequenced in your discography and not categorized as
          a new release.
        </Well>
      </ManageContentBox>
    </Box>
  );
}

export default memo(ClipReleaseInfo);
