import React, { useMemo } from 'react';
import { Table, Tbody, Th, Thead, Tr, Td, Tfoot, useTheme, Box } from '@chakra-ui/react';
import { useTable } from 'react-table';
import { Heading } from '@chakra-ui/react';
import numeral from 'numeral';

import SkeletonLoader from '../../components/loading-containers/skeleton-loader';
import RowWrapper from '../../components/Table/row-wrapper';
import CellWrapper from '../../components/Table/cell-wrapper';
import { HIDDEN_TABLE_COLUMN } from './view-configurations';

function StatementSummaryTable({ grandTotal, transferredAmount, deductedFeeAmount, theme }) {
  const headerStyle = {
    ...theme.components.Table.baseStyle.th,
    textAlign: 'right',
    border: 'none',
    fontSize: theme.fonts.sizes.xxsm,
  };
  const valueStyle = {
    border: 'none',
    textAlign: 'right',
    fontSize: theme.fonts.sizes.xlg,
    fontWeight: theme.fonts.weights.bold,
    width: '10rem',
  };
  const numeralFormat = '$0,0.00';

  return (
    <Table width="25rem" float="right" mt="3rem">
      <Tbody>
        <Tr border="none">
          <Td {...headerStyle}>GRAND TOTAL</Td>
          <Td {...valueStyle}>{numeral(grandTotal).format(numeralFormat)}</Td>
        </Tr>
        <Tr border="none">
          <Td {...headerStyle}>TRANSFER FEE</Td>
          <Td {...valueStyle}>{numeral(deductedFeeAmount).format(numeralFormat)}</Td>
        </Tr>
        <Tr border="none">
          <Td {...headerStyle}>TRANSFERRED AMOUNT</Td>
          <Td {...valueStyle}>{numeral(transferredAmount).format(numeralFormat)}</Td>
        </Tr>
      </Tbody>
    </Table>
  );
}

function StatementsTable({
  data = [],
  columns = [],
  tableStyle = {},
  RowComponent,
  context,
  theme,
  onRowSelected,
  domainData,
  grandTotal,
  tableTotal,
  transferredAmount,
  deductedFeeAmount,
}) {
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, prepareRow, rows } = useTable({
    columns,
    data,
  });

  const emptyFooterColumns = useMemo(
    () =>
      Array(columns.length - 2)
        .fill(0)
        .map(() => <Th border={'none'}></Th>),
    [columns]
  );

  return (
    <>
      <Table {...getTableProps} sx={tableStyle} variant={'analytics'}>
        <Thead background={'none'}>
          {headerGroups.map((headerGroup, hgIdx) => (
            <Tr key={`header-group-${hgIdx}`} _hover={{ background: 'none' }}>
              {headerGroup.headers.map((col, idx) => {
                const weight = col.isSorted ? theme.fonts.weights.bold : theme.fonts.weights.medium;
                const isHidden = col.id === HIDDEN_TABLE_COLUMN;
                const headerStyle = col.headerStyle || {};
                return (
                  <Th
                    sx={headerStyle}
                    key={idx}
                    fontWeight={weight}
                    color={'black50'}
                    visibility={isHidden ? 'hidden' : 'unset'}
                    border={'none'}>
                    {col.render('Header')}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps}>
          {rows.map((row, rowIdx) => {
            prepareRow(row);
            const { key, role } = row.getRowProps();

            return (
              <RowWrapper
                RowComponent={RowComponent}
                idx={rowIdx}
                key={key}
                role={role}
                {...context}
                row={row}
                domainData={domainData}
                onRowSelected={onRowSelected}>
                {row.cells.map((cell, i) => {
                  const { key: cellKey, role: cellRole } = cell.getCellProps();
                  return (
                    <CellWrapper
                      key={cellKey}
                      role={cellRole}
                      idx={i}
                      row={row}
                      cell={cell}
                      background={'none'}
                      CellComponent={cell?.column?.CellComponent}
                      rowIdx={rowIdx}
                      style={cell?.column?.style}
                      context={context}>
                      {cell.render('Cell')}
                    </CellWrapper>
                  );
                })}
              </RowWrapper>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr border={'none'} _hover={{ border: 'none' }}>
            {emptyFooterColumns}
            <Th border={'none'} textAlign="right">
              Total
            </Th>
            <Th border={'none'} fontWeight={'bold'} color={theme.colors.black100} textAlign="right">
              {numeral(tableTotal).format('$0,0.00')}
            </Th>
          </Tr>
        </Tfoot>
      </Table>
      {grandTotal && (
        <StatementSummaryTable
          grandTotal={grandTotal}
          transferredAmount={transferredAmount}
          deductedFeeAmount={deductedFeeAmount}
          theme={theme}
        />
      )}
    </>
  );
}

function TableContainer({
  title,
  columns = [],
  tableStyle = {},
  RowComponent = false,
  status,
  data,
  grandTotal,
  tableTotal,
  transferredAmount,
  deductedFeeAmount,
}) {
  const theme = useTheme();

  return (
    <SkeletonLoader status={status}>
      {!!data && (
        <Box>
          <Heading fontSize={theme.fonts.sizes.xlg} fontFamily={theme.fonts.families.text} as="h2">
            {title}
          </Heading>
          <StatementsTable
            tableTotal={tableTotal}
            grandTotal={grandTotal}
            transferredAmount={transferredAmount}
            deductedFeeAmount={deductedFeeAmount}
            data={data}
            columns={columns}
            tableStyle={tableStyle}
            RowComponent={RowComponent}
            theme={theme}
          />
        </Box>
      )}
    </SkeletonLoader>
  );
}

function createTableType({ title, columns = [], style = {} }) {
  return function tableType({
    data,
    status,
    grandTotal,
    tableTotal,
    withDataColumnTransformFunc,
    transferredAmount,
    deductedFeeAmount,
  }) {
    const tableColumns = data && withDataColumnTransformFunc ? withDataColumnTransformFunc({ data, columns }) : columns;

    return (
      <TableContainer
        title={title}
        columns={tableColumns}
        data={data}
        status={status}
        tableStyle={style}
        tableTotal={tableTotal}
        grandTotal={grandTotal}
        transferredAmount={transferredAmount}
        deductedFeeAmount={deductedFeeAmount}
      />
    );
  };
}

export default createTableType;
