import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl();

const DSP = () => {
  const getAll = () =>
    AuthAxios.get(`${DataApiUrl}/dsps`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  return {
    getAll,
  };
};

export default DSP;
