import React, { useState } from 'react';
import { Box, Icon, Table, Thead, Th, Tr, Td, chakra, Center, Spinner, useTheme, Tbody } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useTable, useSortBy } from 'react-table';
import { RiArrowUpLine, RiArrowDownLine } from 'react-icons/ri';
import TablePaginationFooter from 'components/Table/table-pagination-footer';
import SkeletonLoader from 'components/loading-containers/skeleton-loader';
import SearchBar from 'components/search-bar';
import useDebounce from 'utils/debounce';
import * as API from 'utils/API/API';

const MarketingDiscoveryModeTracks = API.marketingDiscoveryModeTracks();

const TableRow = ({ track }) => {
  return (
    <>
      <Tr {...track.getRowProps()} borderColor={'black20'}>
        {track.cells.map(cell => (
          <Td key={`${cell.column.id}-${track.original.artist_name}-${track.original.isrc_code}`}>
            <Box>
              {cell.render('Cell')}
              {['intent_rate', 'stream_lift', 'listener_lift'].includes(cell.column.id) && '%'}
            </Box>
          </Td>
        ))}
      </Tr>
    </>
  );
};

const PAGE_SIZE = 8;

export default function MarketingDiscoveryModeHistoryTable({ userGroupId, month, year, emptyStateMsg = '' }) {
  const theme = useTheme();
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const query = useQuery(
    ['discoveryModeHistoTableDetail', page, userGroupId, debouncedSearchTerm],
    () =>
      MarketingDiscoveryModeTracks.getHistoryCampaignTracks({
        page_size: PAGE_SIZE,
        page_index: page,
        user_group_id: userGroupId,
        search_term: debouncedSearchTerm,
        month: month,
        year: year,
      }),
    { enabled: !!userGroupId }
  );

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'Artist',
        accessor: 'artist_name',
      },
      {
        Header: 'Track',
        accessor: 'track_name',
      },
      {
        Header: 'ISRC',
        accessor: 'isrc_code',
      },
      {
        Header: 'Promoted Stream Lift',
        accessor: 'stream_lift',
      },
      {
        Header: 'Promoted Streams',
        accessor: 'streams',
      },
      {
        Header: 'Listeners Lift',
        accessor: 'listener_lift',
      },
      {
        Header: 'Listeners',
        accessor: 'listeners',
      },
      {
        Header: 'Saves',
        accessor: 'saves',
      },
      {
        Header: 'Playlist adds',
        accessor: 'playlist_adds',
      },
      {
        Header: 'Intent Rate',
        accessor: 'intent_rate',
      },
    ];
  }, []);

  const { data, totalPages } = React.useMemo(() => {
    const { data: queryData = { total: 1, results: [] } } = query;
    const { total = 1, results } = queryData;
    const totalPages = Math.max(Math.ceil(total / PAGE_SIZE), 1);

    return { data: results, totalPages };
  }, [query.data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'artist_name',
            desc: false,
          },
        ],
      },
    },
    useSortBy
  );

  const onNextPage = React.useMemo(() => () => setPage(p => p + 1), []);
  const onPrevPage = React.useMemo(() => () => setPage(p => p - 1), []);

  const isNextPageDisabled = React.useMemo(
    () => page + 1 >= totalPages || query.isLoading || query.isRefetching,
    [page, totalPages, query.isLoading, query.isRefetching]
  );

  const isPrevPageDisabled = React.useMemo(
    () => page <= 0 || query.isLoading || query.isRefetching,
    [page, query.isLoading, query.isRefetching]
  );

  return (
    <>
      {query.isFetched && !searchTerm && data?.length === 0 ? (
        <Box color="black70" p={6} textAlign="center" fontWeight="bold">
          {emptyStateMsg}
        </Box>
      ) : (
        <>
          <Box pb={5}>
            <SearchBar
              searchTerm={searchTerm}
              onSearchTermChange={setSearchTerm}
              width="25rem"
              placeholder="Search by artist, title or isrc"
            />
          </Box>
          <SkeletonLoader status={query.status} skeletonProps={{ height: '240px', width: '100%' }}>
            <Box overflowX="auto">
              <Table size="sm" variant="light" {...getTableProps()}>
                <Thead>
                  {headerGroups.map((headerGroup, groupIndex) => (
                    <Tr key={`${groupIndex}-headergroup`} {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, headerIndex) => (
                        <Th
                          key={`${groupIndex}-${headerIndex}-tableheader`}
                          isNumeric={column.isNumeric}
                          {...column.getHeaderProps(column.getSortByToggleProps())}>
                          <Box style={{ color: 'white' }} display="inline">
                            {column.isSorted ? (
                              <chakra.span pr="3" position="relative" top="1px">
                                {column.isSortedDesc ? (
                                  <Icon as={RiArrowUpLine} aria-label="sorted descending" />
                                ) : (
                                  <Icon as={RiArrowDownLine} aria-label="sorted ascending" />
                                )}
                              </chakra.span>
                            ) : null}
                            {column.render('Header')}
                          </Box>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {rows.map(track => {
                    prepareRow(track);
                    return (
                      <TableRow key={`track-${track.original.artist_name}-${track.original.isrc_code}`} track={track} />
                    );
                  })}
                </Tbody>
              </Table>
              {query.isRefetching && (
                <Box
                  style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    right: 0,
                    top: 0,
                    backgroundColor: theme.colors.brand.layer4,
                    opacity: '25%',
                  }}>
                  <Center h="full">
                    <Spinner color="black" size="lg" />
                  </Center>
                </Box>
              )}
            </Box>
            <TablePaginationFooter
              onPrevPage={onPrevPage}
              isPrevPageDisabled={isPrevPageDisabled}
              page={page}
              totalPages={totalPages}
              onNextPage={onNextPage}
              isNextPageDisabled={isNextPageDisabled}
            />
          </SkeletonLoader>
        </>
      )}
    </>
  );
}
