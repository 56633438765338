import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from '@emotion/styled/macro';
import Button from 'components/Button/button';
import FieldLabel from 'components/FieldLabel/field-label';
import FormRow from './form-row';

import AddContributorModal from 'components/AddArtistsForm/add-contributor-modal';
import { useDisclosure, Text } from '@chakra-ui/react';
import asModal from 'features/Modal/as-modal';

import { creditRoles } from '../../constants/roles';
import eq from 'lodash/eq';
import isEqual from 'lodash/isEqual';
import { BLANK_CONTRIBUTOR, REQUIRED_AUDIO_ROLES, REQUIRED_VIDEO_ROLES } from './ADD_ARTIST_FORM_CONSTANTS';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const getRequireRolesByResourceType = resourceType =>
  resourceType === 'Video' ? REQUIRED_VIDEO_ROLES : REQUIRED_AUDIO_ROLES;

function* generateTempId() {
  let idx = 0;

  while (true) {
    yield idx++;
  }
}

const idxGenerator = generateTempId();

const createEmptyContributor = ({ roles = [], ...rest }) => ({
  ...BLANK_CONTRIBUTOR,
  _idx: idxGenerator.next().value, //FOR OBJECT COMPARISON WHEN REMOVING SPECIFIC BLANK CONTRIBUTOR ROWS
  roles,
  ...rest,
});

const getRequiredContributors = (fields, contributors = []) =>
  fields.reduce((all, role) => {
    all[role.value] =
      contributors?.find(c => c?.roles?.[0] === role.value) || createEmptyContributor({ roles: [role.value] });
    return all;
  }, {});

const RequiredContributorsWarningModal = asModal(function RequiredContributorsWarning() {
  return (
    <Text margin={'2rem 0 1rem 0'} color="cream100">
      Certain DSPs including Spotify required that all composers and lyricist credits use legal first and last names.
      Please create a new contributor using the legal first and last name of the composer or lyricist.
    </Text>
  );
});

function AddArtistsForm({
  name,
  entityLabel,
  resourceType,
  errors,
  setError,
  disabled,
  heapCodePrefix,
  contributorsList,
  onContributorModalOpen,
  contributors,
  setContributors,
}) {
  const addContributorModal = useDisclosure();
  const requiredContributorsWarningModal = useDisclosure();
  const defaultRequiredRoles = getRequireRolesByResourceType(resourceType);

  const [formConfiguration, setFormConfiguration] = React.useState({
    options: creditRoles.filter(opt => !defaultRequiredRoles.includes(opt)),
    requiredRoles: getRequireRolesByResourceType(resourceType),
    requiredContributors: getRequiredContributors(defaultRequiredRoles, contributors),
  });

  React.useEffect(() => {
    const requiredRoles = getRequireRolesByResourceType(resourceType);
    const options = creditRoles.filter(opt => !requiredRoles.includes(opt));
    const requiredContributors = getRequiredContributors(requiredRoles, contributors);

    setFormConfiguration({
      requiredRoles,
      options,
      requiredContributors,
    });
  }, [contributors, resourceType]);

  const removeContributor = contributor => setContributors(prev => prev.filter(c => !isEqual(c, contributor)));

  const updateContributorRole = (contributor, role) => {
    setContributors(prev => {
      const newState = Array.from(prev);
      const idx = prev.findIndex(p => eq(p, contributor));
      newState[idx].roles[0] = role;
      return newState;
    });
  };

  const setErrorForRequiredContributor = (currentContributor, newContributor) => {
    const isComposerLyricistRole = currentContributor.roles.includes('ComposerLyricist');
    const errorKey = `${newContributor.asset_party_id}_${currentContributor.roles[0]}`;
    const formattedRole = currentContributor.roles.toString().toLowerCase();

    setError('contributors', {
      ...errors,
      [errorKey]: {
        type: 'required',
        message: isComposerLyricistRole
          ? 'Please select a valid composer and lyricist.'
          : `Please select a valid ${formattedRole}.`,
      },
    });
  };

  const updateContributors = (currentContributor, newContributor) => {
    setContributors(prev => {
      const newState = Array.from(prev);
      const idx = prev.findIndex(p => eq(p, currentContributor));

      const isRequiredRole = ['Composer', 'Lyricist', 'ComposerLyricist'].includes(
        currentContributor?.roles.toString()
      );
      if (isRequiredRole === true) {
        const validContributor = !!newContributor?.full_name.includes(' ');
        if (!validContributor) {
          requiredContributorsWarningModal.onOpen();
          setErrorForRequiredContributor(currentContributor, newContributor);
        }
      }
      if (idx === -1) {
        newState.push({
          ...newContributor,
          roles: currentContributor?.roles || [],
        });
      } else {
        newState[idx] = {
          ...currentContributor,
          ...newContributor,
          roles: currentContributor?.roles || [],
        };
      }
      return newState;
    });
  };

  const appendContributor = () => {
    const { user_group_id } = contributorsList[0] || { user_group_id: null };
    const { sequence } = contributors[contributors.length - 1] || 0;
    setContributors(prev => [...prev, createEmptyContributor({ user_group_id, sequence: sequence + 1 })]);
  };

  const inRequiredList = contributor =>
    Object.values(formConfiguration.requiredContributors || {}).includes(contributor);

  return (
    <Container>
      <Row>
        <Col xs={6}>
          <FieldLabel
            text={entityLabel}
            tooltip={`Please enter names exactly as they
are to be seen on DSPs`}
            required
          />
        </Col>
        <Col xs={6}>
          <FieldLabel text={`Role`} tooltip={`Composer and Lyricist are required.`} required />
        </Col>
      </Row>

      {formConfiguration.requiredRoles.map(field => {
        const contributor = formConfiguration.requiredContributors[field.value];
        return (
          <FormRow
            key={`requiredContributor-${field.value}`}
            isRequired={true}
            contributor={contributor}
            options={formConfiguration.options}
            onContributorModalOpen={onContributorModalOpen}
            updateContributors={updateContributors}
            contributorsList={contributorsList}
            errors={errors}
            updateContributorRole={updateContributorRole}
            disabled={disabled}
            heapCodePrefix={heapCodePrefix}
            removeContributor={removeContributor}
            name={name}
          />
        );
      })}

      {contributors
        .filter(contributor => !inRequiredList(contributor))
        .map((contributor, i) => (
          <FormRow
            key={`contributor-${contributor?.asset_party_id || contributor?._idx}-${i}`}
            isRequired={false}
            contributor={contributor}
            options={formConfiguration.options}
            onContributorModalOpen={onContributorModalOpen}
            updateContributors={updateContributors}
            contributorsList={contributorsList}
            errors={errors}
            updateContributorRole={updateContributorRole}
            disabled={disabled}
            heapCodePrefix={heapCodePrefix}
            removeContributor={removeContributor}
            name={name}
          />
        ))}

      <Col>
        <Row>
          <Button disabled={disabled} tertiary onClick={appendContributor} text="Add Contributor" />
        </Row>
      </Col>
      <AddContributorModal disclosure={addContributorModal} />
      <RequiredContributorsWarningModal
        hideCancel
        isOpen={requiredContributorsWarningModal.isOpen}
        onClose={requiredContributorsWarningModal.onClose}
        onSubmit={requiredContributorsWarningModal.onClose}
        submitText="OK"
        headerText="Invalid Contributor Name for Role"
      />
    </Container>
  );
}

AddArtistsForm.defaultProps = {
  items: [],
  entityLabel: 'Artist Name',
  errors: {},
  disabled: false,
  resourceType: null,
  fields: [],
};

export default React.forwardRef(function AddContributorForm(props, ref) {
  return <AddArtistsForm innerRef={ref} {...props} />;
});
