import React from 'react';
import { useToast } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import TrackTable from 'components/TrackTable/track-table';
import { RiFileCopyLine } from 'react-icons/ri';

function ButtonColumn({ marketingLinkTrack, handleCreateClick, imgUrl, title, artistName, releaseId, trackId }) {
  const toast = useToast();

  function handleCopyToClipboard() {
    navigator.clipboard.writeText(marketingLinkTrack.marketingLink.linkfire_url);
    toast({ title: `Copied ${marketingLinkTrack.marketingLink.linkfire_url} to clipboard.` });
  }

  if (marketingLinkTrack?.marketingLink?.linkfire_url) {
    return (
      <Button variant={'smallLighter'} iconLeft={<RiFileCopyLine />} text="" onClick={handleCopyToClipboard}>
        Copy Link
      </Button>
    );
  }

  return (
    <Button
      variant={'smallDark'}
      onClick={() => handleCreateClick({ type: 'track', imgUrl, title, artistName, releaseId, trackId })}>
      Create Link
    </Button>
  );
}

export default function Tracks({ tracks, handleCreateClick }) {
  return <TrackTable tracks={tracks} handleCreateClick={handleCreateClick} buttonColumn={ButtonColumn} />;
}
