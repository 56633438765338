import { FlexCol } from '../../styles';
import { Col, Row } from 'react-styled-flexboxgrid';

import { creditRoles, displayRoles } from '../../constants/roles';

import { LANGUAGES2 } from '../../constants/dictionaries';
import React from 'react';

import {
  RiAppleFill as AppleIcon,
  RiSpotifyFill as SpotifyIcon,
  RiArrowDownSLine as ChevronDown,
  RiArrowUpSLine as ChevronUp,
} from 'react-icons/ri';

import { Flex, Button, useTheme } from '@chakra-ui/react';
import Box from '../../components/Box/Box';
import Small from 'components/Small/Small';
import IconedToolTip from 'components/IconedToolTip/IconedToolTip';

export const MIN_LYRICS_HEIGHT = 200;

const MetadataLabel = ({ children, ...props }) => (
  <Small variant="metaDataLabel" {...props}>
    {children}
  </Small>
);

const MetadataRow = ({ children, ...props }) => {
  return (
    <Flex marginBottom="1.5rem" {...props}>
      {children}
    </Flex>
  );
};

export function LyricsView({ trackData, isExpanded, setIsLyricsExpanded, theme }) {
  return !trackData?.lyrics ? (
    <Box>None entered</Box>
  ) : (
    <>
      <Box>
        <Button
          variant="transparent"
          onClick={() => setIsLyricsExpanded(!isExpanded)}
          rightIcon={isExpanded ? <ChevronUp /> : <ChevronDown />}>
          {isExpanded ? 'See Less' : 'See More'}
        </Button>
      </Box>
      <Box overflow="hidden">
        <Box margin="0" whiteSpace="pre-wrap" maxHeight={isExpanded ? 'none' : '21px'}>
          {trackData.lyrics}
        </Box>
      </Box>
    </>
  );
}

export function Artists({ trackData, isLyricsExpanded, setIsLyricsExpanded }) {
  const theme = useTheme();

  const icons = {
    apple: AppleIcon,
    spotify: SpotifyIcon,
  };

  return (
    <Box margin="1rem 0" variant="darkText">
      <MetadataRow>
        <Col xs={3}>
          <MetadataLabel>Artists</MetadataLabel>
        </Col>
        <Col xs={9}>
          {trackData.artists.map((party, i) => (
            <Row key={i}>
              <Col xs={4}>{party.full_name}</Col>
              <FlexCol xs={6}>
                {(Array.isArray(party?.roles) && displayRoles.find(role => role.value === party.roles[0])?.label) || ''}
                <Flex>
                  {!!party.platform_id &&
                    Object.entries(party.platform_id).map(([name, id]) => (
                      <IconedToolTip
                        iconProps={{ marginLeft: '0.5rem' }}
                        key={`platform-tooltip-${id}`}
                        text={id}
                        icon={icons[name]}
                      />
                    ))}
                </Flex>
              </FlexCol>
            </Row>
          ))}
        </Col>
      </MetadataRow>
      <MetadataRow>
        <Col xs={3}>
          <MetadataLabel>Genre</MetadataLabel>
        </Col>
        <Col xs={9}>{trackData.genre}</Col>
      </MetadataRow>
      <MetadataRow>
        <Col xs={3}>
          <MetadataLabel>Language</MetadataLabel>
        </Col>
        <Col xs={9}>{LANGUAGES2.find(l => l.value === trackData.lang)?.label}</Col>
      </MetadataRow>
      <MetadataRow>
        <Col xs={3}>
          <MetadataLabel>Explicit Content</MetadataLabel>
        </Col>
        <Col xs={9}>{trackData.parental_warning}</Col>
      </MetadataRow>
      <MetadataRow>
        <Col xs={3}>
          <MetadataLabel>Copyright</MetadataLabel>
        </Col>
        <Col xs={9}>{trackData.copyright_year}</Col>
      </MetadataRow>
      <MetadataRow>
        <Col xs={3}>
          <MetadataLabel>Lyrics</MetadataLabel>
        </Col>
        <Col xs={9}>
          <LyricsView
            trackData={trackData}
            isExpanded={isLyricsExpanded}
            setIsLyricsExpanded={setIsLyricsExpanded}
            theme={theme}
          />
        </Col>
      </MetadataRow>
    </Box>
  );
}

export function Credits({ trackData }) {
  return (
    <Box marginTop={'1rem'}>
      <MetadataRow>
        <Col xs={3}>
          <MetadataLabel>Credits</MetadataLabel>
        </Col>
        <Col xs={9}>
          {trackData.contributors.map((party, i) => (
            <Box variant="darkText" key={`${party.asset_party_id}-${party.roles[0]}`}>
              <MetadataRow key={i}>
                <Col xs={4}>{party.full_name}</Col>
                <Col xs={4}>
                  {(Array.isArray(party?.roles) && creditRoles.find(role => role.value === party.roles[0])?.label) ||
                    ''}
                </Col>
              </MetadataRow>
            </Box>
          ))}
        </Col>
      </MetadataRow>
    </Box>
  );
}
