import React from 'react';
import styled from '@emotion/styled/macro';
import { withTheme } from '@emotion/react/macro';
import Icon from 'components/Icon/icon';

const BadgeWrapper = styled.div`
  align-items: center;
  border-radius: 1rem;
  cursor: default;
  display: flex;
  height: 1.125rem;
  justify-content: center;
  width: 1.125rem;

  ${props =>
    props.incomplete !== 0 &&
    `
		background-color: ${props.colorNegative};
	`}
`;

const IncompleteNumber = styled.div`
  color: ${props => props.theme.colors.brand.textWhite};
  font-size: ${props => props.theme.fonts.sizes.xsm};
  font-weight: ${props => props.theme.fonts.weights.medium};
  line-height: 1.125rem;
`;

const BadgeIcon = styled(Icon)`
  color: ${props => props.theme.colors.positive};
`;

const InfoBadge = React.forwardRef(({ className, incomplete, colors, ...props }, ref) => {
  const colorNegative = colors.negative || props.theme.colors.negative;

  return (
    <BadgeWrapper className={className} incomplete={incomplete} colorNegative={colorNegative}>
      {incomplete === 0 ? (
        <BadgeIcon type="circleCheck" />
      ) : (
        <IncompleteNumber>{incomplete > 0 ? incomplete : '!'}</IncompleteNumber>
      )}
    </BadgeWrapper>
  );
});

InfoBadge.displayName = 'InfoBadge';

InfoBadge.defaultProps = {
  incomplete: 0,
  colors: {
    positive: null,
    negative: null,
  },
};

export default withTheme(InfoBadge);
