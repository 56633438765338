import React, { useState, useCallback } from 'react';
import { Col } from 'react-styled-flexboxgrid';
import { Link, withRouter } from 'react-router-dom';
import {
  HStack,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  IconButton,
  StackDivider,
  Spacer,
  VStack,
  useDisclosure,
  useTheme,
  Link as ChakraLink,
  Flex,
} from '@chakra-ui/react';

import Box from '../Box/Box';
import { RiMenuFill, RiSettings5Line, RiArrowRightSLine, RiGiftLine } from 'react-icons/ri';
import Logo from '../Logo/logo';
import Icon from '../Icon/icon';
import { API_STATUS_KEYS } from 'constants/constants';
import { useGlobalData } from 'utils/global-data';

import { hasPermission } from '../../permissions/has-permission';

import HEAP from '../../constants/HEAP.gen.json';
import { NavMenuItem } from './nav-menu-item';
import Header from '../Header/Header';
import useCurrentUser from 'data-client/use-current-user';
import { useReferralCtaClickEventMutation } from 'data-client/member-events';

import ReferralModal from 'features/Referral/referral-modal';
import { isFlagEnabled } from 'utils/flag-checks';
const flags = require('../../constants/FLAGS.gen.json');

const MenuLink = ({ children, ...props }) => <ChakraLink {...props}>{children}</ChakraLink>;

const NavBar = ({ ...props }) => {
  const { userGroupStatus, view } = useGlobalData();
  const [user] = useCurrentUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const groupId = user?.currentGroup?.id;
  const permissions = user?.permissions;
  const [isReferralModalOpen, setIsReferralModalOpen] = useState(false);

  const referralCtaClickEventMutation = useReferralCtaClickEventMutation();
  const theme = useTheme();

  const openReferralModal = useCallback(() => {
    referralCtaClickEventMutation.mutate();
    setIsReferralModalOpen(true);
  }, [referralCtaClickEventMutation, setIsReferralModalOpen]);

  const LogoItem = (
    <ChakraLink
      variant="headerNavLink"
      opacity="1"
      alignSelf="center"
      as={Link}
      key="logo"
      to="/"
      isLogo
      data-heap={HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_LOGO}>
      <span>
        <Logo />
      </span>
    </ChakraLink>
  );

  const navData = [
    {
      id: 'catalog',
      link: `/search/releases`,
      body: 'Catalog',
      meta: 'nav',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_CATALOG,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Releases'],
          permissions,
        }),
    },
    {
      id: 'statements',
      link: `/statements`,
      body: 'Statements',
      meta: 'nav',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_STATEMENTS,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Label Statements', 'Payee Statements'],
          permissions,
        }),
    },
    {
      id: 'analytics',
      link: `/analytics`,
      body: 'Analytics',
      meta: 'nav',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_ANALYTICS,
      shouldRender: () =>
        hasPermission({
          featureNames: ['Data Dashboard - Label View', 'Data Dashboard - Track View'],
          permissions,
          groupId,
        }),
    },
    {
      id: 'marketing',
      link: isFlagEnabled(flags.ENABLE_MARKETING_DISCOVERY_MODE) ? '/marketing' : '/marketing-links',
      body: 'Marketing',
      meta: 'nav',
      heapCode: HEAP.PUBLIC.NAVIGATION.TYPE.NAV_LINK_MARKETING_LINKS,
      hasVisited: localStorage.getItem('marketingLinkClicked'), ///BOOL
      shouldRender: () =>
        hasPermission({
          featureNames: ['Releases'], //You need Release permission to create the links.
          permissions,
          groupId,
        }),
    },
    {
      id: 'co-manager',
      link: `/co-manager`,
      body: 'Co-Manager',
      meta: 'nav',
      isNew: true,
      hasVisited: localStorage.getItem('coManagerLinkCicked'), ///BOOL
      shouldRender: () =>
        hasPermission({
          featureNames: ['Venice Co-Manager'],
          permissions,
          groupId,
        }),
    },
  ];

  const NavItems = [LogoItem].concat(
    navData.map(item =>
      item.shouldRender() ? (
        <NavMenuItem
          key={item.id}
          to={item.link}
          isLoaded={!userGroupStatus[API_STATUS_KEYS.IN_PROGRESS]}
          data-heap={item.heapCode}
          hasVisited={item.isNew && Object.hasOwn(item, 'hasVisited') && !item?.hasVisited}
          isActive={String(props.match.url).includes(item.link)}
          body={item.body}
        />
      ) : null
    )
  );

  const NewTag = ({ children }) => (
    <Box variant="newTag" lineHeight="1.2rem">
      {children}
    </Box>
  );

  const MobileNavItems = navData.reduce((accumulator, navItem) => {
    if (navItem.shouldRender && !navItem.shouldRender()) {
      // skip elements that have defined permissions that the user doesn't have access to
      return accumulator;
    }

    accumulator.push(
      <MenuLink key={navItem.link} as={Link} to={navItem.link} paddingY={2} data-heap={navItem.heapCode} display="flex">
        {navItem.body}
        {navItem.isNew && Object.hasOwn(navItem, 'hasVisited') && !navItem?.hasVisited ? <NewTag>NEW</NewTag> : null}
        <Spacer />
        <RiArrowRightSLine size={24} />
      </MenuLink>
    );
    return accumulator;
  }, []);

  const isDark = view === 'HOME';
  const canSendReferrals = user?.subscription?.isBanned === false;

  return (
    <>
      <Header>
        <Flex width="100%" maxWidth={'80rem'} display={'flex'} justifyContent={'space-between'} paddingX={'2rem'}>
          <Box width="100%" flexDir="row" justifyContent="space-between" display={{ base: 'none', sm: 'flex' }}>
            <Col xs={8} style={{ padding: 0 }}>
              <Flex alignItems="center" flexDirection="row" height="100%">
                {NavItems}
              </Flex>
            </Col>
            <HStack spacing="0.3rem">
              {canSendReferrals && (
                <IconButton
                  icon={<RiGiftLine color={theme.colors.black100} size="1.25rem" />}
                  onClick={openReferralModal}
                  aria-label="Earn $100 per referral"></IconButton>
              )}
              <IconButton
                icon={<RiSettings5Line color={theme.colors.black100} size="1.25rem" />}
                as={Link}
                to={'/settings'}
                heapCode={HEAP.PUBLIC.COMMON.TYPE.NAV_BUTTON_SETTINGS}
                testCode={'settings-button'}
              />
            </HStack>
          </Box>
          <Box flexDir="row" display={{ base: 'flex', sm: 'none' }} justifyContent="space-between" width="100%">
            {LogoItem}
            <IconButton onClick={onOpen} variant="ghost" icon={<RiMenuFill size={24} />} borderRadius="base" />
          </Box>
        </Flex>
      </Header>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} closeOnOverlayClick={true} size="sm">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader>
            <HStack spacing="12px">
              <IconButton
                onClick={onClose}
                dark={isDark}
                icon={<Icon type="exit" color={'#222028'} />}
                borderRadius="base"
              />
            </HStack>
          </DrawerHeader>
          <DrawerBody>
            <StackDivider borderColor="gray.700" borderTopWidth={1} mb={2} />
            <VStack divider={<StackDivider borderColor="gray.700" margin={1} />} align="stretch">
              {MobileNavItems}
              <MenuLink
                as={Link}
                to="/settings"
                paddingY={2}
                data-heap={HEAP.PUBLIC.NAVIGATION.TYPE.NAV_BUTTON_SETTINGS}>
                <HStack>
                  <RiSettings5Line />
                  <div>Settings</div>
                </HStack>
              </MenuLink>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <ReferralModal
        isOpen={isReferralModalOpen}
        onClose={() => setIsReferralModalOpen(false)}
        noFooter={true}
        variant="light"
      />
    </>
  );
};

export default withRouter(NavBar);
