import React from 'react';
import { Box } from '@chakra-ui/react';
import { withTheme } from '@emotion/react/macro';

function HeaderActAs({ meData, theme }) {
  const blink = `@keyframes blink {
    0%		{ background-color: #10c018;}
		50%	  { background-color: #04a1d5;}
    100%  { background-color: #10c018;}
  }`;

  return (
    <React.Fragment>
      <style>{blink} </style>
      <Box
        zIndex={theme.zIndices.tooltip}
        fontSize="lg"
        fontWeight="bold"
        backgroundColor={theme.colors.red100}
        color={theme.colors.black100}
        style={{
          animationDuration: `1s`,
          animationIterationCount: 'infinite',
          animationName: `blink`,
        }}>
        {' '}
        You Are Now Acting As {meData.name} ({meData.email}).
      </Box>
    </React.Fragment>
  );
}

export default withTheme(HeaderActAs);
