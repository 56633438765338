import { useMemo, useCallback } from 'react';
import { DateTime } from 'luxon';
import groupBy from 'lodash/groupBy';

const DEFAULT_TIME_KEY_FUNC = m => m.updated_at || m.created_at;

export function useNaturalTimeCategorizedModels({ models, getTimeKeyFunc = DEFAULT_TIME_KEY_FUNC }) {
  const getCategoryFunc = useCallback(
    model => {
      const keyDateTime = DateTime.fromISO(getTimeKeyFunc(model));
      const dateDiff = keyDateTime.diffNow('days').days;

      if (dateDiff > -1) {
        return 'Today';
      } else if (dateDiff > -2) {
        return 'Yesterday';
      } else if (dateDiff > -8) {
        return 'Previous 7 Days';
      } else if (dateDiff > 31) {
        return 'Previous 30 Days';
      } else {
        return keyDateTime.toFormat('MMMM y');
      }
    },
    [getTimeKeyFunc]
  );

  const categories = useMemo(() => groupBy(models, getCategoryFunc), [models, getCategoryFunc]);

  return { categories };
}
