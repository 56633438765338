import React from 'react';
import { Box, Flex, Heading, ListItem, UnorderedList } from '@chakra-ui/react';

export default function MarketingDiscoveryModeDetails({ isHistoryPage }) {
  return (
    <>
      <Flex padding="20px 0" gap="20px" alignItems="flex-start">
        {!isHistoryPage ? (
          <>
            <Box padding="20px" borderRadius="8px" flexBasis="100%" bgColor="black10">
              <Heading color="black80" fontSize="0.9rem" pb={2}>
                How to Create a Spotify Discovery Mode Campaign
              </Heading>
              <Box color="black80">
                <UnorderedList>
                  <ListItem>
                    Please fill this out by the 25th of the month to ensure your release is opted in or out of Discovery
                    Mode for the following month.
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
            <Box padding="20px" borderRadius="8px" flexBasis="100%" bgColor="black10">
              <Heading color="black80" fontSize="0.9rem" pb={2}>
                Recommended tracks
              </Heading>
              <Box color="black80">
                <Box pb={2}>
                  “Recommended” tracks for Discovery Mode are identified by Spotify based on signals received from their
                  proprietary machine learning technology that indicates a track may experience favorable performance
                  growth if opted in to the upcoming Discovery Mode campaign.
                </Box>
                <Box>
                  Performance is influenced by unique variables both internal and external to Spotify. Favorable
                  performance cannot be guaranteed and is even possible to experience zero or negative growth when
                  opting in “Recommended” tracks. These factors should be taken into consideration when planning your
                  music release and marketing campaigns.
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box padding="20px" borderRadius="8px" flexBasis="100%" bgColor="black10">
              <Box color="black80" fontSize="0.9rem" pb={2}>
                <strong>Stream Lift</strong>
                <Box> Compares the percentage difference between:</Box>
              </Box>
              <Box color="black80">
                <UnorderedList>
                  <ListItem pb={1}>
                    The average daily streams of your selected songs in Discovery Mode contexts over the 28 days before
                    those songs were first selected for Discovery Mode, and
                  </ListItem>
                  <ListItem pb={1}>
                    The average daily streams of your selected songs in Discovery Mode contexts during your current
                    Discovery Mode campaign period
                  </ListItem>
                </UnorderedList>
              </Box>
              <Box color="black80" fontSize="0.9rem" pb={2} pt={6}>
                <strong>Listeners Lift</strong>
                <Box>Compares the percentage difference between:</Box>
              </Box>
              <Box color="black80">
                <UnorderedList>
                  <ListItem pb={1}>
                    The average daily unique listeners of your selected songs in Discovery Mode contexts over the 28
                    days before those songs were first selected for Discovery Mode, and
                  </ListItem>
                  <ListItem>
                    The average daily unique listeners in Discovery Mode contexts during your current Discovery Mode
                    campaign period.
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
            <Box padding="20px" borderRadius="8px" flexBasis="100%" bgColor="black10">
              <Heading color="black80" fontSize="0.9rem" pb={3}>
                Spotify Advice
              </Heading>
              <Box color="black80">
                <UnorderedList>
                  <ListItem pb={1}>
                    For audience growth, consider listeners and listener lift. These metrics can convey the song’s
                    impact on your campaign’s overall performance.
                  </ListItem>
                  <ListItem>
                    For long-term engagement, consider saves and playlist adds. These actions represent a high intent
                    from listeners to play the song again.
                  </ListItem>
                  <ListItem>
                    For streams, consider stream lift. This percentage shows how the song's average daily number of
                    streams in Discovery Mode contexts has changed since the 28 days before the campaign began.
                  </ListItem>
                </UnorderedList>
              </Box>
            </Box>
          </>
        )}
      </Flex>
      <Box height={'5rem'} />
    </>
  );
}
