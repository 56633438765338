import React from 'react';
import { RiAddLine } from 'react-icons/ri';
import { useParams, Link } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

export default function CreateMarketingLinkButton({ disabled }) {
  const { groupId } = useParams();
  return (
    <Button
      disabled={disabled}
      variant="smallDark"
      as={Link}
      leftIcon={<RiAddLine />}
      to={`/marketing-links/${groupId}/create`}
      heapCode={'heap-code'}
      testCode={'create-marketing-link'}>
      Create Link
    </Button>
  );
}
