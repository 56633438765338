import { dataApiUrl } from '../url';
import AuthAxios from '../auth-axios';

const DataApiUrl = dataApiUrl() + '/memberships';

const Events = () => {
  const listEvents = () =>
    AuthAxios.get(`${DataApiUrl}/membershipEvents`)
      .then(res => res.data.data.results)
      .catch(err => console.error(err.message));

  return {
    listEvents,
  };
};

export default Events;
