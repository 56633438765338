import AbstractStateManager from '../../state-management/abstract-state-manager';
import { default as clipSchema } from '../../schema-gen/clip';

class ClipStateManager extends AbstractStateManager {
  dependentFields = ['file', 'clipName', 'startTimeSeconds', 'endTimeSeconds', 'speedPercent', 'playBackTime'];

  constructor({ saveState, stateData }, name, schema, idx) {
    super({ saveState, stateData }, name, clipSchema.Model);
    this.onUpdateParentState = () => {
      saveState(this);
    };
    this.idx = idx;
  }

  onSaveState() {
    this.onUpdateParentState();
  }

  setClipName(clipName) {
    this.stateData = {
      ...this.stateData,
      clipName,
    };
    this.onSaveState(this);
  }

  setStartTimeSeconds(startTimeSeconds) {
    this.stateData = {
      ...this.stateData,
      startTimeSeconds,
    };
    this.onSaveState(this);
  }

  setEndTimeSeconds(endTimeSeconds) {
    this.stateData = {
      ...this.stateData,
      endTimeSeconds,
    };
    this.onSaveState(this);
  }

  setSpeedPercent(speedPercent) {
    this.stateData = {
      ...this.stateData,
      speedPercent,
    };
    this.onSaveState(this);
  }

  setPlayBackTime(playBackTime) {
    this.stateData = {
      ...this.stateData,
      playBackTime,
    };
    this.onSaveState(this);
  }

  updateState(newState) {
    this.stateData = {
      ...this.stateData,
      ...newState,
    };
    this.onSaveState(this);
  }
}

export default ClipStateManager;
