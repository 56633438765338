import React from 'react';
import { Box, Input, FormLabel, FormControl, Stack, Text, FormHelperText, useTheme } from '@chakra-ui/react';

const errorDetailForEmailFromErrors = errors => {
  if (!errors.length) return null;
  const _error = errors[0].detail || errors[0];
  if (_error.includes('not allowed to be empty')) return 'Email is Missing';
  if (_error.includes('format')) return 'Email is invalid';
  if (_error.includes('associated with a current payee.')) return _error;
  if (_error.includes('The user already exists.')) return _error;
  return 'Email is missing/invalid';
};

const errorDetailForNameFromErrors = errors => {
  if (!errors.length) return null;
  const _error = errors[0].detail || errors[0];
  if (_error.includes('not allowed to be empty')) return 'Name is Missing';
  return 'Name is Missing/Invalid';
};

const didEmailError = errors => {
  if (!errors.length) return false;
  const _error = errors[0].detail || errors[0];
  return _error.includes('email') || _error.includes('The user already exists.');
};

const didNameError = errors => {
  if (!errors.length) return false;
  const _error = errors[0].detail || errors[0];
  return _error.includes('name');
};

function AddPayeeForm(props) {
  const { setPayee, payee, errors, clearErrors } = props;

  const handleSetPayee = event => {
    const { target } = event;
    const { name, value } = target;
    setPayee({ ...payee, [name]: value });
  };

  const theme = useTheme();

  const withError = errors.length;

  const inputStyles = {
    borderColor: theme.colors.black60,
    borderRadius: '.25rem',
    mb: `0${!withError ? '.75rem' : ''}`,
  };

  const formLabelStyles = {
    mb: '0.15rem',
    fontSize: '.6rem',
    textTransform: 'uppercase',
    color: theme.colors.black60,
  };

  return (
    <>
      <Box key="add-payee" as="form" id="add-payee" mt="1rem">
        <Stack color="black100">
          <Text fontSize="1rem" mb="1rem">
            Payees are anyone that gets paid for a Track Sound Recording Split. Once added, Payees will be invited to
            join Venice, connect their bank account and start automatically collecting royalties.
          </Text>
          <FormControl>
            <FormLabel {...formLabelStyles}>Name</FormLabel>
            <Input
              name="name"
              placeholder="Name"
              isRequired
              isInvalid={didNameError(errors)}
              errorBorderColor="crimson"
              onChange={handleSetPayee}
              value={payee.name}
              onFocus={clearErrors}
              {...inputStyles}
            />
            {didNameError(errors) && (
              <FormHelperText margin=".5rem 0 1rem 0">{errorDetailForNameFromErrors(errors)} </FormHelperText>
            )}
          </FormControl>
          <FormControl>
            <FormLabel {...formLabelStyles}>Email</FormLabel>
            <Input
              name="email"
              placeholder="Email"
              isInvalid={didEmailError(errors)}
              isRequired
              errorBorderColor="crimson"
              onChange={handleSetPayee}
              value={payee.email}
              onFocus={clearErrors}
              {...inputStyles}
            />
            {didEmailError(errors) && (
              <FormHelperText margin=".5rem 0 1rem 0">{errorDetailForEmailFromErrors(errors)}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </Box>
    </>
  );
}

export default AddPayeeForm;
