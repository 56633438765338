import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from '@emotion/styled/macro';

import ViewWrapper from '../ViewWrapper/view-wrapper';
import Icon from 'components/Icon/icon';

import CardFlow from './card-flow';
import Button from 'components/Button/button';
import ProgressTrackerBar from 'components/ProgressTrackerBar/progress-tracker-bar';
import SaveIndicator from 'features/SaveIndicator/save-indicator';

import { API_STATUS_KEYS } from '../../constants/constants';
import * as API from '../../utils/API/API';
import { buildStatus, noZoneDate, chopDate, offsetFromDateWithZone, pluralize } from '../../utils/helpers';
import { isFieldDirty, generatePartySubmitObject } from '../../utils/form';
import { CardFlowProvider } from 'utils/use-card-flow';
import { useGlobalData } from '../../utils/global-data';
import { useReleaseForm } from '../../utils/use-release-form';
import { useModals } from '../../utils/useModals';
import { ARTWORK_SIZE, EMPTY_RELEASE_TITLE } from 'constants/constants';
import { Redirect } from 'react-router-dom';
import { sortArtists } from '../../utils/form';
import { validateTitle } from './manage-release-functions';
import HEAP from '../../constants/HEAP.gen.json';

const PREVIEW_PARAM = 'preview=true';

const FlowNav = styled.div`
  margin-top: 2rem;
`;

const NavContent = styled.div`
  display: flex;
  height: 100%;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;

  > div {
    align-items: center;
    display: flex;
  }
`;

const NavLabel = styled.div`
  font-family: ${props => props.theme.fonts.families.text};
  font-size: ${props => props.theme.fonts.sizes.xxsm};
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-right: 1rem;
`;

const StatusContainer = styled.div`
  margin: 1rem 0 2rem;

  > div {
    background-color: ${props => props.theme.colors.brand.layer0};
    border-radius: 0.5rem;
    margin: 0 0.5rem;
    padding: 1.5rem 2rem;
  }
`;

const LeftSide = styled.div`
  color: ${props => props.theme.colors.brand.textWhite};
  font-family: ${props => props.theme.fonts.families.display};
  font-size: ${props => props.theme.fonts.sizes.xxlg};
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin: 0;
  min-height: 18px;
`;

const RightSide = styled.div`
  align-items: center;
  display: flex;
  justify-self: flex-end;
  margin-left: auto;
`;

const PreviewButtonCol = styled(Col)`
  align-items: flex-start;
  display: flex !important;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  height: 1rem;
  padding: 0;
`;

const initialProgressTrackerChunks = [
  {
    flex: 1,
    label: 'Setup Release',
    progress: 0,
    navCard: 'ReleaseDetails',
  },
  {
    flex: 1,
    label: 'Upload',
    progress: 0,
    navCard: 'Tracklist',
  },
  {
    flex: 1,
    label: 'Delivery details',
    progress: 0,
    navCard: 'LiveReleaseDate',
  },
];

const ManageRelease = ({ ...props }) => {
  const { match, location } = props;
  const { release_id, stack_index, card_index } = match.params;

  const [omnishareWhitelist, setOmnishareWhitelist] = useState([]);
  const [isReviewMode, setIsReviewMode] = useState(false);

  const [publishReleaseStatus, setPublishReleaseStatus] = useState(buildStatus(null));
  const [releaseSubmissionValidity, setReleaseSubmissionValidity] = useState({
    setup: {},
    tracklist: {},
    releaseDetails: {},
  });
  const [isCreatingRelease, setIsCreatingRelease] = useState(false);
  const [isNewRelease, setIsNewRelease] = useState(match.path.indexOf(`/releases/new`) !== -1);
  const [selectedDSPs, setSelectedDSPs] = useState([]);
  const [isModalPreviewOnly, setIsModalPreviewOnly] = useState(false);
  const [inducePreview, setInducePreview] = useState(false);
  const [induceSave, setInduceSave] = useState(false);
  const [wasJustDuplicated, setWasJustDuplicated] = useState(false);

  // Form Progress
  const [cardOrder, setCardOrder] = useState([]);
  const [progressTrackerChunks, setProgressTrackerChunks] = useState(initialProgressTrackerChunks);
  const [cardsAccessible, setCardsAccessible] = useState({});

  // Layout state
  const [cardLeftOffset, setCardLeftOffset] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [statusContainerWidth, setStatusContainerWidth] = useState('100%');

  // Refs
  const [refStatusTrackerContainer, setRefStatusTrackerContainer] = useState(null);

  // Forms

  const { dbUser, release, setReleaseId, releaseStatus, fileUrls, getDSPs, dsps, dspsStatus, loadFile } =
    useGlobalData();

  const {
    releaseFormObject,
    updateReleaseFormData,
    updateReleaseFormMeta,
    releaseTracks,
    deleteRelease,
    updateTrackField,
    createRelease,
    metaConditionals,
    updateMetaConditionals,
    selectedSet,
    saveReleaseStatus,
    patchRelease,
  } = useReleaseForm();

  const { updateModalState, showModal, hideModal } = useModals();

  const releaseImageUrls = fileUrls['release'] || {};
  const releaseImage =
    releaseImageUrls &&
    release &&
    selectedSet &&
    releaseImageUrls[release.id] &&
    releaseImageUrls[release.id][selectedSet.id] &&
    releaseImageUrls[release.id][selectedSet.id][0][ARTWORK_SIZE];

  const Release = API.release();

  const selectedSetId = selectedSet?.id;
  useEffect(() => {
    if (release && selectedSet && selectedSet.id) {
      const doesntExist =
        !releaseImageUrls[release.id] ||
        !releaseImageUrls[release.id][selectedSet.id] ||
        !releaseImageUrls[release.id][selectedSet.id][0][ARTWORK_SIZE];

      if (doesntExist) {
        loadFile('release', 'coverArt', ARTWORK_SIZE, {
          releaseId: release.id,
          setId: selectedSet.id,
          artworkIndex: 0,
        });
      }
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSetId]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => window.removeEventListener('resize', handleWindowResize);
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refStatusTrackerContainer, statusContainerWidth]);

  // DEBT:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRefStatusTrackerContainer = useCallback(node => {
    if (node && !node.isEqualNode(refStatusTrackerContainer)) {
      setRefStatusTrackerContainer(node);
      handleWindowResize();
    }
  });

  const handleWindowResize = () => {
    updateCardFlowSizes();
    updateResponsivePageLayout();
  };

  const updateCardFlowSizes = () => {
    if (refStatusTrackerContainer) {
      const rect = refStatusTrackerContainer.getBoundingClientRect();

      setCardWidth(rect.width);
      setCardLeftOffset(rect.left);
    }
  };

  const updateResponsivePageLayout = () => {
    if (window.outerWidth < 1200 && statusContainerWidth !== '100%') {
      setStatusContainerWidth('100%');
    } else if (window.outerWidth >= 1200 && statusContainerWidth !== '1000px') {
      setStatusContainerWidth('1000px');
    }
  };

  useEffect(() => {
    setReleaseId(isNewRelease ? null : release_id);

    setPublishReleaseStatus(buildStatus(null));

    // Omnishare
    if (location?.state?.omnishare) {
      setOmnishareWhitelist(location.state.omnishare.containers.split(','));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isNewRelease && match.path.indexOf(`/releases/new`) !== -1) {
      setIsNewRelease(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  useEffect(() => {
    if (isCreatingRelease && release && isNewRelease) {
      setIsCreatingRelease(false);
      props.history.replace(`/releases/${release.id}/edit`);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release]);

  useEffect(() => {
    if (!dsps.length && !dspsStatus[API_STATUS_KEYS.IN_PROGRESS] && !dspsStatus[API_STATUS_KEYS.COMPLETE]) {
      getDSPs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // check validity for distribution any time release data updates
  useEffect(() => {
    if (releaseFormObject) {
      isReleaseValid();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseTracks, releaseFormObject, releaseImage]);

  // keep the summary modal data up to date
  useEffect(() => {
    if (release && releaseFormObject) {
      updateModalState('releaseSummary', {
        releaseFormObject,
        releaseTracks,
        releaseSummaryEditClickedHandler,
        submitForDistributionClickedHandler,
        publishReleaseStatus,
        saveReleaseStatus,
        releaseImage,
        releaseSummaryCloseHandler,
        releaseSubmissionValidity,
        isModalPreviewOnly,
        cardsAccessible,
      });
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    release,
    releaseFormObject,
    releaseTracks,
    selectedDSPs,
    publishReleaseStatus,
    saveReleaseStatus,
    releaseImage,
    releaseSubmissionValidity,
    isModalPreviewOnly,
    cardsAccessible,
  ]);

  // Event Handlers

  // listen for events coming from ./Card
  //  this is necessary because ManageRelease doesn't have access to cardFlow data
  //  vars like induceSave and inducePreview are also passed back to ./Card via _passthough prop
  const handleCardFlowEvent = e => {
    switch (e.name) {
      case 'CREATE_RELEASE':
        setIsCreatingRelease(true);
        createRelease(e.data.userGroupId);
        break;
      case 'SHOW_PREVIEW':
        setIsModalPreviewOnly(false);
        showSummaryModal();
        setInduceSave(false);
        setInducePreview(false);
        break;
      case 'RESET_SAVE_AND_PREVIEW':
        setInduceSave(false);
        setInducePreview(false);
        break;
      case 'DSPS_SAVED':
        setSelectedDSPs(e.data.dsp_platforms);
        break;
      case 'REVIEW_MODE_CHANGED':
        calculateCardsAccessible(e.data.cardData);
        calculateProgressTrackerPosition(e.data.cardData);
        break;
      default:
        break;
    }
  };

  const handleCloseFlow = () => {
    const isEmptyRelease = releaseFormObject?.title === EMPTY_RELEASE_TITLE;

    if (isEmptyRelease) {
      deleteRelease(releaseFormObject.id);
      props.history.push('/search/releases');
    } else if (
      window.confirm('Abandon this draft for now. You will be able to come back and edit it later. Are you sure?')
    ) {
      props.history.push('/search/releases');
    }
  };

  const handlePreviewClicked = () => {
    setInduceSave(true);
    setInducePreview(true);
  };

  const showSummaryModal = () => {
    isReleaseValid();
    showModal('releaseSummary');
  };

  useEffect(() => {
    if (location.search.includes(PREVIEW_PARAM) && releaseFormObject && release) {
      showSummaryModal();
      setIsReviewMode(true);
      setWasJustDuplicated(true);
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [release, releaseFormObject]);

  const releaseSummaryEditClickedHandler = cardId => {
    navigateToCard(cardId);
    hideModal('releaseSummary');
    setIsReviewMode(true);
  };

  const releaseSummaryCloseHandler = () => {
    hideModal('releaseSummary');
    setIsReviewMode(false);
  };

  const submitForDistributionClickedHandler = () => {
    submitRelease();
  };

  const handleCardStacksChanged = stacks => setCardOrder(stacks.map(stack => stack.map(card => card.props.id)));

  const navigateToCard = cardName => {
    const url = `/releases/${release.id}/edit/${stack_index}/${cardOrder[stack_index].indexOf(cardName)}`;

    if (props.history.location.path !== url) {
      props.history.push(url);
    }
  };

  const cardFlowIndexChangedHandler = (stackIndex, cardIndex, cardData) => {
    const urlBase = props.history.location.pathname.split('/edit')[0];
    const newUrl = `${urlBase}/edit/${stackIndex}/${cardIndex}`;

    if (!isNewRelease && newUrl !== props.history.location.pathname) {
      props.history.push(newUrl);
    }

    calculateProgressTrackerPosition(cardData, cardIndex);
  };

  const handleProgressTrackerClicked = i => {
    setInduceSave(true);

    switch (i) {
      case 0:
        navigateToCard(progressTrackerChunks[0].navCard);
        break;
      case 1:
        navigateToCard(progressTrackerChunks[1].navCard);
        break;
      case 2:
        navigateToCard(progressTrackerChunks[2].navCard);
        break;
      default:
        break;
    }
  };

  // calculates the state of the progress tracker component at the top of the
  //  Create Release flow
  const calculateProgressTrackerPosition = (cardData = null, cardIndex = null) => {
    const chunks = JSON.parse(JSON.stringify(progressTrackerChunks));

    if (cardData && !isNewRelease) {
      const index = cardIndex || card_index;
      let numSetupCards = 2;
      let numUploadCards = 2;
      let numDeliveryCards = 2;

      if (!cardData['ReleaseDetails']?.formData?._meta?.isCompilation) numSetupCards++; // release artists

      if (cardData['ReleaseDetails']?.formData?._meta.releaseType !== 'VideoSingle') numDeliveryCards++; // pre-order?

      const includePreOrder = cardData['IncludePreOrder']?.formData?._meta.includePreOrder === 'yes';

      if (includePreOrder) {
        numDeliveryCards++; // pre-order date
      }

      const includePriceTierCards =
        cardData['DeliveryPlatformsAndTerritories']?.formData?.dsp_platforms?.includes('apple');

      if (includePriceTierCards) {
        numDeliveryCards++; // release price tiers
      }

      if (cardData['Tracklist']?.tracklistData?.length > 1) numDeliveryCards++; // track price tier

      const setupEnd = numSetupCards + 1;
      const uploadEnd = setupEnd + numUploadCards;
      const deliveryEnd = uploadEnd + numDeliveryCards;

      chunks[0] = {
        ...chunks[0],
        progress: index < setupEnd ? (index / (numSetupCards + 1)) * 100 : 100,
        allowClick: wasJustDuplicated || (!isReviewMode && cardsAccessible[chunks[0].navCard]),
      };

      chunks[1] = {
        ...chunks[1],
        progress: index < uploadEnd ? ((index - setupEnd + 1) / (numUploadCards + 1)) * 100 : 100,
        allowClick: wasJustDuplicated || (!isReviewMode && cardsAccessible[chunks[1].navCard]),
      };

      chunks[2] = {
        ...chunks[2],
        progress: index < deliveryEnd ? ((index - uploadEnd + 1) / numDeliveryCards) * 100 : 100,
        allowClick: wasJustDuplicated || (!isReviewMode && cardsAccessible[chunks[2].navCard]),
      };
    }

    setProgressTrackerChunks(chunks);
  };

  // determines which cards should be accessible (navigate to by click) based
  //  on whether they've been reached by the user (or have data)
  const calculateCardsAccessible = cardData => {
    const accessible = {};
    const touched = {
      CoreDetails: !!cardData['CoreDetails']?.formData?.title?.trim(),
      ReleaseDetails: !!cardData['ReleaseDetails']?.formData.display_label_name,
      ReleaseArtists: cardData['ReleaseArtists']?.formData.artists?.length > 0,
      UploadCoverArt: !!cardData['UploadCoverArt']?.imageUrl,
      Tracklist: cardData['Tracklist']?.tracklistData.length > 0,
      LiveReleaseDate: !!cardData['LiveReleaseDate']?.formData.release_date,
      DeliveryPlatformsAndTerritories: cardData['DeliveryPlatformsAndTerritories']?.formData?.dsp_platforms?.length > 0,
      PriceTierRelease: !!cardData['PriceTierRelease']?.formData.price_tier,
      PriceTierTrack: !!cardData['PriceTierTrack']?.formData.track_price_tiers,
      IncludePreOrder: !!cardData['PreOrderDate']?.formData.pre_order_release_date,
      PreOrderDate: !!cardData['PreOrderDate']?.formData.pre_order_release_date,
      InstantGratTracks: !!cardData['InstantGratTracks']?.formData.track_instant_grat_dates.reduce(
        (a, v) => a || v,
        false
      ),
    };

    const keys = Object.keys(touched);

    for (let i = 0; i < keys.length; i++) {
      switch (keys[i]) {
        case 'ReleaseArtists':
        case 'DeliveryPlatformsAndTerritories':
          accessible[keys[i]] = touched[keys[i]];
          break;
        case 'PriceTierTrack':
        case 'PriceTierRelease':
          accessible[keys[i]] = touched['DeliveryPlatformsAndTerritories'];
          break;
        case 'InstantGratTracks':
          accessible[keys[i]] = touched[keys[i]] || touched['IncludePreOrder'];
          break;
        default:
          // By default, a card is accessible if it's been touched or the following one has
          accessible[keys[i]] = !!(touched[keys[i]] || touched[keys[i + 1]]);
      }
    }

    if (wasJustDuplicated) {
      // make the end of the card flow editable/accessible if a track was duplicated
      accessible['LiveReleaseDate'] = true;
      accessible['UploadCoverArt'] = true;
      accessible['DeliveryPlatformsAndTerritories'] = true;
    }

    setCardsAccessible(accessible);
  };

  // primary data reconcilliation for Create Release
  //  runs whenever user alters data on any card and determines if data needs to save to BE
  //  updates releaseFormObject with new data and flags it for save with metaConditionals.isSaved: false
  const handleCardDataChanged = cardData => {
    const formObject = { ...releaseFormObject };
    const newMeta = { isSaved: true };
    const ignoreFields = ['_meta'];

    if (!isEmpty(formObject)) {
      // Update releaseFormData from cardData
      Object.keys(cardData)
        .filter(cardId => cardData[cardId].formData)
        .forEach(cardId =>
          Object.keys(cardData[cardId].formData)
            .filter(field => !includes(ignoreFields, field))
            .filter(field => isFieldDirty(field, cardData[cardId].formData, formObject))
            .forEach(field => {
              updateReleaseFormData({
                [field]: cardData[cardId].formData[field],
              });
              newMeta.isSaved = false;
            })
        );

      // Update _meta
      Object.keys(cardData)
        .filter(cardId => cardData[cardId].formData && cardData[cardId].formData._meta)
        .forEach(cardId =>
          Object.keys(cardData[cardId].formData._meta)
            .filter(
              metaKey =>
                !Object.prototype.hasOwnProperty.call(metaConditionals, metaKey) || //eslint made me do it
                isFieldDirty(metaKey, cardData[cardId].formData._meta, metaConditionals)
            )
            .forEach(metaKey => {
              newMeta[metaKey] = cardData[cardId].formData._meta[metaKey];
            })
        );

      const saveOnMetaFields = [
        'includeReleaseDateTime',
        'includeOriginalReleaseDate',
        'releaseType',
        'isCompilation',
        'includeExistingUPC',
        'trackIds',
      ];

      // Need to save based on _meta data?
      Object.keys(newMeta)
        .filter(metaKey => includes(saveOnMetaFields, metaKey))
        .filter(metaKey => isFieldDirty(metaKey, newMeta, formObject._meta))
        .forEach(metaKey => {
          updateReleaseFormMeta({ [metaKey]: newMeta[metaKey] });

          // includeOriginalReleaseDate should only trigger a save if set to FALSE
          //  (so ignore the case where the key is includeOriginalReleaseDate and true)
          //  A change to original_release_date triggers a save for TRUE if
          //  includeOriginalReleaseDate is true and original_release_date is
          //  unchanged, we shouldn't save

          // releaseType should only trigger a save if release is NOT isCompilation
          if (
            !(metaKey === 'includeOriginalReleaseDate' && newMeta[metaKey]) &&
            !(metaKey === 'releaseType' && metaConditionals.isCompilation)
          ) {
            newMeta.isSaved = false;
          }
        });

      updateMetaConditionals(newMeta);
    }

    if (!isReviewMode) {
      calculateProgressTrackerPosition(cardData);
      calculateCardsAccessible(cardData);
    }
  };

  // If data's been flagged for saving, do save
  useEffect(() => {
    if (!metaConditionals.isSaved && !saveReleaseStatus[API_STATUS_KEYS.IN_PROGRESS]) {
      saveRelease();
    }
    // DEBT:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaConditionals]);

  // redirect back to catalog if user cannot write & update Releases
  if (!dbUser?.subscription?.isActive) {
    return <Redirect to={{ pathname: '/releases', state: { from: location } }} />;
  }

  // does the save — patches the release on the BE with new data
  const saveRelease = async () => {
    const submitObject = JSON.parse(JSON.stringify(releaseFormObject));
    const metaFields = { ...metaConditionals };
    let releaseId = release_id;

    delete submitObject._meta;

    // Collect fields

    // Remap and clean up fields

    if (submitObject.artists) {
      submitObject.artists = sortArtists(submitObject.artists).map((party, idx) =>
        generatePartySubmitObject(party, idx + 1)
      );
    }

    submitObject.artists = Array.isArray(release.artists) && !submitObject.artists ? [] : submitObject.artists;

    // TODO: BE should probably handle this
    submitObject.p_line =
      (submitObject.copyright_year || release.copyright_year) +
      ' ' +
      (submitObject.display_label_name || release.display_label_name);

    // Consolidate time

    const offset = offsetFromDateWithZone(submitObject.release_date, 'America/New_York');

    if (metaFields.includeReleaseDateTime) {
      submitObject.is_timed_release = true;
      submitObject.release_date = submitObject.release_date
        ? `${submitObject.release_date}T${submitObject.release_date_time}${offset}`
        : null;
    } else {
      submitObject.is_timed_release = false;
      submitObject.release_date = submitObject.release_date ? `${submitObject.release_date}T00:00` : null;
    }

    if (metaFields.includeOriginalReleaseDate) {
      submitObject.original_release_date = chopDate(submitObject.original_release_date);
    } else {
      submitObject.original_release_date = null;
    }

    if (metaFields.includeExistingUPC) {
      // eslint-disable-next-line
      submitObject.asset_release_upc = submitObject.asset_release_upc;
    } else {
      submitObject.asset_release_upc = null;
    }

    // Release type

    if (metaFields.isCompilation) {
      submitObject.type = 'Compilation';
    } else {
      submitObject.type = metaFields.releaseType !== 'Compilation' ? metaFields.releaseType : null;
    }

    // Pre-release

    if (submitObject.type !== 'VideoSingle' && metaFields.includePreOrder === 'yes') {
      submitObject.pre_order_release_date = noZoneDate(submitObject.pre_order_release_date);
    }

    // Track fields

    if (submitObject.track_price_tiers.length > 0 || submitObject.track_instant_grat_dates.length > 0) {
      let setTracks = [];

      (metaFields.trackIds || []).forEach(id => {
        const newData = { id };
        const i = release.sets[0].tracks.findIndex(track => track.id === id);

        if (i !== -1) {
          if (metaFields.trackIds.length > 1) {
            if (submitObject.track_price_tiers[i]) {
              newData.price_tier = submitObject.track_price_tiers[i];
            }
          } else {
            newData.price_tier = submitObject.price_tier;
          }

          if (submitObject.type !== 'VideoSingle' && metaFields.includeInstantGratDates[i]) {
            newData.instant_grat_date = submitObject.track_instant_grat_dates[i];
          } else {
            newData.instant_grat_date = null;
          }
        }

        setTracks.push(newData);
      });

      submitObject.sets = [
        {
          id: selectedSet.id,
          tracks: setTracks,
        },
      ];

      delete submitObject.tracks;
      delete submitObject.track_price_tiers;
      delete submitObject.track_instant_grat_dates;
    }

    // Update database

    await patchRelease(releaseId, submitObject);
    updateMetaConditionals({ isSaved: true });

    // Track Side Effects

    // check each releaseTrack to see if there is an instant grat date
    // if there is, set it to null
    if (submitObject.pre_order_release_date === null && release.sets[0]) {
      for (let i = 0; i < release.sets[0].tracks.length; i++) {
        const track = release.sets[0].tracks[i];
        if (track.data && track.data.instant_grat_date) {
          updateTrackField(i, 'instant_grat_date', null);
        }
      }
    }
  };

  // final validity check before submitting the release for distribution
  const isReleaseValid = () => {
    let errorStatus;
    const validity = {
      setup: {},
      tracklist: {
        tracks: releaseTracks.map(() => ({ errors: 0 })),
        message: null,
        errors: 0,
      },
      releaseDetails: {},
    };
    const messages = {
      required: 'This field is required',
      minTracks: 'You need to add at least 1 track',
      minArtists: 'At least one artist is required',
      missingRequiredArtist: 'A required artist is missing',
      numTracks: 'At least one track is required',
      trackArtist: 'Every track must have an artist, a lyricist, and a composer',
      trackDetail: 'Track details missing',
      missingTrackPriceTier: 'A track price tier is missing',
      minGratTracks:
        'You need to have at least 1 track marked as instant gratification if this will be a Pre-Save or Pre-Order',
      maxGratTracks: num =>
        `Up to 50% of the total number of tracks can be set as Instant Gratification Tracks. Please deselect ${num} ${pluralize(
          'track',
          num
        )}`,
      preSaveDsps: dsps =>
        `You are not distributing to any platforms that accept Pre-Save. Please select one of the following for your pre-save to go through: ${dsps
          .map(dsp => dsp.name)
          .join(', ')}`,
    };

    setPublishReleaseStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));
    const appleInDspList = releaseFormObject.dsp_platforms.find(dsp => dsp === 'apple');
    // Core Details

    if (!releaseImage) validity.setup.image = messages.required;
    const { isValid: isTitleValid, errorMessage: titleErrorMessage } = validateTitle(releaseFormObject.title.trim(), {
      isRequired: true,
    });

    if (!isTitleValid) {
      validity.setup.title = titleErrorMessage;
    }
    if (!releaseFormObject.display_artist_name) validity.setup.display_artist_name = messages.required;

    // Artists

    if (releaseFormObject.type !== 'Compilation' && !releaseFormObject.artists.length)
      validity.setup.artists = messages.minArtists;

    // Tracks

    if (!releaseFormObject.tracks.length) {
      errorStatus = errorStatus ? errorStatus : buildStatus(messages.minTracks);
      validity.tracklist.message = messages.numTracks;
      validity.tracklist.errors++;
    } else {
      for (let i = 0; i < releaseTracks.length; i++) {
        const track = releaseTracks[i].data;

        if (!track) {
          continue;
        }
        const { isValid, errorMessage } = validateTitle(track?.title);
        if (
          !track.title ||
          !(track.artists.length && track.artists[0]?.full_name) ||
          !(track.contributors.length && track.contributors[0]?.full_name) ||
          !track.genre ||
          !track.lang ||
          !isValid ||
          (!track.price_tier && appleInDspList)
        ) {
          if (
            !(track.artists.length && track.artists[0]?.full_name) ||
            !(track.contributors.length && track.contributors[0]?.full_name)
          ) {
            errorStatus = errorStatus ? errorStatus : buildStatus(messages.trackArtist);
            validity.tracklist.message = messages.trackArtist;
            validity.tracklist.tracks[i].errors++;
          }

          if (!track.title || !track.genre || !track.lang) {
            errorStatus = errorStatus ? errorStatus : buildStatus(messages.trackDetail);
            validity.tracklist.message = messages.trackDetail;
            validity.tracklist.tracks[i].errors++;
          }

          if (!isValid) {
            errorStatus = errorStatus ? errorStatus : buildStatus(messages.trackDetail);
            validity.tracklist.message = errorMessage;
            validity.tracklist.tracks[i].errors++;
          }

          if (!track.price_tier && appleInDspList) {
            errorStatus = errorStatus ? errorStatus : buildStatus(messages.missingTrackPriceTier);
            validity.tracklist.message = messages.missingTrackPriceTier;
            validity.tracklist.tracks[i].errors++;
          }

          validity.tracklist.errors++;
        }
      }
    }

    // Release Details

    if (!releaseFormObject.display_artist_name) validity.setup.display_artist_name = messages.required;
    if (!releaseFormObject.copyright_year) validity.setup.copyright_year = messages.required;
    if (!releaseFormObject.display_label_name) validity.setup.display_label_name = messages.required;
    if (!releaseFormObject.genre) validity.setup.genre = messages.required;
    if (!releaseFormObject.lang) validity.setup.lang = messages.required;

    // Delivery Details

    if (!releaseFormObject.release_date) validity.releaseDetails.release_date = messages.required;
    if (!releaseFormObject.dsp_platforms.length) validity.releaseDetails.dsp_platforms = messages.required;
    if (!releaseFormObject.territories.length) validity.releaseDetails.territories = messages.required;

    // Pre-Order

    if (releaseFormObject.pre_order_release_date && releaseFormObject?.tracks?.length > 1) {
      let instantGratTracks = releaseFormObject.track_instant_grat_dates.filter(date => !!date).length;

      // none selected
      if (instantGratTracks === 0) {
        errorStatus = errorStatus ? errorStatus : buildStatus(messages.minGratTracks);
        validity.releaseDetails.instant_grat = messages.minGratTracks;
      }

      // more than 50%
      if (instantGratTracks > releaseTracks.length * 0.5) {
        errorStatus = errorStatus
          ? errorStatus
          : buildStatus(messages.maxGratTracks(instantGratTracks - parseInt(releaseTracks.length * 0.5)));
        validity.releaseDetails.instant_grat = messages.maxGratTracks(
          instantGratTracks - parseInt(releaseTracks.length * 0.5)
        );
      }
    }

    setReleaseSubmissionValidity(validity);

    if (errorStatus) {
      setPublishReleaseStatus(errorStatus);
      return false;
    }

    setPublishReleaseStatus(buildStatus(null));

    return true;
  };

  // submits the release for distribution
  const submitRelease = async () => {
    if (!isReleaseValid()) {
      return;
    }

    setPublishReleaseStatus(buildStatus(API_STATUS_KEYS.IN_PROGRESS));

    const publish = await Release.publish(release_id, releaseFormObject.dsp_platforms).catch(err => {
      const error = isArray(err) ? err[0]?.meta : 'Error submitting the release';
      setPublishReleaseStatus(buildStatus(error));
    });

    if (publish) {
      setPublishReleaseStatus(buildStatus(API_STATUS_KEYS.COMPLETE));
      hideModal('releaseSummary');
      props.history.push(`/releases/${release_id}`);
    }
  };

  // Navbar

  const narrowStyles = {
    navContent: {
      margin: '0 0.5rem',
      width: statusContainerWidth,
      padding: '0 1rem 0 0',
      boxSizing: 'border-box',
    },
    statusContainer: {
      width: statusContainerWidth,
    },
  };

  return (
    <ViewWrapper
      designLayer={1}
      navbarDesignLayer={1}
      hideNavbar={!location?.state?.omnishare}
      heightToAccomodate={0}
      disableHorizontalScroll={true}
      wrapperStyle={{ paddingTop: '0' }}
      {...props}>
      <style>{`body { background-color: #2D2A38; }`}</style>
      <FlowNav omnishareas>
        <NavContent style={narrowStyles.navContent}>
          <LeftSide>
            <NavLabel>Create Release</NavLabel>
            <SaveIndicator saving={saveReleaseStatus[API_STATUS_KEYS.IN_PROGRESS]} />
          </LeftSide>
          <RightSide>
            <CloseButton
              onClick={() => handleCloseFlow()}
              data-heap={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_BUTTON_EXIT}>
              <Icon type="exit" />
            </CloseButton>
          </RightSide>
        </NavContent>
      </FlowNav>
      <StatusContainer ref={onRefStatusTrackerContainer} style={narrowStyles.statusContainer}>
        <div>
          <Row>
            <Col xs={12} sm={10} omnishareas>
              <ProgressTrackerBar chunks={progressTrackerChunks} onClick={i => handleProgressTrackerClicked(i)} />
            </Col>
            <PreviewButtonCol xs={12} sm={2} omnishareas>
              <Button
                secondary
                heapCode={HEAP.PUBLIC.CREATE_RELEASE.TYPE.CREATE_RELEASE_BUTTON_PREVIEW}
                text="Preview"
                onClick={() => handlePreviewClicked()}
                disabled={
                  isReviewMode ||
                  saveReleaseStatus[API_STATUS_KEYS.IN_PROGRESS] ||
                  releaseStatus.all[API_STATUS_KEYS.IN_PROGRESS]
                }
              />
            </PreviewButtonCol>
          </Row>
        </div>
      </StatusContainer>
      <CardFlowProvider
        _passthrough={{ inducePreview, induceSave }}
        saveRelease={saveRelease}
        stackIndex={parseInt(stack_index || 0)}
        cardIndex={parseInt(card_index || 0)}
        omnishareWhitelist={omnishareWhitelist}
        isReviewMode={isReviewMode}
        onCardStacksChanged={handleCardStacksChanged}
        onDataChanged={handleCardDataChanged}
        onExternalEvent={handleCardFlowEvent}
        onCardIndexChanged={(stackIndex, cardIndex, cardData) =>
          cardFlowIndexChangedHandler(stackIndex, cardIndex, cardData)
        }>
        <CardFlow cardLeftOffset={cardLeftOffset} cardWidth={cardWidth} />
      </CardFlowProvider>
    </ViewWrapper>
  );
};

export default ManageRelease;
