class AbstractStateManager {
  title;
  dependentFields = [];
  #mutableFields;
  validationResults;
  #allValid;
  #validations;

  constructor({ saveState, stateData }, name, schema) {
    this.onSaveState = saveState;
    this.stateData = stateData;
    // NAME MUST BE AS STABLE AS A REACT COMPONENT KEY
    this.name = name.replace(/[\W_]+/g, '-');
    this.schema = schema;

    if (!this.#mutableFields) {
      this.#mutableFields = this.dependentFields;
    }
  }

  get props() {
    return Object.entries(this.stateData).reduce((data, [key, value]) => {
      if (!this.dependentFields.includes(key)) return data;
      return { ...data, [key]: value };
    }, {});
  }

  get mutableFields() {
    if (!this.#mutableFields) {
      return this.props;
    } else {
      return Object.entries(this.stateData).reduce((data, [key, value]) => {
        if (!this.#mutableFields.includes(key)) return data;
        return { ...data, [key]: value };
      }, {});
    }
  }

  getPropertyByPath(path, defaultValue) {
    return AbstractStateManager.getLeafByPath(this.props, path, defaultValue);
  }

  getValidationErrorByPath(path, defaultValue) {
    return AbstractStateManager.getLeafByPath(this.validationResults, path, defaultValue);
  }

  get validations() {
    if (!this.validationResults) {
      this.validateProps();
    }
    this.#validations = {};

    const updateValidations = (node, path) => {
      const error = this.getValidationErrorByPath(path, false); //ManageClipsStateManager.getLeafByPath(this.validationErrors, path, false);
      AbstractStateManager.setLeafByPath(this.#validations, path, !error);
    };

    const shouldWalkNodeFn = (node, path) => {
      if (node?.validations) {
        AbstractStateManager.setLeafByPath(this.#validations, path, node.validations);
        return false;
      }
      return true;
    };

    AbstractStateManager.walkRecursively(this.mutableFields, updateValidations, shouldWalkNodeFn);

    return this.#validations;
  }

  //This can be dynamically changed by overriding this function
  get linkTitle() {
    return this.title;
  }

  get disabled() {
    return false;
  }

  get disabledHoverText() {
    return '';
  }

  getSaveHandler() {
    return this.saveState.bind(this);
  }

  saveState(newState) {
    this.onSaveState(newState);
  }

  toJSON() {
    return this.props;
  }

  static walkRecursively(node, callback, shouldWalkNodeFn = () => true, parentKeys = []) {
    if (!!node && typeof node === 'object') {
      Object.keys(node).forEach(key => {
        const currentKeys = [...parentKeys, key];
        const currentNode = node[key];

        if (currentNode === null) {
          callback(currentNode, currentKeys);
        } else {
          let shouldWalkNode;

          try {
            shouldWalkNode = shouldWalkNodeFn(currentNode, currentKeys);
          } catch (e) {
            e.context = {
              node,
              parentKeys,
            };
            e.message = `Error in shouldWalkNodeFn for key ${key}: ${e.message}`;
            throw e;
          }

          if (shouldWalkNode) {
            try {
              AbstractStateManager.walkRecursively(currentNode, callback, shouldWalkNodeFn, currentKeys);
            } catch (e) {
              e.message = `Error in callback for key ${key}: ${e.message}`;
              e.context = {
                node,
                parentKeys,
              };
            }
          }
        }
      });
    } else if (!!node && Array.isArray(node)) {
      node.forEach(item => {
        if (item === null) {
          callback(item, parentKeys);
        } else {
          let shouldWalkNode;
          try {
            shouldWalkNode = shouldWalkNodeFn(item, parentKeys);
          } catch (e) {
            e.context = {
              node,
              parentKeys,
            };
            e.message = `Error in shouldWalkNodeFn array item: ${e.message}`;
            throw e;
          }

          if (shouldWalkNode) {
            try {
              AbstractStateManager.walkRecursively(item, callback, shouldWalkNodeFn, parentKeys);
            } catch (e) {
              e.message = `Error in callback for array item: ${e.message}`;
              e.context = {
                node,
                parentKeys,
              };
            }
          }
        }
      });
    } else {
      callback(node, parentKeys);
    }
  }

  //LARGELY COPIED FROM YouMightNotNeed.com/lodash#get
  static getLeafByPath(obj, path, defValue) {
    if (!path) return undefined;

    // Check if path is string or array. Regex : ensure that we do not have '.' and brackets.
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    // Find value
    return pathArray.reduce((prevObj, key) => {
      return prevObj?.[key] ? prevObj[key] : defValue;
    }, obj);
  }

  //LARGELY COPIED FROM YouMightNotNeed.com/lodash#set
  static setLeafByPath(obj, path, value) {
    // Regex explained: https://regexr.com/58j0k
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);
    pathArray.reduce((acc, key, i) => {
      if (acc[key] === undefined) {
        acc[key] = {};
      }

      if (i === pathArray.length - 1) {
        acc[key] = value;
      }

      return acc[key];
    }, obj);
  }

  validateProps() {
    if (!this.validationResults) {
      this.validationResults = {};
    }

    const result = this.schema.safeParse(this.mutableFields);
    this.#allValid = result.success;

    result?.error?.issues?.map(issue => {
      AbstractStateManager.setLeafByPath(this.validationResults, issue.path, issue);
    });
  }
}

export default AbstractStateManager;
